<script>
import services from '@/services';
import { MERCHANT_ID } from '@/constants/names';
import FlatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts';
import { formatDateToHM, formatDateToYMD } from '@/util/date/calendar.util';
import { formatNumber, prefixZeroToNum } from '@/util/helper';
import { statisticsFormatDate } from '@/util/date/calendar.util';

export default {
    name: 'AppGameHistory',
    components: {
        FlatPickr,
        apexchart: VueApexCharts
    },
    data() {
        return {
            statisticsByGame: [],
            merchant_id: parseInt(MERCHANT_ID),
            totalUsers: null,
            totalGainedCoin: null,
            dailyTotalUsers: null,
            dailyTotalCoinsGained: null,
            start_date: null,
            end_date: null,
            start_date_monthly: null,
            end_date_monthly: null,
            fullStatisticsUserChart: {
                series: [],
                chartOptions: {
                    colors: [
                        '#006f62',
                        '#a7204c',
                        '#f87002',
                        '#e17dab',
                        '#0097df',
                        '#739373',
                        '#002296',
                        '#b03966',
                        '#057f5f'
                    ],
                    chart: {
                        id: 'game-users-chart',
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 700
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true
                                    }
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 4,
                            hideZeroBarsWhenGrouped: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        color: '#28c76f',
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 6
                    },
                    fill: {
                        opacity: 1
                    }
                }
            },
            fullStatisticsCoinChart: {
                series: [],
                chartOptions: {
                    colors: [
                        '#006f62',
                        '#a7204c',
                        '#f87002',
                        '#e17dab',
                        '#0097df',
                        '#739373',
                        '#002296',
                        '#b03966',
                        '#057f5f'
                    ],
                    chart: {
                        id: 'game-users-chart',
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 700
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true
                                    }
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 4,
                            hideZeroBarsWhenGrouped: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        color: '#28c76f',
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 6
                    },
                    fill: {
                        opacity: 1
                    }
                }
            },
            gameUserChart: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                loaded: false,
                series: [],
                labels: [],
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            gameCoinChart: {
                loaded: false,
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                series: [],
                labels: [],
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            windowWidth: window.innerWidth
        };
    },

    async created() {
        await this.fixMonthlyStatisticsDates();
        await this.getDatesForParam();
        await Promise.allSettled([this.getFullStatisticsByDays(), this.getGamesTotalUsers()]);
        // await this.changeChartType();
    },

    methods: {
        formatNumber,
        statisticsFormatDate,

        async fixMonthlyStatisticsDates() {
            // const date = new Date();
            // const year = date.getUTCFullYear(); // Get the full year (4 digits)
            // const currentMonth = date.getUTCMonth() + 1; // Month is zero-based, so add 1
            // const beforeMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Adjust if current month is January
            // const beforeYear = currentMonth === 1 ? year - 1 : year; // Adjust year accordingly

            // const pad = (number) => String(number).padStart(2, '0'); // Padding function

            // const day = pad(date.getUTCDate()); // Pad day with leading zero if necessary
            // const formattedBeforeMonth = pad(beforeMonth); // Pad beforeMonth with leading zero if necessary
            // const formattedCurrentMonth = pad(currentMonth); // Pad currentMonth with leading zero if necessary

            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.start_date_monthly = this.statisticsFormatDate(date_start);
            this.end_date_monthly = this.statisticsFormatDate(date_end);
        },

        changeChartType() {
            if (this.windowWidth < 576) {
                this.fullStatisticsUserChart.chartOptions.plotOptions.bar.horizontal = true;
                this.fullStatisticsCoinChart.chartOptions.plotOptions.bar.horizontal = true;
            } else {
                this.fullStatisticsUserChart.chartOptions.plotOptions.bar.horizontal = false;
                this.fullStatisticsCoinChart.chartOptions.plotOptions.bar.horizontal = false;
            }
        },

        async getDatesForParam() {
            const date = new Date();
            const currentYear = date.getUTCFullYear(); // Get the full year (4 digits)
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const currentDay = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.start_date = `${currentYear}-${currentMonth}-${currentDay}`;
            this.end_date = `${currentYear}-${currentMonth}-${currentDay}`;
        },

        async getGamesTotalUsers() {
            const body = {
                merchant_id: this.merchant_id,
                start_date: this.start_date,
                end_date: this.end_date
            };
            this.gameUserChart.series.length = 0;
            this.gameUserChart.labels.length = 0;
            this.gameCoinChart.series.length = 0;
            this.gameCoinChart.labels.length = 0;
            this.dailyTotalUsers = 0;
            this.dailyTotalCoinsGained = 0;

            const { data } = await services.games.fetchGamesTotalUsers(body);
            const response = data.result.games;
            this.totalUsers = data.result.total.users;
            this.totalGainedCoin = data.result.total.coin_gained;

            response.forEach((item) => {
                this.gameUserChart.labels.push(
                    `<h5 style="color: #fff;">${item.name}</h5> Users count`
                );
                this.gameUserChart.series.push(item.users_count);
                this.dailyTotalUsers += item.users_count;
            });

            response.forEach((item) => {
                this.gameCoinChart.labels.push(
                    `<h5 style="color: #fff;">${item.name}</h5> Coin gained`
                );
                this.gameCoinChart.series.push(item.coin_gained);
                this.dailyTotalCoinsGained += item.coin_gained;
            });
            this.statisticsByGame = response;
        },

        async getFullStatisticsByHours() {
            const body = {
                merchant_id: this.merchant_id,
                start_date: this.start_date,
                end_date: this.end_date
            };
            const { data } = await services.games.fetchFullStatistics(body);
            const response = data.result;
            this.fullStatisticsUserChart.series = response.map((item) => {
                return {
                    name: item.name,
                    data: item.history.map((child) => {
                        return child['users_played'];
                    })
                };
            });
            this.fullStatisticsCoinChart.series = response.map((item) => {
                return {
                    name: item.name,
                    data: item.history.map((child) => {
                        return child['total_coin_gained'];
                    })
                };
            });
            response[0].history.forEach((childItem) => {
                this.fullStatisticsUserChart.chartOptions.xaxis.categories.push(
                    `${formatDateToYMD(childItem.date)} ${formatDateToHM(childItem.date)}`
                );
            });
            response[0].history.forEach((childItem) => {
                this.fullStatisticsCoinChart.chartOptions.xaxis.categories.push(
                    `${formatDateToYMD(childItem.date)} ${formatDateToHM(childItem.date)}`
                );
            });
        },

        async getFullStatisticsByDays() {
            const body = {
                merchant_id: this.merchant_id,
                start_date: this.start_date_monthly,
                end_date: this.end_date_monthly
            };
            this.fullStatisticsUserChart.chartOptions.xaxis.categories.length = 0;
            this.fullStatisticsCoinChart.chartOptions.xaxis.categories.length = 0;
            const { data } = await services.games.fetchFullStatisticsByDay(body);
            const response = data.result;

            response[0].history.forEach((childItem) => {
                const date = new Date(childItem.date);
                this.fullStatisticsUserChart.chartOptions.xaxis.categories.push(
                    `${prefixZeroToNum(date.getDate())}.${prefixZeroToNum(date.getMonth() + 1)}`
                );

                this.fullStatisticsCoinChart.chartOptions.xaxis.categories.push(
                    `${prefixZeroToNum(date.getDate())}.${prefixZeroToNum(date.getMonth() + 1)}`
                );
            });

            this.fullStatisticsUserChart.chartOptions.xaxis.categories.reverse();
            this.fullStatisticsCoinChart.chartOptions.xaxis.categories.reverse();

            this.fullStatisticsUserChart.series = response.map((item) => {
                return {
                    name: item.name,
                    data: item.history.reverse().map((child) => {
                        return child['users_played'];
                    })
                };
            });

            this.fullStatisticsCoinChart.series = response.map((item) => {
                return {
                    name: item.name,
                    data: item.history.map((child) => {
                        return child['total_coin_gained'];
                    })
                };
            });
        },

        resetParams() {
            this.start_date = null;
            this.end_date = null;
            this.getGamesTotalUsers();
        },

        resetMonthlyParams() {
            this.start_date_monthly = null;
            this.end_date_monthly = null;
            this.getFullStatisticsByDays();
        }
    }
};
</script>

<template>
    <section class="game">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card total-users d-flex flex-row align-items-center p-2">
                    <b-avatar variant="light-success" size="lg" class="mr-1">
                        <feather-icon icon="UsersIcon" size="28" />
                    </b-avatar>
                    <div>
                        <p>{{ $t('game_history.total_users') }}</p>
                        <span>{{ totalUsers }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card total-users d-flex flex-row align-items-center p-2">
                    <b-avatar variant="light-warning" size="lg" class="mr-1">
                        <feather-icon icon="DollarSignIcon" size="28" />
                    </b-avatar>
                    <div>
                        <p>{{ $t('game_history.coin_gained') }}</p>
                        <span>{{ totalGainedCoin }}</span>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-1">
                    <h3 class="mb-1">
                        {{ $t('game_history.filter') }} - [{{
                            $t('game_history.monthly_statistics')
                        }}]
                    </h3>
                    <div class="row align-items-end">
                        <div class="col-12 col-md-10">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                                    <flat-pickr
                                        v-model="start_date_monthly"
                                        :config="{
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        class="form-control"
                                        name="date"
                                        id="start_date"
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                                    <flat-pickr
                                        v-model="end_date_monthly"
                                        :config="{
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        class="form-control"
                                        name="date"
                                        id="end_date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 mt-1 mt-md-0">
                            <div class="row">
                                <div class="col-6">
                                    <b-button
                                        @click="resetMonthlyParams"
                                        variant="outline-danger"
                                        size="sm"
                                        class="w-100 px-0"
                                    >
                                        <feather-icon icon="XIcon" size="22" />
                                    </b-button>
                                </div>
                                <div class="col-6">
                                    <b-button
                                        @click="getFullStatisticsByDays"
                                        variant="outline-primary"
                                        size="sm"
                                        class="w-100 px-0"
                                    >
                                        <feather-icon icon="SearchIcon" size="22" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-2">
                    <h3 class="mb-1">{{ $t('titles.game_user_statistics') }}</h3>
                    <apexchart
                        id="game-users-chart"
                        ref="fullStatChart"
                        height="400"
                        type="bar"
                        :options="fullStatisticsUserChart.chartOptions"
                        :series="fullStatisticsUserChart.series"
                    ></apexchart>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-2">
                    <h3 class="mb-1">{{ $t('titles.game_coin_statistics') }}</h3>
                    <apexchart
                        id="game-coins-chart"
                        ref="fullStatCoinChart"
                        height="400"
                        type="bar"
                        :options="fullStatisticsCoinChart.chartOptions"
                        :series="fullStatisticsCoinChart.series"
                    ></apexchart>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-1">
                    <h3 class="mb-1">
                        {{ $t('game_history.filter') }} - [{{
                            $t('game_history.daily_statistics')
                        }}]
                    </h3>
                    <div class="row align-items-end">
                        <div class="col-12 col-md-10">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                                    <flat-pickr
                                        v-model="start_date"
                                        :config="{
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        class="form-control"
                                        name="date"
                                        id="start_date"
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                                    <flat-pickr
                                        v-model="end_date"
                                        :config="{
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        class="form-control"
                                        name="date"
                                        id="end_date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 mt-1 mt-md-0">
                            <div class="row">
                                <div class="col-6">
                                    <b-button
                                        @click="resetParams"
                                        variant="outline-danger"
                                        size="sm"
                                        class="w-100 px-0"
                                    >
                                        <feather-icon icon="XIcon" size="22" />
                                    </b-button>
                                </div>
                                <div class="col-6">
                                    <b-button
                                        @click="getGamesTotalUsers"
                                        variant="outline-primary"
                                        size="sm"
                                        class="w-100 px-0"
                                    >
                                        <feather-icon icon="SearchIcon" size="22" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="card p-1">
                            <h3 class="mb-1">{{ $t('game_history.users_stat_title') }}</h3>
                            <apexchart :options="gameUserChart" :series="gameUserChart.series" />
                            <div class="badge-parent mt-1">
                                <b-badge
                                    variant="light-secondary"
                                    class="text-nowrap mb-1"
                                    v-for="(item, index) in statisticsByGame"
                                    :key="index"
                                >
                                    <span class="d-block"
                                        >{{ $t('titles.game') }}:
                                        <b-badge variant="light-primary" style="font-size: 12px">{{
                                            item.name
                                        }}</b-badge></span
                                    >
                                    <div>
                                        <span v-if="item.users_count" class="d-block"
                                            >{{ $t('game_history.played') }}:
                                            <b-badge variant="light-success" style="font-size: 12px"
                                                >{{ formatNumber(item.users_count) }}
                                            </b-badge>
                                            {{ $t('game_history.times') }}</span
                                        >
                                        <span v-else class="d-block"
                                            >{{ $t('game_history.played') }}:
                                            <b-badge variant="light-success" style="font-size: 14px"
                                                >0</b-badge
                                            >
                                            {{ $t('game_history.times') }}</span
                                        >
                                    </div>
                                </b-badge>
                            </div>
                            <b-badge variant="light-success" class="text-nowrap mb-1">
                                <span class="d-block"
                                    >{{ $t('game_history.total_played') }}:
                                    <b-badge variant="success" style="font-size: 14px">{{
                                        dailyTotalUsers
                                    }}</b-badge>
                                    {{ $t('game_history.times') }}</span
                                >
                            </b-badge>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="card p-1">
                            <h3 class="mb-1">{{ $t('game_history.coin_gaining_stat_title') }}</h3>
                            <apexchart :options="gameCoinChart" :series="gameCoinChart.series" />
                            <div class="badge-parent mt-1">
                                <b-badge
                                    variant="light-secondary"
                                    class="text-nowrap mb-1"
                                    v-for="(item, index) in statisticsByGame"
                                    :key="index"
                                >
                                    <span class="d-block"
                                        >{{ $t('titles.game') }}:
                                        <b-badge variant="light-primary" style="font-size: 12px">{{
                                            item.name
                                        }}</b-badge></span
                                    >
                                    <div>
                                        <span v-if="item.coin_gained" class="d-block"
                                            >FIT-Coin:
                                            <b-badge
                                                variant="light-success"
                                                style="font-size: 14px"
                                                >{{ formatNumber(item.coin_gained) }}</b-badge
                                            ></span
                                        >
                                        <span v-else class="d-block"
                                            >FIT-Coin:
                                            <b-badge variant="light-success" style="font-size: 14px"
                                                >0</b-badge
                                            ></span
                                        >
                                    </div>
                                </b-badge>
                            </div>
                            <b-badge variant="light-success" class="text-nowrap mb-1">
                                <span class="d-block"
                                    >{{ $t('titles.total_daily_coins_gained') }}:
                                    <b-badge variant="success" style="font-size: 14px">{{
                                        dailyTotalCoinsGained
                                    }}</b-badge></span
                                >
                            </b-badge>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
.total-users {
    & p {
        font-size: 16px;
        margin-bottom: 4px;
    }

    & span {
        font-size: 18px;
        font-weight: 700;
    }
}

.badge span {
    margin-bottom: 4px;
}

.badge-parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;

    & span {
        padding-bottom: 4px;
    }

    & span.badge {
        padding-bottom: 0;
    }

    & span.text-nowrap {
        padding-top: 1rem;
    }
}

@media only screen and (max-width: 1100px) {
    .badge-parent {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 0.5rem;
    }
}
</style>
