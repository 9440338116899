import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class AdministrationUcell extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchStatus(body) {
        return this.post('ucell/status', body);
    }

    fetchPayPeriod(body) {
        return this.post('ucell/payPeriod', body);
    }

    fetchBalance(body) {
        return this.post('ucell/balance', body);
    }

    fetchSaleDateStatus(body) {
        return this.post('ucell/saleDateStatus', body);
    }

    fetchPackList(body) {
        return this.post('ucell/packList', body);
    }
}

export default AdministrationUcell;
