import api from '@/services/api';

export default {
    namespaced: true,
    state: {
        me: {}
    },
    getters: {
        userId: (state) => state.me.id
    },
    mutations: {
        initMe(state, { me }) {
            state.me = me;
        }
    },
    actions: {
        async getUserMe({ commit }) {
            const { data: me } = await api.auth.authMeApi();
            commit('initMe', { me });
        }
    }
};
