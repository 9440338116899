<script>
export default {
    name: 'SubscribersSingleRoadmap',
    data() {
        return {
            tabs: [
                {
                    name: 'subscriber_roadmap_info',
                    routeName: 'subscriber-roadmap-info',
                    value: 1
                },
                {
                    name: 'subscriber_roadmap_score',
                    routeName: 'subscriber-roadmap-score',
                    value: 2
                },
                {
                    name: 'subscriber_roadmap_product_history',
                    routeName: 'subscriber-roadmap-product-history',
                    value: 3
                }
            ]
        };
    },
    computed: {
        currentRouteName() {
            return this.$route.routeName;
        }
    }
};
</script>

<template>
    <div class="roadmap">
        <div class="nav nav-tabs flex-nowrap overflow-x-scroll">
            <router-link
                v-for="(route, index) in tabs"
                :key="index"
                :to="{ name: route.routeName }"
                active-class="active"
                class="nav-link"
                :class="currentRouteName === route.routeName ? 'active' : ''"
                style="min-width: fit-content"
            >
                {{ $t(`titles.${route.name}`) }}
            </router-link>
        </div>
        <router-view />
    </div>
</template>

<style scoped lang="scss">
.nav-tabs::-webkit-scrollbar {
    display: none;
}
</style>
