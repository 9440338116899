import { render, staticRenderFns } from "./AppUSSDTexts.vue?vue&type=template&id=f521a862&scoped=true&"
import script from "./AppUSSDTexts.vue?vue&type=script&lang=js&"
export * from "./AppUSSDTexts.vue?vue&type=script&lang=js&"
import style0 from "./AppUSSDTexts.vue?vue&type=style&index=0&id=f521a862&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f521a862",
  null
  
)

export default component.exports