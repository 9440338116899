import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class ProductCategory extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllCategories(params) {
        return this.post('category/findAll', params);
    }

    fetchOneCategory(params) {
        return this.post('category/findOne', params);
    }

    createCategory(body) {
        return this.post('category', body);
    }

    updateCategory(body) {
        return this.post('category/update', body);
    }

    deleteCategory(body) {
        return this.post('category/remove', body);
    }
}

export default ProductCategory;
