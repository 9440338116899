import Core from '@/services/axios/core.axios';
import { axiosV2 } from '@/services/core/base';

class ChatService extends Core {
    constructor() {
        super({
            axios: axiosV2({})
        });
    }

    getMessages({ params, chatId }) {
        return this.get(`chats/${chatId}/messages`, {
            params
        });
    }

    removeMessage({ chatId, messageId, merchant_id }) {
        return this.delete(`chats/${chatId}/removeMessage/${messageId}`, {
            params: {
                merchant_id
            }
        });
    }

    updateMessage({ chatId, messageId, body }) {
        return this.put(`chats/${chatId}/updateMessage/${messageId}`, body);
    }

    getChats({ params }) {
        return this.get('chats', {
            params
        });
    }

    getLatestFetchTime({ params }) {
        return this.get('chats/updated', {
            params
        });
    }

    getContacts({ params }) {
        return this.get('chats/clients', { params });
    }

    sendMessage({ chatId, body }) {
        return this.post(`chats/${chatId}/sendMessages`, body);
    }

    getChatIdByClientId({ body, params }) {
        return this.post(`chats`, body, {
            params
        });
    }

    replyMessage({ body }) {
        return this.post('reply-message', body);
    }

    sendFile({ body }) {
        return this.post('send-file', body);
    }

    replyFile({ body }) {
        return this.post('reply-file', body);
    }

    getUser({ params }) {
        return this.get(`chats/user`, {
            params
        });
    }

    checkUnreadMessages({ params }) {
        return this.get('chats/messages/unreads', {
            params
        });
    }
}

export const chatApi = new ChatService();
