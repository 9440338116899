<script>
import vSelect from 'vue-select';
import { BButton, BCol, BFormCheckbox, BFormGroup, BRow } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import flatPickr from 'vue-flatpickr-component';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { isArray, isObject } from '@/util/inspect.util';
import services from '@/services';
import { checkLang } from '@/util/helper';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'AppStockTemplatesCRUD',
    components: {
        ProgressLinear,
        BFormCheckbox,
        BCol,
        BFormGroup,
        BButton,
        BRow,
        vSelect,
        quillEditor,
        flatPickr
    },
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],

                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],

                        [{ color: [] }, { background: [] }],
                        [{ align: [] }]
                    ]
                }
            },
            form: {
                categories: [],
                start_date: '',
                end_date: '',
                is_active: false,
                package_double: false,
                ntf_template: null,
                sms_template: '',
                images: [],
                modal_title: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                modal_description: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                title: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                description: {
                    ru: '',
                    en: '',
                    uz: ''
                }
            },
            categoriesList: [],
            smsList: [],
            notificationsList: [],
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            requesting: false
        };
    },

    watch: {
        uploadImage(image) {
            this.form.images = [];
            if (isArray(image)) {
                this.form.images = image;
            } else if (isObject(image)) {
                this.form.images.push(image);
            }
        }
    },

    async mounted() {
        this.requesting = true;
        await this.getStockCategories();
        await this.getSMSList();
        await this.getNotificationList();
        if (this.$route.params.id) {
            await this.getOneStockTemplate();
        }
        this.requesting = false;
    },

    methods: {
        checkLang,
        async getStockCategories() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                page: 1,
                limit: 500
            };
            try {
                const { data } = await api.stockCategoryApi.fetchAllItems(body);
                this.categoriesList = data.result;
            } catch (e) {
                console.error(e);
            }
        },

        async getSMSList() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                page: 1,
                limit: 100
            };
            try {
                const { data } = await services.sms.findAll(body);
                this.smsList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getNotificationList() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                page: 1,
                limit: 100
            };
            try {
                const { data } = await api.notificationTemplatesApi.fetchAllItems(body);
                this.notificationsList = data.result;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneStockTemplate() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                id: this.$route.params.id
            };
            try {
                const { data } = await api.stockTemplateApi.fetchOneItem(body);
                this.form = data.result;
                this.form.categories = data.result['templateCategories'].map((item) => {
                    if (item.category) {
                        return item.category.id;
                    }
                });
                const image = data.result.image;
                if (image) {
                    this.uploadImage = {
                        id: image.id,
                        name: image.name,
                        size: image.size,
                        type: 'image/jpg',
                        url: image.path,
                        src: image.path
                    };
                }
                const propertiesToRemove = [
                    'created_at',
                    'updated_at',
                    'deleted_at',
                    'templateCategories'
                ];
                propertiesToRemove.forEach((property) => {
                    delete this.form[property];
                });
            } catch (e) {
                console.error(e);
            }
        },

        async createStockTemplate() {
            const body = this.form;
            body.merchant_id = parseInt(MERCHANT_ID);
            body.image = body.images[0].id;
            delete body.images;
            try {
                await api.stockTemplateApi.createItem(body);
                await this.$router.push({ name: 'stock-templates' });
                this.$toast.success(this.$t('success.added'), { position: 'top-right' });
            } catch (e) {
                console.error(e);
            }
        },

        async updateStockTemplate() {
            const body = this.form;
            body.merchant_id = parseInt(MERCHANT_ID);
            if (body.image) {
                body.image = body.images[0].id;
            }
            try {
                await api.stockTemplateApi.updateItem(body);
                await this.$router.push({ name: 'stock-templates' });
                this.$toast.success(this.$t('success.updated.title'), { position: 'top-right' });
            } catch (e) {
                console.error(e);
            }
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.form.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('stock_templates', formData, config)
                        .then(({ data }) => {
                            this.form.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        }
    }
};
</script>

<template>
    <div class="templates">
        <progress-linear v-if="requesting" />
        <div v-else>
            <div class="px-0 col-12 d-flex justify-content-between align-items-center">
                <h2>
                    {{ $t('titles.stock_templates') }}
                </h2>
                <b-button
                    :to="{ name: 'stock-templates' }"
                    variant="outline-primary"
                    class="mb-2"
                    >{{ $t('button.back') }}</b-button
                >
            </div>
            <ValidationObserver ref="createUpdatePage">
                <b-row>
                    <!--   TITLE [UZ]   -->
                    <div class="col-12 col-lg-4">
                        <label> {{ $t('titles.real_title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="form.title.uz"
                                    :placeholder="`${$t('enter.real_title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   TITLE [RU]   -->
                    <div class="col-12 col-lg-4">
                        <label> {{ $t('titles.real_title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="form.title.ru"
                                    :placeholder="`${$t('enter.real_title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   TITLE [EN]   -->
                    <div class="col-12 col-lg-4">
                        <label> {{ $t('titles.real_title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="form.title.en"
                                    :placeholder="`${$t('enter.real_title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--    DESCRIPTION[UZ]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.description') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <quill-editor
                                    v-model="form.description.uz"
                                    :options="editorOption"
                                />
                                <!--                                @change="change"-->
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[RU]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.description') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <quill-editor
                                    v-model="form.description.ru"
                                    :options="editorOption"
                                />
                                <!--                                @change="change"-->
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[EN]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.description') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <quill-editor
                                    v-model="form.description.en"
                                    :options="editorOption"
                                />
                                <!--                                @change="change"-->
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    MODAL TITLE[UZ]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.modal_title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.modal_title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <quill-editor
                                    v-model="form.modal_title.uz"
                                    :options="editorOption"
                                />
                                <!--                                @change="change"-->
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    MODAL TITLE[RU]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.modal_title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.modal_title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <quill-editor
                                    v-model="form.modal_title.ru"
                                    :options="editorOption"
                                />
                                <!--                                @change="change"-->
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    MODAL TITLE[EN]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.modal_title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.modal_title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <quill-editor
                                    v-model="form.modal_title.en"
                                    :options="editorOption"
                                />
                                <!--                                @change="change"-->
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   MODAL DESCRIPTION [UZ]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.modal_description') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.modal_description')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="form.modal_description.uz"
                                    :placeholder="`${$t('enter.modal_description')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   MODAL DESCRIPTION [RU]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.modal_description') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.modal_description')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="form.modal_description.ru"
                                    :placeholder="`${$t('enter.modal_description')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   MODAL DESCRIPTION [EN]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.modal_description') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.modal_description')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="form.modal_description.en"
                                    :placeholder="`${$t('enter.modal_description')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--     SMS TEMPLATE     -->
                    <div class="col-12 col-lg-6 mb-1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.sms_template')"
                            rules="required"
                        >
                            <label>{{ $t('titles.sms_template') }}</label>
                            <b-form-group>
                                <v-select
                                    :placeholder="$t('choose.sms_template')"
                                    id="types"
                                    :options="smsList"
                                    v-model="form.sms_template"
                                    :reduce="(option) => option.name"
                                    label="name"
                                    :clearable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--     NTF TEMPLATE     -->
                    <div class="col-12 col-lg-6 mb-1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.ntf_template')"
                            rules="required"
                        >
                            <label>{{ $t('titles.ntf_template') }}</label>
                            <b-form-group>
                                <v-select
                                    :placeholder="$t('choose.notification_template')"
                                    id="types"
                                    :options="notificationsList"
                                    v-model="form.ntf_template"
                                    :reduce="(option) => option.id"
                                    label="title"
                                    :clearable="false"
                                >
                                    <template v-slot:option="option">
                                        {{ checkLang(option.title) }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ checkLang(option.title) }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--     CATEGORIES     -->
                    <div class="col-12 col-lg-3">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.stock_category')"
                            rules="required"
                        >
                            <label>{{ $t('titles.stock_category') }}</label>
                            <b-form-group>
                                <v-select
                                    :placeholder="$t('titles.stock_category')"
                                    id="types"
                                    :options="categoriesList"
                                    v-model="form.categories"
                                    :reduce="(option) => option.id"
                                    label="name"
                                    :clearable="false"
                                    :multiple="true"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--     START DATE     -->
                    <div class="col-12 col-lg-3">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.start_date')"
                            rules="required"
                        >
                            <label>{{ $t('titles.start_date') }}</label>
                            <flat-pickr
                                v-model="form.start_date"
                                :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                class="form-control"
                                name="date"
                                id="start_date"
                                autocomplete="off"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--     END DATE     -->
                    <div class="col-12 col-lg-3">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.end_date')"
                            rules="required"
                        >
                            <label>{{ $t('titles.end_date') }}</label>
                            <flat-pickr
                                v-model="form.end_date"
                                :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                class="form-control"
                                name="date"
                                id="end_date"
                                autocomplete="off"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <div class="col-12 col-lg-3 d-flex align-items-center justify-content-around">
                        <!--    IS ACTIVE    -->
                        <b-col>
                            <label for="is_active">{{ $t('titles.is_active') }}</label>
                            <b-form-checkbox id="is_active" v-model="form.is_active" />
                        </b-col>

                        <!--    PACKAGE DOUBLE    -->
                        <b-col>
                            <label for="package_double">{{ $t('titles.package_double') }}</label>
                            <b-form-checkbox id="package_double" v-model="form.package_double" />
                        </b-col>
                    </div>

                    <!--      UPLOAD     -->
                    <div class="w-100 mb-4 mt-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <VueFileAgent
                                ref="vueFileAgent"
                                v-model="uploadImage"
                                :accept="'image/*,.zip'"
                                :deletable="true"
                                :errorText="{
                                    type: $t('vue_file_agent.file_type_error'),
                                    size: $t('vue_file_agent.size_type_error')
                                }"
                                :helpText="$t('vue_file_agent.choose_image')"
                                :maxFiles="14"
                                :maxSize="'10MB'"
                                :meta="true"
                                :multiple="false"
                                :theme="'grid'"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                @select="filesSelected($event)"
                            >
                            </VueFileAgent>

                            <b-button
                                :disabled="!fileRecordsForUpload.length || imageUploaded"
                                :variant="imageUploaded ? 'success' : 'primary'"
                                class="float-right mt-1"
                                @click="getImageId()"
                            >
                                <span v-if="!imageUploaded">
                                    {{ $t('vue_file_agent.upload_image') }}
                                </span>
                                <span v-else>
                                    {{ $t('vue_file_agent.image_uploaded') }}
                                </span>
                            </b-button>
                        </b-col>
                    </div>

                    <div class="col-12 mb-4 d-flex justify-content-center">
                        <b-button
                            v-if="!$route.params.id"
                            variant="success"
                            @click="createStockTemplate"
                            >{{ $t('button.create') }}</b-button
                        >
                        <b-button v-else variant="success" @click="updateStockTemplate">{{
                            $t('button.save')
                        }}</b-button>
                    </div>
                </b-row>
            </ValidationObserver>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
