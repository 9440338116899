<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BFormInput, BPagination, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
// import AppFlashHistoryCards from '@/views/others/flash-history/components/AppFlashHistoryCards.vue';
import services from '@/services';
import AppFlashHistoryGraph from '@/views/others/flash-history/components/AppFlashHistoryGraph.vue';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppFlashHistory',
    components: {
        BFormInput,
        AppFlashHistoryGraph,
        // AppFlashHistoryCards,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 30
            },
            sort: {
                column: 'id',
                order: 'down'
            },
            search: {
                phone: null
            },
            orderList: ['up', 'down'],
            columnList: [
                'id',
                'status',
                'package_qty',
                'package_cost',
                'date_start_at',
                'date_end_at',
                'is_app',
                'is_ussd',
                'coin'
            ],
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getFlashHistory();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id')
                },
                {
                    key: 'phone',
                    label: this.$t('titles.phone'),
                    stickyColumn: true,
                    variant: 'dark'
                },
                {
                    key: 'package_cost',
                    label: this.$t('flash_history.package_cost')
                },
                {
                    key: 'coin',
                    label: 'FIT-Coin'
                },
                {
                    key: 'package_qty',
                    label: 'pack quantity'
                },
                {
                    key: 'is_app',
                    label: 'app'
                },
                {
                    key: 'is_ussd',
                    label: 'ussd'
                },
                {
                    key: 'status',
                    label: this.$t('titles.status')
                },
                {
                    key: 'date_accept',
                    label: this.$t('titles.date_accept')
                },
                {
                    key: 'date_start_at',
                    label: this.$t('titles.date_start_at')
                },
                {
                    key: 'date_end_at',
                    label: this.$t('titles.date_end_at')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },
        regionOptionList() {
            const locale = this.$i18n.locale;
            return this.regionOptions.map((option) => {
                return {
                    ...option,
                    label: option.name[locale]
                };
            });
        }
    },

    methods: {
        checkLang,

        async getFlashHistory() {
            this.requestPending = true;
            const params = {
                merchant_id: MERCHANT_ID,
                page: this.pagination.current,
                limit: this.pagination.perPage,
                sort: {
                    column: this.sort.column,
                    order: this.sort.order
                },
                search: {
                    phone: this.search.phone
                }
            };
            await services.flash
                .fetchHistory(params)
                .then((response) => {
                    this.items = response.data.data.list;
                    this.pagination = response.data.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.requestPending = false;
                });
        },

        async clearPhoneField() {
            this.search.phone = '';
            await this.getFlashHistory();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.flash_history') }}</template>
        </page-title>

        <AppFlashHistoryGraph />

        <!--        <AppFlashHistoryCards class="mb-4 mt-3" />-->

        <div class="row align-items-end justify-content-between mb-2 px-1">
            <div class="col-12 col-md-6 col-lg-5 col-xl-4 d-flex align-items-center mb-2 mb-md-0">
                <b-form-input
                    class="mr-1"
                    v-model="search.phone"
                    id="phone"
                    :placeholder="$t('titles.phone')"
                />
                <b-button
                    v-if="search.phone"
                    @click="clearPhoneField"
                    variant="outline-danger"
                    class="mr-1 px-1"
                >
                    <feather-icon icon="XIcon" size="16" />
                </b-button>
                <b-button @click="getFlashHistory" variant="outline-primary" class="px-1">
                    <feather-icon icon="SearchIcon" size="16" />
                </b-button>
            </div>

            <div
                class="col-12 col-md-6 px-0 d-flex flex-wrap align-items-center justify-content-end"
            >
                <div class="col-6">
                    <label for="column">Column</label>
                    <v-select
                        id="column"
                        v-model="sort.column"
                        :options="columnList"
                        append-to-body
                        style="width: 180px"
                        @input="getFlashHistory"
                        :clearable="false"
                        class="w-100"
                    />
                </div>
                <div class="col-6">
                    <label for="order">Order</label>
                    <v-select
                        id="order"
                        v-model="sort.order"
                        :options="orderList"
                        append-to-body
                        style="width: 180px"
                        @input="getFlashHistory"
                        :clearable="false"
                        class="w-100"
                    />
                </div>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="flash__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(phone)="{ item }">
                    <span class="text-white fw-700">{{ item.phone }}</span>
                </template>

                <template #cell(date_accept)="{ item }">
                    <div style="min-width: 80px">{{ item.date_accept }}</div>
                </template>

                <template #cell(date_start_at)="{ item }">
                    <div style="min-width: 100px">{{ item.date_start_at }}</div>
                </template>

                <template #cell(date_end_at)="{ item }">
                    <div style="min-width: 100px">{{ item.date_end_at }}</div>
                </template>

                <template #cell(is_app)="{ item }">
                    <feather-icon
                        :class="[item.is_app ? 'text-success' : 'text-danger']"
                        :icon="item.is_app ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_ussd)="{ item }">
                    <feather-icon
                        :class="[item.is_ussd ? 'text-success' : 'text-danger']"
                        :icon="item.is_ussd ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-5" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.fw-700 {
    font-weight: 700;
}
</style>
