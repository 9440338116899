import Core from '@/services/core';
import { axiosInstance } from '@/services/core/base';

export class PaynetStatistics extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }

    getStatsCountSuccessApi() {
        return this._axios.get(this.url + '/count');
    }

    getStatsCountFailApi() {
        return this._axios.get(this.url + '/count/failed');
    }

    getStatsAmountSuccessApi() {
        return this._axios.get(this.url + '/amount');
    }

    getStatsAmountFailApi() {
        return this._axios.get(this.url + '/amount/failed');
    }

    getTransactionsApi(params) {
        return this._axios.get(this.url + '/transactions', { params });
    }

    getAppsApi() {
        return this._axios.get(this.url + '/get/apps');
    }
}

export class PaynetServices extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }

    getServicesApi() {
        return this.get();
    }

    createServiceApi(payload) {
        return this.post(payload);
    }

    deleteServiceApi(id) {
        return this.delete(id);
    }

    updateServiceApi(id, payload) {
        return this.put(id, payload);
    }
}
