import NewChapter from '@/views/new-chapter/index.vue';
import AppSubscription from '@/views/new-chapter/subscription/AppSubscription.vue';
import AppRandomizer from '@/views/new-chapter/randomizer/index.vue';
import ViewSubscriptionSettings from '@/views/new-chapter/subscription/views/ViewSubscriptionSettings.vue';
import AppBilling from '@/views/new-chapter/billing/index.vue';
import AppBillingPackages from '@/views/new-chapter/billing/packages/index.vue';
import AppBillingCharges from '@/views/new-chapter/billing/charges/index.vue';
import AppBillingRates from '@/views/new-chapter/billing/rate/index.vue';
import ViewBillingPackagesSettings from '@/views/new-chapter/billing/packages/views/ViewBillingPackagesSettings.vue';
import ViewBillingChargesSettings from '@/views/new-chapter/billing/charges/views/ViewBillingChargesSettings.vue';
import ViewBillingRateSettings from '@/views/new-chapter/billing/rate/views/ViewBillingRateSettings.vue';
import AppRandomizerInfinityAssortment from '@/views/new-chapter/randomizer/infinity/index.vue';
import ViewInfinityGroups from '@/views/new-chapter/randomizer/infinity/views/ViewInfinityGroups.vue';
import ViewVIPProducts from '@/views/new-chapter/randomizer/infinity/views/ViewVIPProducts.vue';
import ViewRandomizerCategories from '@/views/new-chapter/randomizer/views/ViewRandomizerCategories.vue';
import ViewRandomizerCategorySteps from '@/views/new-chapter/randomizer/views/ViewRandomizerCategorySteps.vue';
import AppPurchases from '@/views/new-chapter/purchases/index.vue';
import ViewHistoryPurchases from '@/views/new-chapter/purchases/views/ViewHistoryPurchases.vue';
import ViewRandomizerCategoriesFlash from '@/views/new-chapter/randomizer/views/ViewRandomizerCategoriesFlash.vue';
import ViewFastCharges from '@/views/new-chapter/randomizer/views/ViewFastCharges.vue';
import ViewRandomizerCategoriesStock from '@/views/new-chapter/randomizer/views/ViewRandomizerCategoriesStock.vue';
import ViewRoadmap from '@/views/new-chapter/randomizer/views/ViewRoadmap.vue';
import ViewSandbox from '@/views/new-chapter/randomizer/views/ViewSandbox.vue';
import AppBundle from '@/views/new-chapter/bundle/AppBundle.vue';

export default [
    {
        path: '/new-chapter',
        component: NewChapter,
        children: [
            {
                path: 'billing',
                component: AppBilling,
                // meta: {
                //     section: 'collector',
                //     permission: 'collector'
                // },
                children: [
                    {
                        path: 'packages',
                        component: AppBillingPackages,
                        // meta: {
                        //     section: 'collector',
                        //     permission: 'collector'
                        // },
                        children: [
                            {
                                path: 'settings',
                                name: 'billing-packages-settings',
                                component: ViewBillingPackagesSettings,
                                meta: {
                                    section: 'collector',
                                    permission: 'collector'
                                }
                            }
                        ]
                    },
                    {
                        path: 'charges',
                        component: AppBillingCharges,
                        // meta: {
                        //     section: 'collector',
                        //     permission: 'collector'
                        // },
                        children: [
                            {
                                path: 'settings',
                                name: 'billing-charges-settings',
                                component: ViewBillingChargesSettings,
                                meta: {
                                    section: 'collector',
                                    permission: 'collector'
                                }
                            }
                        ]
                    },
                    {
                        path: 'rates',
                        component: AppBillingRates,
                        // meta: {
                        //     section: 'collector',
                        //     permission: 'collector'
                        // },
                        children: [
                            {
                                path: 'settings',
                                name: 'billing-rates-settings',
                                component: ViewBillingRateSettings,
                                meta: {
                                    section: 'collector',
                                    permission: 'collector'
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'subscription',
                name: 'subscription',
                component: AppSubscription,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                },
                children: [
                    {
                        path: 'settings',
                        name: 'subscription-settings',
                        component: ViewSubscriptionSettings,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    }
                ]
            },
            {
                path: 'randomizer',
                name: 'randomizer',
                component: AppRandomizer,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                },
                children: [
                    {
                        path: 'infinity',
                        name: 'infinity',
                        component: AppRandomizerInfinityAssortment,
                        // meta: {
                        //     section: 'collector',
                        //     permission: 'collector'
                        // },
                        children: [
                            {
                                path: 'groups',
                                name: 'infinity-groups',
                                component: ViewInfinityGroups,
                                meta: {
                                    section: 'collector',
                                    permission: 'collector'
                                }
                            },
                            {
                                path: 'products',
                                name: 'infinity-products',
                                component: ViewVIPProducts,
                                meta: {
                                    section: 'collector',
                                    permission: 'collector'
                                }
                            }
                        ]
                    },
                    {
                        path: 'categories',
                        name: 'randomizer-categories',
                        component: ViewRandomizerCategories,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    },
                    {
                        path: 'categories-flash',
                        name: 'randomizer-categories-flash',
                        component: ViewRandomizerCategoriesFlash,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    },
                    {
                        path: 'categories-stock',
                        name: 'randomizer-categories-stock',
                        component: ViewRandomizerCategoriesStock,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    },
                    {
                        path: 'category-steps',
                        name: 'randomizer-category-steps',
                        component: ViewRandomizerCategorySteps,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    },
                    {
                        path: 'fast-charges',
                        name: 'fast-charges',
                        component: ViewFastCharges,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    },
                    {
                        path: 'roadmap',
                        name: 'roadmap',
                        component: ViewRoadmap,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    },
                    {
                        path: 'sandbox/:id',
                        name: 'sandbox',
                        component: ViewSandbox,
                        meta: {
                            section: 'collector',
                            permission: 'collector'
                        }
                    }
                ]
            },
            {
                path: 'purchases',
                name: 'purchases',
                component: AppPurchases,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'purchases-history',
                name: 'purchases-history',
                component: ViewHistoryPurchases,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'bonus-bundle',
                name: 'bonus-bundle',
                component: AppBundle,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            }
        ]
    }
];
