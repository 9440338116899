<script>
import vSelect from 'vue-select';
import api from '@/services/api';
import InfinityScrollSelect from '@/mixins/InfinityScrollSelect';
import { checkLocales } from '@/util/lang';

export default {
    name: 'TournamentsSelectTask',
    mixins: [InfinityScrollSelect],
    components: {
        vSelect
    },
    props: {
        value: {
            type: [Object, String],
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            selectOptions: [],
            tournamentId: null,
            customDisable: this.disabled
        };
    },
    async mounted() {
        await this.getOptions();
    },
    methods: {
        checkLocales,
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                this.optionsParams.page += 1;
                await this.getOptions();
                await this.$nextTick();
                ul.scrollTop = scrollTop;
            }
        },

        filterDebounce() {
            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.getOptions();
            }, 200);
        },

        async getOptions() {
            let query = this.optionsParams;
            if (this.optionsFilter.search) {
                query = {
                    ...this.optionsParams,
                    search: this.optionsFilter.search
                };
            }

            await api.tournament
                .fetchAllTournaments(query)
                .then((response) => {
                    this.generatePagination(response.data.pagination);
                    const filteredList = response.data.result.map((item) => {
                        return {
                            id: item.id,
                            name: checkLocales(item.name)
                        };
                    });

                    this.selectOptions = [...this.selectOptions, ...filteredList];

                    if (this.tournamentId) {
                        this.selectOptions.forEach((item) => {
                            if (item.id === parseInt(this.tournamentId)) {
                                this.inputTrigger(item);
                            }
                        });
                        this.customDisable = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.optionsFilter.loading = false;
                });
        },

        inputTrigger(event) {
            this.$emit('input', event);
        }
    }
};
</script>

<template>
    <div>
        <label>{{ $t('tournament.tournaments_title') }}</label>

        <v-select
            :dense="true"
            :disabled="disabled"
            :filterable="false"
            :multiple="false"
            :options="selectOptions"
            :searchable="true"
            :selected="tournamentId"
            :value="value"
            label="name"
            @close="onClose"
            @input="inputTrigger"
            @open="onOpen"
            @search="filterDebounce"
        >
            <template v-slot:option="option">
                {{ checkLocales(option.name) }}
            </template>

            <template v-slot:selected-option="option">
                {{ checkLocales(option.name) }}
            </template>
            <template #list-footer>
                <li v-show="hasNextPage" ref="load-scroll" class="loader">
                    Loading more options...
                </li>
            </template>
        </v-select>
    </div>
</template>

<style scoped></style>
