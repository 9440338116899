import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class WebGamePortal extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllItems(params) {
        return this.post('wp/games/findAll', params);
    }

    fetchOneItem(params) {
        return this.post('wp/games/findOne', params);
    }

    createItem(body) {
        return this.post('wp/games', body);
    }

    updateItem(body) {
        return this.post('wp/games/update', body);
    }

    deleteItem(body) {
        return this.post('wp/games/remove', body);
    }
}

export default WebGamePortal;
