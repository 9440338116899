import { axiosV2 } from '@/services/core/base';
import Core from '@/services/axios/core.axios';

const dMCtx = {
    merchant_id: 1
};

class Tournament extends Core {
    constructor() {
        super({
            axios: axiosV2({})
        });
    }

    // TOURNAMENTS
    fetchAllTournaments(body) {
        return this.post('tournament/tournaments/findAll', { ...body, ...dMCtx });
    }

    fetchOneTournament(body) {
        return this.post('tournament/tournaments/findOne', { ...body, ...dMCtx });
    }

    createTournament(body) {
        return this.post('tournament/tournaments', { ...body, ...dMCtx });
    }

    updateTournament(body) {
        return this.post('tournament/tournaments/update', { ...body, ...dMCtx });
    }

    deleteTournament(body) {
        return this.post('tournament/tournaments/remove', { ...body, ...dMCtx });
    }

    // TASKS
    fetchAllTasks(body) {
        return this.post('tournament/tasks/findAll', { ...body, ...dMCtx });
    }

    fetchOneTask(body) {
        return this.post('tournament/tasks/findOne', { ...body, ...dMCtx });
    }

    createTask(body) {
        return this.post('tournament/tasks', { ...body, ...dMCtx });
    }

    updateTask(body) {
        return this.post('tournament/tasks/update', { ...body, ...dMCtx });
    }

    deleteTask(body) {
        return this.post('tournament/tasks/remove', { ...body, ...dMCtx });
    }

    // TOURNAMENT TASKS
    fetchAllTournamentTasks(body) {
        return this.post('tournament-tasks/findAll', { ...body, ...dMCtx });
    }

    fetchOneTournamentTask(body) {
        return this.post('tournament-tasks/findOne', { ...body, ...dMCtx });
    }

    createTournamentTask(body) {
        return this.post('tournament-tasks', { ...body, ...dMCtx });
    }

    updateTournamentTask(body) {
        return this.post('tournament-tasks/update', { ...body, ...dMCtx });
    }

    setPositionTournamentTask(body) {
        return this.post('tournament-tasks/setPosition', { ...body, ...dMCtx });
    }

    deleteTournamentTask(body) {
        return this.post('tournament-tasks/remove', { ...body, ...dMCtx });
    }

    // BANNERS
    fetchAllTournamentBanners(body) {
        return this.post('tournament/banners/findAll', { ...body, ...dMCtx });
    }

    fetchOneTournamentBanner(body) {
        return this.post('tournament/banners/findOne', { ...body, ...dMCtx });
    }

    createTournamentBanner(body) {
        return this.post('tournament/banners', { ...body, ...dMCtx });
    }

    updateTournamentBanner(body) {
        return this.post('tournament/banners/update', { ...body, ...dMCtx });
    }

    deleteTournamentBanner(body) {
        return this.post('tournament/banners/remove', { ...body, ...dMCtx });
    }

    // GET PRODUCTS FOR TOURNAMENT PRODUCT LIST
    fetchProducts(body) {
        return this.post('tournament/products', { ...body, ...dMCtx });
    }

    // TOURNAMENT STATISTICS
    activeUserStatistics(body) {
        return this.post('tournament/statistics/activeUserStatistics', { ...body, ...dMCtx });
    }

    activeUserTotal(body) {
        return this.post('tournament/statistics/activeUserTotal', { ...body });
    }

    fullTaskStatistics(body) {
        return this.post('tournament/statistics/fullTaskStatistics', { ...body, ...dMCtx });
    }

    fullTaskStatisticsByTask(body) {
        return this.post('tournament/statistics/fullTaskStatisticsByTask', { ...body, ...dMCtx });
    }

    fetchAllGroups(body) {
        return this.post('tournament/groups/findAll', { ...body, ...dMCtx });
    }

    fetchUserPosition(user_id, merchant_id) {
        return this.post('tournament/myRating', { user_id, merchant_id });
    }

    fetchTournamentTaskProgress(body) {
        return this.post('tournament/userScores/findAll', { ...body });
    }

    fetchTournamentTaskActive(body) {
        return this.post('tournament/userScores', { ...body });
    }

    fetchGroupUsers(body) {
        return this.post('tournament/userGroups/findAll', { ...body, ...dMCtx });
    }

    sendSyncRequest(body) {
        return this.post('tournament/syncRanking', { ...body, ...dMCtx });
    }

    // WINNERS
    fetchWinners(body) {
        return this.post('winners/findAll', body);
    }

    fetchWinnerStatuses(body) {
        return this.post('winners/statuses', body);
    }

    updateWinnerStatuses(body) {
        return this.post('winners/update', body);
    }
}

export default Tournament;
