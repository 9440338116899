// import Core from '@/services/core';
// import { axiosInstance } from '@/services/core/base';
//
// class ImageUploader extends Core {
//     constructor(url) {
//         super(axiosInstance, url);
//     }
//
//     imageUpload(image) {
//         return this.post(image);
//     }
// }
//
// export default ImageUploader;

import Core from '@/services/core';
import { axiosInstanceV2 } from '@/services/core/base';

class ImageUploader extends Core {
    constructor(url) {
        super(axiosInstanceV2, url);
    }

    imageUpload(type, image) {
        return this.customPost(this.url + type, image);
    }
}

export default ImageUploader;
