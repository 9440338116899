<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
// import services from '@/services';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import { MERCHANT_ID } from '@/constants/names';
import { fixTimeFrame } from '@/util/helper';

export default {
    name: 'AppScenarioPrize',
    components: {
        vSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                type: null,
                value: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            prizeList: [],
            staticTypes: [
                {
                    id: 0,
                    label: this.$t('titles.package')
                },
                {
                    id: 1,
                    label: 'FIT-Coin'
                }
            ],
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
                this.getPrizeList();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.name')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'value',
                    label: this.$t('titles.value')
                },
                {
                    key: 'updated_at',
                    label: this.$t('titles.updated_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,
        fixTimeFrame,

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.scenarioPrizeApi.fetchAllItems(body);
                this.items = data.result;
                this.pagination = data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getPrizeList() {
            const body = {
                page: 1,
                limit: 1000
            };
            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.prizeList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.scenarioPrizeApi
                .fetchOneItem({ id: item.id, merchant_id: parseInt(MERCHANT_ID) })
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.scenarioPrizeApi
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.scenarioPrizeApi
                .updateItem({ ...body, id: id })
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.scenarioPrizeApi
                .deleteItem({ id: id })
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.merchant_id = parseInt(MERCHANT_ID);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        clearValue() {
            this.dataForModal.value = null;
        },

        packageIDForTable(id) {
            let packageName = null;
            this.prizeList.forEach((item) => {
                if (id === item.id) {
                    packageName = item.type_name.toUpperCase() + ' ' + item.qty;
                }
            });
            return packageName;
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.scenario_prize') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(value)="{ item }">
                    <span v-if="item.type === 1">{{ item.value }}</span>
                    <span v-else>{{ packageIDForTable(item.value) }}</span>
                </template>

                <template #cell(type)="{ item }">
                    <span v-if="item.type === 1">FIT-Coin</span>
                    <span v-else>{{ $t('titles.package') }}</span>
                </template>

                <template #cell(updated_at)="{ item }">
                    {{ fixTimeFrame(item['updated_at']) }}
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'collector', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'collector', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.scenario_prize') : $t('add.scenario_prize')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME[UZ]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.real_title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.real_title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[RU]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.real_title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.real_title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[EN]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.real_title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.real_title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     TYPE     -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.types')"
                            rules="required"
                        >
                            <b-form-group :label="$t('titles.types')" label-for="types">
                                <v-select
                                    :placeholder="$t('titles.types')"
                                    id="types"
                                    :disabled="isViewModal || updatingItem"
                                    :options="staticTypes"
                                    v-model="dataForModal.type"
                                    :reduce="(option) => option.id"
                                    label="label"
                                    :clearable="false"
                                    @input="clearValue"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--     VALUE     -->
                    <b-col cols="12">
                        <div v-if="dataForModal.type === 1">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.value')"
                                rules="required"
                            >
                                <b-form-group :label="$t('titles.value')" label-for="value">
                                    <b-form-input
                                        v-model="dataForModal.value"
                                        :disabled="isViewModal"
                                        :placeholder="$t('enter.value')"
                                        :state="errors.length > 0 ? false : null"
                                        size="lg"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div v-else>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.value')"
                                rules="required"
                            >
                                <b-form-group :label="$t('titles.package')" label-for="prize">
                                    <v-select
                                        id="prize"
                                        v-model="dataForModal.value"
                                        :disabled="isViewModal"
                                        :options="prizeList"
                                        :reduce="(option) => option.id"
                                        :placeholder="$t('titles.prize')"
                                        label="name"
                                        :clearable="false"
                                    >
                                        <template v-slot:option="option">
                                            {{ option.type_name.toUpperCase() + ' ' + option.qty }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ option.type_name.toUpperCase() + ' ' + option.qty }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                                <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

//.bordered__image-list {
//    border: 2px dashed #999999;
//
//    &_item {
//        position: relative;
//        width: 100px;
//        height: 100px;
//
//        &-close {
//            position: absolute;
//            display: flex;
//            align-items: center;
//            justify-content: center;
//            top: -5px;
//            right: -5px;
//            width: 16px;
//            height: 16px;
//            border-radius: 50%;
//            background-color: #999999;
//            color: #fff;
//            cursor: pointer;
//            font-size: 12px;
//        }
//
//        & img {
//            width: 100px;
//            height: 100%;
//            object-fit: cover;
//        }
//    }
//}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
