import Core from '@/services/core';
import { axiosInstance } from '@/services/core/base';

class Offers extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }
}

export default Offers;
