<script>
import {
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BTable
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import api from '@/services/api';
import { sortItems } from '@/util/helper';
import PageTitle from '@/components/PageTitle';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { offerTypes } from '@/constants/offerTypes.js';
import TableActionBtns from '@/components/TableActionBtns';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';

export default {
    name: 'Offers',
    components: {
        ProgressLinear,
        PageTitle,
        TableActionBtns,
        BRow,
        BTable,
        BButton,
        BCol,
        BFormGroup,
        BFormCheckbox,
        BFormInput,
        BInputGroup,
        BCard,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        offers: [],
        offerTypes,
        dataForModal: {
            name: '',
            offer_id: '',
            types: []
        },
        requestPending: true
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'offer_id',
                    label: 'ID'
                },
                {
                    key: 'voice',
                    label: this.$t('titles.voice')
                },
                {
                    key: 'internet',
                    label: this.$t('titles.internet')
                },
                {
                    key: 'sms',
                    label: this.$t('titles.sms')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        computedOffers() {
            return this.offers.map((offer) => {
                for (const type of this.offerTypes) {
                    offer[type.key] = offer.types.includes(type.key);
                }
                return offer;
            });
        }
    },
    methods: {
        async getOffers() {
            this.requestPending = true;
            const { data } = await api.offers.get();
            this.offers = data;
            this.sortOffersByName();
            this.requestPending = false;
        },

        async create() {
            await api.offers.post(this.dataForModal).then(({ data }) => {
                this.offers.push(data);
                this.$toast.success(this.$t('success.added'));
                this.sortOffersByName();
            });
        },

        async update() {
            await api.offers
                .put(this.updatingItem.id, {
                    name: this.dataForModal.name,
                    offer_id: this.dataForModal.offer_id,
                    types: this.dataForModal.types
                })
                .then(({ data }) => {
                    this.updateDataToNewData(data);
                    this.$toast.success(this.$t('success.updated.title'));
                    this.sortOffersByName();
                });
        },

        async deleteOffer(id, index) {
            await api.offers.delete(id);
            this.offers.splice(index, 1);
        },

        sortOffersByName() {
            this.offers = sortItems(this.offers, 'name');
        }
    },
    mounted() {
        this.getOffers();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.offers') }}
            </template>
            <template #button v-if="hasAccess('offers', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                striped
                borderless
                outlined
                :responsive="true"
                :fields="tableHeader"
                :items="computedOffers"
            >
                <template #cell(index)="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell(voice)="{ item }">
                    <feather-icon
                        :icon="generateIconToBoolean(!item.voice)"
                        :class="['stroke-current', generateIconColorToBoolean(!item.voice)]"
                    />
                </template>
                <template #cell(internet)="{ item }">
                    <feather-icon
                        :icon="generateIconToBoolean(!item.internet)"
                        :class="['stroke-current', generateIconColorToBoolean(!item.internet)]"
                    />
                </template>
                <template #cell(sms)="{ item }">
                    <feather-icon
                        :icon="generateIconToBoolean(!item.sms)"
                        :class="['stroke-current', generateIconColorToBoolean(!item.sms)]"
                    />
                </template>
                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :item="item"
                        :index="index"
                        :delete-handler="deleteOffer"
                        :delete-access="{ section: 'offers', permission: 'delete' }"
                        :update-access="{ section: 'offers', permission: 'update' }"
                        @openModal="openModalToUpdate"
                    />
                </template>
            </b-table>
        </div>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            size="sm"
            :title="$t('add.offer')"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    OFFER NAME    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.title')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.title')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    OFFER ID    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.offer') }} ID </label>
                        <ValidationProvider
                            :name="`${$t('titles.offer')} ID`"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.offer_id"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="`${$t('enter.offer')} ID`"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    OFFER TYPE    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.offerTypes') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.offerTypes')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.types"
                                    multiple
                                    :closeOnSelect="false"
                                    :options="offerTypes"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :placeholder="$t('choose.offerType')"
                                    :reduce="(type) => type.key"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button @click="modalSubmit" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
