import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class AlertModal extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('alert-modal/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('alert-modal/findOne', body);
    }

    createItem(body) {
        return this.post('alert-modal', body);
    }

    updateItem(body) {
        return this.post('alert-modal/update', body);
    }

    deleteItem(body) {
        return this.post('alert-modal/remove', body);
    }

    fetchAllPlaces(body) {
        return this.post('alert-modal/places', body);
    }
}

export default AlertModal;
