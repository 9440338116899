<script>
import vSelect from 'vue-select';
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BRow } from 'bootstrap-vue';
import { categoryTypes } from '@/constants/offerTypes';
import { clearObjectValues } from '@/util/helper';

export default {
    name: 'SubscriberActivatePacketForm',
    components: { vSelect, BFormGroup, BCol, BRow, BButton },
    data: () => ({
        newPacket: {
            type: 'daily'
        },
        isPacketsSelectAvailable: false
    }),
    props: {
        id: [Number, String],
        steps: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedSteps() {
            if (!this.steps) return;
            return this.steps;
        },
        categoryTypes() {
            return categoryTypes;
        },
        premiumPackets() {
            return ['internet', 'voice'];
        }
    },
    methods: {
        async activatePacket() {
            let isPayloadFilled = false;
            Object.keys(this.newPacket).forEach((key) => {
                if (this.newPacket[key]) {
                    isPayloadFilled = true;
                }
            });
            if (isPayloadFilled) {
                await api.subscribers
                    .activatePacketAsSubscriber(this.id, this.newPacket)
                    .then((res) => {
                        this.$emit('success');
                        this.$toast.success('Пакет успешно активировано!');
                        this.newPacket = clearObjectValues(this.newPacket);
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                    });
            } else {
                this.$toast.error('Заполняйте поля!');
            }
        },

        selectedType() {
            if (this.newPacket.type === 'premium') {
                this.isPacketsSelectAvailable = true;
            } else {
                this.isPacketsSelectAvailable = false;
            }
        }
    },
    mounted() {
        this.newPacket.user_id = JSON.parse(localStorage.getItem('userData')).id;
    }
};
</script>

<template>
    <b-row>
        <b-col cols="12" class="mb-2">
            <h5>
                {{ $t('titles.type') }}
            </h5>
            <b-form-group>
                <v-select
                    v-model="newPacket.type"
                    :options="categoryTypes"
                    :searchable="false"
                    :reduce="(type) => type.key"
                    @input="selectedType"
                />
            </b-form-group>
        </b-col>
        <b-col v-if="isPacketsSelectAvailable" cols="12" class="mb-2">
            <h5>Пакеты</h5>
            <b-form-group>
                <v-select
                    v-model="newPacket.type_package"
                    :options="premiumPackets"
                    :searchable="false"
                />
            </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
            <h5>
                {{ $t('titles.step') }}
            </h5>
            <b-form-group>
                <v-select v-model="newPacket.step" :options="computedSteps" :searchable="false" />
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-button variant="success" class="float-right" @click="activatePacket">
                {{ $t('button.activate') }}
            </b-button>
        </b-col>
    </b-row>
</template>

<style scoped></style>
