<script>
export default {
    name: 'Chip'
};
</script>

<template>
    <div class="chip">
        <slot />
    </div>
</template>

<style lang="scss" src="./Chips.scss" />
