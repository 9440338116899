import Core from '@/services/core';
import { axiosDevV3 } from '@/services/core/base';

class Statistics extends Core {
    constructor() {
        super(axiosDevV3({ endpoint: '' }));
    }

    getTotalSubscribers() {
        return this.customGet('/statistics/subscribers/total');
    }

    getSubscribersVerification() {
        return this.customGet('/statistics/subscribers/verification');
    }

    getNewSubscribersAmountInMonth() {
        return this.customGet('/statistics/subscribers/monthly');
    }

    getCharge(id) {
        return this.customGet('/statistics/charges/' + id);
    }

    getDailyCharge(id) {
        return this.customGet('/statistics/charges/daily/' + id);
    }

    getTotalGifts() {
        return this.customGet('/statistics/gifts');
    }

    getTotalInvoices() {
        return this.customGet('/statistics/invoice');
    }

    getTotalProfits(params) {
        return this.customGet('/statistics/profits', params);
    }

    getTotalProfitsTotal(params) {
        return this.customGet('/statistics/profits/total', params);
    }

    getTotalProfitsExpense(params) {
        return this.customGet('/statistics/profits/expense', params);
    }

    getTotalProfitsRevenue(params) {
        return this.customGet('/statistics/profits/revenue', params);
    }

    getPaynetStats() {
        return this.customGet('/statistics/paynet');
    }
}

export default Statistics;
