import News from '@/views/news/News.vue';
import Offers from '@/views/Tariffs.vue';
import Gifts from '@/views/gift/Gifts.vue';
import Categories from '@/views/category/Categories.vue';
import Regions from '@/views/regions/Regions.vue';
import Cities from '@/views/cities/Cities.vue';
import FAQ from '@/views/faq/FAQ.vue';
import ProductCategories from '@/views/product-categories/ProductCategories.vue';
import Notifications from '@/views/notifications/Notifications.vue';
import ServerKey from '@/views/notifications/ServerKey.vue';
import AppLanguages from '@/views/languages/AppLanguages.vue';
import AppTranslations from '@/views/translations/AppTranslations.vue';
import AppGames from '@/views/others/games/AppGames.vue';
import AppSMS from '@/views/others/sms/AppSMS.vue';
import AppScoreHistory from '@/views/others/score-history/AppScoreHistory.vue';
import AppFlash from '@/views/others/flash/AppFlash.vue';
import AppCollector from '@/views/others/collector/AppCollector.vue';
import AppFlashHistory from '@/views/others/flash-history/AppFlashHistory.vue';
import AppGameHistory from '@/views/others/game-history/AppGameHistory.vue';
import AppCollectorActivity from '@/views/others/collector-activity/AppCollectorActivity.vue';
import AppBanners from '@/views/others/banners/AppBanners.vue';
import AppServers from '@/views/others/servers/AppServers.vue';
import AppControl from '@/views/others/control/AppControl.vue';
import AppRecordLogs from '@/views/others/record-logs/AppRecordLogs.vue';
// import AppBonusStatistics from '@/views/others/bonus-statistics/AppBonusStatistics.vue';
import AppSubscribersService from '@/views/others/subscribers-service/AppSubscribersService.vue';
import NewsV1 from '@/views/news/NewsV1.vue';
import AppFlashStatistics from '@/views/others/flash-statistics/AppFlashStatistics.vue';
import AppReferralPrize from '@/views/others/referral/AppReferralPrize.vue';
import AppAlertModal from '@/views/others/alert-modal/AppAlertModal.vue';

export default [
    {
        path: '/news',
        name: 'News',
        component: News,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/news-v1',
        name: 'News-V1',
        component: NewsV1,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/offers',
        name: 'Offers',
        component: Offers,
        meta: {
            section: 'offers',
            permission: 'view'
        }
    },
    {
        path: '/gifts',
        name: 'Gifts',
        component: Gifts,
        meta: {
            section: 'gifts',
            permission: 'view'
        }
    },
    {
        path: '/categories',
        name: 'Categories',
        component: Categories,
        meta: {
            section: 'policies',
            permission: 'categories'
        }
    },
    {
        path: '/regions',
        name: 'regions',
        component: Regions,
        meta: {
            section: 'regions',
            permission: 'regions'
        }
    },
    {
        path: '/cities',
        name: 'cities',
        component: Cities,
        meta: {
            section: 'cities',
            permission: 'cities'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
        meta: {
            section: 'faq',
            permission: 'faq'
        }
    },
    {
        path: '/product-category',
        name: 'product-category',
        component: ProductCategories,
        meta: {
            section: 'product_categories',
            permission: 'product_categories'
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
            section: 'notifications',
            permission: 'notifications'
        }
    },
    {
        path: '/server-keys',
        name: 'server-keys',
        component: ServerKey,
        meta: {
            section: 'server_keys',
            permission: 'server-keys'
        }
    },
    {
        path: '/languages',
        name: 'languages',
        component: AppLanguages,
        meta: {
            section: 'server_keys',
            permission: 'server-keys'
        }
    },
    {
        path: '/translations',
        name: 'translations',
        component: AppTranslations,
        meta: {
            section: 'server_keys',
            permission: 'server-keys'
        }
    },
    {
        path: '/games',
        name: 'games',
        component: AppGames,
        meta: {
            section: 'games',
            permission: 'games'
        }
    },
    {
        path: '/game-history',
        name: 'game-history',
        component: AppGameHistory,
        meta: {
            section: 'game-history',
            permission: 'game-history'
        }
    },
    {
        path: '/sms',
        name: 'sms',
        component: AppSMS,
        meta: {
            section: 'games',
            permission: 'games'
        }
    },
    {
        path: '/score-history',
        name: 'score-history',
        component: AppScoreHistory,
        meta: {
            section: 'score-history',
            permission: 'score-history'
        }
    },
    {
        path: '/flash',
        name: 'flash',
        component: AppFlash,
        meta: {
            section: 'flash',
            permission: 'flash'
        }
    },
    {
        path: '/flash-history',
        name: 'flash-history',
        component: AppFlashHistory,
        meta: {
            section: 'flash-history',
            permission: 'flash-history'
        }
    },
    {
        path: '/flash-statistics',
        name: 'flash-statistics',
        component: AppFlashStatistics,
        meta: {
            section: 'flash-history',
            permission: 'flash-history'
        }
    },
    {
        path: '/collector',
        name: 'collector',
        component: AppCollector,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/collector-activity',
        name: 'collector-activity',
        component: AppCollectorActivity,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/banners',
        name: 'banners',
        component: AppBanners,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/servers',
        name: 'servers',
        component: AppServers,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/control',
        name: 'control',
        component: AppControl,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/record-logs',
        name: 'record-logs',
        component: AppRecordLogs,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    // {
    //     path: '/bonus-statistics',
    //     name: 'bonus-statistics',
    //     component: AppBonusStatistics,
    //     meta: {
    //         section: 'collector',
    //         permission: 'collector'
    //     }
    // },
    {
        path: '/subscribers-service',
        name: 'subscribers-service',
        component: AppSubscribersService,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/referral-prize',
        name: 'referral-prize',
        component: AppReferralPrize,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/alert-modal',
        name: 'alert-modal',
        component: AppAlertModal,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    }
];
