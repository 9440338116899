<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';

export default {
    name: 'ViewUzmobileRtStatus',
    components: { ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUzmobile.fetchRTStatus({
                    phone: parseInt(this.phone)
                });
                this.response = data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },

        hasChildren(value) {
            return typeof value === 'object';
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>

                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <b-button @click="getResponse" variant="success">{{
                        $t('button.filter')
                    }}</b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <div v-else class="json__card card p-1 px-2">
                    <div v-if="response">
                        <div v-for="(value, key, index) in response" :key="index + value + key">
                            <div v-if="!hasChildren(value)">
                                <span class="text-bold text-uppercase font-medium-2 text-warning">
                                    {{ key }}
                                </span>
                                <span class="text-bold text-uppercase font-medium-2 mx-1"> : </span>
                                <span class="text-bold text-uppercase font-medium-2 text-success">
                                    {{ value }}
                                </span>
                            </div>
                            <div v-else>
                                <p
                                    class="text-bold text-uppercase font-medium-2 text-info mb-0 mt-1"
                                >
                                    {{ key }}
                                </p>
                                <p
                                    v-for="(childItem, childIndex) in value"
                                    :key="childItem + childIndex + index"
                                >
                                    <!--                                    {{ childItem }}-->
                                    <span
                                        v-for="(child2Value, child2Key, child2Index) in childItem"
                                        :key="child2Value + child2Key + child2Index"
                                    >
                                        <span class="text-uppercase text-warning font-medium-2">
                                            {{ child2Key }}
                                        </span>
                                        <span class="text-uppercase font-medium-2 mx-1">:</span>
                                        <span class="text-uppercase text-success font-medium-2">
                                            {{ child2Value }}
                                        </span>
                                        <br />
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <h4 v-else>No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
