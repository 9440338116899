import Core from '@/services/core';
import { axiosInstance } from '@/services/core/base';

class Logs extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }

    getLogActionsApi() {
        return this.customGet(this.url + '/actions');
    }
}

export default Logs;
