import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLocalVar } from '@/util/localstorage-helper';
// import eng from '@/libs/i18n/locales/en.json';
// import ru from '@/libs/i18n/locales/ru.json';
// import uz from '@/libs/i18n/locales/uz.json';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;

    // return {
    //     eng,
    //     uz,
    //     ru
    // };
}

export const i18n = new VueI18n({
    locale: getLocalVar('lang') || 'ru',
    fallbackLocale: getLocalVar('lang') || 'ru',
    silentTranslationWarn: true,
    messages: loadLocaleMessages()
});
