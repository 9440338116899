<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import CompStackedChart from '@/components/CompStackedChart.vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { checkLocales } from '@/util/lang';
import { BButton } from 'bootstrap-vue';
import FlatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppQuestionnaireStatistics',
    components: { BButton, ProgressLinear, CompStackedChart, FlatPickr },
    data() {
        return {
            response: {
                isFetched: false,
                charts: []
            },
            date_start: null,
            date_end: null
        };
    },
    async mounted() {
        await this.fixStatisticsDates();
    },
    methods: {
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        async fixStatisticsDates() {
            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.date_start = this.formatDate(date_start);
            this.date_end = this.formatDate(date_end);

            await this.getQuestionnaireStatistics();
        },

        async getQuestionnaireStatistics() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: this.date_start,
                date_end: this.date_end
            };
            this.response.isFetched = false;
            try {
                const rsp = await api.otherStatistics.fetchFeedbackStatistics(body);
                const statistics = rsp.data.data['polls'];
                this.response.charts = statistics.map((poll) => {
                    let series = [];
                    let categories = [];
                    let reasonsMap = {};

                    poll.dates.forEach((dateEntry, index) => {
                        categories.push(dateEntry.date.substring(5));

                        dateEntry.list.forEach((reasonEntry) => {
                            let reasonKey = JSON.stringify(checkLocales(reasonEntry.name));

                            if (!reasonsMap[reasonKey]) {
                                reasonsMap[reasonKey] = new Array(poll.dates.length).fill(0);
                            }

                            reasonsMap[reasonKey][index] = reasonEntry.count;
                        });
                    });

                    for (let reasonKey in reasonsMap) {
                        series.push({
                            name: JSON.parse(reasonKey),
                            data: reasonsMap[reasonKey]
                        });
                    }

                    return {
                        series: series,
                        categories: categories,
                        title: checkLocales(poll.name)
                    };
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.response.isFetched = true;
            }
        }
    }
};
</script>

<template>
    <div class="questionnaire">
        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button
                    @click="getQuestionnaireStatistics"
                    class="w-100 mt-1 mt-md-0"
                    variant="primary"
                >
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="!response.isFetched" />
                    <div v-else>
                        <CompStackedChart
                            v-for="(chart, index) in response.charts"
                            :key="index"
                            :is-pending="!response.isFetched"
                            :series="chart.series"
                            :categories="chart.categories"
                            :title="chart.title"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
