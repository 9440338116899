<script>
import api from '@/services/api';
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow
    // BSpinner
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import FilterBlock from '@/components/FilterBlock';
// import flatPickr from 'vue-flatpickr-component';
import { isBoolean } from '@/util/inspect.util';
import { MERCHANT_ID } from '@/constants/names';
import DashboardActivityLogger from '@/components/DashboardActivityLogger.vue';
import { fixTimeFrame } from '../../util/helper';

export default {
    name: 'AppDashboardActivity',
    components: {
        DashboardActivityLogger,
        // BSpinner,
        FilterBlock,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        vSelect
        // flatPickr
    },
    data() {
        return {
            logs: {
                items: [],
                pagination: {}
            },
            staffList: [],
            filter: {
                page: 1,
                user_id: null
            },
            requestPending: false,
            localLang: localStorage.getItem('lang')
            // interval: null,
            // fetchTrigger: true
        };
    },
    methods: {
        fixTimeFrame,
        isBoolean,

        // colorByStatusCode(statusCode) {
        //     const toFixedNum = parseInt(Math.floor(statusCode / 100).toFixed(0));
        //     switch (toFixedNum) {
        //         case 2:
        //             return 'success';
        //         case 3:
        //             return 'warning';
        //         case 4:
        //             return 'warning';
        //         case 5:
        //             return 'danger';
        //         default:
        //             return 'secondary';
        //     }
        // },

        async fetchLogs() {
            if (this.requestPending) return;
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID)
            });
            const { data } = await api.dashboardActivityApi.fetchAllItems(this.filter);
            const matchedList = data.result.map((obj2) => {
                const matchedObj = this.staffList.find(
                    (obj1) => obj1.id === parseInt(obj2.user_id)
                );
                return { ...obj2, user: matchedObj };
            });
            if (data.pagination.current === 1) {
                this.logs.items = matchedList;
            } else {
                this.logs.items = [...this.logs.items, ...matchedList];
            }
            this.logs.pagination = data.pagination;
            this.requestPending = false;
        },

        async getUsers() {
            const { data } = await api.users.get();
            this.staffList = data;
        },

        loadNextPage(page) {
            if (!this.fetchTrigger) {
                if (this.requestPending || this.autoReload || !page) return;
                this.filter.page = page;
                this.fetchLogs();
            }
        },

        resetFilter() {
            this.filter = {
                page: 1,
                user_id: null
            };
            this.fetchLogs();
        },

        isEmptyObject(obj) {
            return Object.keys(obj).length === 0 ? '}' : '';
        }

        // async fixMonthlyStatisticsDates() {
        //     const date = new Date();
        //     const year = date.getUTCFullYear(); // Get the full year (4 digits)
        //     const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
        //     const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
        //     this.filter.params.date_start = `${year}-${currentMonth}-${day}`;
        //     this.filter.params.date_end = `${year}-${currentMonth}-${day}`;
        //     await this.fetchLogs();
        // },

        // async triggerAutoFetching() {
        //     this.fetchTrigger = !this.fetchTrigger;
        //
        //     if (!this.fetchTrigger) {
        //         clearInterval(this.interval);
        //         this.interval = null;
        //     } else {
        //         await this.fetchLogs();
        //         this.interval = setInterval(async () => {
        //             await this.fetchLogs();
        //         }, 3000);
        //     }
        // }
    },
    async mounted() {
        // await this.fetchLogs();
        // await this.fixMonthlyStatisticsDates();
        await this.getUsers();
        await this.fetchLogs();
        // if (this.fetchTrigger) {
        //     this.interval = setInterval(async () => {
        //         await this.fetchLogs();
        //     }, 3000);
        // }
    }

    // beforeRouteLeave(to, from, next) {
    //     clearInterval(this.interval);
    //     this.interval = null;
    //
    //     next();
    // }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-7 col-sm-12 col-12 order-2 order-md-1">
                <DashboardActivityLogger
                    v-model:loading="requestPending"
                    :items="logs.items"
                    :pagination="logs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="activity-item">
                            <div class="activity-item__content">
                                <div
                                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center"
                                >
                                    <div>
                                        <span class="text-secondary font-small-5">{{
                                            item.user.email
                                        }}</span>
                                    </div>
                                </div>
                                <div>
                                    <var>USER ID : </var>
                                    <span class="font-medium-1">
                                        <strong>{{ item.user_id }}</strong>
                                    </span>
                                </div>
                                <div>
                                    <var>USERNAME : </var>
                                    <b-badge variant="primary">{{ item.user.name }} </b-badge>
                                </div>
                                <div>
                                    <var>ACTION ID : </var>
                                    <span class="font-medium-1">
                                        <strong v-if="item.action_id">{{ item.action_id }}</strong>
                                        <strong v-else>{{ $t('no_data') }}</strong>
                                    </span>
                                </div>
                                <div>
                                    <var>ACTION TYPE : </var>
                                    <span class="font-medium-1">
                                        <strong v-if="item.action_type">{{
                                            item.action_type
                                        }}</strong>
                                        <strong v-else>{{ $t('no_data') }}</strong>
                                    </span>
                                </div>
                                <div class="divider"></div>
                                <div>
                                    <var>URL : </var>
                                    <b-badge variant="primary">{{ item.url }} </b-badge>
                                </div>
                                <div>
                                    PARAMS:</span>
                                </div>
                                <div
                                    v-for="(name, value, index) in item.params"
                                    :key="index"
                                    class="ml-2"
                                >
                                    <var>
                                        <span class="text-uppercase"> {{ value }} : </span>

                                        <span v-if="isBoolean(name)" class="font-medium-2">
                                            <feather-icon
                                                :class="name ? 'text-success' : 'text-danger'"
                                                :icon="name ? 'CheckIcon' : 'XIcon'"
                                                size="20"
                                            />
                                        </span>
                                        <span v-else class="font-medium-2">{{ name }}</span>
                                    </var>
                                </div>
                            </div>
                            <small class="text-right created__at">
                                {{ fixTimeFrame(item.created_at) }}
                            </small>
                        </div>
                    </template>
                </DashboardActivityLogger>
            </b-col>

            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">
                <filter-block :filter="filter" :show-buttons="false" @submit="fetchLogs">
                    <!--                    <b-col class="mb-1" cols="12">-->
                    <!--                        <b-button-->
                    <!--                            :disabled="requestPending"-->
                    <!--                            variant="outline-primary"-->
                    <!--                            @click="triggerAutoFetching"-->
                    <!--                        >-->
                    <!--                            <b-spinner v-if="requestPending" small variant="primary" />-->
                    <!--                            <feather-icon-->
                    <!--                                v-else-->
                    <!--                                :icon="fetchTrigger ? 'PauseIcon' : 'PlayIcon'"-->
                    <!--                                size="20"-->
                    <!--                            ></feather-icon>-->
                    <!--                        </b-button>-->
                    <!--                    </b-col>-->
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('titles.staff_id') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                id="staff_id"
                                v-model="filter.user_id"
                                :options="staffList"
                                :placeholder="$t('choose.staff_id')"
                                :reduce="(option) => option.id"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col class="col-12">
                        <div class="d-flex justify-content-end">
                            <b-button @click="resetFilter">Сбросить</b-button>
                            <b-button class="ml-1" variant="success" @click="fetchLogs"
                                >Фильтровать
                            </b-button>
                        </div>
                    </b-col>
                </filter-block>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #7367f0;
            margin-bottom: 0;
        }

        span {
            //color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}

.divider {
    height: 1px;
    width: 100%;
    background: var(--secondary);
}

@media only screen and (max-width: 450px) {
    .created__at {
        width: min-content;
    }
}
</style>
