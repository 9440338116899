<script>
import api from '@/services/api';
import vSelect from 'vue-select';
import { BButton, BCol, BFormCheckbox, BPagination, BRow, BTable, VBTooltip } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CleanIcon from '@/assets/images/icons/CleanIcon.vue';
import { MERCHANT_ID } from '@/constants/names';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';

export default {
    name: 'ViewKubeControlV2',
    components: {
        BButton,
        BTable,
        BFormCheckbox,
        CleanIcon,
        ProgressLinear,
        BCol,
        BRow,
        BPagination,
        vSelect,
        flatPickr
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    data() {
        return {
            queueList: [],
            serverList: [],
            setIntervalList: [3000, 5000, 10000],
            setIntervalValue: 10000,
            doubleRequesting: null,
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            requestPending: false,
            group_id: null,
            tab_id: 1,
            ussdInterval: null,
            ussdStatus: true,
            ussdBlockStartDate: null,
            ussdBlockEndDate: null,
            performerCount: null,
            tableItems: [],
            body: {
                start: null,
                end: null
            },
            staticTabs: [
                {
                    name: this.$t('titles.clusters'),
                    value: 1
                },
                {
                    name: this.$t('titles.servers'),
                    value: 2
                }
            ],
            openedButton: false,
            activeChild: null,
            openedChildID: null,
            performerStatusResponse: null,
            orchestrator: null
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.fetchServerList();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.doubleRequesting);
        clearInterval(this.ussdInterval);
        this.doubleRequesting = null;
        this.ussdInterval = null;

        next();
    },

    async created() {
        this.requestPending = true;
        await this.fetchQueue();
        await this.checkUSSDStatus();
        await this.checkOrchestratorStatus();
        // await this.fetchServerList();
        await this.doubleRequest();
        this.requestPending = false;
    },

    // async mounted() {
    //     this.ussdInterval = setInterval(async () => {
    //         await this.checkUSSDStatus();
    //     }, 60000);
    // },

    computed: {
        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'id',
                    label: 'Performer ID'
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.serverList && this.serverList.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        async fetchQueue() {
            try {
                const { data } = await api.kubeServersApiV2.fetchQueueTrickList({limit: 100});
                this.queueList = data.data.list;
                let tasks = null;
                this.queueList.map((item) => {
                    tasks += item.tasks;
                });
                this.queueList.unshift({
                    name: 'all',
                    id: null,
                    tasks: tasks,
                    load_half: 100000000,
                    load_limit: 100000000
                });
            } catch (e) {
                console.error(e);
            }
        },

        async fetchServerList() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                queue_trick_id: this.group_id || ''
            };
            try {
                if (this.tab_id === 1) {
                    const { data } = await api.kubeServersApiV2.fetchClusterList(body);
                    this.serverList = data.data.list;
                    this.pagination = data.data.pagination;
                } else {
                    const { data } = await api.kubeServersApiV2.fetchServerStatusList(body);
                    this.serverList = data.data.list;
                    this.pagination = data.data.pagination;
                }
            } catch (e) {
                console.error(e);
            }
        },

        async cleanQueue(serverID) {
            try {
                await api.kubeServersApiV2.killAllFlush(serverID);
                this.$toast.success(this.$t('success.updated.cache_cleaned'));
            } catch (e) {
                console.error(e);
            } finally {
                this.$bvModal.hide(`clean-server-${serverID}`);
            }
        },

        async getPerformerList(serverID) {
            this.activeChild = null;
            try {
                const { data } = await api.kubeServersApiV2.fetchPerformerList(serverID);
                this.performerCount = data.data['performerCount'];
                this.tableItems = data.data['performers'].map((item) => {
                    return {
                        id: item
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async killPerformer(child, performer) {
            try {
                await api.kubeServersApiV2.killProcess(child.id, performer.id);
                await this.getPerformerList(child.id);
                this.$toast.success(`Performer ${performer.id} is killed!`);
            } catch (e) {
                console.error(e);
            }
        },

        async getPerformerStatus(child) {
            const childItem = this.activeChild?.item;
            this.openedButton = !this.openedButton;
            try {
                const { data } = await api.kubeServersApiV2.fetchPerformerStatus(
                    child,
                    childItem.id
                );
                this.performerStatusResponse = data.data;
            } catch (error) {
                if (error.response.data.statusCode === 423) {
                    await this.getPerformerList(child);
                }
            }
        },

        async checkOrchestratorStatus() {
            try {
                const { data } = await api.kubeServersApiV2.checkOrchestratorStatus();
                this.orchestrator = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async startOrchestrator() {
            try {
                await api.kubeServersApiV2.startOrchestrator();
            } catch (e) {
                console.error(e);
            }
        },

        async stopOrchestrator() {
            try {
                await api.kubeServersApiV2.stopOrchestrator();
            } catch (e) {
                console.error(e);
            }
        },

        async triggerOrchestrator() {
            if (this.orchestrator.condition === 'active') {
                await this.stopOrchestrator();
            } else {
                await this.startOrchestrator();
            }
            setTimeout(async () => {
                await this.checkOrchestratorStatus();
            }, 500);
        },

        async startCluster(id) {
            try {
                await api.kubeServersApiV2.startCluster(id);
            } catch (e) {
                console.error(e);
            }
        },

        async stopCluster(id) {
            try {
                await api.kubeServersApiV2.stopCluster(id);
            } catch (e) {
                console.error(e);
            }
        },

        async triggerCluster(id, status) {
            if (status === 'active') {
                await this.stopCluster(id);
            } else {
                await this.startCluster(id);
            }
            this.$bvModal.hide(`cluster-${id}`);
            await this.fetchServerList();
            await this.fetchQueue();
        },

        toggleDetails(childID, row) {
            // this.setChildPaginationByDefault();
            this.tableItems.forEach((item) => {
                if (row.item.id === item.id) {
                    row.toggleDetails();
                    this.activeChild = row;
                }
                // else {
                //     item._showDetails = false;
                // }
            });
            if (!row['detailsShowing']) {
                this.getPerformerStatus(childID.id);
            }
        },

        fetchServerListByClick(groupId) {
            this.group_id = groupId;
            this.pagination.current = 1;
            this.fetchServerList();
        },

        async fetchTabListByClick(tabId) {
            this.tab_id = tabId;
            this.requestPending = true;
            await this.fetchServerList();
            this.requestPending = false;
        },

        async refreshButton() {
            this.requestPending = true;
            await this.fetchQueue();
            await this.fetchServerList();
            this.requestPending = false;
        },

        async doubleRequest() {
            this.doubleRequesting = setInterval(async () => {
                await this.fetchQueue();
                await this.fetchServerList();
                await this.checkOrchestratorStatus();
            }, this.setIntervalValue);
        },

        async clearIntervalAndSetNew() {
            await clearInterval(this.doubleRequesting);
            await this.doubleRequest();
        },

        async triggerServer(server) {
            const serverId = server.id;
            try {
                if (server.info.status.condition === 'passive') {
                    await api.kubeServersApiV2.startingServer(serverId);
                    this.$bvModal.hide(`server-${serverId}`);
                    await this.fetchServerList();
                    await this.fetchQueue();
                } else {
                    await api.kubeServersApiV2.stoppingServer(serverId);
                    this.$bvModal.hide(`server-${serverId}`);
                    await this.fetchServerList();
                    await this.fetchQueue();
                }
            } catch (e) {
                console.error(e);
            }
        },

        matchColorsForTasks(tasks, half, limit) {
            if (tasks === 0) {
                return '';
            } else if (tasks <= half) {
                return 'text-success';
            } else if (tasks > half && tasks <= limit) {
                return 'text-warning';
            } else {
                return 'text-danger';
            }
        },

        matchColorsForCards(status) {
            if (status) {
                if (status === 'checking') {
                    return 'info';
                } else if (status === 'preparation') {
                    return 'warning';
                } else if (status === 'active') {
                    return 'success';
                } else if (status === 'passive') {
                    return 'secondary';
                } else if (status === 'waiting') {
                    return 'primary';
                } else if (status === 'denied') {
                    return 'danger';
                } else {
                    return 'info';
                }
            }
        },

        matchIconsForButton(status) {
            if (status) {
                if (status === 'denied') {
                    return 'AlertTriangleIcon';
                } else if (status === 'preparation') {
                    return 'ToolIcon';
                } else if (status === 'passive') {
                    return 'PlayIcon';
                } else {
                    return 'PauseIcon';
                }
            }
        },

        matchColorsForCardButtons(status) {
            if (status) {
                if (status === 'active' || status === 'checking' || status === 'waiting') {
                    return 'outline-danger';
                } else if (status === 'passive') {
                    return 'outline-success';
                } else {
                    return 'outline-secondary';
                }
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.fetchServerList();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        async checkUSSDStatus() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.ussdApi.checkUSSDStatus(body);
                this.ussdStatus = data.data.status;
                this.ussdBlockStartDate = data.data.start;
                this.ussdBlockEndDate = data.data.end;
            } catch (e) {
                console.error(e);
            }
        },

        async triggerUSSD() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                start: this.body.start,
                end: this.body.end
            };
            if (this.ussdStatus) {
                await api.ussdApi.deleteBlock({ merchant_id: parseInt(MERCHANT_ID) });
            } else {
                await api.ussdApi.setBlock(body);
            }
            await this.closeModals();
            await this.checkUSSDStatus();
        },

        closeModals() {
            this.$refs.firstVerificationModal.hide();
            this.$refs.secondVerificationModal.hide();
            this.body.start = null;
            this.body.end = null;
        },

        async openModal() {
            await this.checkUSSDStatus();
            if (this.ussdStatus === false) {
                this.$refs.firstVerificationModal.show();
            } else {
                this.$refs.secondVerificationModal.show();
            }
        }
    }
};
</script>

<template>
    <div class="control">
        <div class="row">
            <div class="col-12 d-flex flex-wrap align-items-center justify-content-between mb-1">
                <div class="col-12 col-lg-4">
                    <h2>Servers Control V2</h2>
                </div>
                <div class="col-12 col-lg-8 d-flex flex-column justify-content-end">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-6 d-flex justify-content-between mb-1 mb-lg-0">
                            <!--      USSD ON/OFF      -->
                            <div class="d-flex align-items-center switch__button mb-1 mb-lg-0">
                                <!-- button  -->
                                <!--                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">-->
                                <!--                    Open First Modal-->
                                <!--                </b-button>-->
                                <span class="mr-1 font-weight-bold font-medium-3">USSD :</span>
                                <!--                <div v-b-modal.modal-multi-1 @click="checkUSSDStatus">-->
                                <div @click="openModal">
                                    <b-form-checkbox
                                        :checked="!ussdStatus"
                                        class="custom-control-success reversed-switch"
                                        name="check-button"
                                        switch
                                        disabled
                                    >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </div>
                                <div class="ml-1">
                                    <span
                                        v-if="ussdBlockStartDate"
                                        class="d-block font-weight-bold font-medium-1"
                                        >{{ ussdBlockStartDate }}</span
                                    >
                                    <span
                                        v-if="ussdBlockEndDate"
                                        class="d-block font-weight-bold font-medium-1"
                                        >{{ ussdBlockEndDate }}</span
                                    >
                                </div>

                                <!-- VERIFICATION MODAL 1 -->
                                <b-modal
                                    id="modal-multi-1"
                                    ref="firstVerificationModal"
                                    size="md"
                                    :title="$t('ussd.modal_title')"
                                    no-stacking
                                >
                                    <!--          DATE START          -->
                                    <label for="dateStart">{{ $t('titles.start_date') }}</label>
                                    <flat-pickr
                                        v-model="body.start"
                                        :config="{
                                            enableTime: true,
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        autocomplete="off"
                                        class="form-control mb-1"
                                        name="date"
                                        :placeholder="$t('choose.date')"
                                        id="dateStart"
                                    />

                                    <!--          DATE END          -->
                                    <label for="dateStart">{{ $t('titles.end_date') }}</label>
                                    <flat-pickr
                                        v-model="body.end"
                                        :config="{
                                            enableTime: true,
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        autocomplete="off"
                                        class="form-control"
                                        name="date"
                                        :placeholder="$t('choose.date')"
                                        id="dateEnd"
                                    />
                                    <!--                    @on-close="changeRange"-->
                                    <b-card-text
                                        class="my-2 text-center font-weight-bold font-medium-2"
                                    >
                                        <span v-if="!ussdStatus">{{
                                            $t('ussd.off_verification_text1')
                                        }}</span>
                                        <span v-else>{{ $t('ussd.on_verification_text1') }}</span>
                                    </b-card-text>
                                    <template #modal-footer>
                                        <b-button @click="closeModals" variant="danger">
                                            {{ $t('button.cancel') }}
                                        </b-button>
                                        <b-button
                                            :disabled="!body.start || !body.end"
                                            v-b-modal.modal-multi-2
                                            variant="success"
                                        >
                                            {{ $t('button.confirm') }}
                                        </b-button>
                                    </template>
                                </b-modal>

                                <!-- VERIFICATION MODAL 2 -->
                                <b-modal
                                    id="modal-multi-2"
                                    ref="secondVerificationModal"
                                    :title="$t('ussd.modal_title')"
                                >
                                    <b-card-text
                                        class="my-2 text-center font-weight-bold font-medium-2"
                                    >
                                        <span v-if="!ussdStatus">{{
                                            $t('ussd.off_verification_text2')
                                        }}</span>
                                        <span v-else>{{ $t('ussd.on_verification_text2') }}</span>
                                    </b-card-text>
                                    <b-card-text
                                        v-if="!ussdStatus"
                                        class="my-2 text-center font-weight-bold font-medium-2"
                                        >{{
                                            $t('ussd.time_duration', {
                                                start: body.start,
                                                end: body.end
                                            })
                                        }}
                                    </b-card-text>
                                    <template #modal-footer>
                                        <b-button @click="closeModals" variant="danger">
                                            {{ $t('button.cancel') }}
                                        </b-button>
                                        <b-button @click="triggerUSSD" variant="success">
                                            {{ $t('button.confirm') }}
                                        </b-button>
                                    </template>
                                </b-modal>
                            </div>

                            <!--      REFRESH BUTTON      -->
                            <b-button
                                @click="refreshButton"
                                :variant="requestPending ? 'outline-secondary' : 'outline-primary'"
                                :disabled="requestPending"
                                class="mr-lg-1"
                            >
                                <feather-icon icon="RefreshCwIcon" size="16" />
                            </b-button>
                        </div>

                        <div class="col-12 col-lg-6 d-flex justify-content-between">
                            <!--      AUTO REQUEST SECONDS      -->
                            <v-select
                                v-model="setIntervalValue"
                                :options="setIntervalList"
                                style="
                                    flex-grow: 1;
                                    height: 37px;
                                    max-width: 150px;
                                    min-width: 150px;
                                "
                                @input="clearIntervalAndSetNew"
                                :clearable="false"
                            />

                            <!--      ORCHESTRATOR BTN      -->
                            <b-button
                                v-b-modal="'orchestratorModal'"
                                v-if="orchestrator && orchestrator.condition"
                                :variant="matchColorsForCards(orchestrator.condition)"
                                class="ml-lg-1"
                            >
                                Orchestrator
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--      QUEUE LIST [TABS]      -->
            <div class="col-12">
                <b-tabs content-class="mt-2" justified>
                    <b-tab
                        v-for="item in queueList"
                        :key="item.id"
                        :title="`${item.name.toUpperCase()} [${item.tasks}]`"
                        :title-link-class="
                            matchColorsForTasks(item.tasks, item.load_half, item.load_limit)
                        "
                        @click="fetchServerListByClick(item.id)"
                    >
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <hr />

        <!--      CLUSTERS/SERVERS TABS      -->
        <b-tabs content-class="mt-2" justified>
            <b-tab
                v-for="item in staticTabs"
                :key="item.value"
                :title="item.name"
                @click="fetchTabListByClick(item.value)"
            >
            </b-tab>
        </b-tabs>

        <hr />

        <progress-linear v-if="requestPending" />

        <div v-else class="row">
            <div
                :class="
                    tab_id === 2 ? 'col-12 col-sm-6 col-md-4 col-lg-3' : 'col-12 col-md-6 col-xl-4'
                "
                v-for="server in serverList"
                :key="server.id"
            >
                <!--    CLUSTERS    -->
                <b-card
                    v-if="tab_id === 1"
                    :class="`border-${matchColorsForCards(server.status)} border-2`"
                >
                    <div>
                        <div class="d-flex align-items-center justify-content-between mb-1">
                            <div class="d-flex align-items-center">
                                <feather-icon
                                    v-if="server.status === 'passive'"
                                    icon="AlertTriangleIcon"
                                    size="28"
                                    class="mr-1 text-warning"
                                />
                                <b-badge variant="primary" class="font-large-1">
                                    {{ server.mark }}
                                </b-badge>
                            </div>
                            <b-button
                                v-b-modal="`cluster-${server.id}`"
                                :variant="
                                    server.status === 'active'
                                        ? 'outline-danger'
                                        : 'outline-success'
                                "
                                v-if="orchestrator.condition === 'active'"
                            >
                                <feather-icon
                                    :icon="
                                        server.status === 'active'
                                            ? 'AlertTriangleIcon'
                                            : 'PowerIcon'
                                    "
                                    size="20"
                                />
                            </b-button>
                        </div>

                        <div class="font-medium-1 font-weight-bold text-white">
                            {{ server.name }}
                        </div>

                        <div
                            v-for="child in server.servers"
                            :key="child.id"
                            class="inner-card cursor-pointer p-1"
                            :class="`border-${matchColorsForCards(child.info.status.condition)}`"
                            v-b-tooltip.hover
                            :title="child.hostname"
                        >
                            <div class="d-flex align-items-center justify-content-between mb-05">
                                <div class="d-flex align-items-center">
                                    <div class="static__badge" v-if="child['is_main']"></div>
                                    <b-badge
                                        :variant="matchColorsForCards(child.info.status.condition)"
                                        class="font-medium-1"
                                        v-b-modal="`server-info-${child.id}`"
                                    >
                                        {{ child.mark }}
                                    </b-badge>
                                </div>
                                <div>
                                    <div class="d-flex align-items-center">
                                        <div>
                                            RPS:
                                            <span v-if="child.info.status['balancer']">
                                                {{ child.info.status['balancer'] }}
                                            </span>
                                            <span v-else class="text-secondary">null</span>
                                        </div>
                                        <div class="mx-05">||</div>
                                        <div>
                                            <span class="text-warning mr-05">PA:</span>
                                            <span class="text-white">{{
                                                child.info['performerActive']
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <span class="font-weight-bold font-medium-3">PID</span>
                                    <span class="mx-05 font-weight-bold font-medium-2">:</span>
                                    <span class="font-weight-bold font-medium-3">{{
                                        child.info.status.pid
                                    }}</span>
                                    <!--                                    <span class="font-medium-1">{{-->
                                    <!--                                        child.info.status.className-->
                                    <!--                                    }}</span>-->
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <b-button
                                        v-if="
                                            (child.info.status.condition !== 'denied' ||
                                                child.info.status.condition !== 'preparation') &&
                                            child.info.status.condition === 'passive'
                                        "
                                        v-b-modal="`clean-server-${child.id}`"
                                        variant="outline-info"
                                        class="clean__btn"
                                    >
                                        <CleanIcon size="20px" />
                                    </b-button>

                                    <b-button
                                        v-else
                                        class="w-100 p-05 px-2 mr-1"
                                        variant="outline-info"
                                        @click="getPerformerList(child.id)"
                                        v-b-modal="`server-performer-${child.id}`"
                                    >
                                        <feather-icon
                                            :class="
                                                matchColorsForCardButtons(
                                                    child.info.status.condition
                                                )
                                            "
                                            icon="SettingsIcon"
                                            size="20"
                                        />
                                    </b-button>

                                    <b-button
                                        class="w-100 p-05 px-3"
                                        :variant="
                                            matchColorsForCardButtons(child.info.status.condition)
                                        "
                                        v-b-modal="`server-${child.id}`"
                                        :disabled="
                                            child.info.status.condition === 'denied' ||
                                            child.info.status.condition === 'preparation'
                                        "
                                    >
                                        <feather-icon
                                            :class="
                                                matchColorsForCardButtons(
                                                    child.info.status.condition
                                                )
                                            "
                                            :icon="matchIconsForButton(child.info.status.condition)"
                                            size="20"
                                        />
                                    </b-button>
                                </div>
                            </div>

                            <!--         INFO MODAL JSON          -->
                            <b-modal
                                :id="`server-info-${child.id}`"
                                centered
                                :title="child.info.status.className"
                                size="lg"
                            >
                                <div class="d-flex flex-column text-center">
                                    <span class="font-weight-bold font-medium-3">{{
                                        child.hostname
                                    }}</span>
                                    <span class="font-small-3 text-secondary">{{
                                        child.info.status.startedAt
                                    }}</span>
                                </div>
                                <div v-if="child.info.status.info && child.info.status.info.length">
                                    <div
                                        v-for="(row, index) in child.info.status.info"
                                        :key="index"
                                    >
                                        <div>{{ row }}</div>
                                    </div>
                                </div>
                                <div v-else>{{ $t('no_data') }}</div>
                            </b-modal>

                            <!--         PERFORMER MODAL          -->
                            <b-modal
                                :id="`server-performer-${child.id}`"
                                centered
                                :title="`${child.mark} : ${child.info.status.className}`"
                                size="lg"
                            >
                                <progress-linear v-if="requestPending" />
                                <b-table
                                    v-else
                                    :fields="fields"
                                    :items="tableItems"
                                    :responsive="true"
                                    borderless
                                    class="news__table"
                                    outlined
                                    striped
                                >
                                    <template #cell(id)="{ item }">
                                        {{ item.id }}
                                    </template>

                                    <template #cell(show_details)="row">
                                        <div>
                                            <b-button
                                                :class="openedButton ? 'opened' : ''"
                                                class="svg__button p-025"
                                                size="sm"
                                                variant="outline-primary"
                                                @click="toggleDetails(child, row)"
                                            >
                                                <feather-icon
                                                    :icon="
                                                        row.item._showDetails
                                                            ? 'ArrowUpIcon'
                                                            : 'ArrowDownIcon'
                                                    "
                                                    class="svg__icon"
                                                    size="16"
                                                />
                                            </b-button>
                                        </div>
                                    </template>

                                    <template #row-details>
                                        <span>
                                            <pre>{{
                                                JSON.stringify(performerStatusResponse, null, 2)
                                            }}</pre>
                                        </span>
                                    </template>

                                    <template #cell(actions)="{ item }">
                                        <b-button
                                            @click="killPerformer(child, item)"
                                            class="float-right p-025"
                                            variant="outline-danger"
                                        >
                                            <feather-icon icon="Trash2Icon" size="14" />
                                        </b-button>
                                    </template>
                                </b-table>

                                <template #modal-footer>
                                    <b-button
                                        @click="getPerformerList(child.id)"
                                        variant="outline-primary"
                                    >
                                        <feather-icon icon="RefreshCcwIcon" />
                                    </b-button>
                                </template>
                            </b-modal>

                            <!--         PLAY/PAUSE MODAL          -->
                            <b-modal
                                :id="`server-${child.id}`"
                                centered
                                :title="`${child.info.status.className} [${child.mark}]`"
                            >
                                <p class="my-4" v-if="child.info.status.condition === 'active'">
                                    {{ $t('titles.server_actions_verification_stop') }}?
                                    <span class="d-block mt-1 font-medium-4"
                                        ><strong>
                                            <b-badge variant="secondary">{{ child.mark }}</b-badge>
                                            : {{ child.hostname }}</strong
                                        ></span
                                    >
                                </p>
                                <p class="my-4" v-else>
                                    {{ $t('titles.server_actions_verification_start') }}?
                                    <span class="d-block mt-1 font-medium-4"
                                        ><strong>
                                            <b-badge variant="secondary">{{ child.mark }}</b-badge>
                                            : {{ child.hostname }}</strong
                                        ></span
                                    >
                                </p>

                                <template #modal-footer="{ cancel }">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <b-button @click="cancel()" variant="danger"
                                            >{{ $t('titles.cancel') }}
                                        </b-button>
                                        <b-button
                                            @click="triggerServer(child)"
                                            class="ml-1"
                                            variant="success"
                                            >{{ $t('titles.yes') }}
                                        </b-button>
                                    </div>
                                </template>
                            </b-modal>

                            <!--         CLEAN MODAL          -->
                            <b-modal
                                :id="`clean-server-${child.id}`"
                                centered
                                :title="`${$t('titles.clean_queue')} [${child.mark}]`"
                            >
                                <p class="my-4">
                                    {{ $t('titles.server_actions_verification_clean') }}?
                                    <span class="d-block mt-1 font-medium-4"
                                        ><strong>
                                            <b-badge variant="secondary">{{ child.mark }}</b-badge>
                                            : {{ child.hostname }}</strong
                                        ></span
                                    >
                                </p>

                                <template #modal-footer="{ cancel }">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <b-button @click="cancel()" variant="danger"
                                            >{{ $t('titles.cancel') }}
                                        </b-button>
                                        <b-button
                                            @click="cleanQueue(child.id)"
                                            class="ml-1"
                                            variant="success"
                                            >{{ $t('titles.yes') }}
                                        </b-button>
                                    </div>
                                </template>
                            </b-modal>
                        </div>
                        <!--         TURN ON/OFF MODAL [CLUSTER]          -->
                        <b-modal :id="`cluster-${server.id}`" centered :title="server.mark">
                            <p class="my-4" v-if="server.status === 'active'">
                                {{ $t('titles.cluster_actions_verification_stop') }}?
                                <span class="d-block mt-1 font-medium-4">
                                    <strong>
                                        <b-badge variant="secondary">{{ server.mark }}</b-badge>
                                    </strong></span
                                >
                            </p>
                            <p class="my-4" v-else>
                                {{ $t('titles.cluster_actions_verification_start') }}?
                                <span class="d-block mt-1 font-medium-4">
                                    <strong>
                                        <b-badge variant="secondary">{{ server.mark }}</b-badge>
                                    </strong>
                                </span>
                            </p>

                            <template #modal-footer="{ cancel }">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="cancel()" variant="danger"
                                        >{{ $t('titles.cancel') }}
                                    </b-button>
                                    <b-button
                                        @click="triggerCluster(server.id, server.status)"
                                        class="ml-1"
                                        variant="success"
                                        >{{ $t('titles.yes') }}
                                    </b-button>
                                </div>
                            </template>
                        </b-modal>
                    </div>
                </b-card>

                <!--    SERVERS    -->
                <b-card
                    v-else
                    :class="`border-${matchColorsForCards(server.info.status.condition)}`"
                >
                    <div class="cursor-pointer" v-b-tooltip.hover :title="server.hostname">
                        <div class="d-flex align-items-center justify-content-between mb-05">
                            <b-badge
                                :variant="matchColorsForCards(server.info.status.condition)"
                                class="font-medium-1"
                                v-b-modal="`server-info-${server.id}`"
                            >
                                {{ server.mark }}
                            </b-badge>
                            <div class="d-flex align-items-center">
                                <div>
                                    RPS:
                                    <span v-if="server.info.status['balancer']">
                                        {{ server.info.status['balancer'] }}
                                    </span>
                                    <span v-else class="text-secondary">null</span>
                                </div>
                                <div class="mx-05">||</div>
                                <div>
                                    <span class="text-warning mr-05">PA:</span>
                                    <span class="text-white">{{
                                        server.info['performerActive']
                                    }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <!--                                <div>-->
                                <!--                                    Balancer:-->
                                <!--                                    <span v-if="server.info.status['balancer']">-->
                                <!--                                        {{ server.info.status['balancer'] }}-->
                                <!--                                    </span>-->
                                <!--                                    <span v-else class="text-secondary">null</span>-->
                                <!--                                </div>-->
                                <!--                                <div>-->
                                <!--                                    <span class="text-warning mr-05">PA:</span>-->
                                <!--                                    <span class="text-white">{{-->
                                <!--                                        server.info['performerActive']-->
                                <!--                                    }}</span>-->
                                <!--                                </div>-->
                                <span class="font-weight-bold font-medium-3">PID</span>
                                <span class="mx-05 font-weight-bold font-medium-2">:</span>
                                <span class="font-weight-bold font-medium-3">{{
                                    server.info.status.pid
                                }}</span>
                                <!--                                <span class="font-medium-1">{{-->
                                <!--                                    server.info.status.className-->
                                <!--                                }}</span>-->
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <b-button
                                    v-if="server.info.status.condition === 'passive'"
                                    v-b-modal="`clean-server-${server.id}`"
                                    variant="outline-info"
                                    class="clean__btn"
                                >
                                    <CleanIcon size="20px" />
                                </b-button>

                                <b-button
                                    v-else-if="
                                        server.info.status.condition === 'active' ||
                                        server.info.status.condition === 'checking' ||
                                        server.info.status.condition === 'waiting'
                                    "
                                    class="w-100 p-05 px-2 mr-1"
                                    variant="outline-info"
                                    @click="getPerformerList(server.id)"
                                    v-b-modal="`server-performer-${server.id}`"
                                >
                                    <feather-icon
                                        :class="
                                            matchColorsForCardButtons(server.info.status.condition)
                                        "
                                        icon="SettingsIcon"
                                        size="20"
                                    />
                                </b-button>

                                <b-button
                                    class="w-100 p-05 px-3"
                                    :variant="
                                        matchColorsForCardButtons(server.info.status.condition)
                                    "
                                    v-b-modal="`server-${server.id}`"
                                    :disabled="
                                        server.info.status.condition === 'denied' ||
                                        server.info.status.condition === 'preparation'
                                    "
                                >
                                    <feather-icon
                                        :class="
                                            matchColorsForCardButtons(server.info.status.condition)
                                        "
                                        :icon="matchIconsForButton(server.info.status.condition)"
                                        size="20"
                                    />
                                </b-button>
                            </div>
                        </div>

                        <!--         INFO MODAL JSON          -->
                        <b-modal
                            :id="`server-info-${server.id}`"
                            centered
                            :title="server.info.status.className"
                            size="lg"
                            v-if="server.info.status.info"
                        >
                            <div class="d-flex flex-column text-center">
                                <span class="font-weight-bold font-medium-3">{{
                                    server.hostname
                                }}</span>
                                <span class="font-small-3 text-secondary">{{
                                    server.info.status.startedAt
                                }}</span>
                            </div>
                            <div v-if="server.info.status.info || server.info.status.info.length">
                                <!--                                <div v-for="(row, index) in server.info.status.info" :key="index">-->
                                <!--                                    <div>{{ row }}</div>-->
                                <!--                                </div>-->

                                <pre>{{ JSON.stringify(server.info.status.info, null, 2) }}</pre>
                            </div>
                            <div v-else>{{ $t('no_data') }}</div>
                        </b-modal>

                        <!--         PERFORMER MODAL          -->
                        <b-modal
                            :id="`server-performer-${server.id}`"
                            centered
                            :title="`${server.mark} : ${server.info.status.className}`"
                            size="lg"
                        >
                            <progress-linear v-if="requestPending" />
                            <b-table
                                v-else
                                :fields="fields"
                                :items="tableItems"
                                :responsive="true"
                                borderless
                                class="news__table"
                                outlined
                                striped
                            >
                                <template #cell(id)="{ item }">
                                    {{ item.id }}
                                </template>

                                <template #cell(show_details)="row">
                                    <div>
                                        <b-button
                                            :class="openedButton ? 'opened' : ''"
                                            class="svg__button p-025"
                                            size="sm"
                                            variant="outline-primary"
                                            @click="toggleDetails(server, row)"
                                        >
                                            <feather-icon
                                                :icon="
                                                    row.item._showDetails
                                                        ? 'ArrowUpIcon'
                                                        : 'ArrowDownIcon'
                                                "
                                                class="svg__icon"
                                                size="16"
                                            />
                                        </b-button>
                                    </div>
                                </template>

                                <template #row-details>
                                    <span>
                                        <pre>{{
                                            JSON.stringify(performerStatusResponse, null, 2)
                                        }}</pre>
                                    </span>
                                </template>

                                <template #cell(actions)="{ item }">
                                    <b-button
                                        @click="killPerformer(server, item)"
                                        class="float-right p-025"
                                        variant="outline-danger"
                                    >
                                        <feather-icon icon="Trash2Icon" size="14" />
                                    </b-button>
                                </template>
                            </b-table>

                            <template #modal-footer>
                                <b-button
                                    @click="getPerformerList(server.id)"
                                    variant="outline-primary"
                                >
                                    <feather-icon icon="RefreshCcwIcon" />
                                </b-button>
                            </template>
                        </b-modal>

                        <!--         PLAY/PAUSE MODAL          -->
                        <b-modal
                            :id="`server-${server.id}`"
                            centered
                            :title="`${server.info.status.className} [${server.mark}]`"
                        >
                            <p class="my-4" v-if="server.info.status.condition === 'active'">
                                {{ $t('titles.server_actions_verification_stop') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>
                                        <b-badge variant="secondary">{{ server.mark }}</b-badge>
                                        : {{ server.hostname }}</strong
                                    ></span
                                >
                            </p>
                            <p class="my-4" v-else>
                                {{ $t('titles.server_actions_verification_start') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>
                                        <b-badge variant="secondary">{{ server.mark }}</b-badge>
                                        : {{ server.hostname }}</strong
                                    ></span
                                >
                            </p>

                            <template #modal-footer="{ cancel }">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="cancel()" variant="danger"
                                        >{{ $t('titles.cancel') }}
                                    </b-button>
                                    <b-button
                                        @click="triggerServer(server)"
                                        class="ml-1"
                                        variant="success"
                                        >{{ $t('titles.yes') }}
                                    </b-button>
                                </div>
                            </template>
                        </b-modal>

                        <!--         CLEAN MODAL          -->
                        <b-modal
                            :id="`clean-server-${server.id}`"
                            centered
                            :title="`${$t('titles.clean_queue')} [${server.mark}]`"
                        >
                            <p class="my-4">
                                {{ $t('titles.server_actions_verification_clean') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>
                                        <b-badge variant="secondary">{{ server.mark }}</b-badge>
                                        : {{ server.hostname }}</strong
                                    ></span
                                >
                            </p>

                            <template #modal-footer="{ cancel }">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="cancel()" variant="danger"
                                        >{{ $t('titles.cancel') }}
                                    </b-button>
                                    <b-button
                                        @click="cleanQueue(server.id)"
                                        class="ml-1"
                                        variant="success"
                                        >{{ $t('titles.yes') }}
                                    </b-button>
                                </div>
                            </template>
                        </b-modal>
                    </div>
                </b-card>
            </div>
        </div>

        <!--         ORCHESTRATOR MODAL          -->
        <b-modal id="orchestratorModal" centered title="Orchestrator" v-if="orchestrator">
            <div class="font-medium-3 font-weight-bold text-center mb-1">
                {{ orchestrator.title }}
            </div>
            <div class="font-medium-3 font-weight-bold text-white-50">
                <span class="text-warning mr-05">PID:</span>
                <b-badge variant="warning">{{ orchestrator.pid }}</b-badge>
            </div>
            <div class="font-medium-3 font-weight-bold">
                <span class="text-primary mr-05">BALANCER:</span>
                <b-badge variant="primary">{{ orchestrator.balancer }}</b-badge>
            </div>
            <div class="font-medium-3 font-weight-bold text-white-50 mb-2">
                STARTED AT: {{ orchestrator.startedAt }}
            </div>
            <div v-if="orchestrator.info" class="font-medium-1">
                <pre>{{ JSON.stringify(orchestrator.info, null, 2) }}</pre>
            </div>
            <template #modal-footer>
                <b-button @click="checkOrchestratorStatus" variant="outline-primary" class="mr-1">
                    <feather-icon icon="RefreshCwIcon" size="16" />
                </b-button>
                <b-button
                    @click="triggerOrchestrator"
                    :variant="orchestrator.condition === 'active' ? 'danger' : 'success'"
                >
                    <span v-if="orchestrator.condition === 'passive'">{{ $t('button.on') }}</span>
                    <span v-else-if="orchestrator.condition === 'active'">{{
                        $t('button.off')
                    }}</span>
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style scoped lang="scss">
.p-05 {
    padding: 0.5rem;
}

.mx-05 {
    margin: 0 0.5rem;
}

.control {
    &-actions {
    }
}

.ml-05 {
    margin-left: 0.5rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.text__overflow {
    width: auto;
    overflow-x: hidden;
}

.card.active {
    border: 2px solid var(--success);
}

.card.not-active {
    border: 2px solid var(--danger);
}

.status-detector {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border: 2px solid var(--danger);
    background: var(--danger);
    border-radius: 50%;
}

.secondary-bg {
    border: 2px solid var(--secondary);
}

.clean__btn {
    padding: 0.5rem 1.5rem;
    height: 100%;
    margin-right: 1rem;
}

.card-body {
    padding: 1rem;
}

.switch__button {
    //padding-left: 1rem;
    //position: absolute;
    //top: 2.5rem;
    //z-index: 123;
}

.inner-card {
    padding: 0.5rem;
    border-radius: 1rem;
    margin: 1rem 0;
}

.mr-05 {
    margin-right: 0.5rem;
}

.static__badge {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: var(--info);
    margin-right: 0.5rem;
}

.p-025 {
    padding: 0.25rem;
}

.svg__icon {
    pointer-events: none;
}

.border-2 {
    border-width: 2px !important;
}

//@media only screen and (max-width: 1199px) {
//    .switch__button {
//        left: 280px;
//    }
//}
</style>
