<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import { generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppRoadmapProductHistory',
    components: {
        ProgressLinear,
        PageTitle,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllItems();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'season_id',
                    label: this.$t('titles.season')
                },
                {
                    key: 'level_id',
                    label: this.$t('titles.level')
                },
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id')
                },
                {
                    key: 'product_type',
                    label: this.$t('titles.product_type')
                },
                {
                    key: 'product_value',
                    label: this.$t('titles.product_value')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,

        async getAllItems() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.roadmapTaskGtwApi.fetchProductHistory(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAllItems();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.roadmap_product_history') }}</template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
            </b-table>
            <h4 class="text-center my-1" v-if="items.length === 0">{{ $t('no_data') }}</h4>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.buttons-block {
    border: 2px dotted var(--secondary);
}

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}

.table-badge__item {
    margin: 4px;
}

.badge-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.product-card {
    border: 2px dashed var(--secondary);
}

.py-05 {
    padding: 0.5rem 1rem;
}

.p-05 {
    padding: 0.5rem;
}
</style>
