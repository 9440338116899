<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BBadge,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { updateObjectToNew } from '@/util/helper';
import api from '@/services/api';
import vSelect from 'vue-select';

export default {
    name: 'ViewSandbox',
    components: {
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BBadge,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            childItems: [],
            userItems: [],
            status: false,
            dataForModal: {
                name: ''
            },
            dataForUsersModal: {
                name: ''
            },
            dataForChildModal: {
                group_id: null,
                category_id: null,
                package: null,
                proc: null
            },
            fillUserBody: {
                group_id: null,
                fileList: []
            },
            chargeBody: {
                category_id: null,
                group_id: null,
                charge_id: null
            },
            groupList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            usersPagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            categoryList: [],
            packagesList: [],
            chargesList: [],
            requestPending: false,
            childRequestPending: false,
            userRequestPending: false,
            openedButton: false,
            activeCategory: null,
            fillUserModal: false,
            userModal: false,
            chargeModal: false,
            isCreatingChargeModal: null,
            userId: null
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllGroups();
                this.getCategories();
                this.getAllSandboxUsers();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type_name',
                    label: this.$t('titles.type')
                },
                {
                    key: 'charge',
                    label: this.$t('titles.charges')
                },
                {
                    key: 'charge_new',
                    label: this.$t('titles.charges') + '[NEW]'
                },
                {
                    key: 'is_subscriber',
                    label: this.$t('titles.is_subscriber')
                },
                {
                    key: 'by_subscriber',
                    label: this.$t('titles.by_subscriber')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        childFields() {
            return [
                {
                    key: 'calculate',
                    label: this.$t('titles.drop_chance')
                },
                {
                    key: 'package',
                    label: this.$t('titles.package')
                },
                {
                    key: 'proc',
                    label: this.$t('titles.weight')
                },
                {
                    key: 'child_actions',
                    label: ''
                }
            ];
        },

        userFields() {
            return [
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },

        hasUserItems() {
            return this.userItems && this.userItems.length > 0;
        },

        showUserPagination() {
            return this.hasUserItems && !this.userRequestPending;
        }
    },

    async mounted() {
        await this.fetchRandomizerPackages();
        await this.fetchCharges();
    },

    methods: {
        async fetchRandomizerPackages() {
            const body = {
                page: 1,
                limit: 1000
            };
            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.packagesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async fetchCharges() {
            const body = {
                page: 1,
                limit: 100
            };
            try {
                const { data } = await api.billingCharges.fetchAllItems(body);
                this.chargesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getCategories() {
            const body = {
                page: 1,
                limit: 50,
                group_id: this.$route.params.id
            };
            try {
                const { data } = await api.sandboxServiceApi.fetchAllCategories(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
                this.categoryList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllGroups() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.sandboxServiceApi.fetchAllGroups(params);
                this.groupList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllSandboxUsers() {
            const params = {
                page: this.usersPagination.current,
                limit: this.usersPagination.perPage,
                group_id: parseInt(this.$route.params.id)
            };

            try {
                const { data } = await api.sandboxServiceApi.fetchAllSandboxUsers(params);
                this.userItems = data.data.list;
                this.usersPagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllItems() {
            const categoryItem = this.activeCategory?.item;
            this.openedButton = !this.openedButton;
            let response = [];
            let items = [];
            const params = {
                // page: this.childPagination.current,
                // limit: this.childPagination.perPage,
                page: 1,
                limit: 20,
                category_id: categoryItem?.id,
                group_id: parseInt(this.$route.params.id)
            };
            try {
                this.requestPending = true;
                const { data } = await api.sandboxServiceApi.fetchAllItems(params);
                response = data.data.list;
                this.items.forEach((category, index) => {
                    response.forEach((item) => {
                        if (index === this.activeCategory.index) {
                            items.push(item);
                            this.items[index] = {
                                ...category,
                                items: items
                            };
                        }
                    });
                });
                items = [];
                this.childItems = response;
                // this.childPagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async getOneGroup(item) {
            this.updatingItem = item;
            await api.sandboxServiceApi
                .fetchOneGroup(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getOneItem(item) {
            this.updatingItem = item;
            if (item) {
                await this.openChildModal();
                await api.sandboxServiceApi
                    .fetchOneItem(item.value.id)
                    .then((response) => {
                        // merge Object for need data
                        const res = response.data.data;

                        Object.keys(res).forEach((item) => {
                            if (item in this.dataForChildModal) {
                                this.dataForChildModal[item] = res[item];
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        async createGroup(body) {
            await api.sandboxServiceApi
                .createGroup(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAllGroups();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async createItem(body) {
            body.group_id = this.$route.params.id;
            await api.sandboxServiceApi
                .createItem(body)
                .then(async () => {
                    await this.getAllGroups();
                    await this.getCategories();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                    this.$refs.createUpdateChildModal.hide();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateGroup(id, body) {
            await api.sandboxServiceApi
                .updateGroup(id, body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllGroups();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateItem(id, body) {
            await api.sandboxServiceApi
                .updateItem(id.value.id, body)
                .then(() => {
                    this.getAllGroups();
                    this.getCategories();
                    this.$refs.createUpdateChildModal.hide();
                    this.uploadImage = null;
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async getOneCharge(id) {
            try {
                const { data } = await api.sandboxServiceApi.fetchOneSandboxCharge(id);
                this.chargeBody = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async createCharge() {
            try {
                await api.sandboxServiceApi.createSandboxCharge({
                    ...this.chargeBody,
                    group_id: parseInt(this.$route.params.id)
                });
                this.chargeModal = false;
            } catch (e) {
                console.error(e);
            } finally {
                await this.getAllGroups();
                await this.getCategories();
            }
        },

        async updateCharge(id, body) {
            delete body.id;
            try {
                await api.sandboxServiceApi.updateSandboxCharge(id, body);
            } catch (e) {
                console.error(e);
            } finally {
                await this.getAllGroups();
                await this.getCategories();
                this.chargeModal = false;
            }
        },

        async deleteCharge(id) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.sandboxServiceApi
                        .deleteSandboxCharge(id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.getAllGroups();
                            await this.getCategories();
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async deleteGroup(id) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.sandboxServiceApi
                        .deleteGroup(id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.$router.push({ name: 'sandbox', params: { id: '1' } });
                            await this.getAllGroups();
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async deleteItem(val) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.sandboxServiceApi
                        .deleteItem(val.value.id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            // await this.getAllGroups();
                            await this.getCategories();
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async deleteUser(val) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.sandboxServiceApi
                        .deleteSandboxUser(val.user_id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.getAllSandboxUsers();
                            this.userModal = false;
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateGroup(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createGroup(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async submitChildModal() {
            this.$refs.createUpdateChildModalValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForChildModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    // this.dataForChildModal.activation_items =
                    //     this.dataForChildModal.activation_items.map((str) => parseInt(str));

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async fillGroup() {
            const formData = new FormData();
            formData.append('fileList', this.fillUserBody.fileList[0].file);
            formData.append('groupId', this.fillUserBody.group_id[0].id);

            try {
                await api.sandboxServiceApi.fillGroup(formData);
                this.fillUserModal = false;
            } catch (e) {
                console.error(e);
            }
        },

        async addUser() {
            try {
                await api.sandboxServiceApi.createSandboxUser({
                    user_id: this.userId,
                    group_id: parseInt(this.$route.params.id)
                });
                await this.getAllSandboxUsers();
                this.userModal = false;
            } catch (e) {
                console.error(e);
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAllGroups();
            this.getCategories();
        },

        beforeClose() {
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        toggleDetails(row) {
            // this.setChildPaginationByDefault();
            this.items.forEach((item) => {
                if (row.item.id === item.id) {
                    row.toggleDetails();
                    this.activeCategory = row;
                } else {
                    item._showDetails = false;
                }
            });
            if (!row['detailsShowing']) {
                this.getAllItems(row);
            }
        },

        async openChildModal(item) {
            this.$refs.createUpdateChildModal.show();
            this.dataForChildModal = {
                group_id: null,
                category_id: null,
                proc: null,
                package: null
            };
            if (item?.id) {
                this.dataForChildModal.category_id = item.id;
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        openFillModal() {
            this.fillUserBody.group_id = this.groupList.filter((group) => {
                if (group.id === parseInt(this.$route.params.id)) {
                    return {
                        id: group.id,
                        name: group.name
                    };
                }
            });
            this.fillUserModal = true;
        },

        openChargeModal(id, method) {
            this.chargeModal = true;
            this.chargeBody.group_id = this.$route.params.id;
            this.chargeBody.category_id = id;
            if (method === 'create') {
                this.isCreatingChargeModal = true;
                this.chargeBody.charge_id = null;
            } else {
                this.isCreatingChargeModal = false;
                this.getOneCharge(id);
            }
        },

        openUserModal() {
            this.userModal = true;
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.sandbox') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <div class="d-flex flex-column flex-sm-row">
                    <b-button
                        @click="openFillModal"
                        class="ml-auto mr-0 mr-sm-1 mb-1 mb-sm-0"
                        variant="success"
                    >
                        {{ $t('titles.fill_group') }}
                    </b-button>
                    <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                        {{ $t('add.group') }}
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </div>
            </template>
        </page-title>

        <div class="nav nav-tabs flex-nowrap overflow-x-scroll">
            <router-link
                v-for="(group, index) in groupList"
                :key="index"
                :to="{ name: 'sandbox', params: { id: group.id } }"
                active-class="active"
                class="nav-link"
                style="min-width: fit-content"
            >
                <span class="font-medium-3">
                    <b-avatar :badge="`${group.users}`" badge-variant="warning" variant="primary">
                        <span>{{ group.name }}</span>
                    </b-avatar>
                </span>
                <div class="d-flex pl-1" v-if="$route.params.id == group.id">
                    <b-button
                        class="px-1 py-05"
                        variant="flat-danger"
                        @click="deleteGroup(group.id)"
                    >
                        <feather-icon class="text-danger mr-0" icon="TrashIcon" size="18" />
                    </b-button>
                    <b-button
                        class="px-1 py-05"
                        variant="flat-secondary"
                        @click="getOneGroup(group)"
                    >
                        <feather-icon class="text-secondary mr-0" icon="EditIcon" size="18" />
                    </b-button>
                </div>
            </router-link>
        </div>

        <div class="nav nav-tabs flex-wrap overflow-x-scroll">
            <b-tabs class="w-100">
                <b-tab active @click="getCategories">
                    <template #title>
                        <feather-icon icon="MenuIcon" />
                        <span>{{ $t('titles.categories') }}</span>
                    </template>

                    <!--  CATEGORIES TABLE  -->
                    <div class="card">
                        <progress-linear v-if="requestPending" />
                        <b-table
                            v-else
                            :fields="fields"
                            :items="items"
                            :responsive="true"
                            borderless
                            class="news__table"
                            outlined
                            striped
                        >
                            <template #cell(charge)="{ item }">
                                <b-badge :variant="item['charge_new'] ? 'secondary' : 'primary'">
                                    <span :class="item['charge_new'] ? 'line-through' : ''">{{
                                        item['charge'].name
                                    }}</span>
                                </b-badge>
                            </template>

                            <template #cell(charge_new)="{ item }">
                                <div v-if="item['charge_new']">
                                    <b-badge
                                        v-if="item['charge_new']"
                                        :variant="item['charge_new'] ? 'primary' : 'secondary'"
                                        class="mb-05"
                                    >
                                        <span>{{ item['charge_new']['charge_name'] }}</span>
                                    </b-badge>
                                    <div>
                                        <b-button
                                            class="btn-icon mr-1"
                                            variant="outline-secondary"
                                            @click="openChargeModal(item['charge_new'].id, 'edit')"
                                        >
                                            <feather-icon icon="EditIcon" />
                                        </b-button>
                                        <b-button
                                            class="btn-icon"
                                            variant="outline-danger"
                                            @click="deleteCharge(item['charge_new'].id)"
                                        >
                                            <feather-icon icon="Trash2Icon" />
                                        </b-button>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button
                                        variant="outline-success"
                                        class="btn-icon"
                                        @click="openChargeModal(item.id, 'create')"
                                    >
                                        <!--                            @click="openChargeModal(item['charge_new'].id)"-->
                                        <feather-icon icon="PlusIcon" size="18" />
                                    </b-button>
                                </div>
                            </template>

                            <template #cell(is_subscriber)="{ item }">
                                <feather-icon
                                    :class="[item.is_subscriber ? 'text-success' : 'text-danger']"
                                    :icon="item.is_subscriber ? 'CheckIcon' : 'XIcon'"
                                    size="20"
                                />
                            </template>

                            <template #cell(by_subscriber)="{ item }">
                                <feather-icon
                                    :class="[item.by_subscriber ? 'text-success' : 'text-danger']"
                                    :icon="item.by_subscriber ? 'CheckIcon' : 'XIcon'"
                                    size="20"
                                />
                            </template>

                            <template #cell(show_details)="row">
                                <div>
                                    <b-button
                                        :class="openedButton ? 'opened' : ''"
                                        class="p-1 svg__button"
                                        size="sm"
                                        variant="outline-primary"
                                        @click="toggleDetails(row)"
                                    >
                                        <feather-icon
                                            :icon="
                                                row.item._showDetails
                                                    ? 'ArrowUpIcon'
                                                    : 'ArrowDownIcon'
                                            "
                                            class="svg__icon"
                                            size="20"
                                        />
                                    </b-button>
                                </div>
                            </template>

                            <template #row-details="row">
                                <progress-linear v-if="childRequestPending" />
                                <b-card v-else class="child__table">
                                    <b-table
                                        :fields="childFields"
                                        :items="row.item.items"
                                        :responsive="true"
                                        borderless
                                        outlined
                                        striped
                                        thead-class="bg__red"
                                    >
                                        <template #cell(package)="{ item }">
                                            <b-badge
                                                class="table-badge__item"
                                                :variant="item.value.package.color"
                                            >
                                                <span>{{
                                                    item.value.package.text.toUpperCase()
                                                }}</span>
                                            </b-badge>
                                        </template>

                                        <template #cell(proc)="{ item }">
                                            <span>{{ item.value.proc }}</span>
                                        </template>

                                        <template #cell(child_actions)="{ item }">
                                            <div class="d-flex justify-content-end">
                                                <b-button
                                                    v-b-tooltip.hover.top="`${$t('button.edit')}`"
                                                    class="btn-icon"
                                                    variant="flat-dark"
                                                    @click="getOneItem(item)"
                                                >
                                                    <feather-icon icon="EditIcon" size="16" />
                                                </b-button>
                                                <b-button
                                                    v-b-tooltip.hover.top="`${$t('button.delete')}`"
                                                    class="btn-icon"
                                                    variant="flat-danger"
                                                    @click="deleteItem(item)"
                                                >
                                                    <feather-icon icon="Trash2Icon" size="16" />
                                                </b-button>
                                            </div>
                                        </template>
                                    </b-table>

                                    <!--  CHILD PAGINATION  -->
                                    <!--                        <b-row class="my-2">-->
                                    <!--                            <b-col class="offset-3" cols="9">-->
                                    <!--                                <b-pagination-->
                                    <!--                                    v-if="showChildPagination"-->
                                    <!--                                    v-model="childPagination.current"-->
                                    <!--                                    :per-page="childPagination.perPage"-->
                                    <!--                                    :total-rows="childPagination.totalItem"-->
                                    <!--                                    class="float-right mr-4 mb-0"-->
                                    <!--                                    first-number-->
                                    <!--                                    last-number-->
                                    <!--                                    @input="handleChildPagination"-->
                                    <!--                                />-->
                                    <!--                            </b-col>-->
                                    <!--                        </b-row>-->
                                </b-card>
                            </template>

                            <template #cell(actions)="{ item }">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.create_product')}`"
                                        class="btn-icon"
                                        variant="flat-success"
                                        @click="openChildModal(item)"
                                    >
                                        <feather-icon icon="PlusIcon" size="16" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <!--  PAGINATION  -->
                    <b-row>
                        <b-col class="offset-3" cols="9">
                            <b-pagination
                                v-if="showPagination"
                                v-model="pagination.current"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalItem"
                                class="float-right mr-4 mb-0"
                                first-number
                                last-number
                            />
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab @click="getAllSandboxUsers">
                    <template #title>
                        <feather-icon icon="UserIcon" />
                        <span>{{ $t('titles.users') }}</span>
                    </template>
                    <!--  USERS TABLE  -->
                    <div class="card" style="width: 340px">
                        <progress-linear v-if="requestPending" />
                        <div class="d-flex flex-column flex-sm-row" v-else>
                            <b-button
                                @click="openUserModal"
                                variant="success"
                                class="ml-sm-1 p-1 mb-sm-auto order-1 order-sm-2 mb-1 mb-sm-0"
                            >
                                <feather-icon icon="PlusIcon" size="18" />
                            </b-button>
                            <b-table
                                :fields="userFields"
                                :items="userItems"
                                :responsive="true"
                                borderless
                                class="news__table order-2 order-sm-1"
                                outlined
                                striped
                            >
                                <template #cell(charge)="{ item }">
                                    <b-badge
                                        :variant="item['charge_new'] ? 'secondary' : 'primary'"
                                    >
                                        <span :class="item['charge_new'] ? 'line-through' : ''">{{
                                            item['charge'].name
                                        }}</span>
                                    </b-badge>
                                </template>

                                <template #cell(actions)="{ item }">
                                    <div class="d-flex justify-content-end">
                                        <b-button
                                            class="btn-icon"
                                            variant="flat-danger"
                                            @click="deleteUser(item)"
                                        >
                                            <feather-icon icon="Trash2Icon" size="16" />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>

                    <!--  USERS PAGINATION  -->
                    <b-row>
                        <b-col cols="12">
                            <b-pagination
                                v-if="showUserPagination"
                                v-model="usersPagination.current"
                                :per-page="usersPagination.perPage"
                                :total-rows="usersPagination.totalItem"
                                class="mb-2"
                                first-number
                                last-number
                            />
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </div>

        <!--  CHARGE MODAL  -->
        <b-modal
            id="chargeModal"
            v-model="chargeModal"
            :no-close-on-backdrop="true"
            :title="$t('titles.charge_modal')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="chargeModal = false"
        >
            <ValidationObserver ref="chargeModal">
                <b-row>
                    <!--    CHARGE ID    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.charges') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.charge')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="chargeBody.charge_id"
                                    :clearable="false"
                                    :options="chargesList"
                                    :placeholder="$t('choose.charge')"
                                    :reduce="(option) => option.id"
                                    label="name"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button v-if="isCreatingChargeModal" variant="success" @click="createCharge">
                    {{ $t('button.fill') }}
                </b-button>
                <b-button v-else variant="success" @click="updateCharge(chargeBody.id, chargeBody)">
                    {{ $t('button.fill') }}
                </b-button>
            </template>
        </b-modal>

        <!--  FILL USER MODAL  -->
        <b-modal
            id="fillUser"
            v-model="fillUserModal"
            :no-close-on-backdrop="true"
            :title="$t('titles.fill_group')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="fillUserModal = false"
        >
            <ValidationObserver ref="fillUser">
                <b-row>
                    <!--    GROUP ID    -->
                    <b-col cols="12">
                        <label>{{ $t('group') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('group')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="fillUserBody.group_id"
                                    :clearable="false"
                                    :options="groupList"
                                    :placeholder="$t('choose.group')"
                                    :reduce="(option) => option.id"
                                    label="name"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    CSV FILE    -->
                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.file') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="fillUserBody.fileList"
                                    :accept="'.csv'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_file')"
                                    :maxFiles="14"
                                    :maxSize="'30MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="fillGroup">
                    {{ $t('button.fill') }}
                </b-button>
            </template>
        </b-modal>

        <!--  USER MODAL  -->
        <b-modal
            id="userModal"
            v-model="userModal"
            :no-close-on-backdrop="true"
            :title="$t('titles.fill_group')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="userModal = false"
        >
            <ValidationObserver ref="userModal">
                <b-row>
                    <!--    USER ID    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.user_id') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.user_id')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="userId"
                                    :placeholder="$t('enter.user_id')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="addUser">
                    {{ $t('button.add') }}
                </b-button>
            </template>
        </b-modal>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.group') : $t('add.group')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    CREATE UPDATE CHILD MODAL    -->
        <b-modal
            id="createUpdateChildModal"
            ref="createUpdateChildModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.package') : $t('add.package')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateChildModalValidation">
                <b-row>
                    <!--     CATEGORY ID     -->
                    <b-col class="mb-1" cols="12">
                        <b-form-group :label="$t('titles.category')" label-for="categories">
                            <v-select
                                id="categories"
                                v-model="dataForChildModal.category_id"
                                :clearable="false"
                                :disabled="isViewModal || !!updatingItem"
                                :options="categoryList"
                                :placeholder="$t('choose.category')"
                                :reduce="(option) => option.id"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    PACKAGE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.packages') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.packages')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    id="categories"
                                    v-model="dataForChildModal.package"
                                    :clearable="false"
                                    :disabled="isViewModal"
                                    :options="packagesList"
                                    :placeholder="$t('choose.package')"
                                    :reduce="(option) => option.id"
                                    label="text"
                                >
                                    <template v-slot:option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PROC    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.weight') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.weight')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.proc"
                                    :placeholder="$t('enter.weight')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitChildModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}

.table-badge__item {
    margin: 4px;
}

.nav-tabs::-webkit-scrollbar {
    display: none;
}

.py-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.p-05 {
    padding: 0.5rem;
}

.line-through {
    text-decoration: line-through;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.px-05 {
    padding: 1rem 0.5rem;
}
</style>
