<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';

export default {
    name: 'ViewUcellBalance',
    components: { ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUcell.fetchBalance({
                    phone: parseInt(this.phone)
                });
                this.response = data.data;
                await this.JSONViewer(this.response);
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },

        JSONViewer(value) {
            console.log(value, 'value');
            if (typeof value === 'object') {
                console.log(Object.keys(value), 'keys');
                console.log(Object.values(value), 'values');
                console.log(Object.entries(value), 'entries');
            }
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>

                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <b-button @click="getResponse" variant="success">{{
                        $t('button.filter')
                    }}</b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <div v-else class="json__card card p-1">
                    <div v-if="response" class="json__content">
                        <!--                        <pre class="mb-0">{{ response }}</pre>-->
                        <div
                            class="mb-0"
                            v-for="(item, key, index) in response"
                            :key="item + index"
                        >
                            <p class="text-info mt-1 mb-0 font-weight-bold font-medium-2">
                                {{ key }}
                            </p>
                            <p
                                class="mb-0 text-uppercase font-weight-bold"
                                v-for="(childItem, key, index) in item"
                                :key="childItem + key + index"
                            >
                                <span class="text-warning font-medium-1">
                                    {{ key }}
                                </span>
                                <span class="font-weight-bold font-medium-1 mx-1">:</span>
                                <span class="text-success font-medium-1">
                                    {{ childItem }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <h4 v-else>No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
