import { render, staticRenderFns } from "./AppManualNotificationSegmentation.vue?vue&type=template&id=7450cc41&scoped=true&"
import script from "./AppManualNotificationSegmentation.vue?vue&type=script&lang=js&"
export * from "./AppManualNotificationSegmentation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7450cc41",
  null
  
)

export default component.exports