<script>
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BFormInput, BTable } from 'bootstrap-vue';

export default {
    name: 'AppSegmentationUserInfo',
    components: { BFormInput, BTable, ProgressLinear },
    data() {
        return {
            phone: null,
            requestPending: false,
            infoItems: [],
            balanceItems: []
        };
    },

    computed: {
        infoFields() {
            return [
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'value',
                    label: this.$t('titles.value')
                }
            ];
        },
        balanceFields() {
            return [
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'value',
                    label: this.$t('titles.value')
                }
            ];
        }
    },

    methods: {
        async getSegmentationUserInfo() {
            this.requestPending = true;
            const body = {
                phone: parseInt(this.phone),
                merchant_id: parseInt(MERCHANT_ID),
                event_id: parseInt(this.$route.params.id)
            };
            try {
                const { data } = await api.segmentationApi.fetchUserInfo(body);
                for (let key in data.data.info) {
                    this.infoItems.push({ name: key, value: data.data.info[key] });
                }
                for (let key in data.data.balance) {
                    this.balanceItems.push({ name: key, value: data.data.balance[key] });
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="user-info">
        <!--    FILTER    -->
        <div class="row flex-column flex-sm-row align-items-center mb-2">
            <div class="col-12 col-lg-1">
                <h4 class="mb-1 mb-lg-0">{{ $t('filter_title') }}:</h4>
            </div>
            <div class="col-12 col-sm-8 col-md-10 col-lg-10">
                <b-form-input v-model="phone" :placeholder="$t('enter.phone_number')" size="md" />
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-1">
                <b-button
                    @click="getSegmentationUserInfo"
                    variant="outline-success"
                    class="search__btn mt-1 mt-sm-0"
                >
                    <feather-icon icon="SearchIcon" size="24" />
                </b-button>
            </div>
        </div>

        <div class="row">
            <!--      INFO      -->
            <div class="col-12 col-lg-6">
                <div class="card p-1">
                    <h3>{{ $t('segmentation.info') }}</h3>
                    <progress-linear v-if="requestPending" />
                    <b-table
                        v-else
                        :fields="infoFields"
                        :items="infoItems"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    >
                        <template #cell(value)="{ item }">
                            <feather-icon
                                v-if="typeof item.value === 'boolean'"
                                :class="[item.value ? 'text-primary' : 'text-danger']"
                                :icon="item.value ? 'CheckIcon' : 'XIcon'"
                                size="20"
                            />
                            <span v-else>{{ item.value }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
            <!--      BALANCE      -->
            <div class="col-12 col-lg-6">
                <div class="card p-1">
                    <h3>{{ $t('titles.balance') }}</h3>
                    <progress-linear v-if="requestPending" />
                    <b-table
                        v-else
                        :fields="balanceFields"
                        :items="balanceItems"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    >
                        <template #cell(value)="{ item }">
                            <feather-icon
                                v-if="typeof item.value === 'boolean'"
                                :class="[item.value ? 'text-primary' : 'text-danger']"
                                :icon="item.value ? 'CheckIcon' : 'XIcon'"
                                size="20"
                            />
                            <span v-else>{{ item.value }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.search__btn {
    width: 100%;
    padding: 0.5rem 1rem;
}
</style>
