<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import { MERCHANT_ID } from '@/constants/names';
import dataFormat from 'echarts/src/model/mixin/dataFormat';

export default {
    name: 'AppRoadmapSeason',
    components: {
        // LevelProductCard,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect,
        flatPickr
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            childItems: [],
            status: false,
            dataForModal: {
                name: {
                    en: '',
                    ru: '',
                    uz: ''
                },
                date_start: null,
                date_end: null,
                randomizer_category_id: null,
                is_published: false
            },
            dataForChildModal: {
                number: null,
                season_id: null,
                scores: null,
                is_checkpoint: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            childPagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            childRequestPending: false,
            openedButton: false,
            activeSeason: null,
            openedGroupID: null,
            // packagesList: [],
            // levelActionsList: [],
            disableAddButton: false
        };
    },

    watch: {
        '$route.query': {
            async handler(query) {
                this.pagination.current = query.page || 1;
                await this.getRandomizerCategoryList();
                await this.getAllSeasons();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'childPagination.current': {
            handler() {
                this.getAllLevels();
            }
        }
    },

    computed: {
        dataFormat() {
            return dataFormat;
        },
        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'randomizer_category_id',
                    label: this.$t('titles.randomizer_category_id')
                },
                {
                    key: 'date_start',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'date_end',
                    label: this.$t('titles.end_date')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        childFields() {
            return [
                {
                    key: 'number',
                    label: this.$t('titles.level')
                },
                {
                    key: 'scores',
                    label: this.$t('titles.score')
                },
                {
                    key: 'is_checkpoint',
                    label: this.$t('titles.is_checkpoint')
                },
                {
                    key: 'child_actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },

        hasChildItems() {
            return this.childItems && this.childItems.length > 0;
        },

        showChildPagination() {
            return this.hasChildItems && !this.childRequestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getRandomizerCategoryList() {
            const body = {
                page: 1,
                limit: 100
            };
            try {
                const { data } = await api.roadmapApi.fetchAllSeasons(body);
                this.randomizerCategoryList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        handleChildPagination(newPage) {
            this.childPagination.current = newPage;
        },

        async getAllSeasons() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllLevels() {
            const seasonItem = this.activeSeason?.item;
            this.openedButton = !this.openedButton;
            let response = [];
            let levels = [];
            const params = {
                page: this.childPagination.current,
                limit: this.childPagination.perPage,
                merchant_id: parseInt(MERCHANT_ID),
                season_id: seasonItem?.id
            };
            try {
                this.requestPending = true;
                const { data } = await api.roadmapLevelGtwApi.fetchAllItems(params);
                response = data.data.list;
                this.items.forEach((season, index) => {
                    response.forEach((level) => {
                        if (season.id === level['season_id']) {
                            levels.push(level);
                            this.items[index] = {
                                ...season,
                                levels: levels
                            };
                        }
                    });
                });
                levels = [];
                this.childItems = response;
                this.childPagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async getOneSeason(item) {
            this.updatingItem = item;
            const body = {
                id: item.id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.roadmapSeasonGtwApi
                .fetchOneItem(body)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getOneLevel(item) {
            this.updatingItem = item;
            if (item) {
                await api.roadmapLevelGtwApi
                    .fetchOneItem({ id: item.id, merchant_id: parseInt(MERCHANT_ID) })
                    .then((response) => {
                        // merge Object for need data
                        const res = response.data.data;

                        Object.keys(res).forEach((item) => {
                            if (item in this.dataForChildModal) {
                                this.dataForChildModal[item] = res[item];
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                await this.openChildModal(item);
            }
        },

        async createSeason(body) {
            await api.roadmapSeasonGtwApi
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAllSeasons();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async createLevel(body) {
            // body.season_id = this.activeSeason.item.id;
            await api.roadmapLevelGtwApi
                .createItem(body)
                .then(async () => {
                    await this.getAllSeasons();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                    this.$refs.createUpdateChildModal.hide();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateSeason(id, body) {
            await api.roadmapSeasonGtwApi
                .updateItem({ ...body, id: id })
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllSeasons();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateLevel(id, body) {
            await api.roadmapLevelGtwApi
                .updateItem({ ...body, id: id })
                .then(() => {
                    this.getAllSeasons();
                    this.$refs.createUpdateChildModal.hide();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteSeason(id) {
            await api.roadmapSeasonGtwApi
                .deleteItem({ id: id, merchant_id: parseInt(MERCHANT_ID) })
                .then(() => {
                    this.getAllSeasons();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async deleteLevel(val) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.roadmapLevelGtwApi
                        .deleteItem({ id: val.id, merchant_id: parseInt(MERCHANT_ID) })
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.getAllSeasons();
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    if (!body.merchant_id) {
                        body.merchant_id = parseInt(MERCHANT_ID);
                    }

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateSeason(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createSeason(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async submitChildModal() {
            this.$refs.createUpdateChildModalValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForChildModal;

                    body.scores = parseInt(body.scores);
                    body.number = parseInt(body.number);

                    if (!body.merchant_id) {
                        body.merchant_id = parseInt(MERCHANT_ID);
                    }

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateLevel(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createLevel(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAllSeasons();
        },

        beforeClose() {
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        // clearActivationItem() {
        //     this.dataForChildModal.activation_items = null;
        // },

        toggleDetails(row) {
            this.setChildPaginationByDefault();
            this.items.forEach((item) => {
                if (row.item.id === item.id) {
                    row.toggleDetails();
                    this.activeSeason = row;
                } else {
                    item._showDetails = false;
                }
            });
            if (!row['detailsShowing']) {
                this.getAllLevels(row);
            }
        },

        async openChildModal(item) {
            this.$refs.createUpdateChildModal.show();
            if (!this.updatingItem) {
                this.dataForChildModal = {
                    number: null,
                    season_id: item.id,
                    scores: null,
                    is_checkpoint: false
                };
            } else {
                this.dataForChildModal.season_id = item.season_id;
            }
        },

        setChildPaginationByDefault() {
            this.childPagination = {
                current: 1,
                totalItem: '',
                perPage: 10
            };
        },

        checkRandomizerCategory(id) {
            const categoryArr = this.randomizerCategoryList;
            const categoryIdx = categoryArr.findIndex((item) => item.id === id);
            if (categoryIdx !== -1) {
                return categoryArr[categoryIdx];
            } else {
                return { id: null, name: null };
            }
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.roadmap_season') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(show_details)="row">
                    <div>
                        <b-button
                            :class="openedButton ? 'opened' : ''"
                            class="p-1 svg__button"
                            size="sm"
                            variant="outline-primary"
                            @click="toggleDetails(row)"
                        >
                            <feather-icon
                                :icon="row.item._showDetails ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                                class="svg__icon"
                                size="20"
                            />
                        </b-button>
                    </div>
                </template>

                <template #row-details="row">
                    <progress-linear v-if="childRequestPending" />
                    <b-card v-else class="child__table">
                        <b-table
                            :fields="childFields"
                            :items="row.item.levels"
                            :responsive="true"
                            borderless
                            outlined
                            striped
                            thead-class="bg__red"
                        >
                            <template #cell(is_checkpoint)="{ item }">
                                <feather-icon
                                    size="20"
                                    :icon="item['is_checkpoint'] ? 'CheckIcon' : 'XIcon'"
                                    :class="item['is_checkpoint'] ? 'text-success' : 'text-danger'"
                                />
                            </template>

                            <template #cell(child_actions)="{ item }">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.edit')}`"
                                        class="btn-icon"
                                        variant="flat-dark"
                                        @click="getOneLevel(item)"
                                    >
                                        <feather-icon icon="EditIcon" size="16" />
                                    </b-button>
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.delete')}`"
                                        class="btn-icon"
                                        variant="flat-danger"
                                        @click="deleteLevel(item)"
                                    >
                                        <feather-icon icon="Trash2Icon" size="16" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>

                        <!--  CHILD PAGINATION  -->
                        <b-row class="my-2">
                            <b-col class="offset-3" cols="9">
                                <b-pagination
                                    v-if="showChildPagination"
                                    v-model="childPagination.current"
                                    :per-page="childPagination.perPage"
                                    :total-rows="childPagination.totalItem"
                                    class="float-right mr-4 mb-0"
                                    first-number
                                    last-number
                                    @input="handleChildPagination"
                                />
                            </b-col>
                        </b-row>
                    </b-card>
                </template>

                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(randomizer_category_id)="{ item }">
                    <b-badge variant="primary" class="font-medium-1">{{
                        checkRandomizerCategory(item.randomizer_category_id).id
                    }}</b-badge>
                    :
                    <span class="text-capitalize">{{
                        checkRandomizerCategory(item.randomizer_category_id).name
                    }}</span>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        size="20"
                        :class="item.is_published ? 'text-success' : 'text-danger'"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-b-tooltip.hover.top="`${$t('add.roadmap_gtw_level')}`"
                            class="btn-icon"
                            variant="flat-success"
                            @click="openChildModal(item)"
                        >
                            <feather-icon icon="PlusIcon" size="16" />
                        </b-button>
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteSeason"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneSeason(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.season') : $t('add.season')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--   NAME [UZ]   -->
                    <div class="col-12">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   NAME [RU]   -->
                    <div class="col-12">
                        <label> {{ $t('titles.title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   NAME [EN]   -->
                    <div class="col-12">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--  START DATE  -->
                    <b-col cols="12">
                        <label>{{ $t('titles.start_date') }}</label>
                        <b-form-group>
                            <flat-pickr
                                v-model="dataForModal.date_start"
                                :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    allowInput: true,
                                    time_24hr: true,
                                    minuteIncrement: 1
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="mb-1 form-control"
                                name="date"
                            />
                        </b-form-group>
                    </b-col>

                    <!--  END DATE  -->
                    <b-col cols="12">
                        <label>{{ $t('titles.end_date') }}</label>
                        <b-form-group>
                            <flat-pickr
                                v-model="dataForModal.date_end"
                                :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    allowInput: true,
                                    time_24hr: true,
                                    minuteIncrement: 1
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="mb-1 form-control"
                                name="date"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- RANDOMIZER CATEGORY ID -->
                    <div class="col-12 mb-1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.randomizer_category_id')"
                            rules="required"
                        >
                            <label>{{ $t('titles.randomizer_category_id') }}</label>
                            <b-form-group>
                                <v-select
                                    :placeholder="$t('titles.randomizer_category_id')"
                                    id="types"
                                    :options="randomizerCategoryList"
                                    v-model="dataForModal.randomizer_category_id"
                                    :reduce="(option) => option.id"
                                    label="name"
                                    :clearable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--    IS PUBLISHED    -->
                    <b-col cols="12" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox id="is_published" v-model="dataForModal.is_published" />
                        <label for="is_published" class="font-medium-1">
                            {{ $t('titles.is_published') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    CREATE UPDATE CHILD MODAL    -->
        <b-modal
            id="createUpdateChildModal"
            ref="createUpdateChildModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.roadmap_gtw_level') : $t('add.roadmap_gtw_level')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateChildModalValidation">
                <b-row>
                    <!--   NUMBER   -->
                    <div class="col-12">
                        <label> {{ $t('titles.level') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.level')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.number"
                                    :placeholder="$t('enter.level')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   SCORES   -->
                    <div class="col-12">
                        <label> {{ $t('titles.score') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.score')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.scores"
                                    :placeholder="$t('enter.score')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--    IS CHECKPOINT    -->
                    <b-col cols="12" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox
                            id="is_checkpoint"
                            v-model="dataForChildModal.is_checkpoint"
                        />
                        <label for="id_checkpoint" class="font-medium-1">
                            {{ $t('titles.is_checkpoint') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitChildModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}

.table-badge__item {
    margin: 4px;
}

.badge-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.product-card {
    border: 2px dashed var(--secondary);
}

.py-05 {
    padding: 0.5rem 1rem;
}

.p-05 {
    padding: 0.5rem;
}
</style>
