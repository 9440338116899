<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import AppTimeline from '@core/components/app-timeline/AppTimeline';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem';

export default {
    name: 'LevelLoggerTimeLine',
    components: { ProgressLinear, AppTimelineItem, AppTimeline },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        pagination: {
            type: Object,
            default: function () {
                return {
                    current: null,
                    next: 2,
                    perPage: null,
                    previous: null,
                    total: null,
                    totalItem: null
                };
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        observer: null,
        localLang: localStorage.getItem('lang')
    }),
    computed: {
        computedLoading() {
            return this.loading;
        }
    },
    methods: {
        iconByAction(statusCode) {
            switch (statusCode) {
                case 'internet':
                    return 'WifiIcon';
                case 'sms':
                    return 'MailIcon';
                case 'voice':
                    return 'PhoneCallIcon';
                case 'coin':
                    return 'DatabaseIcon';
                case 'paynet':
                    return 'DollarSignIcon';
                case 'product':
                    return 'ShoppingBagIcon';
                default:
                    return '';
            }
        },

        colorByAction(statusCode) {
            switch (statusCode) {
                case 'internet':
                    return 'info';
                case 'sms':
                    return 'warning';
                case 'voice':
                    return 'primary';
                case 'coin':
                    return 'warning';
                case 'paynet':
                    return 'success';
                case 'product':
                    return 'secondary';
                default:
                    return '';
            }
        },

        observe() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.$emit('loadNextPage', this.pagination.next);
                    }
                });
            });
            setTimeout(() => {
                this.observer.observe(this.$refs['last-child']);
            });
        },

        unObserve() {
            this.observer.disconnect();
            this.observer.unobserve(this.$refs['last-child']);
        }
    },
    watch: {
        items(val) {
            if (val.length) this.observe();
        }
    },
    unmounted() {
        this.unObserve();
    }
};
</script>

<template>
    <div class="card activities p-2">
        <app-timeline v-if="items.length" ref="timeLine">
            <app-timeline-item
                v-for="activity in items"
                :key="activity.id"
                :icon="iconByAction(activity.prize.type)"
                :variant="colorByAction(activity.prize.type)"
            >
                <slot :item="activity" />
            </app-timeline-item>
            <div ref="last-child" class="logs-timeline__last-child" />
        </app-timeline>
        <h4 v-else-if="!loading" class="m-0">{{ $t('no_data') }}</h4>
        <progress-linear v-show="loading" style="margin: 25px -15px -25px; width: unset" />
    </div>
</template>
