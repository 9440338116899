<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BModal, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';
import FlatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppUSSDNewUserStatistics',
    components: {
        BModal,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        FlatPickr
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            childItems: [],
            status: false,
            dataForModal: {
                name: '',
                levelInsert: null
            },
            dataForChildModal: {
                season_id: null,
                name: '',
                types: null,
                products: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            childPagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            date_start: null,
            date_end: null,
            requestPending: false,
            childRequestPending: false,
            openedButton: false,
            activeTableRow: null,
            openedGroupID: null,
            disableAddButton: false,
            isDownloadModalOpened: false,
            download_date_start: null,
            download_date_end: null,
            download_type: null
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getTable();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'childPagination.current': {
            handler() {
                this.getChildTable();
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                },
                {
                    key: 'registration_count',
                    label: this.$t('titles.registration_count')
                },
                {
                    key: 'group',
                    label: this.$t('titles.groups')
                },
                {
                    key: 'menu',
                    label: this.$t('titles.opening_menu')
                },
                {
                    key: 'appoint',
                    label: `GET ${this.$t('titles.request')}`
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        childFields() {
            return [
                {
                    key: 'date',
                    label: this.$t('titles.date')
                },
                {
                    key: 'action',
                    label: this.$t('titles.action')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        }
    },

    async mounted() {},

    methods: {
        checkLocales,

        async getTable() {
            const params = {
                // page: this.pagination.current,
                // limit: this.pagination.perPage,
                date_start: this.date_start,
                date_end: this.date_end,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.ussdStatisticsApi.fetchNewUserStatistics(params);
                this.items = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getChildTable(row) {
            let response = [];
            let children = [];
            const params = {
                date: row.item.date,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                this.requestPending = true;
                this.childRequestPending = true;
                const { data } = await api.ussdStatisticsApi.fetchNewUserStatisticsByDate(params);
                response = data.data;
                this.items.forEach((parent, index) => {
                    // response.forEach((child) => {
                    if (parent.date === response.date) {
                        children.push(response);
                        this.items[index] = {
                            ...parent,
                            children: response['appoint']
                        };
                    }
                    // });
                });
                children = [];
                this.childItems = response;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
                this.childRequestPending = false;
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getTable();
        },

        beforeClose() {
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        toggleDetails(row) {
            this.setChildPaginationByDefault();
            this.items.forEach((item) => {
                if (row.item.date === item.date) {
                    row.toggleDetails();
                    this.activeTableRow = row;
                } else {
                    item._showDetails = false;
                }
            });
            if (!row['detailsShowing']) {
                this.getChildTable(row);
            }
        },

        setChildPaginationByDefault() {
            this.childPagination = {
                current: 1,
                totalItem: '',
                perPage: 10
            };
        },

        transformHeader(val) {
            const keys = Object.keys(val);

            return keys.map((key) => ({
                key: key,
                label: this.$t(`titles.${key}`)
            }));
        },

        async downloadNewUsersCsvFile() {
            try {
                const response = await api.ussdStatisticsApi.downloadUSSDNewStatisticsExcel({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.download_date_start,
                    date_end: this.download_date_end
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'new-user-ussd.xlsx');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.closeDownloadModal();
            } catch (e) {
                console.error(e);
            }
        },

        async downloadConversionCsvFile() {
            try {
                const response = await api.ussdStatisticsApi.downloadConversionStatisticsExcel({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.download_date_start,
                    date_end: this.download_date_end
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'conversion.xlsx');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.closeDownloadModal();
            } catch (e) {
                console.error(e);
            }
        },

        async downloadUssdFlowCsvFile() {
            try {
                const response = await api.ussdStatisticsApi.downloadUssdFlowStatisticsExcel({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.download_date_start,
                    date_end: this.download_date_end
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'ussd-flow.xlsx');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.closeDownloadModal();
            } catch (e) {
                console.error(e);
            }
        },

        async downloadInactiveCsvFile() {
            try {
                const response = await api.ussdStatisticsApi.downloadInactiveStatisticsExcel({
                    merchant_id: parseInt(MERCHANT_ID),
                    date: this.download_date_start
                });

                const blob = new Blob([response.data], {
                    type: 'text/csv'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'inactive-users.csv');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.closeDownloadModal();
            } catch (e) {
                console.error(e);
            }
        },

        openDownloadModal(type) {
            this.isDownloadModalOpened = true;
            this.download_type = type;
        },

        closeDownloadModal() {
            this.isDownloadModalOpened = false;
            this.download_type = null;
            this.download_date_start = null;
            this.download_date_end = null;
        },

        async downloadCsvFiles() {
            if (this.download_type === 'flow') {
                await this.downloadUssdFlowCsvFile();
            } else if (this.download_type === 'conversion') {
                await this.downloadConversionCsvFile();
            } else if (this.download_type === 'inactive') {
                await this.downloadInactiveCsvFile();
            } else {
                await this.downloadNewUsersCsvFile();
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>{{ $t('titles.new_user_ussd_statistics') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <div class="d-flex flex-column flex-lg-row">
                    <b-button
                        class="ml-auto mb-1 mr-0 mb-lg-0 mr-lg-2"
                        variant="primary"
                        @click="openDownloadModal('inactive')"
                    >
                        Inactive
                        <feather-icon icon="DownloadIcon" />
                    </b-button>
                    <b-button
                        class="ml-auto mb-1 mr-0 mb-lg-0 mr-lg-2"
                        variant="primary"
                        @click="openDownloadModal('flow')"
                    >
                        USSD FLOW
                        <feather-icon icon="DownloadIcon" />
                    </b-button>
                    <b-button
                        class="ml-auto mb-1 mr-0 mb-lg-0 mr-lg-2"
                        variant="primary"
                        @click="openDownloadModal('conversion')"
                    >
                        {{ $t('titles.conversion') }}
                        <feather-icon icon="DownloadIcon" />
                    </b-button>
                    <b-button
                        class="ml-auto"
                        variant="primary"
                        @click="openDownloadModal('new-user')"
                    >
                        {{ $t('button.download') }}
                        <feather-icon icon="DownloadIcon" />
                    </b-button>
                </div>
                <!--                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">-->
                <!--                    {{ $t('button.add') }}-->
                <!--                    <feather-icon icon="PlusIcon" />-->
                <!--                </b-button>-->
            </template>
        </page-title>

        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                        autocomplete="off"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                        autocomplete="off"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button @click="getTable" class="w-100 mt-1 mt-md-0" variant="primary">
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card mb-3">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(show_details)="row">
                    <div>
                        <b-button
                            :class="openedButton ? 'opened' : ''"
                            class="p-1 svg__button"
                            size="sm"
                            variant="outline-primary"
                            @click="toggleDetails(row)"
                        >
                            <feather-icon
                                :icon="row.item._showDetails ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                                class="svg__icon"
                                size="20"
                            />
                        </b-button>
                    </div>
                </template>
                <template #cell(group)="{ item }">
                    <b-table
                        :fields="transformHeader(item.group)"
                        :items="[item.group]"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    />
                </template>
                <template #cell(menu)="{ item }">
                    <b-table
                        :fields="transformHeader(item.menu)"
                        :items="[item.menu]"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    />
                </template>
                <template #cell(appoint)="{ item }">
                    <b-table
                        :fields="transformHeader(item['appoint'])"
                        :items="[item['appoint']]"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    />
                </template>

                <template #row-details="row">
                    <progress-linear v-if="childRequestPending" />
                    <b-card v-else class="child__table">
                        <div class="row w-100">
                            <div class="col-12 col-md-6">
                                <h4 class="p-1">{{ $t('titles.player_count') }}</h4>
                                <b-table
                                    v-if="
                                        row.item && row.item.children && row.item.children.players
                                    "
                                    :fields="childFields"
                                    :items="row.item.children.players"
                                    :responsive="true"
                                    borderless
                                    outlined
                                    striped
                                    thead-class="bg__red"
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <h4 class="p-1">{{ $t('titles.consumer_count') }}</h4>
                                <b-table
                                    :fields="childFields"
                                    :items="row.item.children['consumers']"
                                    :responsive="true"
                                    borderless
                                    outlined
                                    striped
                                    thead-class="bg__red"
                                >
                                    <template #cell(products)="{ item }">
                                        <b-badge
                                            v-for="(child, index) in item['products']"
                                            :key="index"
                                            class="table-badge__item"
                                            :variant="child.color"
                                        >
                                            <img :src="child.image" alt="" class="badge-img" />
                                            <span>{{ child.text.toUpperCase() }}</span>
                                        </b-badge>
                                    </template>

                                    <template #cell(name)="{ item }">
                                        <b-badge class="table-badge__item" variant="success">
                                            <span class="font-medium-1">{{ item.name }}</span>
                                        </b-badge>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <!--  CHILD PAGINATION  -->
                        <!--                        <b-row class="my-2">-->
                        <!--                            <b-col class="offset-3" cols="9">-->
                        <!--                                <b-pagination-->
                        <!--                                    v-if="showChildPagination"-->
                        <!--                                    v-model="childPagination.current"-->
                        <!--                                    :per-page="childPagination.perPage"-->
                        <!--                                    :total-rows="childPagination.totalItem"-->
                        <!--                                    class="float-right mr-4 mb-0"-->
                        <!--                                    first-number-->
                        <!--                                    last-number-->
                        <!--                                    @input="handleChildPagination"-->
                        <!--                                />-->
                        <!--                            </b-col>-->
                        <!--                        </b-row>-->
                    </b-card>
                </template>
            </b-table>
        </div>

        <!--    DOWNLOAD MODAL    -->
        <b-modal
            v-model="isDownloadModalOpened"
            id="downloadExcelModal"
            ref="downloadExcelModal"
            :title="$t('button.download')"
            @close="closeDownloadModal"
        >
            <!--          DATE START          -->
            <div>
                <label for="dateStart">{{ $t('titles.start_date') }}</label>
                <flat-pickr
                    v-model="download_date_start"
                    :config="{
                        allowInput: true
                    }"
                    autocomplete="off"
                    class="form-control mb-1"
                    name="date"
                    :placeholder="$t('choose.date')"
                    id="dateStart"
                />
            </div>

            <!--          DATE END          -->
            <div v-if="download_type !== 'inactive'">
                <label for="dateStart">{{ $t('titles.end_date') }}</label>
                <flat-pickr
                    v-model="download_date_end"
                    :config="{
                        allowInput: true
                    }"
                    autocomplete="off"
                    class="form-control"
                    name="date"
                    :placeholder="$t('choose.date')"
                    id="dateEnd"
                />
            </div>

            <template #modal-footer>
                <b-button @click="closeDownloadModal" variant="danger">
                    {{ $t('button.cancel') }}
                </b-button>
                <b-button @click="downloadCsvFiles" variant="success">
                    {{ $t('button.confirm') }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <!--        <b-row>-->
        <!--            <b-col class="offset-3" cols="9">-->
        <!--                <b-pagination-->
        <!--                    v-if="showPagination"-->
        <!--                    v-model="pagination.current"-->
        <!--                    :per-page="pagination.perPage"-->
        <!--                    :total-rows="pagination.totalItem"-->
        <!--                    class="float-right mr-4 mb-0"-->
        <!--                    first-number-->
        <!--                    last-number-->
        <!--                />-->
        <!--            </b-col>-->
        <!--        </b-row>-->
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}

.table-badge__item {
    margin: 4px;
}

.badge-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.product-card {
    border: 2px dashed var(--secondary);
}

.py-05 {
    padding: 0.5rem 1rem;
}

.p-05 {
    padding: 0.5rem;
}
</style>
