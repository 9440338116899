import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class AdministrationUzmobile extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchStatus(body) {
        return this.post('uzmobile/status', body);
    }

    fetchRTStatus(body) {
        return this.post('uzmobile/rtStatus', body);
    }

    fetchBalance(body) {
        return this.post('uzmobile/balance', body);
    }

    fetchSubscriberInfo(body) {
        return this.post('uzmobile/subscriberInfo', body);
    }

    fetchPersonalAccounts(body) {
        return this.post('uzmobile/personalAccounts', body);
    }

    fetchPackageInfo(body) {
        return this.post('uzmobile/packageInfo', body);
    }
}

export default AdministrationUzmobile;
