<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    formatNumber,
    generateIconColorToBoolean,
    generateIconToBoolean
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';

export default {
    name: 'AppDealersStatistics',
    components: {
        ProgressLinear,
        PageTitle,
        BTable
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            registrationItems: [],
            appointItems: [],
            subscriptionItems: [],
            registrationTotal: null,
            appointsTotal: null,
            appointsTotalSum: null,
            subscriptionTotal: null,
            subscriptionTotalSum: null,
            registrationRequestPending: false,
            appointRequestPending: false,
            subscriptionRequestPending: false
        };
    },

    async mounted() {
        await this.getDealersRegistrations();
        await this.getDealersAppoints();
        await this.getDealersSubscriptions();
    },

    computed: {
        registrationFields() {
            return [
                {
                    key: 'short_code',
                    label: this.$t('titles.code')
                },
                {
                    key: 'count',
                    label: this.$t('titles.amount')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        appointFields() {
            return [
                {
                    key: 'short_code',
                    label: this.$t('titles.code')
                },
                {
                    key: 'service',
                    label: this.$t('titles.service')
                },
                {
                    key: 'count',
                    label: this.$t('titles.amount')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        subscriptionFields() {
            return [
                {
                    key: 'short_code',
                    label: this.$t('titles.code')
                },
                {
                    key: 'count',
                    label: this.$t('titles.amount')
                },
                {
                    key: 'paid_count',
                    label: this.$t('titles.paid')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        }
    },

    methods: {
        formatNumber,
        checkLocales,
        checkLang,

        async getDealersRegistrations() {
            this.registrationRequestPending = true;
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.dealersApi
                .fetchRegistrations(body)
                .then((response) => {
                    this.registrationItems = response.data.data.dates;
                    this.registrationTotal = response.data.data.total.count;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.registrationRequestPending = false;
                });
        },

        async getDealersAppoints() {
            this.appointRequestPending = true;
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.dealersApi
                .fetchAppoints(body)
                .then((response) => {
                    this.appointItems = response.data.data.dates;
                    this.appointsTotal = response.data.data.total.count;
                    this.appointsTotalSum = response.data.data.total.sum;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.appointRequestPending = false;
                });
        },

        async getDealersSubscriptions() {
            this.subscriptionRequestPending = true;
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.dealersApi
                .fetchSubscriptions(body)
                .then((response) => {
                    this.subscriptionItems = response.data.data.dates;
                    this.subscriptionTotal = response.data.data.total.count;
                    this.subscriptionTotalSum = response.data.data.total.sum;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.subscriptionRequestPending = false;
                });
        },

        async downloadRegistrationExcel() {
            try {
                const response = await api.dealersApi.fetchRegistrationsExcel({
                    merchant_id: parseInt(MERCHANT_ID)
                });

                // Create a blob from the response data
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Set the download attribute with a filename
                link.href = url;
                link.setAttribute('download', 'filename.xlsx'); // Replace 'filename.xlsx' with your preferred file name

                // Append the link to the body (required for Firefox)
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (e) {
                console.error(e);
            }
        },

        async downloadAppointExcel() {
            try {
                const response = await api.dealersApi.fetchAppointsExcel({
                    merchant_id: parseInt(MERCHANT_ID)
                });

                // Create a blob from the response data
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Set the download attribute with a filename
                link.href = url;
                link.setAttribute('download', 'filename.xlsx'); // Replace 'filename.xlsx' with your preferred file name

                // Append the link to the body (required for Firefox)
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (e) {
                console.error(e);
            }
        },

        async downloadSubscriptionExcel() {
            try {
                const response = await api.dealersApi.fetchSubscriptionsExcel({
                    merchant_id: parseInt(MERCHANT_ID)
                });

                // Create a blob from the response data
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Set the download attribute with a filename
                link.href = url;
                link.setAttribute('download', 'filename.xlsx'); // Replace 'filename.xlsx' with your preferred file name

                // Append the link to the body (required for Firefox)
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (e) {
                console.error(e);
            }
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.dealers_statistics') }}</template>
        </page-title>

        <div class="row">
            <!--  REGISTRATION TOTAL  -->
            <div class="col-12 col-lg-4">
                <div class="card p-1">
                    <h4>{{ $t('titles.registration_statistics') }}</h4>
                    <div
                        class="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-items-center justify-content-between"
                    >
                        <div class="mb-1 mb-sm-0">
                            <span class="font-medium-1">{{ $t('titles.total_count') }}:</span>
                            <b-badge class="ml-1 font-weight-bold font-medium-2" variant="primary">
                                {{ registrationTotal }}
                            </b-badge>
                        </div>
                        <div class="mt-lg-1 mt-xl-0">
                            <b-button @click="downloadRegistrationExcel" variant="outline-primary">
                                {{ $t('button.download') }} [Excel]
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--  APPOINT TOTAL  -->
            <div class="col-12 col-lg-4">
                <div class="card p-1">
                    <h4>{{ $t('titles.appoints_statistics') }}</h4>
                    <div
                        class="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-items-center justify-content-between"
                    >
                        <div class="mb-1 mb-sm-0">
                            <div class="mb-1">
                                <span class="font-medium-1">{{ $t('titles.total_count') }}:</span>
                                <b-badge
                                    class="ml-1 font-weight-bold font-medium-2"
                                    variant="primary"
                                >
                                    {{ formatNumber(appointsTotal) }}
                                </b-badge>
                            </div>
                            <div>
                                <span class="font-medium-1">{{ $t('titles.total_sum') }}:</span>
                                <b-badge
                                    class="ml-1 font-weight-bold font-medium-2"
                                    variant="primary"
                                >
                                    {{ formatNumber(appointsTotalSum) }}
                                </b-badge>
                            </div>
                        </div>
                        <div class="mt-lg-1 mt-xl-0">
                            <b-button @click="downloadAppointExcel" variant="outline-primary">
                                {{ $t('button.download') }} [Excel]
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--  SUBSCRIPTION TOTAL  -->
            <div class="col-12 col-lg-4">
                <div class="card p-1">
                    <h4>{{ $t('titles.subscription_event_statistics') }}</h4>
                    <div
                        class="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-items-center justify-content-between"
                    >
                        <div class="mb-1 mb-sm-0">
                            <div class="mb-1">
                                <span class="font-medium-1">{{ $t('titles.total_count') }}:</span>
                                <b-badge
                                    class="ml-1 font-weight-bold font-medium-2"
                                    variant="primary"
                                >
                                    {{ formatNumber(subscriptionTotal) }}
                                </b-badge>
                            </div>
                            <div>
                                <span class="font-medium-1">{{ $t('titles.total_sum') }}:</span>
                                <b-badge
                                    class="ml-1 font-weight-bold font-medium-2"
                                    variant="primary"
                                >
                                    {{ formatNumber(subscriptionTotalSum) }}
                                </b-badge>
                            </div>
                        </div>
                        <div class="mt-lg-1 mt-xl-0">
                            <b-button @click="downloadSubscriptionExcel" variant="outline-primary">
                                {{ $t('button.download') }} [Excel]
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--  REGISTRATION TABLE  -->
            <div class="col-12 col-lg-4">
                <div class="card">
                    <progress-linear v-if="registrationRequestPending" />
                    <b-table
                        v-else
                        :fields="registrationFields"
                        :items="registrationItems"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                    </b-table>
                </div>
            </div>

            <!--  APPOINT TABLE  -->
            <div class="col-12 col-lg-4">
                <div class="card">
                    <progress-linear v-if="appointRequestPending" />
                    <b-table
                        v-else
                        :fields="appointFields"
                        :items="appointItems"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(service)="{ item }">
                            {{ checkLocales(item.service) }}
                        </template>
                    </b-table>
                </div>
            </div>

            <!--  SUBSCRIPTION TABLE  -->
            <div class="col-12 col-lg-4">
                <div class="card">
                    <progress-linear v-if="subscriptionRequestPending" />
                    <b-table
                        v-else
                        :fields="subscriptionFields"
                        :items="subscriptionItems"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(service)="{ item }">
                            {{ checkLocales(item.service) }}
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}
</style>
