import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class DealersService extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchRegistrations(body) {
        return this.post('statistics/dealer/registrations', body);
    }

    fetchAppoints(body) {
        return this.post('statistics/dealer/appoints', body);
    }

    fetchSubscriptions(body) {
        return this.post('statistics/dealer/subscriptions', body);
    }

    fetchSubscriptionsByDealer(body) {
        return this.post('statistics/dealer/subscriptions-by-dealer', body);
    }

    fetchServicesByDealer(body) {
        return this.post('statistics/dealer/services-by-dealer', body);
    }

    fetchRegistrationsExcel(body) {
        return this.post('statistics/dealer/registrationsExcel', body, {
            responseType: 'arraybuffer'
        });
    }

    fetchAppointsExcel(body) {
        return this.post('statistics/dealer/appointsExcel', body, {
            responseType: 'arraybuffer'
        });
    }

    fetchSubscriptionsExcel(body) {
        return this.post('statistics/dealer/subscriptionsExcel', body, {
            responseType: 'arraybuffer'
        });
    }

    downloadDealerTotalStatistics(body) {
        return this.post('statistics/dealer/total-excel', body, {
            responseType: 'arraybuffer'
        });
    }
}

export default DealersService;
