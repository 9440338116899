<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import flatPickr from 'vue-flatpickr-component';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { isArray, isObject } from '@/util/inspect.util';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';

export default {
    name: 'AppAlertModal',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        flatPickr,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            status: false,
            dataForModal: {
                title: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                description: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                buttons: [
                    {
                        type: null,
                        title: {
                            uz: null,
                            ru: null,
                            en: null
                        },
                        value: null
                    }
                ],
                places: [],
                image: [],
                prize: null,
                start_date: null,
                end_date: null,
                service: null,
                is_published: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            staticLevelTypes: ['app', 'lite'],
            placesList: [],
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            disableAddButton: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllItems();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.image = [];
            if (isArray(image)) {
                this.dataForModal.image = image;
            } else if (isObject(image)) {
                this.dataForModal.image.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.real_title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'prize',
                    label: this.$t('titles.prize')
                },
                {
                    key: 'service',
                    label: this.$t('titles.service')
                },
                {
                    key: 'image',
                    label: this.$t('titles.image')
                },
                {
                    key: 'buttons',
                    label: this.$t('titles.buttons')
                },
                {
                    key: 'places',
                    label: this.$t('titles.places')
                },
                {
                    key: 'start_date',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'end_date',
                    label: this.$t('titles.end_date')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getAllPlacesList();
    },

    methods: {
        checkLocales,

        async getAllPlacesList() {
            try {
                const { data } = await api.alertModalApi.fetchAllPlaces({
                    merchant_id: parseInt(MERCHANT_ID)
                });
                this.placesList = data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async getAllItems() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.alertModalApi.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.alertModalApi
                .fetchOneItem({ id: item.id, merchant_id: parseInt(MERCHANT_ID) })
                .then((response) => {
                    this.isOpenModal = true;
                    const image = response.data.data.image;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });

                    this.uploadImage = {
                        id: image.id,
                        name: image.name,
                        size: image.size,
                        type: 'image/jpg',
                        url: image.path,
                        src: image.path
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.alertModalApi
                .createItem({ ...body, merchant_id: parseInt(MERCHANT_ID) })
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAllItems();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(body) {
            await api.alertModalApi
                .updateItem({ ...body, merchant_id: parseInt(MERCHANT_ID) })
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllItems();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.alertModalApi
                .deleteItem({ id: id, merchant_id: parseInt(MERCHANT_ID) })
                .then(() => {
                    this.getAllItems();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.upload_id = this.dataForModal.image[0].id;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateItem(body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        addButton() {
            this.dataForModal.buttons.push({
                title: {
                    en: null,
                    ru: null,
                    uz: null
                },
                type: null,
                value: null
            });
        },

        deleteButton(index) {
            this.dataForModal.buttons.splice(index, 1);
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAllItems();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.image.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('banners', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.image[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.alert_modal') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(title)="{ item }">
                    {{ checkLocales(item.title) }}
                </template>

                <template #cell(description)="{ item }">
                    {{ checkLocales(item.description) }}
                </template>

                <template #cell(places)="{ item }">
                    <b-badge
                        class="mr-05"
                        v-for="(place, index) in item.places"
                        :key="index + place"
                    >
                        {{ place }}
                    </b-badge>
                </template>

                <template #cell(buttons)="{ item }">
                    <b-badge
                        class="mr-1"
                        v-for="(button, index) in item.buttons"
                        :key="index"
                        variant="primary"
                    >
                        {{ checkLocales(button.title) }}
                    </b-badge>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        size="20"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        :class="item.is_published ? 'text-success' : 'text-danger'"
                    />
                </template>

                <template #cell(image)="{ item }">
                    <img
                        class="table-img"
                        v-if="item && item.image"
                        :src="item.image.path"
                        alt=""
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.alert_modal') : $t('add.alert_modal')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE [RU]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE [EN]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.uz"
                                    :placeholder="`${$t('enter.description')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION [RU]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.ru"
                                    :placeholder="`${$t('enter.description')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION [EN]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.en"
                                    :placeholder="`${$t('enter.description')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PRIZE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.prize') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.prize')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.prize"
                                    :placeholder="$t('enter.prize')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SERVICE    -->
                    <b-col cols="12" class="mb-1">
                        <label>{{ $t('titles.service') }}</label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="$t('titles.service')"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.service"
                                :placeholder="$t('enter.service')"
                                size="md"
                            />
                        </b-form-group>
                        <!--                            <span class="validation__red">{{ errors[0] }}</span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--    PLACES    -->
                    <b-col cols="12" class="mb-1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.places')"
                            rules="required"
                        >
                            <label>{{ $t('titles.places') }}</label>
                            <!--                        <ValidationProvider-->
                            <!--                            v-slot="{ errors }"-->
                            <!--                            :name="$t('titles.service')"-->
                            <!--                            rules="required"-->
                            <!--                        >-->
                            <v-select
                                id="places"
                                v-model="dataForModal.places"
                                :options="placesList"
                                :placeholder="$t('titles.places')"
                                :state="errors.length > 0 ? false : null"
                                size="md"
                                taggable
                                multiple
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                        <!--                            <span class="validation__red">{{ errors[0] }}</span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--     START DATE     -->
                    <div class="col-6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.start_date')"
                            rules="required"
                        >
                            <label>{{ $t('titles.start_date') }}</label>
                            <flat-pickr
                                v-model="dataForModal.start_date"
                                :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                class="form-control"
                                name="date"
                                id="start_date"
                                autocomplete="off"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--     END DATE     -->
                    <div class="col-6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.end_date')"
                            rules="required"
                        >
                            <label>{{ $t('titles.end_date') }}</label>
                            <flat-pickr
                                v-model="dataForModal.end_date"
                                :config="{
                                    enableTime: true,
                                    enableSeconds: true,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                class="form-control"
                                name="date"
                                id="end_date"
                                autocomplete="off"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--    IMAGE ID    -->
                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="uploadImage"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_image')"
                                    :maxFiles="14"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    class="float-right mt-1"
                                    @click="getImageId()"
                                >
                                    <span v-if="!imageUploaded">
                                        {{ $t('vue_file_agent.upload_image') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('vue_file_agent.image_uploaded') }}
                                    </span>
                                </b-button>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>

                    <!--    BUTTONS    -->
                    <b-col
                        cols="12"
                        v-for="(button, index) in dataForModal.buttons"
                        :key="index"
                        class="buttons-block"
                        :class="dataForModal.buttons.length > 1 ? 'mb-1' : ''"
                    >
                        <div class="d-flex align-items-center justify-content-between">
                            <h4 class="mt-1">{{ $t('titles.button') }} - {{ index + 1 }}</h4>
                            <div>
                                <b-button @click="deleteButton(index)" class="p-05" variant="danger"
                                    >X</b-button
                                >
                            </div>
                        </div>
                        <div>
                            <label>{{ $t('titles.type') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.type')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="button.type"
                                        :placeholder="$t('enter.type')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label>{{ $t('titles.button') }} [UZ]</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="`${$t('titles.button')} [UZ]`"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="button.title.uz"
                                        :placeholder="`${$t('enter.button')} [UZ]`"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label>{{ $t('titles.button') }} [RU]</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="`${$t('titles.button')} [RU]`"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="button.title.ru"
                                        :placeholder="`${$t('enter.button')} [RU]`"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label>{{ $t('titles.button') }} [EN]</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="`${$t('titles.button')} [EN]`"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="button.title.en"
                                        :placeholder="`${$t('enter.button')} [EN]`"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label>{{ $t('titles.value') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.value')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="button.value"
                                        :placeholder="$t('enter.value')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </b-col>

                    <!--    ADD BUTTONS    -->
                    <b-col class="d-flex justify-content-center align-items-center my-2">
                        <!--?    IS PUBLISHED    -->
                        <b-col cols="6" class="d-flex user-select-none">
                            <b-form-checkbox
                                id="is_published"
                                v-model="dataForModal.is_published"
                                :disabled="isViewModal"
                            />
                            <label for="is_published" class="font-medium-1">
                                {{ $t('titles.is_published') }}
                            </label>
                        </b-col>
                        <b-button @click="addButton" variant="success">{{
                            $t('titles.add_button')
                        }}</b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-2" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.buttons-block {
    border: 2px dotted var(--secondary);
}

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}

.table-badge__item {
    margin: 4px;
}

.badge-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.product-card {
    border: 2px dashed var(--secondary);
}

.py-05 {
    padding: 0.5rem 1rem;
}

.p-05 {
    padding: 0.5rem;
}

.mr-05 {
    margin-right: 0.5rem;
}
</style>
