import AppStockTemplates from '@/views/stock/AppStockTemplates.vue';
import AppStockCategory from '@/views/stock/AppStockCategory.vue';
import AppStockTemplatesCRUD from '@/views/stock/views/AppStockTemplatesCRUD.vue';
import AppStockHistory from '@/views/stock/AppStockHistory.vue';
import AppSpecialOffers from '@/views/stock/AppSpecialOffers.vue';

export default [
    {
        path: '/stock-templates',
        name: 'stock-templates',
        component: AppStockTemplates,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/stock-templates/:id?/update',
        name: 'stock-templates-update',
        component: AppStockTemplatesCRUD,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/stock-templates/create',
        name: 'stock-templates-create',
        component: AppStockTemplatesCRUD,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/stock-categories',
        name: 'stock-categories',
        component: AppStockCategory,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/stock-history',
        name: 'stock-history',
        component: AppStockHistory,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/special-offers',
        name: 'special-offers',
        component: AppSpecialOffers,
        meta: {
            section: 'news',
            permission: 'view'
        }
    }
];
