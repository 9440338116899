import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class SubscriptionV2 extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    activateSubscription(body) {
        return this.post('v2/subscriber/activate', body);
    }

    deactivateSubscription(body) {
        return this.post('v2/subscriber', body);
    }

    checkSubscriberStatus(body) {
        return this.post('v2/subscriber/status', body);
    }
}

export default SubscriptionV2;
