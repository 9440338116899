<script>
import { BButton } from 'bootstrap-vue';

export default {
    name: 'TogglerBtn',
    components: {
        BButton
    },
    props: {
        value: Boolean,
        size: {
            type: String,
            default: 'default'
        },
        variant: {
            type: String,
            default: 'secondary',
            validate(val) {
                return [
                    'primary',
                    'secondary',
                    'success',
                    'danger',
                    'warning',
                    'info',
                    'dark'
                ].includes(val);
            }
        }
    },
    computed: {
        computedModelValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        computedButtonText() {
            return this.value ? this.$t('button.hide') : this.$t('button.show');
        }
    },
    methods: {
        toggle() {
            this.computedModelValue = !this.computedModelValue;
        }
    }
};
</script>

<template>
    <b-button
        :size="size"
        :variant="variant"
        @click="toggle"
        :class="[
            'toggler-btn',
            {
                'toggler-btn--active': value
            }
        ]"
    >
        <div class="toggler-btn__text">
            {{ computedButtonText }}
        </div>
        <feather-icon icon="ChevronUpIcon" class="toggler-btn__arrow" />
    </b-button>
</template>

<style lang="scss" src="./TogglerBtn.scss" />
