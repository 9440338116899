import { i18n } from '@/libs/i18n';

export const offerTypes = [
    {
        key: 'voice',
        label: i18n.t('titles.voice')
    },
    {
        key: 'internet',
        label: i18n.t('titles.internet')
    },
    {
        key: 'sms',
        label: i18n.t('titles.sms')
    }
];

export const categoryTypes = [
    {
        key: 'daily',
        label: i18n.t('titles.daily')
    },
    {
        key: 'premium',
        label: i18n.t('titles.premium')
    }
];
