import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class StockHistoryService extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('stock/history/findAll', body);
    }
}

export default StockHistoryService;
