<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import services from '@/services';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';

export default {
    name: 'AppSubscribersService',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                type: '',
                distribution_interval: '0',
                is_published: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'distribution_interval',
                    label: this.$t('titles.distribution_interval')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await services.subscribersService.fetchAll(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await services.subscribersService
                .fetchOne(item.id, { merchant_id: parseInt(MERCHANT_ID) })
                .then((response) => {
                    this.isOpenModal = true;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await services.subscribersService
                .create({ ...body, merchant_id: parseInt(MERCHANT_ID) })
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(body) {
            await services.subscribersService
                .update(body.id, { ...body, merchant_id: parseInt(MERCHANT_ID) })
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await services.subscribersService
                .delete(id, { merchant_id: parseInt(MERCHANT_ID) })
                .then(() => {
                    this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateItem(body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.subscribers_service') }}</template>
            <template v-if="hasAccess('regions', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="[item.is_published ? 'text-success' : 'text-danger']"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.subscribers_service') : $t('add.subscribers_service')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME[UZ]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[RU]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[EN]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.type') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.type"
                                    :disabled="updatingItem !== null"
                                    :placeholder="$t('enter.type')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DISTRIBUTION INTERVAL    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.distribution_interval') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.distribution_interval')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.distribution_interval"
                                    :placeholder="$t('enter.distribution_interval')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS PUBLISHED    -->
                    <b-col cols="12" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox
                            id="is_published"
                            v-model="dataForModal.is_published"
                            :disabled="isViewModal"
                        />
                        <label for="is_published" class="font-medium-1">
                            {{ $t('titles.is_published') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
