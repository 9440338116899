import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class Services extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    endpoints = {
        sms: 'sms/',
        games: 'games',
        scoreHistory: 'score-history/',
        gameHistory: 'game-portal/game-history/',
        flash: 'flash',
        collector: 'collector',
        paynet: 'paynet/',
        subscribersService: 'service',
        mailing: 'v2/mailing/'
    };

    games = {
        create: (b = {}) => this.post(this.endpoints.games, b),
        update: (b = {}) => this.post(this.endpoints.games + '/update', b),
        remove: (b = {}) => this.post(this.endpoints.games + '/remove', b),
        findAll: (b = {}) => this.post(this.endpoints.games + '/findAll', b),
        findOne: (b = {}) => this.post(this.endpoints.games + '/findOne', b),
        fetchTotalUsers: (b = {}) => this.post(this.endpoints.games + '/statistics/totalUsers', b),
        fetchGamesTotalUsers: (b = {}) =>
            this.post(this.endpoints.games + '/statistics/gamesTotalUsers', b),
        fetchGameHistory: (b = {}) =>
            this.post(this.endpoints.games + '/statistics/gameHistory', b),
        fetchFullStatistics: (b = {}) =>
            this.post(this.endpoints.games + '/statistics/fullStatistics', b),
        fetchFullStatisticsByDay: (b = {}) =>
            this.post(this.endpoints.games + '/statistics/fullStatisticsDay', b)
    };

    sms = {
        create: (b = {}) => this.post(this.endpoints.sms + 'create', b),
        update: (id, b = {}) => this.post(this.endpoints.sms + 'update/' + id, b),
        remove: (id, params = {}) => this.delete(this.endpoints.sms + 'delete/' + id, params),
        findAll: (params = {}) => this.get(this.endpoints.sms + 'findAll', { params }),
        findOne: (id, b = {}) => this.post(this.endpoints.sms + 'findOne/' + id, { b })
    };

    flash = {
        create: (b = {}) => this.post(this.endpoints.flash + '', b),
        update: (id, b = {}) => this.post(this.endpoints.flash + '/update/' + id, b),
        remove: (id, b = {}) => this.delete(this.endpoints.flash + '/' + id, b),
        findAll: (params = {}) => this.get(this.endpoints.flash + '/findAll', { params }),
        findOne: (id, b = {}) => this.get(this.endpoints.flash + '/findOne/' + id, b),
        fetchCatalogue: () => this.get(this.endpoints.flash + '/getPackCatalog'),
        fetchHistory: (b = {}) => this.post(this.endpoints.flash + '/history', b),
        fetchHistoryPack: (b = {}) => this.post(this.endpoints.flash + '/history-packages', b),
        fetchHistoryGraph: (b = {}) => this.post(this.endpoints.flash + '/graph', b),
        fetchFlashBannerStatus: (b = {}) => this.post(this.endpoints.flash + '/visibility', b),
        updateFlashBannerStatus: (b = {}) => this.post(this.endpoints.flash + '/setVisibility', b)
    };

    gameHistory = {
        findAll: (b = {}) => this.post('v2/' + this.endpoints.gameHistory + 'findAll', b)
    };

    scoreHistory = {
        findAll: (b = {}) => this.post(this.endpoints.scoreHistory + 'findAll', b),
        findOne: (b = {}) => this.post(this.endpoints.scoreHistory + 'findOne', b)
    };

    collector = {
        findAll: (b = {}, config = {}) => this.post(this.endpoints.collector + '/list', b, config),
        activityFindAll: (b = {}, config = {}) =>
            this.post(this.endpoints.collector + '/activity', b, config)
    };

    paynet = {
        setPaynet: (b = {}) => this.post(this.endpoints.paynet + 'top-up', b)
    };

    mailing = {
        mailingNewsletter: (merchantId, body = {}) =>
            this.post(this.endpoints.mailing + `sendByCSV/${merchantId}`, body)
    };

    subscribersService = {
        fetchAll: (params = {}) => this.get(this.endpoints.subscribersService, { params }),
        fetchOne: (id, params = {}) =>
            this.get(this.endpoints.subscribersService + '/' + id, { params }),
        create: (body = {}) => this.post(this.endpoints.subscribersService, body),
        update: (id, body = {}) => this.post(this.endpoints.subscribersService + '/' + id, body),
        delete: (id, params = {}) =>
            this.delete(this.endpoints.subscribersService + '/' + id, { params }),
        fetchUserSubscriptionType: (params = {}) =>
            this.get(this.endpoints.subscribersService + '/user', params)
    };
}

export default new Services();
