import AppSegmentation from '@/views/segmentation/AppSegmentation.vue';
import SegmentationView from '@/views/segmentation/views/index.vue';
import AppSegmentationDetailZone from '@/views/segmentation/views/AppSegmentationDetailZone.vue';
import AppSegmentationDetailSalePoint from '@/views/segmentation/views/AppSegmentationDetailSalePoint.vue';
import AppSegmentationUserInfo from '@/views/segmentation/views/AppSegmentationUserInfo.vue';
import AppSegmentationDetailRatePlan from '@/views/segmentation/views/AppSegmentationDetailRatePlan.vue';
import AppSegmentationStatistics from '@/views/segmentation/views/AppSegmentationStatistics.vue';

export default [
    {
        path: '/segmentation',
        name: 'segmentation-list',
        component: AppSegmentation,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/segmentation-statistics',
        name: 'segmentation-statistics',
        component: AppSegmentationStatistics,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/segmentation',
        name: 'segmentation-details-by-user',
        component: SegmentationView,
        children: [
            {
                path: ':id/detail-rate-plan',
                name: 'segmentation-detail-rate-plan',
                component: AppSegmentationDetailRatePlan,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: ':id/detail-zone',
                name: 'segmentation-detail-zone',
                component: AppSegmentationDetailZone,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: ':id/detail-sale-point',
                name: 'segmentation-detail-sale-point',
                component: AppSegmentationDetailSalePoint,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: ':id/user-info',
                name: 'segmentation-user-info',
                component: AppSegmentationUserInfo,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            }
        ]
    }
];
