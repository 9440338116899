<script>
import { createPopper } from '@popperjs/core';
import vSelect from 'vue-select';

export default {
    name: 'customSelect',
    components: {
        vSelect
    },
    props: {
        value: [Array, Number],
        options: Array,
        errors: {
            type: [Object, Array],
            default: () => {
                return [];
            }
        },
        multiple: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'name'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    data: () => ({
        placement: 'top'
    }),
    computed: {
        computedModelValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    },
    methods: {
        withPopper(dropdownList, component, { width }) {
            /**
             * We need to explicitly define the dropdown width since
             * it is usually inherited from the parent with CSS.
             */
            dropdownList.style.width = width;

            /**
             * Here we position the dropdownList relative to the $refs.toggle Element.
             *
             * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
             * the dropdownList overlap by 1 pixel.
             *
             * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
             * wrapper so that we can set some styles for when the dropdown is placed
             * above.
             */
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top');
                        }
                    }
                ]
            });

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy();
        }
    }
};
</script>

<template>
    <v-select
        v-model="computedModelValue"
        :calculate-position="withPopper"
        :class="[
            'select-size-lg',
            {
                'v-select--is-invalid': errors.length > 0
            }
        ]"
        :disabled="disabled"
        :label="label"
        :multiple="multiple"
        :options="options"
        :placeholder="placeholder"
        :reduce="(item) => item.id"
        :searchable="false"
        append-to-body
    />
</template>

<style scoped>
.vs__dropdown-menu {
    z-index: 1051 !important;
    background: red;
    padding-right: 0;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
