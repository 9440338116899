import Vue from 'vue';
import { BootstrapVue, ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import { i18n } from '@/libs/i18n';
import VMask from 'v-mask';
import router from './router';
import store from './store';
import App from './App.vue';

//  lla
// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';
import '@/libs/veeValidate';
import '@/mixins/usersAccess';
import usersAccess from '@/mixins/usersAccess';

Vue.use(VueFileAgent);
Vue.use(ToastPlugin, {
    newestOnTop: true
});
Vue.use(VMask);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(VueCompositionAPI);
Vue.mixin(usersAccess);

import '@core/scss/core.scss';
import '@/assets/scss/style.scss';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
