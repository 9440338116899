<script>
import LogsTimeline from '@/components/LogsTimeline';
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { deletePropertiesWhichNoValue } from '@/util/helper';
import FilterBlock from '@/components/FilterBlock';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'SubscribersSingleActivities',
    components: { FilterBlock, LogsTimeline, BRow, BCol, BFormInput, BFormGroup, BButton, vSelect },
    inject: ['actions'],
    data: () => ({
        logs: {
            items: [],
            pagination: {}
        },
        filter: {
            page: 1
        },
        requestPending: false,
        localLang: localStorage.getItem('lang'),
        merchant: parseInt(MERCHANT_ID)
    }),
    computed: {
        computedActions() {
            if (!this.actions.value) return;
            return this.actions.value;
        }
    },
    methods: {
        async getUserLogs() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            if (this.merchant === 2) {
                const { data } = await api.subscribers.getLogs(this.$route.params.id, this.filter);
                if (data.pagination.current === 1) {
                    this.logs.items = data.items;
                } else {
                    this.logs.items = [...this.logs.items, ...data.items];
                }
                this.logs.pagination = data.pagination;
            } else {
                const { data } = await api.lampSubscribers.fetchActivityLogs(
                    this.$route.params.id,
                    this.filter,
                    (this.filter.limit = 10)
                );
                if (data.pagination.current === 1) {
                    this.logs.items = data.result;
                } else {
                    this.logs.items = [...this.logs.items, ...data.result];
                }
                this.logs.pagination = data.pagination;
            }
            this.requestPending = false;
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            await this.getUserLogs();
        },

        deSelect(val) {
            if (!val) {
                this.getUserLogs();
            }
        }
    },
    mounted() {
        this.getUserLogs();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-7 col-sm-12 col-12 order-2 order-md-1">
                <logs-timeline
                    v-model:loading="requestPending"
                    :items="logs.items"
                    :pagination="logs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>{{ $t(`titles.actions.${item.action}`) }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <router-link
                            :to="{
                                name: 'subscribers Single',
                                params: {
                                    id: $route.params.id
                                }
                            }"
                            class="d-flex"
                            style="width: fit-content"
                        >
                            {{ item.phone }}
                        </router-link>
                        <div v-for="(data, key) in item.data" :key="key" class="d-flex">
                            <template v-if="key === 'prize'">
                                <small class="w-100"
                                    >{{ $t(`titles.actions.${key}`) }}:
                                    {{ data.name[localLang] }}</small
                                >
                            </template>
                            <template v-else>
                                <small class="w-100"
                                    >{{ $t(`titles.actions.${key}`) }}: {{ data }}</small
                                >
                            </template>
                        </div>
                    </template>
                </logs-timeline>
            </b-col>
            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">
                <filter-block :filter="filter" @submit="getUserLogs">
                    <b-col cols="12">
                        <h5>Действия</h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.action"
                                :options="computedActions"
                                :reduce="(item) => item.key"
                                :searchable="false"
                                label="label"
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                </filter-block>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
