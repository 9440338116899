var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('cities')))]},proxy:true},(_vm.hasAccess('cities', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(_vm.checkLang(item.name))+" ")])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.price)+" ")])]}},{key:"cell(region)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.checkLang(item.region.name))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'cities', permission: 'delete' },"delete-handler":_vm.deleteCity,"index":index,"item":item,"update-access":{ section: 'cities', permission: 'update' }},on:{"openModal":function($event){return _vm.getCity(item)}}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.open')))),expression:"`${$t('button.open')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-success"},on:{"click":function($event){return _vm.openViewModal(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.city') : _vm.$t('add.city'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('choose.region'),"label-for":"region"}},[_c('v-select',{attrs:{"id":"region","disabled":_vm.isViewModal,"options":_vm.regionOptionList,"reduce":function (option) { return option.id; },"label":"label"},model:{value:(_vm.dataForModal.region_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "region_id", $$v)},expression:"dataForModal.region_id"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.price')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.price'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.price'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.price),callback:function ($$v) {_vm.$set(_vm.dataForModal, "price", $$v)},expression:"dataForModal.price"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }