<script>
import { BButton, BCol, BFormGroup, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import api from '@/services/api';
import FilterBlock from '@/components/FilterBlock';
import { deletePropertiesWhichNoValue } from '@/util/helper';
import LogsTimeline from '@/components/LogsTimeline';
import ManualPacketForm from '@/components/ManualPacketForm';
import SubscriberActivatePacketForm from '@/components/SubscriberActivatePacketForm';

export default {
    name: 'SubscribersSingleManualPackets',
    components: {
        SubscriberActivatePacketForm,
        ManualPacketForm,
        LogsTimeline,
        FilterBlock,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        vSelect
    },
    inject: ['gifts', 'steps'],
    data: () => ({
        logs: {
            items: [],
            pagination: {}
        },
        filter: {
            page: 1,
            action: 'set_by_user'
        },
        requestPending: false
    }),
    methods: {
        async getUserLogs() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            const { data } = await api.subscriberV2Api.fetchActivatePackageAsSubscribersLogs();
            if (data.pagination.current === 1) {
                this.logs.items = data.items;
            } else {
                this.logs.items = [...this.logs.items, ...data.items];
            }
            this.logs.pagination = data.pagination;
            this.requestPending = false;
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            await this.getUserLogs();
        }
    },
    mounted() {
        // this.getUserLogs();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-7 col-12 order-2 order-sm-1">
                <logs-timeline
                    :items="logs.items"
                    :pagination="logs.pagination"
                    v-model:loading="requestPending"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>User: {{ item.data.user }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                    </template>
                </logs-timeline>
            </b-col>
            <b-col class="mb-2 mb-sm-0 col-lg-6 col-sm-5 col-12 order-1 order-sm-2" v-if="gifts">
                <div class="card w-100 m-0 p-2">
                    <subscriber-activate-packet-form
                        :id="$route.params.id"
                        :gifts="gifts.value"
                        :steps="steps.value"
                        @success="getUserLogs"
                    />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
