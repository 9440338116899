import AppScenarioPrize from '@/views/scenario/scenario-prize/AppScenarioPrize.vue';
import AppScenario from '@/views/scenario/scenario/AppScenario.vue';
import AppScenarioNotification from '@/views/scenario/scenario-notification/AppScenarioNotification.vue';
import AppScenarioLevel from '@/views/scenario/scenario-level/AppScenarioLevel.vue';

export default [
    {
        path: '/scenario-prize',
        name: 'scenario-prize',
        component: AppScenarioPrize,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/scenario-level',
        name: 'scenario-level',
        component: AppScenarioLevel,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/scenario-notification',
        name: 'scenario-notification',
        component: AppScenarioNotification,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/scenario',
        name: 'scenario',
        component: AppScenario,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
