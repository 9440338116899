import qs from 'qs';
import axios from 'axios';
import api from '@/services/api';
import router from '@/router';
import { getLocalVar, removeLocalVar, setLocalVar } from '@/util/localstorage-helper';
import { MERCHANT_ID } from '@/constants/names';

function navigateToLoginPage() {
    removeLocalVar('accessToken');
    removeLocalVar('auth__refresh__token');
    if (router.currentRoute.name !== 'auth Login') {
        router.push({
            name: 'Auth Login'
        });
    }
}

async function retryApiCall(config) {
    const access_token = getLocalVar('accessToken');
    const headers = {};

    if (access_token) {
        headers['Authorization'] = `${access_token}`;
    }

    // config.paramsSerializer = (params) => {
    //   console.log(params, 'params')
    //   return qs.stringify(params, {
    //     encode: false,
    //     skipNulls: false,
    //     withCredentials: true,
    //   })
    // }

    const _axios = axios.create({
        baseURL: config.baseURL,
        headers
    });

    const data = () => {
        if (config.data) {
            return JSON.parse(config.data);
        }
        return config.data;
    };
    return await _axios({
        method: config.method,
        url: config.url,
        data: data()
    })
        .then((response) => response)
        .catch((error) => Promise.reject(error));
}

async function waitApiCall(config) {
    return await new Promise((resolve) => {
        const starter = setInterval(() => {
            const pending = sessionStorage.getItem('pending');
            if (!parseInt(pending)) {
                resolve();
                clearInterval(starter);
            }
        }, 50);
    }).then(async () => await retryApiCall(config));
}

async function refreshToken() {
    const refreshToken = getLocalVar('auth__refresh__token');
    if (refreshToken) {
        sessionStorage.setItem('pending', '1');
        return await api.auth
            .updateToken(refreshToken)
            .then(async (response) => {
                const { access_token } = await response.data;
                // setLocalVar('auth__refresh__token', refresh_token)
                setLocalVar('accessToken', access_token);
                return 1;
            })
            .catch(() => 1)
            .finally(() => {
                sessionStorage.setItem('pending', '0');
                return 1;
            });
    } else {
        navigateToLoginPage();
        return 0;
    }
}

export function axiosRequestInterceptResponse(config) {
    const access_token = getLocalVar('accessToken');
    config.paramsSerializer = (params) => {
        return qs.stringify(params, {
            encode: false,
            skipNulls: false,
            // mode: 'no-cors',
            withCredentials: true
        });
    };

    const requestConfig = Object.assign({}, config);

    config.headers['Accept-Language'] = '';
    config.headers['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!config.headers['Authorization'] && config.url !== 'auth/login') {
        requestConfig.headers['Authorization'] = access_token;
    }
    return requestConfig;
}

export function axiosRequestInterceptResponseCustomToken(config) {
    const customToken =
        Number(MERCHANT_ID) === 1
            ? 'Bearer ok0odj3841rnifoefj32u3kfwn_fnwijal'
            : 'Bearer god04inofenifoef0e_EifnrocelLSew';

    config.paramsSerializer = (params) => {
        return qs.stringify(params, {
            encode: false,
            skipNulls: false,
            // mode: 'no-cors',
            withCredentials: true
        });
    };

    const requestConfig = Object.assign({}, config);

    config.headers['Accept-Language'] = '';
    config.headers['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!config.headers['Authorization'] && config.url !== 'auth/login') {
        requestConfig.headers['Authorization'] = customToken;
    }
    return requestConfig;
}

export async function axiosResponseInterceptorError(error) {
    if (error.response) {
        const { status } = error.response;
        switch (status) {
            case 401:
                const pending = sessionStorage.getItem('pending');
                if (parseInt(pending)) {
                    return await waitApiCall(error.config);
                } else {
                    navigateToLoginPage();
                    // const response = await refreshToken()
                    // if (response) {
                    //   return await retryApiCall(error.config)
                    // }
                }
        }
        return Promise.reject(error);
    }
}
