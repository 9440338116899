import AppCards from '@/views/cards/AppCards.vue';
import AppCardsManagement from '@/views/cards/cards-management/AppCardsManagement.vue';
import AppCardsManagementActiveCards from '@/views/cards/cards-management/views/AppCardsManagementActiveCards.vue';
import AppCardsManagementUserInventory from '@/views/cards/cards-management/views/AppCardsManagementUserInventory.vue';

export default [
    {
        path: '/cards',
        name: 'cards',
        component: AppCards,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/cards-management/:id',
        name: 'cards-management',
        component: AppCardsManagement,
        meta: {
            section: 'users',
            permission: 'view'
        },
        children: [
            {
                path: 'active-cards',
                component: AppCardsManagementActiveCards,
                name: 'active-cards',
                props: true,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'user-inventory',
                component: AppCardsManagementUserInventory,
                name: 'user-inventory',
                props: true,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            }
        ]
    }
];
