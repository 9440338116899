const locales = [
    {
        locale: 'uz',
        img: require('@/assets/images/flags/uz.png'),
        name: 'O`zbekcha'
    },
    {
        locale: 'ru',
        img: require('@/assets/images/flags/ru.png'),
        name: 'Русский'
    },
    {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English'
    }
];

export default locales;
