var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.stock_templates')))]},proxy:true},(_vm.hasAccess('news', 'create'))?{key:"button",fn:function(){return [_c('b-button',{staticClass:"ml-auto",attrs:{"to":{ name: 'stock-templates-create' },"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:item.is_active ? 'text-success' : 'text-danger',attrs:{"icon":item.is_active ? 'CheckIcon' : 'XIcon',"size":"20"}})]}},{key:"cell(package_double)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:item.package_double ? 'text-success' : 'text-danger',attrs:{"icon":item.package_double ? 'CheckIcon' : 'XIcon',"size":"20"}})]}},{key:"cell(modal_title)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"three_lines_text",domProps:{"innerHTML":_vm._s(_vm.checkLang(item.modal_title))}})]}},{key:"cell(modal_description)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.modal_description))+" ")]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.title))+" ")]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"three_lines_text",domProps:{"innerHTML":_vm._s(_vm.checkLang(item.description))}})]}},{key:"cell(templateCategories)",fn:function(ref){
var item = ref.item;
return _vm._l((item['templateCategories']),function(template){return _c('b-badge',{key:template.id},[(template.category)?_c('span',[_vm._v(" "+_vm._s(template.category.name.toUpperCase())+" ")]):_vm._e()])})}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('div',[(item.image.path)?_c('img',{staticClass:"table-img",attrs:{"src":item.image.path,"alt":""}}):_c('span',[_vm._v("No Photo")])]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.edit')))),expression:"`${$t('button.edit')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"to":{ name: 'stock-templates-update', params: { id: item.id } },"variant":"flat-dark"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('table-action-btns',{attrs:{"delete-access":{ section: 'news', permission: 'delete' },"delete-handler":_vm.deleteItem,"show-edit-button":false,"index":index,"item":item}})],1)]}}])})],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }