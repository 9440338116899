<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    computedStatusClass,
    generateIconColorToBoolean,
    generateIconToBoolean
} from '@/util/helper';
import vSelect from 'vue-select';
import { keys } from '@/util/object.util';
import { number } from 'echarts/lib/export';
import {
    addZero,
    dateFormatWithDot,
    dateProperties,
    subtractFiveHours
} from '@/util/date/calendar.util';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppCoinOrderList',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                limit: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            statusOptions: [],
            filter: {
                status: this.$route.query.status || '',
                search: this.$route.query.search || ''
            }
        };
    },
    computed: {
        number() {
            return number;
        },
        statusOptionList() {
            const locale = this.$i18n.locale;
            if (keys(this.statusOptions).length > 0) {
                const l = [];
                for (let [key, option] of Object.entries(this.statusOptions)) {
                    l.push({
                        value: key,
                        label: option[locale][0].toUpperCase() + option[locale].slice(1)
                    });
                }
                const all = { uz: 'Hammasi', ru: 'Bce', en: 'All' };
                l.unshift({ value: 0, label: checkLang(all) });
                return l;
            }

            return [];
        },

        orderFields() {
            return [
                {
                    key: 'id',
                    label: 'Id'
                },
                {
                    key: 'client_detail',
                    label: this.$t('titles.client_detail')
                    // formatter: (client_detail) =>
                    //     client_detail.first_name + ' ' + client_detail.last_name
                },
                {
                    key: 'phone',
                    label: this.$t('order.phone')
                },
                {
                    key: 'address',
                    label: this.$t('titles.region'),
                    formatter: (address) => checkLang(address.region?.name)
                },
                {
                    key: 'status',
                    label: this.$t('titles.status'),
                    formatter: (status) => checkLang(status)
                },
                {
                    key: 'grand_total',
                    label: this.$t('titles.price')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at'),
                    formatter: (created_at) => this.getCreatedDate(created_at)
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = parseInt(query.page) || 1;
                this.pagination.limit = parseInt(query.limit) || 10;
                this.getOrders();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: parseInt(newPage),
                    limit: parseInt(this.query.limit) || 10
                });
            }
        },

        'pagination.limit': {
            handler(limit) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    limit: parseInt(limit)
                });
            }
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getStatusList();
        this.requestPending = false;
    },

    methods: {
        checkLang,
        computedStatusClass,
        getUserPhone(client) {
            if (client && client.user) return client.user.phone;
            return '';
        },
        getCreatedDate(time) {
            const _sTime = subtractFiveHours(time);
            const _slashTime = dateFormatWithDot(_sTime, { reverse: false, monthIndex: 1 });
            const { hours, minutes } = dateProperties(_sTime);
            return _slashTime + ` ${addZero(hours)}:${addZero(minutes)}`;
        },
        async getOrders() {
            this.requestPending = true;
            const body = {
                page: this.pagination.current,
                limit: this.pagination.limit,
                merchant_id: MERCHANT_ID,
                ...this.query
            };
            await api.coin
                .getCoinOrdersList(body)
                .then((response) => {
                    this.items = response.data.result;

                    this.pagination = response.data.pagination;
                    this.pagination.limit = response.data.pagination.perPage;
                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.requestPending = false;
                });
        },
        async getStatusList() {
            try {
                const response = await api.coin.fetchCoinStatus();
                this.statusOptions = response.data;
                this.isOpenModal = true;
            } catch (e) {
                this.$toast.error(e.response?.data?.message || e.message || this.$t('error.smth'));
            }
        },

        async deleteOrder(id) {
            const params = {
                id: id
            };
            await api.coin
                .deleteCoinOrder(params)
                .then(() => {
                    this.getOrders();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        replaceRouter(query) {
            this.$router.push({ query }).catch(() => {});
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },
        statusFiltered(event) {
            if (event && event.value) {
                this.replaceRouter({ ...this.query, status: parseInt(event.value) });
            } else {
                this.filter['status'] = '';
                delete this.query['status'];
                this.replaceRouter({ ...this.query });
            }
        },
        searchDebounce(value) {
            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.searchFiltered(value);
            }, 1000);
        },
        searchFiltered(search) {
            if (search) {
                this.replaceRouter({ ...this.query, search });
            } else {
                this.filter['search'] = '';
                delete this.query['search'];
                this.replaceRouter({ ...this.query });
            }
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('navigation.coin_order') }}</template>
        </page-title>

        <div class="row d-flex align-items-center justify-content-between mb-2" style="gap: 1rem">
            <div class="col-12">
                <v-select
                    v-model="filter.status"
                    :options="statusOptionList"
                    :placeholder="$t('choose.category')"
                    :searchable="true"
                    style="background-color: #fff; height: 37px"
                    @input="statusFiltered"
                >
                </v-select>
            </div>
            <div class="col-4">
                <b-form-group>
                    <b-form-input
                        v-model="filter.search"
                        :placeholder="$t('search')"
                        size="lg"
                        style="height: 37px"
                        @input="searchDebounce"
                    />
                </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center">
                Per page:
                <v-select
                    v-model="pagination.limit"
                    :options="perPageList"
                    class="ml-2"
                    style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                />
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="orderFields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(images)="{ item }">
                    <div class="table-img">
                        <img
                            v-if="item.images && item.images.length > 0"
                            :src="item.images[0].path"
                        />
                        <img v-else :src="require('@/assets/images/icons/no-photo.png')" />
                    </div>
                </template>

                <template #cell(client_detail)="{ item }">
                    <span v-if="item.client_detail">
                        {{ item.client_detail.first_name }} {{ item.client_detail.last_name }}
                    </span>
                </template>

                <template #cell(type)="{ item }">
                    {{ $t(`titles.${item.type}`) }}
                </template>

                <template #cell(status)="{ item }">
                    <b-badge
                        :class="computedStatusClass(item.status)"
                        :style="`background-color: ${item.color}`"
                    >
                        {{ checkLang(item.status) }}
                    </b-badge>
                </template>

                <template #cell(duration_type)="{ item }">
                    {{ $t(`titles.${item.duration_type}`) }}
                </template>

                <template #cell(grand_total)="{ item }">
                    <div class="d-flex align-items-center">
                        <span>{{ item.grand_total }}</span>
                        <img class="grand_total-img" src="@/assets/images/icons/coin.png" alt="" />
                    </div>
                </template>

                <template #cell(phone)="{ item }">
                    <span>+{{ getUserPhone(item.client_detail) }}</span>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="['stroke-current', generateIconColorToBoolean(item.is_published)]"
                        :icon="generateIconToBoolean(item.is_published)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :delete-access="{ section: 'orders', permission: 'delete' }"
                        :index="index"
                        :item="item"
                        :update-access="{ section: 'orders', permission: 'update' }"
                        @openModal="
                            $router.push({ name: 'coin-order-view', params: { id: item.id } })
                        "
                    />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.limit"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.grand_total-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 4px;
}
</style>
