<script>
import api from '@/services/api';
import { BCol, BFormGroup, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
// import { deletePropertiesWhichNoValue } from '@/util/helper';
import FilterBlock from '@/components/FilterBlock';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import LevelLoggerTimeLine from '@/components/LevelLoggerTimeLine.vue';

export default {
    name: 'SubscribersSingleLevelActivities',
    components: { LevelLoggerTimeLine, FilterBlock, BRow, BCol, BFormGroup, vSelect },
    data() {
        return {
            subscriberLevels: {
                items: [],
                pagination: {}
            },
            params: {
                page: 1,
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                sort: {
                    column: 'id',
                    direction: 'desc'
                },
                search: '',
                filters: {
                    user_id: this.$route.params.id,
                    'prize.type': [],
                    level_id: []
                }
            },
            prize_types: ['voice', 'internet', 'coin', 'paynet', 'product'],
            level_types: [],
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },
    methods: {
        checkLocales,
        async getSubscriberLevels() {
            this.requestPending = true;
            try {
                const { data } = await api.newLevel.fetchAllLevelWinnings(this.params);
                if (data.data.pagination.current === 1) {
                    this.subscriberLevels.items = data.data.list;
                } else {
                    this.subscriberLevels.items = [
                        ...this.subscriberLevels.items,
                        ...data.data.list
                    ];
                }
                // this.subscriberLevels.items.forEach((win) => {
                //     win.action = 'gift_set';
                //     return win;
                // });
                this.subscriberLevels.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
            this.requestPending = false;
        },

        async getLevelTypes() {
            const body = {
                page: 1,
                limit: 20,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevel.fetchAllItems(body);
                this.level_types = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.params.page = page;
            await this.getSubscriberLevels();
        },

        resetRequest() {
            this.params = {
                page: 1,
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                sort: {
                    column: 'id',
                    direction: 'desc'
                },
                search: '',
                filters: {
                    user_id: this.$route.params.id,
                    'prize.type': [],
                    level_id: []
                }
            };
            this.getSubscriberLevels();
        }
    },
    async mounted() {
        await this.getSubscriberLevels();
        await this.getLevelTypes();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-7 col-12 order-2 order-sm-1">
                <level-logger-time-line
                    v-model:loading="requestPending"
                    :items="subscriberLevels.items"
                    :pagination="subscriberLevels.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="time__line-block">
                            <div class="d-flex">
                                <h5>{{ checkLocales(item.level.name) }}</h5>
                                <small class="ml-auto text-right">
                                    {{ item.created_at }}
                                </small>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>{{ $t('titles.title') }} :</span>
                                <h5 class="mb-0 ml-1">{{ checkLocales(item.prize.name) }}</h5>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>{{ $t('titles.type') }} :</span>
                                <h5 class="mb-0 ml-1">{{ item.prize.type }}</h5>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>{{ $t('titles.qty') }} :</span>
                                <h5 class="mb-0 ml-1">{{ item.prize.value }}</h5>
                            </div>
                            <div class="level__product-img d-flex align-items-center">
                                <span>{{ $t('titles.image') }}</span
                                >:
                                <img class="ml-1" :src="item.prize.upload.path" alt="" />
                            </div>
                        </div>
                    </template>
                </level-logger-time-line>
            </b-col>
            <b-col class="mb-2 mb-sm-0 col-lg-6 col-sm-5 col-12 order-1 order-sm-2">
                <b-card>
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('titles.type') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="params.filters['prize.type']"
                                :options="prize_types"
                                :multiple="true"
                                :searchable="false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <h5>
                            {{ $t('navigation.levels') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="params.filters.level_id"
                                :options="level_types"
                                :reduce="(level) => level.id"
                                :searchable="false"
                                :multiple="true"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-2 d-flex justify-content-end">
                        <b-button @click="resetRequest">
                            {{ $t('button.reset') }}
                        </b-button>
                        <b-button @click="getSubscriberLevels" class="ml-2" variant="success">{{
                            $t('button.filter')
                        }}</b-button>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
.time__line-block {
    & span {
        font-size: 16px;
    }
}
.level__product-img {
    width: 60px;
    height: 60px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
