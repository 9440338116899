<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
// import CompInfinityScrollPackages from '@/views/new-chapter/randomizer/components/CompInfinityScrollPackages.vue';

export default {
    name: 'ViewRandomizerCategories',
    components: {
        // CompInfinityScrollPackages,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                type_name: '',
                name: '',
                charge_id: '',
                is_subscriber: false,
                by_subscriber: false
            },
            dataForChildModal: {
                category_id: '',
                step: '',
                packages: [],
                static_package: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            chargesList: [],
            categoriesList: [],
            packagesList: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        randomizerPackages() {
            return this.$store.getters.packages;
        },

        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type_name',
                    label: this.$t('titles.type')
                },
                {
                    key: 'charge',
                    label: this.$t('charge id')
                },
                {
                    key: 'is_subscriber',
                    label: this.$t('titles.is_subscriber')
                },
                {
                    key: 'by_subscriber',
                    label: this.$t('titles.by_subscriber')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        childFields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'step',
                    label: this.$t('titles.step')
                },
                {
                    key: 'packages',
                    label: this.$t('titles.packages')
                },
                {
                    key: 'static_package',
                    label: this.$t('titles.static_package')
                },
                {
                    key: 'child_actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getChargesList();
        if (this.randomizerPackages && !this.randomizerPackages['randomizerPackages']) {
            await this.fetchRandomizerPackages();
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async fetchRandomizerPackages() {
            await this.$store
                .dispatch('fetchRandomizerPackages')
                .then(() => {
                    this.packagesList = this.randomizerPackages?.randomizerPackages?.data?.list;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        },

        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.randomizerCategories.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
                this.categoriesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getChargesList() {
            const params = {
                page: 1,
                limit: 20
            };

            try {
                const { data } = await api.billingCharges.fetchAllItems(params);
                this.chargesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.randomizerCategories
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.randomizerCategories
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async createStep(body) {
            await api.randomizerCategorySteps
                .createItem(body)
                .then(async () => {
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                    this.$refs.createUpdateChildModal.hide();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.randomizerCategories
                .updateItem(id, body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateStep(id, body) {
            await api.randomizerCategorySteps
                .updateItem(id, body)
                .then(() => {
                    this.getAll();
                    this.$refs.createUpdateChildModal.hide();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.randomizerCategories
                .deleteItem(id)
                .then(() => {
                    this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async deleteCategoryStep(val) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.randomizerCategorySteps
                        .deleteItem(val.id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.getAll();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async submitChildModal() {
            this.$refs.createUpdateChildModalValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForChildModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            if (typeof body.static_package === 'object') {
                                body.static_package = body.static_package?.id;
                            }

                            body.packages = body.packages.map((item) => {
                                if (typeof item === 'object') {
                                    return item.id;
                                } else {
                                    return item;
                                }
                            });

                            const theSameItem = body.packages.some((item) => {
                                return item === body.static_package;
                            });

                            if (!theSameItem) {
                                await this.updateStep(this.updatingItem.id, body, config);
                                this.$toast.success(this.$t('success.updated.title'));
                            } else {
                                this.$toast.error('The same package!');
                            }
                        } else {
                            const theSameItem = body.packages.some((item) => {
                                return item === body.static_package;
                            });
                            if (!theSameItem) {
                                await this.createStep(body, config);
                                this.$toast.success(this.$t('success.added'));
                            } else {
                                this.$toast.error('The same package!');
                            }
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        async getOneStep(item) {
            this.updatingItem = item;
            if (item) {
                await this.openChildModal();
                await api.randomizerCategorySteps
                    .fetchOneItem(item.id)
                    .then((response) => {
                        // merge Object for need data
                        const res = response.data.data;
                        // res.packages = res.packages.map((item) => {
                        //     const [typeName, number] = item.name.split(' ');
                        //     return {
                        //         id: item.id,
                        //         type_name: typeName,
                        //         qty: parseInt(number)
                        //     };
                        // });
                        // if (res?.static_package) {
                        //     const [typeName, qty] = res.static_package.name.split(' ');
                        //     res.static_package = {
                        //         id: res.static_package.id,
                        //         type_name: typeName.toLowerCase(),
                        //         qty: qty
                        //     };
                        // }
                        Object.keys(res).forEach((item) => {
                            if (item in this.dataForChildModal) {
                                this.dataForChildModal[item] = res[item];
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        async openChildModal(item) {
            if (item?.is_subscriber) {
                this.$refs.createUpdateChildModal.show();
                this.dataForChildModal = {
                    step: '',
                    packages: [],
                    static_package: null
                };
                this.dataForChildModal.category_id = item.id;
            } else {
                this.dataForChildModal = {
                    category_id: '',
                    step: '',
                    packages: [],
                    static_package: null
                };
                this.$refs.createUpdateChildModal.show();
            }
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.bonus_categories') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(show_details)="row">
                    <b-button
                        size="sm"
                        @click="row.toggleDetails"
                        variant="outline-primary"
                        class="p-1"
                    >
                        <feather-icon
                            size="20"
                            :icon="row.item._showDetails ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                        />
                    </b-button>
                </template>

                <template #row-details="row">
                    <b-card>
                        <b-table
                            :fields="childFields"
                            :items="row.item.steps"
                            :responsive="true"
                            borderless
                            outlined
                            striped
                            thead-class="bg__red"
                        >
                            <template #head(packages)="{}">
                                <div class="d-flex justify-content-center">
                                    {{ $t('titles.packages') }}
                                </div>
                            </template>

                            <template #head(static_package)="{}">
                                <div class="d-flex justify-content-center">
                                    {{ $t('titles.static_package') }}
                                </div>
                            </template>

                            <template #cell(packages)="{ item }">
                                <div class="d-flex justify-content-end">
                                    <b-badge
                                        v-for="(child, index) in item.packages"
                                        :key="index"
                                        class="table-badge__item"
                                    >
                                        <span>{{ child.name.toUpperCase() }}</span>
                                    </b-badge>
                                </div>
                            </template>

                            <template #cell(static_package)="{ item }">
                                <div class="d-flex justify-content-center">
                                    <b-badge
                                        v-if="item.static_package"
                                        variant="primary"
                                        size="20"
                                        class="table-badge__item"
                                    >
                                        {{ item.static_package.type_name.toUpperCase() }}
                                    </b-badge>
                                    <span v-else class="text-secondary">
                                        {{ $t('titles.no_data') }}
                                    </span>
                                </div>
                            </template>

                            <template #cell(step)="{ item }">
                                <b-badge variant="success" size="20" class="table-badge__item">
                                    <span class="font-medium-1">{{ item.step }}</span>
                                </b-badge>
                            </template>

                            <template #cell(child_actions)="{ item }">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.edit')}`"
                                        class="btn-icon"
                                        variant="flat-dark"
                                        @click="getOneStep(item)"
                                    >
                                        <feather-icon size="16" icon="EditIcon" />
                                    </b-button>
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.delete')}`"
                                        class="btn-icon"
                                        variant="flat-danger"
                                        @click="deleteCategoryStep(item)"
                                    >
                                        <feather-icon size="16" icon="Trash2Icon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                </template>

                <template #cell(charge)="{ item }">
                    {{ item.charge.name }}
                </template>

                <template #cell(is_subscriber)="{ item }">
                    <feather-icon
                        :class="[item.is_subscriber ? 'text-success' : 'text-danger']"
                        :icon="item.is_subscriber ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(by_subscriber)="{ item }">
                    <feather-icon
                        :class="[item.by_subscriber ? 'text-success' : 'text-danger']"
                        :icon="item.by_subscriber ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-b-tooltip.hover.top="`${$t('button.create_category_step')}`"
                            class="btn-icon"
                            variant="flat-success"
                            @click="openChildModal(item)"
                        >
                            <feather-icon size="16" icon="PlusIcon" />
                        </b-button>
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.category') : $t('add.category')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE NAME    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.type') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.type_name"
                                    :placeholder="$t('enter.type')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    CHARGE ID    -->
                    <b-col cols="12">
                        <b-form-group label="Charge ID" label-for="charges">
                            <v-select
                                id="charges"
                                v-model="dataForModal.charge_id"
                                :disabled="isViewModal"
                                :options="chargesList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('enter.charge_id')"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    IS SUBSCRIBER    -->
                    <b-col cols="6" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox
                            id="is_subscriber"
                            v-model="dataForModal.is_subscriber"
                            :disabled="isViewModal"
                        />
                        <label for="is_subscriber" class="font-medium-1">
                            {{ $t('titles.is_subscriber') }}
                        </label>
                    </b-col>

                    <!--    BY SUBSCRIBER    -->
                    <b-col cols="6" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox
                            id="by_subscriber"
                            v-model="dataForModal.by_subscriber"
                            :disabled="isViewModal"
                        />
                        <label for="by_subscriber" class="font-medium-1">
                            {{ $t('titles.by_subscriber') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    CREATE UPDATE CHILD MODAL    -->
        <b-modal
            id="createUpdateChildModal"
            ref="createUpdateChildModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.category_step') : $t('add.category_step')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateChildModalValidation">
                <b-row>
                    <!--     CATEGORY ID     -->
                    <b-col cols="12" class="mb-1">
                        <b-form-group :label="$t('titles.categories')" label-for="categories">
                            <v-select
                                id="categories"
                                v-model="dataForChildModal.category_id"
                                :disabled="isViewModal"
                                :options="categoriesList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('choose.category')"
                                label="name"
                                :clearable="false"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    STEP    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.step') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.step')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.step"
                                    :placeholder="$t('enter.step')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    STATIC PACKAGE     -->
                    <b-col cols="12" class="mt-1">
                        <b-form-group :label="$t('titles.static_package')" label-for="packages">
                            <v-select
                                id="packages"
                                v-model="dataForChildModal.static_package"
                                :disabled="isViewModal"
                                :options="packagesList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('choose.static_package')"
                                label="type_name"
                            >
                                <template v-slot:option="option">
                                    {{ option.type_name + ' ' + option.qty }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ option.type_name + ' ' + option.qty }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>

                    <!--     PACKAGES     -->
                    <b-col cols="12" class="mt-1">
                        <b-form-group :label="$t('titles.packages')" label-for="packages">
                            <v-select
                                id="packages"
                                v-model="dataForChildModal.packages"
                                :disabled="isViewModal"
                                :options="packagesList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('choose.package')"
                                label="name"
                                :multiple="true"
                            >
                                <template v-slot:option="option">
                                    {{ option.type_name + ' ' + option.qty }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ option.type_name + ' ' + option.qty }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitChildModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
