<script>
import api from '@/services/api';
import FlatPickr from 'vue-flatpickr-component';

export default {
    name: 'index',
    components: {
        FlatPickr
    },
    data() {
        return {
            tabs: [
                {
                    value: 'level-balance',
                    label: 'balance',
                    routeName: 'new-level-statistics-balance'
                },
                {
                    value: 'level-score',
                    label: 'score',
                    routeName: 'new-level-statistics-score'
                },
                {
                    value: 'level-winnings',
                    label: 'winnings',
                    routeName: 'new-level-statistics-winnings'
                }
            ],
            body: {
                date_start: null,
                date_end: null
            },
            statisticsCards: [],
            statisticsCardsTotal: null
        };
    },
    async mounted() {
        await this.getLevelStatistics();
    },

    methods: {
        isActive(route) {
            return this.$route.name === route.routeName;
        },

        async getLevelStatistics() {
            this.statisticsCardsTotal = null;
            try {
                const { data } = await api.newLevel.fetchLevelStatistics(this.body);
                this.statisticsCards = data.data;
                for (let i = 0; i < this.statisticsCards.length; i++) {
                    this.statisticsCardsTotal += this.statisticsCards[i].count;
                }
                console.log(this.statisticsCardsTotal, 'this.statisticsCardsTotal');
            } catch (e) {
                console.error(e);
            }
        },

        resetTheFilter() {
            this.body.date_start = null;
            this.body.date_end = null;
            this.getLevelStatistics();
        }
    }
};
</script>

<template>
    <div>
        <div class="row align-items-center mb-2">
            <!--    FILTER    -->
            <div class="col-12">
                <div class="row flex-wrap mb-1">
                    <div class="col-12 col-sm-6 col-lg-5 mb-1 mb-lg-0">
                        <flat-pickr
                            autocomplete="off"
                            v-model="body.date_start"
                            :config="{
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            class="form-control h-100"
                            name="date"
                        />
                    </div>
                    <div class="col-12 col-sm-6 col-lg-5 mb-1 mb-lg-0">
                        <flat-pickr
                            autocomplete="off"
                            v-model="body.date_end"
                            :config="{
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            class="form-control h-100"
                            name="date"
                        />
                    </div>
                    <div
                        class="d-flex align-items-end justify-content-end col-12 col-lg-2 offset-md-0 mb-1 mb-lg-0"
                    >
                        <div class="col-6">
                            <b-button
                                class="mr-1 w-100"
                                @click="getLevelStatistics"
                                variant="primary"
                            >
                                <feather-icon icon="SearchIcon" size="20" />
                            </b-button>
                        </div>
                        <div class="col-6">
                            <b-button class="w-100" @click="resetTheFilter" variant="danger">
                                <feather-icon icon="XIcon" size="20" />
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-1">
                <div class="d-flex flex-wrap">
                    <div
                        class="col-12 col-sm-6 col-md-4 col-xl-2"
                        v-for="card in statisticsCards"
                        :key="card.type"
                    >
                        <div class="card p-1 d-flex align-items-center justify-content-center">
                            <span class="mb-1 font-medium-3">
                                {{ $t('titles.type') }} :
                                <b-badge variant="primary">
                                    {{ card.type.toUpperCase() }}
                                </b-badge>
                            </span>
                            <span class="mb-0 font-medium-3">
                                {{ $t('titles.qty') }} :
                                <b-badge variant="primary">
                                    {{ card.count }}
                                </b-badge>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xl-2">
                        <div class="card p-1 d-flex align-items-center justify-content-center">
                            <span class="mb-1 font-medium-3">
                                {{ $t('titles.type') }} :
                                <b-badge variant="primary"> ALL </b-badge>
                            </span>
                            <span class="mb-0 font-medium-3">
                                {{ $t('titles.qty') }} :
                                <b-badge variant="primary">
                                    {{ this.statisticsCardsTotal }}
                                </b-badge>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="nav nav-tabs flex-nowrap overflow-x-scroll">
            <router-link
                v-for="(route, index) in tabs"
                :key="index"
                :to="{ name: route.routeName }"
                active-class="active"
                class="nav-link"
                :class="{ active: isActive(route) }"
                style="min-width: fit-content"
            >
                {{ $t(`titles.level_${route.label}`) }}
            </router-link>
        </div>
        <router-view />
    </div>
</template>

<style scoped lang="scss">
::v-deep .btn {
    height: 44px !important;
}
</style>
