<script>
import { BButton, BCol, BFormGroup, BFormInput, BPagination, BRow, BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import TogglerBtn from '@/components/ui/TogglerBtn/TogglerBtn';
import SlideInOutAnimation from '@/components/ui/SlideInOutAnimation/SlideInOutAnimation';
import api from '@/services/api';
import { getLocalVar } from '@/util/localstorage-helper';
import { sortItems } from '@/util/helper';
import LogsTimeline from '@/components/LogsTimeline';
import FilterBlock from '@/components/FilterBlock';
import { logApi } from '@/services/log/log.service';

export default {
    name: 'SubscribersActivities',
    components: {
        BPagination,
        FilterBlock,
        LogsTimeline,
        SlideInOutAnimation,
        TogglerBtn,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BSpinner,
        vSelect
    },
    data: () => ({
        activityLogs: {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            }
        },
        actionsList: [],
        filter: {
            page: 1
        },
        // defaultActionFilter: [],
        interval: null,
        localLang: getLocalVar('lang'),

        autoReload: true,
        isFilterDisplay: true,
        requestPending: false
    }),
    computed: {
        computedPlayPauseIcon() {
            return this.autoReload ? 'PauseIcon' : 'PlayIcon';
        },
        showPagination() {
            return this.activityLogs.items.length;
        }
    },
    watch: {
        'filter.page'() {
            this.stopAutoReload();
            this.getActivity();
        }
    },
    methods: {
        async getActivity() {
            if (this.requestPending) return;
            this.requestPending = true;
            await this.checkFilter();
            // const body = Object.assign({ action: this.defaultActionFilter }, this.filter);
            const { data } = await api.logs.get(this.filter);
            this.activityLogs.items = data.items;
            // if (data.pagination.current === 1) {
            //     this.activityLogs.items = data.items;
            // } else {
            //     this.activityLogs.items = [...this.activityLogs.items, ...data.items];
            // }
            this.activityLogs.pagination = data.pagination;
            this.requestPending = false;
        },

        loadNextPage(page) {
            if (this.requestPending || this.autoReload || !page) return;
            this.filter.page = page;
            this.getActivity();
        },

        async getActionsList() {
            const { data } = await logApi.actions();
            this.actionsList = sortItems(data);
            // this.defaultActionFilter = this.actionsList.filter((item) => item !== 'no_money');
        },

        async filterAction() {
            if (this.requestPending) return;
            this.autoReload = false;
            await this.checkFilter();
            this.requestPending = true;
            const { data } = await api.logs.get(this.filter);
            window.scrollTo(0, 0);
            this.activityLogs.items = data.items;
            this.activityLogs.pagination = data.pagination;
            this.requestPending = false;
        },

        checkFilter() {
            Object.keys(this.filter).forEach((key) => {
                if (key === 'phone') {
                    if (this.filter[key].replace(/\D/g, '').length < 12) return;
                    this.filter[key] = this.filter[key].replace(/\D/g, '');
                } else if (!this.filter[key]) {
                    delete this.filter[key];
                }
            });
        },

        toggleAutoReload() {
            return this.autoReload ? this.stopAutoReload() : this.startAutoReload();
        },

        startAutoReload() {
            if (!this.autoReload) return this.getAuto();
            this.interval = setInterval(() => {
                this.getAuto();
            }, 2000);
        },

        async getAuto() {
            this.autoReload = true;
            if (this.requestPending) return;
            this.filter.page = 1;
            await this.getActivity();
        },

        stopAutoReload() {
            this.autoReload = false;
            this.requestPending = false;
            clearInterval(this.interval);
        },

        deSelect(val) {
            if (!val) {
                this.filterAction();
            }
        }
    },
    async mounted() {
        this.startAutoReload();
        await Promise.all([this.getActivity(), this.getActionsList()]);
    }
};
</script>

<template>
    <div>
        <div class="d-flex align-items-start">
            <h1 class="mb-2">
                {{ $t('navigation.subscribers.activity') }}
            </h1>
            <toggler-btn v-model="isFilterDisplay" class="ml-auto" />
        </div>
        <b-row>
            <b-col class="col-lg-6 col-sm-6 col-12 order-lg-1 order-sm-1 order-2">
                <logs-timeline
                    v-model:loading="requestPending"
                    :items="activityLogs.items"
                    :pagination="activityLogs.pagination"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>{{ item.action }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <router-link
                            :to="{
                                name: 'subscribers Single',
                                params: {
                                    id: item.user.id
                                }
                            }"
                            class="d-flex"
                        >
                            {{ item.phone }}
                        </router-link>
                        <div v-for="(data, key) in item.data" :key="key" class="d-flex">
                            <small v-if="key === 'prize'">
                                {{ key }}: {{ data.name[localLang] }}
                            </small>
                            <small v-else>{{ key }}: {{ data }}</small>
                        </div>
                    </template>
                </logs-timeline>
                <b-row class="mt-2 mb-4">
                    <b-col class="offset-1" cols="9">
                        <b-pagination
                            size="lg"
                            v-if="showPagination"
                            v-model="filter.page"
                            :per-page="activityLogs.pagination.perPage"
                            :total-rows="activityLogs.pagination.total"
                            class="center mr-4 mb-0"
                            first-number
                            last-number
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="col-lg-6 col-sm-6 col-12 order-lg-2 order-sm-2 order-1">
                <div class="filter--sticky">
                    <slide-in-out-animation v-model="isFilterDisplay">
                        <filter-block
                            v-model:filter="filter"
                            :filter="filter"
                            :loading="requestPending"
                            @submit="filterAction"
                        >
                            <b-col class="mb-2" cols="12">
                                <b-button
                                    :disabled="requestPending"
                                    variant="outline-primary"
                                    @click="toggleAutoReload"
                                >
                                    <b-spinner v-if="requestPending" small variant="primary" />
                                    <feather-icon v-else :icon="computedPlayPauseIcon" />
                                </b-button>
                            </b-col>
                            <b-col class="mb-2" cols="12">
                                <h5>
                                    {{ $t('titles.phone_number') }}
                                </h5>
                                <b-form-group>
                                    <b-form-input
                                        v-model="filter.phone"
                                        v-mask="'+998 9# ### ## ##'"
                                        placeholder="+998 9# ### ## ##"
                                        @input="deSelect"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col class="mb-2" cols="12">
                                <h5>
                                    {{ $t('titles.action') }}
                                </h5>
                                <b-form-group>
                                    <v-select
                                        v-model="filter.action"
                                        :disabled="!actionsList.length"
                                        :menu-props="{
                                            closeOnContentClick: false
                                        }"
                                        :options="actionsList"
                                        :searchable="false"
                                        @input="deSelect"
                                    />
                                </b-form-group>
                            </b-col>
                        </filter-block>
                    </slide-in-out-animation>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.activities {
    width: 100%;
    padding: 25px 15px;
}
</style>
