<script>
export default {
    name: 'ProgressLinear'
};
</script>

<template>
    <div class="progress-linear-bar">
        <div class="progress-linear-bar__value" />
    </div>
</template>
<style lang="scss" src="./ProgressLinear.scss" />
