import { hasOwnProperty, keys } from '@/util/object.util';
import router from '@/router';
import { dateProperties, datesAreOnSameDay } from '@/util/date/calendar.util';

export default {
    getMerchantId: () => {
        if (router.currentRoute.name === 'alchiroq-chat') {
            return 1;
        }

        return 2;
    },
    getMessagesById: (state, gts) => (chatId) => {
        if (keys(state.chatMessages).length === 1) {
            return {
                result: [],
                pagination: state.paginationSchema
            };
        }

        if (gts.hasMessages(chatId)) {
            return state.chatMessages[chatId];
        }

        return {
            result: [],
            pagination: state.paginationSchema
        };
    },
    getActiveChatId: (state) => state.chatMessages.activeChatId,
    getActiveChatUser() {
        if (this.hasMessages(this.getActiveChatId)) {
            return {};
        }
    },
    hasMessages: (state) => (chatId) => {
        return (
            hasOwnProperty(state.chatMessages, chatId) &&
            hasOwnProperty(state.chatMessages[chatId], 'result') &&
            state.chatMessages[chatId].result.length > 0
        );
    },
    getPaginationByChatId: (state, gts) => (chatId) => {
        return gts.getMessagesById(chatId).pagination;
    },
    isChatFetchingByUpdate: (state) => state.chats.pending,
    getReplyContext: (state) => state.replyContext,
    showReplyContext: (state) => keys(state.replyContext).length > 0,
    getEditContext: (state) => state.editContext,
    showEditContext: (state) => keys(state.editContext).length > 0,
    getActiveClientCtx: (state) => state.activeClient,
    hasActiveClientCtx: (state, gts) => keys(gts.getActiveClientCtx).length > 0,
    hasQueueCtx: (state) => keys(state.messageQueue).length > 0,
    getChatResultByDate: (state) => {
        if (state.chatMessages.result.length) {
            return state.chatMessages.result.reduce((acm, v) => {
                const cTime = 'created_at';

                const hasInStack = acm.findIndex((a) => {
                    return datesAreOnSameDay(a.time, v[cTime]);
                });

                if (hasInStack === -1) {
                    acm.push({
                        list: [v],
                        time: v[cTime]
                    });
                } else {
                    acm[hasInStack].list.push(v);
                }

                return acm;
            }, []);
        }

        return [];
    },
    getChatListByLatest: (state) => {
        if (state.chats.result.length) {
            return state.chats.result.sort((a, b) => {
                const { time: aTime } = dateProperties(a['created_at'], 'string');
                const { time: bTime } = dateProperties(b['created_at'], 'string');
                return b.unread_count - a.unread_count || bTime - aTime;
            });
        }
        return [];
    }
};
