<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppSegmentation',
    components: {
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'user_invoices',
                    label: this.$t('titles.user_invoices')
                },
                {
                    key: 'is_finish',
                    label: this.$t('segmentation.is_finished')
                },
                {
                    key: 'started_at',
                    label: this.$t('game_monitoring.started_time')
                },
                {
                    key: 'finished_at',
                    label: this.$t('titles.finished_time')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.segmentationApi.fetchAllItems(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        changeRoute(id) {
            this.$router.push({ name: 'segmentation-detail-rate-plan', params: { id: id } });
        },

        beforeClose() {
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.events') }}</template>
            <!--            <template v-if="hasAccess('users', 'create')" #button>-->
            <!--                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">-->
            <!--                    {{ $t('button.add') }}-->
            <!--                    <feather-icon icon="PlusIcon" />-->
            <!--                </b-button>-->
            <!--            </template>-->
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(is_finish)="{ item }">
                    <feather-icon
                        :class="[item['is_finish'] ? 'text-success' : 'text-danger']"
                        :icon="item['is_finish'] ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            @click="changeRoute(item.id)"
                            variant="outline-primary"
                            class="px-1"
                        >
                            <feather-icon class="primary" icon="EyeIcon" size="18" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}
.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
