import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class TelegramChannels extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllChannels(params) {
        return this.post('ads-channel/findAll', params);
    }

    fetchOneChannel(params) {
        return this.post('ads-channel/findOne', params);
    }

    createChannel(body) {
        return this.post('ads-channel', body);
    }

    updateChannel(body) {
        return this.post('ads-channel/update', body);
    }

    deleteChannel(body) {
        return this.post('ads-channel/remove', body);
    }

    fetchOneChannelStatistics(params) {
        return this.post('ads-channel/statisticsMonitoring', params);
    }
}

export default TelegramChannels;
