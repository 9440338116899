<script>
export default {
    name: 'index'
};
</script>

<template>
    <div class="randomizer">
        <router-view />
    </div>
</template>

<style scoped lang="scss"></style>
