var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.cluster'))+" [V2]")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"},on:{"click":_vm.getAllServers}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(servers)",fn:function(ref){
var item = ref.item;
return _vm._l((item.servers),function(server){return _c('b-badge',{key:server.id,staticClass:"mr-1",attrs:{"variant":server['is_main'] ? 'primary' : 'secondary'}},[_vm._v(" "+_vm._s(server.mark.toUpperCase())+" ")])})}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.kube_server') : _vm.$t('add.kube_server'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.key')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.key'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.mark),callback:function ($$v) {_vm.$set(_vm.dataForModal, "mark", $$v)},expression:"dataForModal.mark"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.title'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.server_main')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.server_main'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"clearable":true,"placeholder":_vm.$t('titles.server_main'),"options":_vm.serverList,"reduce":function (option) { return option.id; },"label":"name","multiple":false},on:{"input":_vm.filterServerList},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.mark + ' ' + "[" + (option['queue_trick']) + "]")+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.mark + ' ' + "[" + (option['queue_trick']) + "]")+" ")]}}],null,true),model:{value:(_vm.dataForModal.server_main),callback:function ($$v) {_vm.$set(_vm.dataForModal, "server_main", $$v)},expression:"dataForModal.server_main"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.server_subs')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.server_subs'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"clearable":true,"placeholder":_vm.$t('titles.server_subs'),"options":_vm.filteredServerList,"reduce":function (option) { return option.id; },"label":"name","multiple":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.mark + ' ' + "[" + (option['queue_trick']) + "]")+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.mark + ' ' + "[" + (option['queue_trick']) + "]")+" ")]}}],null,true),model:{value:(_vm.dataForModal.server_subs),callback:function ($$v) {_vm.$set(_vm.dataForModal, "server_subs", $$v)},expression:"dataForModal.server_subs"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }