import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class WalletBalance extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllBalances(body) {
        return this.post('v2/balance/findAll', body);
    }

    fetchOneBalance(body) {
        return this.post('v2/balance/findOne', body);
    }

    updateBalance(body) {
        return this.post('v2/balance/update', body);
    }

    fetchBalanceVisibility() {
        return this.post('v2/balance/config/findOne');
    }

    updateBalanceVisibility(body) {
        return this.post('v2/balance/config/update', body);
    }

    fetchUserWalletBalance(body) {
        return this.post('v2/balance/wallet/findOneById', body);
    }

    fetchTransactions(body) {
        return this.post('v2/balance/transaction/findAll', body);
    }

    credit(body) {
        return this.post('v2/balance/transaction/credit', body);
    }

    debit(body) {
        return this.post('v2/balance/transaction/debit', body);
    }
}

export default WalletBalance;
