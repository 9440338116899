import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Languages extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllLanguages(params) {
        return this.post('languages/findAll', params);
    }

    fetchOneLanguage(params) {
        return this.post('languages/findOne', params);
    }

    createLanguage(body) {
        return this.post('languages', body);
    }

    updateLanguage(body) {
        return this.post('languages/update', body);
    }

    deleteLanguage(body) {
        return this.post('languages/remove', body);
    }
}

export default Languages;
