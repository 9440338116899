var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.roadmap_tasks')))]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLocales(item.name))+" ")]}},{key:"cell(is_published)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:item.is_published ? 'text-success' : 'text-danger',attrs:{"size":"20","icon":item.is_published ? 'CheckIcon' : 'XIcon'}})]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(item && item.image)?_c('img',{staticClass:"table-img",attrs:{"src":item.image.path,"alt":""}}):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'regions', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'regions', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.roadmap_gtw_tasks') : _vm.$t('add.roadmap_gtw_tasks'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" [UZ]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" [RU]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" [EN]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.type')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.type'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.score')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.score'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.score'),"state":errors.length > 0 ? false : null,"type":"number","size":"md"},model:{value:(_vm.dataForModal.scores),callback:function ($$v) {_vm.$set(_vm.dataForModal, "scores", $$v)},expression:"dataForModal.scores"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"w-100 mb-2"},[_c('label',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('titles.image')))]),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"accept":'image/*,.zip',"deletable":true,"errorText":{
                                    type: _vm.$t('vue_file_agent.file_type_error'),
                                    size: _vm.$t('vue_file_agent.size_type_error')
                                },"helpText":_vm.$t('vue_file_agent.choose_image'),"maxFiles":14,"maxSize":'10MB',"meta":true,"multiple":false,"theme":'grid'},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)},"select":function($event){return _vm.filesSelected($event)}},model:{value:(_vm.uploadImage),callback:function ($$v) {_vm.uploadImage=$$v},expression:"uploadImage"}}),_c('b-button',{staticClass:"float-right mt-1",attrs:{"disabled":!_vm.fileRecordsForUpload.length || _vm.imageUploaded,"variant":_vm.imageUploaded ? 'success' : 'primary'},on:{"click":function($event){return _vm.getImageId()}}},[(!_vm.imageUploaded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.upload_image'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.image_uploaded'))+" ")])]),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex mt-2 user-select-none",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"id":"is_published"},model:{value:(_vm.dataForModal.is_published),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_published", $$v)},expression:"dataForModal.is_published"}}),_c('label',{staticClass:"font-medium-1",attrs:{"for":"is_published"}},[_vm._v(" "+_vm._s(_vm.$t('titles.is_published'))+" ")])],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }