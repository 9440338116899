<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import services from '@/services';
import { MERCHANT_ID } from '@/constants/names';
import { isArray, isObject } from '@/util/inspect.util';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import log from 'echarts/src/scale/Log';

export default {
    name: 'AppBanners',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        flatPickr
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                title: {
                    ru: '',
                    uz: '',
                    en: ''
                },
                button_name: {
                    ru: '',
                    uz: '',
                    en: ''
                },
                action_type: null,
                button_value: null,
                button_is_active: false,
                merchant_id: null,
                upload_id: null,
                images: [],
                sales_start: null,
                sales_end: null,
                is_published: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.bannersFindAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.images = [];
            if (isArray(image)) {
                this.dataForModal.images = image;
            } else if (isObject(image)) {
                this.dataForModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.title')
                },
                {
                    key: 'button_name',
                    label: this.$t('titles.button_name')
                },
                {
                    key: 'button_value',
                    label: this.$t('titles.button_value')
                },
                {
                    key: 'action_type',
                    label: this.$t('titles.action_type')
                },
                {
                    key: 'upload',
                    label: this.$t('titles.image')
                },
                {
                    key: 'button_is_active',
                    label: this.$t('titles.button_is_active')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    // created() {
    //     Promise.allSettled([this.gamesFindAll()]);
    // },

    methods: {
        checkLang,

        async bannersFindAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const { data } = await api.banners.fetchAllBanners(body);

            this.items = data.data.list;
            this.pagination = data.data.pagination;

            if (this.pagination.current > this.pagination.totalItem) {
                this.pagination.current = 1;
            }
        },

        async bannersFindOne(item) {
            this.updatingItem = item;
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                id: item.id
            };
            await api.banners
                .fetchOneBanner(body)
                .then((response) => {
                    this.isOpenModal = true;
                    const image = response.data.data.image;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                        // awards: {
                        //     ...response.data.result.awards,
                        //     name: checkLang(response.data.result.awards.name)
                        // },
                        // award_id: response.data.result.awards
                    };
                    this.uploadImage = {
                        id: image.id,
                        name: image.name,
                        size: image.size,
                        type: 'image/jpg',
                        url: image.path,
                        src: image.path
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createBanner(body) {
            await api.banners
                .createBanner(body)
                .then(async () => {
                    await this.bannersFindAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateBanner(body) {
            await api.banners
                .updateBanner(body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.bannersFindAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteBanner(id) {
            const params = {
                merchant_id: parseInt(MERCHANT_ID),
                id: id
            };
            await api.banners
                .deleteBanner(params)
                .then(() => {
                    this.bannersFindAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.upload_id = this.dataForModal.images[0].id;
                    delete body.images;
                    // body.award_id = body.award_id.id;
                    body.merchant_id = parseInt(MERCHANT_ID);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateBanner(body, config);
                        } else {
                            await this.createBanner(body, config);
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getCities();
        },

        // setValue(event) {
        //     console.log(event, 'event');
        // },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        openBannerModal(item) {
            const image = item.upload;
            if (image) {
                this.uploadImage = {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                };
            }

            this.openModalToUpdate(item);
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('banners', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.banners') }}</template>
            <template v-if="hasAccess('cities', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(title)="{ item }">
                    {{ checkLang(item.title) }}
                </template>

                <template #cell(button_name)="{ item }">
                    {{ checkLang(item.button_name) }}
                </template>

                <template #cell(button_is_active)="{ item }">
                    <feather-icon
                        :class="[item.button_is_active ? 'text-success' : 'text-danger']"
                        :icon="item.button_is_active ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(upload)="{ item }">
                    <img
                        class="table-img"
                        v-if="item.image || item.image.path"
                        :src="item.image.path"
                        alt=""
                    />
                    <span v-else>No Photo</span>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'cities', permission: 'delete' }"
                            :delete-handler="deleteBanner"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="bannersFindOne(item)"
                        />
                        <!--                        <b-button-->
                        <!--                            v-b-tooltip.hover.top="`${$t('button.open')}`"-->
                        <!--                            class="btn-icon"-->
                        <!--                            variant="flat-success"-->
                        <!--                            @click="openViewModal(item)"-->
                        <!--                        >-->
                        <!--                            <feather-icon icon="EyeIcon" />-->
                        <!--                        </b-button>-->
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.banner') : $t('add.banner')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE [UZ]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.title.uz"
                                :disabled="isViewModal"
                                :placeholder="`${$t('enter.title')} [UZ]`"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>
                    <!--    TITLE [RU]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [RU] </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.title.ru"
                                :disabled="isViewModal"
                                :placeholder="`${$t('enter.title')} [RU]`"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>
                    <!--    TITLE [EN]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.title.en"
                                :disabled="isViewModal"
                                :placeholder="`${$t('enter.title')} [EN]`"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    BUTTON NAME [UZ]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.button_name') }} [UZ] </label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="`${$t('titles.button_name')} [UZ]`"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.button_name.uz"
                                :disabled="isViewModal"
                                :placeholder="`${$t('enter.button_name')} [UZ]`"
                                size="lg"
                            />
                        </b-form-group>
                        <!--                        :state="errors.length > 0 ? false : null"-->
                        <!--                            <span class="validation__red">-->
                        <!--                                {{ errors[0] }}-->
                        <!--                            </span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--    BUTTON NAME [RU]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.button_name') }} [RU] </label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="`${$t('titles.button_name')} [RU]`"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.button_name.ru"
                                :disabled="isViewModal"
                                :placeholder="`${$t('enter.button_name')} [RU]`"
                                size="lg"
                            />
                        </b-form-group>
                        <!--                        :state="errors.length > 0 ? false : null"-->

                        <!--                            <span class="validation__red">-->
                        <!--                                {{ errors[0] }}-->
                        <!--                            </span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--    BUTTON NAME [EN]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.button_name') }} [EN] </label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="`${$t('titles.button_name')} [EN]`"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.button_name.en"
                                :disabled="isViewModal"
                                :placeholder="`${$t('enter.button_name')} [EN]`"
                                size="lg"
                            />
                        </b-form-group>
                        <!--                        :state="errors.length > 0 ? false : null"-->
                        <!--                            <span class="validation__red">-->
                        <!--                                {{ errors[0] }}-->
                        <!--                            </span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--    ACTION TYPE     -->
                    <b-col cols="12">
                        <label> {{ $t('titles.action_type') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="action_type" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.action_type"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.action_type')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--?     BUTTON VALUE     -->
                    <b-col cols="12">
                        <label> {{ $t('titles.button_value') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="button_value"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.button_value"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.button_value')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--     SALES START     -->
                    <div class="col-12">
                        <label>{{ $t('titles.start_date') }}</label>
                        <flat-pickr
                            v-model="dataForModal.sales_start"
                            :config="{
                                enableTime: true,
                                enableSeconds: true,
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            class="form-control"
                            name="date"
                            id="sales_start"
                            autocomplete="off"
                        />
                    </div>

                    <!--     SALES END     -->
                    <div class="col-12">
                        <label>{{ $t('titles.end_date') }}</label>
                        <flat-pickr
                            v-model="dataForModal.sales_end"
                            :config="{
                                enableTime: true,
                                enableSeconds: true,
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            class="form-control"
                            name="date"
                            id="end_date"
                            autocomplete="off"
                        />
                    </div>

                    <!--?    BUTTON_IS_ACTIVE    -->
                    <b-col
                        cols="12"
                        class="d-flex justify-content-between mt-2 mb-2 user-select-none"
                    >
                        <div class="d-flex">
                            <b-form-checkbox
                                id="button_is_active"
                                v-model="dataForModal.button_is_active"
                                :disabled="isViewModal"
                            />
                            <label for="button_is_active" class="font-medium-1">
                                {{ $t('titles.button_is_active') }}
                            </label>
                        </div>
                        <div class="d-flex">
                            <b-form-checkbox
                                id="is_published"
                                v-model="dataForModal.is_published"
                                :disabled="isViewModal"
                            />
                            <label for="is_published" class="font-medium-1">
                                {{ $t('titles.is_published') }}
                            </label>
                        </div>
                    </b-col>

                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="uploadImage"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_image')"
                                    :maxFiles="14"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    class="float-right mt-1"
                                    @click="getImageId()"
                                >
                                    <span v-if="!imageUploaded">
                                        {{ $t('vue_file_agent.upload_image') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('vue_file_agent.image_uploaded') }}
                                    </span>
                                </b-button>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}
</style>
