<script>
export default {
    name: 'SlideInOutAnimation',
    props: {
        value: Boolean
    },
    mounted() {
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.contentRect.height) {
                    this.$refs.content.parentElement.style.maxHeight =
                        Math.round(entry.contentRect.height) + 'px';
                }
            });
        });

        resizeObserver.observe(this.$refs.content);
    }
};
</script>

<template>
    <transition name="slideInOutAnimation">
        <div v-show="value" class="slideInOutAnimation">
            <div ref="content">
                <slot />
            </div>
        </div>
    </transition>
</template>

<style lang="scss" src="./SlideInOutAnimation.scss" />
