export default [
    {
        path: '/organizations',
        name: 'organizations',
        component: () => import('@/views/questionnaire/Organizations')
    },
    {
        path: '/organizations/:id',
        name: 'organizations-show',
        component: () => import('@/views/questionnaire/Surveys')
    },
    {
        path: '/organizations/:organizationID/surveys/:surveyID',
        name: 'survey-show',
        component: () => import('@/views/questionnaire/Questions')
    },
    {
        path: '/organizations/:organizationID/surveys/:surveyID/question/:questionID',
        name: 'answers',
        component: () => import('@/views/questionnaire/Answers.vue')
    },
    {
        path: '/questionnaire-settings',
        name: 'vote-inputs',
        component: () => import('@/views/questionnaire/QuestionnaireSettings')
    }
];
