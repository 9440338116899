var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.notification_portion')))]},proxy:true},(_vm.hasAccess('news', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(template_id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.template_id.title))+" ")]}},{key:"cell(is_send)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:[item.is_send ? 'text-success' : 'text-danger'],attrs:{"icon":item.is_send ? 'CheckIcon' : 'XIcon',"size":"20"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'news', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'news', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.notification_portion') : _vm.$t('add.notification_portion'),"cancel-variant":"danger","centered":"","size":"lg"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.template')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.template'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"placeholder":_vm.$t('titles.template'),"id":"types","disabled":_vm.isViewModal,"options":_vm.templatesList,"reduce":function (option) { return option.id; },"label":"title","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.title))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.title))+" ")]}}],null,true),model:{value:(_vm.dataForModal.template_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "template_id", $$v)},expression:"dataForModal.template_id"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),(_vm.is_sms)?_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.template'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('titles.template')) + " [SMS]")}},[(_vm.is_sms)?_c('v-select',{attrs:{"placeholder":((_vm.$t('choose.template')) + " [SMS]"),"options":_vm.smsList,"reduce":function (option) { return option.name; },"label":"name","clearable":false},model:{value:(_vm.dataForModal.sms_type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "sms_type", $$v)},expression:"dataForModal.sms_type"}}):_vm._e()],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3320600690)})],1):_vm._e(),_c('div',{staticClass:"mb-1 col-12 col-sm-6"},[_c('h5',[_vm._v(_vm._s(_vm.$t('titles.schedule_at')))]),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                                enableTime: true,
                                allowInput: true,
                                time_24hr: true
                            },"autocomplete":"off","name":"date","placeholder":_vm.$t('choose.date'),"id":"datePicker"},model:{value:(_vm.dataForModal.schedule_at),callback:function ($$v) {_vm.$set(_vm.dataForModal, "schedule_at", $$v)},expression:"dataForModal.schedule_at"}})],1)],1),_c('div',{staticClass:"mb-2 col-12 d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-2",model:{value:(_vm.is_sms),callback:function ($$v) {_vm.is_sms=$$v},expression:"is_sms"}},[_vm._v(" SMS ")])],1),_c('div',{staticClass:"col-12 col-sm-6 d-flex align-items-center"},[(_vm.updatingItem)?_c('b-form-checkbox',{model:{value:(_vm.dataForModal.is_send),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_send", $$v)},expression:"dataForModal.is_send"}},[_vm._v(" "+_vm._s(_vm.$t('titles.sent'))+" ")]):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }