import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class Roadmap extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'randomizer/dashboardGf/' : 'randomizer/dashboardAl/';
    }

    fetchAllSeasons(params) {
        return this.post(this.dashboardVersion() + 'roadMapSeason/getAll', params);
    }

    fetchOneSeason(id) {
        return this.get(this.dashboardVersion() + `roadMapSeason/get/${id}`);
    }

    createSeason(body) {
        return this.post(this.dashboardVersion() + 'roadMapSeason/create', body);
    }

    updateSeason(id, body) {
        return this.post(this.dashboardVersion() + `roadMapSeason/update/${id}`, body);
    }

    deleteSeason(id) {
        return this.delete(this.dashboardVersion() + `roadMapSeason/delete/${id}`);
    }

    fetchAllLevels(params) {
        return this.post(this.dashboardVersion() + 'roadMapLevel/getAll', params);
    }

    fetchOneLevel(id) {
        return this.get(this.dashboardVersion() + `roadMapLevel/get/${id}`);
    }

    createLevel(body) {
        return this.post(this.dashboardVersion() + 'roadMapLevel/create', body);
    }

    updateLevel(id, body) {
        return this.post(this.dashboardVersion() + `roadMapLevel/update/${id}`, body);
    }

    deleteLevel(id) {
        return this.delete(this.dashboardVersion() + `roadMapLevel/delete/${id}`);
    }
}

export default Roadmap;
