import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class FormStatistics extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchFormRegionalStatistics(body) {
        return this.post('profile/regional', body);
    }

    fetchFormGenderStatistics(body) {
        return this.post('profile/gender', body);
    }

    fetchFormAgeStatistics(body) {
        return this.post('profile/age', body);
    }

    fetchFormQuantityStatistics(body) {
        return this.post('profile/qty', body);
    }
}

export default FormStatistics;
