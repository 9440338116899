<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { updateObjectToNew } from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';

export default {
    name: 'AppFeedbackOptions',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                title: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                poll: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            requestPending: false,
            feedbackPolls: []
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.real_title')
                },
                {
                    key: 'poll',
                    label:
                        this.$t('titles.feedback_polls') +
                        ' ' +
                        `[${this.$t('titles.questionnaire')}]`
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getAllFeedbackPolls();
    },

    methods: {
        checkLocales,

        async getAllFeedbackPolls() {
            const body = {
                page: 1,
                limit: 500,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.feedbackPollsApi.fetchAllItems(body);
                this.feedbackPolls = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.feedbackOptionsApi.fetchAllItems(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.feedbackOptionsApi
                .fetchOneItem({ id: item.id, merchant_id: parseInt(MERCHANT_ID) })
                .then((response) => {
                    this.isOpenModal = true;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.feedbackOptionsApi
                .createItem({ ...body, merchant_id: parseInt(MERCHANT_ID) })
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(body) {
            await api.feedbackOptionsApi
                .updateItem(body)
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.feedbackOptionsApi
                .deleteItem({ id: id, merchant_id: parseInt(MERCHANT_ID) })
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.poll_id = body.poll;
                    delete body.poll;
                    delete body.created_at;
                    delete body['update_at'];
                    body.merchant_id = parseInt(MERCHANT_ID);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.poll_id = body.poll_id.id;
                            await this.updateItem({ id: this.updatingItem.id, ...body }, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            delete body.id;
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('titles.feedback_options') }} [{{ $t('titles.questionnaire') }}]
            </template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(title)="{ item }"> {{ checkLocales(item.title) }} </template>

                <template #cell(poll)="{ item }">
                    <span v-if="item.poll">{{ checkLocales(item.poll.title) }}</span>
                    <span v-else></span>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'collector', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'collector', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.feedback_options') : $t('add.feedback_options')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.real_title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.uz"
                                    :placeholder="`${$t('enter.real_title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE [RU]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.real_title') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.ru"
                                    :placeholder="`${$t('enter.real_title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE [EN]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.real_title') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.real_title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.en"
                                    :placeholder="`${$t('enter.real_title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POLL    -->
                    <b-col cols="12" class="mb-1">
                        <label>{{ $t('titles.feedback_polls') }}</label>
                        <b-form-group>
                            <v-select
                                :placeholder="$t('titles.feedback_polls')"
                                :options="feedbackPolls"
                                v-model="dataForModal.poll"
                                :reduce="(item) => item.id"
                                :clearable="true"
                            >
                                <template v-slot:option="option">
                                    {{ checkLocales(option.title) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLocales(option.title) }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-2"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
