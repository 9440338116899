import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class FeedbackOptions extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('feedback/option/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('feedback/option/findOne', body);
    }

    createItem(body) {
        return this.post('feedback/option', body);
    }

    updateItem(body) {
        return this.post('feedback/option/update', body);
    }

    deleteItem(body) {
        return this.post('feedback/option/remove', body);
    }
}

export default FeedbackOptions;
