<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import vSelect from 'vue-select';

export default {
    name: 'AppCoinWarehouse',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            merchantOptions: [],
            measurementOptions: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                images: [],
                images_id: [],
                merchant: null,
                measurement: null,
                qty: null,
                price: null,
                duration: null,
                duration_type: null,
                type: null,
                is_published: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,

            fileRecords: [],
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = parseInt(query.page) || 1;
                this.pagination.perPage = parseInt(query.perPage) || 10;
                this.getWarehouseProducts();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: parseInt(newPage),
                    perPage: parseInt(this.query.perPage) || 10
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        },

        'dataForModal.merchant': {
            async handler(value) {
                if (value && value.id) {
                    await this.getMeasurements(value.id);
                }
            },
            deep: true
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'images',
                    label: this.$t('titles.picture')
                },
                {
                    key: 'name',
                    label: this.$t('titles.title'),
                    formatter: (name) => checkLang(name)
                },
                {
                    key: 'availability',
                    label: this.$t('titles.availability')
                },
                {
                    key: 'category.name',
                    label: this.$t('titles.categories'),
                    formatter: (name) => checkLang(name)
                },
                {
                    key: 'qty_left',
                    label: this.$t('titles.qty_left')
                },
                {
                    key: 'qty_ordered',
                    label: this.$t('titles.qty_ordered')
                },
                // {
                //     key: 'warehouse',
                //     label: this.$t('titles.warehouse'),
                //     formatter: (warehouse) => (warehouse ? checkLang(warehouse?.name || '') : '')
                // },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async created() {
        this.requestPending = true;
        await this.getMerchants();
        this.requestPending = false;
    },

    methods: {
        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getMeasurements(id) {
            const body = {
                method: 'coin.measurement_find_all',
                params: {
                    page: 1,
                    limit: 50,
                    merchant_id: id
                }
            };
            await api.coin
                .fetchCoinMeasurements(body)
                .then((response) => {
                    this.measurementOptions = response.data.result.map((item) => {
                        return {
                            id: item.id,
                            name: this.checkLocales(item.name)
                        };
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getWarehouseProducts() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };
            await api.coin
                .fetchWarehouseProducts(body)
                .then((response) => {
                    this.items = response.data.result;

                    this.pagination = response.data.pagination;
                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async deleteProduct(uuid) {
            const params = {
                method: 'coin.product_remove',
                params: {
                    uuid: uuid
                }
            };
            await api.coin
                .deleteCoinProduct(params)
                .then(() => {
                    this.getProducts();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        replaceRouter(query) {
            this.$router.push({ query }).catch(() => {});
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('navigation.warehouse') }}</template>
        </page-title>

        <div class="d-flex align-items-center justify-content-end mb-2">
            <div class="d-flex align-items-center">
                Per page:
                <v-select
                    id="jamshid"
                    v-model="pagination.perPage"
                    :options="perPageList"
                    class="ml-2"
                    style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                />
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item, index }">
                    {{ index + 1 }}
                </template>

                <template #cell(images)="{ item }">
                    <div class="table-img">
                        <img
                            v-if="item.images && item.images.length > 0"
                            :src="item.images[0].path"
                        />
                        <img v-else src="../../assets/images/icons/no-photo.png" />
                    </div>
                </template>

                <template #cell(type)="{ item }">
                    {{ $t(`titles.${item.type}`) }}
                </template>

                <template #cell(availability)="{ item }">
                    {{ $t(`warehouse_statuses.${item.availability}`) }}
                </template>

                <template #cell(duration_type)="{ item }">
                    {{ $t(`titles.${item.duration_type}`) }}
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="['stroke-current', generateIconColorToBoolean(item.is_published)]"
                        :icon="generateIconToBoolean(item.is_published)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :delete-access="{ section: 'coin_product', permission: 'delete' }"
                        :delete-handler="deleteProduct"
                        :index="index"
                        :item="item"
                        :update-access="{ section: 'coin_product', permission: 'update' }"
                        @openModal="
                            $router.push({
                                name: 'coin-product-show',
                                params: { id: item.uuid, page: 'warehouse' }
                            })
                        "
                    />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
