import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class USSDMenu extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/ussd/mapper/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('v2/ussd/mapper/findOne', body);
    }

    createItem(body) {
        return this.post('v2/ussd/mapper', body);
    }

    updateItem(id, body) {
        return this.post(`v2/ussd/mapper/update/${id}`, body);
    }

    deleteItem(body) {
        return this.post('v2/ussd/mapper/remove', body);
    }

    swapItem(body) {
        return this.post('v2/ussd/mapper/swap', body);
    }
}

export default USSDMenu;
