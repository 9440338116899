export default [
    {
        path: '/avatars',
        name: 'avatars',
        component: () => import('@/views/avatar/AppAvatarsView.vue')
    },
    {
        path: '/avatars/:id',
        name: 'avatar-show',
        component: () => import('@/views/avatar/AvatarShow.vue')
    }
];
