<script>
import { BButton, BCol, BRow, BSpinner } from 'bootstrap-vue';

export default {
    name: 'FilterBlock',
    components: { BButton, BRow, BCol, BSpinner },
    props: {
        filter: Object,
        loading: Boolean,
        showButtons: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        filtrate(isReset = false) {
            try {
                let isExist = false;
                if (this.filter) {
                    Object.keys(this.filter).forEach((key) => {
                        if (key !== 'page') {
                            if (this.filter[key]) {
                                isExist = true;
                                if (isReset) {
                                    delete this.filter[key];
                                }
                            }
                        }
                    });

                    if (isExist) {
                        this.filter.page = 1;
                        // this.filter.limit = 10;
                        this.$emit('update:filter', this.filter);
                        this.$emit('submit');
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<template>
    <div class="filter--sticky">
        <div class="card w-100 m-0">
            <b-row class="p-2">
                <slot />
                <b-col cols="12">
                    <div
                        v-if="showButtons"
                        class="d-flex flex-wrap justify-content-end align-items-end"
                    >
                        <b-button class="ml-auto" @click="filtrate(true)">Сбросить</b-button>
                        <b-button
                            :disabled="loading"
                            class="ml-1 mt-1 position-relative"
                            style="height: 38px"
                            variant="success"
                            @click="filtrate(false)"
                        >
                            <div
                                v-if="loading"
                                class="position-absolute"
                                style="left: 50%; transform: translateX(-50%)"
                            >
                                <b-spinner small />
                            </div>
                            <span :style="{ opacity: loading ? '.1' : '1' }">
                                {{ $t('button.filter') }}
                            </span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style scoped></style>
