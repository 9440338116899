<script>
import FlatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { BFormGroup } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CompStackedChart from '@/components/CompStackedChart.vue';

export default {
    name: 'AppBonusStatistics',
    components: {
        CompStackedChart,
        ProgressLinear,
        BFormGroup,
        FlatPickr,
        vSelect
    },
    data() {
        return {
            staticFilterSelect: [
                {
                    label: 'All',
                    value: 'all'
                },
                {
                    label: 'Active',
                    value: 'active'
                },
                {
                    label: 'Inactive',
                    value: 'inactive'
                }
            ],
            filter: {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: '',
                date_end: '',
                status: 'all'
            },
            generalStatistics: {
                isPending: true,
                categories: [],
                series: []
            },
            typeStatistics: {
                isPending: true,
                categories: [],
                series: []
            }
        };
    },
    async mounted() {
        await this.fixMonthlyDates();
    },
    methods: {
        async getBonusStatistics() {
            this.generalStatistics.isPending = true;
            this.generalStatistics.series.length = 0;
            this.generalStatistics.categories.length = 0;
            try {
                const { data } = await api.bonusStatisticsApi.fetchBonusGeneralStatistics(
                    this.filter
                );
                const response = data.data;
                this.generalStatistics.categories = data.data.map((item) => item.date.substring(5));

                response.forEach((entry) => {
                    entry.services.forEach((item) => {
                        const existingSeries = this.generalStatistics.series.find(
                            (s) => s.name === item.name
                        );
                        if (existingSeries) {
                            existingSeries.data.push(item.qty);
                        } else {
                            this.generalStatistics.series.push({
                                name: item.name,
                                data: [item.qty]
                            });
                        }
                    });
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.generalStatistics.isPending = false;
            }
        },

        async getTypeStatistics() {
            this.typeStatistics.isPending = true;
            this.typeStatistics.series.length = 0;
            this.typeStatistics.categories.length = 0;
            try {
                const { data } = await api.bonusStatisticsApi.fetchBonusStatistics(this.filter);
                const response = data.data;
                this.typeStatistics.categories = data.data.map((item) => item.date.substring(5));

                const seriesData = {};

                response.forEach((entry) => {
                    const info = entry.info;

                    for (const key in info) {
                        if (info.hasOwnProperty(key)) {
                            if (!seriesData[key]) {
                                seriesData[key] = [];
                            }
                            seriesData[key].push(info[key]);
                        }
                    }

                    for (const key in seriesData) {
                        if (!info.hasOwnProperty(key)) {
                            seriesData[key].push(0);
                        }
                    }
                });

                this.typeStatistics.series = Object.keys(seriesData).map((key) => ({
                    name: key,
                    data: seriesData[key]
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.typeStatistics.isPending = false;
            }
        },

        async getAllRequests() {
            this.generalStatistics.isPending = true;
            this.typeStatistics.isPending = true;
            await this.getBonusStatistics();
            await this.getTypeStatistics();
        },

        async fixMonthlyDates() {
            const date = new Date();
            const year = date.getUTCFullYear(); // Get the full year (4 digits)
            const beforeMonth = String(date.getMonth()).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.filter.date_start = `${year}-${beforeMonth}-${day}`;
            this.filter.date_end = `${year}-${currentMonth}-${day}`;
            await this.getAllRequests();
        }
    }
};
</script>

<template>
    <div class="bonus-statistics">
        <div class="bonus-statistics__filter">
            <div class="row align-items-end">
                <div class="col-12 col-sm-6 col-md-3">
                    <label>{{ $t('titles.start_date') }}</label>
                    <b-form-group>
                        <flat-pickr
                            v-model="filter.date_start"
                            :config="{
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            autocomplete="off"
                            class="mb-1 form-control"
                            name="date"
                        />
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <label>{{ $t('titles.end_date') }}</label>
                    <b-form-group>
                        <flat-pickr
                            v-model="filter.date_end"
                            :config="{
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            autocomplete="off"
                            class="mb-1 form-control"
                            name="date"
                        />
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-8 col-md-3 col-lg-5 mb-1">
                    <b-form-group :label="$t('titles.status')" label-for="status">
                        <v-select
                            id="status"
                            v-model="filter.status"
                            :clearable="false"
                            :options="staticFilterSelect"
                            :placeholder="$t('choose.status')"
                            :reduce="(option) => option.value"
                            label="label"
                        />
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-lg-1 mb-1">
                    <b-button
                        @click="getAllRequests"
                        variant="outline-primary"
                        size="sm"
                        class="w-100 px-0"
                    >
                        <feather-icon icon="SearchIcon" size="22" />
                    </b-button>
                </div>
            </div>
        </div>
        <div class="bonus-statistics__statistics">
            <!--      BONUS STATISTICS GENERAL      -->
            <div class="col-12 px-0">
                <div class="card">
                    <progress-linear v-if="generalStatistics.isPending" />
                    <CompStackedChart
                        v-else
                        :is-pending="generalStatistics.isPending"
                        :series="generalStatistics.series"
                        :categories="generalStatistics.categories"
                        :title="$t('titles.general_bonus_statistics')"
                    />
                </div>
            </div>

            <!--      BONUS STATISTICS ORDINARY      -->
            <div class="col-12 px-0">
                <div class="card">
                    <progress-linear v-if="typeStatistics.isPending" />
                    <CompStackedChart
                        v-else
                        :is-pending="typeStatistics.isPending"
                        :series="typeStatistics.series"
                        :categories="typeStatistics.categories"
                        :title="$t('titles.general_bonus_statistics')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
