import Core from '@/services/core';
import { axiosInstance } from '@/services/core/base';

class Levels extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }

    // GIFTS

    getLevelGifts() {
        return this.customGet(`${this.url}/gifts`);
    }

    createLevelGift(levelID, body, config) {
        return this.customPost(`${this.url}/${levelID}/gifts`, body, config);
    }

    updateLevelGift(levelID, giftID, body, config) {
        return this.updateByPost(`${levelID}/gifts/${giftID}`, body, config);
    }
    fetchOneLevelGift(id) {
        return this.get(`${this.url}/gifts/${id}`);
    }

    deleteLevelGift(levelID, giftID) {
        return this.customDelete(`${this.url}/${levelID}/gifts/${giftID}`);
    }

    updateLevelGiftProbabilityApi(levelID, giftID, body) {
        return this.customPut(`${this.url}/${levelID}/gifts/${giftID}/probability`, body);
    }

    // SCORES

    getLevelScores() {
        return this.customGet(`${this.url}/scores`);
    }

    createLevelScore(levelID, body, config) {
        return this.customPost(`${this.url}/${levelID}/score`, body, config);
    }

    updateLevelScore(levelID, giftID, body, config) {
        return this.customPut(`${this.url}/${levelID}/score/${giftID}`, body, config);
    }

    deleteLevelScore(levelID, giftID) {
        return this.customDelete(`${this.url}/${levelID}/score/${giftID}`);
    }

    // Winners

    getLevelWinners(params) {
        return this.customGet('/winners', params);
    }
}

export default Levels;
