import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class StatisticsV2 extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchProducts(body) {
        return this.post('statistics/charges/products', body);
    }

    fetchTotalCount(body) {
        return this.post('statistics/charges/total', body);
    }

    fetchDaily(body) {
        return this.post('statistics/charges/daily', body);
    }

    fetchDailyProduct(body) {
        return this.post('statistics/charges/daily-product', body);
    }
}

export default StatisticsV2;
