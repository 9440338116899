<script>
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import NewLoggerTimeLine from '@/components/NewLoggerTimeLine.vue';

export default {
    name: 'SSRoadmapProductHistory',
    components: { NewLoggerTimeLine },
    data() {
        return {
            items: [],
            pagination: {},
            requestPending: false,
            filter: {
                page: 1
            }
        };
    },
    computed: {
        staticProductTypeList() {
            return [
                {
                    name: this.$t('titles.package'),
                    value: 0
                },
                {
                    name: 'Coin',
                    value: 1
                },
                {
                    name: this.$t('titles.ticket'),
                    value: 2
                },
                {
                    name: 'Paynet',
                    value: 3
                },
                {
                    name: this.$t('titles.level'),
                    value: 4
                },
                {
                    name: this.$t('titles.prize'),
                    value: 5
                },
                {
                    name: this.$t('titles.card'),
                    value: 6
                },
                {
                    name: this.$t('titles.avatar'),
                    value: 7
                }
            ];
        }
    },
    async mounted() {
        await this.getRoadmapProductHistory();
    },
    methods: {
        checkLocales,
        async getRoadmapProductHistory() {
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.$route.params.id
            });
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchProductHistory(this.filter);
                if (data.data.pagination.current === 1) {
                    this.items = data.data.list;
                } else {
                    this.items = [...this.items, ...data.data.list];
                }
                this.pagination = data.data.pagination;
                this.requestPending = false;
            } catch (e) {
                console.error(e);
            }
        },

        loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            this.getRoadmapProductHistory();
        },

        filterProductType(product) {
            return this.staticProductTypeList.filter((item) => product === item.value);
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <new-logger-time-line
                v-model:loading="requestPending"
                :items="items"
                :pagination="pagination"
                @loadNextPage="loadNextPage"
            >
                <template #default="{ item }">
                    <div class="activity-item d-flex justify-content-between">
                        <div class="activity-item__content">
                            <div
                                class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center"
                            >
                                <div class="mb-1">
                                    <span class="text-info font-weight-bold font-medium-2">
                                        {{ checkLocales(item['season'].name) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <var class="text-uppercase">{{ $t('titles.level') }} : </var>
                                <span class="font-medium-1">{{ item['level'].number }}</span>
                            </div>
                            <div>
                                <var class="text-uppercase">{{ $t('titles.product') }} : </var>
                                <span class="font-medium-1">
                                    <b-badge variant="primary">
                                        <strong v-if="item['product_name']">{{
                                            checkLocales(item['product_name'])
                                        }}</strong>
                                        <strong v-else>null</strong>
                                    </b-badge>
                                </span>
                            </div>
                            <div>
                                <var class="text-uppercase">{{ $t('titles.product_type') }} : </var>
                                <span class="font-medium-1">
                                    <strong>{{
                                        filterProductType(item['product_type'])[0].name
                                    }}</strong>
                                </span>
                            </div>
                            <div>
                                <var class="text-uppercase"
                                    >{{ $t('titles.product_value') }} :
                                </var>
                                <span class="font-medium-1">
                                    <strong>{{ item['product_value'] }}</strong>
                                </span>
                            </div>
                        </div>
                        <div>
                            <small class="text-right created__at">
                                {{ item['created_at'] }}
                            </small>
                        </div>
                    </div>
                </template>
            </new-logger-time-line>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
