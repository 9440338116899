import Core from '@/services/core';
import { axiosInstanceV2 } from '@/services/core/base';

export class VoteOrganizations extends Core {
    constructor(url) {
        super(axiosInstanceV2, url);
    }

    getOrganizationsApi() {
        return this.get();
    }

    getSingleOrganizationApi(id) {
        return this.getSingle(id);
    }

    createOrganizationApi(body) {
        return this.post(body);
    }

    deleteOrganizationApi(id) {
        return this.delete(id);
    }

    updateOrganizationApi(id, body) {
        return this.put(id, body);
    }
}

export class Surveys extends Core {
    constructor() {
        super(axiosInstanceV2);
    }

    generateUrl(organizationID, surveyID) {
        if (surveyID) return `vote/organizations/${organizationID}/surveys/${surveyID}`;
        return `vote/organizations/${organizationID}/surveys`;
    }

    getSurveysApi(organizationID) {
        return this.customGet(this.generateUrl(organizationID));
    }

    getSingleSurveyApi(organizationID, surveyID) {
        return this.customGet(this.generateUrl(organizationID, surveyID));
    }

    createSurveyApi(organizationID, payload) {
        return this.customPost(this.generateUrl(organizationID), payload);
    }

    updateSurveyApi(organizationID, surveyID, payload) {
        return this.customPut(this.generateUrl(organizationID, surveyID), payload);
    }

    deleteSurveyApi(organizationID, surveyID) {
        return this.customDelete(this.generateUrl(organizationID, surveyID));
    }
}

export class Questions extends Core {
    constructor() {
        super(axiosInstanceV2);
    }

    generateUrl(organizationID, surveyID, questionID = null, answerID) {
        if (answerID && questionID)
            return `/vote/organizations/${organizationID}/surveys/${surveyID}/questions/${questionID}/answers/${answerID}`;
        if (questionID)
            return `vote/organizations/${organizationID}/surveys/${surveyID}/questions/${questionID}`;
        return `/vote/organizations/${organizationID}/surveys/${surveyID}/questions`;
    }

    getAllQuestionsApi(organizationID, surveyID) {
        return this.customGet(this.generateUrl(organizationID, surveyID));
    }

    getQuestion(organizationID, surveyID, questionID) {
        return this.customGet(this.generateUrl(organizationID, surveyID, questionID));
    }

    createQuestionApi(organizationID, surveyID, payload) {
        return this.customPost(this.generateUrl(organizationID, surveyID), payload);
    }

    updateQuestionApi(organizationID, surveyID, questionID, payload) {
        return this.customPut(this.generateUrl(organizationID, surveyID, questionID), payload);
    }

    deleteQuestionApi(organizationID, surveyID, questionID) {
        return this.customDelete(this.generateUrl(organizationID, surveyID, questionID));
    }

    deleteAnswerApi(organizationID, surveyID, questionID, answerID) {
        return this.customDelete(this.generateUrl(organizationID, surveyID, questionID, answerID));
    }
}

export class VoteAnswers extends Core {
    constructor() {
        super(axiosInstanceV2);
    }

    generateAnswerUrl(organizationID, surveyID, questionID, answerID) {
        if (answerID)
            return `/vote/organizations/${organizationID}/surveys/${surveyID}/questions/${questionID}/answers/${answerID}`;
        return `/vote/organizations/${organizationID}/surveys/${surveyID}/questions/${questionID}/answers`;
    }

    getVoteAnswers(organizationID, surveyID, questionID) {
        return this.customGet(this.generateAnswerUrl(organizationID, surveyID, questionID));
    }

    getVoteAnswer(organizationID, surveyID, questionID, id) {
        return this.customGet(this.generateAnswerUrl(organizationID, surveyID, questionID, id));
    }

    createVoteAnswer(organizationID, surveyID, questionID, body) {
        return this.customPost(this.generateAnswerUrl(organizationID, surveyID, questionID), body);
    }

    deleteVoteAnswer(organizationID, surveyID, questionID, id) {
        return this.customDelete(this.generateAnswerUrl(organizationID, surveyID, questionID, id));
    }

    updateVoteAnswer(organizationID, surveyID, questionID, id, body) {
        return this.customPut(
            this.generateAnswerUrl(organizationID, surveyID, questionID, id),
            body
        );
    }
}

export class InputTypes extends Core {
    constructor(url) {
        super(axiosInstanceV2);
        this.url = url;
    }

    getApi(query) {
        return this.get(query);
    }

    getInputType(id) {
        return this.customGet(this.url + '/' + id);
    }

    createApi(payload) {
        return this.post(payload);
    }

    updateApi(id, payload) {
        return this.put(id, payload);
    }

    deleteApi(id) {
        return this.delete(id);
    }
}
