export default [
    {
        path: '/tournament-tournaments',
        name: 'tournament-tournaments',
        component: () => import('@/views/tournament/tournaments/AppTournaments.vue')
    },
    {
        path: '/tournament-tournaments/:id?',
        name: 'tournament-tournaments-show',
        component: () => import('@/views/tournament/tournaments/AppTournamentCRUD.vue')
    },
    {
        path: '/tournament/tasks',
        name: 'tasks',
        component: () => import('@/views/tournament/tasks/AppTasks.vue')
    },
    {
        path: '/tournament-all-tasks/:id?',
        name: 'tournament-all-tasks',
        component: () => import('@/views/tournament/tournament-tasks/TournamentAllTasks.vue')
    },
    {
        path: '/tournament-tasks/:id?',
        name: 'tournament-tasks',
        component: () => import('@/views/tournament/tournament-tasks/AppTournamentTasks.vue')
    },
    {
        path: '/tournament-banners',
        name: 'tournament-banners',
        component: () => import('@/views/tournament/banners/AppTournamentBanners.vue')
    },
    {
        path: '/tournament/products',
        component: () => import('@/views/tournament/product/index.vue'),
        children: [
            {
                name: 'tournament-product',
                path: '/tournament/products/product',
                component: () => import('@/views/tournament/product/AppTournamentProduct.vue')
            },
            {
                name: 'tournament-product-bonus',
                path: '/tournament/products/bonus',
                component: () => import('@/views/tournament/product/AppTournamentBonus.vue')
            }
        ]
    },
    {
        path: '/tournament/product-show/:id?',
        name: 'tournament-product-show',
        component: () => import('@/views/tournament/product/MarketProduct.vue')
    },
    {
        path: '/tournament/bonus-show/:id?',
        name: 'tournament-bonus-show',
        component: () => import('@/views/tournament/product/LevelProduct.vue')
    },
    {
        path: '/tournament/policy',
        name: 'tournament-policy',
        component: () => import('@/views/tournament/tournament-policy/TournamentPolicyIndex.vue')
    },
    {
        path: '/tournament/policy-show/:id?',
        name: 'tournament-policy-show',
        component: () => import('@/views/tournament/tournament-policy/TournamentPolicyCRUD.vue')
    },
    {
        path: '/tournament/statistics',
        name: 'tournament-statistics',
        component: () => import('@/views/tournament/statistics/AppTournamentStatistics.vue')
    },
    {
        path: '/tournament/groups',
        name: 'tournament-groups',
        component: () => import('@/views/tournament/groups/GroupsView.vue')
    },
    {
        path: '/tournament/tournaments',
        name: 'tournament-list',
        component: () => import('@/views/tournament/winners/TournamentList.vue')
    },
    {
        path: '/tournament/tournaments/:tournament_id/winners',
        name: 'tournament-winners',
        component: () => import('@/views/tournament/winners/TournamentWinners.vue')
    },
    {
        path: '/tournament/g',
        name: 'tournament-groups-test',
        component: () => import('@/views/tournament/groups/GroupsView.vue')
    }
];
