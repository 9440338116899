// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class InfinityGroups extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'randomizer/dashboardGf/' : 'randomizer/dashboardAl/';
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'infinityGroup/getAll', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `infinityGroup/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'infinityGroup/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `infinityGroup/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `infinityGroup/delete/${id}`);
    }

    updateItemPercentage(id, body) {
        return this.post(this.dashboardVersion() + `infinityGroup/procUpdate/${id}`, body);
    }

    updateItemStatus(id, body) {
        return this.post(this.dashboardVersion() + `infinityGroup/status/${id}`, body);
    }
}

export default InfinityGroups;
