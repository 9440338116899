import AppRoadmapSeason from '@/views/roadmap/season/AppRoadmapSeason.vue';
import AppRoadmapTask from '@/views/roadmap/task/AppRoadmapTask.vue';
import AppRoadmapProductHistory from '@/views/roadmap/roadmap-product-history/AppRoadmapProductHistory.vue';
import AppRoadmapAvatars from '@/views/roadmap/avatars/AppRoadmapAvatars.vue';

export default [
    {
        path: '/roadmap-season',
        name: 'roadmap-season',
        component: AppRoadmapSeason,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/roadmap-task',
        name: 'roadmap-task',
        component: AppRoadmapTask,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/roadmap-product-history',
        name: 'roadmap-product-history',
        component: AppRoadmapProductHistory,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/roadmap-avatars',
        name: 'roadmap-avatars',
        component: AppRoadmapAvatars,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
