import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class BonusStatistics extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchBonusGeneralStatistics(body) {
        return this.post('v2/bonus/statistic/general', body);
    }

    fetchBonusStatistics(body) {
        return this.post('v2/bonus/statistic', body);
    }
}

export default BonusStatistics;
