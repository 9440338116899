var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.tournaments'))+" ")]},proxy:true},(_vm.hasAccess('gifts', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"b-table--less-padding",attrs:{"striped":"","borderless":"","outlined":"","responsive":true,"fields":_vm.tableHeader,"items":_vm.gifts},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name[_vm.localLang])+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (item.type))))+" ")]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price ? item.price : 0)+" "+_vm._s(_vm.$t('sum'))+" ")]}},{key:"cell(published)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:['stroke-current', _vm.generateIconColorToBoolean(item.published)],attrs:{"icon":_vm.generateIconToBoolean(item.published)}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteGift,"delete-access":{ section: 'gifts', permission: 'delete' },"update-access":{ section: 'gifts', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","cancel-variant":"danger","centered":"","hide-footer":false,"no-close-on-backdrop":false,"size":"lg","title":_vm.$t('add.gift')},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(!_vm.updatingItem)?_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(_vm.createOneMore),callback:function ($$v) {_vm.createOneMore=$$v},expression:"createOneMore"}},[_vm._v(" "+_vm._s(_vm.$t('titles.add_more'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" (uz)")]),_c('ValidationProvider',{attrs:{"name":"'Название (uz)'","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","placeholder":((_vm.$t('enter.title')) + " (uz)"),"state":errors.length > 0 ? false : null},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" (ru)")]),_c('ValidationProvider',{attrs:{"name":"'Название (ru)'","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","placeholder":((_vm.$t('enter.title')) + " (ru)"),"state":errors.length > 0 ? false : null},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" (en)")]),_c('ValidationProvider',{attrs:{"name":"'Название (en)'","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","placeholder":((_vm.$t('enter.title')) + " (en)"),"state":errors.length > 0 ? false : null},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.package'))+" ID")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.package')) + " ID"),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":("" + (_vm.$t('enter.packageID')))},model:{value:(_vm.dataForModal.package_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "package_id", $$v)},expression:"dataForModal.package_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.types'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.types'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"placeholder":_vm.$t('choose.type'),"options":_vm.offerTypes,"searchable":false,"reduce":function (type) { return type.key; }},model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.values'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.values'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.values')},model:{value:(_vm.dataForModal.values),callback:function ($$v) {_vm.$set(_vm.dataForModal, "values", _vm._n($$v))},expression:"dataForModal.values"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.price'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.price'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.price')},model:{value:(_vm.dataForModal.price),callback:function ($$v) {_vm.$set(_vm.dataForModal, "price", _vm._n($$v))},expression:"dataForModal.price"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.sticker'))+" ID ")]),_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","placeholder":("" + (_vm.$t('enter.stickerID')))},model:{value:(_vm.dataForModal.sticker_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "sticker_id", $$v)},expression:"dataForModal.sticker_id"}})],1)],1)],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6","lg":"3"}},[_c('b-form-checkbox',{model:{value:(_vm.dataForModal.premium),callback:function ($$v) {_vm.$set(_vm.dataForModal, "premium", $$v)},expression:"dataForModal.premium"}},[_vm._v(" "+_vm._s(_vm.$t('titles.premium'))+" ")])],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6","lg":"3"}},[_c('b-form-checkbox',{model:{value:(_vm.dataForModal.first_prize),callback:function ($$v) {_vm.$set(_vm.dataForModal, "first_prize", $$v)},expression:"dataForModal.first_prize"}},[_vm._v(" "+_vm._s(_vm.$t('titles.firstPrize'))+" ")])],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6","lg":"3"}},[_c('b-form-checkbox',{model:{value:(_vm.dataForModal.super_prize),callback:function ($$v) {_vm.$set(_vm.dataForModal, "super_prize", $$v)},expression:"dataForModal.super_prize"}},[_vm._v(" "+_vm._s(_vm.$t('titles.superPrize'))+" ")])],1),_c('b-col',{attrs:{"md":"6","lg":"3"}},[_c('b-form-checkbox',{model:{value:(_vm.dataForModal.published),callback:function ($$v) {_vm.$set(_vm.dataForModal, "published", $$v)},expression:"dataForModal.published"}},[_vm._v(" "+_vm._s(_vm.$t('titles.published'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }