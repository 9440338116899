import { axiosOriginal } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class DealerConstants extends CoreV2 {
    constructor() {
        super(axiosOriginal());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'v1/dashboardGf/' : 'v1/dashboardAl/';
    }

    fetchAllItems() {
        return this.get(this.dashboardVersion() + 'constant/getAll');
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `constant/get/${id}`);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `constant/update/${id}`, body);
    }
}

export default DealerConstants;
