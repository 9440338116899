var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.purchase_methods'))+" [VIP]")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLocales(item.name))+" ")]}},{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:[item.is_active ? 'text-success' : 'text-danger'],attrs:{"icon":item.is_active ? 'CheckIcon' : 'XIcon',"size":"20"}})]}},{key:"cell(activation_id)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.activation_id.color}},[_vm._v(_vm._s(item.activation_id.text)+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.purchase') : _vm.$t('add.purchases'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.type')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"id":"types","disabled":_vm.isViewModal,"options":_vm.staticTypes,"placeholder":_vm.$t('choose.type'),"reduce":function (option) { return option.id; },"label":"label"},on:{"input":_vm.resetActivation},model:{value:(_vm.dataForModal.types),callback:function ($$v) {_vm.$set(_vm.dataForModal, "types", $$v)},expression:"dataForModal.types"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.payment_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.checkLabels,"label-for":"activation_id"}},[(_vm.dataForModal.types === 1)?_c('v-select',{attrs:{"id":"activation_id","disabled":_vm.isViewModal,"options":_vm.chargesList,"placeholder":_vm.$t('choose.charges'),"reduce":function (option) { return option.id; },"label":"name"},model:{value:(_vm.dataForModal.activation_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "activation_id", $$v)},expression:"dataForModal.activation_id"}}):(_vm.dataForModal.types === 2)?_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.qty_coins'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.activation_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "activation_id", $$v)},expression:"dataForModal.activation_id"}}):_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.qty_tickets'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.activation_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "activation_id", $$v)},expression:"dataForModal.activation_id"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.no_discount_price')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.no_discount_price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.no_discount_price'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.price_value),callback:function ($$v) {_vm.$set(_vm.dataForModal, "price_value", $$v)},expression:"dataForModal.price_value"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.qty')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.qty'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.qty'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.item_qty),callback:function ($$v) {_vm.$set(_vm.dataForModal, "item_qty", $$v)},expression:"dataForModal.item_qty"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.order_number')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.order_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.order_number'),"state":errors.length > 0 ? false : null,"size":"lg","type":"number"},model:{value:(_vm.dataForModal.sort),callback:function ($$v) {_vm.$set(_vm.dataForModal, "sort", $$v)},expression:"dataForModal.sort"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('label',{staticClass:"mr-1",attrs:{"for":"is_active"}},[_vm._v(_vm._s(_vm.$t('titles.is_active')))]),_c('b-form-checkbox',{attrs:{"id":"is_active"},model:{value:(_vm.dataForModal.is_active),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_active", $$v)},expression:"dataForModal.is_active"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }