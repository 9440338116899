import SubscribersActivity from '@/views/subscribers/SubscribersActivities';
import SubscribersIndex from '@/views/subscribers/SubscribersIndex';
import SubscribersSingle from '@/views/subscribers/SubscribersSingle';
import SubscribersSingleActivities from '@/views/subscribers/SubscribersSingle/SubscribersSingleActivities';
import SubscribersSingleGifts from '@/views/subscribers/SubscribersSingle/SubscribersSingleGifts';
import SubscribersSingleTransactions from '@/views/subscribers/SubscribersSingle/SubscribersSingleTransactions';
import SubscribersSingleManualPackets from '@/views/subscribers/SubscribersSingle/SubscribersSingleManualPackets';
import SubscribersSingleWins from '@/views/subscribers/SubscribersSingle/SubscribersSingleWins';
import SubscribersSingleManualPacketsAsSubscriber from '@/views/subscribers/SubscribersSingle/SubscribersSingleActivatePacketAsSubscriber';
import SubscribersSingleCoinMonitoring from '@/views/subscribers/SubscribersSingle/SubscribersSingleCoinMonitoring.vue';
import SubscribersSingleOrderHistory from '@/views/subscribers/SubscribersSingle/SubscribersSingleOrderHistory.vue';
import SubscribersSingleTaskProgress from '@/views/subscribers/SubscribersSingle/SubscribersSingleTaskProgress.vue';
import SubscriberGameMonitoring from '@/views/subscribers/SubscribersSingle/SubscriberGameMonitoring.vue';
import SubscribersNewLogger from '@/views/subscribers/SubscribersSingle/SubscribersNewLogger.vue';
import NewSubscribersActivities from '@/views/subscribers/NewSubscribersActivities.vue';
import SubscribersSingleLevelActivities from '@/views/subscribers/SubscribersSingle/SubscribersSingleLevelActivities.vue';
import SubscribersSingleWalletTransactions from '@/views/subscribers/SubscribersSingle/SubscribersSingleWalletTransactions.vue';
import SubscribersSingleRoadmap from '@/views/subscribers/SubscribersSingle/SubscribersSingleRoadmap.vue';
import SSRoadmapInfo from '@/views/subscribers/SubscribersSingle/roadmap-components/SSRoadmapInfo.vue';
import SSRoadmapScore from '@/views/subscribers/SubscribersSingle/roadmap-components/SSRoadmapScore.vue';
import SSRoadmapProductHistory from '@/views/subscribers/SubscribersSingle/roadmap-components/SSRoadmapProductHistory.vue';
import SubscriberStreak from '@/views/subscribers/SubscribersSingle/SubscriberStreak.vue';

export default [
    {
        path: '/subscribers-activities',
        name: 'subscribers Activities',
        component: SubscribersActivity,
        meta: {
            section: 'subscribers_activities',
            permission: 'view'
        }
    },
    {
        path: '/new-subscribers-activities',
        name: 'new-subscribers-activities',
        component: NewSubscribersActivities,
        meta: {
            section: 'subscribers_activities',
            permission: 'view'
        }
    },
    {
        path: '/subscribers',
        name: 'subscribers Index',
        component: SubscribersIndex,
        meta: {
            section: 'subscribers',
            permission: 'view'
        }
    },
    {
        path: '/subscribers/:id',
        name: 'subscribers Single',
        component: SubscribersSingle,
        meta: {
            navActiveLink: 'subscribers Index',
            section: 'subscribers',
            permission: 'view'
        },
        children: [
            {
                path: ':id/activities',
                name: 'subscribers Single Activities',
                component: SubscribersSingleActivities
            },
            {
                path: ':id/tournaments',
                name: 'subscribers Single Gifts',
                component: SubscribersSingleGifts
            },
            {
                path: ':id/transactions',
                name: 'subscribers Single Transactions',
                component: SubscribersSingleTransactions
            },
            {
                path: ':id/manual-packets',
                name: 'subscribers Single Manual Packets',
                component: SubscribersSingleManualPackets,
                meta: {
                    section: 'activate_pack',
                    permission: 'activate'
                }
            },
            {
                path: ':id/activate-packet-as-subscriber',
                name: 'subscribers Single Activate Packet As Subscriber',
                component: SubscribersSingleManualPacketsAsSubscriber,
                meta: {
                    section: 'activate_pack',
                    permission: 'activate'
                }
            },
            {
                path: ':id/wins',
                name: 'subscribers Single Wins',
                component: SubscribersSingleWins
            },
            {
                path: ':id/coin-monitoring',
                name: 'subscriber-coin-monitoring',
                component: SubscribersSingleCoinMonitoring
            },
            {
                path: ':id/coin-orders',
                name: 'subscriber-coin-orders',
                component: SubscribersSingleOrderHistory
            },
            {
                path: ':id/task-progress',
                name: 'subscriber-task-progress',
                component: SubscribersSingleTaskProgress
            },
            {
                path: ':id/game-monitoring',
                name: 'subscriber-game-monitoring',
                component: SubscriberGameMonitoring
            },
            {
                path: ':id/new-logger',
                name: 'subscriber-new-logger',
                component: SubscribersNewLogger
            },
            {
                path: ':id/streak',
                name: 'subscriber-streak',
                component: SubscriberStreak
            },
            {
                path: ':id/level',
                name: 'new-level-activity',
                component: SubscribersSingleLevelActivities
            },
            {
                path: ':id/wallet-transactions',
                name: 'subscriber-wallet-transactions',
                component: SubscribersSingleWalletTransactions
            },
            {
                path: ':id/roadmap',
                component: SubscribersSingleRoadmap,
                children: [
                    {
                        path: 'info',
                        name: 'subscriber-roadmap-info',
                        component: SSRoadmapInfo
                    },
                    {
                        path: 'score',
                        name: 'subscriber-roadmap-score',
                        component: SSRoadmapScore
                    },
                    {
                        path: 'product-history',
                        name: 'subscriber-roadmap-product-history',
                        component: SSRoadmapProductHistory
                    }
                ]
            }
        ]
    }
];
