import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class CardManagement extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchActiveCards(body) {
        return this.post('v2/card-info/active-cards', body);
    }

    fetchUserInventory(body) {
        return this.post('v2/card-info/user-inventory', body);
    }

    giveCardsToUser(body) {
        return this.post('v2/card-info/give-cards', body);
    }

    activateUserCard(body) {
        return this.post('v2/card-info/activate-card', body);
    }
}

export default CardManagement;
