import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Region extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllRegions(params) {
        return this.post('region/findAll', params);
    }

    fetchOneRegion(params) {
        return this.post('region/findOne', params);
    }

    createRegion(body) {
        return this.post('region', body);
    }

    updateRegion(body) {
        return this.post('region/update', body);
    }

    deleteRegion(body) {
        return this.post('region/remove', body);
    }
}

export default Region;
