<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import FlatPickr from 'vue-flatpickr-component';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import vSelect from 'vue-select';
import { formatDateToHMS, formatDateToYMD } from '@/util/date/calendar.util';

export default {
    name: 'Notifications',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BFormTextarea,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        FlatPickr
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            merchantOptions: null,
            dataForModal: {
                title: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                link: '',
                is_send: false,
                schedule_at: null,
                merchant: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getNotifications();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'merchant',
                    label: this.$t('titles.merchant'),
                    formatter: (merchant) => merchant?.name || ''
                },
                {
                    key: 'status',
                    label: this.$t('titles.status')
                },
                {
                    key: 'schedule_at',
                    label: this.$t('titles.schedule_at'),
                    formatter: (schedule_at) =>
                        formatDateToYMD(schedule_at) + ' ' + formatDateToHMS(schedule_at)
                },
                {
                    key: 'is_send',
                    label: this.$t('titles.sent')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getMerchants();
        this.requestPending = false;
    },

    methods: {
        checkLang,

        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getNotifications() {
            const body = {
                params: {
                    page: this.pagination.current,
                    limit: this.pagination.perPage
                }
            };
            await api.notifications
                .fetchAllNotifications(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.pagination = response.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        async createNotification(body) {
            body.merchant_id = body.merchant.id;
            delete body.merchant;
            await api.notifications
                .createNotification(body)
                .then(({ data }) => {
                    this.items.push(data);
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateNotification(body, config) {
            body.merchant_id = body.merchant.id;
            delete body.merchant;
            await api.notifications
                .updateNotification(this.updatingItem.id, body, config)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        // async deleteNotification(id) {
        //     await api.notifications
        //         .deleteNotification(id)
        //         .then(() => {
        //             this.getNotifications();
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //         });
        // },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateNotification(body, config);
                            await this.getNotifications();
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createNotification(body, config);
                            await this.getNotifications();
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (e) {
                        this.$toast.error(
                            e.response?.data?.message || e.message || this.$t('error.smth')
                        );
                    } finally {
                        await this.getNotifications();
                    }
                }
            });
        },

        changeAppID(appID) {
            this.activeAppID = appID;
            this.getMeasurements();
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getMeasurements();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        clearSendingTime(event) {
            if (event) {
                this.dataForModal.schedule_at = null;
            }
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>{{ $t('notifications') }}</template>
            <template v-if="hasAccess('coin_measurement', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(title)="{ item }">
                    {{ checkLang(item.title) }}
                </template>

                <template #cell(description)="{ item }">
                    {{ checkLang(item.description) }}
                </template>

                <template #cell(status)="{ item }">
                    <span v-if="item.status === 'waiting'">{{
                        $t('notification_statuses.waiting')
                    }}</span>
                    <span v-else-if="item.status === 'cancelled'">{{
                        $t('notification_statuses.cancelled')
                    }}</span>
                    <span v-else>{{ $t('notification_statuses.waiting') }}</span>
                </template>

                <template #cell(is_send)="{ item }">
                    <span v-if="item['is_send']">{{ $t('titles.sent') }}</span>
                    <span v-else>{{ $t('titles.not_sent') }}</span>
                </template>

                <!--                :delete-handler="deleteNotification"-->
                <!--                :delete-access="{ section: 'coin_measurement', permission: 'delete' }"-->
                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :index="index"
                        :item="item"
                        :update-access="{ section: 'coin_measurement', permission: 'update' }"
                        @openModal="openModalToUpdate"
                    />
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.notification') : $t('add.notification')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE[UZ]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE[RU]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE[EN]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[UZ]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.uz"
                                    :placeholder="`${$t('enter.description')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    rows="10"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[RU]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.ru"
                                    :placeholder="`${$t('enter.description')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    rows="10"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[EN]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.en"
                                    :placeholder="`${$t('enter.description')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    rows="10"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    LINK    -->
                    <b-col cols="8">
                        <label> {{ $t('titles.url') }}</label>
                        <ValidationProvider v-slot="{ errors }" :name="$t('titles.url')">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.link"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     MERCHANT ID     -->
                    <b-col cols="4">
                        <label>{{ $t('app') }}</label>
                        <ValidationProvider v-slot="{ errors }" :name="$t('app')" rules="required">
                            <v-select
                                v-model="dataForModal.merchant"
                                :options="merchantOptions"
                                :placeholder="$t('choose.app')"
                                :searchable="false"
                                label="name"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS SENT    -->
                    <b-col cols="4">
                        <label for="is_published">{{ $t('button.send') }}?</label>
                        <b-form-checkbox
                            id="is_send"
                            v-model="dataForModal.is_send"
                            @input="clearSendingTime"
                        />
                    </b-col>

                    <!--    DATE    -->
                    <b-col v-if="!dataForModal.is_send" cols="4">
                        <label>{{ $t('titles.date') }}</label>
                        <div class="input-group">
                            <flat-pickr
                                v-model="dataForModal.schedule_at"
                                :config="{ enableTime: true, allowInput: true, time_24hr: true }"
                                :placeholder="$t('choose.date')"
                                class="mb-1 form-control"
                                name="date"
                                size="md"
                            />
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: auto;
}

.flatpickr-input {
    margin-bottom: 0 !important;
    height: 46px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
