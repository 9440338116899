import {
    axiosInstance,
    axiosInstanceV1,
    axiosInstanceV2,
    axiosInstanceV3
} from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Banners extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllBanners(params) {
        return this.post('banner/findAll', params);
    }

    fetchOneBanner(params) {
        return this.post('banner/findOne', params);
    }

    createBanner(body) {
        return this.post('banner', body);
    }

    updateBanner(body) {
        return this.post('banner/update', body);
    }

    deleteBanner(body) {
        return this.post('banner/remove', body);
    }
}

export default Banners;
