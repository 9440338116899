<script>
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import FilterBlock from '@/components/FilterBlock';
import CoinLogsTimeline from '@/components/CoinLogsTimeline.vue';
import { MERCHANT_ID } from '@/constants/names';
import { formatDatePlusFiveHours } from '@/util/date/calendar.util';

export default {
    name: 'SubscribersSingleCoinMonitoring',
    components: {
        FilterBlock,
        CoinLogsTimeline,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BButton,
        vSelect
    },
    inject: ['coinTypes'],
    data: () => ({
        coinCount: null,
        isDebit: true,
        logs: {
            items: [],
            pagination: {}
        },
        filter: {
            page: 1
        },
        requestPending: false,
        localLang: localStorage.getItem('lang')
    }),
    computed: {
        computedActions() {
            if (!this.coinTypes.value) return;
            return this.coinTypes.value;
        },

        computedOptions() {
            return [
                {
                    name: this.$t('titles.plus'),
                    value: true
                },
                {
                    name: this.$t('titles.minus'),
                    value: false
                }
            ];
        }
    },
    methods: {
        formatDatePlusFiveHours,
        async getCoinMonitoring() {
            if (this.requestPending) return;
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 10,
                user_id: this.$route.params.id,
                merchant_id: MERCHANT_ID
            });
            const { data } = await api.coin.getSubscribeCoinHistory(this.filter);
            if (data.pagination.current === 1) {
                this.logs.items = data.result;
            } else {
                this.logs.items = [...this.logs.items, ...data.result];
            }
            this.logs.pagination = data.pagination;
            this.requestPending = false;
        },

        loadNextPage(page) {
            if (this.requestPending || this.autoReload || !page) return;
            this.filter.page = page;
            this.getCoinMonitoring();
        },

        deSelect(val) {
            if (!val) {
                this.getCoinMonitoring();
            }
        },

        resetField() {
            this.coinCount = null;
        },

        async activateCoin() {
            const body = {
                user_id: parseInt(this.$route.params.id),
                merchant_id: parseInt(MERCHANT_ID),
                data: {
                    type: 'dashboard'
                },
                type: 'dashboard',
                status: 1,
                amount: this.coinCount,
                debit: this.isDebit
            };
            if (this.isDebit === true) {
                body.amount = parseInt(this.coinCount);
            } else {
                body.amount = parseInt(`-${this.coinCount}`);
            }
            try {
                await api.coin.plusMinusCoinToUser(body);
            } catch (e) {
                console.error(e);
            }
        }
    },
    mounted() {
        this.getCoinMonitoring();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-7 col-sm-12 col-12 order-2 order-md-1">
                <coin-logs-timeline
                    v-model:loading="requestPending"
                    :items="logs.items"
                    :pagination="logs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <!--                        <div>{{ item }}</div>-->
                        <div class="activity-item">
                            <div class="activity-item__content">
                                <h4>{{ item.title }}</h4>
                                <h5>
                                    {{ item.sub_title }}
                                </h5>
                                <p v-if="item.user">{{ item.user.phone }}</p>
                                <span :class="{ debit: item.debit }"
                                    >{{ item.amount }} {{ $t('titles.coin') }}
                                </span>
                            </div>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <!--                        <router-link-->
                        <!--                            :to="{-->
                        <!--                                name: 'subscribers Single',-->
                        <!--                                params: {-->
                        <!--                                    id: item.user.id-->
                        <!--                                }-->
                        <!--                            }"-->
                        <!--                            class="d-flex"-->
                        <!--                        >-->
                        <!--                            {{ item.phone }}-->
                        <!--                        </router-link>-->
                        <!--                                                <div v-for="(data, key) in item.data" :key="key" class="d-flex">-->
                        <!--                                                    <small v-if="key === 'prize'">-->
                        <!--                                                        {{ key }}: {{ data.name[localLang] }}-->
                        <!--                                                    </small>-->
                        <!--                                                    <small v-else>{{ key }}: {{ data }}</small>-->
                        <!--                                                </div>-->
                    </template>
                </coin-logs-timeline>
            </b-col>
            <!--            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">-->
            <!--                <filter-block :filter="filter" @submit="getCoinMonitoring">-->
            <!--                    <b-col cols="12">-->
            <!--                        <h5>Действия</h5>-->
            <!--                        <b-form-group>-->
            <!--                            <v-select-->
            <!--                                v-model="filter.type"-->
            <!--                                :options="computedActions"-->
            <!--                                :reduce="(item) => item.key"-->
            <!--                                :searchable="false"-->
            <!--                                label="label"-->
            <!--                                @input="deSelect"-->
            <!--                            />-->
            <!--                        </b-form-group>-->
            <!--                    </b-col>-->
            <!--                </filter-block>-->
            <!--            </b-col>-->

            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">
                <b-card>
                    <div class="row">
                        <div class="col-6">
                            <!--                        <h5>{{ $t('titles.') }}</h5>-->
                            <b-form-group label="FIT-Coin">
                                <b-form-input
                                    v-model="coinCount"
                                    :placeholder="$t('enter.coin')"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-6">
                            <b-form-group label="Plus/Minus">
                                <v-select
                                    v-model="isDebit"
                                    :options="computedOptions"
                                    :reduce="(item) => item.value"
                                    :searchable="false"
                                    label="name"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <div>
                                <b-button
                                    @click="activateCoin"
                                    class="mt-1 float-right"
                                    variant="outline-success"
                                    :disabled="!coinCount"
                                    >{{ $t('button.activate') }}</b-button
                                >
                                <b-button
                                    @click="resetField"
                                    class="mt-1 mr-1 float-right"
                                    variant="outline-secondary"
                                >
                                    {{ $t('button.reset') }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <!--                            <v-select-->
                    <!--                                v-model="filter.type"-->
                    <!--                                :options="computedActions"-->
                    <!--                                :reduce="(item) => item.key"-->
                    <!--                                :searchable="false"-->
                    <!--                                label="label"-->
                    <!--                                @input="deSelect"-->
                    <!--                            />-->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #7367f0;
            margin-bottom: 0;
        }

        span {
            color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}
</style>
