import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class HomeUsers extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchTotal(body) {
        return this.post('statistics/users/total', body);
    }

    fetchDaily(body) {
        return this.post('statistics/users/daily', body);
    }

    fetchDailyApp(body) {
        return this.post('statistics/users/daily-app', body);
    }
}

export default HomeUsers;
