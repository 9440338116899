var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.products'))+" [VIP]")]},proxy:true}])}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value.id)+" ")]}},{key:"cell(qty)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value.qty)+" ")]}},{key:"cell(activation_item_id)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.value.activation_item_id.color}},[_vm._v(_vm._s(item.value.activation_item_id.text.toUpperCase()))])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLocales(item.value.name))+" ")]}},{key:"cell(group)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLocales(item.value.group))+" ")]}},{key:"cell(calculate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.calculate)+" % ")]}},{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":item.value.is_active,"name":"check-button","switch":""},on:{"input":function($event){return _vm.changeStatus(item.value)}}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"index":index,"item":item,"update-access":{ section: 'cities', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.subscription') : _vm.$t('add.subscription'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.beforeClose},model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('group'),"label-for":"categories"}},[_c('v-select',{attrs:{"id":"categories","disabled":_vm.isViewModal || !!_vm.updatingItem,"options":_vm.groupsList,"reduce":function (option) { return option.id; },"placeholder":_vm.$t('choose.group'),"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.name))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.name))+" ")]}}]),model:{value:(_vm.dataForModal.group_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "group_id", $$v)},expression:"dataForModal.group_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" [UZ]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(((_vm.$t('titles.title')) + " [RU]")))]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(((_vm.$t('titles.title')) + " [EN]")))]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.types')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.types'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"categories","disabled":_vm.isViewModal,"options":_vm.staticTypesList,"reduce":function (option) { return option.value; },"placeholder":_vm.$t('choose.type'),"label":"name","clearable":false},on:{"input":_vm.clearActivationItem},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.name))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.name))+" ")]}}],null,true),model:{value:(_vm.dataForModal.types),callback:function ($$v) {_vm.$set(_vm.dataForModal, "types", $$v)},expression:"dataForModal.types"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.dataForModal.types !== 5)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.checkedLabels))]),_c('ValidationProvider',{attrs:{"name":_vm.checkedLabels,"rules":_vm.dataForModal.types === 3 ? 'required|min_value:500' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[(_vm.dataForModal.types === 4)?_c('v-select',{attrs:{"id":"categories","disabled":_vm.isViewModal,"options":_vm.levelActionsList,"reduce":function (option) { return option.type; },"placeholder":_vm.checkedLabels,"label":"name","clearable":false},model:{value:(_vm.dataForModal.activation_item_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "activation_item_id", $$v)},expression:"dataForModal.activation_item_id"}}):(
                                    _vm.dataForModal.types === 1 ||
                                    _vm.dataForModal.types === 2 ||
                                    _vm.dataForModal.types === 3
                                )?_c('b-form-input',{attrs:{"placeholder":_vm.checkedLabels,"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.activation_item_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "activation_item_id", $$v)},expression:"dataForModal.activation_item_id"}}):_c('v-select',{attrs:{"id":"categories","disabled":_vm.isViewModal,"options":_vm.packagesList,"reduce":function (option) { return option.id; },"placeholder":_vm.checkedLabels,"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.type_name + ' ' + option.qty)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.type_name + ' ' + option.qty)+" ")]}}],null,true),model:{value:(_vm.dataForModal.activation_item_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "activation_item_id", $$v)},expression:"dataForModal.activation_item_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,543966673)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.percentage')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.percentage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.percentage'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.proc),callback:function ($$v) {_vm.$set(_vm.dataForModal, "proc", $$v)},expression:"dataForModal.proc"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.is_unlimited)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.qty')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.qty'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.qty'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.qty),callback:function ($$v) {_vm.$set(_vm.dataForModal, "qty", $$v)},expression:"dataForModal.qty"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3924336508)})],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-center mt-2 user-select-none",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"id":"is_unlimited","disabled":_vm.isViewModal},on:{"change":function($event){return _vm.disableQtyAndEqualsToNull(_vm.is_unlimited)}},model:{value:(_vm.is_unlimited),callback:function ($$v) {_vm.is_unlimited=$$v},expression:"is_unlimited"}}),_c('label',{staticClass:"font-medium-1",attrs:{"for":"is_unlimited"}},[_vm._v(" "+_vm._s(_vm.$t('titles.is_unlimited'))+" ")])],1),_c('div',{staticClass:"w-100 mb-2"},[_c('label',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('titles.image')))]),_c('b-col',{attrs:{"cols":"12"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"accept":'image/*,.zip',"deletable":true,"errorText":{
                                type: _vm.$t('vue_file_agent.file_type_error'),
                                size: _vm.$t('vue_file_agent.size_type_error')
                            },"helpText":_vm.$t('vue_file_agent.choose_image'),"maxFiles":14,"maxSize":'10MB',"meta":true,"multiple":false,"theme":'grid'},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)},"select":function($event){return _vm.filesSelected($event)}},model:{value:(_vm.uploadImage),callback:function ($$v) {_vm.uploadImage=$$v},expression:"uploadImage"}}),_c('b-button',{staticClass:"float-right mt-1",attrs:{"disabled":!_vm.fileRecordsForUpload.length || _vm.imageUploaded,"variant":_vm.imageUploaded ? 'success' : 'primary'},on:{"click":function($event){return _vm.getImageId()}}},[(!_vm.imageUploaded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.upload_image'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.image_uploaded'))+" ")])])],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"percentage-modal","centered":"","title":_vm.$t('titles.percentage')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.updatePercentage}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.percentage')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.percentage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.percentage'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.percentage.defaultProc),callback:function ($$v) {_vm.$set(_vm.percentage, "defaultProc", $$v)},expression:"percentage.defaultProc"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"d-flex mt-2 user-select-none",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"id":"procTrace","disabled":_vm.isViewModal},model:{value:(_vm.percentage.procTrace),callback:function ($$v) {_vm.$set(_vm.percentage, "procTrace", $$v)},expression:"percentage.procTrace"}}),_c('label',{staticClass:"font-medium-1",attrs:{"for":"procTrace"}},[_vm._v(" "+_vm._s(_vm.$t('titles.update_all'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }