<script>
import vSelect from 'vue-select';
import api from '@/services/api';
import { toFixed } from '@/util/number';
import { keys } from '@/util/object.util';
import { BBadge, BCol, BRow } from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle.vue';
import TimeLineCard from '@/views/coin/order/TimeLineCard.vue';
import { checkLang, computedStatusClass } from '@/util/helper';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { makeBackground } from 'echarts/lib/component/helper/listComponent';
import {
    addZero,
    dateFormatWithDot,
    dateFormatWithSlash,
    dateProperties,
    subtractFiveHours
} from '@/util/date/calendar.util';

export default {
    name: 'OrderView',
    components: {
        TimeLineCard,
        ProgressLinear,
        PageTitle,
        vSelect,
        BCol,
        BRow,
        BBadge
    },
    data() {
        return {
            orderItem: {},
            isOpenModal: false,
            statusOptions: {},
            form: {
                status: null,
                comment: null
            },
            requestPending: false
        };
    },
    computed: {
        statusOptionList() {
            const locale = this.$i18n.locale;
            if (keys(this.statusOptions).length > 0) {
                const l = [];
                for (let [key, option] of Object.entries(this.statusOptions)) {
                    l.push({
                        value: key,
                        label: option[locale][0].toUpperCase() + option[locale].slice(1)
                    });
                }
                return l;
            }

            return [];
        },
        orderItemFields() {
            return [
                {
                    key: 'product',
                    label: this.$t('titles.product'),
                    formatter: (product) => product.name
                },
                {
                    key: 'price',
                    label: this.$t('titles.price')
                },
                {
                    key: 'quantity',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'total_price',
                    label: this.$t('titles.total_price')
                }
            ];
        }
    },
    async mounted() {
        const params = this.$route.params;
        if (params?.id) {
            this.requestPending = true;
            await this.fetchOrder(params.id);
        }
    },
    methods: {
        dateFormatWithSlash,
        subtractFiveHours,
        makeBackground,
        computedStatusClass,
        toFixed,
        checkLang,
        async fetchOrder(id) {
            const body = {
                id: id
            };
            await api.coin
                .getCoinOrder(body)
                .then((response) => {
                    this.orderItem = response.data.result;
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                })
                .finally(() => {
                    this.requestPending = false;
                });
        },

        async changeStatus() {
            let body = {
                id: this.orderItem.id,
                merchant_id: this.orderItem.merchant.id,
                status: this.form.status.value
            };
            if (this.form.comment) {
                body = { ...body, comment: this.form.comment };
            }
            await api.coin
                .updateCoinOrder(body)
                .then((response) => {
                    this.orderItem = response.data.result;
                    this.onCloseModal();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },
        async getStatusList() {
            try {
                const response = await api.coin.fetchCoinStatus();
                this.statusOptions = response.data;
                this.isOpenModal = true;
            } catch (e) {
                this.$toast.error(e.response?.data?.message || e.message || this.$t('error.smth'));
            }
        },
        openStatusModal() {
            this.getStatusList();
        },
        onCloseModal() {
            this.isOpenModal = false;
        },
        async downloadPassport(imageSrc) {
            const image = await fetch(imageSrc.path, { mode: 'no-cors' });
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);

            const link = document.createElement('a');
            link.href = imageURL;
            link.download = imageSrc.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // let xhr = new XMLHttpRequest();
            // xhr.open('GET', imageSrc.path, true);
            // xhr.setRequestHeader('Content-Type', 'application/json');
            // xhr.responseType = 'blob';
            // xhr.onload = function () {
            //     let urlCreator = window.URL || window.webkitURL;
            //     let imageUrl = urlCreator.createObjectURL(this.response);
            //     let tag = document.createElement('a');
            //     tag.href = imageUrl;
            //     tag.download = imageSrc.name;
            //     document.body.appendChild(tag);
            //     tag.click();
            //     document.body.removeChild(tag);
            // };
            // xhr.send();
        },
        getCreatedDate(time) {
            const _sTime = subtractFiveHours(time);
            const _slashTime = dateFormatWithDot(_sTime, { reverse: false, monthIndex: 1 });
            const { hours, minutes } = dateProperties(_sTime);
            return _slashTime + ` ${addZero(hours)}:${addZero(minutes)}`;
        }
    }
};
</script>
<template>
    <div>
        <page-title>
            <template #title> {{ $t('statistics') }}</template>
        </page-title>
        <progress-linear v-if="requestPending" />

        <!--  ORDER INFO  -->
        <b-row v-else>
            <b-col class="col-12 col-lg-9">
                <b-card>
                    <div
                        class="col-12 px-0 order-header d-flex flex-column flex-lg-row align-items-start align-items-sm-center justify-content-between bordered-card"
                    >
                        <div class="col-12 col-lg-8 px-0">
                            <h2 class="order-title">
                                {{ $t('order.order') }} <span>#{{ orderItem.id }}</span>
                            </h2>
                            <p class="order-created_at">
                                {{ $t('order.created_date') }}
                                <span>
                                    {{ getCreatedDate(orderItem.created_at) }}
                                </span>
                            </p>
                        </div>
                        <div>
                            <b-badge
                                class="mb-1 mb-lg-0"
                                :class="computedStatusClass(orderItem.status)"
                                :style="`background-color: ${orderItem.color}`"
                            >
                                {{ checkLang(orderItem.status) }}
                            </b-badge>
                        </div>
                    </div>

                    <div class="order-details">
                        <b-row class="mt-3">
                            <!--      ADDRESS DETAILS     -->
                            <b-col class="col-12 col-lg-6">
                                <h4 class="order-details_title mb-2">
                                    {{ $t('order.address') }}
                                </h4>

                                <div
                                    v-if="orderItem.address"
                                    class="d-flex flex-column align-items-start"
                                >
                                    <div class="order-info">
                                        <h5>{{ $t('order.region') }}:</h5>
                                        <p>{{ checkLang(orderItem.address.region.name) }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.city') }}:</h5>
                                        <p>{{ checkLang(orderItem.address.city.name) }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.address') }}:</h5>
                                        <p>{{ orderItem.address.address }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.entrance') }}:</h5>
                                        <p>{{ orderItem.address.entrance }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.floor') }}:</h5>
                                        <p>{{ orderItem.address.floor }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.flat') }}:</h5>
                                        <p>{{ orderItem.address.apartment }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.comment') }}:</h5>
                                        <p>{{ orderItem.address.comment }}</p>
                                    </div>
                                </div>
                            </b-col>

                            <!--      RECEIVER DETAILS     -->
                            <b-col class="col-12 col-lg-6">
                                <h4 class="order-details_title mb-2">
                                    {{ $t('order.receiver') }}
                                </h4>

                                <div
                                    v-if="orderItem.client_detail"
                                    class="d-flex flex-column align-items-start"
                                >
                                    <div v-if="orderItem.user" class="order-info">
                                        <h5>USER ID:</h5>
                                        <p>{{ orderItem.client_detail.user.id }}</p>
                                    </div>
                                    <div v-if="orderItem.user" class="order-info">
                                        <h5>{{ $t('order.phone') }}:</h5>
                                        <p>{{ orderItem.user.phone }}</p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.fio') }}:</h5>
                                        <p>
                                            {{
                                                orderItem.client_detail.first_name +
                                                ' ' +
                                                orderItem.client_detail.last_name
                                            }}
                                        </p>
                                    </div>
                                    <div class="order-info">
                                        <h5>{{ $t('order.pinfl') }}:</h5>
                                        <p>{{ orderItem.client_detail.pinfl }}</p>
                                    </div>
                                    <!--                                  @click="downloadPassport(orderItem.client_detail.passport)"-->

                                    <a
                                        v-if="orderItem.client_detail.passport"
                                        :href="orderItem.client_detail.passport.path"
                                        class="download-btn"
                                        target="_blank"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div class="download-btn_image">
                                                <FeatherIcon icon="FileTextIcon" />
                                            </div>
                                            <div class="d-flex flex-column ml-1">
                                                <p class="mb-0">
                                                    {{ orderItem.client_detail.passport.name }}
                                                </p>
                                                <span class="text-left"
                                                    >{{
                                                        (
                                                            parseFloat(
                                                                orderItem.client_detail.passport
                                                                    .size
                                                            ) / 1000
                                                        ).toFixed(1)
                                                    }}
                                                    kb</span
                                                >
                                            </div>
                                        </div>
                                        <div>
                                            <FeatherIcon icon="DownloadIcon" />
                                        </div>
                                    </a>

                                    <!--                                    <div class="order-info">-->
                                    <!--                                        <h5>{{ $t('order.passport') }}</h5>-->
                                    <!--                                        <p>{{ orderItem.client_detail.passport }}</p>-->
                                    <!--                                    </div>-->
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!--      TABLE     -->
                            <b-col cols="12">
                                <b-table
                                    v-if="orderItem.order_items"
                                    :fields="orderItemFields"
                                    :items="orderItem.order_items"
                                    :responsive="true"
                                    borderless
                                    class="mt-3"
                                    outlined
                                    striped
                                >
                                    <template #cell(product)="{ item }">
                                        <div class="d-flex align-items-center">
                                            <span
                                                v-if="
                                                    item.product &&
                                                    item.product.images &&
                                                    item.product.images.length
                                                "
                                            >
                                                <img
                                                    :src="item.product.images[0].path"
                                                    alt=""
                                                    class="product-img"
                                                />
                                            </span>
                                            {{ item.product.name }}
                                        </div>
                                    </template>
                                    <template #cell(price)="{ item }">
                                        <div class="d-flex align-items-center">
                                            <span>
                                                <img
                                                    alt=""
                                                    class="coin"
                                                    src="@/assets/images/icons/coin.png"
                                                />
                                            </span>
                                            {{ item.price }}
                                        </div>
                                    </template>

                                    <template #cell(total_price)="{ item }">
                                        <div class="d-flex align-items-center">
                                            <span>
                                                <img
                                                    alt=""
                                                    class="coin"
                                                    src="@/assets/images/icons/coin.png"
                                                />
                                            </span>
                                            {{ item.quantity * item.price }}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>

                            <!--      SUMMARY     -->
                            <b-col cols="12">
                                <div class="mt-4">
                                    <div
                                        class="bordered-card order-summary d-flex flex-column align-items-end col-12 pb-2"
                                    >
                                        <div class="col-12 d-flex align-items-center mb-1">
                                            <div class="col-6 col-lg-2 offset-lg-8">
                                                <p class="mb-0">{{ $t('order.subtotal') }}:</p>
                                            </div>
                                            <div class="d-flex align-items-center col-6 col-lg-2">
                                                <img
                                                    alt=""
                                                    class="coin"
                                                    src="@/assets/images/icons/coin.png"
                                                />
                                                <span>{{ orderItem.grand_total }}</span>
                                            </div>
                                        </div>

                                        <div class="col-12 d-flex align-items-center mb-1">
                                            <div class="col-6 col-lg-2 offset-lg-8">
                                                <p class="mb-0">
                                                    {{ $t('titles.price_discount') }}:
                                                </p>
                                            </div>
                                            <div class="d-flex align-items-center col-6 col-lg-2">
                                                <img
                                                    alt=""
                                                    class="coin"
                                                    src="@/assets/images/icons/coin.png"
                                                />
                                                <span>{{ orderItem['total_discount'] }}</span>
                                            </div>
                                        </div>

                                        <div class="col-12 d-flex align-items-center mb-1">
                                            <div class="col-6 col-lg-2 offset-lg-8">
                                                <p class="mb-0">{{ $t('order.delivery') }}:</p>
                                            </div>
                                            <div class="d-flex align-items-center col-2">
                                                <img
                                                    alt=""
                                                    class="coin"
                                                    src="@/assets/images/icons/coin.png"
                                                />
                                                <span>{{ orderItem.delivery_cost }}</span>
                                            </div>
                                        </div>

                                        <div class="col-12 d-flex align-items-center mb-1">
                                            <div class="col-6 col-lg-2 offset-lg-8">
                                                <p class="mb-0">{{ $t('order.total') }}:</p>
                                            </div>
                                            <div class="d-flex align-items-center col-2">
                                                <img
                                                    alt=""
                                                    class="coin"
                                                    src="@/assets/images/icons/coin.png"
                                                />
                                                <span>{{ orderItem.grand_total }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>
            <b-col class="col-12 col-lg-3">
                <b-card>
                    <div>
                        <button class="btn btn-primary w-100" @click="openStatusModal">
                            {{ $t('order.change_status') }}
                        </button>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <!--  ACTIVITY TIME LINE  -->
        <b-row v-if="orderItem.order_histories">
            <b-col cols="12">
                <time-line-card :order-history="orderItem.order_histories" />
            </b-col>
        </b-row>

        <!--  UPDATE MODAL  -->
        <b-modal
            id="statusChangeModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="$t('edit.status')"
            cancel-variant="danger"
            centered
            size="sm"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--     TYPE     -->
                    <b-col cols="12">
                        <label>{{ $t('titles.type') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <v-select
                                v-model="form.status"
                                :options="statusOptionList"
                                :placeholder="$t('choose.type')"
                                :searchable="false"
                            >
                            </v-select>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    AMOUNT    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.comment') }}
                        </label>
                        <b-form-group>
                            <b-form-input
                                v-model="form.comment"
                                :placeholder="`${$t('order.comment')}`"
                                size="lg"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="light" @click="onCloseModal">
                    <!--                    {{ $t('cancel') }}-->
                    Отмена
                </b-button>
                <b-button variant="success" @click.once="changeStatus"> Изменить</b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.bordered-card {
    border-bottom: 1px solid #dbdade;
}

.product-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 0.5rem;
}

span.badge {
    text-transform: uppercase;
}

ul > li {
    padding-bottom: 0.5rem;
}

::v-deep ul li {
    margin-bottom: 0.5rem !important;

    &::marker {
        color: transparent;
    }
}

.coin {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.download-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    width: 100%;
    border-radius: 6px;
    border: none;
    padding: 9px 1rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #4b465c;

    &_image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
            #8692d0;

        & svg {
            width: 20px;
            height: 20px;
            stroke: #7367f0 !important;
        }
    }

    & svg {
        width: 20px;
        height: 20px;
        stroke: #6f6b7d;
    }

    & p {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        text-align: left;
        color: #4b465c;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & span {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #a3abb8;
        text-align: left;
    }
}

.order {
    &-info {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        margin-bottom: 6px;

        h5,
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 0;
        }

        h5 {
            font-weight: bold;
        }
    }

    &-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
    }

    &-created_at {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;

        & span {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
        }
    }

    &-details_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
    }

    &-details_address li {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
    }

    &-summary {
        & p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
        }

        & span {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
        }
    }
}
</style>
