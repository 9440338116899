export default [
    {
        path: '/ads-company',
        name: 'ads-company',
        component: () => import('@/views/ads/AdsCompany.vue')
    },
    {
        path: '/ads/:id/advertisement',
        name: 'ads-advertisement',
        component: () => import('@/views/ads/Advertisement.vue')
    },
    {
        path: '/telegram-channels',
        name: 'telegram-channels',
        component: () => import('@/views/ads/telegram-channels/TelegramChannels.vue'),
        meta: {
            section: 'telegram_channels',
            permission: 'telegram-channels'
        }
    },
    {
        path: '/telegram-channels/:id/statistics',
        name: 'telegram-channels-show',
        component: () => import('@/views/ads/telegram-channels/TelegramChannelShow.vue')
    }
];
