import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class City extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllCities(params) {
        return this.post('city/findAll', params);
    }

    fetchOneCity(params) {
        return this.post('city/findOne', params);
    }

    createCity(body) {
        return this.post('city', body);
    }

    updateCity(body) {
        return this.post('city/update', body);
    }

    deleteCity(body) {
        return this.post('city/remove', body);
    }
}

export default City;
