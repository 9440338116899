import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class SandboxService extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'randomizer/dashboardGf/' : 'randomizer/dashboardAl/';
    }

    fetchAllGroups(params) {
        return this.post(this.dashboardVersion() + 'sandboxGroup/getAll', params);
    }

    fetchOneGroup(id) {
        return this.get(this.dashboardVersion() + `sandboxGroup/get/${id}`);
    }

    createGroup(body) {
        return this.post(this.dashboardVersion() + 'sandboxGroup/create', body);
    }

    updateGroup(id, body) {
        return this.post(this.dashboardVersion() + `sandboxGroup/update/${id}`, body);
    }

    deleteGroup(id) {
        return this.delete(this.dashboardVersion() + `sandboxGroup/delete/${id}`);
    }

    fillGroup(body) {
        return this.post(this.dashboardVersion() + 'sandboxGroup/userFill', body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    fetchAllCategories(body) {
        return this.post(this.dashboardVersion() + 'sandboxCategoryItem/getCategories', body);
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'sandboxCategoryItem/getItems', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `sandboxCategoryItem/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'sandboxCategoryItem/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `sandboxCategoryItem/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `sandboxCategoryItem/delete/${id}`);
    }

    fetchOneSandboxCharge(id) {
        return this.get(this.dashboardVersion() + `sandboxCategoryCharge/get/${id}`);
    }

    createSandboxCharge(body) {
        return this.post(this.dashboardVersion() + 'sandboxCategoryCharge/create', body);
    }

    updateSandboxCharge(id, body) {
        return this.post(this.dashboardVersion() + `sandboxCategoryCharge/update/${id}`, body);
    }

    deleteSandboxCharge(id) {
        return this.delete(this.dashboardVersion() + `sandboxCategoryCharge/delete/${id}`);
    }

    fetchAllSandboxUsers(body) {
        return this.post(this.dashboardVersion() + 'sandboxUser/getAll', body);
    }

    fetchOneSandboxUser(userId) {
        return this.post(this.dashboardVersion() + `sandboxUser/get/${userId}`);
    }

    createSandboxUser(body) {
        return this.post(this.dashboardVersion() + 'sandboxUser/create', body);
    }

    deleteSandboxUser(userId) {
        return this.delete(this.dashboardVersion() + `sandboxUser/delete/${userId}`);
    }
}

export default SandboxService;
