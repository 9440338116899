import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class USSDTexts extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/ussd/dictionary/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('v2/ussd/dictionary/findOne', body);
    }

    createItem(body) {
        return this.post('v2/ussd/dictionary', body);
    }

    updateItem(id, body) {
        return this.post(`v2/ussd/dictionary/update/${id}`, body);
    }

    deleteItem(body) {
        return this.post('v2/ussd/dictionary/remove', body);
    }
}

export default USSDTexts;
