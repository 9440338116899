<script>
import LogsTimeline from '@/components/LogsTimeline';
import api from '@/services/api';
import { BCol, BFormGroup, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';

export default {
    name: 'SubscribersSingleActivities',
    components: { LogsTimeline, BRow, BCol, BFormGroup, vSelect },
    data() {
        return {
            subscriberTransactions: {
                items: [],
                pagination: {}
            },
            localLang: localStorage.getItem('lang'),
            params: {
                page: 1,
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                sort: {
                    column: 'id',
                    direction: 'desc'
                },
                search: '',
                filters: {
                    user_id: this.$route.params.id,
                    level_id: null
                }
            },
            levelTypes: [],
            actionTypes: [],
            staticLevelList: [
                {
                    id: 1,
                    name: 'Level 1'
                },
                {
                    id: 2,
                    name: 'Level 2'
                },
                {
                    id: 3,
                    name: 'Level 3'
                },
                {
                    id: null,
                    name: 'All'
                }
            ],
            level_up: 1,
            requestPending: true
        };
    },
    computed: {
        computedTransactions() {
            if (!this.transactions.value) return;
            return this.transactions.value.map((item) => {
                if (typeof item.name === 'object') {
                    item.name = item.name[this.localLang];
                }
                return item;
            });
        }
    },
    methods: {
        checkLocales,
        async getUserTransactions() {
            this.requestPending = true;
            try {
                const { data } = await api.newLevel.fetchAllLevelScore(this.params);
                console.log(data);
                if (data.data.pagination.current === 1) {
                    this.subscriberTransactions.items = data.data.list;
                } else {
                    this.subscriberTransactions.items = [
                        ...this.subscriberTransactions.items,
                        ...data.data.list
                    ];
                }
                this.subscriberTransactions.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
            this.requestPending = false;
        },

        async getLevelTypes() {
            const body = {
                page: 1,
                limit: 20,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevel.fetchAllItems(body);
                this.levelTypes = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getActionTypes() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevelActions.fetchAllItems(body);
                this.actionTypes = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async userLevelUp() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.$route.params.id,
                level_id: this.level_up
            };
            try {
                await api.newLevel.userLevelUp(body);
            } catch (e) {
                console.error(e);
            }
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.requestPending = true;
            this.params.page = page;
            await this.getUserTransactions();
            this.requestPending = false;
        },

        resetRequest() {
            this.params = {
                page: 1,
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                sort: {
                    column: 'id',
                    direction: 'desc'
                },
                search: '',
                filters: {
                    user_id: this.$route.params.id,
                    level_id: [],
                    action_id: []
                }
            };
            this.getUserTransactions();
        }
    },
    async mounted() {
        await this.getUserTransactions();
        await this.getLevelTypes();
        await this.getActionTypes();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-6 col-12 order-2 order-sm-1">
                <logs-timeline
                    v-model:loading="requestPending"
                    :items="subscriberTransactions.items"
                    :pagination="subscriberTransactions.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <!--                        {{ item }}-->
                        <!--                        <div>{{ item.level.name }}</div>-->
                        <!--                        <div>{{ item.action.name }}</div>-->
                        <!--                        <div>{{ item.type }}</div>-->
                        <div class="time__line-block">
                            <div class="d-flex">
                                <h5>{{ checkLocales(item.level.name) }}</h5>
                                <small class="ml-auto text-right">
                                    {{ item.created_at }}
                                </small>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>{{ $t('titles.title') }} :</span>
                                <h5 class="mb-0 ml-1">
                                    <b-badge variant="secondary">
                                        {{ checkLocales(item.action.name).toUpperCase() }}
                                    </b-badge>
                                </h5>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>{{ $t('titles.type') }} :</span>
                                <h5 class="mb-0 ml-1">{{ item.type }}</h5>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>{{ $t('titles.score') }} :</span>
                                <h5 class="mb-0 ml-1">
                                    <b-badge variant="primary">
                                        {{ item.score }}
                                    </b-badge>
                                </h5>
                            </div>
                        </div>
                    </template>
                </logs-timeline>
            </b-col>
            <b-col class="mb-2 mb-sm-0 col-lg-6 col-sm-6 col-12 order-1 order-sm-2">
                <b-card>
                    <h3 class="mb-2">{{ $t('filter_title') }}</h3>
                    <b-col cols="12">
                        <h5>
                            {{ $t('navigation.levels') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="params.filters.level_id"
                                :options="levelTypes"
                                :reduce="(level) => level.id"
                                :searchable="false"
                                :multiple="true"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                        <h5>
                            {{ $t('navigation.level_actions') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="params.filters.action_id"
                                :options="actionTypes"
                                :reduce="(action) => action.id"
                                :searchable="false"
                                :multiple="true"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-2 d-flex justify-content-end">
                        <b-button @click="resetRequest">
                            {{ $t('button.reset') }}
                        </b-button>
                        <b-button @click="getUserTransactions" class="ml-2" variant="success">{{
                            $t('button.filter')
                        }}</b-button>
                    </b-col>
                </b-card>

                <b-card>
                    <b-col cols="12">
                        <h5>
                            {{ $t('navigation.levels') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="level_up"
                                :options="staticLevelList"
                                :reduce="(level) => level.id"
                                :searchable="false"
                                :clearable="false"
                                :multiple="false"
                                label="name"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-2 d-flex justify-content-end">
                        <b-button @click="userLevelUp" class="ml-2" variant="success">{{
                            $t('button.activate')
                        }}</b-button>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
