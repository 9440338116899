<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';

export default {
    name: 'AppReferralPrize',
    components: {
        vSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        quillEditor
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                type: '',
                value: null,
                reason: ''
            },
            content: {
                en: '',
                ru: '',
                uz: ''
            },
            typeList: ['package', 'coin'],
            reasonList: ['registration', 'invitation'],
            packageList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false,
            showInfoModal: false,
            editorOption: {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],

                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],

                        [{ color: [] }, { background: [] }],
                        [{ align: [] }]
                    ]
                }
            }
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
                this.getAllPackage();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.name')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'value',
                    label: this.$t('titles.value')
                },
                {
                    key: 'reason',
                    label: this.$t('titles.action')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,

        async getReferralInfo() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            this.showInfoModal = true;
            try {
                const { data } = await api.referralPrizeApi.fetchInfoReferral(body);
                this.content = data.data.content;
            } catch (e) {
                console.error(e);
            }
        },

        async saveReferralInfo() {
            const body = {
                content: this.content,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                await api.referralPrizeApi.updateInfoReferral(body);
                this.showInfoModal = false;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllPackage() {
            const body = {
                page: 1,
                limit: 200
            };
            const { data } = await api.billingPackages.fetchAllItems(body);
            this.packageList = data.data.list;
        },

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.referralPrizeApi.fetchAllItems(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            const body = {
                id: item.id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.referralPrizeApi
                .fetchOneItem(body)
                .then((response) => {
                    this.isOpenModal = true;
                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                    this.dataForModal.id = res.id;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.referralPrizeApi
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.referralPrizeApi
                .updateItem({ ...body, id: id })
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.referralPrizeApi
                .deleteItem({ id: id, merchant_id: parseInt(MERCHANT_ID) })
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.merchant_id = parseInt(MERCHANT_ID);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        filterPackage(id) {
            let item = this.packageList.filter((item) => {
                return item.id === id;
            })[0];
            return item ? `${item.type_name} ${item.qty}`.toUpperCase() : '';
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        updateValue() {
            this.dataForModal.value = null;
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.referral_prize') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button class="mr-1" variant="primary" @click="getReferralInfo">{{
                    $t('titles.referral_info')
                }}</b-button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(value)="{ item }">
                    <span v-if="item.type === 'package'">{{ filterPackage(item.value) }}</span>
                    <span v-else>{{ item.value }}</span>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'collector', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'collector', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.referral_prize') : $t('add.referral_prize')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--   TITLE [UZ]   -->
                    <div class="col-12">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   TITLE [RU]   -->
                    <div class="col-12">
                        <label> {{ $t('titles.real_title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--   TITLE [EN]   -->
                    <div class="col-12">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--     TYPE     -->
                    <div class="col-12 col-lg-6 mb-1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <label>{{ $t('titles.type') }}</label>
                            <b-form-group>
                                <v-select
                                    @input="updateValue"
                                    :placeholder="$t('choose.type')"
                                    id="types"
                                    :options="typeList"
                                    v-model="dataForModal.type"
                                    :clearable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>

                    <!--    VALUE    -->
                    <div class="col-12 col-lg-6">
                        <div v-if="dataForModal.type === 'coin'">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.value')"
                                rules="required"
                            >
                                <label>{{ $t('titles.value') }}</label>
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.value"
                                        :disabled="isViewModal"
                                        :placeholder="$t('enter.value')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div v-else>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.package')"
                                rules="required"
                            >
                                <label>{{ $t('titles.package') }}</label>
                                <b-form-group>
                                    <v-select
                                        :placeholder="$t('choose.package')"
                                        id="types"
                                        :options="packageList"
                                        v-model="dataForModal.value"
                                        :reduce="(option) => option.id"
                                        label="type_name"
                                        :searchable="false"
                                        :clearable="false"
                                    >
                                        <template v-slot:option="option">
                                            {{ option.type_name + ' ' + option.qty }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ option.type_name + ' ' + option.qty }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!--     REASON     -->
                    <div class="col-12 mb-1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.action')"
                            rules="required"
                        >
                            <label>{{ $t('titles.action') }}</label>
                            <b-form-group>
                                <v-select
                                    :placeholder="$t('choose.action')"
                                    id="types"
                                    :options="reasonList"
                                    v-model="dataForModal.reason"
                                    :clearable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>

        <!--  REFERRAL INFO  -->
        <b-modal
            id="referralInfo"
            v-model="showInfoModal"
            :no-close-on-backdrop="true"
            :title="$t('titles.referral_info')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="showInfoModal = false"
        >
            <div class="col-12 mb-2">
                <label> {{ $t('titles.content') }} [UZ] </label>
                <quill-editor v-model="content.uz" :options="editorOption" />
            </div>
            <div class="col-12 mb-2">
                <label> {{ $t('titles.content') }} [RU] </label>
                <quill-editor v-model="content.ru" :options="editorOption" />
            </div>
            <div class="col-12">
                <label> {{ $t('titles.content') }} [EN] </label>
                <quill-editor v-model="content.en" :options="editorOption" />
            </div>
            <template #modal-footer>
                <b-button variant="success" @click="saveReferralInfo">
                    {{ $t('button.save') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
