import AppNotificationTemplates from '@/views/notificationV2/AppNotificationTemplates.vue';
import AppNotificationHistory from '@/views/notificationV2/AppNotificationHistory.vue';
import AppNotificationStatistics from '@/views/notificationV2/AppNotificationStatistics.vue';
import AppNotificationPortion from '@/views/notificationV2/AppNotificationPortion.vue';
import AppManualNotification from '@/views/notificationV2/manual-notifications/index.vue';
import AppManualNotificationOne from '@/views/notificationV2/manual-notifications/views/AppManualNotificationOne.vue';
import AppManualNotificationAll from '@/views/notificationV2/manual-notifications/views/AppManualNotificationAll.vue';
import AppManualNotificationSegmentation from '@/views/notificationV2/manual-notifications/views/AppManualNotificationSegmentation.vue';

export default [
    {
        path: '/notification-templates',
        name: 'notification-templates',
        component: AppNotificationTemplates,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/notification-portion',
        name: 'notification-portion',
        component: AppNotificationPortion,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/notification-history',
        name: 'notification-history',
        component: AppNotificationHistory,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/notification-statistics',
        name: 'notification-statistics',
        component: AppNotificationStatistics,
        meta: {
            section: 'news',
            permission: 'view'
        }
    },
    {
        path: '/notification-manual',
        name: 'notification-manual',
        component: AppManualNotification,
        meta: {
            section: 'news',
            permission: 'view'
        },
        children: [
            {
                path: 'one',
                name: 'notification-manual-one',
                component: AppManualNotificationOne
            },
            {
                path: 'all',
                name: 'notification-manual-all',
                component: AppManualNotificationAll
            },
            {
                path: 'segmentation',
                name: 'notification-manual-segmentation',
                component: AppManualNotificationSegmentation
            }
        ]
    }
];
