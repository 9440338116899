export default {
    data: () => ({
        permissions: JSON.parse(localStorage.getItem('userData'))?.role.permission ?? null,
        roleName: JSON.parse(localStorage.getItem('userData'))?.role.name ?? null
    }),
    methods: {
        hasAccess(section, permission = 'view') {
            // eslint-disable-next-line no-undef
            // if (process.env.NODE_ENV === 'development') {
            //     return true;
            // }

            if (!this.permissions) return true;
            if (this.roleName === 'Super admin') return true;

            let result = true;
            const customSection = String(section).toLowerCase();
            const customPermission = String(permission).toLowerCase();

            if (section) {
                Object.keys(this.permissions).forEach((sectionName) => {
                    if (sectionName === customSection) {
                        Object.keys(this.permissions[sectionName]).forEach((permissionName) => {
                            if (permissionName === customPermission)
                                result = this.permissions[sectionName][permissionName];
                        });

                        return result;
                    }
                });
            }
            return result;
        },

        haveChildren(item) {
            let result = true;
            Object.keys(item).forEach((key) => {
                if (key === 'children') {
                    item.children.forEach((child, index) => {
                        const checkPerm = this.hasAccess(child.permission, 'view');
                        if (!checkPerm) {
                            item.children[index] = { ...item.children[index], viewActive: false };
                        } else {
                            item.children[index] = { ...item.children[index], viewActive: true };
                        }
                    });

                    if (!item.children.some((ch) => ch.viewActive)) {
                        return (result = false);
                    } else {
                        item.children.forEach((ch, index) => {
                            if (!ch.viewActive) {
                                item.children.splice(index, 1);
                            }
                        });
                    }
                }
            });
            return { item, result };
        }
    }
};
