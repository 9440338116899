import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class USSD extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    checkUSSDStatus(body) {
        return this.post('v2/ussd/status', body);
    }
    setBlock(body) {
        return this.post('v2/ussd/create', body);
    }
    deleteBlock(body) {
        return this.post('v2/ussd/delete', body);
    }
}

export default USSD;
