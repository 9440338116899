var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templates"},[(_vm.requesting)?_c('progress-linear'):_c('div',[_c('div',{staticClass:"px-0 col-12 d-flex justify-content-between align-items-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('titles.stock_templates'))+" ")]),_c('b-button',{staticClass:"mb-2",attrs:{"to":{ name: 'stock-templates' },"variant":"outline-primary"}},[_vm._v(_vm._s(_vm.$t('button.back')))])],1),_c('ValidationObserver',{ref:"createUpdatePage"},[_c('b-row',[_c('div',{staticClass:"col-12 col-lg-4"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.real_title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.real_title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.real_title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.form.title.uz),callback:function ($$v) {_vm.$set(_vm.form.title, "uz", $$v)},expression:"form.title.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-4"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.real_title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.real_title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.real_title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.form.title.ru),callback:function ($$v) {_vm.$set(_vm.form.title, "ru", $$v)},expression:"form.title.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-4"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.real_title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.real_title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.real_title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.form.title.en),callback:function ($$v) {_vm.$set(_vm.form.title, "en", $$v)},expression:"form.title.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.form.description.uz),callback:function ($$v) {_vm.$set(_vm.form.description, "uz", $$v)},expression:"form.description.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.form.description.ru),callback:function ($$v) {_vm.$set(_vm.form.description, "ru", $$v)},expression:"form.description.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.form.description.en),callback:function ($$v) {_vm.$set(_vm.form.description, "en", $$v)},expression:"form.description.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.modal_title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.modal_title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.form.modal_title.uz),callback:function ($$v) {_vm.$set(_vm.form.modal_title, "uz", $$v)},expression:"form.modal_title.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.modal_title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.modal_title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.form.modal_title.ru),callback:function ($$v) {_vm.$set(_vm.form.modal_title, "ru", $$v)},expression:"form.modal_title.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.modal_title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.modal_title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.form.modal_title.en),callback:function ($$v) {_vm.$set(_vm.form.modal_title, "en", $$v)},expression:"form.modal_title.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.modal_description'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.modal_description')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.modal_description')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.form.modal_description.uz),callback:function ($$v) {_vm.$set(_vm.form.modal_description, "uz", $$v)},expression:"form.modal_description.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.modal_description'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.modal_description')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.modal_description')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.form.modal_description.ru),callback:function ($$v) {_vm.$set(_vm.form.modal_description, "ru", $$v)},expression:"form.modal_description.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.modal_description'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.modal_description')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.modal_description')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.form.modal_description.en),callback:function ($$v) {_vm.$set(_vm.form.modal_description, "en", $$v)},expression:"form.modal_description.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-6 mb-1"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.sms_template'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('titles.sms_template')))]),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":_vm.$t('choose.sms_template'),"id":"types","options":_vm.smsList,"reduce":function (option) { return option.name; },"label":"name","clearable":false},model:{value:(_vm.form.sms_template),callback:function ($$v) {_vm.$set(_vm.form, "sms_template", $$v)},expression:"form.sms_template"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-6 mb-1"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.ntf_template'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('titles.ntf_template')))]),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":_vm.$t('choose.notification_template'),"id":"types","options":_vm.notificationsList,"reduce":function (option) { return option.id; },"label":"title","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.title))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLang(option.title))+" ")]}}],null,true),model:{value:(_vm.form.ntf_template),callback:function ($$v) {_vm.$set(_vm.form, "ntf_template", $$v)},expression:"form.ntf_template"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.stock_category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('titles.stock_category')))]),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":_vm.$t('titles.stock_category'),"id":"types","options":_vm.categoriesList,"reduce":function (option) { return option.id; },"label":"name","clearable":false,"multiple":true},model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.start_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('titles.start_date')))]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                                enableTime: true,
                                enableSeconds: true,
                                allowInput: true,
                                time_24hr: true
                            },"placeholder":_vm.$t('choose.date'),"name":"date","id":"start_date","autocomplete":"off"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.end_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('titles.end_date')))]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                                enableTime: true,
                                enableSeconds: true,
                                allowInput: true,
                                time_24hr: true
                            },"placeholder":_vm.$t('choose.date'),"name":"date","id":"end_date","autocomplete":"off"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3 d-flex align-items-center justify-content-around"},[_c('b-col',[_c('label',{attrs:{"for":"is_active"}},[_vm._v(_vm._s(_vm.$t('titles.is_active')))]),_c('b-form-checkbox',{attrs:{"id":"is_active"},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1),_c('b-col',[_c('label',{attrs:{"for":"package_double"}},[_vm._v(_vm._s(_vm.$t('titles.package_double')))]),_c('b-form-checkbox',{attrs:{"id":"package_double"},model:{value:(_vm.form.package_double),callback:function ($$v) {_vm.$set(_vm.form, "package_double", $$v)},expression:"form.package_double"}})],1)],1),_c('div',{staticClass:"w-100 mb-4 mt-2"},[_c('label',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('titles.image')))]),_c('b-col',{attrs:{"cols":"12"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"accept":'image/*,.zip',"deletable":true,"errorText":{
                                type: _vm.$t('vue_file_agent.file_type_error'),
                                size: _vm.$t('vue_file_agent.size_type_error')
                            },"helpText":_vm.$t('vue_file_agent.choose_image'),"maxFiles":14,"maxSize":'10MB',"meta":true,"multiple":false,"theme":'grid'},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)},"select":function($event){return _vm.filesSelected($event)}},model:{value:(_vm.uploadImage),callback:function ($$v) {_vm.uploadImage=$$v},expression:"uploadImage"}}),_c('b-button',{staticClass:"float-right mt-1",attrs:{"disabled":!_vm.fileRecordsForUpload.length || _vm.imageUploaded,"variant":_vm.imageUploaded ? 'success' : 'primary'},on:{"click":function($event){return _vm.getImageId()}}},[(!_vm.imageUploaded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.upload_image'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.image_uploaded'))+" ")])])],1)],1),_c('div',{staticClass:"col-12 mb-4 d-flex justify-content-center"},[(!_vm.$route.params.id)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.createStockTemplate}},[_vm._v(_vm._s(_vm.$t('button.create')))]):_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.updateStockTemplate}},[_vm._v(_vm._s(_vm.$t('button.save')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }