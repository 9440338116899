export default {
    setActiveChatId(state, activeChatId) {
        state.chatMessages.activeChatId = activeChatId;
    },
    setChatResult(state, result) {
        state.chatMessages.result = [];
        for (let i = 0; i < result.length; i++) {
            state.chatMessages.result.push(result[i]);
        }
    },
    startMessagesUpdating(state) {
        state.chatMessages.updating = true;
    },
    finishMessagesUpdating(state) {
        state.chatMessages.updating = false;
    },
    chatMsgsStartFetching(state) {
        state.chats.isFetching = true;
    },
    chatMsgsFinishFetching(state) {
        state.chats.isFetching = false;
    },
    chatRefreshStartFetching(state) {
        state.chats.pending = true;
    },
    chatRefreshFinishFetching(state) {
        state.chats.pending = false;
    },
    contactsStartFetching(state) {
        state.contacts.isFetching = true;
    },
    contactsFinishFetching(state) {
        state.contacts.isFetching = false;
    },
    messagesStartFetching(state) {
        state.chatMessages.isFetching = true;
    },
    messagesFinishFetching(state) {
        state.chatMessages.isFetching = false;
    },
    chatTrigger(state, { result, pagination }) {
        state.chats.result = result;
        state.chats.pagination = pagination;
    },
    appendChats(state, { result, pagination }) {
        state.chats.pagination = pagination;
        for (let i = 0; i < result.length; i++) {
            state.chats.result.push(result[i]);
        }
    },
    contactTrigger(state, { result, pagination }) {
        state.contacts.result = result;
        state.contacts.pagination = pagination;
    },
    appendContacts(state, { result, pagination }) {
        state.contacts.pagination = pagination;
        for (let i = 0; i < result.length; i++) {
            state.contacts.result.push(result[i]);
        }
    },
    scrollChatViewToBottom(state) {
        const chViewContent = document.getElementById(state.chatMessages.chatMessagesContentId);
        if (chViewContent) {
            chViewContent.scrollTo(0, chViewContent.scrollHeight);
        }
    },
    addNewChat(state, { result }) {
        const hasBefore = state.chats.result.findIndex((ch) => ch.id === result.id);
        if (hasBefore === -1) {
            state.chats.result.unshift(result);
        }
    },
    setClientChatId(state, { clientId, chatId }) {
        const idx = state.contacts.result.findIndex((c) => c.id === clientId);
        if (idx !== -1) {
            state.contacts.result[idx].chat_id = chatId;
        }
    },
    clearChatUnreadCount(state, { id }) {
        const idx = state.chats.result.findIndex((ch) => ch.id === id);
        if (idx !== -1) {
            state.chats.result[idx].unread_count = 0;
        }
    },
    clearContactUnreadCount(state, { id }) {
        const idx = state.contacts.result.findIndex((ch) => ch.id === id);
        if (idx !== -1) {
            state.contacts.result[idx].unread_count = 0;
        }
    },
    initActiveClient(state, aContact) {
        state.activeChatContact = aContact;
    }
};
