import { getLocalStorageVariable } from '@/util/localstorage.util';
import router from '@/router/index';
import { showRequestErrorByToast } from '@/util/response.util';

export function axiosRequestInterceptResponse(config) {
    const lang = localStorage.getItem('locale');
    const requestConfig = Object.assign({}, config);
    if (!config.headers['Authorization'] && config.url !== 'oauth/login') {
        requestConfig.headers['Authorization'] = `Bearer ${getLocalStorageVariable(
            'storageTokenKeyName'
        )}`;
    }

    requestConfig.headers['Accept-Language'] = lang || 'ru';
    requestConfig.headers['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return requestConfig;
}

export async function axiosResponseInterceptorError(error) {
    if (error.response) {
        const { status } = error.response;
        if (status === 401) {
            if (router.currentRoute['name'] !== 'Auth Login') {
                return await router.push({
                    name: 'Auth Login'
                });
            }
        } else {
            showRequestErrorByToast(error);
        }
    }

    return Promise.reject(error);
}
