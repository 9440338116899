import { BFormCheckbox } from 'bootstrap-vue';

export default {
    components: { BFormCheckbox },
    data: () => ({
        optionsFilter: {
            observer: null,
            search: '',
            loading: false
        },
        optionsParams: {
            limit: 10,
            page: 1,
            next: 2
        },
        optionsTimeoutId: null
    }),
    computed: {
        hasNextPage() {
            return !!this.optionsParams.next;
        }
    },
    mounted() {
        this.optionsFilter.observer = new IntersectionObserver(this.infiniteScroll);
    },
    methods: {
        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick();
                this.optionsFilter.observer.observe(this.$refs['load-scroll']);
            }
        },
        onClose() {
            this.optionsFilter.observer.disconnect();
        },
        generatePagination(pagination) {
            this.optionsParams = {
                page: pagination.current || 1,
                limit: pagination.perPage || 10,
                next: pagination.next
            };
        }
    }
};
