<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import { checkLang } from '@/util/helper';

export default {
    name: 'ViewLevelBalanceStatistics',
    components: {
        vSelect,
        ProgressLinear,
        PageTitle,
        BTable,
        BRow,
        BCol,
        BPagination
    },
    data() {
        return {
            items: [],
            levelOptions: [],
            sort: {
                column: 'id',
                direction: 'desc'
            },
            search: '',
            filter: {
                user_id: null,
                level_id: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 30
            },
            merchantId: parseInt(MERCHANT_ID),
            requestPending: true
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllItems();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id')
                },
                {
                    key: 'balance',
                    label: this.$t('titles.balance')
                },
                {
                    key: 'level',
                    label: this.$t('titles.level')
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        }
    },

    async mounted() {
        await this.getLevels();
    },

    methods: {
        checkLang,
        checkLocales,
        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        async getAllItems() {
            this.requestPending = true;
            const body = {
                merchant_id: this.merchantId,
                page: this.pagination.current,
                limit: this.pagination.perPage,
                sort: this.sort,
                search: this.search,
                filters: {
                    user_id: parseInt(this.filter.user_id),
                    level_id: this.filter.level_id
                }
            };

            await api.newLevel
                .fetchAllLevelBalance(body)
                .then((response) => {
                    this.items = response.data.data.list;
                    this.pagination = response.data.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.requestPending = false;
                });
        },

        async getLevels() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevel.fetchAllItems(body);
                this.levelOptions = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        resetTheFilter() {
            this.filter.level_id = [];
            this.filter.user_id = null;
            this.getAllItems();
        }
    }
};
</script>

<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <page-title>
                <template #title> {{ $t('titles.level_balance') }}</template>
            </page-title>
        </div>

        <!--    FILTER    -->
        <div
            class="d-flex row flex-wrap flex-column flex-sm-row justify-content-between justify-content-lg-start mb-2"
        >
            <div
                class="col-12 px-0 d-flex flex-column flex-sm-row flex-wrap align-items-center align-items-lg-end"
            >
                <div class="col-12 col-sm-6 col-lg-5 mb-1 mb-lg-0">
                    <b-form-input
                        v-model="filter.user_id"
                        id="phone"
                        :placeholder="$t('titles.user_id')"
                    />
                </div>
                <div class="col-12 col-sm-6 col-lg-5 mb-1 mb-lg-0">
                    <v-select
                        id="categories"
                        v-model="filter.level_id"
                        :clearable="true"
                        :placeholder="$t('choose.level')"
                        :options="levelOptions"
                        :reduce="(option) => option.id"
                        label="name"
                        :multiple="true"
                    >
                        <template v-slot:option="option">
                            {{ checkLang(option.name) }}
                        </template>

                        <template v-slot:selected-option="option">
                            {{ checkLang(option.name) }}
                        </template>
                    </v-select>
                </div>
                <div
                    class="d-flex align-items-end justify-content-end col-12 col-lg-2 offset-md-0 mb-1 mb-lg-0"
                >
                    <b-button class="mr-1" @click="getAllItems" variant="primary">
                        <feather-icon icon="SearchIcon" size="20" />
                    </b-button>
                    <b-button @click="resetTheFilter" variant="danger">
                        <feather-icon icon="XIcon" size="20" />
                    </b-button>
                </div>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                :fields="fields"
                :items="items"
                :responsive="true"
                class="news__table"
                striped
                outlined
                borderless
            >
                <template #cell(level)="{ item }">
                    <span v-if="item.level">{{ checkLocales(item.level.name) }}</span>
                    <span v-else-if="item.level === null">null</span>
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-5"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.fw-700 {
    font-weight: 700;
}

@media only screen and (max-width: 575px) {
    .trigger-button {
        position: fixed;
        z-index: 1000;
        top: 95px;
        right: 28px;
    }
}

@media only screen and (min-width: 576px) {
    .trigger-button {
        position: fixed;
        z-index: 1000;
        top: 100px;
        right: 28px;
    }
}
</style>
