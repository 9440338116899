<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import VueApexCharts from 'vue-apexcharts';
import PageTitle from '@/components/PageTitle.vue';
import { formatNumber1 } from '@/util/number';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'AppNotificationStatistics',
    components: {
        ProgressLinear,
        PageTitle,
        apexchart: VueApexCharts
    },
    data() {
        return {
            readyArray: [],
            isPending: false,
            series: [],
            notificationChart: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    id: 'segmentation',
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                dataLabels: {
                    formatter: function (val) {
                        return formatNumber1(val);
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    inverseOrder: true,
                    intersect: false,
                    fixed: {
                        enabled: true,
                        position: 'bottomRight',
                        offsetX: 0,
                        offsetY: 300
                    },
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            return (
                                val +
                                ' - ' +
                                opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            );
                        }
                    }
                }
            }
        };
    },
    methods: {
        async getStatistics() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                start_date: '',
                end_date: ''
            };
            this.isPending = true;
            this.series.length = 0;
            this.notificationChart.xaxis.categories.length = 0;
            try {
                const { data } = await api.notificationTemplatesApi.fetchNotificationStatistics(
                    body
                );
                this.notificationChart.xaxis.categories = data.result
                    .reverse()
                    .map((item) => item.date.substring(5));
                this.series = data.result[0].list.map((item) => ({
                    name: item.service,
                    data: [item.count]
                }));
                data.result.slice(1).forEach((day) => {
                    day.list.forEach((item, i) => {
                        this.series[i].data.push(item.count);
                    });
                });
                // data.result.forEach((item) => {
                //     this.notificationChart.xaxis.categories.push(item.date.substring(5));
                //     item.list.map((childItem) => {
                //         console.log(childItem);
                //         // this.series.push({
                //         //     name: childItem.service,
                //         //     data: childItem.count
                //         // });
                //     });
                //     console.log(item, 'item');
                // });
            } catch (e) {
                console.error(e);
            } finally {
                this.isPending = false;
            }
        }
    },
    async mounted() {
        await this.getStatistics();
    }
};
</script>

<template>
    <div class="statistics">
        <page-title>
            <template #title>{{ $t('titles.notification_statistics') }}</template>
        </page-title>
        <div class="row">
            <div class="col-12">
                <progress-linear v-if="isPending" />
                <apexchart
                    v-else
                    type="bar"
                    height="350"
                    :options="notificationChart"
                    :series="series"
                ></apexchart>
            </div>
            <!--            <div class="col-12">-->
            <!--                <div class="badge-parent mt-1">-->
            <!--                    <b-badge-->
            <!--                        variant="light-secondary"-->
            <!--                        class="text-nowrap mb-1"-->
            <!--                        v-for="(item, index) in readyArray"-->
            <!--                        :key="index"-->
            <!--                    >-->
            <!--                        <span class="d-block"-->
            <!--                            >{{ item.service.toUpperCase() }}:-->
            <!--                            <b-badge variant="light-primary" style="font-size: 12px">{{-->
            <!--                                item.count-->
            <!--                            }}</b-badge></span-->
            <!--                        >-->
            <!--                    </b-badge>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<style scoped lang="scss">
.badge-parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;

    & span.text-nowrap {
        padding: 1rem 0;
    }
}

@media only screen and (max-width: 576px) {
    .badge-parent {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 0.5rem;
    }
}
</style>
