<script>
import { BButton } from 'bootstrap-vue';

export default {
    name: 'ShowHideInfo',
    components: {
        BButton
    },
    props: {
        value: String
    },
    data: () => ({
        display: false
    }),
    computed: {
        toggleIcon() {
            return this.display ? 'EyeIcon' : 'EyeOffIcon';
        },
        computedValue() {
            return this.display ? this.value : '***';
        }
    },
    methods: {
        toggleVisibility() {
            this.display = !this.display;
        }
    }
};
</script>

<template>
    <div class="d-flex align-items-center">
        {{ computedValue }}
        <feather-icon class="cursor-pointer ml-1" :icon="toggleIcon" @click="toggleVisibility" />
    </div>
</template>

<style scoped></style>
