import Core from '@/services/core';
import { axiosInstance } from '@/services/core/base';

class Roles extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }

    getPermissionsList() {
        return this.customGet(`${this.url}/permissions`);
    }
}

export default Roles;
