import Level from '@/views/new-level/level/index.vue';
import Prize from '@/views/new-level/prize/index.vue';
import Actions from '@/views/new-level/actions/index.vue';
import LevelStatistics from '@/views/new-level/level-statistics/index.vue';
import ViewLevelBalanceStatistics from '@/views/new-level/level-statistics/views/ViewLevelBalanceStatistics.vue';
import ViewLevelScoreStatistics from '@/views/new-level/level-statistics/views/ViewLevelScoreStatistics.vue';
import ViewLevelWinningsStatistics from '@/views/new-level/level-statistics/views/ViewLevelWinningsStatistics.vue';
export default [
    {
        path: '/levels/level',
        name: 'new-level',
        component: Level,
        meta: {
            section: 'levels',
            permission: 'view'
        }
    },
    {
        path: '/levels/prize',
        name: 'new-prize',
        component: Prize,
        meta: {
            section: 'levels_gifts',
            permission: 'view'
        }
    },
    {
        path: '/levels/actions',
        name: 'new-actions',
        component: Actions,
        meta: {
            section: 'levels_score',
            permission: 'view'
        }
    },
    {
        path: '/levels/statistics',
        name: 'new-level-statistics',
        component: LevelStatistics,
        meta: {
            section: 'collector',
            permission: 'view'
        },
        children: [
            {
                path: 'balance',
                name: 'new-level-statistics-balance',
                component: ViewLevelBalanceStatistics,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'score',
                name: 'new-level-statistics-score',
                component: ViewLevelScoreStatistics,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'winnings',
                name: 'new-level-statistics-winnings',
                component: ViewLevelWinningsStatistics,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            }
        ]
    }
];
