import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class HomeInvoice extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchTotal(body) {
        return this.post('statistics/invoice/total', body);
    }

    fetchExcel(body) {
        return this.post('statistics/invoice/excel', body);
    }

    fetchRevenue(body) {
        return this.post('statistics/invoice/revenue', body);
    }
}

export default HomeInvoice;
