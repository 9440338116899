<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';
import { fixTimeFrame } from '@/util/helper';
import vSelect from 'vue-select';

export default {
    name: 'AppStockHistory',
    components: {
        vSelect,
        ProgressLinear,
        PageTitle,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 25
            },
            interval: null,
            fetchTrigger: true,
            perPageList: [10, 25, 50, 100],
            requestPending: false
        };
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.interval);
        this.interval = null;

        next();
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                // this.pagination.perPage = query.perPage || 25;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                // {
                //     key: 'stock_id',
                //     label: this.$t('titles.stock_id')
                // },
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id')
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'category',
                    label: this.$t('titles.category')
                },
                {
                    key: 'push_send',
                    label: this.$t('titles.notification')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at')
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        if (this.fetchTrigger) {
            this.interval = setInterval(async () => {
                await this.getAll();
            }, 10000);
        }
    },

    methods: {
        checkLocales,
        fixTimeFrame,

        async triggerAutoFetching() {
            this.fetchTrigger = !this.fetchTrigger;

            if (!this.fetchTrigger) {
                clearInterval(this.interval);
                this.interval = null;
            } else {
                await this.getAll();
                this.interval = setInterval(async () => {
                    await this.getAll();
                }, 10000);
            }
        },

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.stockHistoryApi.fetchAllItems(body);
                this.items = data.result;
                this.pagination = data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <page-title>
                <template #title> {{ $t('titles.stock_history') }}</template>
            </page-title>
            <div class="d-flex align-items-center justify-content-end">
                <b-button
                    @click="triggerAutoFetching"
                    :variant="fetchTrigger ? 'outline-danger' : 'outline-success'"
                    class="mr-2"
                >
                    <feather-icon :icon="fetchTrigger ? 'PauseIcon' : 'PlayIcon'" />
                </b-button>
                Per page:
                <v-select
                    v-model="pagination.perPage"
                    :options="perPageList"
                    class="ml-2"
                    style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                />
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    <span>{{ item['prize_type'].toUpperCase() }} {{ item['prize_qty'] }}</span>
                </template>

                <template #cell(user_id)="{ item }">
                    <router-link
                        :to="{ name: 'subscriber-new-logger', params: { id: item.user_id } }"
                    >
                        {{ item.user_id }}
                    </router-link>
                </template>

                <template #cell(push_send)="{ item }">
                    <feather-icon
                        :icon="item['push_send'] ? 'CheckIcon' : 'XIcon'"
                        :class="item['push_send'] ? 'text-success' : 'text-danger'"
                    />
                </template>

                <template #cell(created_at)="{ item }">
                    {{ fixTimeFrame(item.created_at) }}
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-3"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

//.bordered__image-list {
//    border: 2px dashed #999999;
//
//    &_item {
//        position: relative;
//        width: 100px;
//        height: 100px;
//
//        &-close {
//            position: absolute;
//            display: flex;
//            align-items: center;
//            justify-content: center;
//            top: -5px;
//            right: -5px;
//            width: 16px;
//            height: 16px;
//            border-radius: 50%;
//            background-color: #999999;
//            color: #fff;
//            cursor: pointer;
//            font-size: 12px;
//        }
//
//        & img {
//            width: 100px;
//            height: 100%;
//            object-fit: cover;
//        }
//    }
//}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
