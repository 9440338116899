// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class KubeServersV2 extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'arsenal/dashboardGf/' : 'arsenal/dashboardAl/';
    }

    // SERVERS
    fetchAllItems(params) {
        return this.get(this.dashboardVersion() + 'server/getAll', { params });
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `server/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'server/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `server/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `server/delete/${id}`);
    }

    fetchConfigConstants(id) {
        return this.get(this.dashboardVersion() + `server/constant/${id}`);
    }

    cleanServerTemp(id) {
        return this.get(this.dashboardVersion() + `server/cleanTemp/${id}`);
    }

    //QUEUE TRICKS
    fetchAllTricks(params) {
        return this.get(this.dashboardVersion() + 'queueTrick/getAll', { params });
    }

    fetchOneTrick(id) {
        return this.get(this.dashboardVersion() + `queueTrick/get/${id}`);
    }

    createTrick(body) {
        return this.post(this.dashboardVersion() + 'queueTrick/create', body);
    }

    updateTrick(id, body) {
        return this.post(this.dashboardVersion() + `queueTrick/update/${id}`, body);
    }

    deleteTrick(id) {
        return this.delete(this.dashboardVersion() + `queueTrick/delete/${id}`, id);
    }

    //CLUSTER
    fetchAllClusters(params) {
        return this.get(this.dashboardVersion() + 'cluster/getAll', { params });
    }

    fetchOneCluster(id) {
        return this.get(this.dashboardVersion() + `cluster/get/${id}`);
    }

    createCluster(body) {
        return this.post(this.dashboardVersion() + 'cluster/create', body);
    }

    updateCluster(id, body) {
        return this.post(this.dashboardVersion() + `cluster/update/${id}`, body);
    }

    deleteCluster(id) {
        return this.delete(this.dashboardVersion() + `cluster/delete/${id}`);
    }

    // CONTROL
    fetchClusterList(params) {
        return this.get(this.dashboardVersion() + 'control/clusters', { params });
    }

    fetchQueueTrickList(params) {
        return this.get(this.dashboardVersion() + 'control/queueTricks', { params });
    }

    fetchServerStatusList(params) {
        return this.get(this.dashboardVersion() + 'control/servers', { params });
    }

    stoppingServer(id) {
        return this.post(this.dashboardVersion() + `control/serverStopping/${id}`);
    }

    startingServer(id) {
        return this.post(this.dashboardVersion() + `control/serverStarting/${id}`);
    }

    fetchPerformerList(id) {
        return this.post(this.dashboardVersion() + `control/serverPerformerList/${id}`);
    }

    fetchPerformerStatus(id, performerId) {
        return this.post(
            this.dashboardVersion() + `control/serverPerformerStatus/${id}/${performerId}`
        );
    }

    killProcess(id, performerId) {
        return this.post(
            this.dashboardVersion() + `control/serverPerformerKilling/${id}/${performerId}`
        );
    }

    killAllFlush(id) {
        return this.post(this.dashboardVersion() + `control/serverPerformerFlush/${id}`);
    }

    checkOrchestratorStatus() {
        return this.post(this.dashboardVersion() + 'orchestrator/status');
    }

    startOrchestrator() {
        return this.post(this.dashboardVersion() + 'orchestrator/starting');
    }

    stopOrchestrator() {
        return this.post(this.dashboardVersion() + 'orchestrator/stopping');
    }

    cleanQueueTricks(id) {
        return this.get(this.dashboardVersion() + `queueTrick/clean/${id}`);
    }

    startCluster(id) {
        return this.post(this.dashboardVersion() + `control/clusterStarting/${id}`);
    }

    stopCluster(id) {
        return this.post(this.dashboardVersion() + `control/clusterStopping/${id}`);
    }
}

export default KubeServersV2;
