var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.category_steps'))+" [Randomizer]")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(static_package)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.static_package.name.toUpperCase())+" ")]}},{key:"cell(packages)",fn:function(ref){
var item = ref.item;
return _vm._l((item.packages),function(child,index){return _c('b-badge',{key:index,staticClass:"table-badge__item"},[_c('span',[_vm._v(_vm._s(child.name.toUpperCase())+" "+_vm._s(child.qty))])])})}},{key:"cell(is_internal)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:[item.is_internal ? 'text-success' : 'text-danger'],attrs:{"icon":item.is_internal ? 'CheckIcon' : 'XIcon',"size":"20"}})]}},{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":item.is_active,"name":"check-button","switch":""},on:{"input":function($event){return _vm.changeStatus(item)}}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.subscription') : _vm.$t('add.subscription'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.step')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.step'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.step'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.step),callback:function ($$v) {_vm.$set(_vm.dataForModal, "step", $$v)},expression:"dataForModal.step"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('titles.categories'),"label-for":"categories"}},[_c('v-select',{attrs:{"id":"categories","disabled":_vm.isViewModal,"options":_vm.categoriesList,"reduce":function (option) { return option.id; },"placeholder":_vm.$t('choose.category'),"label":"name","multiple":""},model:{value:(_vm.dataForModal.category_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "category_id", $$v)},expression:"dataForModal.category_id"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('titles.packages'),"label-for":"categories"}},[_c('v-select',{attrs:{"id":"categories","disabled":_vm.isViewModal,"options":_vm.packagesList,"reduce":function (option) { return option.id; },"placeholder":_vm.$t('choose.package'),"label":"name","multiple":""},model:{value:(_vm.dataForModal.category_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "category_id", $$v)},expression:"dataForModal.category_id"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }