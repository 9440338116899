export default [
    {
        path: '/levels',
        name: 'Levels',
        component: () => import('@/views/levels/Levels.vue'),
        meta: {
            section: 'levels',
            permission: 'view'
        }
    },
    {
        path: '/levels/tournaments',
        name: 'Levels Gifts',
        component: () => import('@/views/levels/LevelsGifts.vue'),
        meta: {
            section: 'levels_gifts',
            permission: 'view'
        }
    },
    {
        path: '/levels/score',
        name: 'Levels Score',
        component: () => import('@/views/levels/LevelsScore.vue'),
        meta: {
            section: 'levels_score',
            permission: 'view'
        }
    },
    {
        path: '/levels/winners',
        name: 'Levels Winners',
        component: () => import('@/views/levels/LevelsWinners.vue'),
        meta: {
            section: 'winners',
            permission: 'view'
        }
    }
];
