<script>
import api from '@/services/api';
// import { MERCHANT_ID } from '@/constants/names';
import VueApexCharts from 'vue-apexcharts';
import PageTitle from '@/components/PageTitle.vue';
// import { formatNumber1 } from '@/util/number';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import { formatNumber1 } from '@/util/number';
import { statisticsFormatDate } from '@/util/date/calendar.util';

export default {
    name: 'AppFlashStatistics',
    components: {
        BTable,
        BRow,
        BPagination,
        BCol,
        ProgressLinear,
        PageTitle,
        apexchart: VueApexCharts
    },
    data() {
        return {
            isPending: false,
            start_date_monthly: null,
            end_date_monthly: null,
            generalSeries: [],
            generalSeriesSum: [],
            productSeries: [],
            generalChart: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    inverseOrder: true,
                    intersect: false,
                    // fixed: {
                    //     enabled: true,
                    //     position: 'bottomRight',
                    //     offsetX: 0,
                    //     offsetY: 300
                    // },
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            return (
                                val +
                                ' - ' +
                                opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            );
                        }
                    }
                }
            },
            generalChartSum: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                dataLabels: {
                    formatter: function (val) {
                        return formatNumber1(val);
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    inverseOrder: true,
                    intersect: false,
                    // fixed: {
                    //     enabled: true,
                    //     position: 'bottomRight',
                    //     offsetX: 0,
                    //     offsetY: 300
                    // },
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            return (
                                val +
                                ' - ' +
                                opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            );
                        }
                    }
                }
            },
            productsChart: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    inverseOrder: true,
                    intersect: false,
                    // fixed: {
                    //     enabled: true,
                    //     position: 'bottomRight',
                    //     offsetX: 0,
                    //     offsetY: 300
                    // },
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            return (
                                val +
                                ' - ' +
                                opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            );
                        }
                    }
                }
            },
            items: [],
            bestsellerItems: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 30
            },
            perPageList: [10, 30, 50, 100]
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                console.log(query);
                this.pagination.current = query.page || 1;
                // this.pagination.perPage = query.perPage || 30;
                this.getBestUserStatistics();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'phone',
                    label: this.$t('titles.phone_number')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                }
            ];
        },

        bestsellerFields() {
            return [
                {
                    key: 'gift',
                    label: this.$t('titles.gifts')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        statisticsFormatDate,

        async fixMonthlyStatisticsDates() {
            // const date = new Date();
            // const year = date.getUTCFullYear(); // Get the full year (4 digits)
            // const currentMonth = date.getUTCMonth() + 1; // Month is zero-based, so add 1
            // const beforeMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Adjust if current month is January
            // const beforeYear = currentMonth === 1 ? year - 1 : year; // Adjust year accordingly

            // const pad = (number) => String(number).padStart(2, '0'); // Padding function

            // const day = pad(date.getUTCDate()); // Pad day with leading zero if necessary
            // const formattedBeforeMonth = pad(beforeMonth); // Pad beforeMonth with leading zero if necessary
            // const formattedCurrentMonth = pad(currentMonth); // Pad currentMonth with leading zero if necessary

            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.start_date_monthly = this.statisticsFormatDate(date_start);
            this.end_date_monthly = this.statisticsFormatDate(date_end);
        },

        async getGeneralStatistics() {
            const body = {
                // merchant_id: parseInt(MERCHANT_ID),
                date_start: this.start_date_monthly,
                date_end: this.end_date_monthly
            };
            this.generalSeries.length = 0;
            this.generalSeriesSum.length = 0;
            this.generalChart.xaxis.categories.length = 0;
            this.generalChartSum.xaxis.categories.length = 0;
            try {
                const { data } = await api.flashStatisticsApi.fetchGeneralStatistics(body);
                data.data.forEach((item) => {
                    this.generalChart.xaxis.categories.push(item.date.substring(5));
                    this.generalChartSum.xaxis.categories.push(item.date.substring(5));

                    // QTY
                    item.services.forEach((childItem) => {
                        const existingService = this.generalSeries.find(
                            (s) => s.name === childItem.name
                        );
                        if (existingService) {
                            existingService.data.push(childItem.qty);
                        } else {
                            this.generalSeries.push({
                                name: childItem.name,
                                data: [childItem.qty]
                            });
                        }
                    });

                    // SUM
                    item.services.forEach((childItem) => {
                        const existingService = this.generalSeriesSum.find(
                            (s) => s.name === childItem.name
                        );
                        if (existingService) {
                            existingService.data.push(childItem.amount);
                        } else {
                            this.generalSeriesSum.push({
                                name: childItem.name,
                                data: [childItem.amount]
                            });
                        }
                    });
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getProductStatistics() {
            const body = {
                // merchant_id: parseInt(MERCHANT_ID),
                date_start: this.start_date_monthly,
                date_end: this.end_date_monthly
            };
            this.productSeries.length = 0;
            this.productsChart.xaxis.categories.length = 0;
            try {
                const { data } = await api.flashStatisticsApi.fetchProductStatistics(body);
                data.data.forEach((item) => {
                    this.productsChart.xaxis.categories.push(item.date.substring(5));
                    item.types.forEach((childItem) => {
                        const existingService = this.productSeries.find(
                            (s) => s.name === childItem.name
                        );
                        if (existingService) {
                            existingService.data.push(childItem.qty);
                        } else {
                            this.productSeries.push({
                                name: childItem.name,
                                data: [childItem.qty]
                            });
                        }
                    });
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getBestSellerStatistics() {
            const body = {
                page: 1,
                limit: 1000,
                date_start: '',
                date_end: ''
            };
            try {
                const { data } = await api.flashStatisticsApi.fetchBestSellerStatistics(body);
                this.bestsellerItems = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getBestUserStatistics() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                date_start: '',
                date_end: ''
            };
            try {
                const { data } = await api.flashStatisticsApi.fetchBestUserStatistics(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    },

    async mounted() {
        this.isPending = true;
        await this.fixMonthlyStatisticsDates();
        await this.getGeneralStatistics();
        await this.getProductStatistics();
        await this.getBestSellerStatistics();
        // await this.getBestUserStatistics();
        this.isPending = false;
    }
};
</script>

<template>
    <div class="statistics">
        <div class="row">
            <!--      GENERAL STATISTICS [QTY]      -->
            <div class="col-12 mb-2">
                <page-title>
                    <template #title>{{ $t('titles.general_statistics') }} [Quantity]</template>
                </page-title>
                <progress-linear v-if="isPending" />
                <apexchart
                    v-else
                    type="bar"
                    height="350"
                    :options="generalChart"
                    :series="generalSeries"
                ></apexchart>
            </div>

            <!--      GENERAL STATISTICS [SUM]      -->
            <div class="col-12 mb-2">
                <page-title>
                    <template #title>{{ $t('titles.general_statistics') }} [Sum]</template>
                </page-title>
                <progress-linear v-if="isPending" />
                <apexchart
                    v-else
                    type="bar"
                    height="350"
                    :options="generalChartSum"
                    :series="generalSeriesSum"
                ></apexchart>
            </div>

            <!--      PRODUCTS STATISTICS      -->
            <div class="col-12 mb-2">
                <page-title>
                    <template #title>{{ $t('titles.products_statistics') }}</template>
                </page-title>
                <progress-linear v-if="isPending" />
                <apexchart
                    v-else
                    type="bar"
                    height="350"
                    :options="productsChart"
                    :series="productSeries"
                ></apexchart>
            </div>

            <!--  BEST SELLERS TABLE  -->
            <div class="col-6">
                <page-title>
                    <template #title>{{ $t('titles.best_sellers') }}</template>
                </page-title>
                <div class="card">
                    <progress-linear v-if="isPending" />
                    <b-table
                        v-else
                        :fields="bestsellerFields"
                        :items="bestsellerItems"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    />
                </div>
            </div>

            <!--  BEST USERS TABLE  -->
            <div class="col-6">
                <page-title>
                    <template #title>{{ $t('titles.best_users') }}</template>
                </page-title>
                <div class="card">
                    <progress-linear v-if="isPending" />
                    <b-table
                        v-else
                        :fields="fields"
                        :items="items"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    />
                </div>

                <!--  PAGINATION  -->
                <b-row>
                    <b-col class="offset-3" cols="9">
                        <b-pagination
                            v-if="showPagination"
                            v-model="pagination.current"
                            :per-page="pagination.perPage"
                            :total-rows="pagination.totalItem"
                            class="float-right mr-4 mb-3"
                            first-number
                            last-number
                        />
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.badge-parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;

    & span.text-nowrap {
        padding: 1rem 0;
    }
}

@media only screen and (max-width: 576px) {
    .badge-parent {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 0.5rem;
    }
}
</style>
