import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class CardsService extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/cards/findAll', body);
    }

    fetchAllItemsWithoutPagination(body) {
        return this.post('v2/cards/list', body);
    }

    fetchOneItem(body) {
        return this.post('v2/cards/findOne', body);
    }

    createItem(body) {
        return this.post('v2/cards', body);
    }

    updateItem(body) {
        return this.post('v2/cards/update', body);
    }

    deleteItem(body) {
        return this.post('v2/cards/remove', body);
    }

    fetchTypes(body) {
        return this.post('v2/cards/type', body);
    }
}

export default CardsService;
