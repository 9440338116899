export default [
    {
        path: '/paynet',
        name: 'Paynet Index',
        component: () => import('@/views/paynet/PaynetIndex'),
        children: [
            {
                path: '/paynet/statistics',
                name: 'paynet Statistics',
                component: () => import('@/views/paynet/PaynetStatistics')
            },
            {
                path: '/paynet/services',
                name: 'paynet Services',
                component: () => import('@/views/paynet/PaynetServices')
            },
            {
                path: '/paynet/transactions',
                name: 'paynet Transactions',
                component: () => import('@/views/paynet/PaynetTransactions')
            }
        ]
    }
];
