<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';

export default {
    name: 'AppSegmentationDetailRatePlan',
    components: { BRow, BCol, BPagination, BTable, ProgressLinear },
    data() {
        return {
            requestPending: false,
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            availableTariffsList: []
        };
    },
    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getBillingTariffs();
                this.getSegmentationRatePlan();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'rate_plan_id',
                    label: 'ID'
                },
                {
                    key: 'rate_plan_name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        async getBillingTariffs() {
            const body = {
                page: 1,
                limit: 1000
            };
            try {
                const { data } = await api.billingRate.fetchAllItems(body);
                this.availableTariffsList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getSegmentationRatePlan() {
            this.requestPending = true;
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID),
                event_id: parseInt(this.$route.params.id)
            };
            try {
                const { data } = await api.segmentationApi.fetchDetailRatePlan(body);
                this.items = data.data.list;
                this.items.forEach((item1) => {
                    let matchedItem = this.availableTariffsList.find(
                        (item2) => item1.rate_plan_id === item2.uid
                    );
                    if (matchedItem) {
                        item1.rate_plan_name = matchedItem.name;
                    }
                });
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div class="rate-plan">
        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            />
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style scoped lang="scss"></style>
