import AppWalletBalance from '@/views/wallet-balance/AppWalletBalance.vue';

export default [
    {
        path: '/wallet-balance',
        name: 'wallet-balance',
        component: AppWalletBalance,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
