export default {
    mounted() {
        this.pastImage();
    },
    methods: {
        pastImage() {
            const imagesList = [];
            document.addEventListener('paste', async (e) => {
                const clipboardData = e.clipboardData || window.clipboardData;

                const items = clipboardData.items;
                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf('image') !== -1) {
                        imagesList.unshift(items[i].getAsFile());
                    }
                }

                await navigator.clipboard.readText().then(async (cText) => {
                    if (cText.startsWith('http') || this.isImage(cText)) {
                        const image = await this.downloadImage(cText);
                        if (image) {
                            imagesList.unshift(image);
                        }
                    }
                });

                if (this.$refs?.vueFileAgent) {
                    if (!this.$refs.vueFileAgent.multiple) {
                        this.$refs.vueFileAgent.fileRecords = [];
                        this.$refs.vueFileAgent.rawFileRecords = [];
                    }
                    this.$refs.vueFileAgent.handleFiles(imagesList);
                }
            });
            return imagesList;
        },
        async downloadImage(url) {
            return await fetch(url, { mode: 'cors' })
                .then((response) => response.blob())
                .then((blob) => {
                    if (blob.type.startsWith('image/')) {
                        const imageName = new Date().getTime();
                        return new File([blob], `${imageName}`, { type: blob.type });
                    }
                    return false;
                });
        },
        isImage(url) {
            return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
        }
    }
};
