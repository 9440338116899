import { axiosV5 } from '@/services/core/base';
import { MERCHANT_ID } from '@/constants/names';
import CoreV2 from '@/services/core/coreV2';

class Subscription extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2
            ? 'subscription/dashboardGf/'
            : 'subscription/dashboardAl/';
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'subscription/getAll', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `subscription/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'subscription/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `subscription/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `subscription/delete/${id}`);
    }

    updateItemStatus(id, body) {
        return this.post(this.dashboardVersion() + `subscription/status/${id}`, body);
    }
}

export default Subscription;
