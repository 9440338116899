import { axiosOriginal } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class BundleService extends CoreV2 {
    constructor() {
        super(axiosOriginal());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2
            ? 'v2/randomizer/dashboardGf/'
            : 'v2/randomizer/dashboardAl/';
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'bundle/getAll', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `bundle/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'bundle/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `bundle/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `bundle/delete/${id}`);
    }
}

export default BundleService;
