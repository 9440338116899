import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class NewLevelPrize extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/level-prize/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('v2/level-prize/findOne', body);
    }

    createItem(body) {
        return this.post('v2/level-prize', body);
    }

    updateItem(body) {
        return this.post('v2/level-prize/update', body);
    }

    deleteItem(body) {
        return this.post('v2/level-prize/remove', body);
    }
}

export default NewLevelPrize;
