import { axiosInstanceV2, axiosInstanceV3 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Coin extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    // MERCHANT
    // COIN MERCHANT FETCH ALL
    fetchCoinMerchants(params) {
        return this.post('merchant/findAll', params);
    }

    // COIN MERCHANT CREATE
    createCoinMerchant(body) {
        return this.post('merchant', body);
    }

    // COIN MERCHANT UPDATE
    updateCoinMerchant(body) {
        return this.post('merchant/update', body);
    }

    // AWARD
    // COIN AWARD FETCH ALL
    fetchCoinAwards(params) {
        return this.post('award/findAll', params);
    }

    // COIN AWARD CREATE
    createAward(body) {
        return this.post('award', body);
    }

    // COIN AWARD UPDATE
    updateCoinAward(body) {
        return this.post('award/update', body);
    }

    // COIN AWARD DELETE
    deleteCoinAward(params) {
        return this.post('award/remove', params);
    }

    // AWARD
    // COIN AWARDTYPE FETCH ALL
    fetchCoinTypeAwards(params) {
        return this.post('awardType/findAll', params);
    }

    // COIN AWARD CREATE
    createAwardType(body) {
        return this.post('awardType', body);
    }

    // COIN AWARD UPDATE
    updateCoinAwardType(body) {
        return this.post('awardType/update', body);
    }

    // COIN AWARD DELETE
    deleteCoinAwardType(params) {
        return this.post('awardType/remove', params);
    }

    // MEASUREMENT
    // COIN MEASUREMENT FETCH ALL
    fetchCoinMeasurements(params) {
        return this.post('measurement/findAll', params);
    }

    createCoinMeasurement(body) {
        return this.post('measurement', body);
    }

    updateCoinMeasurement(body) {
        return this.post('measurement/update', body);
    }

    deleteCoinMeasurement(body) {
        return this.post('measurement/remove', body);
    }

    // PRODUCT
    // COIN PRODUCT FETCH ALL
    fetchCoinProducts(params) {
        return this.post('product/findAll', params);
    }

    fetchCoinProduct(params) {
        return this.post('product/findOne', params);
    }

    createCoinProduct(body) {
        return this.post('product', body);
    }

    updateCoinProduct(body) {
        return this.post('product/update', body);
    }

    deleteCoinProduct(body) {
        return this.post('product/remove', body);
    }

    // COIN BONUS
    fetchCoinBonuses(params) {
        return this.post('specialProduct/findAll', params);
    }

    fetchBonusTypes(params) {
        return this.post('specialProduct/types', params);
    }

    fetchBonusDurationTypes(params) {
        return this.post('specialProduct/durations', params);
    }

    fetchCoinBonus(params) {
        return this.post('specialProduct/findOne', params);
    }

    createCoinBonus(body) {
        return this.post('specialProduct', body);
    }

    updateCoinBonus(body) {
        return this.post('specialProduct/update', body);
    }

    deleteCoinBonus(body) {
        return this.post('specialProduct/remove', body);
    }

    // order
    getCoinOrdersList(body) {
        return this.post('order/findAll', body);
    }

    getUserCoinOrdersHistory(body) {
        return this.post('order/userHistory', body);
    }

    getCoinOrder(body) {
        return this.post('order/findOne', body);
    }

    updateCoinOrder(body) {
        return this.post('order/update', body);
    }

    deleteCoinOrder(body) {
        return this.post('order/remove', body);
    }

    fetchCoinStatus(body) {
        return this.post('order/statuses', body);
    }

    fetchWarehouseProducts(body) {
        return this.post('product/warehouseProducts', body);
    }

    //MONITORING
    fetchTransactionList(body) {
        // body['merchant_id'] = MERCHANT_ID;
        return this.post('transactions/findAll', body);
    }

    // fetchTransactionTotal(body) {
    //     return this.post('transactions/total', body);
    // }

    // COIN ANALYTICS
    fetchCardsAmount(body) {
        return this.post('analytics/cards', body);
    }

    fetchCardHistories(body) {
        return this.post('analytics/cardHistories', body);
    }

    fetchNewList(body) {
        return this.post('analytics/newList', body);
    }

    fetchProcessingList(body) {
        return this.post('analytics/processingList', body);
    }

    fetchStatistics(body) {
        return this.post('analytics/statistics', body);
    }

    fetchTransactionFilter() {
        return this.post('transactions/filter-fields');
    }

    getSubscribeCoinBalance(body) {
        return this.post('transactions/balance', body);
    }

    getSubscribeCoinHistory(body) {
        return this.post('transactions/userHistory', body);
    }

    plusMinusCoinToUser(body) {
        return this.post('transactions/create', body);
    }

    getTournamentPolicyStatistics(body) {
        return this.post('policy/tournamentPolicyStatistics', body);
    }

    getTournamentPolicyTotal(body) {
        return this.post('policy/tournamentPolicyTotal', body);
    }

    fetchNewLogsList(body) {
        return this.post('journal/getUserEvents', body);
    }

    fetchRegistrationStatistics(body) {
        return this.post('journal/registrationStatistics', body);
    }

    fetchRecordLogsList(body) {
        return this.post('record/read', body);
    }

    // fetchBonusProductPackages(id, params) {
    //     return this.get(`get/packages?merchant_id=${id}`, params);
    // }
}

export default Coin;
