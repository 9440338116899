export default [
    {
        path: '/coin',
        name: 'coin',
        component: () => import('@/views/coin/index.vue'),
        children: [
            {
                path: '/coin/merchant',
                name: 'coin-merchant',
                component: () => import('@/views/coin/AppCoinMerchant.vue'),
                meta: {
                    section: 'merchant',
                    permission: 'view'
                }
            },
            {
                path: '/coin/awards',
                name: 'coin-awards',
                component: () => import('@/views/coin/AppCoinAward.vue')
            },
            {
                path: '/coin/award/types',
                name: 'coin-award-types',
                component: () => import('@/views/coin/AppCoinAwardType.vue')
            },
            {
                path: '/coin/measurement',
                name: 'coin-measurement',
                component: () => import('@/views/coin/AppCoinMeasurement.vue')
            },
            {
                path: '/coin/products',
                component: () => import('@/views/coin/product/index.vue'),
                children: [
                    {
                        name: 'coin-product',
                        path: '/coin/products/product',
                        component: () => import('@/views/coin/product/AppCoinProduct.vue')
                    },
                    {
                        name: 'coin-product-bonus',
                        path: '/coin/products/bonus',
                        component: () => import('@/views/coin/product/AppCoinBonus.vue')
                    }
                ]
            }, // {
            //     path: '/coin/product',
            //     name: 'coin-product',
            //     component: () => import('@/views/coin/product/AppTournamentProduct.vue')
            // },
            {
                path: '/coin/bonus',
                name: 'coin-bonus',
                component: () => import('@/views/coin/product/AppCoinBonus.vue')
            },
            {
                path: '/coin/product-show/:id?',
                name: 'coin-product-show',
                component: () => import('@/views/coin/product/MarketProduct.vue')
            },
            {
                path: '/coin/bonus-show/:id?',
                name: 'coin-bonus-show',
                component: () => import('@/views/coin/product/LevelProduct.vue')
            },
            {
                path: '/coin/monitoring',
                name: 'coin-monitoring',
                component: () => import('@/views/coin/monitoring/CoinActivities.vue')
            },
            {
                path: '/coin/market-policy',
                name: 'market-policy',
                component: () => import('@/views/market-policy/MarketPolicyIndex.vue')
            },
            {
                path: '/coin/market-policy-show/:id?',
                name: 'market-policy-show',
                component: () => import('@/views/market-policy/MarketPolicyCRUD.vue')
            },
            {
                path: '/coin/analytics',
                name: 'coin-analytics',
                component: () => import('@/views/coin/analytics/AppCoinAnalytics.vue')
            }
            // {
            //     path: '/coin/monitoring',
            //     name: 'coin-monitoring',
            //     component: () => import('@/views/coin/monitoring/CoinActivity.vue')
            // }
        ]
    }
];
