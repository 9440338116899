<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    fixTimeFrame,
    generateIconColorToBoolean,
    generateIconToBoolean
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';
import { formatDateWithFiveHours } from '@/util/date/calendar.util';
import api from '@/services/api';
import { checkLocales } from '../../util/lang';

export default {
    name: 'AppNotificationHistory',
    components: {
        vSelect,
        ProgressLinear,
        PageTitle,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 25
            },
            perPageList: [25, 50, 75, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            gameList: [],
            filter: {
                direction: 'DESC'
            },
            directionList: ['ASC', 'DESC'],
            requestingInterval: null
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getNotificationHistories();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },

    async mounted() {
        this.requestingInterval = setInterval(async () => {
            await this.getNotificationHistories();
        }, 7500);
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.real_title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'service',
                    label: this.$t('titles.service')
                },
                {
                    key: 'link',
                    label: this.$t('titles.link')
                },
                {
                    key: 'image',
                    label: this.$t('titles.image')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.gained_at')
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        fixTimeFrame,
        checkLocales,
        formatDateWithFiveHours,
        checkLang,

        async getNotificationHistories() {
            const params = {
                merchant_id: parseInt(MERCHANT_ID),
                page: this.pagination.current,
                limit: this.pagination.perPage,
                sort: {
                    property: 'id',
                    direction: this.filter.direction
                }
            };
            const { data } = await api.notificationTemplatesApi.fetchNotificationHistory(params);
            this.items = data.result;
            this.pagination = data.pagination;
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getNotificationHistories();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.requestingInterval);
        this.requestingInterval = null;

        next();
    }
};
</script>

<template>
    <div>
        <div class="d-sm-flex align-items-center justify-content-between">
            <!--    v-if="hasAccess('coin_award','view')"-->
            <div class="d-flex align-items-center">
                <page-title>
                    <template #title> {{ $t('titles.notification_history') }}</template>
                </page-title>

                <!--                <b-form-input-->
                <!--                    v-model="dataForModal.qty"-->
                <!--                    :placeholder="`${$t('enter.qty')}`"-->
                <!--                    size="md"-->
                <!--                    style="width: 200px; margin-left: 1rem; margin-bottom: 1.5rem"-->
                <!--                />-->

                <!--                <v-select-->
                <!--                    id="property"-->
                <!--                    v-model="filter.game_id"-->
                <!--                    :options="gameList"-->
                <!--                    append-to-body-->
                <!--                    class="ml-2 mb-2"-->
                <!--                    style="width: 200px"-->
                <!--                    @input="getScoreHistories"-->
                <!--                    :clearable="true"-->
                <!--                    placeholder="Select a game"-->
                <!--                    label="name"-->
                <!--                />-->

                <!--                <v-select-->
                <!--                    id="property"-->
                <!--                    v-model="filter.property"-->
                <!--                    :options="propertyList"-->
                <!--                    append-to-body-->
                <!--                    class="ml-2 mb-2"-->
                <!--                    style="min-width: 200px"-->
                <!--                    :clearable="true"-->
                <!--                    @input="getScoreHistories"-->
                <!--                    placeholder="Select a property"-->
                <!--                    label="key"-->
                <!--                />-->
            </div>

            <div class="d-flex align-items-center mb-2 mb-sm-0">
                <v-select
                    id="property"
                    v-model="filter.direction"
                    :options="directionList"
                    append-to-body
                    class="mr-2"
                    style="width: 200px"
                    @input="getNotificationHistories"
                    :clearable="true"
                    placeholder="Select a direction"
                />
                <div class="d-flex align-items-center">
                    <div>Per page:</div>
                    <v-select
                        v-model="pagination.perPage"
                        :options="perPageList"
                        class="ml-2"
                        style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                    />
                </div>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                no-border-collapse
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(title)="{ item }">
                    <span>{{ checkLocales(item.title) }}</span>
                </template>

                <template #cell(description)="{ item }">
                    <span class="line__break">{{ checkLocales(item.description) }}</span>
                </template>

                <template #cell(created_at)="{ item }">
                    <span>{{ fixTimeFrame(item.created_at) }}</span>
                </template>

                <template #cell(image)="{ item }">
                    <img class="table-img" v-if="item && item.image" :src="item.image.path" />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

* {
    font-size: 16px;
}

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.fw-700 {
    font-weight: 700;
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.line__break {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
}
</style>
