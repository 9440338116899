<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BFormInput, BPagination, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import services from '@/services';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';
import { formatDateWithFiveHours } from '@/util/date/calendar.util';

export default {
    name: 'AppScoreHistory',
    components: {
        vSelect,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BCol,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                values: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                name: ''
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 25
            },
            perPageList: [25, 50, 75, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            gameList: [],
            filter: {
                user_id: null,
                game_id: null,
                property: null,
                direction: 'DESC'
            },
            directionList: ['ASC', 'DESC']
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getScoreHistories();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id'),
                    stickyColumn: true,
                    variant: 'dark'
                },
                {
                    key: 'first_name',
                    label: this.$t('titles.fio')
                },
                {
                    key: 'game_name',
                    label: this.$t('titles.game')
                },
                {
                    key: 'score',
                    label: this.$t('titles.score')
                },
                {
                    key: 'coin_gained',
                    label: this.$t('titles.coin_gained')
                },
                {
                    key: 'time_played',
                    label: this.$t('game_monitoring.duration')
                },
                {
                    key: 'limit_over',
                    label: this.$t('game_monitoring.limit_over')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.gained_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        propertyList() {
            return [
                {
                    key: this.$t('score_history.score'),
                    value: 'score'
                },
                {
                    key: this.$t('score_history.created_at'),
                    value: 'created_at'
                }
            ];
        },
        query() {
            console.log(this.$route.query);
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getGamesList();
    },

    methods: {
        formatDateWithFiveHours,
        checkLang,

        async getScoreHistories() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.filter.user_id,
                game_id: this.filter.game_id,
                sort: {
                    property: this.filter.property,
                    direction: this.filter.direction
                }
            };
            if (params.game_id) {
                params.game_id = params.game_id.id;
            }
            if (params.sort.property) {
                params.sort.property = params.sort.property.value;
            }

            const { data } = await services.scoreHistory.findAll(params);
            this.items = data.result;
            this.pagination = data.pagination;
        },

        async getGamesList() {
            const body = {
                page: 1,
                limit: 30,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const { data } = await services.games.findAll(body);
            this.gameList = data.result.map((item) => {
                return { id: item.id, name: item.name };
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateSMS(body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createSMS(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getSMS();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <div class="d-sm-flex align-items-center justify-content-between">
            <!--    v-if="hasAccess('coin_award','view')"-->
            <div class="d-flex align-items-center">
                <page-title>
                    <template #title> {{ $t('titles.score_history') }}</template>
                </page-title>

                <!--                <b-form-input-->
                <!--                    v-model="dataForModal.qty"-->
                <!--                    :placeholder="`${$t('enter.qty')}`"-->
                <!--                    size="md"-->
                <!--                    style="width: 200px; margin-left: 1rem; margin-bottom: 1.5rem"-->
                <!--                />-->

                <v-select
                    id="property"
                    v-model="filter.game_id"
                    :options="gameList"
                    append-to-body
                    class="ml-2 mb-2"
                    style="width: 200px"
                    @input="getScoreHistories"
                    :clearable="true"
                    placeholder="Select a game"
                    label="name"
                />

                <v-select
                    id="property"
                    v-model="filter.property"
                    :options="propertyList"
                    append-to-body
                    class="ml-2 mb-2"
                    style="min-width: 200px"
                    :clearable="true"
                    @input="getScoreHistories"
                    placeholder="Select a property"
                    label="key"
                />

                <v-select
                    id="property"
                    v-model="filter.direction"
                    :options="directionList"
                    append-to-body
                    class="ml-2 mb-2"
                    style="width: 200px"
                    @input="getScoreHistories"
                    :clearable="true"
                    placeholder="Select a direction"
                />
            </div>

            <div class="d-flex align-items-center justify-content-end mb-2 mb-sm-0">
                Per page:
                <v-select
                    v-model="pagination.perPage"
                    :options="perPageList"
                    class="ml-2"
                    style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                />
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                no-border-collapse
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(user_id)="{ item }">
                    <span class="fw-700 text-white">{{ item.user_id }}</span>
                </template>

                <template #cell(game_name)="{ item }">
                    <span class="fw-700">{{ item.game_name }}</span>
                </template>

                <template #cell(first_name)="{ item }">
                    <span>{{ item.first_name }} {{ item.last_name }}</span>
                </template>

                <template #cell(time_played)="{ item }">
                    <span>{{ item.time_played }} {{ $t('titles.second') }}</span>
                </template>

                <template #cell(coin_gained)="{ item }">
                    <feather-icon
                        :class="[item.coin_gained ? 'text-primary' : 'text-danger']"
                        :icon="item.coin_gained ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(limit_over)="{ item }">
                    <feather-icon
                        :class="[item.coin_gained ? 'text-primary' : 'text-danger']"
                        :icon="item.coin_gained ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(created_at)="{ item }">
                    {{ formatDateWithFiveHours(item.created_at) }}
                </template>

                <template #cell(actions)="{ item }">
                    <b-button
                        v-b-tooltip.hover.top="`${$t('button.view')}`"
                        :to="{
                            name: 'subscriber-game-monitoring',
                            params: {
                                id: item.user_id
                            }
                        }"
                        class="btn-icon"
                        variant="flat-dark"
                    >
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

* {
    font-size: 16px;
}

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.fw-700 {
    font-weight: 700;
}
</style>
