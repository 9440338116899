<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';

export default {
    name: 'ViewFastCharges',
    components: {
        vSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                amount_from: null,
                amount_to: null,
                charge_id: null
            },
            chargesList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
                this.getChargesList();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'charge',
                    label: this.$t('titles.charges')
                },
                {
                    key: 'amount_from',
                    label: this.$t('titles.from')
                },
                {
                    key: 'amount_to',
                    label: this.$t('titles.to')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.fastCharges.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getChargesList() {
            const body = {
                page: 1,
                limit: 1000
            };
            try {
                const { data } = await api.billingCharges.fetchAllItems(body);
                this.chargesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.fastCharges
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.fastCharges
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                    await this.$store.dispatch('fetchRandomizerPackages');
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.fastCharges
                .updateItem(id, body)
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    await this.$store.dispatch('fetchRandomizerPackages');
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.fastCharges
                .deleteItem(id)
                .then(async () => {
                    await this.getAll();
                    await this.$store.dispatch('fetchRandomizerPackages');
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    // body.amount_from = parseInt(body.amount_from);
                    // body.amount_to = parseInt(body.amount_to);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>Fast Charges</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(charge)="{ item }">
                    {{ item['charge'].name }}
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.billing_package') : $t('add.billing_package')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    FROM    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.from') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.from')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.amount_from"
                                    :placeholder="$t('titles.from')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TO    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.to') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.to')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.amount_to"
                                    :placeholder="$t('titles.to')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    CHARGE_ID    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.charges') }}</label>
                        <ValidationProvider v-slot="{ errors }" name="value" rules="required">
                            <b-form-group>
                                <v-select
                                    id="value"
                                    v-model="dataForModal.charge_id"
                                    :disabled="isViewModal"
                                    :options="chargesList"
                                    :reduce="(option) => option.id"
                                    :placeholder="$t('choose.charge')"
                                    label="name"
                                    :searchable="false"
                                    :clearable="true"
                                />
                                <!--                                    <template v-slot:option="option">-->
                                <!--                                        {{ option.type_name + ' ' + option.qty }}-->
                                <!--                                    </template>-->

                                <!--                                    <template v-slot:selected-option="option">-->
                                <!--                                        {{ option.type_name + ' ' + option.qty }}-->
                                <!--                                    </template>-->
                                <!--                                </v-select>-->
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
