import Core from '@/services/core';
import { axiosInstance } from '@/services/core/base';

class Subscribers extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }

    getLogs(id, params) {
        return this.customGet(`${this.url}/${id}/logs`, params);
    }

    getLogActionsApi() {
        return this.customGet(this.url + '/actions');
    }

    getPersonalInfo(id) {
        return this.customGet(`${this.url}/${id}/personal`);
    }

    getLevelsStatistics(id) {
        return this.customGet(`${this.url}/${id}/levels/statistics`);
    }

    getTransactions(id, params) {
        return this.customGet(`${this.url}/${id}/transactions`, params);
    }

    getGifts(id, params) {
        return this.customGet(`${this.url}/${id}/gifts`, params);
    }

    getWins(id, params) {
        return this.customGet(`${this.url}/${id}/levels`, params);
    }

    getFilterFields() {
        return this.customGet(`${this.url}/filter-fields`);
    }

    activatePacket(id, body) {
        return this.customPost(`${this.url}/${id}/packet`, body);
    }

    activatePacketAsSubscriber(id, body) {
        return this.customPost(`${this.url}/${id}/set-by-user`, body);
    }

    updateActivate(id) {
        return this.customPut(`${this.url}/${id}/activate`);
    }

    fetchTransactionList(body) {
        return this.customPost('transactions/findAll', body);
    }
}

export default Subscribers;
