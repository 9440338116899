import { axiosInstance, axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import Core from '@/services/core';
import CoreAxios from '@/services/axios/core.axios';

class News extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllNews(params) {
        return this.post('news/findAll', params);
    }

    fetchOneNews(params) {
        return this.post('news/findOne', params);
    }

    createNews(body) {
        return this.post('news', body);
    }

    updateNews(body) {
        return this.post('news/update', body);
    }

    deleteNews(body) {
        return this.post('news/remove', body);
    }
}

export default News;

class NewsApiV2 extends CoreAxios {
    constructor() {
        super({ axios: axiosInstanceV2 });
    }

    newsFindAll(body) {
        return this.post('v2/news/findAll', body);
    }

    newsFindOne(body) {
        return this.post('v2/news/findOne', body);
    }

    newsCreate(body) {
        return this.post('v2/news', body);
    }

    newsUpdate(body) {
        return this.post('v2/news/update', body);
    }

    newsRemove(body) {
        return this.post('v2/news/remove', body);
    }
}

export const newsApiV2 = new NewsApiV2();
