import axios from 'axios';
import {
    axiosRequestInterceptResponse,
    axiosRequestInterceptResponseCustomToken
} from '@/util/axios-intercept';
import { axiosResponseInterceptorError } from '@/services/axios/interceptor.axios';

const instanceGenerator = (baseUrl, token = false) => {
    const instance = axios.create({
        baseURL: baseUrl
    });

    instance.interceptors.request.use(
        token ? axiosRequestInterceptResponseCustomToken : axiosRequestInterceptResponse,
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use((response) => response, axiosResponseInterceptorError);

    return instance;
};

// eslint-disable-next-line no-undef
export const axiosInstance = instanceGenerator(process.env.VUE_APP_URL);

// eslint-disable-next-line no-undef
export const axiosInstanceV1 = instanceGenerator(process.env.VUE_APP_DEV_URL_V1);

// eslint-disable-next-line no-undef
export const axiosInstanceV2 = instanceGenerator(process.env.VUE_APP_URL_V2);

// eslint-disable-next-line no-undef
export const axiosInstanceV3 = instanceGenerator(process.env.VUE_APP_URL_V3);

// eslint-disable-next-line no-undef
export const axiosInstanceV4 = instanceGenerator(process.env.VUE_APP_URL_V4, true);

export const axiosV1 = ({ endpoint = '' }) => {
    // eslint-disable-next-line no-undef
    return instanceGenerator(process.env.VUE_APP_URL + endpoint);
};

export const axiosV2 = ({ endpoint = '' }) => {
    // eslint-disable-next-line no-undef
    return instanceGenerator(process.env.VUE_APP_URL_V2 + endpoint);
};

export const axiosV4 = (endpoint = '') => {
    // eslint-disable-next-line no-undef
    return instanceGenerator(process.env.VUE_APP_URL_V4 + endpoint, true);
};

export const axiosV5 = (endpoint = '') => {
    // eslint-disable-next-line no-undef
    return instanceGenerator(process.env.VUE_APP_URL_V4 + endpoint);
};

export const axiosOriginal = (endpoint = '') => {
    // eslint-disable-next-line no-undef
    return instanceGenerator(process.env.VUE_APP_URL_ORIGINAL + endpoint);
};

export const axiosDevV3 = ({ endpoint = '' }) => {
    // eslint-disable-next-line no-undef
    return instanceGenerator(process.env.VUE_APP_URL + endpoint);
};
