<script>
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import LogsTimeline from '@/components/LogsTimeline.vue';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';

export default {
    name: 'SubscribersSingleWalletTransactions',
    components: {
        LogsTimeline,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BButton,
        vSelect
    },
    data: () => ({
        requestBody: {
            amount: null,
            comment: ''
        },
        logs: {
            items: [],
            pagination: {}
        },
        filter: {
            page: 1,
            type: null
        },
        selectedType: null,
        staticTypeOptions: [
            {
                label: 'all',
                value: null
            },
            {
                label: 'debiting',
                value: 0
            },
            {
                label: 'crediting',
                value: 1
            }
        ],
        requestPending: false
    }),
    methods: {
        async getWalletTransactionMonitoring(page) {
            if (page) {
                if (this.requestPending) return;
                this.requestPending = true;
                this.filter.type = this.selectedType;
                if (this.selectedType === null) {
                    delete this.filter.type;
                }
                this.filter = Object.assign(this.filter, {
                    page: 1,
                    limit: 10,
                    user_id: this.$route.params.id,
                    merchant_id: MERCHANT_ID
                });
                const { data } = await api.walletBalanceApi.fetchTransactions(this.filter);
                if (data.data.pagination.current === 1) {
                    this.logs.items = data.data.list;
                } else {
                    this.logs.items = [...this.logs.items, ...data.data.list];
                }
                this.logs.pagination = data.data.pagination;
                this.requestPending = false;
            } else {
                if (this.requestPending) return;
                this.requestPending = true;
                this.filter.type = this.selectedType;
                if (this.selectedType === null) {
                    delete this.filter.type;
                }
                this.filter = Object.assign(this.filter, {
                    limit: 10,
                    user_id: this.$route.params.id,
                    merchant_id: MERCHANT_ID
                });
                const { data } = await api.walletBalanceApi.fetchTransactions(this.filter);
                if (data.data.pagination.current === 1) {
                    this.logs.items = data.data.list;
                } else {
                    this.logs.items = [...this.logs.items, ...data.data.list];
                }
                this.logs.pagination = data.data.pagination;
                this.requestPending = false;
            }
        },

        loadNextPage(page) {
            if (this.requestPending || this.autoReload || !page) return;
            this.filter.page = page;
            this.getWalletTransactionMonitoring();
        },

        async debit() {
            try {
                await api.walletBalanceApi.debit({
                    ...this.requestBody,
                    user_id: parseInt(this.$route.params.id)
                });
            } finally {
                await this.getWalletTransactionMonitoring(1);
                this.requestBody = {
                    amount: null,
                    comment: ''
                };
            }
            this.$emit('debitFunctionTriggered');
        },

        async credit() {
            try {
                await api.walletBalanceApi.credit({
                    ...this.requestBody,
                    user_id: parseInt(this.$route.params.id)
                });
            } finally {
                await this.getWalletTransactionMonitoring(1);
                this.requestBody = {
                    amount: null,
                    comment: ''
                };
            }
            this.$emit('creditFunctionTriggered');
        }
    },
    mounted() {
        this.getWalletTransactionMonitoring();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-7 col-sm-12 col-12 order-2 order-md-1">
                <LogsTimeline
                    v-model:loading="requestPending"
                    :items="logs.items"
                    :pagination="logs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="activity-item">
                            <div class="activity-item__content">
                                <h4>{{ item.product }}</h4>
                                <div class="mb-0">
                                    {{ $t('titles.service') }}: <strong>{{ item.service }}</strong>
                                </div>
                                <div class="mb-0">
                                    <div class="d-flex align-items-center">
                                        {{ $t('titles.type') }}:
                                        <div
                                            :class="
                                                item.type === 0 ? 'text-danger' : 'text-success'
                                            "
                                        >
                                            <strong v-if="item.type === 0">{{
                                                $t('titles.debiting')
                                            }}</strong>
                                            <strong v-else>{{ $t('titles.crediting') }}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {{ $t('titles.sum') }}:
                                    <strong class="font-medium-2">
                                        <b-badge :variant="item.type === 0 ? 'danger' : 'success'">
                                            {{ item.amount }} {{ $t('sum') }}
                                        </b-badge>
                                    </strong>
                                </div>
                                <div>
                                    {{ $t('titles.comment') }}
                                    <strong class="font-medium-1">{{ item.comment }}</strong>
                                </div>
                            </div>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <!--                        <router-link-->
                        <!--                            :to="{-->
                        <!--                                name: 'subscribers Single',-->
                        <!--                                params: {-->
                        <!--                                    id: item.user.id-->
                        <!--                                }-->
                        <!--                            }"-->
                        <!--                            class="d-flex"-->
                        <!--                        >-->
                        <!--                            {{ item.phone }}-->
                        <!--                        </router-link>-->
                        <!--                                                <div v-for="(data, key) in item.data" :key="key" class="d-flex">-->
                        <!--                                                    <small v-if="key === 'prize'">-->
                        <!--                                                        {{ key }}: {{ data.name[localLang] }}-->
                        <!--                                                    </small>-->
                        <!--                                                    <small v-else>{{ key }}: {{ data }}</small>-->
                        <!--                                                </div>-->
                    </template>
                </LogsTimeline>
            </b-col>
            <!--            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">-->
            <!--                <filter-block :filter="filter" @submit="getCoinMonitoring">-->
            <!--                    <b-col cols="12">-->
            <!--                        <h5>Действия</h5>-->
            <!--                        <b-form-group>-->
            <!--                            <v-select-->
            <!--                                v-model="filter.type"-->
            <!--                                :options="computedActions"-->
            <!--                                :reduce="(item) => item.key"-->
            <!--                                :searchable="false"-->
            <!--                                label="label"-->
            <!--                                @input="deSelect"-->
            <!--                            />-->
            <!--                        </b-form-group>-->
            <!--                    </b-col>-->
            <!--                </filter-block>-->
            <!--            </b-col>-->

            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">
                <b-card>
                    <h4>{{ $t('filter_title') }}</h4>
                    <v-select
                        @input="getWalletTransactionMonitoring(1)"
                        v-model="selectedType"
                        :options="staticTypeOptions"
                        label="label"
                        :reduce="(option) => option.value"
                        :clearable="false"
                    >
                        <template v-slot:option="option">
                            {{ `${$t(`titles.${option.label}`)}` }}
                        </template>

                        <template v-slot:selected-option="option">
                            {{ `${$t(`titles.${option.label}`)}` }}
                        </template>
                    </v-select>
                </b-card>
                <b-card>
                    <div class="row">
                        <div class="col-12">
                            <!--                        <h5>{{ $t('titles.') }}</h5>-->
                            <b-form-group :label="$t('titles.amount')">
                                <b-form-input
                                    v-model="requestBody.amount"
                                    :placeholder="$t('enter.amount')"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 my-1">
                            <b-form-group :label="$t('titles.comment')">
                                <b-form-textarea
                                    v-model="requestBody.comment"
                                    :placeholder="$t('enter.comment')"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-lg-6 mb-1 mb-lg-0">
                            <b-button @click="debit" variant="outline-danger" class="w-100">{{
                                $t('titles.debit')
                            }}</b-button>
                        </div>
                        <div class="col-12 col-lg-6">
                            <b-button @click="credit" variant="outline-success" class="w-100">{{
                                $t('titles.credit')
                            }}</b-button>
                        </div>
                    </div>
                    <!--                            <v-select-->
                    <!--                                v-model="filter.type"-->
                    <!--                                :options="computedActions"-->
                    <!--                                :reduce="(item) => item.key"-->
                    <!--                                :searchable="false"-->
                    <!--                                label="label"-->
                    <!--                                @input="deSelect"-->
                    <!--                            />-->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #7367f0;
            margin-bottom: 0;
        }

        span {
            color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}
</style>
