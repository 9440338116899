import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Flash extends CoreV2 {
    constructor() {
        super(axiosV2);
    }

    fetchAllFlashes(params) {
        return this.get('flash/findAll', params);
    }

    fetchOneFlash(id, params) {
        return this.get(`flash/findOne/${id}`, params);
    }

    createFlash(body) {
        return this.post('flash', body);
    }

    updateFlash(id, body) {
        return this.post(`flash/update/${id}`, body);
    }

    deleteFlash(id) {
        return this.delete(`flash/remove/${id}`);
    }
}

export default Flash;
