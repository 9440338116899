<script>
import {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BTable
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import api from '@/services/api';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import Chip from '@/components/ui/Chips/Chip.vue';
import ChipsWrapper from '@/components/ui/Chips/ChipsWrapper.vue';
import { getNameByLang } from '@/util/helper';
import TableActionBtns from '@/components/TableActionBtns.vue';
import PageTitle from '@/components/PageTitle.vue';
import { categoryTypes } from '@/constants/offerTypes';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'Categories',
    components: {
        ProgressLinear,
        PageTitle,
        TableActionBtns,
        ChipsWrapper,
        Chip,
        CustomSelect,
        BRow,
        BCol,
        BButton,
        BCard,
        BTable,
        BCardText,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BFormCheckbox,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        categories: [],
        gifts: [],
        dataForModal: {
            name: '',
            type: '',
            step: '',
            packages: '',
            gift_id: [],
            last_package_id: null
        },
        categorySteps: [1, 2, 3],
        requestPending: true
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'step',
                    label: this.$t('titles.step')
                },
                {
                    key: 'packages',
                    label: this.$t('titles.packages')
                },
                {
                    key: 'gifts',
                    label: this.$t('titles.tournaments')
                },
                {
                    key: 'last_package_id',
                    label: this.$t('titles.lastGift')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        categoryPackages() {
            return [
                {
                    key: 'internet',
                    label: this.$t('titles.internet')
                },
                {
                    key: 'voice',
                    label: this.$t('titles.voice')
                },
                {
                    key: 'sms',
                    label: this.$t('titles.sms')
                },
                {
                    key: 'voice_sms',
                    label: this.$t('titles.withoutInternet')
                },
                {
                    key: 'internet_sms',
                    label: this.$t('titles.withoutVoice')
                }
            ];
        },

        categoryTypes() {
            return categoryTypes;
        },

        filteredGiftsByPackage() {
            if (!this.gifts.length) return;
            switch (this.dataForModal.packages) {
                case 'voice':
                case 'voice_sms':
                    return this.gifts.filter((gift) => {
                        return gift.type !== 'internet';
                    });
                case 'sms':
                    return this.gifts.filter((gift) => {
                        return gift.type === 'sms';
                    });
                case 'internet_sms':
                    return this.gifts.filter((gift) => {
                        return gift.type !== 'voice';
                    });
                default:
                    return this.gifts;
            }
        },

        computedCategories() {
            return this.categories;
        }
    },
    methods: {
        async getCategories() {
            this.requestPending = true;
            const { data } = await api.categories.get();
            this.categories = data;
            this.requestPending = false;
        },

        async create() {
            const { data } = await api.categories.post(this.dataForModal);
            this.categories.unshift(data);
            this.$toast.success(this.$t('success.added'));
        },

        async update() {
            await api.categories.put(this.updatingItem.id, this.dataForModal);
            this.$toast.success(this.$t('success.updated.title'));
        },

        async deleteCategory(id, index) {
            await api.categories.delete(id);
            this.categories.splice(index, 1);
        },

        async getGifts() {
            const { data } = await api.gifts.get();
            this.gifts = data.map((gift) => {
                gift.name = getNameByLang(gift.name);
                return gift;
            });
        },

        filterGiftsNameByLang(items) {
            return items.map((item) => {
                item = getNameByLang(item.name);
                return item;
            });
        },

        getGiftByID(id) {
            const { name } = this.gifts.find((gift) => {
                return gift.id === id;
            });
            return name;
        }
    },
    mounted() {
        this.getCategories();
        this.getGifts();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.categories') }}
            </template>
            <template #button v-if="hasAccess('categories', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                striped
                borderless
                outlined
                :responsive="true"
                :fields="tableHeader"
                :items="computedCategories"
                class="b-table--less-padding"
            >
                <template #cell(index)="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell(type)="{ item }">
                    {{ $t(`titles.${item.type}`) }}
                </template>
                <template #cell(packages)="{ item }">
                    {{ $t(`titles.${item.packages}`) }}
                </template>
                <template #cell(gifts)="{ item }">
                    <div style="min-width: 300px">
                        <chips-wrapper :items="item.gifts" :filter="filterGiftsNameByLang" />
                    </div>
                </template>
                <template #cell(last_package_id)="{ item }">
                    <chip style="white-space: nowrap">
                        {{ getGiftByID(item.last_package_id) }}
                    </chip>
                </template>
                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :item="item"
                        :index="index"
                        :delete-handler="deleteCategory"
                        :delete-access="{ section: 'categories', permission: 'delete' }"
                        :update-access="{ section: 'categories', permission: 'update' }"
                        @openModal="openModalToUpdate"
                    />
                </template>
            </b-table>
        </div>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            size="lg"
            :title="$t('add.category')"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE    -->
                    <b-col cols="6">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.title')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    size="lg"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="6">
                        <label>
                            {{ $t('titles.type') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.type')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.type"
                                    :placeholder="$t('choose.type')"
                                    :options="categoryTypes"
                                    :menu-props="{
                                        closeOnContentClick: false
                                    }"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :reduce="(type) => type.key"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    STEP    -->
                    <b-col cols="6">
                        <label>
                            {{ $t('titles.step') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.step')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.step"
                                    :placeholder="$t('choose.step')"
                                    :options="categorySteps"
                                    :menu-props="{
                                        closeOnContentClick: false
                                    }"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PACKAGES    -->
                    <b-col cols="6">
                        <label>
                            {{ $t('titles.packages') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.packages')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.packages"
                                    :placeholder="$t('choose.package')"
                                    :options="categoryPackages"
                                    :menu-props="{
                                        closeOnContentClick: false
                                    }"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :reduce="(pack) => pack.key"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    GIFTS    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.tournaments') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.tournaments')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <custom-select
                                    v-model="dataForModal.gift_id"
                                    multiple
                                    :options="filteredGiftsByPackage"
                                    :placeholder="$t('choose.tournaments')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    LAST GIFT    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.lastGift') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.lastGift')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <custom-select
                                    v-model="dataForModal.last_package_id"
                                    :options="filteredGiftsByPackage"
                                    :errors="errors"
                                    :placeholder="$t('choose.lastGift')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button @click="modalSubmit" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
