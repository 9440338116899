import AppWebGamePortal from '@/views/web-game-portal/AppWebGamePortal.vue';

export default [
    {
        path: '/web-game-portal',
        name: 'web-game-portal',
        component: AppWebGamePortal,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
