<script>
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import vSelect from 'vue-select';
import { BTable } from 'bootstrap-vue';

export default {
    name: 'SSRoadmapInfo',
    components: { BTable, ProgressLinear, vSelect },
    data() {
        return {
            seasonList: [],
            info: null,
            requestPending: false,
            chosenSeason: null,
            userInfo: null
        };
    },
    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'level',
                    label: this.$t('titles.level')
                },
                {
                    key: 'is_opened',
                    label: this.$t('titles.is_opened')
                },
                {
                    key: 'opened_at',
                    label: this.$t('titles.opened_at')
                }
            ];
        }
    },
    async mounted() {
        await this.getCurrentSeason();
        await this.getRoadmapSeasons();
        await this.getRoadmapUserInfo(this.chosenSeason);
    },
    methods: {
        async getRoadmapSeasons() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchAllItems(body);
                this.seasonList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getCurrentSeason() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchCurrentSeason(body);
                this.chosenSeason = data.data.id;
            } catch (e) {
                console.error(e);
            }
        },

        async getRoadmapUserInfo() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.$route.params.id,
                season_id: this.chosenSeason
            };
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchRoadmapUserInfo(body);
                this.info = data.data.levels;
                this.userInfo = data.data.user;
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card p-1 d-flex flex-row font-weight-bold">
                        <span>{{ $t('titles.balance') }}</span>
                        <span class="mx-05-1">:</span>
                        <span v-if="userInfo" class="text-info">{{ userInfo.balance }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card p-1 d-flex flex-row font-weight-bold">
                        <span>{{ $t('titles.current_level') }}</span>
                        <span class="mx-05-1">:</span>
                        <span v-if="userInfo" class="text-info">{{
                            userInfo['current_level']
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="card">
                <progress-linear v-if="requestPending" />
                <b-table
                    v-else
                    :fields="fields"
                    :items="info"
                    :responsive="true"
                    borderless
                    class="news__table"
                    outlined
                    striped
                >
                    <template #cell(is_opened)="{ item }">
                        <feather-icon
                            :icon="item['is_opened'] ? 'CheckIcon' : 'XIcon'"
                            :class="item['is_opened'] ? 'text-success' : 'text-danger'"
                            size="20"
                        />
                    </template>
                </b-table>
            </div>
        </div>
        <div class="col-12 col-md-6 order-1 order-md-2">
            <div class="card p-1">
                <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                <label for="season">{{ $t('titles.season') }}</label>
                <v-select
                    id="season"
                    :placeholder="$t('titles.season_id')"
                    :options="seasonList"
                    v-model="chosenSeason"
                    :reduce="(option) => option.id"
                    label="name"
                    :clearable="false"
                    @input="getRoadmapUserInfo"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.mx-05-1 {
    margin: 0 1rem 0 0.5rem;
}
</style>
