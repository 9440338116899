<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import services from '@/services';

export default {
    name: 'AppFlash',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        flatPickr,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            packageOptions: [],
            dataForModal: {
                package_list: [],
                for_date: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            merchantId: parseInt(MERCHANT_ID),
            bannerStatus: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getFlashes();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'for_date',
                    label: this.$t('titles.for_date')
                },
                {
                    key: 'package_list',
                    label: this.$t('titles.package_list')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
        // regionOptionList() {
        //     const locale = this.$i18n.locale;
        //     return this.regionOptions.map((option) => {
        //         return {
        //             ...option,
        //             label: option.name[locale]
        //         };
        //     });
        // }
    },

    async mounted() {
        this.requestPending = true;
        await this.getFlashPackages();
        this.requestPending = false;
    },

    methods: {
        checkLang,

        async getFlashes() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };
            await services.flash
                .findAll(params)
                .then((response) => {
                    this.items = response.data.data.list;
                    // this.pagination = response.data.data.pagination;
                    //
                    // if (this.pagination.current > this.pagination.totalItem) {
                    //     this.pagination.current = 1;
                    // }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getFlash(item) {
            this.updatingItem = item;
            const body = {
                id: item.id
            };
            await services.flash
                .findOne(body.id)
                .then((response) => {
                    this.isOpenModal = true;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getFlashPackages() {
            await services.flash
                .fetchCatalogue()
                .then((response) => {
                    this.packageOptions = response.data.data.map((item) => {
                        return item.name;
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createFlash(body) {
            await services.flash
                .create(body)
                .then(async () => {
                    // this.items.push(data);
                    await this.getFlashes();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateFlash(body) {
            await services.flash
                .update(body.id, body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getFlashes();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteFlash(id) {
            const params = {
                id: id
            };
            await services.flash
                .remove(params.id)
                .then(() => {
                    this.getFlashes();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getFlashBannerStatus() {
            await services.flash
                .fetchFlashBannerStatus()
                .then((res) => {
                    this.bannerStatus = res.data.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        async updateFlashBannerStatus() {
            const body = {
                status: this.bannerStatus
            };
            await services.flash
                .updateFlashBannerStatus(body)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    // if (this.dataForModal.region) {
                    //     this.dataForModal.region_id = this.dataForModal.region.id;
                    // }

                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateFlash(body, config);
                        } else {
                            await this.createFlash(body, config);
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getFlashes();
        },

        // setValue(event) {
        //     console.log(event, 'event');
        // },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.flashes') }}</template>
            <template v-if="hasAccess('cities', 'create')" #button>
                <b-button
                    v-if="merchantId === 2"
                    v-b-modal.flashBanner
                    @click="getFlashBannerStatus"
                    class="ml-auto mr-1"
                    variant="primary"
                >
                    {{ $t('titles.flash_banner') }}
                </b-button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(package_list)="{ item }">
                    <div style="max-width: 300px">
                        <span v-for="(item, index) in item.package_list" :key="index">
                            <b-badge style="font-size: 14px; margin: 0.25rem">{{ item }}</b-badge>
                        </span>
                    </div>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'cities', permission: 'delete' }"
                            :delete-handler="deleteFlash"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="getFlash(item)"
                        />
                        <!--                        <b-button-->
                        <!--                            v-b-tooltip.hover.top="`${$t('button.open')}`"-->
                        <!--                            class="btn-icon"-->
                        <!--                            variant="flat-success"-->
                        <!--                            @click="openViewModal(item)"-->
                        <!--                        >-->
                        <!--                            <feather-icon icon="EyeIcon" />-->
                        <!--                        </b-button>-->
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.flash') : $t('add.flash')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--     PACKAGE     -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.package')"
                            rules="required"
                        >
                            <b-form-group :label="$t('choose.package')" label-for="package">
                                <v-select
                                    id="package"
                                    v-model="dataForModal.package_list"
                                    :disabled="isViewModal"
                                    :options="packageOptions"
                                    multiple
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    DATE FOR    -->
                    <b-col class="mt-1" cols="12">
                        <label>{{ $t('titles.date') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.date')}`"
                            rules="required"
                        >
                            <div class="input-group">
                                <flat-pickr
                                    v-model="dataForModal.for_date"
                                    :config="{
                                        allowInput: true
                                    }"
                                    :disabled="isViewModal"
                                    :placeholder="$t('choose.date')"
                                    class="mb-1 form-control"
                                    name="date"
                                    size="md"
                                />
                            </div>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    SWITCH FLASH MODAL    -->
        <b-modal id="flashBanner" hide-footer :title="$t('edit.flash_banner')" centered>
            <b-form-checkbox
                @change="updateFlashBannerStatus"
                v-model="bannerStatus"
                switch
                class="custom-control-success"
            >
                {{ $t('titles.switch_flash_banner') }}
            </b-form-checkbox>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
