<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import { isArray, isObject } from '@/util/inspect.util';
import LevelProductCard from '@/views/new-chapter/randomizer/components/LevelProductCard.vue';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'ViewRoadmap',
    components: {
        LevelProductCard,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            childItems: [],
            status: false,
            dataForModal: {
                name: '',
                levelInsert: null
            },
            dataForChildModal: {
                season_id: null,
                name: '',
                types: null,
                products: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            childPagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            staticLevelTypes: [
                {
                    name: 'static',
                    value: 0,
                    disabled: false
                },
                {
                    name: 'list',
                    value: 1,
                    disabled: false
                }
            ],
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            childRequestPending: false,
            openedButton: false,
            activeSeason: null,
            openedGroupID: null,
            packagesList: [],
            levelActionsList: [],
            cardsList: [],
            avatarsList: [],
            disableAddButton: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllSeasons();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'childPagination.current': {
            handler() {
                this.getAllLevels();
            }
        },

        uploadImage(image) {
            this.dataForChildModal.images = [];
            if (isArray(image)) {
                this.dataForChildModal.images = image;
            } else if (isObject(image)) {
                this.dataForChildModal.images.push(image);
            }
        }
    },

    computed: {
        // randomizerPackages() {
        //     return this.$store.getters.packages;
        // },

        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        childFields() {
            return [
                {
                    key: 'name',
                    label: 'level'
                },
                {
                    key: 'types',
                    label: this.$t('titles.types')
                },
                {
                    key: 'products',
                    label: this.$t('titles.products')
                },
                {
                    key: 'child_actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },

        hasChildItems() {
            return this.childItems && this.childItems.length > 0;
        },

        showChildPagination() {
            return this.hasChildItems && !this.childRequestPending;
        }
    },

    async mounted() {
        await this.getLevelActions();
        await this.getBillingPackages();
        await this.getCardsList();
        await this.getAvatarsList();
    },

    methods: {
        checkLocales,
        checkLang,

        async getBillingPackages() {
            const body = {
                page: 1,
                limit: 200
            };

            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.packagesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getLevelActions() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevelActions.fetchAllItems(body);
                this.levelActionsList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getCardsList() {
            const body = {
                page: 1,
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.cardsApi.fetchAllItemsWithoutPagination(body);
                this.cardsList = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getAvatarsList() {
            const body = {
                page: 1,
                limit: 1000,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.roadmapAvatarGtwApi.fetchAllItems(body);
                this.avatarsList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        handleChildPagination(newPage) {
            this.childPagination.current = newPage;
        },

        async getAllSeasons() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.roadmapApi.fetchAllSeasons(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllLevels() {
            const seasonItem = this.activeSeason?.item;
            this.openedButton = !this.openedButton;
            let response = [];
            let levels = [];
            const params = {
                page: this.childPagination.current,
                limit: this.childPagination.perPage,
                season_id: seasonItem?.id
            };
            try {
                this.requestPending = true;
                const { data } = await api.roadmapApi.fetchAllLevels(params);
                response = data.data.list;
                this.items.forEach((season, index) => {
                    response.forEach((level) => {
                        if (season.id === level['season_id']) {
                            levels.push(level);
                            this.items[index] = {
                                ...season,
                                levels: levels
                            };
                        }
                    });
                });
                levels = [];
                this.childItems = response;
                this.childPagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async getOneSeason(item) {
            this.updatingItem = item;
            await api.roadmapApi
                .fetchOneSeason(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getOneLevel(item) {
            this.updatingItem = item;
            if (item) {
                await api.roadmapApi
                    .fetchOneLevel(item.id)
                    .then((response) => {
                        // merge Object for need data
                        const res = response.data.data;

                        Object.keys(res).forEach((item) => {
                            if (item in this.dataForChildModal) {
                                this.dataForChildModal[item] = res[item];
                            }
                        });

                        this.dataForChildModal.products.forEach((item) => {
                            item.image = [item.image];
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                await this.openChildModal(item);
            }
        },

        async createSeason(body) {
            await api.roadmapApi
                .createSeason(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAllSeasons();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async createLevel(body) {
            // body.season_id = this.activeSeason.item.id;
            await api.roadmapApi
                .createLevel(body)
                .then(async () => {
                    await this.getAllSeasons();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                    this.$refs.createUpdateChildModal.hide();
                    this.uploadImage = null;
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateSeason(id, body) {
            await api.roadmapApi
                .updateSeason(id, body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllSeasons();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateLevel(id, body) {
            await api.roadmapApi
                .updateLevel(id, body)
                .then(() => {
                    this.getAllSeasons();
                    this.$refs.createUpdateChildModal.hide();
                    this.uploadImage = null;
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteSeason(id) {
            await api.roadmapApi
                .deleteSeason(id)
                .then(() => {
                    this.getAllSeasons();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async deleteLevel(val) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.roadmapApi
                        .deleteLevel(val.id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.getAllSeasons();
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.levelInsert = null;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateSeason(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createSeason(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async submitChildModal() {
            this.$refs.createUpdateChildModalValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForChildModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    body.products.forEach((item) => {
                        if (item.type === 1 || item.type === 2 || item.type === 3) {
                            item.activation_item_id = parseInt(item.activation_item_id);
                        }
                        item.image = item.image[0].id;
                    });

                    try {
                        if (this.updatingItem) {
                            await this.updateLevel(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createLevel(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        addProduct() {
            this.dataForChildModal.products.push({
                name: {
                    en: '',
                    ru: '',
                    uz: ''
                },
                type: null,
                activation_item_id: null,
                image: null
            });
            this.isProductIncrementable();
        },

        deleteProduct(index) {
            this.dataForChildModal.products.splice(index, 1);
            this.isProductIncrementable();
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAllSeasons();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        // clearActivationItem() {
        //     this.dataForChildModal.activation_items = null;
        // },

        toggleDetails(row) {
            this.setChildPaginationByDefault();
            this.items.forEach((item) => {
                if (row.item.id === item.id) {
                    row.toggleDetails();
                    this.activeSeason = row;
                } else {
                    item._showDetails = false;
                }
            });
            if (!row['detailsShowing']) {
                this.getAllLevels(row);
            }
        },

        async openChildModal(item) {
            this.$refs.createUpdateChildModal.show();
            if (!this.updatingItem) {
                this.dataForChildModal = {
                    season_id: null,
                    types: null,
                    name: null,
                    products: []
                };
            }
            if (item?.season_id) {
                this.dataForChildModal.season_id = item.season_id;
            } else {
                this.dataForChildModal.season_id = item.id;
            }
            await this.isProductIncrementable();
        },

        setChildPaginationByDefault() {
            this.childPagination = {
                current: 1,
                totalItem: '',
                perPage: 10
            };
        },

        isProductIncrementable() {
            const productsLength = this.dataForChildModal.products.length;
            const levelType = this.dataForChildModal.types;
            if (productsLength < 1) {
                this.staticLevelTypes[0].disabled = false;
                this.disableAddButton = false;
            } else if (productsLength === 1) {
                levelType === 0 ? (this.disableAddButton = true) : (this.disableAddButton = false);
                this.staticLevelTypes[0].disabled = false;
            } else {
                this.staticLevelTypes[0].disabled = true;
                this.disableAddButton = false;
            }
        },

        isSelectableLevelType(option) {
            return option.disabled === false;
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>Roadmap</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(show_details)="row">
                    <div>
                        <b-button
                            :class="openedButton ? 'opened' : ''"
                            class="p-1 svg__button"
                            size="sm"
                            variant="outline-primary"
                            @click="toggleDetails(row)"
                        >
                            <feather-icon
                                :icon="row.item._showDetails ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                                class="svg__icon"
                                size="20"
                            />
                        </b-button>
                    </div>
                </template>

                <template #row-details="row">
                    <progress-linear v-if="childRequestPending" />
                    <b-card v-else class="child__table">
                        <b-table
                            :fields="childFields"
                            :items="row.item.levels"
                            :responsive="true"
                            borderless
                            outlined
                            striped
                            thead-class="bg__red"
                        >
                            <template #cell(products)="{ item }">
                                <b-badge
                                    v-for="(child, index) in item['products']"
                                    :key="index"
                                    class="table-badge__item"
                                    :variant="child.color"
                                >
                                    <img :src="child.image" alt="" class="badge-img" />
                                    <span>{{ child.text.toUpperCase() }}</span>
                                </b-badge>
                            </template>

                            <template #cell(name)="{ item }">
                                <b-badge class="table-badge__item" variant="success">
                                    <span class="font-medium-1">{{ item.name }}</span>
                                </b-badge>
                            </template>

                            <template #cell(child_actions)="{ item }">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.edit')}`"
                                        class="btn-icon"
                                        variant="flat-dark"
                                        @click="getOneLevel(item)"
                                    >
                                        <feather-icon icon="EditIcon" size="16" />
                                    </b-button>
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.delete')}`"
                                        class="btn-icon"
                                        variant="flat-danger"
                                        @click="deleteLevel(item)"
                                    >
                                        <feather-icon icon="Trash2Icon" size="16" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>

                        <!--  CHILD PAGINATION  -->
                        <b-row class="my-2">
                            <b-col class="offset-3" cols="9">
                                <b-pagination
                                    v-if="showChildPagination"
                                    v-model="childPagination.current"
                                    :per-page="childPagination.perPage"
                                    :total-rows="childPagination.totalItem"
                                    class="float-right mr-4 mb-0"
                                    first-number
                                    last-number
                                    @input="handleChildPagination"
                                />
                            </b-col>
                        </b-row>
                    </b-card>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-b-tooltip.hover.top="`${$t('add.level')}`"
                            class="btn-icon"
                            variant="flat-success"
                            @click="openChildModal(item)"
                        >
                            <feather-icon icon="PlusIcon" size="16" />
                        </b-button>
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteSeason"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneSeason(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.season') : $t('add.season')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    LEVEL INSERT    -->
                    <b-col v-if="!updatingItem" cols="12">
                        <label>{{ $t('titles.level_insert') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.level_insert')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.levelInsert"
                                    :placeholder="$t('enter.level_insert')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    CREATE UPDATE CHILD MODAL    -->
        <b-modal
            id="createUpdateChildModal"
            ref="createUpdateChildModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.level') : $t('add.level')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateChildModalValidation">
                <b-row>
                    <!--    NAME    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.types') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.types')"
                            rules="required"
                        >
                            <v-select
                                id="categories"
                                v-model="dataForChildModal.types"
                                :options="staticLevelTypes"
                                :reduce="(option) => option.value"
                                label="name"
                                :placeholder="$t('choose.type')"
                                :clearable="false"
                                :selectable="isSelectableLevelType"
                                @input="isProductIncrementable"
                            >
                                <!--                                @input="clearActivationItem"-->
                            </v-select>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     PRODUCTS     -->
                    <div class="d-flex flex-wrap w-100 px-1">
                        <div
                            v-for="(product, index) in dataForChildModal.products"
                            :key="index"
                            class="col-6 py-05"
                        >
                            <div class="product-card p-1">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        class="p-05"
                                        variant="danger"
                                        @click="deleteProduct(index)"
                                        >X
                                    </b-button>
                                </div>
                                <div>
                                    <LevelProductCard
                                        :key="index"
                                        :index="index"
                                        :product="product"
                                        :level-list="levelActionsList"
                                        :package-list="packagesList"
                                        :cards-list="cardsList"
                                        :avatars-list="avatarsList"
                                    />
                                    <!--                                        @change-gift="changeGiftProduct"-->
                                </div>
                            </div>

                            <!--                            <b-col v-if="Object.keys(product)" class="p-2" cols="12">-->
                            <!--                                <LevelProductCard :key="product.id" :product="product" />-->
                            <!--                            </b-col>-->
                        </div>
                    </div>

                    <b-col cols="12">
                        <div class="float-right">
                            <b-button
                                :disabled="disableAddButton"
                                variant="primary"
                                @click="addProduct"
                                >{{ $t('button.add') }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitChildModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}

.table-badge__item {
    margin: 4px;
}

.badge-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.product-card {
    border: 2px dashed var(--secondary);
}

.py-05 {
    padding: 0.5rem 1rem;
}

.p-05 {
    padding: 0.5rem;
}
</style>
