<script>
import {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BTable
} from 'bootstrap-vue';
import api from '@/services/api';
import { sortItems } from '@/util/helper';
import { offerTypes } from '@/constants/offerTypes.js';
import vSelect from 'vue-select';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import TableActionBtns from '@/components/TableActionBtns.vue';
import PageTitle from '@/components/PageTitle.vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { isNUNEZ } from '@/util/inspect.util';

export default {
    name: 'Gifts',
    components: {
        ProgressLinear,
        PageTitle,
        TableActionBtns,
        BRow,
        BCol,
        BButton,
        BCard,
        BTable,
        BCardText,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BFormCheckbox,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        gifts: [],
        offerTypes,
        dataForModal: {
            name: {
                uz: '',
                ru: '',
                en: ''
            },
            package_id: null,
            published: false,
            first_prize: false,
            super_prize: false,
            type: '',
            premium: false,
            sticker_id: '',
            values: null,
            price: 0
        },
        requestPending: true
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'package_id',
                    label: `${this.$t('titles.package')} ID`
                },
                {
                    key: 'price',
                    label: this.$t('titles.price')
                },
                {
                    key: 'published',
                    label: this.$t('titles.published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getGifts() {
            this.requestPending = true;
            const { data } = await api.gifts.get();
            this.gifts = data;
            this.sortGiftsByValues();
            this.requestPending = false;
        },

        generateBody() {
            const bodyCtx = Object.assign({}, this.dataForModal);
            if (!isNUNEZ(bodyCtx.sticker_id)) {
                delete bodyCtx.sticker_id;
            }
            return bodyCtx;
        },

        async create() {
            await api.gifts.post(this.generateBody()).then(({ data }) => {
                this.gifts.unshift(data);
                this.$toast.success(this.$t('success.added'));
                this.sortGiftsByValues();
            });
        },

        async update() {
            await api.gifts.put(this.updatingItem.id, this.generateBody()).then(({ data }) => {
                this.updateDataToNewData(this.gifts, data);
                this.sortGiftsByValues();
            });
        },

        async deleteGift(id, index) {
            await api.gifts.delete(id);
            this.gifts.splice(index, 1);
        },

        sortGiftsByValues() {
            this.gifts = sortItems(this.gifts, 'type', 'values');
        }
    },
    mounted() {
        this.getGifts();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.tournaments') }}
            </template>
            <template #button v-if="hasAccess('gifts', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                striped
                borderless
                outlined
                class="b-table--less-padding"
                :responsive="true"
                :fields="tableHeader"
                :items="gifts"
            >
                <template #cell(index)="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell(name)="{ item }">
                    {{ item.name[localLang] }}
                </template>
                <template #cell(type)="{ item }">
                    {{ $t(`titles.${item.type}`) }}
                </template>
                <template #cell(price)="{ item }">
                    {{ item.price ? item.price : 0 }} {{ $t('sum') }}
                </template>
                <template #cell(published)="{ item }">
                    <feather-icon
                        :icon="generateIconToBoolean(item.published)"
                        :class="['stroke-current', generateIconColorToBoolean(item.published)]"
                    />
                </template>
                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :item="item"
                        :index="index"
                        :delete-handler="deleteGift"
                        :delete-access="{ section: 'gifts', permission: 'delete' }"
                        :update-access="{ section: 'gifts', permission: 'update' }"
                        @openModal="openModalToUpdate"
                    />
                </template>
            </b-table>
        </div>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            size="lg"
            :title="$t('add.gift')"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE[uz]    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>{{ $t('titles.title') }} (uz)</label>
                        <ValidationProvider
                            name="'Название (uz)'"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    size="lg"
                                    :placeholder="`${$t('enter.title')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE[ru]    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>{{ $t('titles.title') }} (ru)</label>
                        <ValidationProvider
                            name="'Название (ru)'"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    size="lg"
                                    :placeholder="`${$t('enter.title')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE[en]    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>{{ $t('titles.title') }} (en)</label>
                        <ValidationProvider
                            name="'Название (en)'"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    size="lg"
                                    :placeholder="`${$t('enter.title')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PACKAGE ID    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>{{ $t('titles.package') }} ID</label>
                        <ValidationProvider
                            :name="`${$t('titles.package')} ID`"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.package_id"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="`${$t('enter.packageID')}`"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>
                            {{ $t('titles.types') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.types')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.type"
                                    :placeholder="$t('choose.type')"
                                    :options="offerTypes"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :searchable="false"
                                    :reduce="(type) => type.key"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    VALUES    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>
                            {{ $t('titles.values') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.values')"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model.number="dataForModal.values"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.values')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PRICE    -->
                    <b-col lg="4" md="6" sm="12">
                        <label>
                            {{ $t('titles.price') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.price')"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model.number="dataForModal.price"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.price')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    STICKER ID    -->
                    <b-col lg="4" md="6" sm="12">
                        <label> {{ $t('titles.sticker') }} ID </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.sticker_id"
                                size="lg"
                                :placeholder="`${$t('enter.stickerID')}`"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="my-2">
                    <!--    PREMIUM    -->
                    <b-col md="6" lg="3" class="mb-2">
                        <b-form-checkbox v-model="dataForModal.premium">
                            {{ $t('titles.premium') }}
                        </b-form-checkbox>
                    </b-col>

                    <!--    FIRST PRIZE    -->
                    <b-col md="6" lg="3" class="mb-2">
                        <b-form-checkbox v-model="dataForModal.first_prize">
                            {{ $t('titles.firstPrize') }}
                        </b-form-checkbox>
                    </b-col>

                    <!--    SUPER PRIZE    -->
                    <b-col md="6" lg="3" class="mb-2">
                        <b-form-checkbox v-model="dataForModal.super_prize">
                            {{ $t('titles.superPrize') }}
                        </b-form-checkbox>
                    </b-col>

                    <!--    PUBLISHED    -->
                    <b-col md="6" lg="3">
                        <b-form-checkbox v-model="dataForModal.published">
                            {{ $t('titles.published') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button @click="modalSubmit" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
