<script>
import services from '@/services';
import FlatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts';
import { formatNumber } from '@/util/helper';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BTable } from 'bootstrap-vue';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppFlashHistoryGraph',
    components: {
        BTable,
        ProgressLinear,
        FlatPickr,
        apexchart: VueApexCharts
    },
    data() {
        return {
            cards: [],
            overall: [],
            app: [],
            ussd: [],
            param: {
                date_start: '',
                date_end: ''
            },
            overallOptions: {
                loaded: false,
                series: [],
                labels: [],
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            appOptions: {
                loaded: false,
                series: [],
                labels: [],
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            ussdOptions: {
                loaded: false,
                series: [],
                labels: [],
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            search: '',
            requestPending: false
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'package',
                    label: this.$t('titles.package'),
                    stickyColumn: true,
                    variant: 'dark'
                },
                {
                    key: 'cost',
                    label: this.$t('titles.cost')
                },
                {
                    key: 'amount',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'is_success',
                    label: this.$t('titles.success')
                },
                {
                    key: 'is_fail',
                    label: this.$t('titles.fail')
                }
            ];
        }
    },

    async created() {
        await this.getDatesForParam();
        await this.applyFilter();
    },

    methods: {
        formatNumber,

        async applyFilter() {
            await this.getFlashHistoryGraph();
            await this.getFlashHistoryCards();
        },

        async getFlashHistoryGraph() {
            const params = {
                date_start: this.param.date_start,
                date_end: this.param.date_end,
                merchant_id: MERCHANT_ID
            };
            this.appOptions.series.length = 0;
            this.appOptions.labels.length = 0;
            this.appOptions.loaded = false;
            this.ussdOptions.series.length = 0;
            this.ussdOptions.labels.length = 0;
            this.ussdOptions.loaded = false;
            this.overallOptions.series.length = 0;
            this.overallOptions.labels.length = 0;
            this.overallOptions.loaded = false;

            await services.flash
                .fetchHistoryGraph({ params })
                .then((response) => {
                    this.app = response.data.data.app;
                    this.app.detail.forEach((item) => {
                        this.appOptions.labels.push(
                            `<center><em><h4 style="color: #fff;">${item.package_qty}</h4></em></center> Quantity: <strong>${item.qty}</strong></br> Amount`
                        );
                        this.appOptions.series.push(parseInt(item.amount));
                    });
                    this.overall = response.data.data.overall;
                    this.overall.detail.forEach((item) => {
                        this.overallOptions.labels.push(
                            `<center><em><h4 style="color: #fff;">${item.package_qty}</h4></em></center> Quantity: <strong>${item.qty}</strong></br> Amount`
                        );
                        this.overallOptions.series.push(item.amount);
                    });
                    this.ussd = response.data.data.ussd;
                    this.ussd.detail.forEach((item) => {
                        this.ussdOptions.labels.push(
                            `<center><em><h4 style="color: #fff;">${item.package_qty}</h4></em></center> Quantity: <strong>${item.qty}</strong></br> Amount`
                        );
                        this.ussdOptions.series.push(parseInt(item.amount));
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.overallOptions.loaded = true;
                    this.appOptions.loaded = true;
                    this.ussdOptions.loaded = true;
                });
        },

        async getFlashHistoryCards() {
            const param = {
                date_start: this.param.date_start,
                date_end: this.param.date_end,
                merchant_id: MERCHANT_ID
            };
            const search = this.search;
            await services.flash
                .fetchHistoryPack({ param, search })
                .then((response) => {
                    this.cards = response.data.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getDatesForParam() {
            const date = new Date();
            const fromYear = date.getUTCFullYear(); // Get the full year (4 digits)
            const fromMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const fromDay = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary

            const toYear = date.getUTCFullYear(); // Get the full year (4 digits)
            const toMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const toDay = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary

            this.param.date_start = `${fromYear}-${fromMonth}-${fromDay}`;
            this.param.date_end = `${toYear}-${toMonth}-${toDay}`;
        }
    }
};
</script>

<template>
    <section class="flash">
        <div class="row d-flex flex-column flex-sm-row align-items-center mb-2">
            <div class="col-12 col-sm-10 col-lg-11 px-0 d-sm-flex align-items-center">
                <div class="col-12 col-sm-6 px-1">
                    <flat-pickr
                        v-model="param.date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-2 mt-sm-0 px-1">
                    <flat-pickr
                        v-model="param.date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                    />
                </div>
            </div>
            <div class="col-12 col-sm-2 col-lg-1">
                <b-button
                    @click="applyFilter"
                    variant="outline-primary"
                    class="px-1 px-sm-0 d-block mx-auto mt-2 mt-sm-0 w-100"
                >
                    <feather-icon icon="SearchIcon" size="16" />
                </b-button>
            </div>
        </div>
        <div class="flash-history-graph px-1">
            <div class="row align-items-start flash-history-graph__cards">
                <!--       USSD PIE CHART      -->
                <div class="col-12 col-md-6 col-xl-4 px-0 px-sm-1">
                    <progress-linear v-if="ussd.loaded" />
                    <div v-else class="statistics-radial-bar card p-1">
                        <h4>USSD</h4>
                        <apexchart :options="ussdOptions" :series="ussdOptions.series" />
                        <div class="d-flex flex-column px-2 pb-1">
                            <b-badge
                                class="text-nowrap mb-1"
                                v-for="(item, index) in ussd.detail"
                                :key="index"
                            >
                                <span class="d-block"
                                    >{{ $t('titles.package') }}: {{ item.package_qty }}x</span
                                >
                                <span class="d-block"
                                    >{{ $t('titles.sum') }}: {{ formatNumber(item.amount) }}</span
                                >
                                <span class="d-block"
                                    >{{ $t('titles.qty') }}: {{ formatNumber(item.qty) }}</span
                                >
                            </b-badge>
                            <b-badge variant="success">
                                <span class="d-block text-nowrap"
                                    >{{ $t('titles.overall_amount') }}:
                                    <strong>{{ formatNumber(ussd.amount) }}</strong>
                                </span>
                                <span class="d-block text-nowrap"
                                    >{{ $t('titles.overall_quantity') }}:
                                    <strong>{{ formatNumber(ussd.qty) }}</strong>
                                </span>
                            </b-badge>
                        </div>
                    </div>
                </div>

                <!--       APP PIE CHART      -->
                <div class="col-12 col-md-6 col-xl-4 px-0 px-sm-1">
                    <progress-linear v-if="ussd.loaded" />
                    <div v-else class="statistics-radial-bar card p-1">
                        <h4>APP</h4>
                        <apexchart :options="appOptions" :series="appOptions.series" />
                        <div class="d-flex flex-column px-2 pb-1">
                            <b-badge
                                class="text-nowrap mb-1"
                                v-for="(item, index) in app.detail"
                                :key="index"
                            >
                                <span class="d-block"
                                    >{{ $t('titles.package') }}: {{ item.package_qty }}x</span
                                >
                                <span class="d-block"
                                    >{{ $t('titles.sum') }}: {{ formatNumber(item.amount) }}</span
                                >
                                <span class="d-block"
                                    >{{ $t('titles.qty') }}: {{ formatNumber(item.qty) }}</span
                                >
                            </b-badge>
                            <b-badge variant="success">
                                <span class="d-block text-nowrap"
                                    >{{ $t('titles.overall_amount') }}:
                                    <strong>{{ formatNumber(app.amount) }}</strong>
                                </span>
                                <span class="d-block text-nowrap"
                                    >{{ $t('titles.overall_quantity') }}:
                                    <strong>{{ formatNumber(app.qty) }}</strong>
                                </span>
                            </b-badge>
                        </div>
                    </div>
                </div>

                <!--       OVERALL PIE CHART      -->
                <div class="col-12 col-md-6 col-xl-4 px-0 px-sm-1">
                    <progress-linear v-if="overall.loaded" />
                    <div v-else class="statistics-radial-bar card p-1">
                        <h4 class="text-uppercase">{{ $t('titles.overall') }}</h4>
                        <apexchart :options="overallOptions" :series="overallOptions.series" />
                        <div class="d-flex flex-column mt-1 px-2 pb-1">
                            <b-badge
                                class="text-nowrap mb-1"
                                v-for="(item, index) in overall.detail"
                                :key="index"
                            >
                                <span class="d-block"
                                    >{{ $t('titles.package') }}: {{ item.package_qty }}x</span
                                >
                                <span class="d-block"
                                    >{{ $t('titles.sum') }}: {{ formatNumber(item.amount) }}</span
                                >
                                <span class="d-block"
                                    >{{ $t('titles.qty') }}: {{ formatNumber(item.qty) }}</span
                                >
                            </b-badge>
                            <b-badge variant="success">
                                <span class="d-block text-nowrap"
                                    >{{ $t('titles.overall_amount') }}:
                                    <strong>{{ formatNumber(overall.amount) }}</strong>
                                </span>
                                <span class="d-block text-nowrap"
                                    >{{ $t('titles.overall_quantity') }}:
                                    <strong>{{ formatNumber(overall.qty) }}</strong>
                                </span>
                            </b-badge>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="cards.length" class="cards-item mb-3">
            <!--     TABLE VIEW     -->
            <div class="card table-cards">
                <progress-linear v-if="requestPending" />
                <b-table
                    v-else
                    :fields="fields"
                    :items="cards"
                    :responsive="true"
                    borderless
                    :sticky-header="true"
                    :no-border-collapse="true"
                    class="bs__table"
                    outlined
                    striped
                >
                    <template #cell(id)="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell(amount)="{ item }">
                        {{ formatNumber(parseInt(item.amount)) }}
                    </template>

                    <template #cell(package)="{ item }">
                        <span class="text-white fw-700">{{ item.package }}</span>
                    </template>

                    <template #cell(cost)="{ item }">
                        {{ formatNumber(parseInt(item.cost)) }}
                    </template>

                    <template #cell(count)="{ item }">
                        {{ formatNumber(item.count) }}
                    </template>

                    <template #cell(is_success)="{ item }">
                        {{ formatNumber(item.is_success) }}
                    </template>

                    <template #cell(is_fail)="{ item }">
                        {{ formatNumber(item.count - item.is_success) }}
                    </template>
                </b-table>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
.flash-history-graph {
    &__card {
        & h4 {
            font-size: 20px;
        }
        & p {
            margin-bottom: 4px;
            font-size: 15px;

            & span {
                font-weight: 700;
                font-size: 17px;
            }
        }
    }
}

.flash-history-pack {
    overflow-x: scroll;

    &::-webkit-scrollbar {
        width: 20px;
        height: 1rem;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(180, 183, 189, 1);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(180, 183, 189, 1);
    }

    &__card {
        & h4 {
            font-size: 20px;
        }
        & p {
            margin-bottom: 4px;
            font-size: 15px;

            & span {
                font-weight: 700;
                font-size: 17px;
            }
        }
    }
}

::v-deep .apexcharts-legend-marker {
    margin-right: 1rem !important;
}

::v-deep .apexcharts-legend-series {
    margin-bottom: 1rem !important;
}

.table-cards {
    height: 500px;
    overflow-y: scroll;

    & .bs__table {
        max-height: 100% !important;
    }
}
.badge span {
    margin-bottom: 4px;
}

.fw-700 {
    font-weight: 700;
}
</style>
