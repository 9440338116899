<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import AppTimeline from '@core/components/app-timeline/AppTimeline';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem';

export default {
    name: 'LogsTimeline',
    components: { ProgressLinear, AppTimelineItem, AppTimeline },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        pagination: {
            type: Object,
            default: {
                current: null,
                next: 2,
                perPage: null,
                previous: null,
                total: null,
                totalItem: null
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        observer: null,
        localLang: localStorage.getItem('lang')
    }),
    computed: {
        computedLoading() {
            return this.loading;
        }
    },
    methods: {
        iconByAction(actionName) {
            switch (actionName) {
                // authorized
                // no_money
                // retry_tomorrow
                // gift_set
                // try_again
                // verify
                // rub_level_gift
                // firebase_store
                // language_change
                // balance_invoice
                // charged_invoice
                // daily_charge_invoice_unkown_error
                case 'verify':
                    return 'AwardIcon';
                case 'barring':
                    return 'AlertOctagonIcon';
                case 'daily_charge_invoice':
                    return 'PlayIcon';
                case 'subscribe_active':
                case 'subscribe_stop':
                    return 'PowerIcon';
                case 'daily_charge_invoice_low_balance':
                    return 'ArrowDownRightIcon';
                case 'login':
                    return 'LogInIcon';
                case 'logout':
                    return 'LogOutIcon';
                case 'charged_invoice':
                    return 'CheckIcon';
                case 'gift_set':
                case 'prize':
                    return 'GiftIcon';
                case 'rub':
                    return 'AnchorIcon';
                case 'rub_double':
                case 'rub_triple':
                case 'rub_premium_double':
                case 'rub_premium_triple':
                    return 'PlusIcon';
                case 'firebase_store':
                    return 'SaveIcon';
                case 'balance_invoice':
                    return 'DivideIcon';
                case 'internet':
                    return 'WifiIcon';
                case 'voice':
                    return 'VoicemailIcon';
                case 'sms':
                    return 'MailIcon';
            }
        },

        colorByAction(actionName) {
            switch (actionName) {
                case 'barring':
                    return 'danger';
                case 'daily_charge_invoice_low_balance':
                    return 'bg-gradient-light';
                case 'login':
                case 'logout':
                case 'internet':
                    return 'bg-gradient-light';
                case 'gift_set':
                case 'prize':
                    return 'bg-primary';
                case 'voice':
                case 'rub':
                case 'charged_invoice':
                    return 'bg-success';
                case 'rub_double':
                case 'rub_triple':
                case 'rub_premium_double':
                case 'rub_premium_triple':
                    return 'primary';
                case 'firebase_store':
                    return 'bg-info';
                case 'sms':
                case 'balance_invoice':
                    return 'bg-warning';
                default:
                    return 'dark';
            }
        },

        observe() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.$emit('loadNextPage', this.pagination.next);
                    }
                });
            });
            setTimeout(() => {
                this.observer.observe(this.$refs['last-child']);
            });
        },

        unObserve() {
            this.observer.disconnect();
            this.observer.unobserve(this.$refs['last-child']);
        }
    },
    watch: {
        items(val) {
            if (val.length) this.observe();
        }
    },
    unmounted() {
        this.unObserve();
    }
};
</script>

<template>
    <div class="card activities p-2">
        <app-timeline v-if="items.length" ref="timeLine">
            <app-timeline-item
                v-for="activity in items"
                :key="activity.id"
                :icon="iconByAction(activity.action)"
                :variant="colorByAction(activity.action)"
            >
                <slot :item="activity" />
            </app-timeline-item>
            <div ref="last-child" class="logs-timeline__last-child" />
        </app-timeline>
        <h4 v-else-if="!loading" class="m-0">{{ $t('no_data') }}</h4>
        <progress-linear v-show="loading" style="margin: 25px -15px -25px; width: unset" />
    </div>
</template>
