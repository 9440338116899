import AppDealersStatistics from '@/views/dealers/statistics/AppDealersStatistics.vue';
import AppDealerAccount from '@/views/dealers/account/AppDealerAccount.vue';
import AppDealerService from '@/views/dealers/service/AppDealerService.vue';
import AppDealerConstants from '@/views/dealers/constants/AppDealerConstants.vue';
import AppDealerOverallStatistics from '@/views/dealers/account/statistics/AppDealerOverallStatistics.vue';

export default [
    {
        path: '/dealers',
        name: 'dealers',
        component: AppDealersStatistics,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/dealers-accounts',
        name: 'dealers-accounts',
        component: AppDealerAccount,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/dealers-accounts/statistics/:id',
        name: 'dealers-accounts',
        component: AppDealerOverallStatistics,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/dealers-service',
        name: 'dealers-service',
        component: AppDealerService,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/dealers-constants',
        name: 'dealers-constants',
        component: AppDealerConstants,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
