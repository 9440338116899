var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all"},[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.statistics_set'))+" [All]")]},proxy:true},{key:"button",fn:function(){return [_c('b-button',{staticClass:"btn-icon mr-1",attrs:{"variant":"primary"},on:{"click":_vm.getDistributionStatisticsAll}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon"}})],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('downloadExcelModal'),expression:"'downloadExcelModal'"}],staticClass:"btn-icon",attrs:{"variant":"primary"}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('button.download')))]),_c('feather-icon',{attrs:{"icon":"DownloadIcon"}})],1)]},proxy:true}])}),_c('div',{staticClass:"row align-items-end mb-2"},[_c('div',{staticClass:"col-12 col-md-10 d-flex flex-wrap align-items-center"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('label',{attrs:{"for":"start_date"}},[_vm._v(_vm._s(_vm.$t('titles.date_start_at')))]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        allowInput: true,
                        time_24hr: true
                    },"placeholder":_vm.$t('choose.date'),"name":"date","id":"start_date"},model:{value:(_vm.date_start),callback:function ($$v) {_vm.date_start=$$v},expression:"date_start"}})],1),_c('div',{staticClass:"col-12 col-sm-6 mt-1 mt-sm-0"},[_c('label',{attrs:{"for":"end_date"}},[_vm._v(_vm._s(_vm.$t('titles.date_end_at')))]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        allowInput: true,
                        time_24hr: true
                    },"placeholder":_vm.$t('choose.date'),"name":"date","id":"end_date"},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}})],1)]),_c('div',{staticClass:"col-12 col-md-2 px-2"},[_c('b-button',{staticClass:"w-100 mt-1 mt-md-0",attrs:{"variant":"primary"},on:{"click":_vm.doubleRequest}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)]),_c('div',{staticClass:"card p-1 d-flex flex-column flex-md-row justify-content-around"},[_c('div',{staticClass:"d-flex mb-2 mb-md-0 justify-content-center"},[_c('h3',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('titles.total_count'))+":")]),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.formatNumber(_vm.total.quantity)))])],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('h3',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('titles.total_sum'))+":")]),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.formatNumber(_vm.total.sum)))])],1)]),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{attrs:{"fields":_vm.totalFields,"items":_vm.total.table,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(count)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.count))+" ")]}},{key:"cell(sum)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.sum))+" ")]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(count)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.count))+" ")]}},{key:"cell(sum)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.sum))+" ")]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end my-2"},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)])]),_c('b-modal',{ref:"downloadExcelModal",attrs:{"id":"downloadExcelModal","title":_vm.$t('button.download')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.isDownloadModalOpened = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.downloadDealerStatistics}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])]},proxy:true}]),model:{value:(_vm.isDownloadModalOpened),callback:function ($$v) {_vm.isDownloadModalOpened=$$v},expression:"isDownloadModalOpened"}},[_c('label',{attrs:{"for":"dateStart"}},[_vm._v(_vm._s(_vm.$t('titles.start_date')))]),_c('flat-pickr',{staticClass:"form-control mb-1",attrs:{"config":{
                allowInput: true
            },"autocomplete":"off","name":"date","placeholder":_vm.$t('choose.date'),"id":"dateStart"},model:{value:(_vm.download_date_start),callback:function ($$v) {_vm.download_date_start=$$v},expression:"download_date_start"}}),_c('label',{attrs:{"for":"dateStart"}},[_vm._v(_vm._s(_vm.$t('titles.end_date')))]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                allowInput: true
            },"autocomplete":"off","name":"date","placeholder":_vm.$t('choose.date'),"id":"dateEnd"},model:{value:(_vm.download_date_end),callback:function ($$v) {_vm.download_date_end=$$v},expression:"download_date_end"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }