<script>
import LogsTimeline from '@/components/LogsTimeline';
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { categoryTypes } from '@/constants/offerTypes';
import { deletePropertiesWhichNoValue } from '@/util/helper';
import FilterBlock from '@/components/FilterBlock';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'SubscribersSingleActivities',
    components: { FilterBlock, LogsTimeline, BRow, BCol, BButton, BFormGroup, vSelect },
    inject: ['gifts', 'steps'],
    data: () => ({
        subscriberGifts: {
            items: [],
            pagination: {}
        },
        filter: {
            page: 1
        },
        localLang: localStorage.getItem('lang'),
        requestPending: true,
        merchant: parseInt(MERCHANT_ID)
    }),
    computed: {
        computedGifts() {
            if (!this.gifts.value) return;
            return this.gifts.value;
        },
        computedSteps() {
            if (!this.steps.value) return;
            return this.steps.value;
        },
        categoryTypes() {
            return categoryTypes;
        }
    },
    methods: {
        async getUserGifts() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            if (this.merchant === 2) {
                const { data } = await api.subscribers.getGifts(this.$route.params.id, this.filter);
                if (data.pagination.current === 1) {
                    this.subscriberGifts.items = data.items.map((item) => {
                        item.action = 'gift_set';
                        return item;
                    });
                } else {
                    this.subscriberGifts.items = [...this.subscriberGifts.items, ...data.items];
                }
                this.subscriberGifts.pagination = data.pagination;
            } else {
                const { data } = await api.lampSubscribers.fetchGifts(
                    this.$route.params.id,
                    this.filter,
                    (this.filter.limit = 10),
                    (this.filter.merchant_id = this.merchant)
                );
                if (data.pagination.current === 1) {
                    this.subscriberGifts.items = data.result.map((item) => {
                        item.action = 'gift_set';
                        return item;
                    });
                } else {
                    this.subscriberGifts.items = [...this.subscriberGifts.items, ...data.result];
                }
                this.subscriberGifts.pagination = data.pagination;
            }
            this.requestPending = false;
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.requestPending = true;
            this.filter.page = page;
            await this.getUserGifts();
            this.requestPending = false;
        },

        isPremium(premium) {
            return premium ? 'Премиум' : 'Ежедневный';
        },

        deSelect(val) {
            if (!val) {
                this.getUserGifts();
            }
        }
    },
    mounted() {
        this.getUserGifts();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-7 col-12 order-2 order-sm-1">
                <logs-timeline
                    v-model:loading="requestPending"
                    :items="subscriberGifts.items"
                    :pagination="subscriberGifts.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>{{ item.gift.name[localLang] }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <div>
                            <small>{{ $t('titles.type') }}: {{ isPremium(item.premium) }}</small>
                        </div>
                        <div>
                            <small
                                >{{ $t('titles.price') }}: {{ item.price }} {{ $t('sum') }}</small
                            >
                        </div>
                        <div>
                            <small>{{ $t('titles.step') }}: {{ item.step }}</small>
                        </div>
                    </template>
                </logs-timeline>
            </b-col>
            <b-col v-if="gifts" class="mb-2 mb-sm-0 col-lg-6 col-sm-5 col-12 order-1 order-sm-2">
                <filter-block v-model:filter="filter" :filter="filter" @submit="getUserGifts">
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('navigation.gifts') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.gift_id"
                                :options="computedGifts"
                                :reduce="(gift) => gift.id"
                                :searchable="false"
                                label="name"
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('titles.step') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.step"
                                :options="computedSteps"
                                :searchable="false"
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <h5>
                            {{ $t('titles.type') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.type"
                                :options="categoryTypes"
                                :reduce="(type) => type.key"
                                :searchable="false"
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                </filter-block>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
