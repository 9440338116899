import { axiosOriginal } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class DealerService extends CoreV2 {
    constructor() {
        super(axiosOriginal());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'v1/dashboardGf/' : 'v1/dashboardAl/';
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'account/getAll', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `account/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'account/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `account/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `account/delete/${id}`);
    }
}

export default DealerService;
