import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class ReferralPrizeService extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v3/referral/prize/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('v3/referral/prize/findOne', body);
    }

    createItem(body) {
        return this.post('v3/referral/prize', body);
    }

    updateItem(body) {
        return this.post('v3/referral/prize/update', body);
    }

    deleteItem(body) {
        return this.post('v3/referral/prize/remove', body);
    }

    fetchInfoReferral(body) {
        return this.post('v3/referral/info/get', body);
    }

    updateInfoReferral(body) {
        return this.post('v3/referral/info/update', body);
    }

    fetchInvitationStatistics(body) {
        return this.post('statistics/invitation/overall', body);
    }
}

export default ReferralPrizeService;
