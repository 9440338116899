import { axiosInstanceV2 } from '@/services/core/base';
import Core from '@/services/core';

class Ads extends Core {
    constructor(url) {
        super(axiosInstanceV2, url);
    }

    getCompanies(body) {
        return this.customPost('/campaign/findAll', body);
    }

    createCompany(body) {
        return this.customPost('/campaign', body);
    }

    updateCompany(body) {
        return this.customPost('/campaign/update', body);
    }

    deleteCompany(body) {
        return this.customPost('/campaign/remove', body);
    }

    getAdvertisements(body) {
        return this.customPost('/advertisement/findAll', body);
    }

    getOneAdvertisement(body) {
        return this.customPost('/advertisement/findOne', body);
    }

    createAdvertisement(body) {
        return this.customPost('/advertisement', body);
    }

    updateAdvertisement(body) {
        return this.customPost('/advertisement/update', body);
    }

    deleteAdvertisement(body) {
        return this.customPost('/advertisement/remove', body);
    }
}

export default Ads;
