import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Notifications extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllNotifications(params) {
        return this.get('notification', params);
    }

    fetchOneNotification(id, params) {
        return this.get(`notification/${id}`, params);
    }

    createNotification(body) {
        return this.post('notification', body);
    }

    updateNotification(id, body) {
        return this.put(`notification/${id}`, body);
    }

    deleteNotification(id) {
        return this.delete(`notification/${id}`);
    }

    // SERVER KEY
    fetchAllServerKeys(params) {
        return this.get('server-keys', params);
    }

    fetchOneServerKey(id, params) {
        return this.get(`server-keys/${id}`, params);
    }

    createServerKey(body) {
        return this.post('server-keys', body);
    }

    updateServerKey(id, body) {
        return this.put(`server-keys/${id}`, body);
    }

    deleteServerKey(id) {
        return this.delete(`server-keys/${id}`);
    }
}

export default Notifications;
