import Core from '@/services/axios/core.axios';
import { axiosV2 } from '@/services/core/base';

class SubscriberStreakService extends Core {
    constructor() {
        super(axiosV2({}));
    }

    fetchSubscriberStreak(body) {
        return this.post('v2/scenario/user-data/findOne', body);
    }

    setScoreToSubscriber(body) {
        return this.post('v2/scenario/user-data/handle-input', body);
    }

    restartSubscriber(body) {
        return this.post('v2/scenario/user-data/restart', body);
    }
}

export const subscriberStreakApi = new SubscriberStreakService();
