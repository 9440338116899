<script>
export default {
    name: 'index'
};
</script>

<template>
    <router-view />
</template>

<style scoped lang="scss"></style>
