<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';

export default {
    name: 'ViewUcellPackList',
    components: { ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUcell.fetchPackList({
                    phone: parseInt(this.phone)
                });
                this.response = data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>

                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <b-button @click="getResponse" variant="success">{{
                        $t('button.filter')
                    }}</b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <div v-else class="json__card card p-1 mb-0">
                    <div v-if="response" class="json__card-content">
                        <div v-for="(value, key, index) in response" :key="value + index + key">
                            <span
                                class="d-block mt-1 mb-0 text-info font-medium-2 font-weight-bold"
                            >
                                {{ key }}
                            </span>
                            <p
                                v-for="(childValue, childIndex) in value"
                                :key="childValue + childIndex"
                            >
                                <span
                                    class="d-block"
                                    v-for="(child2Value, child2Key, child2Index) in childValue"
                                    :key="child2Value + child2Key + child2Index"
                                >
                                    <span class="font-medium-2 text-warning font-weight-bold">{{
                                        child2Key
                                    }}</span>
                                    <span class="mx-1 font-medium-2 font-weight-bold">:</span>
                                    <span class="font-medium-2 text-success font-weight-bold">{{
                                        child2Value
                                    }}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <h4 v-else>No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
