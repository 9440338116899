<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import { isArray, isObject } from '@/util/inspect.util';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'ViewInfinityGroups',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            childItems: [],
            dataForModal: {
                name: {
                    en: '',
                    ru: '',
                    uz: ''
                },
                default_proc: '',
                is_internet: false,
                is_night_internet: false,
                is_sms: false,
                is_voice: false,
                is_immortal: false
            },
            dataForChildModal: {
                name: {
                    en: '',
                    ru: '',
                    uz: ''
                },
                group_id: '',
                types: '',
                proc: '',
                activation_item_id: null,
                images: [],
                upload_id: null,
                qty: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            childPagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            groupsList: [],
            group: {
                defaultProc: '',
                procTrace: true
            },
            staticTypesList: [
                {
                    name: {
                        uz: 'Paket',
                        ru: 'Пакет',
                        en: 'Package'
                    },
                    value: 0
                },
                {
                    name: {
                        uz: 'Fit-Coin',
                        ru: 'Fit-Coin',
                        en: 'Fit-Coin'
                    },
                    value: 1
                },
                {
                    name: {
                        uz: 'Chipta',
                        ru: 'Билет',
                        en: 'Ticket'
                    },
                    value: 2
                },
                {
                    name: {
                        uz: 'Paynet',
                        ru: 'Paynet',
                        en: 'Paynet'
                    },
                    value: 3
                },
                {
                    name: {
                        uz: 'Level',
                        ru: 'Левел',
                        en: 'Level'
                    },
                    value: 4
                },
                {
                    name: {
                        uz: "Sovg'a",
                        ru: 'Приз',
                        en: 'Prize'
                    },
                    value: 5
                }
            ],
            packagesList: [],
            levelActionsList: [],
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            childRequestPending: false,
            openedButton: false,
            activeGroup: null,
            openedGroupID: null,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            is_unlimited: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'childPagination.current': {
            handler() {
                this.getAllProducts();
            }
        },

        uploadImage(image) {
            this.dataForChildModal.images = [];
            if (isArray(image)) {
                this.dataForChildModal.images = image;
            } else if (isObject(image)) {
                this.dataForChildModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'show_details',
                    label: ''
                },
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'default_proc',
                    label: this.$t('titles.weight')
                },
                {
                    key: 'is_voice',
                    label: this.$t('titles.is_voice')
                },
                {
                    key: 'is_sms',
                    label: this.$t('titles.is_sms')
                },
                {
                    key: 'is_internet',
                    label: this.$t('titles.is_internet')
                },
                {
                    key: 'is_night_internet',
                    label: this.$t('titles.is_night_internet')
                },
                {
                    key: 'is_active',
                    label: this.$t('titles.is_active')
                },
                {
                    key: 'is_immortal',
                    label: this.$t('titles.is_immortal')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        childFields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'upload',
                    label: this.$t('titles.image')
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'activation_item_id',
                    label: this.$t('titles.activation_item_id')
                },
                {
                    key: 'proc',
                    label: this.$t('titles.weight')
                },
                {
                    key: 'is_active',
                    label: this.$t('titles.is_active')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'child_actions',
                    label: ''
                }
            ];
        },

        checkedLabels() {
            if (this.dataForChildModal.types === 0) {
                return this.$t('titles.packages');
            } else if (this.dataForChildModal.types === 1) {
                return this.$t('titles.coin_amount');
            } else if (this.dataForChildModal.types === 2) {
                return this.$t('titles.ticket_qty');
            } else if (this.dataForChildModal.types === 3) {
                return this.$t('titles.paynet_sum');
            } else if (this.dataForChildModal.types === 4) {
                return this.$t('titles.level_type');
            } else if (this.dataForChildModal.types === 5) {
                return this.$t('titles.prize');
            }
            return this.$t('titles.packages');
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },

        hasChildItems() {
            return this.childItems && this.childItems.length > 0;
        },

        showChildPagination() {
            return this.hasChildItems && !this.childRequestPending;
        }
    },

    async mounted() {
        await this.getBillingPackages();
        await this.getLevelActions();
    },

    methods: {
        checkLocales,
        checkLang,

        handleChildPagination(newPage) {
            this.childPagination.current = newPage;
        },

        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.infinityGroups.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
                this.groupsList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllProducts() {
            const groupItem = this.activeGroup?.item;
            this.openedButton = !this.openedButton;
            let response = [];
            let products = [];
            const params = {
                page: this.childPagination.current,
                limit: this.childPagination.perPage,
                group_id: groupItem?.id
            };
            try {
                this.requestPending = true;
                const { data } = await api.infinityProducts.fetchAllItems(params);
                response = data.data.list;
                this.items.forEach((group, index) => {
                    response.forEach((product) => {
                        if (group.id === product.group_id) {
                            products.push(product);
                            this.items[index] = {
                                ...group,
                                products: products
                            };
                        }
                    });
                });
                products = [];
                this.childItems = response;
                this.childPagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async getBillingPackages() {
            const body = {
                page: 1,
                limit: 200
            };

            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.packagesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getLevelActions() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevelActions.fetchAllItems(body);
                this.levelActionsList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.infinityGroups
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getOneProduct(item) {
            this.updatingItem = item;
            if (item) {
                await this.openChildModal();
                await api.infinityProducts
                    .fetchOneItem(item.id)
                    .then((response) => {
                        // merge Object for need data
                        const res = response.data.data;
                        const image = res.upload;

                        Object.keys(res).forEach((item) => {
                            if (item in this.dataForChildModal) {
                                this.dataForChildModal[item] = res[item];
                            }
                        });
                        this.dataForChildModal.activation_item_id = parseInt(
                            this.dataForChildModal.activation_item_id
                        );
                        if (res.qty === null) {
                            this.dataForChildModal.qty = null;
                            this.is_unlimited = true;
                        } else {
                            this.dataForChildModal.qty = res.qty;
                            this.is_unlimited = false;
                        }

                        if (image) {
                            this.uploadImage = {
                                id: image.id,
                                name: image.name,
                                size: image.size,
                                type: 'image/jpg',
                                url: image.path,
                                src: image.path
                            };
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        async createItem(body) {
            await api.infinityGroups
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async createProduct(body) {
            await api.infinityProducts
                .createItem(body)
                .then(async () => {
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                    this.$refs.createUpdateChildModal.hide();
                    this.uploadImage = null;
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.infinityGroups
                .updateItem(id, body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateProduct(id, body) {
            await api.infinityProducts
                .updateItem(id, body)
                .then(() => {
                    this.getAll();
                    this.$refs.createUpdateChildModal.hide();
                    this.uploadImage = null;
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.infinityGroups
                .deleteItem(id)
                .then(() => {
                    this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async deleteProduct(val) {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.infinityProducts
                        .deleteItem(val.id)
                        .then(async () => {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            await this.getAll();
                        })
                        .catch((e) => {
                            console.error(e, 'error');
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async submitChildModal() {
            this.$refs.createUpdateChildModalValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForChildModal;

                    if (this.dataForChildModal.images[0] && this.dataForChildModal.images[0].id) {
                        body.upload_id = this.dataForChildModal.images[0].id;
                    } else {
                        body.upload_id = null;
                    }

                    delete body.images;

                    if (body.types !== 4) {
                        body.activation_item_id = parseInt(body.activation_item_id);
                    }

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateProduct(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createProduct(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async updateGroupPercentage() {
            const body = this.group;
            try {
                await api.infinityGroups.updateItemPercentage(this.openedGroupID, body);
                this.$refs.groupPercentageModal.hide();
                await this.getAll();
            } catch (e) {
                console.error(e);
            }
        },

        async openGroupPercentageModal(item) {
            this.openedGroupID = item.id;
            this.$refs.groupPercentageModal.show();
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        clearActivationItem() {
            this.dataForChildModal.activation_item_id = null;
        },

        toggleDetails(row) {
            this.setChildPaginationByDefault();
            this.items.forEach((item) => {
                if (row.item.id === item.id) {
                    row.toggleDetails();
                    this.activeGroup = row;
                } else {
                    item._showDetails = false;
                }
            });
            if (!row['detailsShowing']) {
                this.getAllProducts(row);
            }
        },

        async openChildModal(item) {
            this.$refs.createUpdateChildModal.show();
            this.dataForChildModal = {
                name: {
                    en: '',
                    ru: '',
                    uz: ''
                },
                group_id: '',
                types: '',
                proc: '',
                activation_item_id: ''
            };
            if (item?.id) {
                this.dataForChildModal.group_id = item.id;
            }
        },

        setChildPaginationByDefault() {
            this.childPagination = {
                current: 1,
                totalItem: '',
                perPage: 10
            };
        },

        async changeItemStatus(val) {
            const isActive = val.is_active;
            const body = {
                is_active: !isActive
            };
            this.requestPending = true;
            try {
                await api.infinityGroups.updateItemStatus(val.id, body);
                await this.getAll();
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async changeProductStatus(val) {
            const isActive = val.is_active;
            const body = {
                is_active: !isActive
            };
            this.requestPending = true;
            try {
                await api.infinityProducts.updateItemStatus(val.id, body);
                await this.getAll();
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        openBannerModal(item) {
            const image = item.upload;
            if (image) {
                this.uploadImage = {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                };
            }

            this.openModalToUpdate(item);
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForChildModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('infinityProducts', formData, config)
                        .then(({ data }) => {
                            this.dataForChildModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        },

        disableQtyAndEqualsToNull(val) {
            if (val === null) {
                this.dataForChildModal.qty = null;
            } else {
                if (this.dataForChildModal.qty) {
                    this.dataForChildModal.qty = parseInt(this.dataForChildModal.qty);
                } else {
                    this.dataForChildModal.qty = null;
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> Infinity {{ $t('tournament.groups') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(show_details)="row">
                    <div>
                        <b-button
                            :class="openedButton ? 'opened' : ''"
                            class="p-1 svg__button"
                            size="sm"
                            variant="outline-primary"
                            @click="toggleDetails(row)"
                        >
                            <feather-icon
                                :icon="row.item._showDetails ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                                class="svg__icon"
                                size="20"
                            />
                        </b-button>
                    </div>
                </template>

                <template #cell(is_immortal)="{ item }">
                    <feather-icon
                        :class="[item.is_immortal ? 'text-warning' : 'text-secondary']"
                        :icon="item.is_immortal ? 'StarIcon' : 'StarIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_sms)="{ item }">
                    <feather-icon
                        :class="[item.is_sms ? 'text-success' : 'text-danger']"
                        :icon="item.is_sms ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_voice)="{ item }">
                    <feather-icon
                        :class="[item.is_voice ? 'text-success' : 'text-danger']"
                        :icon="item.is_voice ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_internet)="{ item }">
                    <feather-icon
                        :class="[item.is_internet ? 'text-success' : 'text-danger']"
                        :icon="item.is_internet ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_night_internet)="{ item }">
                    <feather-icon
                        :class="[item.is_night_internet ? 'text-success' : 'text-danger']"
                        :icon="item.is_night_internet ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #row-details="row">
                    <progress-linear v-if="childRequestPending" />
                    <b-card v-else class="child__table">
                        <b-table
                            :fields="childFields"
                            :items="row.item.products"
                            :responsive="true"
                            borderless
                            outlined
                            striped
                            thead-class="bg__red"
                        >
                            <template #cell(name)="{ item }">
                                {{ checkLang(item.name) }}
                            </template>

                            <template #cell(qty)="{ item }">
                                <span v-if="item.qty === null" class="infinity-icon">&#8734;</span>
                                <span v-else-if="item.qty <= 0" class="infinity-text text-danger">{{
                                    item.qty
                                }}</span>
                                <span v-else class="infinity-text text-info">{{ item.qty }}</span>
                            </template>

                            <template #cell(activation_item_id)="{ item }">
                                <b-badge :variant="item.activation_item_id.color"
                                    >{{ item.activation_item_id.text.toUpperCase() }}
                                </b-badge>
                            </template>

                            <template #cell(upload)="{ item }">
                                <div v-if="item.upload_id">
                                    <img
                                        v-if="item.upload_id || item.upload_id.path"
                                        :src="item.upload_id"
                                        alt=""
                                        class="table-img"
                                    />
                                    <span v-else>No Photo</span>
                                </div>
                            </template>

                            <template #cell(is_active)="{ item }">
                                <b-form-checkbox
                                    :checked="item.is_active"
                                    class="custom-control-success"
                                    name="check-button"
                                    switch
                                    @change="changeProductStatus(item)"
                                />
                            </template>

                            <template #cell(child_actions)="{ item }">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.edit')}`"
                                        class="btn-icon"
                                        variant="flat-dark"
                                        @click="getOneProduct(item)"
                                    >
                                        <feather-icon icon="EditIcon" size="16" />
                                    </b-button>
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.delete')}`"
                                        class="btn-icon"
                                        variant="flat-danger"
                                        @click="deleteProduct(item)"
                                    >
                                        <feather-icon icon="Trash2Icon" size="16" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>

                        <!--  CHILD PAGINATION  -->
                        <b-row class="my-2">
                            <b-col class="offset-3" cols="9">
                                <b-pagination
                                    v-if="showChildPagination"
                                    v-model="childPagination.current"
                                    :per-page="childPagination.perPage"
                                    :total-rows="childPagination.totalItem"
                                    class="float-right mr-4 mb-0"
                                    first-number
                                    last-number
                                    @input="handleChildPagination"
                                />
                            </b-col>
                        </b-row>
                    </b-card>
                </template>

                <template #cell(name)="{ item }">
                    {{ checkLang(item.name) }}
                </template>

                <template #cell(default_proc)="{ item }">
                    <div
                        class="percent-btn"
                        style="display: flex; align-items: center; justify-content: flex-end"
                    >
                        <span>{{ item.default_proc }}</span>
                        <b-button
                            v-b-tooltip.hover.top="`${$t('button.edit_group_percentage')}`"
                            class="btn-icon ml-1"
                            variant="primary"
                            @click="openGroupPercentageModal(item)"
                        >
                            <feather-icon icon="PercentIcon" size="16" />
                        </b-button>
                    </div>
                </template>

                <template #cell(is_active)="{ item }">
                    <b-form-checkbox
                        :checked="item.is_active"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        @change="changeItemStatus(item)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-b-tooltip.hover.top="`${$t('button.create_product')}`"
                            class="btn-icon"
                            variant="flat-success"
                            @click="openChildModal(item)"
                        >
                            <feather-icon icon="PlusIcon" size="16" />
                        </b-button>
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="
                updatingItem ? `${$t('edit.group')} [Infinity]` : `${$t('add.group')} [Infinity]`
            "
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME [UZ]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [UZ]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [RU]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [RU]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [EN]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [EN]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DEFAULT PROC    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.default_percentage') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.default_percentage')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.default_proc"
                                    :placeholder="$t('enter.default_percentage')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS SMS    -->
                    <b-col class="d-flex mt-2 user-select-none" cols="6">
                        <b-form-checkbox
                            id="is_sms"
                            v-model="dataForModal.is_sms"
                            :disabled="isViewModal"
                        />
                        <label class="font-medium-1" for="is_sms">
                            {{ $t('titles.is_sms') }}
                        </label>
                    </b-col>

                    <!--    IS VOICE    -->
                    <b-col class="d-flex mt-2 user-select-none" cols="6">
                        <b-form-checkbox
                            id="is_voice"
                            v-model="dataForModal.is_voice"
                            :disabled="isViewModal"
                        />
                        <label class="font-medium-1" for="is_voice">
                            {{ $t('titles.is_voice') }}
                        </label>
                    </b-col>

                    <!--    IS INTERNET    -->
                    <b-col class="d-flex mt-2 user-select-none" cols="6">
                        <b-form-checkbox
                            id="is_internet"
                            v-model="dataForModal.is_internet"
                            :disabled="isViewModal"
                        />
                        <label class="font-medium-1" for="is_internet">
                            {{ $t('titles.is_internet') }}
                        </label>
                    </b-col>

                    <!--    IS NIGHT INTERNET    -->
                    <b-col class="d-flex mt-2 user-select-none" cols="6">
                        <b-form-checkbox
                            id="is_night_internet"
                            v-model="dataForModal.is_night_internet"
                            :disabled="isViewModal"
                        />
                        <label class="font-medium-1" for="is_night_internet">
                            {{ $t('titles.is_night_internet') }}
                        </label>
                    </b-col>

                    <!--    IS NIGHT INTERNET    -->
                    <b-col class="d-flex mt-2 user-select-none" cols="6">
                        <b-form-checkbox
                            id="is_immortal"
                            v-model="dataForModal.is_immortal"
                            :disabled="isViewModal"
                        />
                        <label class="font-medium-1" for="is_immortal">
                            {{ $t('titles.is_immortal') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    CREATE UPDATE CHILD MODAL    -->
        <b-modal
            id="createUpdateChildModal"
            ref="createUpdateChildModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="
                updatingItem
                    ? `${$t('edit.product')} [Infinity]`
                    : `${$t('add.product')} [Infinity]`
            "
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateChildModalValidation">
                <b-row>
                    <!--     GROUP ID     -->
                    <b-col class="mb-1" cols="12">
                        <b-form-group :label="$t('group')" label-for="categories">
                            <v-select
                                id="categories"
                                v-model="dataForChildModal.group_id"
                                :clearable="false"
                                :disabled="isViewModal || !!updatingItem"
                                :options="groupsList"
                                :placeholder="$t('choose.group')"
                                :reduce="(option) => option.id"
                                label="name"
                            >
                                <template v-slot:option="option">
                                    {{ checkLang(option.name) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLang(option.name) }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>

                    <!--    NAME [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [RU]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [RU]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [EN]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [EN]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPES    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.types') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.types')"
                            rules="required"
                        >
                            <v-select
                                id="categories"
                                v-model="dataForChildModal.types"
                                :clearable="false"
                                :disabled="isViewModal"
                                :options="staticTypesList"
                                :placeholder="$t('choose.type')"
                                :reduce="(option) => option.value"
                                label="name"
                                @input="clearActivationItem"
                            >
                                <template v-slot:option="option">
                                    {{ checkLang(option.name) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLang(option.name) }}
                                </template>
                            </v-select>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ACTIVATION ITEM ID    -->
                    <b-col v-if="dataForChildModal.types !== 5" cols="12">
                        <label>{{ checkedLabels }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="checkedLabels"
                            :rules="
                                dataForChildModal.types === 3
                                    ? 'required|min_value:500'
                                    : 'required'
                            "
                        >
                            <b-form-group>
                                <v-select
                                    v-if="dataForChildModal.types === 4"
                                    id="categories"
                                    v-model="dataForChildModal.activation_item_id"
                                    :clearable="false"
                                    :disabled="isViewModal"
                                    :options="levelActionsList"
                                    :placeholder="checkedLabels"
                                    :reduce="(option) => option.type"
                                    label="name"
                                />
                                <b-form-input
                                    v-else-if="
                                        dataForChildModal.types === 1 ||
                                        dataForChildModal.types === 2 ||
                                        dataForChildModal.types === 3
                                    "
                                    v-model="dataForChildModal.activation_item_id"
                                    :placeholder="checkedLabels"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                                <v-select
                                    v-else
                                    id="categories"
                                    v-model="dataForChildModal.activation_item_id"
                                    :clearable="false"
                                    :disabled="isViewModal"
                                    :options="packagesList"
                                    :placeholder="checkedLabels"
                                    :reduce="(option) => option.id"
                                    label="name"
                                >
                                    <template v-slot:option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PROC    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.percentage') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.percentage')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.proc"
                                    :placeholder="$t('enter.percentage')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    QTY    -->
                    <b-col
                        v-if="
                            !is_unlimited &&
                            (dataForChildModal.qty ||
                                dataForChildModal.qty === 0 ||
                                dataForChildModal.qty === null)
                        "
                        cols="12"
                    >
                        <label>{{ $t('titles.qty') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.qty')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForChildModal.qty"
                                    :disabled="is_unlimited"
                                    :placeholder="$t('enter.qty')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS UNLIMITED    -->
                    <b-col class="d-flex justify-content-center mt-2 user-select-none" cols="12">
                        <b-form-checkbox
                            id="is_unlimited"
                            v-model="is_unlimited"
                            :disabled="isViewModal"
                            @change="disableQtyAndEqualsToNull(is_unlimited)"
                        />
                        <label class="font-medium-1" for="is_unlimited">
                            {{ $t('titles.is_unlimited') }}
                        </label>
                    </b-col>

                    <!--    IMAGE ID    -->
                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <!--                            <ValidationProvider-->
                            <!--                                v-slot="{ errors }"-->
                            <!--                                :name="$t('titles.image')"-->
                            <!--                                rules="required"-->
                            <!--                            >-->
                            <VueFileAgent
                                ref="vueFileAgent"
                                v-model="uploadImage"
                                :accept="'image/*,.zip'"
                                :deletable="true"
                                :errorText="{
                                    type: $t('vue_file_agent.file_type_error'),
                                    size: $t('vue_file_agent.size_type_error')
                                }"
                                :helpText="$t('vue_file_agent.choose_image')"
                                :maxFiles="14"
                                :maxSize="'10MB'"
                                :meta="true"
                                :multiple="false"
                                :theme="'grid'"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                @select="filesSelected($event)"
                            >
                            </VueFileAgent>

                            <b-button
                                :disabled="!fileRecordsForUpload.length || imageUploaded"
                                :variant="imageUploaded ? 'success' : 'primary'"
                                class="float-right mt-1"
                                @click="getImageId()"
                            >
                                <span v-if="!imageUploaded">
                                    {{ $t('vue_file_agent.upload_image') }}
                                </span>
                                <span v-else>
                                    {{ $t('vue_file_agent.image_uploaded') }}
                                </span>
                            </b-button>
                            <!--                                <span class="validation__red">-->
                            <!--                                    {{ errors[0] }}-->
                            <!--                                </span>-->
                            <!--                            </ValidationProvider>-->
                        </b-col>
                    </div>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitChildModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    UPDATE GROUP PERCENTAGE    -->
        <b-modal
            id="groupPercentageModal"
            ref="groupPercentageModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="
                updatingItem
                    ? `${$t('edit.product')} [Infinity]`
                    : `${$t('add.product')} [Infinity]`
            "
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="itemPercentageModalValidation">
                <b-row>
                    <!--    DEFAULT PROC    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.default_percentage') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.default_percentage')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="group.defaultProc"
                                    :placeholder="$t('enter.default_percentage')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="updateGroupPercentage">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.svg__icon {
    pointer-events: none;
}

.child__table .card-body {
    flex-direction: column;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.infinity-icon {
    font-size: 24px;
    color: var(--secondary);
    margin-left: 20%;
}

.infinity-text {
    font-size: 17px;
    margin-left: 20%;
}
</style>
