<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CompStackedChart from '@/components/CompStackedChart.vue';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import api from '@/services/api';

export default {
    name: 'AppOrderStatistics',
    components: { CompStackedChart, ProgressLinear },
    data() {
        return {
            orderStatistics: {
                isPending: true,
                categories: [],
                quantity: {
                    series: [],
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: false,
                        y: {
                            show: true,
                            formatter: function (val) {
                                if (val.toString().length < 4) {
                                    return val.toString();
                                } else {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                                // return val;
                            }
                        },
                        x: {
                            show: true,
                            formatter: function (val, opts) {
                                if (val.toString().length < 4) {
                                    return val.toString();
                                } else {
                                    return (
                                        val +
                                        ' - ' +
                                        opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    );
                                }
                            }
                        },
                        fixed: {
                            enabled: true,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0
                        }
                    }
                },
                sum: {
                    series: [],
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: true,
                        y: {
                            show: true,
                            formatter: function (val) {
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        },
                        x: {
                            show: true,
                            formatter: function (val, opts) {
                                return (
                                    val +
                                    ' - ' +
                                    opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                );
                            }
                        },
                        fixed: {
                            enabled: true,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0
                        }
                    }
                }
            }
        };
    },
    async mounted() {
        await this.getOrderStatistics();
    },
    methods: {
        // ORDER STATISTICS
        async getOrderStatistics() {
            this.orderStatistics.quantity.series.length = 0;
            this.orderStatistics.sum.series.length = 0;
            this.orderStatistics.categories.length = 0;
            this.orderStatistics.isPending = true;
            try {
                const { data } = await api.otherStatistics.fetchOrderStatistics({
                    date_start: this.date_start,
                    date_end: this.date_end,
                    merchant_id: parseInt(MERCHANT_ID)
                });

                const orderQuantitySeriesMap = new Map();
                const orderSumSeriesMap = new Map();

                // ORDER QUANTITY
                data.data.dates.forEach((dateObj) => {
                    dateObj.statuses.forEach((item) => {
                        if (!orderQuantitySeriesMap.has(item.name)) {
                            orderQuantitySeriesMap.set(item.name, {
                                name: checkLocales(item.name),
                                data: []
                            });
                            orderSumSeriesMap.set(item.name, {
                                name: checkLocales(item.name),
                                data: []
                            });
                        }
                    });
                });

                data.data.dates.forEach((dateObj) => {
                    const dateSeries = new Map(orderQuantitySeriesMap);
                    dateObj.statuses.forEach((item) => {
                        dateSeries.get(item.name).data.push(item.count);
                    });
                    dateSeries.forEach((series, name) => {
                        if (!dateObj.statuses.some((item) => checkLocales(item.name) === name)) {
                            series.data.push(0);
                        }
                    });
                    this.orderStatistics.categories.push(dateObj.date.substring(5));
                });

                this.orderStatistics.quantity.series = Array.from(
                    orderQuantitySeriesMap.values()
                ).filter((series) => series.data.some((count) => count !== 0));

                // DEBIT SUM
                data.data.dates.forEach((dateObj) => {
                    const dateSeries = new Map(orderSumSeriesMap);
                    dateObj.statuses.forEach((item) => {
                        dateSeries.get(item.name).data.push(item.sum);
                    });
                    dateSeries.forEach((series, name) => {
                        if (!dateObj.statuses.some((item) => checkLocales(item.name) === name)) {
                            series.data.push(0);
                        }
                    });
                });

                this.orderStatistics.sum.series = Array.from(orderSumSeriesMap.values()).filter(
                    (series) => series.data.some((sum) => sum !== 0)
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.orderStatistics.isPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="row">
        <!--      ORDER STATISTICS [QUANTITY]      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="orderStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="orderStatistics.quantity.tooltip"
                    :is-pending="orderStatistics.isPending"
                    :series="orderStatistics.quantity.series"
                    :categories="orderStatistics.categories"
                    :title="$t('titles.order_statistics_quantity')"
                />
            </div>
        </div>

        <!--      ORDER STATISTICS [SUM]      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="orderStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="orderStatistics.sum.tooltip"
                    :is-pending="orderStatistics.isPending"
                    :series="orderStatistics.sum.series"
                    :categories="orderStatistics.categories"
                    :title="$t('titles.order_statistics_sum')"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
