<script>
import { BButton, BCol, BFormGroup, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import api from '@/services/api';
import { deletePropertiesWhichNoValue } from '@/util/helper';
import LogsTimeline from '@/components/LogsTimeline';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'SubscribersSingleManualPackets',
    components: {
        LogsTimeline,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        vSelect
    },
    inject: ['gifts', 'steps'],
    data: () => ({
        logs: {
            items: [],
            pagination: {}
        },
        localLang: localStorage.getItem('lang'),
        filter: {
            page: 1,
            action: 'set_manual_gift'
        },
        requestBody: {
            page: 1,
            limit: 10,
            status: 'all'
        },
        body: {
            phone: null,
            packageId: null,
            service: 'dashboard',
            comment: ''
        },
        packageList: [],
        requestPending: false
    }),
    methods: {
        async getUserLogs() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            const { data } = await api.subscriberV2Api.fetchManualPackageActivationLogs({
                ...this.requestBody,
                user_id: parseInt(this.$route.params.id)
            });
            if (data.data.pagination.current === 1) {
                this.logs.items = data.data.list;
            } else {
                this.logs.items = [...this.logs.items, ...data.data.list];
            }
            this.logs.pagination = data.data.pagination;
            this.requestPending = false;
        },

        async getPackageList() {
            const body = {
                page: 1,
                limit: 1000
            };
            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.packageList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: item.type_name.toUpperCase() + ' ' + item.qty
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getSubscriber() {
            if (parseInt(MERCHANT_ID) === 2) {
                await api.subscribers.getSingle(this.$route.params.id).then(({ data }) => {
                    this.body.phone = data.phone;
                });
            } else {
                await api.lampSubscribers
                    .fetchOneSubscriber(this.$route.params.id)
                    .then(({ data }) => {
                        this.body.phone = data.result.phone;
                    });
            }
        },

        async setPackageManually() {
            try {
                await api.billingPackages.setPackageManually(this.body);
                this.$toast.success(this.$t('success.appointed_successfully'));
            } catch (e) {
                console.error(e);
            } finally {
                await this.getUserLogs();
                this.body.comment = '';
                this.body.packageId = null;
            }
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            await this.getUserLogs();
        }
    },
    async mounted() {
        await this.getUserLogs();
        await this.getSubscriber();
        await this.getPackageList();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-7 col-12 order-2 order-sm-1">
                <logs-timeline
                    :items="logs.items"
                    :pagination="logs.pagination"
                    v-model:loading="requestPending"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <div class="text-uppercase">
                                <var class="mr-1">PRIZE:</var>
                                <b-badge variant="primary" class="font-small-4"
                                    >{{ item.package.name }} | {{ item.package.qty }}</b-badge
                                >
                            </div>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <div class="text-uppercase">
                            <var class="mr-1">ID:</var
                            ><span class="font-weight-bold font-medium-1">{{ item.id }}</span>
                        </div>
                        <div class="text-uppercase">
                            <var class="mr-1">service:</var
                            ><b-badge variant="primary" class="font-small-4">{{
                                item.service
                            }}</b-badge>
                        </div>
                        <div class="text-uppercase">
                            <var class="mr-1">activation at:</var>
                            <span class="font-weight-bold font-medium-1">{{
                                item.package.activation_at
                            }}</span>
                        </div>
                        <div class="text-uppercase">
                            <var class="mr-1">deactivation at:</var>
                            <span class="font-weight-bold font-medium-1">{{
                                item.package.deactivation_at
                            }}</span>
                        </div>
                        <div class="text-uppercase">
                            <var class="mr-1">comment:</var>
                            <span class="font-weight-bold font-medium-1">{{ item.comment }}</span>
                        </div>
                        <!--                        <div v-for="(data, index) in item.data" :key="index">-->
                        <!--                            <template v-if="index !== 'prize'">-->
                        <!--                                <small>{{ index }}: {{ data }}</small>-->
                        <!--                            </template>-->
                        <!--                        </div>-->
                    </template>
                </logs-timeline>
            </b-col>
            <b-col class="mb-2 mb-sm-0 col-lg-6 col-sm-5 col-12 order-1 order-sm-2" v-if="gifts">
                <div class="card w-100 m-0 p-2">
                    <!--                    <manual-packet-form-->
                    <!--                        :id="$route.params.id"-->
                    <!--                        :gifts="gifts.value"-->
                    <!--                        :steps="steps.value"-->
                    <!--                        @success="getUserLogs"-->
                    <!--                    />-->

                    <b-col cols="12" class="mb-2">
                        <h5>
                            {{ $t('titles.package') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="body.packageId"
                                :options="packageList"
                                label="name"
                                :reduce="(option) => option.id"
                                :searchable="true"
                                :placeholder="$t('titles.package')"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" class="mb-2">
                        <h5>
                            {{ $t('titles.comment') }}
                        </h5>
                        <b-form-group>
                            <b-form-textarea
                                v-model="body.comment"
                                class="pr-3"
                                :placeholder="$t('titles.comment')"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" class="mb-2 d-flex justify-content-end">
                        <b-button variant="primary" @click="setPackageManually">{{
                            $t('button.activate')
                        }}</b-button>
                    </b-col>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
