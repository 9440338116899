export default [
    {
        path: '/error-page',
        name: 'Error 404',
        component: () => import('@/views/miscellaneous/Error'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read'
        }
    },
    {
        path: '/login',
        name: 'Auth Login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true
        }
    }
];
