<script>
export default {
    name: 'ListDetailsIcon',
    props: {
        size: {
            type: [String, Number],
            default: 24
        },
        fill: {
            type: String,
            default: '#4B465C'
        }
    }
};
</script>

<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.8334 4.16667H17.5"
            :stroke="fill"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 4.16667H17.5"
            stroke="white"
            stroke-opacity="0.1"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 7.49992H15"
            :stroke="fill"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 7.49992H15"
            stroke="white"
            stroke-opacity="0.1"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 12.4999H17.5"
            :stroke="fill"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 12.4999H17.5"
            stroke="white"
            stroke-opacity="0.1"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 15.8334H15"
            :stroke="fill"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8334 15.8334H15"
            stroke="white"
            stroke-opacity="0.1"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="2.5"
            y="3.33325"
            width="5"
            height="5"
            rx="1"
            :stroke="fill"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="2.5"
            y="3.33325"
            width="5"
            height="5"
            rx="1"
            stroke="white"
            stroke-opacity="0.1"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="2.5"
            y="11.6667"
            width="5"
            height="5"
            rx="1"
            :stroke="fill"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="2.5"
            y="11.6667"
            width="5"
            height="5"
            rx="1"
            stroke="white"
            stroke-opacity="0.1"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<style></style>
