<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable,
    VBTooltip
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import flatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppDealerAccount',
    directives: {
        'b-tooltip': VBTooltip
    },
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        VBTooltip,
        flatPickr,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                login: '',
                password: '',
                shortCode: null,
                contact: null,
                details: null,
                is_active: false,
                service_percent: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            requestPending: false,
            changePassword: false,
            date_start: null,
            date_end: null,
            isDownloadModalOpened: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'short_code',
                    label: this.$t('titles.code')
                },
                {
                    key: 'login',
                    label: this.$t('titles.login')
                },
                {
                    key: 'contact',
                    label: this.$t('titles.contact')
                },
                {
                    key: 'details',
                    label: this.$t('titles.details')
                },
                {
                    key: 'service_percent',
                    label: `${this.$t('titles.service_percent')} [%]`
                },
                {
                    key: 'is_active',
                    label: this.$t('titles.is_active')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.dealerAccountsApi.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.dealerAccountsApi
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                    this.dataForModal.shortCode = response.data.data['short_code'];
                    delete this.dataForModal['short_code'];
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.dealerAccountsApi
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.dealerAccountsApi
                .updateItem(id, body)
                .then(() => {
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.dealerAccountsApi
                .deleteItem(id)
                .then(() => {
                    this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    if (this.dataForModal.password && this.dataForModal.password.length > 0) {
                        body.password = this.dataForModal.password;
                    } else {
                        body.password = null;
                    }
                    body.shortCode = parseInt(body.shortCode);
                    body.service_percent = parseInt(body.service_percent);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            // this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            // this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                        this.changePassword = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async downloadDealerStatistics() {
            try {
                const response = await api.dealersApi.downloadDealerTotalStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'filename.xlsx');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.isDownloadModalOpened = false;
            } catch (e) {
                console.error(e);
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.dealer_accounts') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button class="btn-icon mr-1" variant="primary" v-b-modal="'downloadExcelModal'">
                    <span class="mr-1">{{ $t('button.download') }}</span>
                    <feather-icon icon="DownloadIcon" />
                </b-button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(is_active)="{ item }">
                    <feather-icon
                        :class="[item.is_active ? 'text-success' : 'text-danger']"
                        :icon="item.is_active ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(details)="{ item }">
                    <span v-if="!item.details">{{ $t('no_data') }}</span>
                    <span v-else>{{ item.details }}</span>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            :to="{
                                path: `/dealers-accounts/statistics/${item.id}`,
                                params: { id: item.id }
                            }"
                            class="btn-icon"
                            v-b-tooltip.hover.top="`${$t('navigation.statistics')}`"
                            variant="flat-primary"
                        >
                            <!--                            <router-link-->
                            <!--                                :to="{-->
                            <!--                                    path: `/dealers-accounts/statistics/${item.id}`,-->
                            <!--                                    params: { value: item }-->
                            <!--                                }"-->
                            <!--                            >-->
                            <!--                            </router-link>-->
                            <feather-icon size="16" icon="ActivityIcon" />
                        </b-button>
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.dealer_account') : $t('add.dealer_account')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    LOGIN    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.login') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.login')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.login"
                                    :placeholder="$t('enter.login')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PASSWORD    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.password') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.password')"
                            :rules="updatingItem ? 'min:6' : 'required|min:6'"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.password"
                                    :placeholder="$t('enter.password')"
                                    :state="errors.length > 0 ? false : null"
                                    :disabled="updatingItem && !changePassword"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SHORT CODE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.code') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.code')"
                            rules="required|between:100,999"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.shortCode"
                                    :placeholder="$t('enter.code')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SERVICE PERCENT    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.service_percent') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.service_percent')"
                            rules="required|between:0,100"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.service_percent"
                                    :placeholder="$t('titles.service_percent')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    CONTACT    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.contact') }}</label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.contact"
                                :placeholder="$t('enter.contact')"
                                size="md"
                                type="number"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    DETAILS    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.details') }}</label>
                        <b-form-group>
                            <b-form-textarea
                                v-model="dataForModal.details"
                                :placeholder="$t('enter.details')"
                                size="md"
                                rows="5"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    IS ACTIVE    -->
                    <b-col class="d-flex justify-content-between mt-2 user-select-none" cols="12">
                        <div>
                            <b-form-checkbox id="is_active" v-model="dataForModal.is_active" />
                            <label class="font-medium-1" for="is_active">
                                {{ $t('titles.is_active') }}
                            </label>
                        </div>
                    </b-col>

                    <b-col class="d-flex justify-content-between mt-2 user-select-none" cols="12">
                        <div v-if="updatingItem">
                            <b-form-checkbox v-model="changePassword" switch />
                            <label class="font-medium-1" for="is_active">
                                {{ $t('titles.change_password') }}
                            </label>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--    DOWNLOAD MODAL    -->
        <b-modal
            v-model="isDownloadModalOpened"
            id="downloadExcelModal"
            ref="downloadExcelModal"
            :title="$t('button.download')"
        >
            <!--          DATE START          -->
            <label for="dateStart">{{ $t('titles.start_date') }}</label>
            <flat-pickr
                v-model="date_start"
                :config="{
                    allowInput: true
                }"
                autocomplete="off"
                class="form-control mb-1"
                name="date"
                :placeholder="$t('choose.date')"
                id="dateStart"
            />

            <!--          DATE END          -->
            <label for="dateStart">{{ $t('titles.end_date') }}</label>
            <flat-pickr
                v-model="date_end"
                :config="{
                    allowInput: true
                }"
                autocomplete="off"
                class="form-control"
                name="date"
                :placeholder="$t('choose.date')"
                id="dateEnd"
            />

            <template #modal-footer>
                <b-button @click="isDownloadModalOpened = false" variant="danger">
                    {{ $t('button.cancel') }}
                </b-button>
                <b-button @click="downloadDealerStatistics" variant="success">
                    {{ $t('button.confirm') }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: var(--secondary);
    margin: 2rem 0 1rem;
}
</style>
