<script>
import { BFormCheckbox, BFormGroup, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import services from '@/services';

export default {
    name: 'AppManualNotificationSegmentation',
    components: { BFormCheckbox, BFormGroup, BRow, vSelect },
    data() {
        return {
            templatesList: [],
            smsList: [],
            eventList: [],
            keyList: [],
            requestBody: {
                template_id: null,
                key: null,
                event_id: null,
                sms_type: null,
                ussd_only: false
            },
            is_sms: false,
            is_notification: false
        };
    },
    async mounted() {
        await this.getTemplatesList();
        await this.getSMSList();
        await this.getEventAndKeyList();
    },
    methods: {
        checkLocales,
        async getTemplatesList() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                page: 1,
                limit: 2000
            };
            try {
                const { data } = await api.notificationTemplatesApi.fetchAllItems(body);
                this.templatesList = data.result;
            } catch (e) {
                console.error(e);
            }
        },

        async getSMSList() {
            const body = {
                page: 1,
                limit: 200,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await services.sms.findAll(body);
                this.smsList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getEventAndKeyList() {
            try {
                const { data } = await api.segmentationApi.fetchCatalog({
                    merchant_id: parseInt(MERCHANT_ID)
                });
                this.eventList = data.data.events;
                this.keyList = data.data.values;
            } catch (e) {
                console.error(e);
            }
        },

        async sendManualNotificationSegmentation() {
            const body = {
                template_id: this.requestBody.template_id,
                key: this.requestBody.key,
                event_id: this.requestBody.event_id,
                merchant_id: parseInt(MERCHANT_ID),
                sms_type: this.requestBody.sms_type,
                ussd_only: this.requestBody.ussd_only
            };
            this.$refs.validationForm.validate().then(async (success) => {
                if (success) {
                    try {
                        const { data } =
                            await api.notificationManualApi.sendNotificationSegmentation(body);
                        console.log(data, data);
                    } catch (e) {
                        console.error(e);
                    } finally {
                        await this.clearFields();
                    }
                }
            });
        },

        clearFields() {
            this.requestBody = {
                template_id: null,
                key: null,
                event_id: null
            };
        }
    }
};
</script>

<template>
    <div class="manual__notification">
        <ValidationObserver ref="validationForm">
            <b-row>
                <!--     SMS     -->
                <div v-if="is_sms" class="col-12 col-xl-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.template')"
                        rules="required"
                    >
                        <b-form-group :label="`${$t('titles.template')} [SMS]`">
                            <v-select
                                v-if="is_sms"
                                :placeholder="`${$t('choose.template')} [SMS]`"
                                :options="smsList"
                                v-model="requestBody.sms_type"
                                :reduce="(option) => option.name"
                                label="name"
                                :clearable="false"
                            />
                        </b-form-group>
                        <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>

                <!--     TEMPLATE ID     -->
                <div v-if="is_notification" class="col-12 col-xl-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.template')"
                        rules="required"
                    >
                        <b-form-group
                            :label="`${$t('titles.template')} [${$t('titles.notification')}]`"
                        >
                            <v-select
                                :placeholder="`${$t('choose.template')} [${$t(
                                    'titles.notification'
                                )}]`"
                                :options="templatesList"
                                v-model="requestBody.template_id"
                                :reduce="(option) => option.id"
                                label="title"
                                :clearable="false"
                            >
                                <template v-slot:option="option">
                                    {{ checkLocales(option.title) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLocales(option.title) }}
                                </template>
                            </v-select>
                        </b-form-group>
                        <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>

                <!--    EVENT ID    -->
                <div class="col-12 col-xl-3">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('segmentation.event')"
                        rules="required"
                    >
                        <b-form-group :label="$t('segmentation.event')">
                            <v-select
                                :placeholder="$t('segmentation.event')"
                                :options="eventList"
                                v-model="requestBody.event_id"
                                :reduce="(option) => option"
                                :clearable="false"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <!--     KEY     -->
                <div class="col-12 col-xl-3">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.key')"
                        rules="required"
                    >
                        <b-form-group :label="$t('titles.key')">
                            <v-select
                                :placeholder="$t('titles.key')"
                                :options="keyList"
                                v-model="requestBody.key"
                                :reduce="(option) => option"
                                :clearable="false"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>

        <div
            class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between px-0 mt-1"
        >
            <div class="d-flex align-items-center justify-content-start mb-2">
                <b-form-checkbox v-model="is_sms" class="mr-2"> SMS </b-form-checkbox>
                <b-form-checkbox v-model="is_notification" class="mr-3">
                    {{ $t('titles.notification') }}
                </b-form-checkbox>
                <b-form-checkbox v-model="requestBody.ussd_only"> USSD </b-form-checkbox>
            </div>
            <b-button
                @click="sendManualNotificationSegmentation"
                class="float-right mb-2 mb-md-0"
                variant="success"
                :disabled="!is_sms && !is_notification"
            >
                {{ $t('button.send') }}
            </b-button>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
