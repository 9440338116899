<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BTable,
    BPagination,
    BFormTextarea
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import vSelect from 'vue-select';

export default {
    name: 'ServerKey',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BFormTextarea,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            merchantOptions: null,
            dataForModal: {
                server_key: '',
                merchant: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getServerKeys();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'merchant_id',
                    label: this.$t('app')
                },
                {
                    key: 'server_key',
                    label: this.$t('server_keys')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getMerchants();
        this.requestPending = false;
    },

    methods: {
        checkLang,
        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getServerKeys() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };
            await api.notifications
                .fetchAllServerKeys(body)
                .then((response) => {
                    this.items = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createServerKey(body) {
            body.merchant_id = body.merchant.id;
            delete body.merchant;
            await api.notifications
                .createServerKey(body)
                .then(({ data }) => {
                    this.items.push(data);
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async updateServerKey(body, config) {
            body.merchant_id = body.merchant.id;
            delete body.merchant;
            await api.notifications
                .updateServerKey(this.updatingItem.id, body, config)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteServerKey(id) {
            await api.notifications
                .deleteServerKey(id)
                .then(() => {
                    this.getServerKeys();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchServerKey(item) {
            await api.notifications
                .fetchOneServerKey(item.id)
                .then(({ data }) => {
                    const { id, merchant_id, server_key } = data.result;
                    const result = {
                        id,
                        merchant: merchant_id,
                        server_key
                    };
                    this.updatingItem = result;
                    this.openModalToUpdate(result);
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateServerKey(body, config);
                            await this.getServerKeys();
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createServerKey(body, config);
                            await this.getServerKeys();
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changeAppID(appID) {
            this.activeAppID = appID;
            this.getMeasurements();
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getMeasurements();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>{{ $t('server_keys') }}</template>
            <template #button v-if="hasAccess('coin_measurement', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                striped
                borderless
                outlined
                :responsive="true"
                :fields="fields"
                :items="items"
                class="news__table"
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(merchant_id)="{ item }">
                    {{ item.merchant_id.name }}
                </template>

                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :item="item"
                        :index="index"
                        :delete-handler="deleteServerKey"
                        :delete-access="{ section: 'coin_measurement', permission: 'delete' }"
                        :update-access="{ section: 'coin_measurement', permission: 'update' }"
                        @openModal="fetchServerKey"
                    />
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="true"
            size="md"
            :title="updatingItem ? $t('edit.server_keys') : $t('add.server_keys')"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    SERVER KEY    -->
                    <b-col cols="12">
                        <label> {{ $t('server_keys') }}</label>
                        <ValidationProvider
                            :name="$t('server_keys')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.server_key"
                                    size="lg"
                                    :placeholder="$t('server_keys')"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--     MERCHANT ID     -->
                    <b-col cols="12">
                        <label>{{ $t('app') }}</label>
                        <ValidationProvider v-slot="{ errors }" :name="$t('app')" rules="required">
                            <v-select
                                v-model="dataForModal.merchant"
                                :options="merchantOptions"
                                :placeholder="$t('choose.app')"
                                :searchable="false"
                                label="name"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button @click="submitModal" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col cols="9" class="offset-3">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :total-rows="pagination.totalItem"
                    :per-page="pagination.perPage"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 200px;
}

.flatpickr-input {
    margin-bottom: 0 !important;
    height: 46px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
