import Core from '@/services/core';
import { axiosDevV3, axiosInstance, axiosV1, axiosV2 } from '@/services/core/base';

export class Policies extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }
}

export class Prizes extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }
}

export class Guides extends Core {
    constructor(url) {
        super(axiosDevV3({ endpoint: '' }), url);
    }
}

export class Socials extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }
}
