var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.kube-servers')))]},proxy:true},(_vm.hasAccess('servers', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(upload)",fn:function(ref){
var item = ref.item;
return [(item.upload || item.upload.path)?_c('img',{staticClass:"table-img",attrs:{"src":item.upload.path,"alt":""}}):_c('span',[_vm._v("No Photo")])]}},{key:"cell(is_published)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:[item.is_published ? 'text-primary' : 'text-danger'],attrs:{"icon":item.is_published ? 'CheckIcon' : 'XIcon',"size":"20"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'cities', permission: 'delete' },"delete-handler":_vm.deleteKubeServer,"index":index,"item":item,"update-access":{ section: 'cities', permission: 'update' }},on:{"openModal":function($event){return _vm.kubeServersFindOne(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.kube_server') : _vm.$t('add.kube_server'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.host'))+" ")]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.host'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":("" + (_vm.$t('enter.host'))),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.host),callback:function ($$v) {_vm.$set(_vm.dataForModal, "host", $$v)},expression:"dataForModal.host"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.port'))+" ")]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.port'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":("" + (_vm.$t('enter.port'))),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.port),callback:function ($$v) {_vm.$set(_vm.dataForModal, "port", $$v)},expression:"dataForModal.port"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.token'))+" ")]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.token'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":("" + (_vm.$t('enter.token'))),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.token),callback:function ($$v) {_vm.$set(_vm.dataForModal, "token", $$v)},expression:"dataForModal.token"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }