import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class USSDStatistics extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchStatisticsProducts(body) {
        return this.post('statistics/bonuses/products', body);
    }

    fetchStatistics(body) {
        return this.post('statistics/bonuses/overall', body);
    }

    fetchNewUserStatistics(body) {
        return this.post('statistics/ussd/overall', body);
    }

    fetchNewUserStatisticsByDate(body) {
        return this.post('statistics/ussd/by-date', body);
    }

    downloadUSSDNewStatisticsExcel(body) {
        return this.post('statistics/ussd/excel-new-users', body, {
            responseType: 'arraybuffer'
        });
    }

    downloadUssdFlowStatisticsExcel(body) {
        return this.post('statistics/ussd/ussd-flow-excel', body, {
            responseType: 'arraybuffer'
        });
    }

    downloadConversionStatisticsExcel(body) {
        return this.post('statistics/ussd/conversion-excel', body, {
            responseType: 'arraybuffer'
        });
    }

    downloadInactiveStatisticsExcel(body) {
        return this.post('v2/mailing/inactive-users-csv', body, {
            responseType: 'arraybuffer'
        });
    }
}

export default USSDStatistics;
