<script>
export default {
    name: 'Switcher',
    data: () => ({}),
    props: {
        value: {
            type: Boolean,
            default: false
        },
        confirmation: {
            type: Boolean,
            default: false
        },
        reverse: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedValue: {
            get() {
                if (this.reverse) return !this.value;
                return this.value;
            },
            set(newValue) {
                if (this.reverse) return this.$emit('input', !newValue);
                this.$emit('input', newValue);
            }
        }
    },
    methods: {
        changeValue() {
            if (this.disabled) return;
            if (this.confirmation) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, do it!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then(async (result) => {
                    if (result.value) {
                        this.computedValue = !this.computedValue;
                    }
                });
            }
        }
    }
};
</script>

<template>
    <div
        :class="[
            'switcher',
            {
                'switcher--active': computedValue
            }
        ]"
        @click="changeValue"
    >
        <div class="switcher__circle" />
    </div>
</template>

<style lang="scss" src="./Switcher.scss" />
