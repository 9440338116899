var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('notifications')))]},proxy:true},(_vm.hasAccess('coin_measurement', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.title))+" ")]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.description))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === 'waiting')?_c('span',[_vm._v(_vm._s(_vm.$t('notification_statuses.waiting')))]):(item.status === 'cancelled')?_c('span',[_vm._v(_vm._s(_vm.$t('notification_statuses.cancelled')))]):_c('span',[_vm._v(_vm._s(_vm.$t('notification_statuses.waiting')))])]}},{key:"cell(is_send)",fn:function(ref){
var item = ref.item;
return [(item['is_send'])?_c('span',[_vm._v(_vm._s(_vm.$t('titles.sent')))]):_c('span',[_vm._v(_vm._s(_vm.$t('titles.not_sent')))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"index":index,"item":item,"update-access":{ section: 'coin_measurement', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":false,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.notification') : _vm.$t('add.notification'),"cancel-variant":"danger","centered":"","size":"lg"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.title.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "uz", $$v)},expression:"dataForModal.title.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.title.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "ru", $$v)},expression:"dataForModal.title.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.title.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "en", $$v)},expression:"dataForModal.title.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.description')) + " [UZ]"),"state":errors.length > 0 ? false : null,"rows":"10","size":"lg"},model:{value:(_vm.dataForModal.description.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "uz", $$v)},expression:"dataForModal.description.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.description')) + " [RU]"),"state":errors.length > 0 ? false : null,"rows":"10","size":"lg"},model:{value:(_vm.dataForModal.description.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "ru", $$v)},expression:"dataForModal.description.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.description')) + " [EN]"),"state":errors.length > 0 ? false : null,"rows":"10","size":"lg"},model:{value:(_vm.dataForModal.description.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "en", $$v)},expression:"dataForModal.description.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.url')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.url')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.title'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.link),callback:function ($$v) {_vm.$set(_vm.dataForModal, "link", $$v)},expression:"dataForModal.link"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t('app')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('app'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.merchantOptions,"placeholder":_vm.$t('choose.app'),"searchable":false,"label":"name"},model:{value:(_vm.dataForModal.merchant),callback:function ($$v) {_vm.$set(_vm.dataForModal, "merchant", $$v)},expression:"dataForModal.merchant"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"is_published"}},[_vm._v(_vm._s(_vm.$t('button.send'))+"?")]),_c('b-form-checkbox',{attrs:{"id":"is_send"},on:{"input":_vm.clearSendingTime},model:{value:(_vm.dataForModal.is_send),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_send", $$v)},expression:"dataForModal.is_send"}})],1),(!_vm.dataForModal.is_send)?_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.date')))]),_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"mb-1 form-control",attrs:{"config":{ enableTime: true, allowInput: true, time_24hr: true },"placeholder":_vm.$t('choose.date'),"name":"date","size":"md"},model:{value:(_vm.dataForModal.schedule_at),callback:function ($$v) {_vm.$set(_vm.dataForModal, "schedule_at", $$v)},expression:"dataForModal.schedule_at"}})],1)]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }