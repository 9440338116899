<script>
import CompStackedChart from '@/components/CompStackedChart.vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import { statisticsFormatDate } from '@/util/date/calendar.util';
import { BButton, BModal } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppTotalActivityStatistics',
    components: {
        BButton,
        BModal,
        flatPickr,
        ProgressLinear,
        CompStackedChart
    },
    data() {
        return {
            ussd: {
                isLoaded: false,
                series: [],
                categories: []
            },
            isModalOpened: false,
            date_start: null,
            date_end: null
        };
    },
    async mounted() {
        await this.fixStatisticsDates();
    },
    methods: {
        statisticsFormatDate,
        async fixStatisticsDates() {
            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.date_start = this.statisticsFormatDate(date_start);
            this.date_end = this.statisticsFormatDate(date_end);
            await this.getUssdStatistics();
        },

        async getUssdStatistics() {
            this.ussd.isLoaded = false;
            this.ussd.categories.length = 0;
            this.ussd.series.length = 0;
            try {
                const { data } = await api.otherStatistics.fetchUssdStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });

                this.ussd.categories = data.data.dates.map((item) => item.date.substring(5));

                const items = {};

                data.data.dates.forEach((item) => {
                    item.list.forEach((date) => {
                        const itemName = checkLocales(date.name);
                        if (!items[itemName]) {
                            items[itemName] = new Array(data.data.dates.length).fill(0);
                        }
                        const index = data.data.dates.findIndex((obj) => obj.date === item.date);
                        items[itemName][index] = date.count;
                    });
                });

                this.ussd.series = Object.entries(items).map(([name, data]) => ({
                    name,
                    data
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.ussd.isLoaded = true;
            }
        },

        async downloadUssdStatistics() {
            try {
                const response = await api.otherStatistics.downloadUssdStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'filename.xlsx');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.isModalOpened = false;
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12 d-flex justify-content-end mb-1">
            <b-button class="btn-icon" variant="primary" v-b-modal="'downloadExcelModal'">
                <span class="mr-1">{{ $t('button.download') }}</span>
                <feather-icon icon="DownloadIcon" />
            </b-button>
        </div>
        <!--      USSD STATISTICS      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="!ussd.isLoaded" />
                <CompStackedChart
                    v-else
                    :title="$t('titles.total_activity')"
                    :legend-height="100"
                    :height="600"
                    :is-pending="!ussd.isLoaded"
                    :series="ussd.series"
                    :categories="ussd.categories"
                />
            </div>
        </div>

        <b-modal
            v-model="isModalOpened"
            id="downloadExcelModal"
            ref="downloadExcelModal"
            :title="$t('button.download')"
        >
            <!--          DATE START          -->
            <label for="dateStart">{{ $t('titles.start_date') }}</label>
            <flat-pickr
                v-model="date_start"
                :config="{
                    allowInput: true
                }"
                autocomplete="off"
                class="form-control mb-1"
                name="date"
                :placeholder="$t('choose.date')"
                id="dateStart"
            />

            <!--          DATE END          -->
            <label for="dateStart">{{ $t('titles.end_date') }}</label>
            <flat-pickr
                v-model="date_end"
                :config="{
                    allowInput: true
                }"
                autocomplete="off"
                class="form-control"
                name="date"
                :placeholder="$t('choose.date')"
                id="dateEnd"
            />

            <template #modal-footer>
                <b-button @click="isModalOpened = false" variant="danger">
                    {{ $t('button.cancel') }}
                </b-button>
                <b-button @click="downloadUssdStatistics" variant="success">
                    {{ $t('button.confirm') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped lang="scss"></style>
