<script>
import api from '@/services/api';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BPagination,
    BRow,
    BTable,
    VBTooltip
} from 'bootstrap-vue';
import { generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import vSelect from 'vue-select';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import getMobileOperatorLogo from '@/constants/mobileOperators';
import Switcher from '@/components/ui/Switcher/Switcher';
import ManualPacketForm from '@/components/ManualPacketForm';
import Ripple from 'vue-ripple-directive';
import { subscriberApi } from '@/services/subscriber/subscriber.service';
import { MERCHANT_ID } from '@/constants/names';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import subscriptionV2Service from '@/services/subscriber/subscriptionV2.service';

export default {
    name: 'SubscribersIndex',
    components: {
        ManualPacketForm,
        Switcher,
        ProgressLinear,
        BTable,
        BButton,
        BRow,
        BCol,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        BPagination,
        BModal,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent
    },
    data: () => ({
        subscribers: [],
        filterField: {},
        pagination: {
            current: 1,
            totalItems: '',
            perPage: 10,
            limit: 10
        },
        filter: {},
        perPage: 100,
        perPageList: [10, 20, 30, 50, 100],
        subscribersParams: {},
        isOpenManualPacketFormModal: false,
        pageLoading: true,
        localLang: localStorage.getItem('lang'),
        merchant: parseInt(MERCHANT_ID)
    }),
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    /*
{"current":1,"previous":0,"next":2,"perPage":10,"totalPage":465851,"totalItem":4658503}
{"current":1,"previous":null,"next":2,"total":86296,"perPage":10,"totalItems":862959}
* */
    computed: {
        paginateParams() {
            if (this.merchant === 1) {
                return {
                    current: this.pagination.current,
                    previous: this.pagination.previous,
                    next: this.pagination.next,
                    perPage: this.pagination.perPage,
                    total: this.pagination.totalPage,
                    totalItems: this.pagination.totalItem
                };
            }

            return this.pagination;
        },
        tableHeader() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'phone',
                    label: this.$t('titles.phone_number')
                },
                // {
                //     key: 'stop',
                //     label: this.$t('titles.active')
                // },
                {
                    key: 'language',
                    label: this.$t('titles.language')
                },
                {
                    key: 'company',
                    label: this.$t('titles.company')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getSubscribers() {
            this.pageLoading = true;

            Object.keys(this.subscribersParams).forEach((key) => {
                if (!this.subscribersParams[key]) delete this.subscribersParams[key];
            });

            if (this.merchant === 2) {
                await api.subscribers.get(this.subscribersParams).then(({ data }) => {
                    // this.subscribers = sortItems(data.items, 'id')
                    this.subscribers = data.items;
                    this.pagination = data.pagination;
                    this.pageLoading = false;
                    this.checkToExistingPage();
                });
            } else {
                const body = {
                    limit: this.subscribersParams.perPage || this.perPage || 10,
                    ...this.subscribersParams
                };
                await api.lampSubscribers.fetchSubscribers(body).then(({ data }) => {
                    // this.subscribers = sortItems(data.items, 'id')
                    this.subscribers = data.result;
                    this.pagination = data.pagination;
                    this.pageLoading = false;
                    this.checkToExistingPage();
                });
            }
        },

        async getFilterFields() {
            if (this.merchant === 2) {
                const { data } = await api.subscribers.getFilterFields();
                this.filterField = data;
                this.filterField.gifts.map((item) => {
                    if (typeof item.name === 'object') {
                        item.name = item.name[this.localLang];
                    }
                    return item;
                });
            } else {
                const { data } = await api.lampSubscribers.fetchFilterFields(this.merchant);
                this.filterField = data;
                this.filterField.gifts.map((item) => {
                    if (typeof item.name === 'object') {
                        item.name = item.name[this.localLang];
                    }
                    return item;
                });
            }
        },

        async filterSubscribers() {
            if (
                !this.filter.search &&
                !this.filter.phone &&
                !this.filter.user_id &&
                !this.filter.id
            )
                return;
            // if (this.filter.search) this.filter.search = this.filter.search.replace(/\D/g, '');
            const query = {
                page: 1,
                limit: this.perPage,
                perPage: this.perPage
            };
            Object.keys(this.filter).forEach((key) => {
                query[key] = this.filter[key];
            });
            this.subscribersParams = query;
            await this.getSubscribers().then(() => {
                this.$router.push({
                    query
                });
            });
        },

        async checkToExistingPage() {
            if (this.$route.query.page > this.pagination.total) {
                await this.$router.push({
                    query: {
                        page: this.pagination.total
                    }
                });
                this.subscribersParams.page = this.pagination.total;
                await this.getSubscribers();
            }
        },

        // async changeActive(id) {
        //     await api.subscribers.updateActivate(id);
        // },

        // async toggleSubscription(user) {
        //     const body = {
        //         merchant_id: parseInt(MERCHANT_ID),
        //         user_id: user?.id
        //     };
        //     const toast = {
        //         title: this.$t('subscription.subscription_active_success'),
        //         icon: 'CheckIcon',
        //         variant: 'success'
        //     };
        //
        //     if (user.stop) {
        //         toast.title = this.$t('subscription.subscription_deactive_success');
        //     } else {
        //         toast.title = this.$t('subscription.subscription_active_success');
        //     }
        //     try {
        //         await subscriberApi.toggleSubscription(body);
        //         await this.showToast(toast);
        //         await this.getSubscribers();
        //     } catch (e) {
        //         console.error(e);
        //         await this.showToast({
        //             title: this.$t('error.smth'),
        //             icon: 'XIcon',
        //             variant: 'danger'
        //         });
        //     }
        // },

        async toggleSubscriptionV2(user) {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                phone: user?.phone
            };
            const toast = {
                title: this.$t('subscription.subscription_active_success'),
                icon: 'CheckIcon',
                variant: 'success'
            };
            if (user?.stop) {
                toast.title = this.$t('subscription.subscription_deactive_success');
                try {
                    await api.subscriptionV2.deactivateSubscription(body);
                    await this.showToast(toast);
                    await this.getSubscribers();
                } catch (e) {
                    console.error(e);
                    await this.showToast({
                        title: this.$t('error.smth'),
                        icon: 'XIcon',
                        variant: 'danger'
                    });
                    await this.getSubscribers();
                }
            } else {
                toast.title = this.$t('subscription.subscription_active_success');
                try {
                    await api.subscriptionV2.activateSubscription(body);
                    await this.showToast(toast);
                    await this.getSubscribers();
                } catch (e) {
                    console.error(e);
                    await this.showToast({
                        title: this.$t('error.smth'),
                        icon: 'XIcon',
                        variant: 'danger'
                    });
                    await this.getSubscribers();
                }
            }
        },

        async changePage(page) {
            this.subscribersParams.page = page;
            await this.getSubscribers();
            await this.$router.push({
                query: {
                    page
                }
            });
        },

        changePerPage(perPage) {
            this.subscribersParams = {
                perPage,
                limit: perPage,
                page: 1
            };
            this.getSubscribers();
        },

        openManualModal(id) {
            this.isOpenManualPacketFormModal = true;
            this.filterField.id = id;
        },

        clearFilter(key) {
            if (!key) return;
            this.filter[key] = '';
            delete this.subscribersParams[key];
            this.$router.push({
                query: this.subscribersParams
            });
            this.getSubscribers();
        },

        flagForLang(lang) {
            if (lang === 'uzb' || lang === 'uz') return require(`@/assets/images/flags/uz.png`);
            if (lang === 'rus' || lang === 'ru') return require(`@/assets/images/flags/ru.png`);
            if (lang === 'eng' || lang === 'en') return require(`@/assets/images/flags/en.png`);
            return require(`@/assets/images/flags/${lang}.png`);
        },

        generateIconToBoolean(value) {
            return generateIconToBoolean(value);
        },

        generateIconColorToBoolean(value) {
            return generateIconColorToBoolean(value);
        },

        mobileOperatorsLogo(name) {
            return getMobileOperatorLogo(name);
        },

        showToast({ title, icon, variant }) {
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        variant
                    }
                },
                {
                    position: 'top-right'
                }
            );
        }
    },
    mounted() {
        if (
            this.$route.query.id ||
            this.$route.query.phone ||
            this.$route.query.search ||
            this.$route.query.page ||
            this.$route.query.user_id
        ) {
            this.subscribersParams = {
                phone: this.$route.query.phone,
                search: this.$route.query.search,
                id: this.$route.query.id,
                page: this.$route.query.page,
                user_id: this.$route.query.user_id
            };
            this.filter.search = this.$route.query.search;
            this.filter.phone = this.$route.query.phone;
            this.filter.id = this.$route.query.id;
            this.filter.user_id = this.$route.query.user_id;
        } else {
            this.subscribersParams = {
                perPage: this.perPage,
                limit: this.perPage,
                page: this.pagination.current
            };
        }

        this.getSubscribers();
        // this.getFilterFields();
    }
};
</script>

<template>
    <div>
        <h1 class="mb-2">
            {{ $t('navigation.subscribers.index') }}
        </h1>

        <progress-linear v-if="pageLoading" />

        <template v-else>
            <b-row class="no-gutters">
                <b-col class="col-lg-6 col-sm-7 col-12 mb-2 d-flex">
                    <div v-if="merchant === 2" class="flex-grow-1 position-relative">
                        <b-form-input
                            v-model="filter.search"
                            class="pr-2 pl-1"
                            placeholder="Номер"
                            style="border-top-right-radius: 0; border-bottom-right-radius: 0"
                            @keypress.enter="filterSubscribers"
                        />
                        <div
                            v-show="filter.search"
                            class="b-button--clear"
                            @click.prevent="clearFilter('search')"
                        >
                            x
                        </div>
                    </div>
                    <div v-else class="flex-grow-1 position-relative">
                        <b-form-input
                            v-model="filter.phone"
                            class="pr-2 pl-1"
                            placeholder="Номер"
                            style="border-top-right-radius: 0; border-bottom-right-radius: 0"
                            @keypress.enter="filterSubscribers"
                        />
                        <div
                            v-show="filter.phone"
                            class="b-button--clear"
                            @click.prevent="clearFilter('phone')"
                        >
                            x
                        </div>
                    </div>
                    <b-input-group v-if="merchant === 2" class="w-auto">
                        <div class="position-relative">
                            <b-form-input
                                v-model="filter.user_id"
                                class="pr-3"
                                placeholder="ID"
                                style="border-radius: 0; width: 100px"
                                @keypress.enter="filterSubscribers"
                            />
                            <div
                                v-show="filter.user_id"
                                class="b-button--clear"
                                @click.prevent="clearFilter('user_id')"
                            >
                                x
                            </div>
                        </div>
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                style="font-size: 12px; padding: 0 12px"
                                variant="success"
                                @click="filterSubscribers"
                            >
                                <!--                                {{ $t('button.filter') }}-->
                                <feather-icon icon="SearchIcon" size="20" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-input-group v-else class="w-auto">
                        <div class="position-relative">
                            <b-form-input
                                v-model="filter.id"
                                class="pr-3"
                                placeholder="ID"
                                style="border-radius: 0; width: 100px"
                                @keypress.enter="filterSubscribers"
                            />
                            <div
                                v-show="filter.id"
                                class="b-button--clear"
                                @click.prevent="clearFilter('id')"
                            >
                                x
                            </div>
                        </div>
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                style="font-size: 12px; padding: 0 12px"
                                variant="success"
                                @click="filterSubscribers"
                            >
                                <!--                                {{ $t('button.filter') }}-->
                                <feather-icon icon="SearchIcon" size="20" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col class="col-lg-3 offset-lg-3 col-sm-4 offset-sm-1 col-6 offset-6 mb-2">
                    <div class="d-flex align-items-center justify-content-end">
                        Per page:
                        <v-select
                            v-model="perPage"
                            :options="perPageList"
                            class="ml-2"
                            :clearable="false"
                            style="flex-grow: 1; background-color: #fff; height: 37px; width: 100px"
                            @input="changePerPage"
                        />
                    </div>
                </b-col>
            </b-row>

            <div class="card">
                <b-table
                    :fields="tableHeader"
                    :items="subscribers"
                    :responsive="true"
                    borderless
                    outlined
                    striped
                >
                    <template #cell(index)="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #cell(stop)="{ item }">
                        <switcher
                            v-if="hasAccess('activate_pack', 'activate')"
                            v-model="item.stop"
                            confirmation
                            reverse
                            @input="toggleSubscriptionV2(item)"
                        />
                        <template v-else>
                            <feather-icon
                                :class="['stroke-current', generateIconColorToBoolean(!item.stop)]"
                                :icon="generateIconToBoolean(!item.stop)"
                            />
                        </template>
                    </template>
                    <template #cell(language)="{ item }">
                        <img :src="flagForLang(item.language)" alt="" style="width: 30px" />
                    </template>
                    <template #cell(company)="{ item }">
                        <img :src="mobileOperatorsLogo(item.company)" alt="" style="height: 20px" />
                    </template>

                    <template #cell(actions)="{ item }">
                        <div class="d-flex justify-content-end">
                            <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                :to="{
                                    name: 'active-cards',
                                    params: {
                                        id: item.id
                                    }
                                }"
                                v-b-tooltip.hover.top="`${$t('titles.cards')}`"
                            >
                                <feather-icon icon="CreditCardIcon" />
                            </b-button>
                            <b-button
                                v-if="hasAccess('activate_pack', 'activate')"
                                v-b-tooltip.hover.top="`Активировать пакет`"
                                class="btn-icon"
                                variant="flat-dark"
                                @click="openManualModal(item.id)"
                            >
                                <feather-icon icon="SlidersIcon" />
                            </b-button>
                            <b-button
                                v-b-tooltip.hover.top="`${$t('button.view')}`"
                                :to="{
                                    name: 'subscribers Single',
                                    params: {
                                        id: item.id
                                    }
                                }"
                                class="btn-icon"
                                variant="flat-dark"
                            >
                                <feather-icon icon="EyeIcon" />
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </div>

            <b-row>
                <b-col class="offset-3" cols="9">
                    <b-pagination
                        v-model="paginateParams.current"
                        :per-page="paginateParams.perPage"
                        :total-rows="paginateParams.totalItems"
                        class="float-right mr-4 mb-2"
                        first-number
                        last-number
                        @change="changePage"
                    />
                </b-col>
            </b-row>
        </template>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenManualPacketFormModal"
            :hide-footer="true"
            :no-close-on-backdrop="true"
            centered
            size="sm"
            title="Activation"
        >
            <manual-packet-form
                :id="filterField.id"
                :gifts="filterField.gifts"
                :steps="filterField.steps"
            />
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
::v-deep ul.vs__dropdown-menu {
    min-width: auto;
}
</style>
