import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Translations extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllTranslations(params) {
        return this.post('translations/findAll', params);
    }

    fetchOneTranslation(params) {
        return this.post('translations/findOne', params);
    }

    createTranslation(body) {
        return this.post('translations', body);
    }

    updateTranslation(body) {
        return this.post('translations/update', body);
    }

    deleteTranslation(body) {
        return this.post('translations/remove', body);
    }
}

export default Translations;
