<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import AwardsSelect from '@/views/tournament/statistics/elements/AwardsSelect.vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import services from '@/services';
import { MERCHANT_ID } from '@/constants/names';
import { isArray, isObject } from '@/util/inspect.util';

export default {
    name: 'AppGames',
    components: {
        TableActionBtns,
        AwardsSelect,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            award: {
                list: [],
                pagination: {
                    current: 1,
                    totalItem: '',
                    perPage: 10
                }
            },
            dataForModal: {
                name: '',
                coin_score: null,
                coin_amount: null,
                merchant_id: null,
                is_published: true,
                link: '',
                upload_id: null,
                award_id: '',
                horizontal: false,
                images: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.gamesFindAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.images = [];
            if (isArray(image)) {
                this.dataForModal.images = image;
            } else if (isObject(image)) {
                this.dataForModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'coin_amount',
                    label: this.$t('titles.coin_amount')
                },
                {
                    key: 'coin_score',
                    label: this.$t('titles.coin_score')
                },
                {
                    key: 'upload',
                    label: this.$t('titles.image')
                },
                {
                    key: 'horizontal',
                    label: this.$t('titles.horizontal')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    // created() {
    //     Promise.allSettled([this.gamesFindAll()]);
    // },

    methods: {
        checkLang,

        async gamesFindAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const rsp = await services.games.findAll(body);
            this.items = rsp.data.result;
            this.pagination = rsp.data.pagination;

            if (this.pagination.current > this.pagination.totalItem) {
                this.pagination.current = 1;
            }
        },

        async gamesFindOne(item) {
            this.updatingItem = item;
            const body = {
                id: item.id
            };
            await services.games
                .findOne(body)
                .then((response) => {
                    this.isOpenModal = true;
                    const image = response.data.result.upload;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.result,
                        awards: {
                            ...response.data.result.awards,
                            name: checkLang(response.data.result.awards.name)
                        },
                        award_id: response.data.result.awards
                    };
                    this.uploadImage = {
                        id: image.id,
                        name: image.name,
                        size: image.size,
                        type: 'image/jpg',
                        url: image.path,
                        src: image.path
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createGame(body) {
            await services.games
                .create(body)
                .then(async () => {
                    await this.gamesFindAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateGame(body) {
            await services.games
                .update(body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.gamesFindAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteGame(id) {
            const params = {
                id: id
            };
            await services.games
                .remove(params)
                .then(() => {
                    this.gamesFindAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.upload_id = this.dataForModal.images[0].id;
                    delete body.images;
                    body.award_id = body.award_id.id;
                    body.merchant_id = parseInt(MERCHANT_ID);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateGame(body, config);
                        } else {
                            await this.createGame(body, config);
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getCities();
        },

        // setValue(event) {
        //     console.log(event, 'event');
        // },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        openBannerModal(item) {
            const image = item.upload;
            if (image) {
                this.uploadImage = {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                };
            }

            this.openModalToUpdate(item);
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('games', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.games') }}</template>
            <template v-if="hasAccess('cities', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(upload)="{ item }">
                    <img
                        class="table-img"
                        v-if="item.upload || item.upload.path"
                        :src="item.upload.path"
                        alt=""
                    />
                    <span v-else>No Photo</span>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="[item.is_published ? 'text-primary' : 'text-danger']"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(horizontal)="{ item }">
                    <feather-icon
                        :class="[item.horizontal ? 'text-primary' : 'text-danger']"
                        :icon="item.horizontal ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'cities', permission: 'delete' }"
                            :delete-handler="deleteGame"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="gamesFindOne(item)"
                        />
                        <!--                        <b-button-->
                        <!--                            v-b-tooltip.hover.top="`${$t('button.open')}`"-->
                        <!--                            class="btn-icon"-->
                        <!--                            variant="flat-success"-->
                        <!--                            @click="openViewModal(item)"-->
                        <!--                        >-->
                        <!--                            <feather-icon icon="EyeIcon" />-->
                        <!--                        </b-button>-->
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.game') : $t('add.games')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--?    NAME   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--?     COIN SCORE     -->
                    <b-col cols="12">
                        <label> {{ $t('titles.coin_score') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="coin_score" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.coin_score"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.coin_score')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--?     COIN AMOUNT     -->
                    <b-col cols="12">
                        <label> {{ $t('titles.coin_amount') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="coin_amount" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.coin_amount"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.coin_amount')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--?     LINK     -->
                    <b-col cols="12">
                        <label> {{ $t('titles.link') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="link" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.link"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.link')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--?     AWARDS     -->
                    <b-col cols="12">
                        <ValidationProvider v-slot="{ errors }" name="award" rules="required">
                            <b-form-group>
                                <awards-select v-model="dataForModal.award_id" />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="uploadImage"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_image')"
                                    :maxFiles="14"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    class="float-right mt-1"
                                    @click="getImageId()"
                                >
                                    <span v-if="!imageUploaded">
                                        {{ $t('vue_file_agent.upload_image') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('vue_file_agent.image_uploaded') }}
                                    </span>
                                </b-button>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>

                    <!--?    IS PUBLISHED    -->
                    <b-col cols="6" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox
                            id="is_published"
                            v-model="dataForModal.is_published"
                            :disabled="isViewModal"
                        />
                        <label for="is_published" class="font-medium-1">
                            {{ $t('titles.is_published') }}
                        </label>
                    </b-col>
                    <b-col cols="6" class="d-flex justify-content-end mt-2 user-select-none">
                        <b-form-checkbox
                            id="horizontal"
                            v-model="dataForModal.horizontal"
                            :disabled="isViewModal"
                        />
                        <label for="horizontal" class="font-medium-1">
                            {{ $t('titles.horizontal') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}
</style>
