<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';

export default {
    name: 'ViewUzmobileStatus',
    components: { ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUzmobile.fetchStatus({
                    phone: parseInt(this.phone)
                });
                this.response = data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },

        hasChildren(value) {
            return typeof value === 'object';
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>

                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <b-button @click="getResponse" variant="success">
                        {{ $t('button.filter') }}
                    </b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <div v-else class="json__card card p-1">
                    <div v-if="response">
                        <div
                            class="mb-0"
                            v-for="(value, key, index) in response"
                            :key="key + value + index"
                        >
                            <span
                                v-if="hasChildren(value)"
                                class="d-block mt-1 text-info text-uppercase font-medium-2 text-bold"
                            >
                                {{ key }}
                            </span>
                            <div v-if="hasChildren(value)">
                                <div
                                    class="mb-0"
                                    v-for="(childValue, childKey, childIndex) in value"
                                    :key="childValue + childKey + childIndex"
                                >
                                    <span class="font-medium-2 text-uppercase text-warning">
                                        {{ childKey }}
                                    </span>
                                    <span class="mx-1 font-medium-2 text-bold text-waring">:</span>
                                    <span class="font-medium-2 text-uppercase text-success">{{
                                        childValue
                                    }}</span>
                                </div>
                            </div>
                            <div v-else>
                                <span class="text-uppercase text-warning font-medium-2 text-bold">{{
                                    key
                                }}</span>
                                <span class="mx-1 text-bold font-medium-2">:</span>
                                <span class="text-uppercase text-bold font-medium-2 text-success">{{
                                    value
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <h4 v-else>No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.json__card pre {
    //border: 2px solid #50ff15;
    border-radius: 0.5rem;
    padding: 1rem;
    //color: #50ff15;
}
</style>
