import { axiosInstanceV3 } from '@/services/core/base';
import Core from '@/services/core/index';

class Auth extends Core {
    constructor(url) {
        super(axiosInstanceV3, url);
    }

    signInApi(login) {
        return this.customPost(this.url + '/login', login);
    }

    authMeApi() {
        return this.customPost(this.url + '/me');
    }

    // updateToken(refreshToken) {
    //   return this.post('auth/refreshToken', { refreshToken })
    // }
}

export default Auth;
