import AllStatistics from '@/views/statistics/other-statistics/index.vue';
import AppBonusStatistics from '@/views/statistics/other-statistics/AppBonusStatistics.vue';
import AppFormStatistics from '@/views/statistics/other-statistics/AppFormStatistics.vue';
import AppOrderStatistics from '@/views/statistics/other-statistics/AppOrderStatistics.vue';
import AppFitCoinStatistics from '@/views/statistics/other-statistics/AppFitCoinStatistics.vue';
import AppPrizesOfLevelStatistics from '@/views/statistics/other-statistics/AppPrizesOfLevelStatistics.vue';
import AppQuestionnaireStatistics from '@/views/statistics/other-statistics/AppQuestionnaireStatistics.vue';
import AppRoadmapStatistics from '@/views/statistics/other-statistics/AppRoadmapStatistics.vue';
import AppVoteStatistics from '@/views/statistics/other-statistics/AppVoteStatistics.vue';
import AppDistributionStatistics from '@/views/statistics/other-statistics/AppDistributionStatistics.vue';
import ViewUcellIsStatus from '@/views/administration/ucell/views/ViewUcellIsStatus.vue';
import AppDistributionAll from '@/views/statistics/other-statistics/distribution/AppDistributionAll.vue';
import AppDistributionNew from '@/views/statistics/other-statistics/distribution/AppDistributionNew.vue';
import AppDistributionFresh from '@/views/statistics/other-statistics/distribution/AppDistributionFresh.vue';

export default [
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/homeV2',
        name: 'HomeV2',
        component: () => import('@/views/HomeV2.vue')
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('@/views/statistics/Statistics.vue'),
        meta: {
            section: 'statistics_page',
            permission: 'view'
        }
    },
    {
        path: '/new-statistics',
        name: 'new-statistics',
        component: () => import('@/views/statistics/AppNewStatistics.vue'),
        meta: {
            section: 'statistics_page',
            permission: 'view'
        }
    },
    {
        path: '/registration-statistics',
        name: 'registration-statistics',
        component: () => import('@/views/registration-statistics/AppRegistrationStatistics.vue'),
        meta: {
            section: 'statistics_page',
            permission: 'view'
        }
    },
    {
        path: '/match-statistics',
        name: 'match-statistics',
        component: () => import('@/views/statistics/AppMatchingStatistics.vue'),
        meta: {
            section: 'statistics_page',
            permission: 'view'
        }
    },
    {
        path: '/statistics',
        component: AllStatistics,
        children: [
            {
                path: 'bonus',
                name: 'bonus-statistics',
                component: AppBonusStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'form',
                name: 'form-statistics',
                component: AppFormStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'order',
                name: 'order-statistics',
                component: AppOrderStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'fitcoin',
                name: 'fitcoin-statistics',
                component: AppFitCoinStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'level-prize',
                name: 'level-prize-statistics',
                component: AppPrizesOfLevelStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'questionnaire',
                name: 'questionnaire-statistics',
                component: AppQuestionnaireStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'roadmap',
                name: 'roadmap-statistics',
                component: AppRoadmapStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'vote',
                name: 'vote-statistics',
                component: AppVoteStatistics,
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            },
            {
                path: 'set',
                name: 'set-statistics',
                component: AppDistributionStatistics,
                children: [
                    {
                        path: 'all',
                        component: AppDistributionAll,
                        name: 'set-statistics-all'
                    },
                    {
                        path: 'new',
                        component: AppDistributionNew,
                        name: 'set-statistics-new'
                    },
                    {
                        path: 'fresh',
                        component: AppDistributionFresh,
                        name: 'set-statistics-fresh'
                    }
                ],
                meta: {
                    section: 'statistics_page',
                    permission: 'view'
                }
            }
        ]
    }
];
