var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.coin_order')))]},proxy:true}])}),_c('div',{staticClass:"row d-flex align-items-center justify-content-between mb-2",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col-12"},[_c('v-select',{staticStyle:{"background-color":"#fff","height":"37px"},attrs:{"options":_vm.statusOptionList,"placeholder":_vm.$t('choose.category'),"searchable":true},on:{"input":_vm.statusFiltered},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',[_c('b-form-input',{staticStyle:{"height":"37px"},attrs:{"placeholder":_vm.$t('search'),"size":"lg"},on:{"input":_vm.searchDebounce},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1)],1),_c('div',{staticClass:"col-4 d-flex align-items-center"},[_vm._v(" Per page: "),_c('v-select',{staticClass:"ml-2",staticStyle:{"flex-grow":"1","background-color":"#fff","height":"37px","width":"180px"},attrs:{"options":_vm.perPageList},model:{value:(_vm.pagination.limit),callback:function ($$v) {_vm.$set(_vm.pagination, "limit", $$v)},expression:"pagination.limit"}})],1)]),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.orderFields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(images)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-img"},[(item.images && item.images.length > 0)?_c('img',{attrs:{"src":item.images[0].path}}):_c('img',{attrs:{"src":require('@/assets/images/icons/no-photo.png')}})])]}},{key:"cell(client_detail)",fn:function(ref){
var item = ref.item;
return [(item.client_detail)?_c('span',[_vm._v(" "+_vm._s(item.client_detail.first_name)+" "+_vm._s(item.client_detail.last_name)+" ")]):_vm._e()]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (item.type))))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{class:_vm.computedStatusClass(item.status),style:(("background-color: " + (item.color)))},[_vm._v(" "+_vm._s(_vm.checkLang(item.status))+" ")])]}},{key:"cell(duration_type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (item.duration_type))))+" ")]}},{key:"cell(grand_total)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(item.grand_total))]),_c('img',{staticClass:"grand_total-img",attrs:{"src":require("@/assets/images/icons/coin.png"),"alt":""}})])]}},{key:"cell(phone)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("+"+_vm._s(_vm.getUserPhone(item.client_detail)))])]}},{key:"cell(is_published)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:['stroke-current', _vm.generateIconColorToBoolean(item.is_published)],attrs:{"icon":_vm.generateIconToBoolean(item.is_published)}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"delete-access":{ section: 'orders', permission: 'delete' },"index":index,"item":item,"update-access":{ section: 'orders', permission: 'update' }},on:{"openModal":function($event){return _vm.$router.push({ name: 'coin-order-view', params: { id: item.id } })}}})]}}])})],1),_c('b-row',[_c('b-col',{staticClass:"offset-3 mb-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.limit,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }