<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';

export default {
    name: 'ViewRandomizerCategorySteps',
    components: {
        vSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                category_id: '',
                step: '',
                packages: []
            },
            chargesList: [],
            selectedCharge: [],
            categoriesList: [],
            packagesList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            staticActiveOptions: [
                {
                    label: this.$t('titles.is_active'),
                    value: true
                },
                {
                    label: this.$t('titles.is_not_active'),
                    value: false
                }
            ],
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
                // this.getChargesList();
                // this.getServicesList();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'category',
                    label: this.$t('titles.category')
                },
                {
                    key: 'step',
                    label: this.$t('titles.step')
                },
                {
                    key: 'static_package',
                    label: this.$t('titles.static_package')
                },
                {
                    key: 'packages',
                    label: this.$t('titles.packages')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.randomizerCategorySteps.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        // async getChargesList() {
        //     const params = {
        //         page: 1,
        //         limit: 20
        //     };
        //
        //     try {
        //         const { data } = await api.randomizerCategorySteps.fetchAllItems(params);
        //         this.chargesList = data.data.list;
        //     } catch (e) {
        //         console.error(e);
        //     }
        // },
        //
        // async getServicesList() {
        //     const params = {
        //         page: 1,
        //         limit: 20,
        //         merchant_id: parseInt(MERCHANT_ID)
        //     };
        //
        //     try {
        //         const { data } = await services.subscribersService.fetchAll(params);
        //         this.servicesList = data.data.list.map((item) => {
        //             return {
        //                 ...item,
        //                 name: checkLocales(item.name)
        //             };
        //         });
        //     } catch (e) {
        //         console.error(e);
        //     }
        // },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.randomizerCategorySteps
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });

                    const chargesValues = this.dataForModal.charges;
                    this.dataForModal.charges = [];

                    this.chargesList.forEach((item) => {
                        chargesValues.forEach((charge, index) => {
                            if (item.id === charge) {
                                this.dataForModal.charges.push({
                                    unique: index,
                                    index: charge,
                                    name: item.name
                                });
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.randomizerCategorySteps
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.randomizerCategorySteps
                .updateItem(id, body)
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.randomizerCategorySteps
                .deleteItem(id)
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async changeStatus(val) {
            const isActive = val.is_active;
            const body = {
                is_active: !isActive
            };
            await api.randomizerCategorySteps.updateItemStatus(val.id, body);
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    this.dataForModal.charges = this.dataForModal.charges.map((item) => {
                        return item.index;
                    });

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        findItemsInArray(val) {
            const arr = [];
            this.chargesList.forEach((item) => {
                val.forEach((childItem) => {
                    if (item.id === childItem) {
                        arr.push(item);
                    }
                });
            });
            return arr.map((item) => item.name);
        },

        onSelect(event) {
            this.dataForModal.charges.push({
                unique: this.dataForModal.charges.length + 1,
                index: event[event.length - 1].id,
                name: event[event.length - 1].name
            });
        },
        onDeSelect(event) {
            const fIdx = this.dataForModal.charges.findIndex(
                (item) => item.unique === event.unique
            );
            if (fIdx !== -1) {
                this.dataForModal.charges.splice(fIdx, 1);
            }
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.category_steps') }} [Randomizer]</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(static_package)="{ item }">
                    {{ item.static_package.name.toUpperCase() }}
                </template>

                <template #cell(packages)="{ item }">
                    <b-badge
                        v-for="(child, index) in item.packages"
                        :key="index"
                        class="table-badge__item"
                    >
                        <span>{{ child.name.toUpperCase() }} {{ child.qty }}</span>
                    </b-badge>
                </template>

                <template #cell(is_internal)="{ item }">
                    <feather-icon
                        :class="[item.is_internal ? 'text-success' : 'text-danger']"
                        :icon="item.is_internal ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_active)="{ item }">
                    <!--                    <switcher-->
                    <!--                        v-model="item.is_active"-->
                    <!--                        confirmation-->
                    <!--                        reverse-->
                    <!--                        @input="changeStatus(item)"-->
                    <!--                    />-->
                    <b-form-checkbox
                        :checked="item.is_active"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        @input="changeStatus(item)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'collector', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'collector', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.subscription') : $t('add.subscription')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    STEP    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.step') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.step')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.step"
                                    :placeholder="$t('enter.step')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     PACKAGES     -->
                    <!--                    <b-col cols="12">-->
                    <!--                        <ValidationProvider v-slot="{ errors }" name="Charges" rules="required">-->
                    <!--                            <b-form-group label="Charges" label-for="charges">-->
                    <!--                                <v-select-->
                    <!--                                    id="charges"-->
                    <!--                                    :disabled="isViewModal"-->
                    <!--                                    :options="chargesList"-->
                    <!--                                    :value="dataForModal.charges"-->
                    <!--                                    :reduce="(option) => option"-->
                    <!--                                    placeholder="Charges"-->
                    <!--                                    label="name"-->
                    <!--                                    @option:selected="onSelect"-->
                    <!--                                    @option:deselected="onDeSelect"-->
                    <!--                                    multiple-->
                    <!--                                />-->
                    <!--                            </b-form-group>-->
                    <!--                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>-->
                    <!--                        </ValidationProvider>-->
                    <!--                    </b-col>-->

                    <!--     CATEGORY ID     -->
                    <b-col cols="12">
                        <b-form-group :label="$t('titles.categories')" label-for="categories">
                            <v-select
                                id="categories"
                                v-model="dataForModal.category_id"
                                :disabled="isViewModal"
                                :options="categoriesList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('choose.category')"
                                label="name"
                                multiple
                            />
                        </b-form-group>
                    </b-col>

                    <!--     PACKAGES     -->
                    <b-col cols="12" class="mt-1">
                        <b-form-group :label="$t('titles.packages')" label-for="categories">
                            <v-select
                                id="categories"
                                v-model="dataForModal.category_id"
                                :disabled="isViewModal"
                                :options="packagesList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('choose.package')"
                                label="name"
                                multiple
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

//.bordered__image-list {
//    border: 2px dashed #999999;
//
//    &_item {
//        position: relative;
//        width: 100px;
//        height: 100px;
//
//        &-close {
//            position: absolute;
//            display: flex;
//            align-items: center;
//            justify-content: center;
//            top: -5px;
//            right: -5px;
//            width: 16px;
//            height: 16px;
//            border-radius: 50%;
//            background-color: #999999;
//            color: #fff;
//            cursor: pointer;
//            font-size: 12px;
//        }
//
//        & img {
//            width: 100px;
//            height: 100%;
//            object-fit: cover;
//        }
//    }
//}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
