<script>
import { BAvatar, BCard, BMedia } from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import ListDetailsIcon from '@/components/icons/ListDetailsIcon.vue';
import { checkLang, computedStatusClass } from '@/util/helper';
import { dateProperties } from '@/util/date/calendar.util';

export default {
    name: 'TimeLineCard',
    components: {
        BCard,
        BMedia,
        BAvatar,
        AppTimeline,
        AppTimelineItem,
        ListDetailsIcon
    },
    props: {
        orderHistory: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            // 'merchant_id' => 'integer',
            // 'order_id' => 'integer',
            // 'user_id' => 'integer',
            // 'status_from' => 'integer',
            // 'status_to' => 'integer',
            // 'comment' => 'string',
            // 'created_at' => 'timestamp',
            list: [
                {
                    merchant_id: 1,
                    order_id: 1,
                    user_id: 1,
                    status_from: 'В ожидании',
                    status_to: 'Заказ успешно завершен',
                    comment: 'Отабек Каримов изменил статус заказа',
                    created_at: '2023-04-17 16:11:18'
                }
            ]
        };
    },
    methods: {
        computedStatusClass,
        checkLang,
        userName(item) {
            if (item) {
                if (item.first_name || item.last_name) {
                    return item.first_name + ' ' + item.last_name;
                } else {
                    return 'Id: ' + item.id || 'Tel: ' + item.phone;
                }
            }
            return 'user';
        },
        createdTimeZone(time) {
            const lastReadTime = new Date(time);
            const TIME_STAMP_DIF = 5 * 60 * 60 * 1000;
            lastReadTime.setTime(lastReadTime.getTime() + TIME_STAMP_DIF);
            const { ymdHms } = dateProperties(lastReadTime);
            return ymdHms;
        }
    }
};
</script>
<template>
    <b-card>
        <template #header>
            <div class="d-flex align-items-center">
                <list-details-icon />
                <div class="ol-timeline-title">
                    {{ $t('titles.activity_timeline') }}
                </div>
            </div>
        </template>
        <app-timeline v-if="orderHistory && orderHistory.length">
            <app-timeline-item v-for="item in orderHistory" :key="item.id">
                <div
                    class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                    <h6>
                        <b>{{ userName(item.user) }}</b> изменил статус заказа
                    </h6>
                    <small class="text-muted">{{ createdTimeZone(item.created_at) }}</small>
                </div>
                <p v-if="item.comment">{{ item.comment }}</p>

                <div class="d-flex align-items-center">
                    <b-media v-if="item.user && item.user.avatar">
                        <template #aside>
                            <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
                        </template>
                    </b-media>
                    <div class="d-flex align-items-center" style="column-gap: 0.5rem">
                        <b-badge
                            :class="computedStatusClass(item.status_from)"
                            :style="`background-color: ${item.color_from}`"
                        >
                            {{ checkLang(item.status_from) }}
                        </b-badge>
                        <span class="align-bottom">
                            <img :src="require('@/assets/images/icons/arrow-right.svg')" alt="" />
                        </span>
                        <b-badge
                            :class="computedStatusClass(item.status_to)"
                            :style="`background-color: ${item.color_to}`"
                        >
                            {{ checkLang(item.status_to) }}
                        </b-badge>
                    </div>
                </div>
            </app-timeline-item>
        </app-timeline>
    </b-card>
</template>

<style lang="scss" scoped>
.ol-timeline-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-left: 0.75rem;
}
</style>
