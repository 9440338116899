import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class ScenarioPrize extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/scenario/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('v2/scenario/findOne', body);
    }

    createItem(body) {
        return this.post('v2/scenario', body);
    }

    updateItem(body) {
        return this.post('v2/scenario/update', body);
    }

    deleteItem(body) {
        return this.post('v2/scenario/remove', body);
    }

    fetchScenarioHistory(body) {
        return this.post('v2/story-scenario/findOne', body);
    }

    fetchStreakVisibilityStatus(body) {
        return this.post('v2/scenario/config-get', body);
    }

    updateStreakVisibilityStatus(body) {
        return this.post('v2/scenario/config-update', body);
    }
}

export default ScenarioPrize;
