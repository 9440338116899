import CoreV2 from '@/services/core/coreV2';
import { axiosInstanceV3 } from '@/services/core/base';

class Test extends CoreV2 {
    constructor() {
        super(axiosInstanceV3);
    }

    fetchBonusProductPackages(id, params) {
        return this.get(`get/packages?merchant_id=${id}`, params);
    }
}

export default Test;
