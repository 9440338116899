import AppAdministration from '@/views/administration/AppAdministration.vue';
import ViewUcellIsStatus from '@/views/administration/ucell/views/ViewUcellIsStatus.vue';
import ViewUzmobileIsStatus from '@/views/administration/uzmobile/views/ViewUzmobileIsStatus.vue';
import ViewUzmobileStatus from '@/views/administration/uzmobile/views/ViewUzmobileStatus.vue';
import ViewUzmobileIsRtStatus from '@/views/administration/uzmobile/views/ViewUzmobileIsRtStatus.vue';
import ViewUzmobileRtStatus from '@/views/administration/uzmobile/views/ViewUzmobileRtStatus.vue';
import ViewUzmobileBalance from '@/views/administration/uzmobile/views/ViewUzmobileBalance.vue';
import ViewUzmobileSubscriberInfo from '@/views/administration/uzmobile/views/ViewUzmobileSubscriberInfo.vue';
import ViewUzmobilePersonalAccounts from '@/views/administration/uzmobile/views/ViewUzmobilePersonalAccounts.vue';
import ViewUzmobileFlagActive from '@/views/administration/uzmobile/views/ViewUzmobileFlagActive.vue';
import ViewUzmobileFlagDeactive from '@/views/administration/uzmobile/views/ViewUzmobileFlagDeactive.vue';
import ViewUzmobilePackageInfo from '@/views/administration/uzmobile/views/ViewUzmobilePackageInfo.vue';
import ViewUzmobilePackageStat from '@/views/administration/uzmobile/views/ViewUzmobilePackageStat.vue';
import ViewUzmobilePackageAdd from '@/views/administration/uzmobile/views/ViewUzmobilePackageAdd.vue';
import ViewUzmobileChargeAdd from '@/views/administration/uzmobile/views/ViewUzmobileChargeAdd.vue';
import ViewUcellStatus from '@/views/administration/ucell/views/ViewUcellStatus.vue';
import ViewUcellPayPeriod from '@/views/administration/ucell/views/ViewUcellPayPeriod.vue';
import ViewUcellBalance from '@/views/administration/ucell/views/ViewUcellBalance.vue';
import ViewUcellSaleDateStatus from '@/views/administration/ucell/views/ViewUcellSaleDateStatus.vue';
import ViewUcellPackList from '@/views/administration/ucell/views/ViewUcellPackList.vue';
import ViewUcellPackCreate from '@/views/administration/ucell/views/ViewUcellPackCreate.vue';
import ViewUcellPackDelete from '@/views/administration/ucell/views/ViewUcellPackDelete.vue';

export default [
    {
        path: '/administration',
        component: AppAdministration,
        name: 'administration',
        meta: {
            section: 'collector',
            permission: 'collector'
        },
        children: [
            {
                path: 'ucell/is-status',
                component: ViewUcellIsStatus,
                name: 'ucell-is-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/status',
                component: ViewUcellStatus,
                name: 'ucell-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/pay-period',
                component: ViewUcellPayPeriod,
                name: 'ucell-pay-period',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/balance',
                component: ViewUcellBalance,
                name: 'ucell-balance',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/sale-date-status',
                component: ViewUcellSaleDateStatus,
                name: 'ucell-sale-date-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/pack-list',
                component: ViewUcellPackList,
                name: 'ucell-pack-list',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/pack-create',
                component: ViewUcellPackCreate,
                name: 'ucell-pack-create',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'ucell/pack-delete',
                component: ViewUcellPackDelete,
                name: 'ucell-pack-delete',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/is-status',
                component: ViewUzmobileIsStatus,
                name: 'uzmobile-is-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/status',
                component: ViewUzmobileStatus,
                name: 'uzmobile-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/is-rt-status',
                component: ViewUzmobileIsRtStatus,
                name: 'uzmobile-is-rt-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/rt-status',
                component: ViewUzmobileRtStatus,
                name: 'uzmobile-rt-status',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/balance',
                component: ViewUzmobileBalance,
                name: 'uzmobile-balance',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/subscriber-info',
                component: ViewUzmobileSubscriberInfo,
                name: 'uzmobile-subscriber-info',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/personal-accounts',
                component: ViewUzmobilePersonalAccounts,
                name: 'uzmobile-personal-accounts',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/flag-active',
                component: ViewUzmobileFlagActive,
                name: 'uzmobile-flag-active',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/flag-deactive',
                component: ViewUzmobileFlagDeactive,
                name: 'uzmobile-flag-deactive',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/package-info',
                component: ViewUzmobilePackageInfo,
                name: 'uzmobile-package-info',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/package-stat',
                component: ViewUzmobilePackageStat,
                name: 'uzmobile-package-stat',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/package-add',
                component: ViewUzmobilePackageAdd,
                name: 'uzmobile-package-add',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'uzmobile/charge-add',
                component: ViewUzmobileChargeAdd,
                name: 'uzmobile-charge-add',
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            }
        ]
    }
];
