// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV4 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class Control extends CoreV2 {
    static function;

    constructor() {
        super(axiosV4());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'dashboardGf/' : 'dashboard/';
    }

    fetchQueue() {
        return this.post(this.dashboardVersion() + `control/queueAll`);
    }

    fetchServerList() {
        return this.post(this.dashboardVersion() + 'control/getAll');
    }

    startServer(id) {
        return this.post(this.dashboardVersion() + `control/starting/${id}`);
    }

    stopServer(id) {
        return this.post(this.dashboardVersion() + `control/stopping/${id}`);
    }
}

export default Control;
