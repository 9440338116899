<script>
import AppSegmentationDetailEvent from '@/views/segmentation/views/AppSegmentationDetailEvent.vue';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'index',
    components: { AppSegmentationDetailEvent },
    data() {
        return {
            uzmobileTabs: [
                {
                    value: 'detail-rate-plan',
                    label: this.$t('segmentation.detail_rate_plan'),
                    routeName: 'segmentation-detail-rate-plan'
                },
                {
                    value: 'detail-zone',
                    label: this.$t('segmentation.detail_zone'),
                    routeName: 'segmentation-detail-zone'
                },
                {
                    value: 'detail-sale-point',
                    label: this.$t('segmentation.detail_sale_point'),
                    routeName: 'segmentation-detail-sale-point'
                },
                {
                    value: 'detail-user-info',
                    label: this.$t('segmentation.detail_user_info'),
                    routeName: 'segmentation-user-info'
                }
                // {
                //     value: 'detail-statistics',
                //     label: this.$t('navigation.statistics'),
                //     routeName: 'segmentation-statistics'
                // }
            ],
            ucellTabs: [
                {
                    value: 'detail-rate-plan',
                    label: this.$t('segmentation.detail_rate_plan'),
                    routeName: 'segmentation-detail-rate-plan'
                },
                {
                    value: 'detail-user-info',
                    label: this.$t('segmentation.detail_user_info'),
                    routeName: 'segmentation-user-info'
                }
                // {
                //     value: 'detail-statistics',
                //     label: this.$t('navigation.statistics'),
                //     routeName: 'segmentation-statistics'
                // }
            ],
            merchantId: parseInt(MERCHANT_ID)
        };
    },
    methods: {
        isActive(route) {
            return this.$route.name === route.routeName;
        }
    }
};
</script>

<template>
    <div class="segmentation">
        <AppSegmentationDetailEvent />
        <div v-if="merchantId === 1" class="nav nav-tabs flex-nowrap overflow-x-scroll">
            <router-link
                v-for="(route, index) in ucellTabs"
                :key="index"
                :to="{ name: route.routeName }"
                active-class="active"
                class="nav-link"
                :class="{ active: isActive(route) }"
                style="min-width: fit-content"
            >
                {{ route.label }}
            </router-link>
        </div>

        <div v-else class="nav nav-tabs flex-nowrap overflow-x-scroll">
            <router-link
                v-for="(route, index) in uzmobileTabs"
                :key="index"
                :to="{ name: route.routeName }"
                active-class="active"
                class="nav-link"
                :class="{ active: isActive(route) }"
                style="min-width: fit-content"
            >
                {{ route.label }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<style scoped lang="scss">
.nav-tabs::-webkit-scrollbar {
    display: none;
}
</style>
