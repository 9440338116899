<script>
import api from '@/services/api';
import { BCol, BFormGroup, BFormInput, BRow, BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import { isBoolean } from '@/util/inspect.util';
import CompRecordLogsTimeLine from '@/views/others/record-logs/components/CompRecordLogsTimeLine.vue';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppRecordLogs',
    components: {
        BFormInput,
        CompRecordLogsTimeLine,
        BSpinner,
        BRow,
        BCol,
        BFormGroup,
        vSelect,
        flatPickr
    },
    data() {
        return {
            logs: {
                items: [],
                pagination: {}
            },
            filter: {
                page: 1,
                merchant_id: parseInt(MERCHANT_ID),
                params: {
                    date_start: '',
                    date_end: '',
                    service: ''
                }
            },
            requestPending: false,
            localLang: localStorage.getItem('lang'),
            // serviceList: [
            //     '| MAIN |',
            //     'charge-al',
            //     'package-al-control',
            //     'subscription-al',
            //     'randomizer-al',
            //     '| QUEUE |',
            //     'queue-al-service-dispenser',
            //     'queue-al-subscription',
            //     'queue-al-bonus',
            //     'queue-al-daily',
            //     'queue-al-premium',
            //     'queue-al-infinity',
            //     'queue-al-flash',
            //     '| OTHERS |',
            //     'infinity-al',
            //     'infinity-al-purchase',
            //     'infinity-al-dispenser',
            //     'unknown',
            //     '| OLDS |',
            //     'bonus-al-gateway',
            //     'bonus-al-queue-bonus',
            //     'bonus-al-queue-daily',
            //     'bonus-al-queue-premium',
            //     'bonus-al-queue-flash'
            // ],
            interval: null,
            fetchTrigger: true
        };
    },

    computed: {
        serviceList() {
            if (parseInt(MERCHANT_ID) === 1) {
                return [
                    '| MAIN |',
                    'charge-al',
                    'package-al-control',
                    'subscription-al',
                    'randomizer-al',
                    'ussd-al',
                    '| QUEUE |',
                    'queue-al-service-dispenser',
                    'queue-al-subscription',
                    'queue-al-bonus',
                    'queue-al-daily',
                    'queue-al-premium',
                    'queue-al-infinity',
                    'queue-al-flash',
                    '| OTHERS |',
                    'infinity-al',
                    'infinity-al-purchase',
                    'infinity-al-dispenser',
                    'referral-al',
                    'unknown',
                    '| OLDS |',
                    'bonus-al-gateway',
                    'bonus-al-queue-bonus',
                    'bonus-al-queue-daily',
                    'bonus-al-queue-premium',
                    'bonus-al-queue-flash'
                ];
            } else {
                return [
                    '| MAIN |',
                    'charge-gf',
                    'package-gf-control',
                    'subscription-gf',
                    'randomizer-gf',
                    'ussd-gf',
                    '| QUEUE |',
                    'queue-gf-service-dispenser',
                    'queue-gf-subscription',
                    'queue-gf-bonus',
                    'queue-gf-daily',
                    'queue-gf-premium',
                    'queue-gf-infinity',
                    'queue-gf-flash',
                    '| OTHERS |',
                    'infinity-gf',
                    'infinity-gf-purchase',
                    'infinity-gf-dispenser',
                    'referral-gf',
                    'unknown',
                    '| OLDS |',
                    'bonus-gf-gateway',
                    'bonus-gf-queue-bonus',
                    'bonus-gf-queue-daily',
                    'bonus-gf-queue-premium',
                    'bonus-gf-queue-flash'
                ];
            }
        }
    },

    methods: {
        isBoolean,

        colorByStatusCode(statusCode) {
            const toFixedNum = parseInt(Math.floor(statusCode / 100).toFixed(0));
            switch (toFixedNum) {
                case 2:
                    return 'success';
                case 3:
                    return 'warning';
                case 4:
                    return 'warning';
                case 5:
                    return 'danger';
                default:
                    return 'secondary';
            }
        },

        // colorByText(item) {
        //     const text = item.split('[')[2].split(']')[0];
        //     console.log(text);
        //     return item;
        // },

        async fetchLogs() {
            if (this.requestPending) return;
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 15
            });
            const { data } = await api.coin.fetchRecordLogsList(this.filter);
            if (data.data.pagination.current === 1) {
                this.logs.items = data.data.list;
            } else {
                this.logs.items = [...this.logs.items, ...data.data.list];
            }
            this.logs.pagination = data.data.pagination;
            this.requestPending = false;
        },

        loadNextPage(page) {
            if (!this.fetchTrigger) {
                if (this.requestPending || this.autoReload || !page) return;
                this.filter.page = page;
                this.fetchLogs();
            }
        },

        resetFilter() {
            this.filter = {
                page: 1,
                merchant_id: parseInt(MERCHANT_ID),
                params: {
                    date_start: '',
                    date_end: '',
                    service: ''
                }
            };
            this.fixMonthlyStatisticsDates();
        },

        async fixMonthlyStatisticsDates() {
            const date = new Date();
            const year = date.getUTCFullYear(); // Get the full year (4 digits)
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.filter.params.date_start = `${year}-${currentMonth}-${day}`;
            this.filter.params.date_end = `${year}-${currentMonth}-${day}`;
            await this.fetchLogs();
        },

        async triggerAutoFetching() {
            this.fetchTrigger = !this.fetchTrigger;

            if (!this.fetchTrigger) {
                clearInterval(this.interval);
                this.interval = null;
            } else {
                await this.fetchLogs();
                this.interval = setInterval(async () => {
                    await this.fetchLogs();
                }, 3000);
            }
        }
    },
    async mounted() {
        await this.fixMonthlyStatisticsDates();
        if (this.fetchTrigger) {
            this.interval = setInterval(async () => {
                await this.fetchLogs();
            }, 3000);
        }
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.interval);
        this.interval = null;

        next();
    }
};
</script>

<template>
    <div>
        <b-col class="col-12 px-0">
            <b-card>
                <b-row class="align-items-start">
                    <!--  START DATE  -->
                    <div class="mb-1 col-12 col-sm-6 col-md-4 col-xl-4">
                        <h5>{{ $t('titles.start_date') }}</h5>
                        <b-form-group>
                            <flat-pickr
                                v-model="filter.params.date_start"
                                :config="{
                                    enableTime: false,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                autocomplete="off"
                                class="form-control"
                                name="date"
                                :placeholder="$t('choose.date')"
                                id="datePicker"
                            />
                        </b-form-group>
                    </div>

                    <!--  END DATE  -->
                    <div class="mb-1 col-12 col-sm-6 col-md-4 col-xl-4">
                        <h5>{{ $t('titles.end_date') }}</h5>
                        <b-form-group>
                            <flat-pickr
                                v-model="filter.params.date_end"
                                :config="{
                                    enableTime: false,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                autocomplete="off"
                                class="form-control"
                                name="date"
                                :placeholder="$t('choose.date')"
                                id="datePicker"
                            />
                        </b-form-group>
                    </div>

                    <!--  SERVICE  -->
                    <div class="col-12 col-sm-6 col-md-4 col-xl-4">
                        <h5>{{ $t('titles.service') }}</h5>
                        <b-form-group>
                            <b-form-input
                                v-model="filter.params.service"
                                :placeholder="$t('enter.service')"
                                size="md"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-12 col-sm-6 col-md-12 col-xl-12 float-right">
                        <div class="row justify-content-end">
                            <div class="mt-1 mt-md-0 col-12 col-md-2 col-xl-1">
                                <b-button
                                    @click="triggerAutoFetching"
                                    :disabled="requestPending"
                                    variant="outline-primary"
                                    class="w-100 px-md-1"
                                >
                                    <b-spinner v-if="requestPending" small variant="primary" />
                                    <feather-icon
                                        v-else
                                        :icon="fetchTrigger ? 'PauseIcon' : 'PlayIcon'"
                                        size="20"
                                    ></feather-icon>
                                </b-button>
                            </div>
                            <div class="mt-1 mt-md-0 col-12 col-md-3 col-xl-2">
                                <b-button class="w-100" @click="resetFilter">Сбросить</b-button>
                            </div>
                            <div class="mt-1 mt-md-0 col-12 col-md-3 col-xl-2">
                                <b-button class="w-100" @click="fetchLogs" variant="success">
                                    Фильтровать
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-card>
        </b-col>

        <b-col class="col-12 px-0">
            <comp-record-logs-time-line
                v-model:loading="requestPending"
                :items="logs.items"
                :pagination="logs.pagination"
                @loadNextPage="loadNextPage"
            >
                <template #default="{ item }">
                    <div class="activity-item">
                        <div class="activity-item__content">
                            <h2 class="text-primary">{{ item.service }}</h2>
                            <p>{{ item.body }}</p>
                        </div>
                        <small class="text-right created__at">
                            {{ item.created_at }}
                        </small>
                    </div>
                </template>
            </comp-record-logs-time-line>
        </b-col>
    </div>
</template>

<style lang="scss" scoped>
.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 0;
        }

        span {
            color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}

button {
    height: 44px;
}

.divider {
    height: 1px;
    width: 100%;
    background: var(--secondary);
}

.created__at {
    min-width: 120px;
}

@media only screen and (max-width: 575px) {
    .created__at {
        min-width: min-content;
    }
}
</style>
