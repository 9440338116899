import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class NewLevel extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/level/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('v2/level/findOne', body);
    }

    createItem(body) {
        return this.post('v2/level', body);
    }

    updateItem(body) {
        return this.post('v2/level/update', body);
    }

    deleteItem(body) {
        return this.post('v2/level/remove', body);
    }

    fetchAllLevelBalance(body) {
        return this.post('v2/level/balance', body);
    }

    fetchAllLevelScore(body) {
        return this.post('v2/level/score', body);
    }

    fetchAllLevelWinnings(body) {
        return this.post('v2/level/winnings', body);
    }

    fetchLevelStatistics(body) {
        return this.post('v2/level/statWinnings', body);
    }

    userLevelUp(body) {
        return this.post('v2/level/fill-up', body);
    }
}

export default NewLevel;
