<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'AppVoteStatistics',
    components: { ProgressLinear, apexchart: VueApexCharts },
    data() {
        return {
            voteStatistics: [],
            chartOptions: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                loaded: false,
                series: [],
                labels: [],
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            isFetching: false
        };
    },
    async mounted() {
        await this.getRoadmapStatistics();
    },
    methods: {
        getChartOptions(labels) {
            return {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#002296',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f'
                ],
                loaded: false,
                labels: labels,
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom'
                },
                dataLabels: {
                    formatter: function (val, opts) {
                        return (
                            opts.w.config.labels[opts.seriesIndex]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                            ':' +
                            opts.w.config.series[opts.seriesIndex]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        );
                    }
                },
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                }
            };
        },

        // ROADMAP STATISTICS
        async getRoadmapStatistics() {
            this.isFetching = true;
            try {
                const { data } = await api.otherStatistics.fetchVoteStatistics({
                    merchant_id: parseInt(MERCHANT_ID)
                });

                this.voteStatistics = data.data.map((survey) => {
                    return {
                        name: survey.name,
                        questions: survey.list.map((question) => {
                            return {
                                name: question.name,
                                series: question.list.map((answer) => answer.count),
                                labels: question.list.map((answer) => answer.name)
                            };
                        })
                    };
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.isFetching = false;
            }
        }
    }
};
</script>

<template>
    <div>
        <!--      ROADMAP STATISTICS      -->
        <progress-linear v-if="isFetching" />
        <div v-else>
            <div class="card p-1" v-for="(vote, index) in voteStatistics" :key="index">
                <h3>{{ vote.name }}</h3>
                <div class="row">
                    <div
                        class="col-12 col-md-6 col-xl-4"
                        v-for="(chart, index) in vote.questions"
                        :key="index"
                    >
                        <p class="text-center">{{ chart.name }}</p>
                        <apexchart
                            :options="getChartOptions(chart.labels)"
                            :series="chart.series"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
