var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.categories'))+" ")]},proxy:true},(_vm.hasAccess('categories', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"b-table--less-padding",attrs:{"striped":"","borderless":"","outlined":"","responsive":true,"fields":_vm.tableHeader,"items":_vm.computedCategories},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (item.type))))+" ")]}},{key:"cell(packages)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (item.packages))))+" ")]}},{key:"cell(gifts)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"300px"}},[_c('chips-wrapper',{attrs:{"items":item.gifts,"filter":_vm.filterGiftsNameByLang}})],1)]}},{key:"cell(last_package_id)",fn:function(ref){
var item = ref.item;
return [_c('chip',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getGiftByID(item.last_package_id))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteCategory,"delete-access":{ section: 'categories', permission: 'delete' },"update-access":{ section: 'categories', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","cancel-variant":"danger","centered":"","hide-footer":false,"no-close-on-backdrop":false,"size":"lg","title":_vm.$t('add.category')},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(!_vm.updatingItem)?_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(_vm.createOneMore),callback:function ($$v) {_vm.createOneMore=$$v},expression:"createOneMore"}},[_vm._v(" "+_vm._s(_vm.$t('titles.add_more'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","placeholder":_vm.$t('enter.title'),"state":errors.length > 0 ? false : null},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.type'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"placeholder":_vm.$t('choose.type'),"options":_vm.categoryTypes,"menu-props":{
                                    closeOnContentClick: false
                                },"reduce":function (type) { return type.key; },"searchable":false},model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.step'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.step'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"placeholder":_vm.$t('choose.step'),"options":_vm.categorySteps,"menu-props":{
                                    closeOnContentClick: false
                                },"searchable":false},model:{value:(_vm.dataForModal.step),callback:function ($$v) {_vm.$set(_vm.dataForModal, "step", $$v)},expression:"dataForModal.step"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.packages'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.packages'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"placeholder":_vm.$t('choose.package'),"options":_vm.categoryPackages,"menu-props":{
                                    closeOnContentClick: false
                                },"reduce":function (pack) { return pack.key; },"searchable":false},model:{value:(_vm.dataForModal.packages),callback:function ($$v) {_vm.$set(_vm.dataForModal, "packages", $$v)},expression:"dataForModal.packages"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.tournaments'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.tournaments'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('custom-select',{attrs:{"multiple":"","options":_vm.filteredGiftsByPackage,"placeholder":_vm.$t('choose.tournaments')},model:{value:(_vm.dataForModal.gift_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "gift_id", $$v)},expression:"dataForModal.gift_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.lastGift'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.lastGift'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('custom-select',{attrs:{"options":_vm.filteredGiftsByPackage,"errors":errors,"placeholder":_vm.$t('choose.lastGift')},model:{value:(_vm.dataForModal.last_package_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "last_package_id", $$v)},expression:"dataForModal.last_package_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }