import { axiosInstance } from '@/services/core/base';
import Core from '@/services/core';

class Users extends Core {
    constructor(url) {
        super(axiosInstance, url);
    }
}

export default Users;
