import gettersChat from '@/store/dashboard/chat/getters.chat';
import mutationsChat from '@/store/dashboard/chat/mutations.chat';
import actionsChat from '@/store/dashboard/chat/actions.chat';

const pagination = {
    current: 1,
    previous: 0,
    next: 0,
    perPage: 10,
    totalPage: 0,
    totalItem: 0
};

export function paginationSchema() {
    return Object.assign({}, pagination);
}

export const chatListSchema = () => ({
    result: [],
    isFetching: false,
    pagination: paginationSchema()
});

export const stateSchema = {
    paginationSchema: paginationSchema(),
    activeTab: 0,
    search: {
        query: ''
    },
    activeChatContact: {},
    chats: {
        result: [],
        pending: false,
        isFetching: false,
        refreshDelay: 1000,
        chatRefreshTimer: null,
        pagination: paginationSchema(),
        lastUpdatedTime: null,
        pms: {
            update: true
        }
    },
    contacts: {
        result: [],
        complete: false,
        isFetching: false,
        pagination: paginationSchema()
    },
    chatMessages: {
        user: {},
        result: [],
        isFetching: false,
        activeChatId: undefined,
        chatMessagesContentId: 'chat-messages-content',
        updating: false,
        refreshTimer: null,
        pms: {
            update: true
        }
    },
    activeClient: {},
    replyContext: {},
    editContext: {},
    messageQueue: {}
};

export default {
    namespaced: true,
    state: Object.assign({}, stateSchema),
    getters: gettersChat,
    mutations: mutationsChat,
    actions: actionsChat
};
