import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class BlacklistService extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchUserBlacklistStatus(body) {
        return this.post('blacklist/status', body);
    }

    updateUserBlacklistStatus(body) {
        return this.post('blacklist/update', body);
    }
}

export default BlacklistService;
