import Core from '@/services/axios/core.axios';
import { axiosInstance } from '@/services/core/base';

class LogService extends Core {
    constructor() {
        super({
            axios: axiosInstance
        });
    }

    actions() {
        return this.get('log/actions');
    }
}

export const logApi = new LogService();
