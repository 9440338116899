<script>
import { BCol, BPagination, BTable } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import { EventBus } from '@/util/event-bus';

export default {
    name: 'AppCardsManagementActiveCards',
    components: { BPagination, BCol, ProgressLinear, BTable, vSelect },
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            filter: {
                is_active: null
            },
            staticStatusList: [
                {
                    label: this.$t('titles.all_cards'),
                    value: null
                },
                {
                    label: this.$t('titles.active_cards'),
                    value: true
                },
                {
                    label: this.$t('titles.expired_cards'),
                    value: false
                }
            ],
            requestPending: false,
            receivedData: null
        };
    },
    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getActiveCards();
            },
            deep: true,
            immediate: true
        },

        receivedData: {
            handler() {
                if (this.receivedData) {
                    this.getActiveCards();
                }
                this.receivedData = false;
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'card_name',
                    label: this.$t('titles.title') + ' ' + this.$t('titles.cards')
                },
                {
                    key: 'card_type',
                    label: this.$t('titles.types') + ' ' + this.$t('titles.cards')
                },
                {
                    key: 'card_days',
                    label: this.$t('titles.days') + ' ' + this.$t('titles.cards')
                },
                {
                    key: 'date_start',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'date_end',
                    label: this.$t('titles.end_date')
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    created() {
        EventBus.$on('send-data', (data) => {
            this.receivedData = data;
        });
    },

    methods: {
        checkLocales,

        async getActiveCards() {
            this.requestPending = true;
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                is_active: this.filter.is_active,
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.$route.params.id
            };
            if (this.filter.is_active === null) {
                delete body.is_active;
            }
            try {
                const { data } = await api.cardsManagementApi.fetchActiveCards(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getActiveCards();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    },

    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        EventBus.$off('send-data');
    }
};
</script>

<template>
    <div class="active-cards">
        <div class="d-flex align-items-center float-right mb-1">
            <v-select
                v-model="filter.is_active"
                :options="staticStatusList"
                :clearable="false"
                label="label"
                :reduce="(option) => option.value"
                @input="getActiveCards"
                class="mr-1"
                style="width: 250px"
            />
            <b-button @click="getActiveCards" variant="primary">
                <feather-icon icon="RefreshCcwIcon"></feather-icon>
            </b-button>
        </div>
        <div>
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(card_name)="{ item }">
                    {{ checkLocales(item.card.name) }}
                </template>

                <template #cell(card_type)="{ item }">
                    {{ item.card.type }}
                </template>

                <template #cell(card_days)="{ item }">
                    {{ item.card.days }}
                </template>
            </b-table>
            <h5 class="text-center my-1" v-if="items.length === 0">{{ $t('no_data') }}</h5>

            <!--  PAGINATION  -->
            <b-col class="d-flex justify-content-center my-2" cols="12">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mb-3"
                    first-number
                    last-number
                />
            </b-col>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
