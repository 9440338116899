<script>
import { computed } from '@vue/composition-api';
import api from '@/services/api';
import { BCol, BRow, VBTooltip } from 'bootstrap-vue';
import getMobileOperatorLogo from '@/constants/mobileOperators';
import { generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import locales from '@/constants/locales';
import ShowHideInfo from '@/components/ShowHideInfo';
import Switcher from '@/components/ui/Switcher/Switcher';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';

export default {
    name: 'SubscribersSingle',
    components: {
        Switcher,
        ShowHideInfo,
        BRow,
        BCol
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data: () => ({
        subscriber: {
            company: '',
            phone: '',
            full_name: '',
            current_balance: '',
            rt_balance: '',
            coin_balance: 0,
            wallet_balance: 0,
            account_status: '',
            tariff: '',
            percents: {
                loaded: false,
                items: {}
            },
            position: null,
            group: null,
            score: null
        },
        streakStatus: null,
        subscriptionDeadline: null,
        userStatus: null,
        isBlocked: null,
        userLevel: [],
        smallTable: false,
        streakData: null,

        filterField: {},
        locales,
        localLang: localStorage.getItem('lang'),
        requestPending: false,
        merchant: parseInt(MERCHANT_ID)
    }),
    provide() {
        return {
            gifts: computed(() => {
                if (!this.filterField.gifts) return;
                return this.filterField.gifts.map((item) => {
                    if (typeof item.name === 'object') {
                        item.name = item.name[this.localLang];
                    }
                    return item;
                });
            }),
            steps: computed(() => this.filterField.steps),
            transactions: computed(() => {
                if (!this.filterField.transactions) return;
                return this.filterField.transactions.map((item) => {
                    if (typeof item.name === 'object') {
                        item.name = item.name[this.localLang];
                    }
                    return item;
                });
            }),
            actions: computed(() => {
                if (!this.filterField.logs) return;
                const actions = [];
                this.filterField.logs.map((item) => {
                    actions.push({
                        key: item,
                        label: this.$t(`titles.actions.${item}`)
                        // label: item
                    });
                });
                return actions;
            }),
            coinTypes: computed(() => {
                if (!this.filterField.coin) return;
                const coinTypes = [];
                this.filterField.coin.map((item) => {
                    coinTypes.push({
                        key: item,
                        // label: this.$t(`titles.actions.${item}`)
                        label: item
                    });
                });
                return coinTypes;
            }),
            giftTypes: computed(() => {
                if (!this.filterField.types) return;
                return this.filterField.types;
            }),
            subscriber: computed(() => {
                if (!this.subscriber) return;
                return this.subscriber;
            })
        };
    },
    computed: {
        tabsNavlinks() {
            const navs = [
                {
                    route: {
                        name: 'subscriber-new-logger'
                    },
                    title: 'New Logger'
                },
                // {
                //     route: {
                //         name: 'subscribers Single Activities'
                //     },
                //     title: 'Activity logs'
                // },
                {
                    route: {
                        name: 'subscribers Single Gifts'
                    },
                    title: 'Gifts'
                },
                {
                    route: {
                        name: 'subscribers Single Transactions'
                    },
                    title: 'Transactions'
                },
                {
                    route: {
                        name: 'subscribers Single Manual Packets'
                    },
                    title: 'Manual Packet Activation',
                    section: 'activate_pack',
                    permission: 'activate'
                },
                {
                    route: {
                        name: 'subscribers Single Activate Packet As Subscriber'
                    },
                    title: 'Activate Packet as Subscriber',
                    section: 'activate_pack',
                    permission: 'activate'
                },
                {
                    route: {
                        name: 'subscriber-roadmap-info'
                    },
                    title: 'Roadmap',
                    children: ['subscriber-roadmap-score', 'subscriber-roadmap-product-history']
                },
                {
                    route: {
                        name: 'subscribers Single Wins'
                    },
                    title: 'Wins'
                },
                {
                    route: {
                        name: 'new-level-activity'
                    },
                    title: 'Level'
                },
                {
                    route: {
                        name: 'subscriber-coin-monitoring'
                    },
                    title: 'Coin Monitoring'
                },
                {
                    route: {
                        name: 'subscriber-game-monitoring'
                    },
                    title: 'Game Monitoring'
                },
                {
                    route: {
                        name: 'subscriber-coin-orders'
                    },
                    title: 'Order History'
                },
                {
                    route: {
                        name: 'subscriber-streak'
                    },
                    title: 'Streak'
                }
                // {
                //     route: {
                //         name: 'subscriber-wallet-transactions'
                //     },
                //     title: 'Wallet Transactions'
                // }
            ];

            if (this.merchant === 2) {
                // navs.splice(4, 1);
                navs.push(
                    // {
                    //     route: {
                    //         name: 'subscriber-task-progress'
                    //     },
                    //     title: 'Subscriber Task Progress'
                    // }
                    {
                        route: {
                            name: 'subscriber-wallet-transactions'
                        },
                        title: 'Wallet Transactions'
                    }
                );
            }

            return navs;
        }
    },
    methods: {
        async getSubscriber() {
            if (this.merchant === 2) {
                await api.subscribers.getSingle(this.$route.params.id).then(({ data }) => {
                    Object.assign(this.subscriber, data);
                });
                await this.checkSubscriberStatus();
                await this.checkSubscriberBlockStatus();
            } else {
                await api.lampSubscribers
                    .fetchOneSubscriber(this.$route.params.id)
                    .then(({ data }) => {
                        Object.assign(this.subscriber, data.result);
                    });
                await this.checkSubscriberStatus();
                await this.checkSubscriberBlockStatus();
            }
        },

        async getSubscriberInfo() {
            if (this.merchant === 2) {
                await api.subscribers.getPersonalInfo(this.$route.params.id).then(({ data }) => {
                    Object.assign(this.subscriber, data);
                });
            }
        },

        async getCoinBalance() {
            const body = {
                merchant_id: MERCHANT_ID,
                user_id: this.$route.params.id
            };
            await api.coin.getSubscribeCoinBalance(body).then(({ data }) => {
                this.subscriber.coin_balance = data['balance'];
            });
        },

        async getUserWalletBalance() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.$route.params.id
            };
            await api.walletBalanceApi.fetchUserWalletBalance(body).then(({ data }) => {
                this.subscriber.wallet_balance = data.data['balance'];
            });
        },

        async changeActive() {
            try {
                this.requestPending = true;
                await api.subscribers.updateActivate(this.$route.params.id);
                Promise.all([await this.getSubscriberInfo(), await this.getSubscriber()]).finally(
                    () => {
                        this.requestPending = false;
                    }
                );
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        async getFilterFields() {
            const { data } = await api.subscribers.getFilterFields();
            this.filterField = data;
        },

        async getLevelStatisticsV2() {
            const body = {
                page: 1,
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                sort: {
                    column: 'id',
                    direction: 'asc'
                },
                search: '',
                filters: {
                    user_id: this.$route.params.id
                }
            };
            const { data } = await api.newLevel.fetchAllLevelBalance(body);
            const response = data.data.list;
            this.userLevel = [
                {
                    level: 1,
                    balance: null,
                    max_score: null
                },
                {
                    level: 2,
                    balance: null,
                    max_score: null
                },
                {
                    level: 3,
                    balance: null,
                    max_score: null
                }
            ];
            for (let i = 0; i < response.length; i++) {
                if (response[i].level.level === 1) {
                    this.userLevel[0].balance = response[i].balance;
                    this.userLevel[0].max_score = response[i].level.max_score;
                }
                if (response[i].level.level === 2) {
                    this.userLevel[1].balance = response[i].balance;
                    this.userLevel[1].max_score = response[i].level.max_score;
                }
                if (response[i].level.level === 3) {
                    this.userLevel[2].balance = response[i].balance;
                    this.userLevel[2].max_score = response[i].level.max_score;
                }
            }
        },

        async getLevelStatistics() {
            if (this.merchant === 2) {
                await api.subscribers
                    .getLevelsStatistics(this.$route.params.id)
                    .then(({ data }) => {
                        this.subscriber.percents.items = data.percents;
                        this.subscriber.percents.loaded = true;
                    });
            } else {
                await api.lampSubscribers.fetchLevel(this.$route.params.id).then(({ data }) => {
                    this.subscriber.percents.items = data.percents;
                    this.subscriber.percents.loaded = true;
                });
            }
        },

        async getPositionAndGroup() {
            if (this.merchant === 1) {
                await api.tournament
                    .fetchUserPosition(this.$route.params.id, this.merchant)
                    .then(({ data }) => {
                        this.subscriber.position = data.result.position;
                        this.subscriber.score = data.result.total_scores;
                    });
            }
        },

        async getScenarioHistory() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                user_id: parseInt(this.$route.params.id)
            };
            try {
                const { data } = await api.scenario.fetchScenarioHistory(body);
                this.streakData = data.result;
            } catch (e) {
                console.error(e);
            }
        },

        subscriberLogo(name) {
            return getMobileOperatorLogo(name);
        },

        generateIconToBoolean(value) {
            return generateIconToBoolean(value);
        },

        generateIconColorToBoolean(value) {
            return generateIconColorToBoolean(value);
        },

        generateLangFlag(lang) {
            const { img } = this.locales.find((item) => item.locale === lang);
            return img;
        },

        formatSum(sum) {
            return parseFloat(sum.toFixed(2)).toLocaleString('en').replace(/,/g, ' ');
        },

        isUzmobile(name) {
            return name === 'uzmobile';
        },
        getLevelPercent(x) {
            const v = x.toFixed(5).split('.');
            return v[0] + '.' + v[1].slice(0, 2);
        },
        getLevelPercentage(maxScore, score) {
            return ((score / maxScore) * 100).toFixed(2);
        },
        async checkSubscriberStatus() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                phone: this.subscriber?.phone
            };
            try {
                const { data } = await api.subscriptionV2.checkSubscriberStatus(body);
                this.userStatus = data.data.is_subscriber;
                const subscriptionStartDate = data?.data?.created_at;
                const subscriptionName = checkLocales(data?.data?.subscription?.title);
                // this.subscriptionDeadline = `${subscriptionName} | ${subscriptionStartDate?.[0]} - ${data.data['expiry_date']}`;
                this.subscriptionDeadline = `${subscriptionName} | <em class="text-muted">${subscriptionStartDate} &mdash; ${data.data['expiry_date']}</em>`;
            } catch (e) {
                console.error(e);
            }
        },

        async checkSubscriberBlockStatus() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                phone: this.subscriber?.phone
            };
            try {
                const { data } = await api.blackListApi.fetchUserBlacklistStatus(body);
                this.isBlocked = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async toggleSubscriberActivation() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                phone: this.subscriber?.phone
            };
            if (this.userStatus) {
                try {
                    await api.subscriptionV2.activateSubscription(body);
                } catch (e) {
                    console.error(e);
                } finally {
                    setTimeout(async () => {
                        await this.checkSubscriberStatus();
                    }, 1000);
                }
            } else {
                try {
                    await api.subscriptionV2.deactivateSubscription(body);
                } catch (e) {
                    console.error(e);
                } finally {
                    setTimeout(async () => {
                        await this.checkSubscriberStatus();
                    }, 1000);
                }
            }
        },

        async toggleSubscriberBlock() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                phone: this.subscriber?.phone
            };
            try {
                await api.blackListApi.updateUserBlacklistStatus(body);
            } catch (e) {
                console.error(e);
            } finally {
                setTimeout(async () => {
                    await this.checkSubscriberBlockStatus();
                }, 1000);
            }
        },
        checkActiveRoute(link) {
            const routeName = this.$route.name;
            let valid = link.route.name === routeName;
            if (!valid && link.children && link.children.length > 0) {
                valid = link.children.filter((item) => item === routeName).length > 0;
                // this.$router.push(link.children[0]);
            }
            return valid;
        }
    },
    mounted() {
        // this.requestPending = true;
        this.smallTable = window.innerWidth < 992;
        Promise.all([
            this.getSubscriberInfo(),
            this.getSubscriber(),
            // this.getLevelStatistics(),
            this.getLevelStatisticsV2(),
            // this.getFilterFields(),
            this.getCoinBalance(),
            this.getUserWalletBalance(),
            this.getScenarioHistory()
            // this.getPositionAndGroup()
        ]).finally(() => {
            this.requestPending = false;
        });
        if (this.$route.name === 'subscribers Single') {
            this.$router.push({
                name: 'subscriber-new-logger'
            });
        }
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col cols="12">
                <div class="card p-2">
                    <b-row>
                        <b-col cols="12">
                            <img
                                v-if="subscriber.company"
                                :src="subscriberLogo(subscriber.company)"
                                alt=""
                                class="subscriber-single__company-logo mb-1"
                            />
                        </b-col>
                        <b-col class="col-lg-6 col-12">
                            <table style="vertical-align: top" width="100%">
                                <tr>
                                    <th class="pb-1">Nomer:</th>
                                    <td class="pb-1">
                                        {{ subscriber.phone }}
                                    </td>
                                </tr>
                                <template v-if="isUzmobile(subscriber.company)">
                                    <tr>
                                        <th class="pb-1">FIO:</th>
                                        <td class="pb-1">
                                            {{ subscriber.full_name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1">Balans:</th>
                                        <td class="pb-1">
                                            <div class="d-flex">
                                                <show-hide-info
                                                    v-if="subscriber.current_balance"
                                                    v-b-tooltip.hover.top="
                                                        `Кешированный баланс ${subscriber.current_balance}`
                                                    "
                                                    :value="formatSum(subscriber.current_balance)"
                                                />
                                                <span class="mx-2">|</span>
                                                <show-hide-info
                                                    v-if="subscriber.rt_balance"
                                                    v-b-tooltip.hover.top="
                                                        `РТ баланс ${subscriber.rt_balance}`
                                                    "
                                                    :value="formatSum(subscriber.rt_balance)"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1">Account Status:</th>
                                        <td class="pb-1">
                                            {{ subscriber.account_status }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1">Tariff:</th>
                                        <td v-if="subscriber.tariff" class="pb-1">
                                            {{ subscriber.tariff.name }}
                                            |
                                            <strong>ID: {{ subscriber.tariff.id }}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1">Status</th>
                                        <td class="pb-1">
                                            {{ subscriber.status }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1">Subscription</th>
                                        <td class="pb-1">
                                            <span v-html="subscriptionDeadline" />
                                        </td>
                                    </tr>
                                </template>

                                <!--                                <tr>-->
                                <!--                                    <th class="pb-1">Streak:</th>-->
                                <!--                                    <td class="pb-1">-->
                                <!--                                        {{ streak }}-->
                                <!--                                    </td>-->
                                <!--                                </tr>-->
                            </table>
                            <div v-if="streakData && streakData.days">
                                <div class="font-medium-1 font-weight-bold mr-1">
                                    <var>
                                        Streak
                                        <div>
                                            {{ $t('titles.days') }}:
                                            <span>{{ streakData.days.length }}</span>
                                        </div>
                                        <div>
                                            {{ $t('titles.days_passed') }}:
                                            <span>{{ streakData['days_passed'] }}</span>
                                        </div>
                                        <div>
                                            {{ $t('titles.is_completed') }}:
                                            <span>
                                                <feather-icon
                                                    :class="[
                                                        streakData['is_completed']
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    ]"
                                                    :icon="
                                                        streakData['is_completed']
                                                            ? 'CheckIcon'
                                                            : 'XIcon'
                                                    "
                                                    size="20"
                                                />
                                            </span>
                                        </div>
                                    </var>
                                </div>
                                <!--                                    <div v-for="streak in streakData.days" :key="streak.index">-->
                                <div class="d-flex flex-wrap">
                                    <div v-for="streak in streakData.days" :key="streak.index">
                                        <span
                                            class="streak__circle d-flex align-items-center justify-content-center"
                                            :class="
                                                streak.status === 3
                                                    ? 'streak__circle-transparent'
                                                    : streak.status === 0
                                                    ? 'streak__circle-success'
                                                    : streak.status === 2
                                                    ? 'streak__circle-danger'
                                                    : ''
                                            "
                                        >
                                            <feather-icon
                                                v-if="streak.status === 3"
                                                size="20"
                                                icon="GiftIcon"
                                                class="text-primary"
                                            />

                                            <feather-icon
                                                v-if="streak.status === 2"
                                                size="16"
                                                icon="MinusIcon"
                                                class="text-white"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <table>
                                <template v-if="!isUzmobile(subscriber.company)">
                                    <tr>
                                        <th class="pb-1">Subscription</th>
                                        <td class="pb-1">
                                            <span v-html="subscriptionDeadline" />
                                        </td>
                                    </tr>
                                </template>
                            </table>
                            <div></div>
                        </b-col>
                        <b-col class="col-lg-6 col-12">
                            <table>
                                <tbody>
                                    <tr>
                                        <th class="pb-1" colspan="2" style="">Active</th>
                                        <td class="pb-1">
                                            <switcher
                                                v-model="userStatus"
                                                :disabled="!hasAccess('activate_pack', 'activate')"
                                                confirmation
                                                @input="toggleSubscriberActivation"
                                            />
                                            <!--                                            <b-form-checkbox-->
                                            <!--                                                v-model="subscriber.is_subscriber"-->
                                            <!--                                                switch-->
                                            <!--                                                :disabled="-->
                                            <!--                                                    !hasAccess('activate_pack', 'activate') ||-->
                                            <!--                                                    merchant === 1-->
                                            <!--                                                "-->
                                            <!--                                            />-->
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1" colspan="2" style="">Blocked</th>
                                        <td class="pb-1">
                                            <switcher
                                                v-model="isBlocked"
                                                :disabled="!hasAccess('activate_pack', 'activate')"
                                                confirmation
                                                @input="toggleSubscriberBlock"
                                            />
                                            <!--                                            <b-form-checkbox-->
                                            <!--                                                v-model="subscriber.is_subscriber"-->
                                            <!--                                                switch-->
                                            <!--                                                :disabled="-->
                                            <!--                                                    !hasAccess('activate_pack', 'activate') ||-->
                                            <!--                                                    merchant === 1-->
                                            <!--                                                "-->
                                            <!--                                            />-->
                                        </td>
                                    </tr>
                                    <tr v-if="subscriber.language">
                                        <th class="pb-1" colspan="2">Language</th>
                                        <td class="pb-1">
                                            <img
                                                :src="generateLangFlag(subscriber.language)"
                                                alt=""
                                                height="20px"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1" colspan="2">Business</th>
                                        <td class="pb-1">
                                            <feather-icon
                                                :class="[
                                                    'stroke-current',
                                                    generateIconColorToBoolean(subscriber.business)
                                                ]"
                                                :icon="generateIconToBoolean(subscriber.business)"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-1" colspan="2">Verified</th>
                                        <td class="pb-1">
                                            <feather-icon
                                                :class="[
                                                    'stroke-current',
                                                    generateIconColorToBoolean(subscriber.verified)
                                                ]"
                                                :icon="generateIconToBoolean(subscriber.verified)"
                                            />
                                        </td>
                                    </tr>
                                    <tr v-if="!smallTable">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Level 1</th>
                                                    <th>Level 2</th>
                                                    <th>Level 3</th>
                                                    <th>FIT-Coin</th>
                                                    <th>Wallet</th>
                                                    <!--                                                    <th>Position</th>-->
                                                    <!--                                                    <th>Score</th>-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        v-for="(level, index) in userLevel"
                                                        :key="index"
                                                    >
                                                        <span v-if="level && level.max_score">
                                                            {{
                                                                getLevelPercentage(
                                                                    level.max_score,
                                                                    level['balance']
                                                                )
                                                            }}%
                                                        </span>
                                                        <span v-else>0.00%</span>
                                                    </td>

                                                    <td>
                                                        {{ subscriber.coin_balance }}
                                                    </td>

                                                    <td>
                                                        {{ subscriber.wallet_balance }}
                                                    </td>
                                                    <!--                                                    <td>-->
                                                    <!--                                                        {{ subscriber.position }}-->
                                                    <!--                                                    </td>-->
                                                    <!--                                                    <td>-->
                                                    <!--                                                        {{ subscriber.score }}-->
                                                    <!--                                                    </td>-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="smallTable" class="small__table table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Level 1</th>
                                            <th>Level 2</th>
                                            <th>Level 3</th>
                                            <th>FIT-Coin</th>
                                            <th>Wallet</th>
                                            <!--                                            <th>Position</th>-->
                                            <!--                                            <th>Score</th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                v-for="(level, index) in userLevel"
                                                v-show="userLevel.length"
                                                :key="index"
                                            >
                                                <span v-if="level"
                                                    >{{
                                                        getLevelPercentage(
                                                            level.max_score,
                                                            level['balance']
                                                        )
                                                    }}%</span
                                                >
                                                <span v-else>0.00%</span>
                                            </td>
                                            <td>
                                                {{ subscriber.coin_balance }}
                                            </td>
                                            <td>
                                                {{ subscriber.wallet_balance }}
                                            </td>
                                            <!--                                            <td>{{ subscriber.position }}</td>-->
                                            <!--                                            <td>-->
                                            <!--                                                {{ subscriber.score }}-->
                                            <!--                                            </td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="12">
                <div class="nav nav-tabs flex-nowrap overflow-x-scroll">
                    <router-link
                        v-for="(link, index) in tabsNavlinks"
                        v-if="hasAccess(link.section, link.permission)"
                        :key="index"
                        :to="link.route"
                        class="nav-link"
                        :class="checkActiveRoute(link) ? 'active' : ''"
                        style="min-width: fit-content"
                    >
                        {{ link.title }}
                    </router-link>
                </div>
            </b-col>
            <b-col cols="12">
                <keep-alive>
                    <router-view
                        @debitFunctionTriggered="getUserWalletBalance"
                        @creditFunctionTriggered="getUserWalletBalance"
                        :phoneNumber="subscriber.phone"
                    />
                </keep-alive>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: top;
}

th {
    width: 250px;
}

.small__table .table th,
.small__table .table td {
    padding: 0.25rem 0.5rem;
    text-align: center;
    vertical-align: middle;
    min-width: 80px;
}

.nav-tabs::-webkit-scrollbar {
    display: none;
}

.streak__circle {
    display: block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: var(--secondary);

    &-transparent {
        background: transparent;
    }

    &-success {
        background: var(--success);
    }

    &-danger {
        background: var(--secondary);
    }
}
</style>
