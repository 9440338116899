<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CompStackedChart from '@/components/CompStackedChart.vue';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';

export default {
    name: 'AppFItCoinStatistics',
    components: { CompStackedChart, ProgressLinear },
    data() {
        return {
            coinStatistics: {
                isPending: true,
                categories: [],
                debitCount: {
                    series: [],
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: false,
                        y: {
                            show: true,
                            formatter: function (val) {
                                if (val.toString().length < 4) {
                                    return val.toString();
                                } else {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                                // return val;
                            }
                        },
                        x: {
                            show: true,
                            formatter: function (val, opts) {
                                if (val.toString().length < 4) {
                                    return val.toString();
                                } else {
                                    return (
                                        val +
                                        ' - ' +
                                        opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    );
                                }
                            }
                        },
                        fixed: {
                            enabled: true,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0
                        }
                    }
                },
                debitSum: {
                    series: [],
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: true,
                        y: {
                            show: true,
                            formatter: function (val) {
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        },
                        x: {
                            show: true,
                            formatter: function (val, opts) {
                                return (
                                    val +
                                    ' - ' +
                                    opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                );
                            }
                        },
                        fixed: {
                            enabled: true,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0
                        }
                    }
                },
                creditCount: {
                    series: [],
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: true,
                        y: {
                            show: true,
                            formatter: function (val) {
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        },
                        x: {
                            show: true,
                            formatter: function (val, opts) {
                                return (
                                    val +
                                    ' - ' +
                                    opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                );
                            }
                        },
                        fixed: {
                            enabled: true,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0
                        }
                    }
                },
                creditSum: {
                    series: [],
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: true,
                        y: {
                            show: true,
                            formatter: function (val) {
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                        },
                        x: {
                            show: true,
                            formatter: function (val, opts) {
                                return (
                                    val +
                                    ' - ' +
                                    opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                );
                            }
                        },
                        fixed: {
                            enabled: true,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0
                        }
                    }
                }
            }
        };
    },
    async mounted() {
        await this.getCoinStatistics();
    },
    methods: {
        // COIN STATISTICS
        async getCoinStatistics() {
            this.coinStatistics.debitCount.series.length = 0;
            this.coinStatistics.debitSum.series.length = 0;
            this.coinStatistics.creditCount.series.length = 0;
            this.coinStatistics.creditSum.series.length = 0;
            this.coinStatistics.categories.length = 0;
            this.coinStatistics.isPending = true;
            try {
                const { data } = await api.otherStatistics.fetchCoinStatistics({
                    date_start: this.date_start,
                    date_end: this.date_end,
                    merchant_id: parseInt(MERCHANT_ID)
                });

                const debitCountSeriesMap = new Map();
                const debitSumSeriesMap = new Map();
                const creditCountSeriesMap = new Map();
                const creditSumSeriesMap = new Map();

                // DEBIT COUNT
                data.data.dates.forEach((dateObj) => {
                    dateObj.list.forEach((item) => {
                        if (!debitCountSeriesMap.has(item.name)) {
                            debitCountSeriesMap.set(item.name, { name: item.name, data: [] });
                            debitSumSeriesMap.set(item.name, { name: item.name, data: [] });
                            creditCountSeriesMap.set(item.name, { name: item.name, data: [] });
                            creditSumSeriesMap.set(item.name, { name: item.name, data: [] });
                        }
                    });
                });

                data.data.dates.forEach((dateObj) => {
                    const dateSeries = new Map(debitCountSeriesMap);
                    dateObj.list.forEach((item) => {
                        dateSeries.get(item.name).data.push(item.debit.count);
                    });
                    dateSeries.forEach((series, name) => {
                        if (!dateObj.list.some((item) => item.name === name)) {
                            series.data.push(0);
                        }
                    });
                    this.coinStatistics.categories.push(dateObj.date.substring(5));
                });

                this.coinStatistics.debitCount.series = Array.from(
                    debitCountSeriesMap.values()
                ).filter((series) => series.data.some((count) => count !== 0));

                // DEBIT SUM
                data.data.dates.forEach((dateObj) => {
                    const dateSeries = new Map(debitSumSeriesMap);
                    dateObj.list.forEach((item) => {
                        dateSeries.get(item.name).data.push(item.debit.sum);
                    });
                    dateSeries.forEach((series, name) => {
                        if (!dateObj.list.some((item) => item.name === name)) {
                            series.data.push(0);
                        }
                    });
                });

                this.coinStatistics.debitSum.series = Array.from(debitSumSeriesMap.values()).filter(
                    (series) => series.data.some((sum) => sum !== 0)
                );

                // CREDIT COUNT
                data.data.dates.forEach((dateObj) => {
                    const dateSeries = new Map(creditCountSeriesMap);
                    dateObj.list.forEach((item) => {
                        dateSeries.get(item.name).data.push(item['credit']['count']);
                    });
                    dateSeries.forEach((series, name) => {
                        if (!dateObj.list.some((item) => item.name === name)) {
                            series.data.push(0);
                        }
                    });
                });

                this.coinStatistics.creditCount.series = Array.from(
                    creditCountSeriesMap.values()
                ).filter((series) => series.data.some((count) => count !== 0));

                // CREDIT SUM
                data.data.dates.forEach((dateObj) => {
                    const dateSeries = new Map(creditSumSeriesMap);
                    dateObj.list.forEach((item) => {
                        dateSeries.get(item.name).data.push(item['credit']['sum']);
                    });
                    dateSeries.forEach((series, name) => {
                        if (!dateObj.list.some((item) => item.name === name)) {
                            series.data.push(0);
                        }
                    });
                });

                this.coinStatistics.creditSum.series = Array.from(
                    creditSumSeriesMap.values()
                ).filter((series) => series.data.some((sum) => sum !== 0));
            } catch (e) {
                console.error(e);
            } finally {
                this.coinStatistics.isPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="row">
        <!--      COIN STATISTICS [DEBIT COUNT]      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="coinStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="coinStatistics.debitCount.tooltip"
                    :is-pending="coinStatistics.isPending"
                    :series="coinStatistics.debitCount.series"
                    :categories="coinStatistics.categories"
                    :title="$t('titles.coin_statistics_debit_count')"
                />
            </div>
        </div>

        <!--      COIN STATISTICS [DEBIT SUM]      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="coinStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="coinStatistics.debitSum.tooltip"
                    :is-pending="coinStatistics.isPending"
                    :series="coinStatistics.debitSum.series"
                    :categories="coinStatistics.categories"
                    :title="$t('titles.coin_statistics_debit_sum')"
                />
            </div>
        </div>

        <!--      COIN STATISTICS [CREDIT COUNT]      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="coinStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="coinStatistics.creditCount.tooltip"
                    :is-pending="coinStatistics.isPending"
                    :series="coinStatistics.creditCount.series"
                    :categories="coinStatistics.categories"
                    :title="$t('titles.coin_statistics_credit_count')"
                />
            </div>
        </div>

        <!--      COIN STATISTICS [CREDIT SUM]      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="coinStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="coinStatistics.creditSum.tooltip"
                    :is-pending="coinStatistics.isPending"
                    :series="coinStatistics.creditSum.series"
                    :categories="coinStatistics.categories"
                    :title="$t('titles.coin_statistics_credit_sum')"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
