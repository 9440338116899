var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('server_keys')))]},proxy:true},(_vm.hasAccess('coin_measurement', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"striped":"","borderless":"","outlined":"","responsive":true,"fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(merchant_id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.merchant_id.name)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteServerKey,"delete-access":{ section: 'coin_measurement', permission: 'delete' },"update-access":{ section: 'coin_measurement', permission: 'update' }},on:{"openModal":_vm.fetchServerKey}})]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","cancel-variant":"danger","centered":"","hide-footer":false,"no-close-on-backdrop":true,"size":"md","title":_vm.updatingItem ? _vm.$t('edit.server_keys') : _vm.$t('add.server_keys')},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('server_keys')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('server_keys'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","placeholder":_vm.$t('server_keys'),"state":errors.length > 0 ? false : null},model:{value:(_vm.dataForModal.server_key),callback:function ($$v) {_vm.$set(_vm.dataForModal, "server_key", $$v)},expression:"dataForModal.server_key"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('app')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('app'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.merchantOptions,"placeholder":_vm.$t('choose.app'),"searchable":false,"label":"name"},model:{value:(_vm.dataForModal.merchant),callback:function ($$v) {_vm.$set(_vm.dataForModal, "merchant", $$v)},expression:"dataForModal.merchant"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"total-rows":_vm.pagination.totalItem,"per-page":_vm.pagination.perPage,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }