<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BFormGroup, BFormInput, BModal, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';

export default {
    name: 'AppDealerConstants',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                title: '',
                value: ''
            },
            requestPending: false
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'title',
                    label: this.$t('titles.real_title')
                },
                {
                    key: 'value',
                    label: this.$t('titles.value')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },

    async mounted() {
        await this.getAll();
    },

    methods: {
        checkLocales,

        async getAll() {
            try {
                const { data } = await api.dealerConstantsApi.fetchAllItems();
                this.items = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.dealerConstantsApi
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async updateItem(id, body) {
            await api.dealerConstantsApi
                .updateItem(id, body)
                .then(() => {
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        await this.updateItem(this.updatingItem.id, body, config);
                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        beforeClose() {
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.dealer_services') }}</template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="$t('edit.dealer_constants')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.real_title') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.real_title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title"
                                    :placeholder="$t('titles.real_title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    VALUE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.value') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.value')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.value"
                                    :placeholder="$t('titles.value')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
