<script>
export default {
    name: 'AppSubscription'
};
</script>

<template>
    <div class="subscription">
        <router-view />
    </div>
</template>

<style scoped lang="scss"></style>
