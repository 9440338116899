<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { formatNumber } from '@/util/helper';
import VueApexCharts from 'vue-apexcharts';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'AppSegmentationDetailEvent',
    components: {
        ProgressLinear,
        apexchart: VueApexCharts
    },
    data() {
        return {
            response: null,
            detailEventChart: {
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#b03966',
                    '#739373',
                    '#0097df',
                    '#057f5f'
                    // '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'pie',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            }
        };
    },
    async created() {
        await this.getSegmentationDetailEvent();
    },
    methods: {
        formatNumber,
        async getSegmentationDetailEvent() {
            this.detailEventChart.completed = false;
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                eventId: parseInt(this.$route.params.id)
            };
            try {
                const { data } = await api.segmentationApi.fetchDetailEvent(body);
                delete data.data['balanceCount']['event_id'];
                this.response = data.data;
                for (let key in this.response['balanceCount']) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.response['balanceCount'].hasOwnProperty(key)) {
                        this.detailEventChart.labels.push(key);
                        this.detailEventChart.series.push(this.response['balanceCount'][key]);
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.detailEventChart.completed = true;
            }
        }
    }
};
</script>

<template>
    <progress-linear v-if="!response" />
    <div v-else>
        <div class="row mb-2">
            <!--      EVENT      -->
            <div class="col-12 col-md-6 col-xl-4 mb-2 mb-xl-0">
                <div class="card p-1 font-weight-bold font-medium-3 h-100">
                    <h2 class="text-center">{{ $t('segmentation.event') }}</h2>
                    <div>
                        <span>ID</span> :
                        {{ response.event.id }}
                    </div>
                    <div>
                        <span>{{ $t('segmentation.users_qty') }}</span> :
                        {{ formatNumber(response.event['user_invoices']) }}
                    </div>
                    <div>
                        <span>{{ $t('segmentation.is_finished') }}</span> :
                        <feather-icon
                            :class="response.event['is_finish'] ? 'text-success' : 'text-danger'"
                            :icon="response.event['is_finish'] ? 'CheckIcon' : 'XIcon'"
                            size="20"
                        />
                    </div>
                    <div>
                        <span>{{ $t('game_monitoring.started_time') }}</span> :
                        {{ response.event['started_at'] }}
                    </div>
                    <div>
                        <span>{{ $t('titles.finished_time') }}</span> :
                        {{ response.event['finished_at'] }}
                    </div>
                </div>
            </div>

            <!--      INFO      -->
            <div class="col-12 col-md-6 col-xl-4 mb-2 mb-xl-0">
                <div class="card p-1 font-weight-bold font-medium-3 h-100">
                    <h2 class="text-center">{{ $t('segmentation.info') }}</h2>
                    <div>
                        <span>{{ $t('segmentation.is_die') }}</span> :
                        {{ formatNumber(response.info['is_die']) }}
                    </div>
                    <div>
                        <span>{{ $t('segmentation.is_life') }}</span> :
                        {{ formatNumber(response.info['is_life']) }}
                    </div>
                    <div>
                        <span>{{ $t('segmentation.rt_active') }}</span> :
                        {{ formatNumber(response.info['rt_active']) }}
                    </div>
                    <div>
                        <span>{{ $t('segmentation.rt_barring') }}</span> :
                        {{ formatNumber(response.info['rt_barring']) }}
                    </div>
                    <div>
                        <span>RC PACKAGE</span> :
                        {{ formatNumber(response.info['rc_status']) }}
                    </div>
                </div>
            </div>

            <!--      BALANCE INFO      -->
            <div class="col-12 col-md-6 col-xl-4">
                <div class="card py-1 px-2 h-100">
                    <div class="row">
                        <div class="col-12 px-0 d-flex flex-column align-items-center">
                            <h2 class="text-center">{{ $t('segmentation.balance_info') }}</h2>
                            <apexchart
                                :options="detailEventChart"
                                :series="detailEventChart.series"
                            />
                        </div>
                        <div class="col-12 px-0">
                            <p
                                class="text-center text-xl-left mb-0 font-weight-bold font-medium-2 px-1"
                                v-for="(item, index) in response['balanceCount']"
                                :key="index"
                            >
                                <span>{{ index }}</span> :
                                <span class="ml-1">{{ formatNumber(item) }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
