<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, updateObjectToNew } from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import { isArray, isObject } from '@/util/inspect.util';

export default {
    name: 'AppWalletBalance',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    directives: {
        'b-modal': VBModal,
        Ripple
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                description: {
                    ru: '',
                    en: '',
                    uz: ''
                },
                position: null,
                can_set_default: false,
                image: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            balanceVisibility: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllBalances();
                this.getBalanceVisibility();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.image = [];
            if (isArray(image)) {
                this.dataForModal.image = image;
            } else if (isObject(image)) {
                this.dataForModal.image.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'position',
                    label: this.$t('titles.position')
                },
                {
                    key: 'image',
                    label: this.$t('titles.image')
                },
                {
                    key: 'can_set_default',
                    label: this.$t('titles.default')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLang,

        async getBalanceVisibility() {
            try {
                const { data } = await api.walletBalanceApi.fetchBalanceVisibility();
                this.balanceVisibility = data.data.visibility;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllBalances() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const { data } = await api.walletBalanceApi.fetchAllBalances(body);
            this.items = data.data.list;
            this.pagination = data.data.pagination;

            if (this.pagination.current > this.pagination.totalItem) {
                this.pagination.current = 1;
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.walletBalanceApi
                .fetchOneBalance({ ...item, merchant_id: parseInt(MERCHANT_ID) })
                .then((response) => {
                    this.isOpenModal = true;
                    const image = response.data.data.image;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });

                    this.uploadImage = {
                        id: image.id,
                        name: image.name,
                        size: image.size,
                        type: 'image/jpg',
                        url: image.path,
                        src: image.path
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async updateItem(body) {
            await api.walletBalanceApi
                .updateBalance({ ...body, merchant_id: parseInt(MERCHANT_ID) })
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllBalances();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.upload_id = this.dataForModal.image[0].id;
                    delete this.dataForModal.image;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        // if (this.updatingItem) {
                        body.id = this.updatingItem.id;
                        await this.updateItem(body, config);
                        this.$toast.success(this.$t('success.updated.title'));
                        // } else {
                        //     await this.createItem(body, config);
                        //     this.$toast.success(this.$t('success.added'));
                        // }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        updateBalanceVisibility() {
            const body = {
                visibility: this.balanceVisibility
            };
            try {
                api.walletBalanceApi.updateBalanceVisibility(body);
                this.$toast.success(this.$t('success.updated.title'));
            } finally {
                setTimeout(() => {
                    this.getBalanceVisibility();
                }, 1000);
            }
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getAllBalances();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.image.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('roadmap', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.image[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>{{ $t('titles.wallet_balance') }}</template>
            <template #button>
                <b-form-checkbox
                    @change="updateBalanceVisibility"
                    v-model="balanceVisibility"
                    switch
                    class="custom-control-success"
                >
                    {{ $t('titles.balance_visibility') }}
                </b-form-checkbox>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLang(item.name) }}
                </template>

                <template #cell(description)="{ item }">
                    <span v-html="checkLang(item.description)" class="three_lines_text" />
                </template>

                <template #cell(can_set_default)="{ item }">
                    <feather-icon
                        :class="item.can_set_default ? 'text-success' : 'text-danger'"
                        :icon="item.can_set_default ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(image)="{ item }">
                    <div v-if="item.image">
                        <img
                            class="table-img"
                            v-if="item.image.path"
                            :src="item.image.path"
                            alt=""
                        />
                        <span v-else>No Photo</span>
                    </div>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :show-edit-button="true"
                            :index="index"
                            :item="item"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.wallet_balance') : $t('add.wallet_balance')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [RU]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [EN]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.uz"
                                    :placeholder="`${$t('enter.description')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION [RU]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.ru"
                                    :placeholder="`${$t('enter.description')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION [EN]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.en"
                                    :placeholder="`${$t('enter.description')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.position') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.position')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    :placeholder="$t('enter.position')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IMAGE ID    -->
                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="uploadImage"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_image')"
                                    :maxFiles="14"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    class="float-right mt-1"
                                    @click="getImageId()"
                                >
                                    <span v-if="!imageUploaded">
                                        {{ $t('vue_file_agent.upload_image') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('vue_file_agent.image_uploaded') }}
                                    </span>
                                </b-button>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>

                    <!--    CAN SET DEFAULT    -->
                    <b-col cols="12" class="d-flex mt-2 user-select-none">
                        <b-form-checkbox
                            id="can_set_default"
                            v-model="dataForModal.can_set_default"
                        />
                        <label for="can_set_default" class="font-medium-1">
                            {{ $t('titles.default') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.line__break {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.three_lines_text {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
