import { render, staticRenderFns } from "./AwardsSelect.vue?vue&type=template&id=47b60059&scoped=true&"
import script from "./AwardsSelect.vue?vue&type=script&lang=js&"
export * from "./AwardsSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b60059",
  null
  
)

export default component.exports