const operatorsLogo = [
    {
        name: 'uzmobile',
        logo: require('@/assets/images/operators/uzmobile.svg')
    },
    {
        name: 'beeline',
        logo: require('@/assets/images/operators/beeline.svg')
    },
    {
        name: 'humans',
        logo: require('@/assets/images/operators/humans.svg')
    },
    {
        name: 'mobiuz',
        logo: require('@/assets/images/operators/mobiuz.png')
    },
    {
        name: 'ucell',
        logo: require('@/assets/images/operators/ucell.png')
    },
    {
        name: 'perfectum',
        logo: require('@/assets/images/operators/perfectum.svg')
    }
];

export default function getMobileOperatorLogo(name) {
    const { logo } = operatorsLogo.find((item) => {
        return item.name === name;
    });
    return logo;
}
