import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';

import uz from '@/libs/veeValidate/warns/uz.json';
import ru from '@/libs/veeValidate/warns/ru.json';
import en from '@/libs/veeValidate/warns/en.json';

import * as rules from 'vee-validate/dist/rules';

localize({
    uz,
    ru,
    en
});

const localeLang = localStorage.locale ? localStorage.locale : 'ru';

localize(localeLang);

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
