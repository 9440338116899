import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Segmentation extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/segmentation/list', body);
    }

    fetchDetailEvent(body) {
        return this.post('v2/segmentation/detailEvent', body);
    }

    fetchDetailRatePlan(body) {
        return this.post('v2/segmentation/detailRatePlan', body);
    }

    fetchDetailZone(body) {
        return this.post('v2/segmentation/detailZone', body);
    }

    fetchDetailSalePoint(body) {
        return this.post('v2/segmentation/detailSalePoint', body);
    }

    fetchUserInfo(body) {
        return this.post('v2/segmentation/userInfo', body);
    }

    fetchStatistics(body) {
        return this.post('v2/segmentation/statistics', body);
    }

    fetchCatalog(body) {
        return this.post('v2/segmentation/catalog', body);
    }
}

export default Segmentation;
