export default [
    {
        path: '/policies',
        name: 'Policies',
        component: () => import('@/views/informations/Policies.vue'),
        meta: {
            section: 'policies',
            permission: 'view'
        }
    },
    {
        path: '/new-policies',
        name: 'new_policies',
        component: () => import('@/views/informations/PoliciesV2.vue'),
        meta: {
            section: 'policies',
            permission: 'view'
        }
    },
    {
        path: '/prizes',
        name: 'Prizes',
        component: () => import('@/views/informations/Prizes.vue'),
        meta: {
            section: 'prizes',
            permission: 'view'
        }
    },
    {
        path: '/guides',
        name: 'Guides',
        component: () => import('@/views/informations/Guides.vue'),
        meta: {
            section: 'guides',
            permission: 'view'
        }
    },
    {
        path: '/socials',
        name: 'Socials',
        component: () => import('@/views/informations/Socials.vue'),
        meta: {
            section: 'socials',
            permission: 'view'
        }
    }
];
