<script>
import { BButton } from 'bootstrap-vue';

export default {
    name: 'PageTitle',
    components: {
        BButton
    },
    props: {
        to: {
            type: [String, Object],
            default: null
        }
    }
};
</script>

<template>
    <div class="d-flex flex-nowrap align-items-center justify-content-between mb-2">
        <h1 class="mb-0">
            <slot name="title" />
        </h1>
        <div class="page-button">
            <slot name="button" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.page-button {
    width: fit-content;
    display: flex;
    justify-content: flex-end;

    button {
        width: 100%;
        white-space: nowrap;
    }
}
</style>
