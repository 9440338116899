import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Policy extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllMarketPolicies(params) {
        return this.post('policy/findAll', params);
    }

    fetchOneMarketPolicy(params) {
        return this.post('policy/findOne', params);
    }

    createMarketPolicy(body) {
        return this.post('policy/create', body);
    }

    updateMarketPolicy(body) {
        return this.post('policy/update', body);
    }

    deleteMarketPolicy(body) {
        return this.post('policy/remove', body);
    }
}

export default Policy;
