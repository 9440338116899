<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import vSelect from 'vue-select';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppBundle',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                charge_id: null,
                steps: [],
                items: [],
                is_active: false
            },
            staticTypesList: [
                {
                    name: {
                        uz: 'Paket',
                        ru: 'Пакет',
                        en: 'Package'
                    },
                    value: 0
                },
                {
                    name: {
                        uz: 'Fit-Coin',
                        ru: 'Fit-Coin',
                        en: 'Fit-Coin'
                    },
                    value: 1
                },
                {
                    name: {
                        uz: 'Chipta',
                        ru: 'Билет',
                        en: 'Ticket'
                    },
                    value: 2
                },
                {
                    name: {
                        uz: 'Level',
                        ru: 'Левел',
                        en: 'Level'
                    },
                    value: 4
                }
            ],
            packagesList: [],
            levelActionsList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            requestPending: false,
            chargeList: []
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'charge',
                    label: this.$t('titles.charges')
                },
                {
                    key: 'steps',
                    label: this.$t('titles.step')
                },
                {
                    key: 'items',
                    label: this.$t('titles.package')
                },
                {
                    key: 'is_active',
                    label: this.$t('titles.is_active')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        staticStepList() {
            return [
                {
                    label: this.$t('ussd_statistics.daily_1'),
                    value: 1
                },
                {
                    label: this.$t('ussd_statistics.daily_2'),
                    value: 2
                },
                {
                    label: this.$t('ussd_statistics.daily_3'),
                    value: 3
                },
                {
                    label: this.$t('ussd_statistics.premium_1'),
                    value: 4
                },
                {
                    label: this.$t('ussd_statistics.premium_2'),
                    value: 5
                },
                {
                    label: this.$t('ussd_statistics.premium_3'),
                    value: 6
                }
            ];
        },

        // checkedLabels() {
        //     if (this.dataForModal.types === 0) {
        //         return this.$t('titles.packages');
        //     } else if (this.dataForChildModal.types === 1) {
        //         return this.$t('titles.coin_amount');
        //     } else if (this.dataForChildModal.types === 2) {
        //         return this.$t('titles.ticket_qty');
        //     } else if (this.dataForChildModal.types === 4) {
        //         return this.$t('titles.level_type');
        //     }
        //     return this.$t('titles.packages');
        // },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getChargeList();
        await this.getBillingPackages();
        await this.getLevelActions();
    },

    methods: {
        checkLocales,

        async getBillingPackages() {
            const body = {
                page: 1,
                limit: 200
            };

            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.packagesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getLevelActions() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevelActions.fetchAllItems(body);
                this.levelActionsList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getChargeList() {
            const body = {
                page: 1,
                limit: 1000
            };
            try {
                const { data } = await api.billingCharges.fetchAllItems(body);
                this.chargeList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getAll() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.bundleApi.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.bundleApi
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.bundleApi
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.bundleApi
                .updateItem(id, body)
                .then(() => {
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.bundleApi
                .deleteItem(id)
                .then(() => {
                    this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    body.items = body.items.map((item) => {
                        if (item.type !== 4) {
                            return {
                                ...item,
                                value: parseInt(item.value)
                            };
                        } else {
                            return {
                                ...item
                            };
                        }
                    });

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            // this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            // this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                        this.changePassword = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        addItem() {
            this.dataForModal.items.push({
                type: null,
                value: null
            });
        },

        deleteItemFromItems(index) {
            this.dataForModal.items.splice(index, 1);
        },

        clearActivationItem(idx) {
            console.log(this.dataForModal.items[idx]);
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>Bundle</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(charge)="{ item }">
                    {{ item.charge.name }}
                </template>

                <template #cell(steps)="{ item }">
                    <b-badge
                        class="mr-1"
                        v-for="(step, index) in item.steps"
                        :key="step + index"
                        variant="primary"
                    >
                        {{ step }}
                    </b-badge>
                </template>

                <template #cell(items)="{ item }">
                    <b-badge
                        class="mr-1"
                        v-for="(item, index) in item.items"
                        :key="item + index"
                        :variant="item.color"
                    >
                        {{ item.text }}
                    </b-badge>
                </template>

                <template #cell(is_active)="{ item }">
                    <feather-icon
                        :class="[item.is_active ? 'text-success' : 'text-danger']"
                        :icon="item.is_active ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'regions', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'regions', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.bundle') : $t('add.bundle')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    CHARGE ID    -->
                    <b-col cols="6" class="mb-1">
                        <label>{{ $t('titles.charges') }}</label>
                        <v-select
                            v-model="dataForModal.charge_id"
                            :clearable="false"
                            :disabled="isViewModal"
                            :options="chargeList"
                            :reduce="(option) => option.id"
                            label="name"
                            :searchable="false"
                        >
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>

                            <template v-slot:selected-option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </b-col>

                    <!--    STEPS    -->
                    <b-col cols="6" class="mb-1">
                        <label>{{ $t('titles.step') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.step')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.steps"
                                    :clearable="false"
                                    :disabled="isViewModal"
                                    :options="staticStepList"
                                    :reduce="(option) => option.value"
                                    label="label"
                                    :searchable="false"
                                    :multiple="true"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     ITEMS     -->
                    <div class="d-flex flex-wrap w-100 px-1">
                        <div
                            v-for="(item, index) in dataForModal.items"
                            :key="index"
                            class="col-6 py-05 mt-1"
                        >
                            <div class="product-card p-1 inner__card">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        class="p-05"
                                        variant="danger"
                                        @click="deleteItemFromItems(index)"
                                        >X
                                    </b-button>
                                </div>
                                <div>
                                    <!--    TYPES    -->
                                    <b-col cols="12">
                                        <label>{{ $t('titles.types') }}</label>
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="$t('titles.types')"
                                            rules="required"
                                        >
                                            <v-select
                                                id="categories"
                                                v-model="item.type"
                                                :options="staticTypesList"
                                                :reduce="(option) => option.value"
                                                :placeholder="$t('choose.type')"
                                                @input="clearActivationItem(index)"
                                                label="name"
                                                :clearable="false"
                                            >
                                                <template v-slot:option="option">
                                                    {{ checkLocales(option.name) }}
                                                </template>

                                                <template v-slot:selected-option="option">
                                                    {{ checkLocales(option.name) }}
                                                </template>
                                            </v-select>
                                            <span class="validation__red">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </b-col>

                                    <!--    ACTIVATION ITEM ID    -->
                                    <b-col cols="12" v-if="item.type !== 5">
                                        <label>{{ $t('titles.value') }}</label>
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="$t('titles.value')"
                                            rules="required"
                                        >
                                            <b-form-group>
                                                <v-select
                                                    v-if="item.type === 4"
                                                    id="categories"
                                                    v-model="item.value"
                                                    :options="levelActionsList"
                                                    :reduce="(option) => option.type"
                                                    :placeholder="$t('enter.value')"
                                                    label="name"
                                                    :clearable="false"
                                                />
                                                <b-form-input
                                                    v-else-if="item.type === 1 || item.type === 2"
                                                    v-model="item.value"
                                                    :placeholder="$t('enter.value')"
                                                    :state="errors.length > 0 ? false : null"
                                                    size="md"
                                                    type="number"
                                                />
                                                <v-select
                                                    v-else
                                                    id="categories"
                                                    v-model="item.value"
                                                    :options="packagesList"
                                                    :reduce="(option) => option.id"
                                                    :placeholder="$t('enter.value')"
                                                    label="name"
                                                    :clearable="false"
                                                >
                                                    <template v-slot:option="option">
                                                        {{ option.type_name + ' ' + option.qty }}
                                                    </template>

                                                    <template v-slot:selected-option="option">
                                                        {{ option.type_name + ' ' + option.qty }}
                                                    </template>
                                                </v-select>
                                            </b-form-group>
                                            <span class="validation__red">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </b-col>
                                </div>
                            </div>
                        </div>
                    </div>

                    <b-col cols="12">
                        <div class="d-flex justify-content-center mt-2 plus__button">
                            <b-button variant="primary" @click="addItem" class="font-medium-3">
                                +
                            </b-button>
                        </div>
                    </b-col>

                    <!--    IS ACTIVE    -->
                    <b-col class="d-flex justify-content-сутеук mt-2 user-select-none" cols="12">
                        <b-form-checkbox id="is_active" v-model="dataForModal.is_active" />
                        <label class="font-medium-1" for="is_active">
                            {{ $t('titles.is_active') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.inner__card {
    border: 1px dashed #fff;
    border-radius: 0.5rem;
}
</style>
