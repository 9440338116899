// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class FlashStatistics extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'flash/dashboardGf/' : 'flash/dashboardAl/';
    }

    fetchGeneralStatistics(body) {
        return this.post(this.dashboardVersion() + 'statistic/general', body);
    }

    fetchProductStatistics(body) {
        return this.post(this.dashboardVersion() + 'statistic/products', body);
    }

    fetchBestSellerStatistics(body) {
        return this.post(this.dashboardVersion() + 'statistic/bestSeller', body);
    }

    fetchBestUserStatistics(body) {
        return this.post(this.dashboardVersion() + 'statistic/bestUser', body);
    }
}

export default FlashStatistics;
