<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';

export default {
    name: 'ViewUzmobileSubscriberInfo',
    components: { ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUzmobile.fetchSubscriberInfo({
                    phone: parseInt(this.phone)
                });
                this.response = data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },

        haveChildren(value) {
            return typeof value === 'object';
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>

                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <b-button @click="getResponse" variant="success">{{
                        $t('button.filter')
                    }}</b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <div v-else class="json__card card p-1">
                    <div v-if="response">
                        <div v-for="(value, key, index) in response" :key="index + key + value">
                            <div v-if="!haveChildren(value)">
                                <span class="text-uppercase text-warning font-medium-2 mb-1">
                                    {{ key }}
                                </span>
                                <span class="text-uppercase font-medium-2"> : </span>
                                <span class="text-uppercase text-success font-medium-2">
                                    {{ value }}
                                </span>
                            </div>
                            <div v-else-if="value === null">
                                <span class="text-uppercase text-warning font-medium-2 mb-1">
                                    {{ key }}
                                </span>
                                <span class="text-uppercase font-medium-2"> : </span>
                                <span class="text-uppercase text-success font-medium-2">
                                    null
                                </span>
                            </div>
                            <div v-else-if="value.length === 0">
                                <span class="text-uppercase text-warning font-medium-2 mb-1">
                                    {{ key }}
                                </span>
                                <span class="text-uppercase font-medium-2"> : </span>
                                <span class="text-uppercase text-success font-medium-2"> [] </span>
                            </div>
                            <div v-else>
                                <span class="text-info text-uppercase font-medium-2 d-block">
                                    {{ key }} =>
                                </span>
                                <div
                                    v-for="(childValue, childKey, childIndex) in value"
                                    :key="childValue + childKey + childIndex"
                                    class="ml__2"
                                >
                                    <div v-if="!haveChildren(childValue)">
                                        <span
                                            class="text-uppercase text-warning font-medium-2 mb-1"
                                        >
                                            {{ childKey }}
                                        </span>
                                        <span class="text-uppercase font-medium-2"> : </span>
                                        <span class="text-uppercase text-success font-medium-2">
                                            {{ childValue }}
                                        </span>
                                    </div>
                                    <div v-else-if="childValue === null">
                                        <span
                                            class="text-uppercase text-warning font-medium-2 mb-1"
                                        >
                                            {{ childKey }}
                                        </span>
                                        <span class="text-uppercase font-medium-2"> : </span>
                                        <span class="text-uppercase text-success font-medium-2">
                                            null
                                        </span>
                                    </div>
                                    <div v-else-if="childValue.length === 0">
                                        <span
                                            class="text-uppercase text-warning font-medium-2 mb-1"
                                        >
                                            {{ childKey }}
                                        </span>
                                        <span class="text-uppercase font-medium-2"> : </span>
                                        <span class="text-uppercase text-success font-medium-2">
                                            []
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span class="text-info text-uppercase font-medium-2 d-block"
                                            >{{ childKey }} =></span
                                        >
                                        <div
                                            v-for="(
                                                child1Value, child1Key, child1Index
                                            ) in childValue"
                                            :key="child1Value + child1Key + child1Index"
                                            class="ml__2"
                                        >
                                            <div v-if="!haveChildren(child1Value)">
                                                <span
                                                    class="text-uppercase text-warning font-medium-2 mb-1"
                                                >
                                                    {{ child1Key }}
                                                </span>
                                                <span class="text-uppercase font-medium-2">
                                                    :
                                                </span>
                                                <span
                                                    class="text-uppercase text-success font-medium-2"
                                                >
                                                    {{ child1Value }}
                                                </span>
                                            </div>
                                            <div v-else-if="child1Value === null">
                                                <span
                                                    class="text-uppercase text-warning font-medium-2 mb-1"
                                                >
                                                    {{ child1Key }}
                                                </span>
                                                <span class="text-uppercase font-medium-2">
                                                    :
                                                </span>
                                                <span
                                                    class="text-uppercase text-success font-medium-2"
                                                >
                                                    null
                                                </span>
                                            </div>
                                            <div v-else-if="child1Value.length === 0">
                                                <span
                                                    class="text-uppercase text-warning font-medium-2 mb-1"
                                                >
                                                    {{ child1Key }}
                                                </span>
                                                <span class="text-uppercase font-medium-2">
                                                    :
                                                </span>
                                                <span
                                                    class="text-uppercase text-success font-medium-2"
                                                >
                                                    []
                                                </span>
                                            </div>
                                            <div v-else>
                                                <span
                                                    class="text-info text-uppercase font-medium-2 d-block"
                                                    >{{ child1Key }} =></span
                                                >
                                                <div
                                                    v-for="(
                                                        child2Value, child2Key, child2Index
                                                    ) in child1Value"
                                                    :key="child2Value + child2Key + child2Index"
                                                    class="ml__2"
                                                >
                                                    <div v-if="!haveChildren(child2Value)">
                                                        <span
                                                            class="text-uppercase text-warning font-medium-2 mb-1"
                                                        >
                                                            {{ child2Key }}
                                                        </span>
                                                        <span class="text-uppercase font-medium-2">
                                                            :
                                                        </span>
                                                        <span
                                                            class="text-uppercase text-success font-medium-2"
                                                        >
                                                            {{ child2Value }}
                                                        </span>
                                                    </div>
                                                    <div v-else-if="child2Value === null">
                                                        <span
                                                            class="text-uppercase text-warning font-medium-2 mb-1"
                                                        >
                                                            {{ child2Key }}
                                                        </span>
                                                        <span class="text-uppercase font-medium-2">
                                                            :
                                                        </span>
                                                        <span
                                                            class="text-uppercase text-success font-medium-2"
                                                        >
                                                            null
                                                        </span>
                                                    </div>
                                                    <div v-else-if="child2Value.length === 0">
                                                        <span
                                                            class="text-uppercase text-warning font-medium-2 mb-1"
                                                        >
                                                            {{ child2Key }}
                                                        </span>
                                                        <span class="text-uppercase font-medium-2">
                                                            :
                                                        </span>
                                                        <span
                                                            class="text-uppercase text-success font-medium-2"
                                                        >
                                                            []
                                                        </span>
                                                    </div>
                                                    <div v-else>
                                                        <span
                                                            class="text-info text-uppercase font-medium-2 d-block"
                                                            >{{ child2Key }} =></span
                                                        >
                                                        <div
                                                            v-for="(
                                                                child3Value, child3Key, child3Index
                                                            ) in child2Value"
                                                            :key="
                                                                child3Value +
                                                                child3Key +
                                                                child3Index
                                                            "
                                                            class="ml__2"
                                                        >
                                                            <div v-if="!haveChildren(child3Value)">
                                                                <span
                                                                    class="text-uppercase text-warning font-medium-2 mb-1"
                                                                >
                                                                    {{ child3Key }}
                                                                </span>
                                                                <span
                                                                    class="text-uppercase font-medium-2"
                                                                >
                                                                    :
                                                                </span>
                                                                <span
                                                                    class="text-uppercase text-success font-medium-2"
                                                                >
                                                                    {{ child3Value }}
                                                                </span>
                                                            </div>

                                                            <div v-else-if="child3Value === null">
                                                                <span
                                                                    class="text-uppercase text-warning font-medium-2 mb-1"
                                                                >
                                                                    {{ child3Key }}
                                                                </span>
                                                                <span
                                                                    class="text-uppercase font-medium-2"
                                                                >
                                                                    :
                                                                </span>
                                                                <span
                                                                    class="text-uppercase text-success font-medium-2"
                                                                >
                                                                    null
                                                                </span>
                                                            </div>

                                                            <div
                                                                v-else-if="child3Value.length === 0"
                                                            >
                                                                <span
                                                                    class="text-uppercase text-warning font-medium-2 mb-1"
                                                                >
                                                                    {{ child3Key }}
                                                                </span>
                                                                <span
                                                                    class="text-uppercase font-medium-2"
                                                                >
                                                                    :
                                                                </span>
                                                                <span
                                                                    class="text-uppercase text-success font-medium-2"
                                                                >
                                                                    []
                                                                </span>
                                                            </div>

                                                            <div v-else>
                                                                <span
                                                                    class="text-info text-uppercase font-medium-2 d-block"
                                                                    >{{ child3Key }} =></span
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 v-else>No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.ml__2 {
    margin-left: 3rem;
}
</style>
