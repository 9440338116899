var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.kube-servers')))]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLocales(item.title))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.open')))),expression:"`${$t('button.open')}`",modifiers:{"hover":true,"top":true}},{name:"b-modal",rawName:"v-b-modal.config-constants-modal",modifiers:{"config-constants-modal":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-success"},on:{"click":function($event){return _vm.openConfigModal(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_c('table-action-btns',{attrs:{"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.kube_server') : _vm.$t('add.kube_server'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.host')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.host'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.host'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.host),callback:function ($$v) {_vm.$set(_vm.dataForModal, "host", $$v)},expression:"dataForModal.host"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.port')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.port'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.port'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.port),callback:function ($$v) {_vm.$set(_vm.dataForModal, "port", $$v)},expression:"dataForModal.port"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.updatingItem)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.token')))]),_c('b-form-group',[_c('b-form-input',{staticClass:"mb-1",attrs:{"placeholder":_vm.$t('enter.token'),"size":"md"},model:{value:(_vm.dataForModal.token),callback:function ($$v) {_vm.$set(_vm.dataForModal, "token", $$v)},expression:"dataForModal.token"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.queue_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('CompInfinityScrollQueue',{model:{value:(_vm.dataForModal.queue_trick_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "queue_trick_id", $$v)},expression:"dataForModal.queue_trick_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"config-constants-modal","centered":"","title":_vm.$t('titles.config_constants')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.copyConfigConstant}},[_c('feather-icon',{attrs:{"icon":"CopyIcon","size":"16"}})],1)]},proxy:true}])},[_c('b-card-text',[_c('span',{ref:"configConstants",staticClass:"constant"},[_c('pre',[_vm._v(_vm._s(_vm.configConstants))])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }