var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manual__notification"},[_c('ValidationObserver',{ref:"validationForm"},[_c('b-row',[(_vm.is_sms)?_c('div',{staticClass:"col-12 col-xl-6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.template'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('titles.template')) + " [SMS]")}},[(_vm.is_sms)?_c('v-select',{attrs:{"placeholder":((_vm.$t('choose.template')) + " [SMS]"),"options":_vm.smsList,"reduce":function (option) { return option.name; },"label":"name","clearable":false},model:{value:(_vm.requestBody.sms_type),callback:function ($$v) {_vm.$set(_vm.requestBody, "sms_type", $$v)},expression:"requestBody.sms_type"}}):_vm._e()],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3757081203)})],1):_vm._e(),(_vm.is_notification)?_c('div',{staticClass:"col-12 col-xl-6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.template'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('titles.template')) + " [" + (_vm.$t('titles.notification')) + "]")}},[_c('v-select',{attrs:{"placeholder":((_vm.$t('choose.template')) + " [" + (_vm.$t(
                                'titles.notification'
                            )) + "]"),"options":_vm.templatesList,"reduce":function (option) { return option.id; },"label":"title","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLocales(option.title))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLocales(option.title))+" ")]}}],null,true),model:{value:(_vm.requestBody.template_id),callback:function ($$v) {_vm.$set(_vm.requestBody, "template_id", $$v)},expression:"requestBody.template_id"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,456577800)})],1):_vm._e(),_c('div',{staticClass:"col-12 col-xl-3"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('segmentation.event'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('segmentation.event')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('segmentation.event'),"options":_vm.eventList,"reduce":function (option) { return option; },"clearable":false},model:{value:(_vm.requestBody.event_id),callback:function ($$v) {_vm.$set(_vm.requestBody, "event_id", $$v)},expression:"requestBody.event_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-12 col-xl-3"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.key')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('titles.key'),"options":_vm.keyList,"reduce":function (option) { return option; },"clearable":false},model:{value:(_vm.requestBody.key),callback:function ($$v) {_vm.$set(_vm.requestBody, "key", $$v)},expression:"requestBody.key"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('div',{staticClass:"col-12 d-flex flex-column flex-md-row align-items-center justify-content-between px-0 mt-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start mb-2"},[_c('b-form-checkbox',{staticClass:"mr-2",model:{value:(_vm.is_sms),callback:function ($$v) {_vm.is_sms=$$v},expression:"is_sms"}},[_vm._v(" SMS ")]),_c('b-form-checkbox',{staticClass:"mr-3",model:{value:(_vm.is_notification),callback:function ($$v) {_vm.is_notification=$$v},expression:"is_notification"}},[_vm._v(" "+_vm._s(_vm.$t('titles.notification'))+" ")]),_c('b-form-checkbox',{model:{value:(_vm.requestBody.ussd_only),callback:function ($$v) {_vm.$set(_vm.requestBody, "ussd_only", $$v)},expression:"requestBody.ussd_only"}},[_vm._v(" USSD ")])],1),_c('b-button',{staticClass:"float-right mb-2 mb-md-0",attrs:{"variant":"success","disabled":!_vm.is_sms && !_vm.is_notification},on:{"click":_vm.sendManualNotificationSegmentation}},[_vm._v(" "+_vm._s(_vm.$t('button.send'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }