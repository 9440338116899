var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.ussd_texts'))+" [USSD]")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('label',{staticClass:"mr-1",attrs:{"for":"canAct"}},[_vm._v(_vm._s(_vm.$t('titles.action_on_off')))]),_c('b-form-checkbox',{staticClass:"custom-control-success reversed-switch",attrs:{"id":"canAct","name":"check-button","switch":""},on:{"change":_vm.showWarningModal},model:{value:(_vm.canAct),callback:function ($$v) {_vm.canAct=$$v},expression:"canAct"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"disabled":!_vm.canAct,"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.checkLocales(item.name)))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"disable-delete-button":!_vm.canAct,"disable-update-button":!_vm.canAct,"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.ussd_texts') : _vm.$t('add.ussd_texts'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('b-alert',{staticClass:"mb-2",attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading text-center"},[_vm._v(_vm._s(_vm.$t('warning'))+"!")]),_c('div',{staticClass:"alert-body text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('titles.ussd_change_warning_msg')))])])]),_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.key')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('titles.key'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.key),callback:function ($$v) {_vm.$set(_vm.dataForModal, "key", $$v)},expression:"dataForModal.key"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" [UZ]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"md","rows":"10"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" [RU]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"rows":"10","size":"md"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-modal',{ref:"warningModal",attrs:{"id":"warningModal","hide-footer":false,"no-close-on-backdrop":true,"title":_vm.$t('warning'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hide":_vm.hideWarningModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.onCancelWarningModal}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.onOkWarningModal}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])]},proxy:true}])},[_c('b-alert',{staticClass:"mb-2",attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading text-center"},[_vm._v(_vm._s(_vm.$t('warning'))+"!")]),_c('div',{staticClass:"alert-body text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('titles.ussd_change_warning_msg')))])])])],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-2",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }