// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV4 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class Servers extends CoreV2 {
    constructor() {
        super(axiosV4());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'dashboardGf/' : 'dashboard/';
    }

    findAll() {
        return this.get(this.dashboardVersion() + 'kubeServer/getAll');
    }

    findOne(id) {
        return this.get(this.dashboardVersion() + `kubeServer/get/${id}`);
    }

    create(body) {
        return this.post(this.dashboardVersion() + 'kubeServer/create', body);
    }

    update(id) {
        return this.post(this.dashboardVersion() + `kubeServer/update/${id}`);
    }

    deleteKubeServer(id) {
        return this.delete(this.dashboardVersion() + `kubeServer/delete/${id}`, id);
    }

    // NEW BONUS STATISTICS
    fetchBonusStatistics(body) {
        return this.post(this.dashboardVersion() + 'history/statistic', body);
    }
}

export default Servers;
