<script>
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BButton, BModal, BPagination, BTable } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppDistributionNew',
    components: { BButton, BModal, BTable, BPagination, ProgressLinear, PageTitle, flatPickr },
    data() {
        return {
            items: [],
            requestPending: false,
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 21
            },
            date_start: null,
            date_end: null,
            isDownloadModalOpened: false,
            download_date_start: null,
            download_date_end: null,
            total: {
                sum: null,
                quantity: null,
                table: []
            }
        };
    },
    watch: {
        '$route.query': {
            async handler(query) {
                this.pagination.current = query.page || 1;
                this.requestPending = true;
                await this.fixStatisticsDates();
                this.requestPending = false;
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'action',
                    label: this.$t('titles.action')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        totalFields() {
            return [
                {
                    key: 'product',
                    label: this.$t('titles.action')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        async getTotalDistributionStatisticsNew() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID),
                date_start: this.date_end,
                date_end: this.date_end,
                user_type: 'new'
            };
            try {
                const { data } = await api.otherStatistics.fetchDistributionStatisticsTotal(body);
                this.total.sum = data.data.total.sum;
                this.total.quantity = data.data.total.count;
                this.total.table = data.data.products;
            } catch (e) {
                console.error(e);
            }
        },

        async getDistributionStatisticsNew() {
            this.requestPending = true;
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                date_start: this.date_start,
                date_end: this.date_end,
                merchant_id: parseInt(MERCHANT_ID),
                user_type: 'new'
            };
            try {
                const { data } = await api.otherStatistics.fetchDistributionStatistics(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
                this.requestPending = false;
            } catch (e) {
                console.error(e);
            }
        },

        async doubleRequest() {
            await this.getDistributionStatisticsNew();
            await this.getTotalDistributionStatisticsNew();
        },

        async downloadDealerStatisticsNew() {
            try {
                const response = await api.otherStatistics.downloadDistributionStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.download_date_start,
                    date_end: this.download_date_end,
                    user_type: 'new'
                });

                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const link = document.createElement('a');

                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'filename.xlsx');

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.isDownloadModalOpened = false;
            } catch (e) {
                console.error(e);
            }
        },

        async fixStatisticsDates() {
            const date_start = new Date();
            const date_end = new Date();
            // date_start.setDate(date_end.getDate() - 30);

            this.date_start = this.formatDate(date_start);
            this.date_end = this.formatDate(date_end);

            await this.getTotalDistributionStatisticsNew();
            await this.getDistributionStatisticsNew();
        },

        formatNumber(num) {
            if (num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div class="all">
        <page-title>
            <template #title>{{ $t('titles.statistics_set') }} [New]</template>
            <template #button>
                <b-button
                    class="btn-icon mr-1"
                    variant="primary"
                    @click="getDistributionStatisticsNew"
                >
                    <feather-icon icon="RefreshCwIcon" />
                </b-button>
                <b-button class="btn-icon" variant="primary" v-b-modal="'downloadExcelModal'">
                    <span class="mr-1">{{ $t('button.download') }}</span>
                    <feather-icon icon="DownloadIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button @click="doubleRequest" class="w-100 mt-1 mt-md-0" variant="primary">
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>

        <div class="card p-1 d-flex flex-column flex-md-row justify-content-around">
            <div class="d-flex mb-2 mb-md-0 justify-content-center">
                <h3 class="mr-1">{{ $t('titles.total_count') }}:</h3>
                <b-badge variant="primary">{{ formatNumber(total.quantity) }}</b-badge>
            </div>
            <div class="d-flex justify-content-center">
                <h3 class="mr-1">{{ $t('titles.total_sum') }}:</h3>
                <b-badge variant="primary">{{ formatNumber(total.sum) }}</b-badge>
            </div>
        </div>

        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="totalFields"
                :items="total.table"
                :responsive="true"
                borderless
                outlined
                striped
            >
                <template #cell(count)="{ item }">
                    {{ formatNumber(item.count) }}
                </template>
                <template #cell(sum)="{ item }">
                    {{ formatNumber(item.sum) }}
                </template>
            </b-table>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="requestPending" />
                    <b-table
                        v-else
                        :fields="fields"
                        :items="items"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                    </b-table>
                </div>
                <div class="d-flex justify-content-end my-2">
                    <b-pagination
                        v-if="showPagination"
                        v-model="pagination.current"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalItem"
                        class="float-right mr-4 mb-0"
                        first-number
                        last-number
                    />
                </div>
            </div>
        </div>

        <!--    DOWNLOAD MODAL    -->
        <b-modal
            v-model="isDownloadModalOpened"
            id="downloadExcelModal"
            ref="downloadExcelModal"
            :title="$t('button.download')"
        >
            <!--          DATE START          -->
            <label for="dateStart">{{ $t('titles.start_date') }}</label>
            <flat-pickr
                v-model="download_date_start"
                :config="{
                    allowInput: true
                }"
                autocomplete="off"
                class="form-control mb-1"
                name="date"
                :placeholder="$t('choose.date')"
                id="dateStart"
            />

            <!--          DATE END          -->
            <label for="dateStart">{{ $t('titles.end_date') }}</label>
            <flat-pickr
                v-model="download_date_end"
                :config="{
                    allowInput: true
                }"
                autocomplete="off"
                class="form-control"
                name="date"
                :placeholder="$t('choose.date')"
                id="dateEnd"
            />

            <template #modal-footer>
                <b-button @click="isDownloadModalOpened = false" variant="danger">
                    {{ $t('button.cancel') }}
                </b-button>
                <b-button @click="downloadDealerStatisticsNew" variant="success">
                    {{ $t('button.confirm') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.badge {
    font-size: 20px;
}
</style>
