import { render, staticRenderFns } from "./ViewUzmobilePackageAdd.vue?vue&type=template&id=5439f918&scoped=true&"
import script from "./ViewUzmobilePackageAdd.vue?vue&type=script&lang=js&"
export * from "./ViewUzmobilePackageAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5439f918",
  null
  
)

export default component.exports