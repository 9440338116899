import Vue from 'vue';
import { isNUNEZ } from '@/util/inspect.util';
import { chatApi } from '@/services/chat/chat.service';
import { dateProperties } from '@/util/date/calendar.util';
import { chatListSchema } from '@/store/dashboard/chat/_chat.store';

export default {
    async initChatWatcher(
        { state, getters, commit, dispatch },
        { perPage = 20, current = 1 } = { perPage: 20, current: 1 }
    ) {
        const DELAY = 3000;
        const TIME_STAMP_DIF = 5 * 60 * 60 * 1000;
        try {
            const {
                data: { updated_at: updatedAt }
            } = await chatApi.getLatestFetchTime({
                page: 1,
                limit: 20,
                merchant_id: getters.getMerchantId
            });

            const { time: updatedTime } = dateProperties(updatedAt, 'string');

            if (!state.chats.lastUpdatedTime) {
                state.chats.lastUpdatedTime = updatedTime;
            }

            if (
                !(state.chats.pending || state.chats.isFetching) &&
                state.chats.lastUpdatedTime &&
                state.chats.lastUpdatedTime < updatedTime
            ) {
                commit('chatRefreshStartFetching');
                const lastReadTime = new Date(state.chats.lastUpdatedTime);
                lastReadTime.setTime(lastReadTime.getTime() - TIME_STAMP_DIF);
                const { ymdHms } = dateProperties(lastReadTime);
                const response = await chatApi.getChats({
                    params: {
                        page: current,
                        limit: perPage,
                        merchant_id: getters.getMerchantId,
                        last_read_time: ymdHms
                    }
                });

                for (let i = 0; i < response.data.result.length; i++) {
                    const cItem = response.data.result[i];
                    const idx = state.chats.result.findIndex((ch) => ch.id === cItem.id);
                    const isNotEqual =
                        idx !== -1 &&
                        state.chats.result[idx]['unread_count'] !== cItem['unread_count'];

                    if (cItem['unread_count']) {
                        if (idx !== -1) {
                            if (isNotEqual) {
                                state.chats.result[idx].message = cItem.message;
                                state.chats.result[idx]['unread_count'] = cItem['unread_count'];
                            }
                        } else {
                            state.chats.result.unshift(cItem);
                        }
                    }

                    if (idx !== -1 && isNotEqual) {
                        state.chats.result[idx].message = cItem.message;
                        state.chats.result[idx]['unread_count'] = cItem['unread_count'];
                    }
                }
                state.chats.lastUpdatedTime = updatedTime;
            }
        } catch (e) {
            return Promise.reject(e);
        } finally {
            commit('chatRefreshFinishFetching');
            if (state.chats.pms.update) {
                clearTimeout(state.chats.chatRefreshTimer);
                state.chats.chatRefreshTimer = setTimeout(() => {
                    dispatch('initChatWatcher');
                }, DELAY);
            }
        }
    },
    clearChatRefreshInterval({ state }) {
        clearTimeout(state.chats.chatRefreshTimer);
    },
    async initMessagesWatcher({ state, getters: gts, commit, dispatch }) {
        const MESSAGES_UPDATE_DELAY = 3000;
        try {
            commit('startMessagesUpdating');
            if (gts.getActiveChatId) {
                const {
                    data: { unreads }
                } = await chatApi.checkUnreadMessages({
                    params: {
                        merchant_id: gts.getMerchantId,
                        chat_id: gts.getActiveChatId
                    }
                });

                if (unreads) {
                    const {
                        data: { result: messagesList }
                    } = await chatApi.getMessages({
                        chatId: gts.getActiveChatId,
                        params: {
                            page: 1,
                            limit: unreads,
                            merchant_id: gts.getMerchantId
                        }
                    });

                    for (let i = 0; i < messagesList.length; i++) {
                        const cItem = messagesList[i];
                        const hasBefore = state.chatMessages.result.find((mItem) => {
                            const { time: mCreateTime } = dateProperties(
                                mItem['created_at'],
                                'string'
                            );
                            const { time: cCreateTime } = dateProperties(
                                cItem['created_at'],
                                'string'
                            );
                            return cItem.id === mItem.id && mCreateTime === cCreateTime;
                        });

                        if (!hasBefore) {
                            state.chatMessages.result.push(cItem);
                            Vue.nextTick(() => {
                                commit('scrollChatViewToBottom');
                            });
                        }
                    }
                }
            }
        } finally {
            commit('finishMessagesUpdating');
            if (state.chatMessages.pms.update) {
                clearTimeout(state.chatMessages.refreshTimer);
                state.chatMessages.refreshTimer = setTimeout(() => {
                    dispatch('initMessagesWatcher');
                }, MESSAGES_UPDATE_DELAY);
            }
        }
    },
    async chatReceiver(
        { commit, getters },
        { perPage = 20, current = 1, infinite } = { perPage: 20, current: 1, infinite: false }
    ) {
        commit('chatMsgsStartFetching');
        try {
            const response = await chatApi.getChats({
                params: {
                    page: current,
                    limit: perPage,
                    merchant_id: getters.getMerchantId
                }
            });

            if (infinite) {
                if (response.data.pagination.current) {
                    commit('appendChats', {
                        result: response.data.result,
                        pagination: response.data.pagination
                    });
                }
            } else {
                commit('chatTrigger', {
                    result: response.data.result,
                    pagination: response.data.pagination
                });
            }
        } catch (e) {
            return Promise.reject(e);
        } finally {
            commit('chatMsgsFinishFetching');
        }
    },

    async contactReceiver(
        { state, commit, getters },
        { perPage = 20, current = 1, infinite = false } = {
            perPage: 20,
            current: 1,
            infinite: false
        }
    ) {
        commit('contactsStartFetching');
        try {
            const params = {
                page: current,
                limit: perPage ?? perPage,
                merchant_id: getters.getMerchantId
            };

            if (state.search.query.length > 3) {
                params.search = state.search.query.replace(/\s+/g, '');
            }

            const response = await chatApi.getContacts({ params });
            state.contacts.complete = !response.data.result.length;

            if (infinite) {
                if (response.data.pagination.current) {
                    commit('appendContacts', {
                        result: response.data.result,
                        pagination: response.data.pagination
                    });
                }
            } else {
                commit('contactTrigger', {
                    result: response.data.result,
                    pagination: response.data.pagination
                });
            }
        } catch (e) {
            return Promise.reject(e);
        } finally {
            commit('contactsFinishFetching');
        }
    },
    async chatMessagesReceiver(
        { state, commit, dispatch, getters },
        { chatId, perPage = 20, current = 1, showLoading = true } = {
            perPage: 20,
            current: 1,
            showLoading: true
        }
    ) {
        if (showLoading) {
            commit('messagesStartFetching');
        }

        try {
            const response = await chatApi.getMessages({
                chatId,
                params: {
                    page: current,
                    limit: perPage,
                    merchant_id: getters.getMerchantId
                }
            });

            if (response.data.pagination.current || !state.chatMessages.result.length) {
                await dispatch('appendChatList', {
                    chatId,
                    result: response.data.result,
                    pagination: response.data.pagination
                });
            }
        } catch (e) {
            return Promise.reject(e);
        } finally {
            if (showLoading) {
                commit('messagesFinishFetching');
            }
        }
    },
    appendChatList({ state, getters: gts, commit, dispatch }, { chatId, result, pagination }) {
        state.chatMessages[chatId] = {
            result: [],
            complete: false
        };

        if (!gts.hasActiveClientCtx && result.length > 0) {
            dispatch('setActiveClient', {
                clientCtx: result[0]
            });
        }

        state.chatMessages[chatId].pagination = pagination;
        for (let i = 0; i < result.length; i++) {
            state.chatMessages[chatId].result.unshift(result[i]);
        }
        if (state.chatMessages.activeChatId.toString() === chatId.toString()) {
            commit('setChatResult', state.chatMessages[chatId].result.slice());
        }
    },
    async addMessagesByScroll({ state, getters: gts }, { chatId }) {
        try {
            const { current, perPage } = gts.getPaginationByChatId(chatId);
            const response = await chatApi.getMessages({
                chatId,
                params: {
                    page: current + 1,
                    limit: perPage,
                    merchant_id: gts.getMerchantId
                }
            });

            if (response.data.result.length) {
                state.chatMessages[chatId].pagination = response.data.pagination;

                for (let i = 0; i < response.data.result.length; i++) {
                    state.chatMessages[chatId].result.unshift(response.data.result[i]);
                }

                if (state.chatMessages.activeChatId.toString() === chatId.toString()) {
                    for (let i = 0; i < response.data.result.length; i++) {
                        state.chatMessages.result.unshift(response.data.result[i]);
                    }
                }
            } else {
                state.chatMessages[chatId].complete = true;
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    cancelRequest({ dispatch }) {
        dispatch('clearEditContext');
        dispatch('clearReplyContext');
    },
    async sendMessage({ getters: gts, dispatch }, { chatId, message = null, uploadId = null }) {
        let body = {
            merchant_id: gts.getMerchantId
        };

        // if (state.chatMessages.result.length < 1) {
        //     const {
        //         data: { result }
        //     } = await chatApi.getChatIdByClientId({
        //         body: {
        //             merchant_id: gts.getMerchantId,
        //             client_id: gts.getActiveClientCtx?.user?.id ?? gts.getActiveClientCtx.id
        //         }
        //     });
        //
        //     if (result) {
        //         commit('chatTrigger', {
        //             result: [],
        //             pagination: state.paginationSchema
        //         });
        //         await dispatch('chatReceiver');
        //     }
        //
        //     chatId = result.id;
        //     const paramsId = router.currentRoute.params.id;
        //     if (paramsId && paramsId.toString() !== result.id.toString()) {
        //         await router.push({
        //             params: { id: result.id }
        //         });
        //     }
        // }

        if (message) {
            body.message = message;
        } else if (uploadId) {
            body.upload_id = uploadId;
        }

        if (gts.showReplyContext && gts.getReplyContext?.id) {
            body.parent_id = gts.getReplyContext.id;
        }

        try {
            const response = await chatApi.sendMessage({ chatId, body });
            dispatch('appendChatMessage', {
                chatId,
                pagination: response.data.pagination,
                messageContext: response.data.result
            });
            dispatch('clearReplyContext');
        } catch (e) {
            return Promise.reject(e);
        }
    },
    appendChatMessage({ state, getters: gts }, { chatId, messageContext, pagination = {} }) {
        if (!gts.hasMessages(chatId)) {
            state.chatMessages[chatId] = {
                result: [],
                pagination
            };
        }

        state.chatMessages[chatId].result.push(messageContext);
        state.chatMessages.result.push(messageContext);
    },
    async updateMessage(
        { state, getters: gts, dispatch },
        { chatId, message = null, uploadId = null }
    ) {
        let body = {
            merchant_id: gts.getMerchantId
        };

        if (message) {
            body.message = message;
        }

        if (uploadId) {
            body.upload_id = uploadId;
        }

        try {
            const response = await chatApi.updateMessage({
                messageId: gts.getEditContext.id,
                chatId,
                body
            });

            const uIdx = state.chatMessages.result.findIndex((m) => m.id === gts.getEditContext.id);
            const uStackIdx = state.chatMessages[chatId].result.findIndex(
                (m) => m.id === gts.getEditContext.id
            );

            if (uStackIdx !== -1) {
                state.chatMessages[chatId].result.splice(uIdx, 1, response.data.result);
            }

            if (uIdx !== -1) {
                state.chatMessages.result.splice(uIdx, 1, response.data.result);
            }

            dispatch('clearEditContext');
        } catch (e) {
            return Promise.reject(e);
        }
    },
    refreshChatResult({ state, getters }, { chatId }) {
        state.chatMessages.result = getters.getMessagesById(chatId).result.slice();
    },
    removeMessage({ state, getters: gts }, { chatId, messageId }) {
        if (gts.getActiveChatId === chatId) {
            state.chatMessages.result = state.chatMessages.result.filter(
                (msgs) => msgs.id !== messageId
            );
        }

        state.chatMessages[chatId].result = state.chatMessages[chatId].result.filter(
            (chmsg) => chmsg.id !== messageId
        );
    },
    setReplyContext({ state, dispatch }, { replyData }) {
        dispatch('clearEditContext');
        state.replyContext = replyData;
    },
    clearReplyContext({ state }) {
        state.replyContext = {};
    },
    setEditContext({ state, dispatch }, { editCtx }) {
        dispatch('clearReplyContext');
        state.editContext = editCtx;
    },
    clearEditContext({ state }) {
        state.editContext = {};
    },
    setActiveClient({ state }, { clientCtx }) {
        state.activeClient = clientCtx;
    },
    clearActiveChatResult({ state }) {
        state.chatMessages.result = [];
    },
    setTabIndex({ state }, idx) {
        state.activeTab = idx;
    },
    clearChatActiveMessages({ state }) {
        state.chatMessages.result = [];
    },
    chatsPermissionTrigger({ state }, { property, value }) {
        state.chats.pms[property] = value;
    },
    messagesPermissionTrigger({ state }, { property, value }) {
        state.chatMessages.pms[property] = value;
    },
    clearChatStore({ state, dispatch }) {
        dispatch('clearChatRefreshInterval');

        state.contacts = { ...chatListSchema() };
        state.chats = {
            ...chatListSchema(),
            pms: {
                update: false
            }
        };
        state.chatMessages = {
            ...chatListSchema(),
            activeChatId: undefined,
            pms: {
                update: false
            },
            user: {}
        };
        state.activeClient = {};
        state.replyContext = {};
        state.editContext = {};
        state.messageQueue = {};
        state.search = {
            query: ''
        };
    },
    async setActiveUserInfo({ state, getters }) {
        try {
            if (isNUNEZ(getters.getActiveChatId)) {
                const response = await chatApi.getUser({
                    params: {
                        merchant_id: getters.getMerchantId,
                        chat_id: getters.getActiveChatId
                    }
                });

                state.chatMessages.user = response.data.result;
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }
};
