var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.scenario')))]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('label',{staticClass:"mr-1",attrs:{"for":"streakVisibility"}},[_vm._v("Streak")]),_c('b-form-checkbox',{attrs:{"id":"streakVisibility","switch":""},on:{"change":_vm.triggerStreakVisibilityStatus},model:{value:(_vm.streakVisibility),callback:function ($$v) {_vm.streakVisibility=$$v},expression:"streakVisibility"}})],1),_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('label',{staticClass:"mr-1",attrs:{"for":"streakVisibility"}},[_vm._v("Lite Streak")]),_c('b-form-checkbox',{attrs:{"id":"streakVisibilityLite","switch":""},on:{"change":_vm.triggerStreakVisibilityStatus},model:{value:(_vm.streakVisibilityLite),callback:function ($$v) {_vm.streakVisibilityLite=$$v},expression:"streakVisibilityLite"}})],1)]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(prize)",fn:function(ref){
var item = ref.item;
return [(item.prize)?_c('span',[_vm._v(" "+_vm._s(_vm.checkLocales(item.prize.name))+" ")]):_vm._e()]}},{key:"cell(type_of_condition)",fn:function(ref){
var item = ref.item;
return [(item.type_of_condition === 2)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('notifications'))+" ")]):_c('span',[_vm._v("Streak")])]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixTimeFrame(item['updated_at']))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.scenario') : _vm.$t('add.scenario'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.value'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.real_title')}},[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.real_title'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.description')}},[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.description'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.description),callback:function ($$v) {_vm.$set(_vm.dataForModal, "description", $$v)},expression:"dataForModal.description"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.info')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('titles.info')) + " [UZ]")}},[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.info')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.info.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.info, "uz", $$v)},expression:"dataForModal.info.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.info')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('titles.info')) + " [RU]")}},[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.info')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.info.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.info, "ru", $$v)},expression:"dataForModal.info.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.info')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('titles.info')) + " [EN]")}},[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.info')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.info.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.info, "en", $$v)},expression:"dataForModal.info.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.days'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.days')}},[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.days'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.days),callback:function ($$v) {_vm.$set(_vm.dataForModal, "days", $$v)},expression:"dataForModal.days"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.type')}},[_c('v-select',{attrs:{"placeholder":_vm.$t('titles.type'),"disabled":_vm.isViewModal,"options":_vm.staticTypeOfConditions,"reduce":function (option) { return option.id; },"label":"label","clearable":false},model:{value:(_vm.dataForModal.type_of_condition),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type_of_condition", $$v)},expression:"dataForModal.type_of_condition"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),(_vm.dataForModal.type_of_condition === 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.prize'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.prize')}},[_c('v-select',{attrs:{"disabled":_vm.isViewModal,"options":_vm.prizeList,"reduce":function (option) { return option.id; },"placeholder":_vm.$t('titles.prize'),"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLocales(option.name))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.checkLocales(option.name))+" ")]}}],null,true),model:{value:(_vm.dataForModal.prize_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "prize_id", $$v)},expression:"dataForModal.prize_id"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,306829577)})],1):_vm._e(),(_vm.dataForModal.type_of_condition === 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('titles.position')}},[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.position'),"size":"lg","type":"number"},model:{value:(_vm.dataForModal.position),callback:function ($$v) {_vm.$set(_vm.dataForModal, "position", $$v)},expression:"dataForModal.position"}})],1)],1):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }