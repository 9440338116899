<script>
import VueApexCharts from 'vue-apexcharts';
import { formatNumber1 } from '@/util/number';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'CompStackedChart',
    components: {
        ProgressLinear,
        apexchart: VueApexCharts
    },
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        series: {
            type: Array,
            default: () => []
        },
        isPending: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        total: {
            type: Number,
            required: false
        },
        legendHeight: {
            type: Number,
            required: false
        },
        height: {
            type: Number,
            required: false,
            default: 350
        },
        tooltipChanges: {
            type: Object,
            default: function () {
                return {
                    enabled: true,
                    shared: true,
                    intersect: false,
                    followCursor: true,
                    hideEmptySeries: true,
                    y: {
                        show: true,
                        formatter: function (val) {
                            if (val !== 0) {
                                if (val.toString().length < 4) {
                                    return val.toString();
                                } else {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                            }
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            if (val !== 0) {
                                if (val.toString().length < 4) {
                                    return val.toString();
                                } else {
                                    return (
                                        val +
                                        ' - ' +
                                        opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    );
                                }
                            }
                        }
                    }
                };
            }
        }
    },
    data() {
        return {
            chartOptions: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    type: 'bar',
                    height: '350',
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                dataLabels: {
                    formatter: function (val) {
                        return formatNumber1(val);
                    }
                },
                xaxis: {
                    categories: this.categories
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6,
                    height: this.legendHeight
                },
                fill: {
                    opacity: 1
                },
                tooltip: this.tooltipChanges
                // tooltip: {
                //     enabled: true,
                //     shared: true,
                //     intersect: false,
                //     followCursor: true,
                //     y: {
                //         show: true,
                //         formatter: function (val) {
                //             return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                //         }
                //     },
                //     x: {
                //         show: true,
                //         formatter: function (val, opts) {
                //             return (
                //                 val +
                //                 ' - ' +
                //                 opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                //                     .toString()
                //                     .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                //             );
                //         }
                //     }
                // }
            }
        };
    }
};
</script>

<template>
    <div class="statistics p-1">
        <progress-linear v-if="isPending" />
        <div v-else>
            <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div class="d-flex flex-wrap flex-column flex-md-row align-items-center">
                    <p class="mb-0 mb-lg-1 p-1 font-medium-5 font-weight-bold">{{ title }}</p>
                    <p v-if="total" class="mb-1 p-1 font-medium-5 font-weight-bold">
                        {{ $t('titles.total') }} : {{ total }}
                    </p>
                </div>
                <div class="d-flex align-items-end">
                    <slot name="datePicker" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <apexchart
                        type="bar"
                        :height="height"
                        :options="chartOptions"
                        :series="series"
                    ></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
