var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Roadmap")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('div',[_c('b-button',{staticClass:"p-1 svg__button",class:_vm.openedButton ? 'opened' : '',attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.toggleDetails(row)}}},[_c('feather-icon',{staticClass:"svg__icon",attrs:{"icon":row.item._showDetails ? 'ArrowUpIcon' : 'ArrowDownIcon',"size":"20"}})],1)],1)]}},{key:"row-details",fn:function(row){return [(_vm.childRequestPending)?_c('progress-linear'):_c('b-card',{staticClass:"child__table"},[_c('b-table',{attrs:{"fields":_vm.childFields,"items":row.item.levels,"responsive":true,"borderless":"","outlined":"","striped":"","thead-class":"bg__red"},scopedSlots:_vm._u([{key:"cell(products)",fn:function(ref){
var item = ref.item;
return _vm._l((item['products']),function(child,index){return _c('b-badge',{key:index,staticClass:"table-badge__item",attrs:{"variant":child.color}},[_c('img',{staticClass:"badge-img",attrs:{"src":child.image,"alt":""}}),_c('span',[_vm._v(_vm._s(child.text.toUpperCase()))])])})}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"table-badge__item",attrs:{"variant":"success"}},[_c('span',{staticClass:"font-medium-1"},[_vm._v(_vm._s(item.name))])])]}},{key:"cell(child_actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.edit')))),expression:"`${$t('button.edit')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-dark"},on:{"click":function($event){return _vm.getOneLevel(item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"16"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.delete')))),expression:"`${$t('button.delete')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.deleteLevel(item)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"16"}})],1)],1)]}}],null,true)}),_c('b-row',{staticClass:"my-2"},[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showChildPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.childPagination.perPage,"total-rows":_vm.childPagination.totalItem,"first-number":"","last-number":""},on:{"input":_vm.handleChildPagination},model:{value:(_vm.childPagination.current),callback:function ($$v) {_vm.$set(_vm.childPagination, "current", $$v)},expression:"childPagination.current"}}):_vm._e()],1)],1)],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('add.level')))),expression:"`${$t('add.level')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-success"},on:{"click":function($event){return _vm.openChildModal(item)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}})],1),_c('table-action-btns',{attrs:{"delete-access":{ section: 'regions', permission: 'delete' },"delete-handler":_vm.deleteSeason,"index":index,"item":item,"update-access":{ section: 'regions', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneSeason(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.season') : _vm.$t('add.season'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.title'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.updatingItem)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.level_insert')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.level_insert'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.level_insert'),"state":errors.length > 0 ? false : null,"type":"number","size":"md"},model:{value:(_vm.dataForModal.levelInsert),callback:function ($$v) {_vm.$set(_vm.dataForModal, "levelInsert", $$v)},expression:"dataForModal.levelInsert"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3210111683)})],1):_vm._e()],1)],1)],1),_c('b-modal',{ref:"createUpdateChildModal",attrs:{"id":"createUpdateChildModal","no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.level') : _vm.$t('add.level'),"cancel-variant":"danger","centered":"","size":"lg"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitChildModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}])},[_c('ValidationObserver',{ref:"createUpdateChildModalValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.title'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForChildModal.name),callback:function ($$v) {_vm.$set(_vm.dataForChildModal, "name", $$v)},expression:"dataForChildModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.types')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.types'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"categories","options":_vm.staticLevelTypes,"reduce":function (option) { return option.value; },"label":"name","placeholder":_vm.$t('choose.type'),"clearable":false,"selectable":_vm.isSelectableLevelType},on:{"input":_vm.isProductIncrementable},model:{value:(_vm.dataForChildModal.types),callback:function ($$v) {_vm.$set(_vm.dataForChildModal, "types", $$v)},expression:"dataForChildModal.types"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap w-100 px-1"},_vm._l((_vm.dataForChildModal.products),function(product,index){return _c('div',{key:index,staticClass:"col-6 py-05"},[_c('div',{staticClass:"product-card p-1"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"p-05",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteProduct(index)}}},[_vm._v("X ")])],1),_c('div',[_c('LevelProductCard',{key:index,attrs:{"index":index,"product":product,"level-list":_vm.levelActionsList,"package-list":_vm.packagesList,"cards-list":_vm.cardsList,"avatars-list":_vm.avatarsList}})],1)])])}),0),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"disabled":_vm.disableAddButton,"variant":"primary"},on:{"click":_vm.addProduct}},[_vm._v(_vm._s(_vm.$t('button.add'))+" ")])],1)])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }