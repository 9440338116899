// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class SubscribersV2 extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'package/dashboardGf/' : 'package/dashboardAl/';
    }

    fetchManualPackageActivationLogs(params) {
        return this.post(this.dashboardVersion() + 'transaction/history', params);
    }

    // fetchActivatePackageAsSubscribersLogs() {
    //     return this.post(this.dashboardVersion() + 'transaction/history', params);
    // }
}

export default SubscribersV2;
