<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BFormGroup, BFormInput, BModal, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang } from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import TableActionBtns from '@/components/TableActionBtns.vue';
import vSelect from 'vue-select';
import { MERCHANT_ID } from '@/constants/names';
import { isArray, isObject } from '@/util/inspect.util';

export default {
    name: 'ViewVIPProducts',
    components: {
        vSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    en: '',
                    ru: '',
                    uz: ''
                },
                group_id: '',
                types: '',
                proc: '',
                activation_item_id: null,
                images: [],
                upload_id: null,
                qty: null
            },
            percentage: {
                defaultProc: '',
                procTrace: ''
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            groupsList: [],
            packagesList: [],
            levelActionsList: [],
            staticTypesList: [
                {
                    name: {
                        uz: 'Paket',
                        ru: 'Пакет',
                        en: 'Package'
                    },
                    value: 0
                },
                {
                    name: {
                        uz: 'Fit-Coin',
                        ru: 'Fit-Coin',
                        en: 'Fit-Coin'
                    },
                    value: 1
                },
                {
                    name: {
                        uz: 'Chipta',
                        ru: 'Билет',
                        en: 'Ticket'
                    },
                    value: 2
                },
                {
                    name: {
                        uz: 'Paynet',
                        ru: 'Paynet',
                        en: 'Paynet'
                    },
                    value: 3
                },
                {
                    name: {
                        uz: 'Level',
                        ru: 'Левел',
                        en: 'Level'
                    },
                    value: 4
                },
                {
                    name: {
                        uz: "Sovg'a",
                        ru: 'Приз',
                        en: 'Prize'
                    },
                    value: 5
                }
            ],
            updatingItem: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            is_unlimited: false,
            uploadImage: null,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' }
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getCalculatedProducts();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.images = [];
            if (isArray(image)) {
                this.dataForModal.images = image;
            } else if (isObject(image)) {
                this.dataForModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'calculate',
                    label: this.$t('titles.percentage')
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'group',
                    label: this.$t('tournament.groups')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'activation_item_id',
                    label: this.$t('titles.activation_item_id')
                },
                {
                    key: 'is_active',
                    label: this.$t('titles.is_active')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        checkedLabels() {
            if (this.dataForModal.types === 0) {
                return this.$t('titles.packages');
            } else if (this.dataForModal.types === 1) {
                return this.$t('titles.coin_amount');
            } else if (this.dataForModal.types === 2) {
                return this.$t('titles.ticket_qty');
            } else if (this.dataForModal.types === 3) {
                return this.$t('titles.paynet_sum');
            } else if (this.dataForModal.types === 4) {
                return this.$t('titles.level_type');
            } else if (this.dataForModal.types === 5) {
                return this.$t('titles.prize');
            }
            return this.$t('titles.packages');
        },

        query() {
            return Object.assign({}, this.$route.query);
        }
    },

    async mounted() {
        await this.getAllGroups();
        await this.getBillingPackages();
        await this.getLevelActions();
    },

    methods: {
        checkLocales,
        checkLang,

        async getCalculatedProducts() {
            try {
                const { data } = await api.infinityProducts.fetchCalculatedProducts();
                this.items = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllGroups() {
            const params = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.infinityGroups.fetchAllItems(params);
                // this.items = data.data.list;
                // this.pagination = data.data.pagination;
                this.groupsList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getBillingPackages() {
            const body = {
                page: 1,
                limit: 200
            };

            try {
                const { data } = await api.billingPackages.fetchAllItems(body);
                this.packagesList = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        async getLevelActions() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.newLevelActions.fetchAllItems(body);
                this.levelActionsList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.infinityProducts
                .fetchOneItem(item.value.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    const image = res.upload;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });

                    this.dataForModal.activation_item_id = parseInt(
                        this.dataForModal.activation_item_id
                    );

                    if (res.qty) {
                        this.dataForModal.qty = res.qty;
                    } else {
                        this.dataForModal.qty = null;
                        this.is_unlimited = true;
                    }

                    if (image) {
                        this.uploadImage = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async updateItem(id, body) {
            await api.infinityProducts
                .updateItem(id, body)
                .then(() => {
                    this.getCalculatedProducts();
                    this.$refs.createUpdateModal.hide();
                    this.uploadImage = null;
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async changeStatus(val) {
            const isActive = val.is_active;
            const body = {
                is_active: !isActive
            };
            await api.infinityProducts.updateItemStatus(val.id, body);
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        }
                        // else {
                        //     await this.createItem(body, config);
                        //     this.$toast.success(this.$t('success.added'));
                        // }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        clearActivationItem() {
            this.dataForModal.activation_item_id = null;
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getCalculatedProducts();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        openBannerModal(item) {
            const image = item.upload;
            if (image) {
                this.uploadImage = {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                };
            }

            this.openModalToUpdate(item);
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        disableQtyAndEqualsToNull(val) {
            if (!val) {
                this.dataForModal.qty = parseInt(this.dataForModal.qty);
            } else {
                this.dataForModal.qty = null;
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('infinityProducts', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.products') }} [VIP]</template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.value.id }}
                </template>

                <template #cell(qty)="{ item }">
                    {{ item.value.qty }}
                </template>

                <template #cell(activation_item_id)="{ item }">
                    <b-badge :variant="item.value.activation_item_id.color">{{
                        item.value.activation_item_id.text.toUpperCase()
                    }}</b-badge>
                </template>

                <template #cell(name)="{ item }">
                    {{ checkLocales(item.value.name) }}
                </template>

                <template #cell(group)="{ item }">
                    {{ checkLocales(item.value.group) }}
                </template>

                <template #cell(calculate)="{ item }"> {{ item.calculate }} % </template>

                <template #cell(is_active)="{ item }">
                    <b-form-checkbox
                        :checked="item.value.is_active"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        @input="changeStatus(item.value)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.subscription') : $t('add.subscription')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--     GROUP ID     -->
                    <b-col cols="12" class="mb-1">
                        <b-form-group :label="$t('group')" label-for="categories">
                            <v-select
                                id="categories"
                                v-model="dataForModal.group_id"
                                :disabled="isViewModal || !!updatingItem"
                                :options="groupsList"
                                :reduce="(option) => option.id"
                                :placeholder="$t('choose.group')"
                                label="name"
                                :clearable="false"
                            >
                                <template v-slot:option="option">
                                    {{ checkLang(option.name) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLang(option.name) }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>

                    <!--    NAME [UZ]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [RU]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [RU]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [EN]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [EN]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPES    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.types') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.types')"
                            rules="required"
                        >
                            <v-select
                                id="categories"
                                v-model="dataForModal.types"
                                :disabled="isViewModal"
                                :options="staticTypesList"
                                :reduce="(option) => option.value"
                                :placeholder="$t('choose.type')"
                                label="name"
                                @input="clearActivationItem"
                                :clearable="false"
                            >
                                <template v-slot:option="option">
                                    {{ checkLang(option.name) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLang(option.name) }}
                                </template>
                            </v-select>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ACTIVATION ITEM ID    -->
                    <b-col cols="12" v-if="dataForModal.types !== 5">
                        <label>{{ checkedLabels }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="checkedLabels"
                            :rules="
                                dataForModal.types === 3 ? 'required|min_value:500' : 'required'
                            "
                        >
                            <b-form-group>
                                <v-select
                                    v-if="dataForModal.types === 4"
                                    id="categories"
                                    v-model="dataForModal.activation_item_id"
                                    :disabled="isViewModal"
                                    :options="levelActionsList"
                                    :reduce="(option) => option.type"
                                    :placeholder="checkedLabels"
                                    label="name"
                                    :clearable="false"
                                />
                                <b-form-input
                                    v-else-if="
                                        dataForModal.types === 1 ||
                                        dataForModal.types === 2 ||
                                        dataForModal.types === 3
                                    "
                                    v-model="dataForModal.activation_item_id"
                                    :placeholder="checkedLabels"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                                <v-select
                                    v-else
                                    id="categories"
                                    v-model="dataForModal.activation_item_id"
                                    :disabled="isViewModal"
                                    :options="packagesList"
                                    :reduce="(option) => option.id"
                                    :placeholder="checkedLabels"
                                    label="name"
                                    :clearable="false"
                                >
                                    <template v-slot:option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PROC    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.percentage') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.percentage')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.proc"
                                    :placeholder="$t('enter.percentage')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    QTY    -->
                    <b-col cols="12" v-if="!is_unlimited">
                        <label>{{ $t('titles.qty') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.qty')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.qty"
                                    :placeholder="$t('enter.qty')"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS UNLIMITED    -->
                    <b-col cols="12" class="d-flex justify-content-center mt-2 user-select-none">
                        <b-form-checkbox
                            @change="disableQtyAndEqualsToNull(is_unlimited)"
                            id="is_unlimited"
                            v-model="is_unlimited"
                            :disabled="isViewModal"
                        />
                        <label for="is_unlimited" class="font-medium-1">
                            {{ $t('titles.is_unlimited') }}
                        </label>
                    </b-col>

                    <!--    IMAGE ID    -->
                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <!--                            <ValidationProvider-->
                            <!--                                v-slot="{ errors }"-->
                            <!--                                :name="$t('titles.image')"-->
                            <!--                                rules="required"-->
                            <!--                            >-->
                            <VueFileAgent
                                ref="vueFileAgent"
                                v-model="uploadImage"
                                :accept="'image/*,.zip'"
                                :deletable="true"
                                :errorText="{
                                    type: $t('vue_file_agent.file_type_error'),
                                    size: $t('vue_file_agent.size_type_error')
                                }"
                                :helpText="$t('vue_file_agent.choose_image')"
                                :maxFiles="14"
                                :maxSize="'10MB'"
                                :meta="true"
                                :multiple="false"
                                :theme="'grid'"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                @select="filesSelected($event)"
                            >
                            </VueFileAgent>

                            <b-button
                                :disabled="!fileRecordsForUpload.length || imageUploaded"
                                :variant="imageUploaded ? 'success' : 'primary'"
                                class="float-right mt-1"
                                @click="getImageId()"
                            >
                                <span v-if="!imageUploaded">
                                    {{ $t('vue_file_agent.upload_image') }}
                                </span>
                                <span v-else>
                                    {{ $t('vue_file_agent.image_uploaded') }}
                                </span>
                            </b-button>
                            <!--                                <span class="validation__red">-->
                            <!--                                    {{ errors[0] }}-->
                            <!--                                </span>-->
                            <!--                            </ValidationProvider>-->
                        </b-col>
                    </div>
                </b-row>
            </ValidationObserver>

            <!--            <template #modal-footer>-->
            <!--                <b-button variant="success" @click="submitModal">-->
            <!--                    {{ modalSubmitBtnText }}-->
            <!--                </b-button>-->
            <!--            </template>-->
        </b-modal>

        <!--    PERCENTAGE MODAL    -->
        <b-modal id="percentage-modal" centered :title="$t('titles.percentage')">
            <b-row>
                <!--    DEFAULT PROC    -->
                <b-col cols="12">
                    <label>{{ $t('titles.percentage') }}</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.percentage')"
                        rules="required"
                    >
                        <b-form-group>
                            <b-form-input
                                v-model="percentage.defaultProc"
                                :placeholder="$t('enter.percentage')"
                                :state="errors.length > 0 ? false : null"
                                size="md"
                                type="number"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <!--    PROC TRACE    -->
                <b-col cols="12" class="d-flex mt-2 user-select-none">
                    <b-form-checkbox
                        id="procTrace"
                        v-model="percentage.procTrace"
                        :disabled="isViewModal"
                    />
                    <label for="procTrace" class="font-medium-1">
                        {{ $t('titles.update_all') }}
                    </label>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-button variant="success" @click="updatePercentage">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
