import KubeServers from '@/views/kube-servers/index.vue';
import ViewKubeServers from '@/views/kube-servers/views/ViewKubeServers.vue';
import ViewKubeControl from '@/views/kube-servers/views/ViewKubeControl.vue';
import ViewKubeGroups from '@/views/kube-servers/views/ViewKubeGroups.vue';
import AppKubeServersV2 from '@/views/kube-servers-v2/AppKubeServersV2.vue';
import AppKubeControlV2 from '@/views/kube-servers-v2/control/AppKubeControlV2.vue';
import AppKubeQueueV2 from '@/views/kube-servers-v2/AppKubeQueueV2.vue';
import AppKubeClusterV2 from '@/views/kube-servers-v2/AppKubeClusterV2.vue';

export default [
    {
        path: '',
        component: KubeServers,
        meta: {
            section: 'collector',
            permission: 'collector'
        },
        children: [
            {
                path: 'kube-servers/servers',
                name: 'kube-servers',
                component: ViewKubeServers,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'kube-servers/control',
                name: 'kube-control',
                component: ViewKubeControl,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            },
            {
                path: 'kube-servers/groups',
                name: 'kube-groups',
                component: ViewKubeGroups,
                meta: {
                    section: 'collector',
                    permission: 'collector'
                }
            }
        ]
    },
    {
        path: '/kube-servers/v2/servers',
        name: 'kube-servers-v2',
        component: AppKubeServersV2,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/kube-servers/v2/control',
        name: 'kube-control-v2',
        component: AppKubeControlV2,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/kube-servers/v2/queue',
        name: 'kube-queue-v2',
        component: AppKubeQueueV2,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    },
    {
        path: '/kube-servers/v2/cluster',
        name: 'kube-cluster-v2',
        component: AppKubeClusterV2,
        meta: {
            section: 'collector',
            permission: 'collector'
        }
    }
];
