<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CompStackedChart from '@/components/CompStackedChart.vue';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';

export default {
    name: 'AppPrizesOfLevelStatistics',
    components: { CompStackedChart, ProgressLinear },
    data() {
        return {
            levelWinningStatistics: {
                series: [],
                categories: [],
                isPending: true,
                tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                    followCursor: true,
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            return (
                                val +
                                ' - ' +
                                opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            );
                        }
                    }
                }
            }
        };
    },
    async mounted() {
        await this.getLevelWinningStatistics();
    },
    methods: {
        // LEVEL STATISTICS
        async getLevelWinningStatistics() {
            this.levelWinningStatistics.categories.length = 0;
            this.levelWinningStatistics.series.length = 0;
            this.levelWinningStatistics.isPending = true;
            try {
                const { data } = await api.otherStatistics.fetchLevelWinningsStatistics({
                    date_start: this.date_start,
                    date_end: this.date_end,
                    merchant_id: parseInt(MERCHANT_ID)
                });

                this.levelWinningStatistics.categories = data.data.dates.map((item) =>
                    item.date.substring(5)
                );

                const prizes = {};

                data.data.dates.forEach((item) => {
                    item.levels.forEach((level) => {
                        level.prizes.forEach((prize) => {
                            const prizeName = checkLocales(prize.name);
                            if (!prizes[prizeName]) {
                                prizes[prizeName] = new Array(data.data.dates.length).fill(0);
                            }
                            const index = data.data.dates.findIndex(
                                (obj) => obj.date === item.date
                            );
                            prizes[prizeName][index] = prize.count;
                        });
                    });
                });

                this.levelWinningStatistics.series = Object.entries(prizes).map(([name, data]) => ({
                    name,
                    data
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.levelWinningStatistics.isPending = false;
            }
        }
    }
};
</script>

<template>
    <div>
        <!--      LEVEL WINNINGS STATISTICS      -->
        <div class="col-12">
            <div class="card">
                <progress-linear v-if="levelWinningStatistics.isPending" />
                <CompStackedChart
                    v-else
                    :tooltip-changes="levelWinningStatistics.tooltip"
                    :is-pending="levelWinningStatistics.isPending"
                    :series="levelWinningStatistics.series"
                    :categories="levelWinningStatistics.categories"
                    :title="$t('titles.level_winnings_statistics')"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
