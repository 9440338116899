import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class HomePackage extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchPackages(body) {
        return this.post('statistics/package/packages', body);
    }

    fetchServices(body) {
        return this.post('statistics/package/services', body);
    }

    fetchTotal(body) {
        return this.post('statistics/package/total', body);
    }

    fetchDaily(body) {
        return this.post('statistics/package/daily', body);
    }

    fetchDailyPackage(body) {
        return this.post('statistics/package/daily-package', body);
    }
}

export default HomePackage;
