<script>
import FlatPickr from 'vue-flatpickr-component';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { BFormCheckbox, BFormGroup, BTable } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { checkLocales } from '@/util/lang';
import AppHomeLongChart from '@/components/AppHomeLongChart.vue';
import VueApexCharts from 'vue-apexcharts';
import { formatNumber } from '@/util/helper';
import { statisticsFormatDate } from '@/util/date/calendar.util';

export default {
    name: 'AppFormStatistics',
    components: {
        BFormCheckbox,
        BTable,
        AppHomeLongChart,
        ProgressLinear,
        BFormGroup,
        FlatPickr,
        apexchart: VueApexCharts
    },
    data() {
        return {
            filter: {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: '',
                date_end: '',
                date_ignore: false
            },
            regionalStatistics: {
                isPending: false,
                series: [
                    {
                        name: this.$t('titles.forms'),
                        data: []
                    }
                ],
                xaxis: [],
                labels: [],
                categories: [],
                chartBarColors: ['#1f42b4']
            },
            genderStatistics: {
                isPending: false,
                series: [],
                chartOptions: {
                    chart: {
                        width: '100%',
                        type: 'pie'
                    },
                    labels: [],
                    theme: {
                        monochrome: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                offset: -5
                            }
                        }
                    },
                    dataLabels: {
                        formatter(val, opts) {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            const value = opts.w.config.series[opts.seriesIndex];
                            return [
                                name,
                                'Quantity' + ': ' + formatNumber(value),
                                'Percentage: ' + val.toFixed(1) + '%'
                            ];
                        }
                    },
                    legend: {
                        show: false
                    }
                }
            },
            ageItems: [],
            qtyStatistics: null
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: 'age',
                    label: this.$t('titles.age')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                }
            ];
        }
    },

    async mounted() {
        await this.fixMonthlyDates();
    },

    methods: {
        statisticsFormatDate,
        async getRegionalStatistics() {
            this.regionalStatistics.isPending = true;
            this.regionalStatistics.series[0].data.length = 0;
            this.regionalStatistics.labels.length = 0;
            this.regionalStatistics.categories.length = 0;
            try {
                const { data } = await api.formStatisticsApi.fetchFormRegionalStatistics(
                    this.filter
                );

                for (const item of data.data) {
                    this.regionalStatistics.categories.push(checkLocales(item.region.name));
                    this.regionalStatistics.series[0].data.push(item.qty);
                    this.regionalStatistics.labels.push(item.qty);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.regionalStatistics.isPending = false;
            }
        },

        async getGenderStatistics() {
            this.genderStatistics.isPending = true;
            this.genderStatistics.series.length = 0;
            this.genderStatistics.chartOptions.labels.length = 0;
            try {
                const { data } = await api.formStatisticsApi.fetchFormGenderStatistics(this.filter);

                Object.entries(data.data).forEach(([key, value], index) => {
                    this.genderStatistics.chartOptions.labels[index] = key.toUpperCase();
                    this.genderStatistics.series.push(value);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.genderStatistics.isPending = false;
            }
        },

        async getAgeStatistics() {
            try {
                const { data } = await api.formStatisticsApi.fetchFormAgeStatistics(this.filter);
                this.ageItems = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getQuantityStatistics() {
            try {
                const { data } = await api.formStatisticsApi.fetchFormQuantityStatistics(
                    this.filter
                );
                this.qtyStatistics = formatNumber(data.data);
            } catch (e) {
                console.error(e);
            }
        },

        async getAllRequests() {
            await this.getRegionalStatistics();
            await this.getGenderStatistics();
            await this.getAgeStatistics();
            await this.getQuantityStatistics();
        },

        async fixMonthlyDates() {
            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.filter.date_start = this.statisticsFormatDate(date_start);
            this.filter.date_end = this.statisticsFormatDate(date_end);
            await this.getAllRequests();
        },

        triggerDateIgnore() {
            if (this.filter.date_ignore) {
                this.filter.date_start = '';
                this.filter.date_end = '';
                this.getAllRequests();
            } else {
                this.fixMonthlyDates();
            }
        }
    }
};
</script>

<template>
    <div class="form-statistics">
        <div class="form-statistics__filter">
            <div class="row align-items-end">
                <div class="col-12 col-sm-5">
                    <label>{{ $t('titles.start_date') }}</label>
                    <b-form-group>
                        <flat-pickr
                            v-model="filter.date_start"
                            :config="{
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            autocomplete="off"
                            class="mb-1 form-control"
                            name="date"
                            :disabled="filter.date_ignore"
                        />
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-5">
                    <label>{{ $t('titles.end_date') }}</label>
                    <b-form-group>
                        <flat-pickr
                            v-model="filter.date_end"
                            :config="{
                                allowInput: true,
                                time_24hr: true
                            }"
                            :placeholder="$t('choose.date')"
                            autocomplete="off"
                            class="mb-1 form-control"
                            name="date"
                            :disabled="filter.date_ignore"
                        />
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-2 mb-1 d-flex align-items-center">
                    <div class="mr-1">
                        <b-form-checkbox
                            v-model="filter.date_ignore"
                            class="custom-control-success reversed-switch"
                            name="check-button"
                            switch
                            @change="triggerDateIgnore"
                        >
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </div>
                    <b-button
                        @click="getAllRequests"
                        variant="outline-primary"
                        size="sm"
                        class="w-100 px-0"
                    >
                        <feather-icon icon="SearchIcon" size="22" />
                    </b-button>
                </div>
            </div>
        </div>
        <div class="row form-statistics__statistics">
            <!--      REGIONAL STATISTICS      -->
            <div class="col-12 order-2 order-sm-1">
                <div class="card">
                    <progress-linear v-if="regionalStatistics.isPending" />
                    <AppHomeLongChart
                        v-else
                        tooltip-text="titles.forms"
                        :chart-bar-colors="regionalStatistics.chartBarColors"
                        :title="$t('titles.regional_statistics')"
                        :series="regionalStatistics.series"
                        :loaded="false"
                        :dynamic-xaxis="regionalStatistics.categories"
                        :labels="regionalStatistics.labels"
                    />
                </div>
            </div>

            <div class="col-12 col-lg-7 mb-lg-0 order-3 order-sm-2">
                <progress-linear v-if="!ageItems.length" />
                <div class="card p-2" v-else>
                    <h3>{{ $t('titles.age_statistics') }}</h3>
                    <b-table
                        :fields="fields"
                        :items="ageItems"
                        :responsive="true"
                        borderless
                        class="news__table"
                        outlined
                        striped
                    >
                    </b-table>
                </div>
            </div>

            <div class="col-12 col-lg-5 order-1 order-sm-3">
                <!--      GENDER STATISTICS      -->
                <div>
                    <div class="card p-1">
                        <h3>{{ $t('titles.gender_statistics') }}</h3>
                        <progress-linear v-if="genderStatistics.isPending" />
                        <div id="chart">
                            <apexchart
                                type="pie"
                                :options="genderStatistics.chartOptions"
                                :series="genderStatistics.series"
                            ></apexchart>
                        </div>
                    </div>
                </div>

                <!--      QUANTITY STATISTICS      -->
                <div>
                    <div class="card p-1">
                        <h3>{{ $t('titles.qty_statistics') }}</h3>
                        <progress-linear v-if="!qtyStatistics" />
                        <h3 v-else>
                            {{ $t('titles.qty') }} -
                            <b-badge variant="primary">{{ qtyStatistics }}</b-badge>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
