<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { updateObjectToNew } from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import { MERCHANT_ID } from '@/constants/names';
import { isArray, isObject } from '@/util/inspect.util';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppSpecialOffers',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect,
        flatPickr
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                image: [],
                price: null,
                real_price: null,
                places: [],
                service: null,
                product: null,
                product_value: null,
                segment: null,
                date_start: null,
                date_end: null,
                show_before_payment: false,
                is_published: false
            },
            typesList: {},
            bundleList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            requestPending: false,
            uploadImage: null,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' }
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.image = [];
            if (isArray(image)) {
                this.dataForModal.image = image;
            } else if (isObject(image)) {
                this.dataForModal.image.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.real_title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'real_price',
                    label: this.$t('titles.real_price')
                },
                {
                    key: 'price',
                    label: this.$t('titles.discount_price')
                },
                {
                    key: 'service',
                    label: this.$t('titles.service')
                },
                {
                    key: 'product',
                    label: this.$t('titles.product_value')
                },
                {
                    key: 'segment',
                    label: this.$t('titles.segmentation')
                },
                {
                    key: 'places',
                    label: this.$t('titles.places')
                },
                {
                    key: 'date_start',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'date_end',
                    label: this.$t('titles.end_date')
                },
                {
                    key: 'image',
                    label: this.$t('titles.image')
                },
                {
                    key: 'show_before_payment',
                    label: this.$t('titles.show_before_payment')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getEnum();
        await this.getBundleList();
    },

    methods: {
        checkLocales,

        async getEnum() {
            try {
                const { data } = await api.specialOffersApi.fetchEnum({
                    merchant_id: parseInt(MERCHANT_ID),
                    page: 1,
                    limit: 100
                });
                this.typesList = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };
            this.requestPending = true;

            try {
                const { data } = await api.specialOffersApi.fetchAllItems(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async getBundleList() {
            const params = {
                page: 1,
                limit: 100
            };

            try {
                const { data } = await api.bundleApi.fetchAllItems(params);
                this.bundleList = data.data.list.map((item) => {
                    return {
                        id: item.id,
                        name: item.charge.name
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            const body = {
                id: item.id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.specialOffersApi
                .fetchOneItem(body)
                .then((response) => {
                    this.isOpenModal = true;
                    const image = response.data.data.image;

                    // merge Object for need data
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                    };

                    if (image) {
                        this.uploadImage = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }

                    this.dataForModal.product_value = parseInt(this.dataForModal.product_value);
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.specialOffersApi
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.specialOffersApi
                .updateItem({ ...body, id: id })
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.specialOffersApi
                .deleteItem({ id: id, merchant_id: parseInt(MERCHANT_ID) })
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    body.merchant_id = parseInt(MERCHANT_ID);
                    body.product_value = body.product_value.toString();
                    body.upload_id = this.dataForModal.image[0].id;
                    delete this.dataForModal.image;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.image.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('special-offers', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.image[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.special_offers') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(description)="{ item }">
                    {{ checkLocales(item.description) }}
                </template>

                <template #cell(places)="{ item }">
                    <b-badge
                        variant="primary"
                        v-for="(place, index) in item.places"
                        :key="place + index"
                    >
                        {{ place }}
                    </b-badge>
                </template>

                <template #cell(image)="{ item }">
                    <img
                        class="table-img"
                        v-if="item && item.image"
                        :src="item.image.path"
                        alt=""
                    />
                </template>

                <template #cell(show_before_payment)="{ item }">
                    <feather-icon
                        size="20"
                        :icon="item.show_before_payment ? 'CheckIcon' : 'XIcon'"
                        :class="item.show_before_payment ? 'text-success' : 'text-danger'"
                    />
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        size="20"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        :class="item.is_published ? 'text-success' : 'text-danger'"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'collector', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'collector', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.special_offers') : $t('add.special_offers')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME [UZ]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [UZ]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [RU]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [RU]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME [EN]    -->
                    <b-col cols="12">
                        <label>{{ `${$t('titles.title')} [EN]` }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--   DESCRIPTION [UZ]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.description') }} [UZ] </label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="`${$t('titles.description')} [UZ]`"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-textarea
                                v-model="dataForModal.description.uz"
                                :placeholder="`${$t('enter.description')} [UZ]`"
                                size="lg"
                            />
                        </b-form-group>
                        <!--                            <span class="validation__red">-->
                        <!--                                {{ errors[0] }}-->
                        <!--                            </span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--   DESCRIPTION [RU]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.description') }} [RU] </label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="`${$t('titles.description')} [RU]`"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-textarea
                                v-model="dataForModal.description.ru"
                                :placeholder="`${$t('enter.description')} [RU]`"
                                size="lg"
                            />
                        </b-form-group>
                        <!--                            <span class="validation__red">-->
                        <!--                                {{ errors[0] }}-->
                        <!--                            </span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--   DESCRIPTION [EN]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.description') }} [EN] </label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="`${$t('titles.description')} [EN]`"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <b-form-group>
                            <b-form-textarea
                                v-model="dataForModal.description.en"
                                :placeholder="`${$t('enter.description')} [EN]`"
                                size="lg"
                            />
                        </b-form-group>
                        <!--                            <span class="validation__red">-->
                        <!--                                {{ errors[0] }}-->
                        <!--                            </span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--    REAL PRICE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.real_price') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.real_price')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.real_price"
                                    :placeholder="$t('titles.real_price')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PRICE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.discount_price') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.discount_price')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.price"
                                    :placeholder="$t('titles.discount_price')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SERVICE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.service') }}</label>
                        <!--                        <ValidationProvider-->
                        <!--                            v-slot="{ errors }"-->
                        <!--                            :name="$t('titles.service')"-->
                        <!--                            rules="required"-->
                        <!--                        >-->
                        <v-select
                            v-model="dataForModal.service"
                            :options="typesList.services"
                            :reduce="(option) => option"
                            :placeholder="$t('titles.service')"
                            :clearable="false"
                        />
                        <!--                            <span class="validation__red">{{ errors[0] }}</span>-->
                        <!--                        </ValidationProvider>-->
                    </b-col>

                    <!--    PRODUCT    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.product') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.product')"
                            rules="required"
                        >
                            <v-select
                                v-model="dataForModal.product"
                                :options="typesList.products"
                                :reduce="(option) => option"
                                :placeholder="$t('titles.product')"
                                :clearable="false"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PRODUCT VALUE    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.product_value') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.product_value')"
                            rules="required"
                        >
                            <v-select
                                v-model="dataForModal.product_value"
                                :options="bundleList"
                                :reduce="(option) => option.id"
                                label="name"
                                :placeholder="$t('titles.product_value')"
                                :clearable="false"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SEGMENT    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.segmentation') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.segmentation')"
                            rules="required"
                        >
                            <v-select
                                v-model="dataForModal.segment"
                                :options="typesList['segments']"
                                :reduce="(option) => option"
                                :placeholder="$t('titles.segmentation')"
                                :clearable="false"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PLACES    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.places') }}</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.places')"
                            rules="required"
                        >
                            <v-select
                                v-model="dataForModal.places"
                                :options="typesList['places']"
                                :reduce="(option) => option"
                                :placeholder="$t('titles.places')"
                                :multiple="true"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <b-col cols="12">
                        <div>
                            <!--          DATE START          -->
                            <label for="dateStart">{{ $t('titles.start_date') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.start_date')"
                                rules="required"
                            >
                                <flat-pickr
                                    v-model="dataForModal.date_start"
                                    :config="{
                                        enableTime: true,
                                        allowInput: true,
                                        time_24hr: true
                                    }"
                                    autocomplete="off"
                                    class="form-control"
                                    name="date"
                                    :placeholder="$t('choose.date')"
                                    id="dateStart"
                                />
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div>
                            <!--          DATE END          -->
                            <label for="dateStart">{{ $t('titles.end_date') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.end_date')"
                                rules="required"
                            >
                                <flat-pickr
                                    v-model="dataForModal.date_end"
                                    :config="{
                                        enableTime: true,
                                        allowInput: true,
                                        time_24hr: true
                                    }"
                                    autocomplete="off"
                                    class="form-control"
                                    name="date"
                                    :placeholder="$t('choose.date')"
                                    id="dateEnd"
                                />
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </b-col>

                    <!--    IMAGE ID    -->
                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <!--                            <ValidationProvider-->
                            <!--                                v-slot="{ errors }"-->
                            <!--                                :name="$t('titles.image')"-->
                            <!--                                rules="required"-->
                            <!--                            >-->
                            <VueFileAgent
                                ref="vueFileAgent"
                                v-model="uploadImage"
                                :accept="'image/*,.zip'"
                                :deletable="true"
                                :errorText="{
                                    type: $t('vue_file_agent.file_type_error'),
                                    size: $t('vue_file_agent.size_type_error')
                                }"
                                :helpText="$t('vue_file_agent.choose_image')"
                                :maxFiles="14"
                                :maxSize="'10MB'"
                                :meta="true"
                                :multiple="false"
                                :theme="'grid'"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                @select="filesSelected($event)"
                            >
                            </VueFileAgent>

                            <b-button
                                :disabled="!fileRecordsForUpload.length || imageUploaded"
                                :variant="imageUploaded ? 'success' : 'primary'"
                                class="float-right mt-1"
                                @click="getImageId()"
                            >
                                <span v-if="!imageUploaded">
                                    {{ $t('vue_file_agent.upload_image') }}
                                </span>
                                <span v-else>
                                    {{ $t('vue_file_agent.image_uploaded') }}
                                </span>
                            </b-button>
                            <!--                                <span class="validation__red">-->
                            <!--                                    {{ errors[0] }}-->
                            <!--                                </span>-->
                            <!--                            </ValidationProvider>-->
                        </b-col>
                    </div>

                    <!--    IS PUBLISHED    -->
                    <b-col cols="12">
                        <div class="float-left">
                            <label for="show_before_payment">{{
                                $t('titles.show_before_payment')
                            }}</label>
                            <b-form-checkbox
                                id="show_before_payment"
                                v-model="dataForModal.show_before_payment"
                            />
                        </div>
                        <div class="float-right">
                            <label for="is_published">{{ $t('titles.is_published') }}</label>
                            <b-form-checkbox
                                id="is_published"
                                v-model="dataForModal.is_published"
                            />
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-2"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.table-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
</style>
