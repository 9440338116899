// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class RandomizerCategories extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'randomizer/dashboardGf/' : 'randomizer/dashboardAl/';
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'category/getAll', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `category/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'category/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `category/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `category/delete/${id}`);
    }
}

export default RandomizerCategories;
