import { render, staticRenderFns } from "./ActivateBlock.vue?vue&type=template&id=47797ace&scoped=true&"
import script from "./ActivateBlock.vue?vue&type=script&lang=js&"
export * from "./ActivateBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47797ace",
  null
  
)

export default component.exports