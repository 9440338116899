<script>
export default {
    name: 'ViewIsStatus'
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12">Uzmobile</div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
