<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BPagination, BTable } from 'bootstrap-vue';
import { formatNumber } from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import flatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppDealersStatistics',
    components: {
        BButton,
        BPagination,
        ProgressLinear,
        PageTitle,
        BTable,
        flatPickr
    },
    data() {
        return {
            subscriptionItems: [],
            serviceItems: [],
            serviceRequestPending: false,
            subscriptionRequestPending: false,
            date_start: null,
            date_end: null,
            servicePagination: {
                current: 1,
                totalItem: '',
                perPage: 25
            },
            subscriptionPagination: {
                current: 1,
                totalItem: '',
                perPage: 25
            },
            activeDealer: null
        };
    },

    watch: {
        '$route.query': {
            async handler(query) {
                if (
                    query.subscriptionPage &&
                    this.subscriptionPagination.current !== query.subscriptionPage
                ) {
                    this.subscriptionPagination.current = query.subscriptionPage || 1;
                    await this.getStatisticsBySubscription();
                }
                if (query.servicePage && this.servicePagination.current !== query.servicePage) {
                    this.servicePagination.current = query.servicePage || 1;
                    await this.getStatisticsByServices();
                }
            },
            deep: true,
            immediate: true
        },

        'servicePagination.current': {
            handler(page) {
                let newPage = page;
                if (this.servicePagination.current > this.servicePagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    servicePage: newPage
                });
            }
        },

        'subscriptionPagination.current': {
            handler(page) {
                let newPage = page;
                if (this.subscriptionPagination.current > this.subscriptionPagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    subscriptionPage: newPage
                });
            }
        }
    },

    async mounted() {
        await this.getAllDealers();
        await this.fixStatisticsDates();
    },

    computed: {
        subscriptionFields() {
            return [
                {
                    key: 'count',
                    label: this.$t('titles.amount')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'our_sum',
                    label: this.$t('titles.sum_our')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        serviceFields() {
            return [
                {
                    key: 'count',
                    label: this.$t('titles.amount')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'sum_our',
                    label: this.$t('titles.sum_our')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasServiceItems() {
            return this.serviceItems && this.serviceItems.length > 0;
        },

        hasSubscriptionItems() {
            return this.subscriptionItems && this.subscriptionItems.length > 0;
        },

        showServicePagination() {
            return this.hasServiceItems && !this.serviceRequestPending;
        },

        showSubscriptionPagination() {
            return this.hasSubscriptionItems && !this.subscriptionRequestPending;
        }
    },

    methods: {
        formatNumber,
        checkLocales,

        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        async getStatisticsByServices() {
            this.serviceRequestPending = true;
            const body = {
                id: this.$route.params.id,
                date_start: this.date_start,
                date_end: this.date_end,
                page: this.servicePagination.current,
                limit: this.servicePagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.dealersApi
                .fetchServicesByDealer(body)
                .then((response) => {
                    this.serviceItems = response.data.data.list;
                    this.servicePagination = response.data.data.pagination;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.serviceRequestPending = false;
                });
        },

        async getStatisticsBySubscription() {
            this.subscriptionRequestPending = true;
            const body = {
                id: this.$route.params.id,
                date_start: this.date_start,
                date_end: this.date_end,
                page: this.subscriptionPagination.current,
                limit: this.subscriptionPagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.dealersApi
                .fetchSubscriptionsByDealer(body)
                .then((response) => {
                    this.subscriptionItems = response.data.data.list;
                    this.subscriptionPagination = response.data.data.pagination;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.subscriptionRequestPending = false;
                });
        },

        async getAllDealers() {
            const params = {
                page: 1,
                limit: 500
            };

            try {
                const { data } = await api.dealerAccountsApi.fetchAllItems(params);
                this.activeDealer = data.data.list.find((dealer) => {
                    return dealer.id === parseInt(this.$route.params.id);
                });
            } catch (e) {
                console.error(e);
            }
        },

        async fixStatisticsDates() {
            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.date_start = this.formatDate(date_start);
            this.date_end = this.formatDate(date_end);

            await this.getStatisticsByServices();
            await this.getStatisticsBySubscription();
        },

        async sendAllRequests() {
            await this.getStatisticsByServices();
            await this.getStatisticsBySubscription();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                <span>{{ $t('titles.dealers_statistics') }}</span>
            </template>
            <template #button>
                <h4 v-if="activeDealer">{{ activeDealer.details }} [{{ activeDealer.contact }}]</h4>
            </template>
        </page-title>

        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button @click="sendAllRequests" class="w-100 mt-1 mt-md-0" variant="primary">
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>

        <div class="row">
            <!--  STATISTICS BY SERVICES  -->
            <div class="col-12 col-lg-6">
                <div class="card p-1">
                    <h4 class="mb-1">
                        {{ $t('titles.service') }} {{ $t('navigation.statistics') }}
                    </h4>
                    <progress-linear v-if="serviceRequestPending" />
                    <b-table
                        v-else
                        :fields="serviceFields"
                        :items="serviceItems"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(service)="{ item }">
                            {{ checkLocales(item.service) }}
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-center my-2">
                        <b-pagination
                            v-if="showServicePagination"
                            v-model="servicePagination.current"
                            :per-page="servicePagination.perPage"
                            :total-rows="servicePagination.totalItem"
                            class="float-right mr-4 mb-0"
                            first-number
                            last-number
                        />
                    </div>
                </div>
            </div>

            <!--  STATISTICS BY SUBSCRIPTION  -->
            <div class="col-12 col-lg-6">
                <div class="card p-1">
                    <h4 class="mb-1">
                        {{ $t('titles.subscription_event_statistics') }}
                    </h4>
                    <progress-linear v-if="subscriptionRequestPending" />
                    <b-table
                        v-else
                        :fields="subscriptionFields"
                        :items="subscriptionItems"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(service)="{ item }">
                            {{ checkLocales(item.service) }}
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-center my-2">
                        <b-pagination
                            v-if="showSubscriptionPagination"
                            v-model="subscriptionPagination.current"
                            :per-page="subscriptionPagination.perPage"
                            :total-rows="subscriptionPagination.totalItem"
                            class="float-right mr-4 mb-0"
                            first-number
                            last-number
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}
</style>
