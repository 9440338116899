var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.offers'))+" ")]},proxy:true},(_vm.hasAccess('offers', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{attrs:{"striped":"","borderless":"","outlined":"","responsive":true,"fields":_vm.tableHeader,"items":_vm.computedOffers},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(voice)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:['stroke-current', _vm.generateIconColorToBoolean(!item.voice)],attrs:{"icon":_vm.generateIconToBoolean(!item.voice)}})]}},{key:"cell(internet)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:['stroke-current', _vm.generateIconColorToBoolean(!item.internet)],attrs:{"icon":_vm.generateIconToBoolean(!item.internet)}})]}},{key:"cell(sms)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:['stroke-current', _vm.generateIconColorToBoolean(!item.sms)],attrs:{"icon":_vm.generateIconToBoolean(!item.sms)}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteOffer,"delete-access":{ section: 'offers', permission: 'delete' },"update-access":{ section: 'offers', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","cancel-variant":"danger","centered":"","hide-footer":false,"no-close-on-backdrop":false,"size":"sm","title":_vm.$t('add.offer')},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(!_vm.updatingItem)?_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(_vm.createOneMore),callback:function ($$v) {_vm.createOneMore=$$v},expression:"createOneMore"}},[_vm._v(" "+_vm._s(_vm.$t('titles.add_more'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.title')},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.offer'))+" ID ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.offer')) + " ID"),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":((_vm.$t('enter.offer')) + " ID")},model:{value:(_vm.dataForModal.offer_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "offer_id", $$v)},expression:"dataForModal.offer_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.offerTypes'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.offerTypes'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"multiple":"","closeOnSelect":false,"options":_vm.offerTypes,"placeholder":_vm.$t('choose.offerType'),"reduce":function (type) { return type.key; },"searchable":false},model:{value:(_vm.dataForModal.types),callback:function ($$v) {_vm.$set(_vm.dataForModal, "types", $$v)},expression:"dataForModal.types"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }