import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class DashboardActivity extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('v2/dashboard-activity/findAll', body);
    }
}

export default DashboardActivity;
