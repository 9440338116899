<script>
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CompStackedChart from '@/components/CompStackedChart.vue';
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { formatNumber } from '@/util/helper';

export default {
    name: 'AppRoadmapStatistics',
    components: { CompStackedChart, ProgressLinear },
    data() {
        return {
            roadmapStatistics: [],
            isFetching: false
        };
    },
    async mounted() {
        await this.getRoadmapStatistics();
    },
    methods: {
        customTooltip(opts) {
            const level = opts.w.globals.labels[opts.dataPointIndex];
            const users = opts.series[0][opts.dataPointIndex];
            return `<div class="p-1">
                <span style="color: black"><i>${level} level : </i><strong>${formatNumber(
                users
            )}</strong></span>
              </div>`;
        },

        // ROADMAP STATISTICS
        async getRoadmapStatistics() {
            this.isFetching = true;
            try {
                const { data } = await api.otherStatistics.fetchRoadmapStatistics({
                    merchant_id: parseInt(MERCHANT_ID)
                });

                this.roadmapStatistics = data.data.map((season) => ({
                    name: season.name,
                    series: [
                        {
                            name: season.name,
                            data: season.list.map((item) => item.count)
                        }
                    ],
                    categories: season.list.map((item) => item.number),
                    tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: true,
                        custom: (opts) => this.customTooltip(opts)
                    }
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.isFetching = false;
            }
        }
    }
};
</script>

<template>
    <div>
        <!--      ROADMAP STATISTICS      -->
        <div class="col-12 px-0">
            <progress-linear v-if="isFetching" />
            <div v-else>
                <div class="card" v-for="(season, index) in roadmapStatistics" :key="index">
                    <CompStackedChart
                        :tooltip-changes="season.tooltip"
                        :series="season.series"
                        :categories="season.categories"
                        :title="`${$t('titles.roadmap_statistics')} - [${season.name}]`"
                    />
                    <!--                    <AppHomeLongChart-->
                    <!--                        :chart-bar-colors="season.chartBarColors"-->
                    <!--                        :title="$t('bonus_statistics.daily_three')"-->
                    <!--                        :series="season.series"-->
                    <!--                        :loaded="false"-->
                    <!--                        :dynamic-xaxis="season.categories"-->
                    <!--                        :labels="season.daily_3.labels"-->
                    <!--                    />-->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
