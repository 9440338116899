<script>
import vSelect from 'vue-select';
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BRow } from 'bootstrap-vue';
import { categoryTypes } from '@/constants/offerTypes';
import { clearObjectValues } from '@/util/helper';

export default {
    name: 'ManualPacketForm',
    components: { vSelect, BFormGroup, BCol, BRow, BButton },
    data: () => ({
        newPacket: {}
    }),
    props: {
        id: [Number, String],
        gifts: {
            type: Array,
            default: () => []
        },
        steps: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedGifts() {
            if (!this.gifts) return;
            return this.gifts;
        },
        computedSteps() {
            if (!this.steps) return;
            return this.steps;
        },
        categoryTypes() {
            return categoryTypes;
        }
    },
    methods: {
        async activatePacket() {
            let isPayloadFilled = false;
            Object.keys(this.newPacket).forEach((key) => {
                if (this.newPacket[key]) {
                    isPayloadFilled = true;
                }
            });
            if (isPayloadFilled) {
                await api.subscribers
                    .activatePacket(this.id, this.newPacket)
                    .then((res) => {
                        this.$emit('success');
                        this.$toast.success('Пакет успешно активировано!');
                        this.newPacket = clearObjectValues(this.newPacket);
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                    });
            } else {
                this.$toast.error('Заполняйте поля!');
            }
        }
    }
};
</script>

<template>
    <b-row>
        <b-col cols="12" class="mb-2">
            <h5>
                {{ $t('titles.tournaments') }}
            </h5>
            <b-form-group>
                <v-select
                    v-model="newPacket.gift_id"
                    label="name"
                    :options="computedGifts"
                    :reduce="(gift) => gift.id"
                    :searchable="false"
                    :menu-props="{
                        closeOnContentClick: false
                    }"
                />
            </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
            <h5>
                {{ $t('titles.step') }}
            </h5>
            <b-form-group>
                <v-select v-model="newPacket.step" :options="computedSteps" :searchable="false" />
            </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
            <h5>
                {{ $t('titles.type') }}
            </h5>
            <b-form-group>
                <v-select
                    v-model="newPacket.type"
                    :options="categoryTypes"
                    :searchable="false"
                    :reduce="(type) => type.key"
                />
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-button variant="success" class="float-right" @click="activatePacket">
                {{ $t('button.activate') }}
            </b-button>
        </b-col>
    </b-row>
</template>

<style scoped></style>
