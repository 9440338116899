<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';
import FlatPickr from 'vue-flatpickr-component';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CompStackedChart from '@/components/CompStackedChart.vue';
import { formatNumber } from '@/util/helper';

export default {
    name: 'AppUSSDStatistics',
    components: {
        CompStackedChart,
        ProgressLinear,
        vSelect,
        FlatPickr
    },
    data() {
        return {
            productOptions: [],
            filterBody: {
                products: [],
                merchant_id: parseInt(MERCHANT_ID)
            },
            isPending: true,
            categories: [],
            charts: [],
            products: [
                {
                    name: 'daily_1'
                },
                {
                    name: 'daily_3'
                },
                {
                    name: 'daily_2'
                },
                {
                    name: 'premium_1'
                },
                {
                    name: 'premium_3'
                },
                {
                    name: 'premium_2'
                },
                {
                    name: 'flash'
                }
            ]
        };
    },
    async mounted() {
        await this.getUSSDStatisticsProducts();
        await this.getStatistics();
    },
    methods: {
        formatNumber,
        async getUSSDStatisticsProducts() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.ussdStatisticsApi.fetchStatisticsProducts(body);
                this.productOptions = data.data;
                this.filterBody.products = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getStatistics() {
            this.isPending = true;
            this.charts.length = 0;
            try {
                const { data } = await api.ussdStatisticsApi.fetchStatistics(this.filterBody);
                const products = data.data.products;
                this.categories = products[0].dates.map((item) => item.date.substring(5));

                products.forEach((product) => {
                    const chartData = {
                        name: product.product.replace('-', '_'),
                        overall_count: product.count,
                        series: []
                    };

                    product.dates.forEach((date) => {
                        date.services.forEach((service) => {
                            const existingSeries = chartData.series.find(
                                (s) => s.name === service.name
                            );
                            if (existingSeries) {
                                existingSeries.data.push(service.count);
                            } else {
                                chartData.series.push({
                                    name: service.name,
                                    data: [service.count]
                                });
                            }
                        });
                    });

                    this.charts.push(chartData);
                });

                this.charts.sort((a, b) => {
                    const [categoryA, numberA] = a.name.split('_');
                    const [categoryB, numberB] = b.name.split('_');

                    const order = { premium: 0, daily: 1 };
                    const categoryOrderA = order[categoryA] || 2;
                    const categoryOrderB = order[categoryB] || 2;

                    if (categoryOrderA !== categoryOrderB) {
                        return categoryOrderA - categoryOrderB;
                    }

                    return parseInt(numberA) - parseInt(numberB);
                });
            } finally {
                this.isPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="statistics">
        <h3>{{ $t('titles.ussd_statistics') }}</h3>
        <div class="row">
            <div class="col-12 col-sm-6 col-xl-3 mb-1">
                <label for="dateStart">{{ $t('titles.start_date') }}</label>
                <flat-pickr
                    v-model="filterBody.date_start"
                    :config="{
                        allowInput: true
                    }"
                    autocomplete="off"
                    class="form-control mb-1"
                    name="date"
                    :placeholder="$t('choose.date')"
                    id="dateStart"
                />
            </div>
            <div class="col-12 col-sm-6 col-xl-3 mb-1">
                <label for="dateStart">{{ $t('titles.end_date') }}</label>
                <flat-pickr
                    v-model="filterBody.date_end"
                    :config="{
                        allowInput: true
                    }"
                    autocomplete="off"
                    class="form-control mb-1"
                    name="date"
                    :placeholder="$t('choose.date')"
                    id="dateStart"
                />
            </div>
            <div class="col-12 col-sm-10 col-xl-5 mb-1">
                <label for="services">{{ $t('titles.services') }}</label>
                <v-select
                    id="services"
                    :options="productOptions"
                    v-model="filterBody.products"
                    :placeholder="$t('choose.product')"
                    size="md"
                    taggable
                    multiple
                />
            </div>
            <div class="col-12 col-sm-2 col-xl-1 mb-1 d-flex align-items-end align-items-xl-center">
                <b-button @click="getStatistics" variant="outline-primary" class="w-100 py-05 px-0">
                    <feather-icon icon="SearchIcon" size="20" />
                </b-button>
            </div>

            <div class="col-12" v-for="(chart, index) in this.charts" :key="index">
                <div class="card">
                    <progress-linear v-if="isPending" />
                    <CompStackedChart
                        v-else
                        :is-pending="isPending"
                        :series="chart.series"
                        :categories="categories"
                        :title="
                            $t(`ussd_statistics.${chart.name}`) +
                            ' - ' +
                            formatNumber(chart.overall_count)
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.py-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
</style>
