import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class FAQ extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllNews(params) {
        return this.post('news/findAll', params);
    }

    fetchOneNews(params) {
        return this.post('news/findOne', params);
    }

    createNews(body) {
        return this.post('news', body);
    }

    updateNews(body) {
        return this.post('news/update', body);
    }

    deleteNews(body) {
        return this.post('news/remove', body);
    }
}

export default FAQ;
