export default [
    {
        path: '/roles',
        name: 'Roles Index',
        component: () => import('@/views/user-management/Roles/RolesIndex.vue'),
        meta: {
            section: 'roles',
            permission: 'view'
        }
    },
    {
        path: '/roles/create',
        name: 'Roles Create',
        component: () => import('@/views/user-management/Roles/RolesSingle.vue'),
        meta: {
            navActiveLink: 'Roles Index',
            section: 'roles',
            permission: 'create'
        }
    },
    {
        path: '/roles/:id',
        name: 'Roles Single',
        component: () => import('@/views/user-management/Roles/RolesSingle.vue'),
        meta: {
            navActiveLink: 'Roles Index',
            section: 'roles',
            permission: 'view'
        }
    },
    {
        path: '/staff',
        name: 'Staff',
        component: () => import('@/views/user-management/Staff.vue'),
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
