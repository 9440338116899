<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';

export default {
    name: 'ViewUzmobileBalance',
    components: { ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUzmobile.fetchBalance({
                    phone: parseInt(this.phone)
                });
                this.response = data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>

                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <b-button @click="getResponse" variant="success">{{
                        $t('button.filter')
                    }}</b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <div v-else class="json__card card p-1">
                    <div v-if="response">
                        <div v-for="(value, key, index) in response" :key="index + key + value">
                            <span class="d-block text-uppercase font-medium-2 text-info mb-0">
                                {{ key }}
                            </span>
                            <p
                                v-for="(childValue, childKey, childIndex) in value"
                                :key="childIndex + childKey + childValue"
                            >
                                <span class="text-bold text-uppercase font-medium-2 text-warning">{{
                                    childKey
                                }}</span>
                                <span class="text-bold text-uppercase font-medium-2">:</span>
                                <span
                                    class="text-bold text-uppercase font-medium-2 text-success"
                                    v-if="childValue !== null"
                                >
                                    {{ childValue }}
                                </span>
                                <span
                                    class="text-bold text-uppercase font-medium-2 text-success"
                                    v-else
                                >
                                    null
                                </span>
                            </p>
                        </div>
                    </div>
                    <h4 v-else>No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
