<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import vSelect from 'vue-select';
import { checkLocales } from '@/util/lang';
import { EventBus } from '@/util/event-bus';

export default {
    name: 'AppCardsManagement',
    components: {
        vSelect
    },
    data() {
        return {
            tabs: [
                {
                    value: 'active-cards',
                    label: 'active_cards',
                    routeName: 'active-cards'
                },
                {
                    value: 'user-inventory',
                    label: 'user_inventory',
                    routeName: 'user-inventory'
                }
            ],
            requestBody: {
                user_id: this.$route.params.id,
                card_id: null,
                merchant_id: parseInt(MERCHANT_ID)
            },
            cardsList: []
        };
    },

    async mounted() {
        await this.getCardsList();
    },

    methods: {
        checkLocales,
        isActive(route) {
            return this.$route.name === route.routeName;
        },

        async getCardsList() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.cardsApi.fetchAllItemsWithoutPagination(body);
                this.cardsList = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        giveCardToUser() {
            try {
                api.cardsManagementApi.giveCardsToUser(this.requestBody);
                this.$toast.success(this.$t('success.done_successfully'));
                const dataToSend = true;
                EventBus.$emit('send-data', dataToSend);
            } catch (e) {
                console.error(e);
                this.$toast.error(this.$t('error.smth'));
            } finally {
                this.requestBody = {
                    user_id: this.$route.params.id,
                    card_id: null,
                    merchant_id: parseInt(MERCHANT_ID)
                };
            }
        }
    }
};
</script>

<template>
    <div class="card-management">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="nav nav-tabs flex-nowrap overflow-x-scroll w-auto">
                    <router-link
                        v-for="(route, index) in tabs"
                        :key="index"
                        :to="{ name: route.routeName }"
                        active-class="active"
                        class="nav-link"
                        :class="{ active: isActive(route) }"
                        style="min-width: fit-content"
                    >
                        {{ $t(`titles.${route.label}`) }}
                    </router-link>
                </div>
                <router-view />
            </div>
            <div class="col-12 col-lg-4">
                <div class="card p-1">
                    <h4 class="mb-1">{{ $t('titles.give_card') }}</h4>
                    <div>
                        <label for="user_id">{{ $t('titles.user_id') }}</label>
                        <b-form-input
                            id="user_id"
                            v-model="requestBody.user_id"
                            :placeholder="$t('titles.user_id')"
                        />
                    </div>
                    <div class="mt-1">
                        <label for="user_id">{{ $t('titles.cards') }}</label>
                        <div>
                            <v-select
                                v-model="requestBody.card_id"
                                :options="cardsList"
                                :clearable="false"
                                label="label"
                                :placeholder="$t('titles.cards')"
                                :reduce="(option) => option.id"
                                class="mb-1"
                            >
                                <template v-slot:option="option">
                                    {{ checkLocales(option.name) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLocales(option.name) }}
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <b-button @click="giveCardToUser" variant="primary" class="mt-2">{{
                        $t('titles.give_card')
                    }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.nav-tabs::-webkit-scrollbar {
    display: none;
}
</style>
