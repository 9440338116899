<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import { checkLang } from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppStockTemplates',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    directives: {
        'b-modal': VBModal,
        Ripple
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllItems();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.real_title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'modal_title',
                    label: this.$t('titles.modal_title')
                },
                {
                    key: 'modal_description',
                    label: this.$t('titles.modal_description')
                },
                {
                    key: 'templateCategories',
                    label: this.$t('titles.template_categories')
                },
                {
                    key: 'ntf_template',
                    label: this.$t('titles.ntf_template')
                },
                {
                    key: 'image',
                    label: this.$t('titles.image')
                },
                {
                    key: 'sms_template',
                    label: this.$t('titles.sms_template')
                },
                {
                    key: 'is_active',
                    label: this.$t('titles.is_active')
                },
                {
                    key: 'package_double',
                    label: this.$t('titles.package_double')
                },
                {
                    key: 'start_date',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'end_date',
                    label: this.$t('titles.end_date')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLang,

        async getAllItems() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const rsp = await api.stockTemplateApi.fetchAllItems(body);
            this.items = rsp.data.result;
            this.pagination = rsp.data.pagination;

            if (this.pagination.current > this.pagination.totalItem) {
                this.pagination.current = 1;
            }
        },

        async deleteItem(id) {
            const body = {
                id: id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.stockTemplateApi
                .deleteItem(body)
                .then(() => {
                    this.getAllItems();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getAllItems();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.stock_templates') }}</template>
            <template v-if="hasAccess('news', 'create')" #button>
                <b-button
                    :to="{ name: 'stock-templates-create' }"
                    class="ml-auto"
                    variant="success"
                >
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(is_active)="{ item }">
                    <feather-icon
                        :class="item.is_active ? 'text-success' : 'text-danger'"
                        :icon="item.is_active ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(package_double)="{ item }">
                    <feather-icon
                        :class="item.package_double ? 'text-success' : 'text-danger'"
                        :icon="item.package_double ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(modal_title)="{ item }">
                    <span v-html="checkLang(item.modal_title)" class="three_lines_text" />
                </template>

                <template #cell(modal_description)="{ item }">
                    {{ checkLang(item.modal_description) }}
                </template>

                <template #cell(title)="{ item }">
                    {{ checkLang(item.title) }}
                </template>

                <template #cell(description)="{ item }">
                    <span v-html="checkLang(item.description)" class="three_lines_text" />
                </template>

                <template #cell(templateCategories)="{ item }">
                    <b-badge v-for="template in item['templateCategories']" :key="template.id">
                        <span v-if="template.category">
                            {{ template.category.name.toUpperCase() }}
                        </span>
                    </b-badge>
                </template>

                <template #cell(image)="{ item }">
                    <div v-if="item.image">
                        <img
                            class="table-img"
                            v-if="item.image.path"
                            :src="item.image.path"
                            alt=""
                        />
                        <span v-else>No Photo</span>
                    </div>
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-b-tooltip.hover.top="`${$t('button.edit')}`"
                            :to="{ name: 'stock-templates-update', params: { id: item.id } }"
                            class="btn-icon"
                            variant="flat-dark"
                        >
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        <table-action-btns
                            :delete-access="{ section: 'news', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :show-edit-button="false"
                            :index="index"
                            :item="item"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.line__break {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.three_lines_text {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
