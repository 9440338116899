<script>
import services from '@/services';
import PageTitle from '@/components/PageTitle.vue';
import FlatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts';
import { formatYMD } from '@/util/date';

export default {
    name: 'AppCollectorActivity',
    components: { PageTitle, FlatPickr, apexchart: VueApexCharts },
    data() {
        return {
            dateRange: [],
            reactiveSeries: [
                {
                    name: 'Reactive',
                    data: []
                }
            ],
            deactiveSeries: [
                {
                    name: 'Deactive',
                    data: []
                }
            ],
            reactiveChartOptions: {
                colors: ['#28c76f'],
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                }
            },
            deactiveChartOptions: {
                colors: ['#ea5455'],
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                }
            }
        };
    },
    async created() {
        await this.fixMonthlyStatisticsDates();
        // await this.getCollectorActivity(this.dateRange[0], this.dateRange[1]);
    },
    methods: {
        async refreshChartDetails(response) {
            const chartReactive = await this.$refs?.authChartReactive;
            const chartDeactive = await this.$refs?.authChartDeactive;

            response.forEach((childItem) => {
                // eslint-disable-next-line no-unused-vars
                const [y, d, m] = childItem['timing'].split('-');
                this.reactiveChartOptions.xaxis.categories.push(`${d}.${m}`);
                this.deactiveChartOptions.xaxis.categories.push(`${d}.${m}`);
            });

            response.forEach((item) => {
                this.reactiveSeries[0].data.push(item.active);
                this.deactiveSeries[0].data.push(item.passive);
            });

            await chartReactive?.updateOptions({
                xaxis: {
                    categories: this.reactiveChartOptions.xaxis.categories
                }
            });

            await chartReactive?.updateSeries([
                {
                    data: this.reactiveSeries[0].data
                }
            ]);

            await chartDeactive?.updateOptions({
                xaxis: {
                    categories: this.deactiveChartOptions.xaxis.categories
                }
            });

            await chartDeactive?.updateSeries([
                {
                    data: this.deactiveSeries[0].data
                }
            ]);
        },

        async fixMonthlyStatisticsDates() {
            this.dateRange = [];
            const date = new Date();
            const year = date.getUTCFullYear(); // Get the full year (4 digits)
            const beforeMonth = String(date.getMonth()).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.dateRange[0] = `${year}-${beforeMonth}-${day}`;
            this.dateRange[1] = `${year}-${currentMonth}-${day}`;
            await this.getCollectorActivity(this.dateRange[0], this.dateRange[1]);
        },

        async getCollectorActivity(from, to) {
            const params = {
                date_start: from,
                date_end: to
            };
            this.reactiveChartOptions.xaxis.categories.length = 0;
            this.deactiveChartOptions.xaxis.categories.length = 0;
            this.reactiveSeries[0].data.length = 0;
            this.deactiveSeries[0].data.length = 0;
            const { data } = await services.collector.activityFindAll({ params });
            await this.refreshChartDetails(data.data);
        },

        async changeRange(dates) {
            await this.getCollectorActivity(formatYMD(dates[0]), formatYMD(dates[1]));
        }
    }
};
</script>

<template>
    <div class="collector">
        <page-title>
            <template #title> {{ $t('titles.collector_activity') }}</template>
        </page-title>
        <div class="row">
            <!--      TASK STATISTICS by TASK      -->
            <div class="col-12">
                <div class="card p-2">
                    <div
                        class="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                    >
                        <h3 class="mb-1">{{ $t('titles.reactive_statistics') }}</h3>
                        <div
                            class="d-flex align-items-center mx-auto mx-sm-0"
                            style="min-width: 250px"
                        >
                            <div class="mr-1">
                                <flat-pickr
                                    v-model="dateRange"
                                    :config="{ mode: 'range' }"
                                    class="form-control mr-1"
                                    style="min-width: 200px"
                                    :placeholder="$t('choose.date')"
                                    @on-close="changeRange"
                                />
                            </div>
                            <b-button
                                @click="fixMonthlyStatisticsDates"
                                variant="outline-primary"
                                size="sm"
                            >
                                <feather-icon icon="RefreshCcwIcon" size="16" />
                            </b-button>
                        </div>
                    </div>
                    <div v-if="reactiveSeries[0].data.length" class="mb-2">
                        <apexchart
                            id="authChartReactive"
                            ref="authChartReactive"
                            height="400"
                            type="bar"
                            :options="reactiveChartOptions"
                            :series="reactiveSeries"
                        ></apexchart>
                    </div>
                    <div class="d-flex justify-content-center justify-content-sm-start">
                        <h3 class="mb-1">{{ $t('titles.deactive_statistics') }}</h3>
                    </div>
                    <div v-if="deactiveSeries[0].data.length">
                        <apexchart
                            id="authChartDeactive"
                            ref="authChartDeactive"
                            height="400"
                            type="bar"
                            :options="deactiveChartOptions"
                            :series="deactiveSeries"
                        ></apexchart>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-2"></div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
