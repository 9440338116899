import api from '@/services/api';

export default {
    state: {
        randomizerPackages: []
    },
    getters: {
        packages: (state) => state.randomizerPackages
    },
    mutations: {
        setPackages(state, data) {
            state.randomizerPackages = data;
        }
    },
    actions: {
        async fetchRandomizerPackages({ commit }) {
            const body = {
                page: 1,
                limit: 1000
            };
            const { data: randomizerPackages } = await api.billingPackages.fetchAllItems(body);
            commit('setPackages', { randomizerPackages });
        }
    }
};
