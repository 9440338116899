var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('translations')))]},proxy:true},(_vm.hasAccess('cities', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.value))+" ")]}},{key:"cell(tags)",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag,index){return _c('span',{key:index},[_vm._v("#"+_vm._s(tag)+", ")])})}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'cities', permission: 'delete' },"delete-handler":_vm.deleteTranslation,"index":index,"item":item,"update-access":{ section: 'cities', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneTranslation(item)}}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.open')))),expression:"`${$t('button.open')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-success"},on:{"click":function($event){return _vm.openViewModal(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.translation') : _vm.$t('add.translation'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.value'))+" [RU]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.value')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.value')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.value.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.value, "ru", $$v)},expression:"dataForModal.value.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.value'))+" [UZ]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.value')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.value')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.value.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.value, "uz", $$v)},expression:"dataForModal.value.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.value'))+" [EN]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.value')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.value')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.value.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.value, "en", $$v)},expression:"dataForModal.value.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.key')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.key'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.key),callback:function ($$v) {_vm.$set(_vm.dataForModal, "key", $$v)},expression:"dataForModal.key"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.tags')))]),_c('v-select',{attrs:{"taggable":"","multiple":"","options":_vm.tagOptions,"label":"name","disabled":_vm.isViewModal},model:{value:(_vm.dataForModal.tags),callback:function ($$v) {_vm.$set(_vm.dataForModal, "tags", $$v)},expression:"dataForModal.tags"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }