<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import { MERCHANT_ID } from '@/constants/names';
import { fixTimeFrame } from '@/util/helper';

export default {
    name: 'AppScenario',
    components: {
        vSelect,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: '',
                description: '',
                days: null,
                prize_id: null,
                type_of_condition: null,
                position: 0,
                info: {
                    uz: null,
                    ru: null,
                    en: null
                }
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            streakVisibility: null,
            streakVisibilityLite: null,
            prizeList: [],
            staticTypeOfConditions: [
                {
                    id: 1,
                    label: 'Streak'
                },
                {
                    id: 2,
                    label: this.$t('notifications')
                },
                {
                    id: 3,
                    label: 'Collect'
                }
            ],
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAll();
                this.getPrizeList();
                this.getStreakVisibilityStatus();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.name')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'prize',
                    label: this.$t('titles.prize')
                },
                {
                    key: 'type_of_condition',
                    label: this.$t('titles.type')
                },
                {
                    key: 'count',
                    label: this.$t('flash_history.count')
                },
                {
                    key: 'days',
                    label: this.$t('titles.days')
                },
                {
                    key: 'updated_at',
                    label: this.$t('titles.updated_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        fixTimeFrame,

        async getStreakVisibilityStatus() {
            try {
                const { data } = await api.scenario.fetchStreakVisibilityStatus({
                    merchant_id: parseInt(MERCHANT_ID)
                });
                this.streakVisibility = data.data.content.visibility;
                this.streakVisibilityLite = data.data.content['visibility_lite'];
            } catch (e) {
                console.error(e);
            }
        },

        async triggerStreakVisibilityStatus() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                content: {
                    visibility: this.streakVisibility,
                    visibility_lite: this.streakVisibilityLite
                }
            };
            try {
                await api.scenario.updateStreakVisibilityStatus(body);
            } catch (e) {
                console.error(e);
            } finally {
                await this.getStreakVisibilityStatus();
            }
        },

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await api.scenario.fetchAllItems(body);
                this.items = data.result;
                this.pagination = data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getPrizeList() {
            const body = {
                page: 1,
                limit: 1000,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.scenarioPrizeApi.fetchAllItems(body);
                this.prizeList = data.result;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            const body = {
                id: item.id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.scenario
                .fetchOneItem(body)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                    this.dataForModal.id = res.id;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.scenario
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(body) {
            await api.scenario
                .updateItem(body)
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.scenario
                .deleteItem({ id: id })
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    body.merchant_id = parseInt(MERCHANT_ID);
                    body.position = parseInt(body.position);

                    if (body.type_of_condition === 2) {
                        body.prize_id = null;
                        body.position = null;
                    }

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        // clearValue() {
        //     console.log('asdasd');
        //     if (this.dataForModal.type_of_condition === 2) {
        //         this.dataForModal.prize_id = null;
        //         this.dataForModal.prize_id = null;
        //     } else if (this.dataForModal.type_of_condition === 1) {
        //         this.dataForModal.position = 0;
        //     }
        // },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('titles.scenario') }}</template>
            <template v-if="hasAccess('users', 'create')" #button>
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center mr-2">
                        <label for="streakVisibility" class="mr-1">Streak</label>
                        <b-form-checkbox
                            id="streakVisibility"
                            v-model="streakVisibility"
                            switch
                            @change="triggerStreakVisibilityStatus"
                        />
                    </div>
                    <div class="d-flex align-items-center mr-2">
                        <label for="streakVisibility" class="mr-1">Lite Streak</label>
                        <b-form-checkbox
                            id="streakVisibilityLite"
                            v-model="streakVisibilityLite"
                            switch
                            @change="triggerStreakVisibilityStatus"
                        />
                    </div>
                </div>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(prize)="{ item }">
                    <span v-if="item.prize">
                        {{ checkLocales(item.prize.name) }}
                    </span>
                </template>

                <template #cell(type_of_condition)="{ item }">
                    <span v-if="item.type_of_condition === 2">
                        {{ $t('notifications') }}
                    </span>
                    <span v-else>Streak</span>
                </template>

                <template #cell(updated_at)="{ item }">
                    {{ fixTimeFrame(item['updated_at']) }}
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'collector', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'collector', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.scenario') : $t('add.scenario')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME    -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.value')"
                            rules="required"
                        >
                            <b-form-group :label="$t('titles.real_title')">
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :disabled="isViewModal"
                                    :placeholder="$t('enter.real_title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION    -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.description')"
                            rules="required"
                        >
                            <b-form-group :label="$t('titles.description')">
                                <b-form-textarea
                                    v-model="dataForModal.description"
                                    :disabled="isViewModal"
                                    :placeholder="$t('enter.description')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    INFO [UZ]    -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.info')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group :label="`${$t('titles.info')} [UZ]`">
                                <b-form-textarea
                                    v-model="dataForModal.info.uz"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.info')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    INFO [RU]    -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.info')} [RU]`"
                            rules="required"
                        >
                            <b-form-group :label="`${$t('titles.info')} [RU]`">
                                <b-form-textarea
                                    v-model="dataForModal.info.ru"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.info')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    INFO [EN]    -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.info')} [EN]`"
                            rules="required"
                        >
                            <b-form-group :label="`${$t('titles.info')} [EN]`">
                                <b-form-textarea
                                    v-model="dataForModal.info.en"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.info')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DAYS    -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.days')"
                            rules="required"
                        >
                            <b-form-group :label="$t('titles.days')">
                                <b-form-input
                                    v-model="dataForModal.days"
                                    :disabled="isViewModal"
                                    :placeholder="$t('enter.days')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     TYPE_OF_CONDITION     -->
                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <b-form-group :label="$t('titles.type')">
                                <v-select
                                    :placeholder="$t('titles.type')"
                                    :disabled="isViewModal"
                                    :options="staticTypeOfConditions"
                                    v-model="dataForModal.type_of_condition"
                                    :reduce="(option) => option.id"
                                    label="label"
                                    :clearable="false"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--     PRIZE     -->
                    <b-col cols="12" v-if="dataForModal.type_of_condition === 1">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.prize')"
                            rules="required"
                        >
                            <b-form-group :label="$t('titles.prize')">
                                <v-select
                                    v-model="dataForModal.prize_id"
                                    :disabled="isViewModal"
                                    :options="prizeList"
                                    :reduce="(option) => option.id"
                                    :placeholder="$t('titles.prize')"
                                    label="name"
                                    :clearable="false"
                                >
                                    <template v-slot:option="option">
                                        {{ checkLocales(option.name) }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ checkLocales(option.name) }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col cols="12" v-if="dataForModal.type_of_condition === 1">
                        <b-form-group :label="$t('titles.position')">
                            <b-form-input
                                v-model="dataForModal.position"
                                :disabled="isViewModal"
                                :placeholder="$t('enter.position')"
                                size="lg"
                                type="number"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
