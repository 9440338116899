import AppUSSDMenu from '@/views/ussd/AppUSSDMenu.vue';
import AppUSSDTexts from '@/views/ussd/AppUSSDTexts.vue';
import AppUSSDStatistics from '@/views/ussd/AppUSSDStatistics.vue';
import AppUSSDNewUserStatistics from '@/views/ussd/AppUSSDNewUserStatistics.vue';
import AppTotalActivityStatistics from '@/views/ussd/AppTotalActivityStatistics.vue';

export default [
    {
        path: '/ussd-menu',
        name: 'ussd-menu',
        component: AppUSSDMenu,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/ussd-texts',
        name: 'ussd-texts',
        component: AppUSSDTexts,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/ussd-total-activity',
        name: 'ussd-total-activity',
        component: AppTotalActivityStatistics,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/ussd-statistics',
        name: 'ussd-statistics',
        component: AppUSSDStatistics,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/ussd-new-user-statistics',
        name: 'ussd-new-user-statistics',
        component: AppUSSDNewUserStatistics,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
