<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import FlatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BButton } from 'bootstrap-vue';
import { statisticsFormatDate } from '@/util/date/calendar.util';

export default {
    name: 'AppSegmentationStatistics',
    components: {
        ProgressLinear,
        FlatPickr,
        apexchart: VueApexCharts,
        BButton
    },
    data() {
        return {
            requestBody: {
                date_start: null,
                date_end: null,
                merchant_id: parseInt(MERCHANT_ID)
            },
            isLoaded: false,
            // series: [],
            // chartOptions: {
            //     chart: {
            //         height: 350,
            //         type: 'line',
            //         dropShadow: {
            //             enabled: true,
            //             color: '#000',
            //             top: 18,
            //             left: 7,
            //             blur: 10,
            //             opacity: 0.2
            //         },
            //         toolbar: {
            //             show: false
            //         },
            //         zoom: false
            //     },
            //     colors: ['#006f62', '#a7204c', '#f87002', '#e17dab', '#0097df'],
            //     dataLabels: {
            //         enabled: true,
            //         formatter: function (value) {
            //             // Example of custom label formatter
            //             return `${formatNumber1(value)}`; // Formats the label to two decimal places
            //         }
            //     },
            //     stroke: {
            //         curve: 'smooth'
            //     },
            //     grid: {
            //         borderColor: '#e7e7e7',
            //         row: {
            //             opacity: 0.1
            //         }
            //     },
            //     markers: {
            //         size: 1
            //     },
            //     xaxis: {
            //         categories: []
            //     },
            //     yaxis: {
            //         labels: {
            //             formatter: function (value) {
            //                 return `${formatNumber1(value)}`;
            //             }
            //         }
            //     },
            //     tooltip: {
            //         enabled: true,
            //         y: {
            //             formatter: function (val) {
            //                 return val;
            //             }
            //         }
            //     },
            //     legend: {
            //         position: 'bottom',
            //         offsetY: 6
            //     }
            // }
            series: [],
            chartOptions: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    id: 'segmentation',
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                dataLabels: {
                    // formatter: function (val) {
                    //     if (val >= 1000000) {
                    //         return (val / 1000000).toFixed(3).replace(/\.?0*$/, '') + 'M';
                    //     } else if (val >= 1000) {
                    //         return (val / 1000).toFixed(1).replace(/\.?0*$/, '') + 'K';
                    //     } else {
                    //         return val.toString();
                    //     }
                    // }
                    formatter: function (number) {
                        if (number >= 1000000) {
                            return (
                                (Math.floor(number / 1000) / 1000)
                                    .toFixed(3)
                                    .replace(/\.?0*$/, '') + 'M'
                            );
                        } else if (number >= 1000) {
                            return (
                                (Math.floor(number / 100) / 10).toFixed(1).replace(/\.?0*$/, '') +
                                'K'
                            );
                        } else {
                            return number.toString();
                        }
                    }
                },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    intersect: false,
                    shared: true,
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (val, opts) {
                            return (
                                val +
                                ' - ' +
                                opts.w.globals.stackedSeriesTotals[opts.dataPointIndex]
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            );
                        }
                    }
                }
            }
        };
    },
    async mounted() {
        await this.fixMonthlyStatisticsDates();
    },
    methods: {
        statisticsFormatDate,
        async fixMonthlyStatisticsDates() {
            // const date = new Date();
            // const year = date.getUTCFullYear(); // Get the full year (4 digits)
            // const beforeMonth = String(date.getMonth()).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            // const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            // const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary

            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.requestBody.date_start = this.statisticsFormatDate(date_start);
            this.requestBody.date_end = this.statisticsFormatDate(date_end);
            await this.getStatistics();
        },

        async getStatistics() {
            this.isLoaded = false;
            this.chartOptions.xaxis.categories.length = 0;
            try {
                const { data } = await api.segmentationApi.fetchStatistics(this.requestBody);
                const responseWithoutNullData = data.data.filter((obj) => obj.data !== null);
                const responseWithoutEventId = responseWithoutNullData.map((item) => {
                    if (!item || !item.data) {
                        delete item.data;
                    }
                    delete item.data.event_id;
                    return { ...item };
                });
                this.chartOptions.xaxis.categories = responseWithoutEventId
                    .reverse()
                    .map((item) => {
                        return item['started_at'].substring(5);
                    });
                this.series = Object.keys(responseWithoutEventId[0].data).map((key) => ({
                    name: key,
                    data: responseWithoutEventId.map((obj) => obj.data[key])
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoaded = true;
            }
        }
    }
};
</script>

<template>
    <div class="statistics">
        <h2 class="mb-2">{{ $t('navigation.statistics') }}</h2>
        <div class="row align-items-end">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="requestBody.date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="requestBody.date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button @click="getStatistics" class="w-100 mt-1 mt-md-0" variant="primary">
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
            <progress-linear v-if="!isLoaded" />
            <div v-else id="chart" class="col-12">
                <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
