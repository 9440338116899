<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
// import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'index',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        // vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            levelsList: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                type: '',
                merchant_id: null,
                is_published: false,
                action_scores: []
            },
            action_scores: [],
            sort: {
                column: 'id',
                direction: 'asc'
            },
            search: '',
            filter: {},
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllItems();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getAllLevels();
    },

    methods: {
        checkLang,

        async getAllItems() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: parseInt(MERCHANT_ID),
                sort: this.sort,
                search: this.search,
                filter: this.filter
            };

            const { data } = await api.newLevelActions.fetchAllItems(body);
            this.items = data.data.list;
            this.pagination = data.data.pagination;

            if (this.pagination.current > this.pagination.totalItem) {
                this.pagination.current = 1;
            }
        },

        async getAllLevels() {
            const body = {
                page: 1,
                limit: 30,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const { data } = await api.newLevel.fetchAllItems(body);
            this.levelsList = data.data.list;
            this.action_scores = data.data.list.map((item) => {
                return {
                    name: item.name,
                    level_id: item.id,
                    score: null
                };
            });
        },

        async getOneItem(item) {
            this.updatingItem = item;
            this.action_scores = null;
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                id: item.id
            };
            await api.newLevelActions
                .fetchOneItem(body)
                .then((response) => {
                    this.isOpenModal = true;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                    };
                    this.dataForModal.action_scores = this.dataForModal.action_scores.map(
                        (item) => {
                            return {
                                id: item.id,
                                name: item.level.name,
                                level_id: item.level_id,
                                score: item.score
                            };
                        }
                    );
                    this.action_scores = this.dataForModal.action_scores;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.newLevelActions
                .createItem(body)
                .then(async () => {
                    await this.getAllItems();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(body) {
            await api.newLevelActions
                .updateItem(body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllItems();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            const params = {
                id: id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.newLevelActions
                .deleteItem(params)
                .then(() => {
                    this.getAllItems();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    body.merchant_id = parseInt(MERCHANT_ID);

                    this.action_scores.map((item) => {
                        delete item.name;
                    });

                    body.action_scores = this.action_scores;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateItem(body, config);
                        } else {
                            await this.createItem(body, config);
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getCities();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        beforeClose() {
            this.action_scores.forEach((item) => {
                item.score = null;
                delete item.id;
            });
            this.uploadImage = null;
            this.onCloseModal();
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.level_actions') }}</template>
            <template v-if="hasAccess('cities', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(name)="{ item }">
                    {{ checkLang(item.name) }}
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="[item.is_published ? 'text-success' : 'text-danger']"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'cities', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.level_actions') : $t('add.level_actions')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--   NAME [UZ]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   NAME [RU]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   NAME [EN]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.type') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="type" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.type"
                                    :disabled="isViewModal || updatingItem"
                                    :placeholder="`${$t('enter.type')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    ACTION SCORES    -->
                    <b-col cols="12" v-for="(value, index) in action_scores" :key="index">
                        <label>
                            {{ $t('titles.action_score') }} | {{ checkLang(value.name) }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="action_scores"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="value.score"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.action_scores')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    IS PUBLISHED    -->
                    <b-col cols="4" class="d-flex justify-content-end mt-2 user-select-none">
                        <b-form-checkbox
                            id="is_published"
                            v-model="dataForModal.is_published"
                            :disabled="isViewModal"
                        />
                        <label for="is_published" class="font-medium-1">
                            {{ $t('titles.is_published') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}
</style>
