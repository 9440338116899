import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import me from './dashboard/me/me.store';
import randomizerPackages from './dashboard/select/randomizer.packages';
import chatStore from '@/store/dashboard/chat/_chat.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        me,
        app,
        appConfig,
        verticalMenu,
        chatStore,
        randomizerPackages
    },
    strict: process.env.DEV
});
