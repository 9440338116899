import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class LampSubscribers extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchSubscribers(body) {
        return this.post('lamp/users/findAll', body);
    }

    fetchFilterFields(id) {
        return this.post('lamp/users/filterFields', { merchant_id: id });
    }

    fetchOneSubscriber(id) {
        return this.post('lamp/users/findOne', { id: id });
    }

    fetchLevel(id) {
        return this.post('lamp/users/levels', { user_id: id });
    }

    fetchActivityLogs(id, body) {
        return this.post('lamp/users/logs', { id, ...body });
    }

    fetchGifts(id, body) {
        return this.post('lamp/users/gifts', { id, ...body });
    }

    fetchTransactions(id, body) {
        return this.post('lamp/users/transactions', { id, ...body });
    }

    fetchWinners(user_id, body) {
        return this.post('lamp/users/winners', { user_id, ...body });
    }
}

export default LampSubscribers;
