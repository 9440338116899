import { axiosInstanceV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class Warehouse extends CoreV2 {
    constructor() {
        super(axiosInstanceV2);
    }

    fetchAllWarehouses(params) {
        return this.post('warehouse/findAll', params);
    }

    fetchOneWarehouse(params) {
        return this.post('warehouse/findOne', params);
    }

    createWarehouse(body) {
        return this.post('warehouse', body);
    }

    updateWarehouse(body) {
        return this.post('warehouse/update', body);
    }

    deleteWarehouse(body) {
        return this.post('warehouse/remove', body);
    }
}

export default Warehouse;
