<script>
import Chip from '@/components/ui/Chips/Chip';
import { BButton } from 'bootstrap-vue';
export default {
    name: 'ChipsWrapper',
    components: { Chip, BButton },
    props: {
        items: Array,
        minLength: {
            type: Number,
            default: 3
        },
        filter: Function
    },
    data: () => ({
        chips: [],
        isAvailable: false
    }),
    computed: {
        alwaysShowingChips() {
            return this.chips.slice(0, this.minLength);
        },
        hiddenChips() {
            return this.chips.slice(this.minLength, this.chips.length);
        },
        showHideBtnText() {
            return this.isAvailable ? this.$t('button.hide') : this.$t('button.show');
        }
    },
    methods: {
        toggleDisplay() {
            this.isAvailable = !this.isAvailable;
        }
    },
    async mounted() {
        if (this.filter) {
            this.chips = await this.filter(this.items);
        }
    }
};
</script>

<template>
    <div class="d-flex flex-wrap align-items-center">
        <chip v-for="(item, index) in alwaysShowingChips" :key="index">
            {{ item }}
        </chip>
        <div class="d-flex flex-wrap" v-if="isAvailable">
            <chip v-for="(item, index) in hiddenChips" :key="index">
                {{ item }}
            </chip>
        </div>
        <b-button v-if="hiddenChips.length" @click="toggleDisplay" size="sm" style="margin: 5px">
            {{ showHideBtnText }}
        </b-button>
    </div>
</template>

<style scoped></style>
