import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class RandomizerCategoriesFlash extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'randomizer/dashboardGf/' : 'randomizer/dashboardAl/';
    }

    fetchAllCategories(params) {
        return this.post(this.dashboardVersion() + 'flashCategory/getAll', params);
    }

    fetchOneCategory(id) {
        return this.get(this.dashboardVersion() + `flashCategory/get/${id}`);
    }

    createCategory(body) {
        return this.post(this.dashboardVersion() + 'flashCategory/create', body);
    }

    updateCategory(id, body) {
        return this.post(this.dashboardVersion() + `flashCategory/update/${id}`, body);
    }

    deleteCategory(id) {
        return this.delete(this.dashboardVersion() + `flashCategory/delete/${id}`);
    }

    fetchAllGroups(params) {
        return this.post(this.dashboardVersion() + 'flashGroup/getAll', params);
    }

    fetchOneGroup(id) {
        return this.get(this.dashboardVersion() + `flashGroup/get/${id}`);
    }

    createGroup(body) {
        return this.post(this.dashboardVersion() + 'flashGroup/create', body);
    }

    updateGroup(id, body) {
        return this.post(this.dashboardVersion() + `flashGroup/update/${id}`, body);
    }

    deleteGroup(id) {
        return this.delete(this.dashboardVersion() + `flashGroup/delete/${id}`);
    }
}

export default RandomizerCategoriesFlash;
