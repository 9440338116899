import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class OtherStatistics extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchSubscriptionEventStatistics(body) {
        return this.post('statistics/subscription-event/overall', body);
    }

    fetchLevelWinningsStatistics(body) {
        return this.post('statistics/level-winnings/overall', body);
    }

    fetchCoinStatistics(body) {
        return this.post('statistics/coin-transactions/overall', body);
    }

    fetchOrderStatistics(body) {
        return this.post('statistics/orders/statuses', body);
    }

    fetchUssdStatistics(body) {
        return this.post('statistics/ussd/actions', body);
    }

    downloadUssdStatistics(body) {
        return this.post('statistics/ussd/excel-actions', body, {
            responseType: 'arraybuffer'
        });
    }

    downloadDistributionStatistics(body) {
        return this.post('statistics/distribution/excel', body, {
            responseType: 'arraybuffer'
        });
    }

    fetchDistributionStatistics(body) {
        return this.post('statistics/distribution/overall', body);
    }

    fetchDistributionStatisticsTotal(body) {
        return this.post('statistics/distribution/total', body);
    }

    fetchFeedbackStatistics(body) {
        return this.post('statistics/feedback/overall', body);
    }

    fetchNewUserUssdStatistics(body) {
        return this.post('statistics/ussd/new-user-actions', body);
    }

    fetchRoadmapStatistics(body) {
        return this.post('statistics/roadmap/overall', body);
    }

    fetchVoteStatistics(body) {
        return this.post('statistics/vote/overall', body);
    }

    downloadFeedbackStatistics(body) {
        return this.post('statistics/feedback/csv', body);
    }
}

export default OtherStatistics;
