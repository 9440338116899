<script>
import { BButton, VBTooltip } from 'bootstrap-vue';

export default {
    name: 'TableActionBtns',
    components: {
        BButton,
        VBTooltip
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        item: Object,
        index: [Number, String],
        deleteHandler: Function,
        showDeleteButton: {
            type: Boolean,
            default: true
        },
        showEditButton: {
            type: Boolean,
            default: true
        },
        deleteAccess: {
            type: Object,
            default: () => ({
                show: true,
                section: '',
                permission: ''
            })
        },
        updateAccess: {
            type: Object,
            default: () => ({
                show: true,
                section: '',
                permission: ''
            })
        },
        disableDeleteButton: {
            type: Boolean,
            default: false
        },
        disableUpdateButton: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    methods: {
        openModal() {
            this.$emit('openModal', this.item);
        },

        deleteItem() {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await this.deleteHandler(
                        this.item.uuid ? this.item.uuid : this.item.id,
                        this.index
                    )
                        .then(() => {
                            // if (res) {
                            this.$swal({
                                icon: 'success',
                                title: this.$t('success.deleted'),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            // }
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'error.smth',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div class="d-flex justify-content-end align-items-center">
        <b-button
            v-if="showEditButton && hasAccess(updateAccess.section, updateAccess.permission)"
            :disabled="disableUpdateButton"
            v-b-tooltip.hover.top="`${$t('button.edit')}`"
            class="btn-icon"
            variant="flat-dark"
            @click="openModal"
        >
            <feather-icon icon="EditIcon" />
        </b-button>

        <b-button
            v-if="
                showDeleteButton &&
                deleteHandler &&
                hasAccess(deleteAccess.section, deleteAccess.permission)
            "
            :disabled="disableDeleteButton"
            v-b-tooltip.hover.top="`${$t('button.delete')}`"
            class="btn-icon"
            variant="flat-danger"
            @click="deleteItem"
        >
            <feather-icon icon="Trash2Icon" />
        </b-button>
    </div>
</template>

<style scoped></style>
