<script>
import VueApexCharts from 'vue-apexcharts';
import { formatNumber } from '@/util/helper';

export default {
    name: 'AppHomeLongChart',
    components: {
        apexchart: VueApexCharts
    },
    props: {
        chartBarColors: {
            type: Array,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        series: {
            type: Array,
            required: true
        },
        dynamicXaxis: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        },
        loaded: {
            type: Boolean,
            required: true
        },
        enableTooltip: {
            type: Boolean,
            default: true
        },
        tooltipText: {
            type: String,
            required: false,
            default: 'titles.sum'
        }
    },
    data() {
        return {
            chartOptions: {
                colors: [],
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        colors: ['#1f42b4']
                    },
                    background: {
                        enabled: true
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false
                    }
                },
                xaxis: {
                    categories: []
                },
                tooltip: {
                    enabled: this.enableTooltip,
                    followCursor: true,
                    intersect: true,

                    custom: (opts) => this.customTooltip(opts)
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                }
            }
        };
    },

    methods: {
        customTooltip(opts) {
            const price = opts.w.config.labels[opts.dataPointIndex];
            const sumTranslation = this.$t(`${this.tooltipText}`);
            return `<div class="p-1">
                <span style="color: black">${sumTranslation}: ${formatNumber(price)}</span>
              </div>`;
        }
    },

    created() {
        this.chartOptions.xaxis.categories = this.dynamicXaxis;
        this.chartOptions.colors = this.chartBarColors;
        this.chartOptions.labels = this.labels;
    }
};
</script>

<template>
    <div>
        <p class="p-1 font-medium-5 font-weight-bold">{{ title }}</p>
        <apexchart height="300" type="bar" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<style scoped lang="scss"></style>
