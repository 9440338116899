<script>
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppAdministration',
    data() {
        return {
            merchantId: parseInt(MERCHANT_ID),
            uzmobileTabs: [
                {
                    value: 'status',
                    label: 'Status',
                    routeName: 'uzmobile-status'
                },
                {
                    value: 'rtStatus',
                    label: 'RT Status',
                    routeName: 'uzmobile-rt-status'
                },
                {
                    value: 'balance',
                    label: 'Balance',
                    routeName: 'uzmobile-balance'
                },
                {
                    value: 'subscriberInfo',
                    label: 'Subscriber Info',
                    routeName: 'uzmobile-subscriber-info'
                },
                {
                    value: 'personalAccounts',
                    label: 'Personal Accounts',
                    routeName: 'uzmobile-personal-accounts'
                },
                {
                    value: 'packageInfo',
                    label: 'Package Info',
                    routeName: 'uzmobile-package-info'
                }
            ],
            ucellTabs: [
                {
                    value: 'status',
                    label: 'Status',
                    routeName: 'ucell-status'
                },
                {
                    value: 'payPeriod',
                    label: 'Pay Period',
                    routeName: 'ucell-pay-period'
                },
                {
                    value: 'balance',
                    label: 'Balance',
                    routeName: 'ucell-balance'
                },
                {
                    value: 'saleDateStatus',
                    label: 'Sale Date Status',
                    routeName: 'ucell-sale-date-status'
                },
                {
                    value: 'packList',
                    label: 'Pack List',
                    routeName: 'ucell-pack-list'
                }
            ]
        };
    },

    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },

    async created() {
        await this.detectRoute();
    },

    methods: {
        async detectRoute() {
            if (this.merchantId === 1) {
                this.$router.push({ name: 'ucell-status' }).catch(() => {});
            } else {
                this.$router.push({ name: 'uzmobile-status' }).catch(() => {});
            }
        },

        isActive(route) {
            return this.$route.name === route.routeName;
        }
    }
};
</script>

<template>
    <div class="administration">
        <!--    TABS    -->
        <div>
            <div v-if="merchantId === 1" class="nav nav-tabs flex-nowrap overflow-x-scroll">
                <router-link
                    v-for="(route, index) in ucellTabs"
                    :key="index"
                    :to="{ name: route.routeName }"
                    active-class="active"
                    class="nav-link"
                    :class="{ active: isActive(route) }"
                    style="min-width: fit-content"
                >
                    {{ route.label }}
                </router-link>
            </div>

            <div v-else class="nav nav-tabs flex-nowrap overflow-x-scroll">
                <router-link
                    v-for="(route, index) in uzmobileTabs"
                    :key="index"
                    :to="{ name: route.routeName }"
                    active-class="active"
                    class="nav-link"
                    :class="currentRouteName === route.routeName ? 'active' : ''"
                    style="min-width: fit-content"
                >
                    {{ route.label }}
                </router-link>
            </div>
        </div>

        <router-view />
    </div>
</template>

<style scoped lang="scss">
.nav-tabs::-webkit-scrollbar {
    display: none;
}
</style>
