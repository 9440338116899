import { getLocalVar } from '@/util/localstorage-helper';

export const NOTIFY = {
    type: {
        success: 'success',
        error: 'error'
    }
};

export let MERCHANT_ID = getLocalVar('merchant') || 2;

export function triggerMerchant(merchant) {
    MERCHANT_ID = merchant;
}

export const ROOT = 'root';

export const ROUTE = '$route';

export const ROUTER = '$router';

export const ACTIVE_MERCHANT = 'cd70uamc4m';
