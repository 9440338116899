<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'index',
    components: { ProgressLinear },
    data() {
        return {
            tabs: [
                {
                    label: this.$t('titles.manual_notification_one'),
                    routeName: 'notification-manual-one'
                },
                {
                    label: this.$t('titles.manual_notification_all'),
                    routeName: 'notification-manual-all'
                },
                {
                    label: this.$t('titles.manual_notification_segmentation'),
                    routeName: 'notification-manual-segmentation'
                }
            ],
            types: ['notification', 'users', 'segment'],
            processData: [],
            isRequestPending: false,
            requestingInterval: null
        };
    },

    async mounted() {
        await this.checkStatus();
        this.requestingInterval = setInterval(async () => {
            await this.checkStatus();
        }, 30000);
    },

    methods: {
        isActive(route) {
            return this.$route.name === route.routeName;
        },

        async checkStatus() {
            this.processData = [];
            this.isRequestPending = true;
            for (const item of this.types) {
                const body = {
                    id: 1,
                    merchant_id: parseInt(MERCHANT_ID),
                    type: item
                };
                try {
                    const { data } = await api.notificationManualApi.checkStatusOfProcess(body);
                    this.processData.push(data.result);
                } catch (e) {
                    console.error(e);
                } finally {
                    this.isRequestPending = false;
                }
            }
        },

        toggleNotificationProcess(status, type) {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                type: type
            };
            if (status) {
                try {
                    api.notificationManualApi.pauseNotificationProcess(body);
                } catch (e) {
                    console.error(e);
                } finally {
                    setTimeout(() => {
                        this.checkStatus();
                    }, 500);
                }
            } else {
                try {
                    api.notificationManualApi.continueNotificationProcess(body);
                } catch (e) {
                    console.error(e);
                } finally {
                    setTimeout(() => {
                        this.checkStatus();
                    }, 500);
                }
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.requestingInterval);
        this.requestingInterval = null;

        next();
    }
};
</script>

<template>
    <div class="manual">
        <progress-linear v-if="isRequestPending" />
        <div class="process-statuses row" v-else>
            <div class="col-12 col-md-4" v-for="(item, index) in processData" :key="index">
                <div class="card p-1">
                    <h3 class="text-center" v-if="item.name === 'notification'">
                        {{ $t('titles.notification_from_services') }}
                    </h3>

                    <h3 class="text-center" v-else-if="item.name === 'users'">
                        {{ $t('titles.manual_notification_all') }}
                    </h3>

                    <h3 class="text-center" v-else>
                        {{ $t('titles.manual_notification_segmentation') }}
                    </h3>
                    <span>
                        <var> {{ $t('titles.status') }} </var>:
                        <feather-icon
                            :icon="item.status ? 'CheckIcon' : 'XIcon'"
                            :class="item.status ? 'text-success' : 'text-danger'"
                            size="20"
                        />
                    </span>
                    <span>
                        <var>{{ $t('titles.active') }}</var> :
                        {{ item['jobsCount'].active }}
                    </span>
                    <span>
                        <var>{{ $t('notification_statuses.waiting') }}</var> :
                        {{ item['jobsCount'].waiting }}
                    </span>
                    <span>
                        <var>{{ $t('titles.is_completed') }}</var> :
                        {{ item['jobsCount'].completed }}
                    </span>
                    <span>
                        <var>{{ $t('titles.fail') }}</var> :
                        {{ item['jobsCount'].failed }}
                    </span>
                    <span>
                        <var>{{ $t('titles.delayed') }}</var> :
                        {{ item['jobsCount']['delayed'] }}
                    </span>
                    <span>
                        <var>{{ $t('titles.paused') }}</var> :
                        {{ item['jobsCount'].paused }}
                    </span>
                    <b-button
                        @click="toggleNotificationProcess(item.status, item.name)"
                        :variant="!item.status ? 'outline-success' : 'outline-danger'"
                        class="mt-1"
                    >
                        <feather-icon
                            :icon="!item.status ? 'PlayIcon' : 'PauseIcon'"
                            :class="!item.status ? 'text-success' : 'text-danger'"
                            size="20"
                        />
                    </b-button>
                </div>
            </div>
        </div>
        <div class="nav nav-tabs flex-nowrap overflow-x-scroll">
            <router-link
                v-for="(route, index) in tabs"
                :key="index"
                :to="{ name: route.routeName }"
                active-class="active"
                class="nav-link"
                :class="{ active: isActive(route) }"
                style="min-width: fit-content"
            >
                {{ route.label }}
            </router-link>
        </div>
        <router-view />
    </div>
</template>

<style scoped lang="scss">
.nav-tabs::-webkit-scrollbar {
    display: none;
}
</style>
