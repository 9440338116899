<script>
export default {
    name: 'AppDistributionStatistics',
    data() {
        return {
            staticTabs: [
                {
                    value: 'all',
                    label: 'All',
                    routeName: 'set-statistics-all',
                    routePath: '/statistics/set/all'
                },
                {
                    value: 'new',
                    label: 'New',
                    routeName: 'set-statistics-new',
                    routePath: '/statistics/set/new'
                },
                {
                    value: 'fresh',
                    label: 'Fresh',
                    routeName: 'set-statistics-fresh',
                    routePath: '/statistics/set/fresh'
                }
            ]
        };
    },

    beforeCreate() {
        if (this.$route.name === 'set-statistics') {
            this.$router.push({ path: '/statistics/set/all' });
        }
    },

    methods: {
        isActive(route) {
            return this.$route.name === route.routeName;
        }
    }
};
</script>

<template>
    <div class="distribution">
        <div class="nav nav-tabs flex-nowrap">
            <router-link
                v-for="(route, index) in staticTabs"
                :key="index"
                :to="route.routePath"
                active-class="active"
                class="nav-link"
                :class="{ active: isActive(route) }"
                style="min-width: fit-content"
            >
                {{ route.label }}
            </router-link>
        </div>
        <router-view />
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
