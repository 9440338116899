<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BFormGroup, BFormInput, BModal, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import services from '@/services';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppControl',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                host: '',
                port: '',
                token: ''
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            loadingImage: false
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'host',
                    label: this.$t('titles.host')
                },
                {
                    key: 'port',
                    label: this.$t('titles.port')
                },
                {
                    key: 'token',
                    label: this.$t('titles.token')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async created() {
        await this.kubeServersFindAll();
    },

    methods: {
        checkLang,

        async kubeServersFindAll() {
            try {
                const rsp = await api.servers.findAll();
                this.items = rsp.data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async kubeServersFindOne(item) {
            this.updatingItem = item;
            await api.servers
                .findOne(item.id)
                .then((response) => {
                    console.log(response, 'res');
                    this.isOpenModal = true;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createKubeServer(body) {
            await api.servers
                .create(body)
                .then(async () => {
                    await this.kubeServersFindAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateKubeServer(body) {
            await api.servers
                .update(body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.kubeServersFindAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteKubeServer(id) {
            await api.servers
                .deleteKubeServer(id)
                .then(() => {
                    this.kubeServersFindAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateKubeServer(body, config);
                        } else {
                            await this.createKubeServer(body, config);
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.kube-servers') }}</template>
            <template v-if="hasAccess('servers', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(upload)="{ item }">
                    <img
                        class="table-img"
                        v-if="item.upload || item.upload.path"
                        :src="item.upload.path"
                        alt=""
                    />
                    <span v-else>No Photo</span>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="[item.is_published ? 'text-primary' : 'text-danger']"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'cities', permission: 'delete' }"
                            :delete-handler="deleteKubeServer"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="kubeServersFindOne(item)"
                        />
                        <!--                        <b-button-->
                        <!--                            v-b-tooltip.hover.top="`${$t('button.open')}`"-->
                        <!--                            class="btn-icon"-->
                        <!--                            variant="flat-success"-->
                        <!--                            @click="openViewModal(item)"-->
                        <!--                        >-->
                        <!--                            <feather-icon icon="EyeIcon" />-->
                        <!--                        </b-button>-->
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.kube_server') : $t('add.kube_server')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--   HOST   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.host') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.host')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.host"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.host')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   PORT   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.port') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.port')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.port"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.port')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   TOKEN   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.token') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.token')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.token"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.token')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--        &lt;!&ndash;  PAGINATION  &ndash;&gt;-->
        <!--        <b-row>-->
        <!--            <b-col class="offset-3" cols="9">-->
        <!--                <b-pagination-->
        <!--                    v-if="showPagination"-->
        <!--                    v-model="pagination.current"-->
        <!--                    :per-page="pagination.perPage"-->
        <!--                    :total-rows="pagination.totalItem"-->
        <!--                    class="float-right mr-4 mb-0"-->
        <!--                    first-number-->
        <!--                    last-number-->
        <!--                />-->
        <!--            </b-col>-->
        <!--        </b-row>-->
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}
</style>
