<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BCol,
    // BButton,
    // BFormCheckbox,
    // BFormGroup,
    // BModal,
    BFormInput,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import services from '@/services';
import vSelect from 'vue-select';
import { isObject } from '@/util/inspect.util';

export default {
    name: 'AppCollector',
    components: {
        vSelect,
        ProgressLinear,
        PageTitle,
        BTable,
        // BButton,
        // BModal,
        // BFormCheckbox,
        // BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BPagination
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.fetchInterval);
        this.fetchInterval = null;

        next();
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            fetchTrigger: true,
            fetchInterval: null,
            items: [],
            search: {
                phone: null,
                tariff: '',
                service_name: ''
            },
            sort: {
                column: 'id',
                order: 'down'
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 30
            },
            orderList: ['up', 'down'],
            columnList: [
                'id',
                'create_at',
                'service_name',
                'balance',
                'lc_code',
                'dagon',
                'is_rc_package'
            ],
            requestPending: true
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getCollectorList();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        fetchTrigger: {
            handler() {
                if (this.fetchTrigger) {
                    this.fetchInterval = setInterval(() => {
                        this.getCollectorList();
                    }, 3000);
                } else {
                    clearInterval(this.fetchInterval);
                    this.fetchInterval = null;
                }
            },
            immediate: true
        }
    },

    async created() {
        // await this.getCollectorList();
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'phone',
                    label: this.$t('titles.phone'),
                    stickyColumn: true,
                    variant: 'dark'
                },
                {
                    key: 'tariff',
                    label: this.$t('titles.tariff')
                },
                {
                    key: 'balance',
                    label: this.$t('titles.balance')
                },
                {
                    key: 'service_name',
                    label: this.$t('titles.service_name')
                },
                {
                    key: 'addition.PAY_TO',
                    label: 'pay to'
                },
                {
                    key: 'lc_code',
                    label: 'lc code'
                },
                {
                    key: 'dagon',
                    label: 'dagon'
                },
                {
                    key: 'is_rc_package',
                    label: 'RC PACKAGE'
                },
                {
                    key: 'timing',
                    label: 'timing'
                },
                {
                    key: 'create_at',
                    label: this.$t('titles.created_at')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        }
    },

    methods: {
        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        triggerFetching() {
            this.fetchTrigger = !this.fetchTrigger;

            if (this.fetchTrigger) {
                this.getCollectorList();
            }
        },

        applySearch() {
            this.fetchTrigger = false;
            this.getCollectorList({ search: this.search });
        },

        async getCollectorList({ search = null } = { search: null }) {
            this.requestPending = true;
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                sort: this.sort,
                search: {
                    phone: null,
                    tariff: '',
                    service_name: ''
                }
            };

            if (isObject(search)) {
                body.search.phone = search.phone?.trim();
                body.search.tariff = search.tariff?.trim();
                body.search.service_name = search.service_name?.trim();
            }

            await services.collector
                .findAll(body)
                .then((response) => {
                    this.items = response.data.data.list;
                    this.pagination = response.data.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.requestPending = false;
                });
        }
    }
};
</script>

<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <page-title>
                <template #title> {{ $t('titles.collector') }}</template>
            </page-title>
            <b-button
                :variant="!fetchTrigger ? 'success' : 'danger'"
                @click="triggerFetching"
                class="trigger-button"
            >
                <FeatherIcon :icon="!fetchTrigger ? 'PlayIcon' : 'PauseIcon'" size="20" />
            </b-button>
        </div>
        <div
            class="d-flex row flex-wrap flex-column flex-sm-row justify-content-between justify-content-lg-start mb-2"
        >
            <div
                class="col-12 col-md-9 col-lg-7 px-0 d-flex flex-column flex-sm-row flex-wrap align-items-center align-items-lg-end"
            >
                <div class="col-12 col-sm-4 col-md-4 mb-1 mb-lg-0">
                    <b-form-input
                        v-model="search.phone"
                        id="phone"
                        :placeholder="$t('titles.phone')"
                    />
                </div>
                <div class="col-12 col-sm-4 col-md-4 mb-1 mb-lg-0">
                    <b-form-input
                        v-model="search.tariff"
                        id="tariff"
                        :placeholder="$t('titles.tariff')"
                    />
                </div>
                <div class="col-12 col-sm-4 col-md-4 mb-1 mb-lg-0">
                    <b-form-input
                        v-model="search.service_name"
                        id="service"
                        :placeholder="$t('titles.service_name')"
                    />
                </div>
            </div>
            <div
                class="d-flex align-items-end col-12 col-sm-4 col-md-3 col-lg-1 offset-sm-4 offset-md-0 mb-1 mb-lg-0"
            >
                <b-button class="w-100 px-lg-0" @click="applySearch" variant="primary">
                    <feather-icon icon="SearchIcon" size="20" />
                </b-button>
            </div>
            <div class="col-12 col-lg-4 px-0 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="column">Column</label>
                    <v-select
                        id="column"
                        v-model="sort.column"
                        :options="columnList"
                        append-to-body
                        style="width: 180px"
                        @input="getCollectorList"
                        :clearable="false"
                        class="w-100"
                    />
                </div>
                <div class="col-12 col-sm-6">
                    <label for="order">Order</label>
                    <v-select
                        id="order"
                        v-model="sort.order"
                        :options="orderList"
                        append-to-body
                        style="width: 180px"
                        @input="getCollectorList"
                        :clearable="false"
                        class="w-100"
                    />
                </div>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                :fields="fields"
                :items="items"
                :responsive="true"
                class="news__table"
                striped
                outlined
                borderless
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(phone)="{ item }">
                    <span class="text-white fw-700">{{ item.phone }}</span>
                </template>

                <!--                <template #cell(service_name)="{ item }">-->
                <!--                    {{ $t(`service_name.${item.service_name}`) }}-->
                <!--                </template>-->

                <template #cell(dagon)="{ item }">
                    <feather-icon
                        :class="[item.dagon ? 'text-success' : 'text-danger']"
                        :icon="item.dagon ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_rc_package)="{ item }">
                    <feather-icon
                        :class="[item.is_rc_package ? 'text-success' : 'text-danger']"
                        :icon="item.is_rc_package ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="!fetchTrigger"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-5"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.fw-700 {
    font-weight: 700;
}

@media only screen and (max-width: 575px) {
    .trigger-button {
        position: fixed;
        z-index: 1000;
        top: 95px;
        right: 28px;
    }
}

@media only screen and (min-width: 576px) {
    .trigger-button {
        position: fixed;
        z-index: 1000;
        top: 100px;
        right: 28px;
    }
}
</style>
