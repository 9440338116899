import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class PaynetServiceV2 extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllTransaction(body) {
        return this.post('v2/paynet/findAll', body);
    }

    fetchBalance(body) {
        return this.post('v2/paynet/info', body);
    }

    setPaynet(body) {
        return this.post('v2/paynet', body);
    }

    fetchServicesList(body) {
        return this.post('v2/paynet/services', body);
    }
}

export default PaynetServiceV2;
