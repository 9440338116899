<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import { isArray, isObject } from '@/util/inspect.util';

export default {
    name: 'index',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                upload_id: null,
                images: [],
                level_id: '',
                probability: null,
                type: '',
                value: '',
                qty: null,
                draft_count: null,
                has_draft: true,
                merchant_id: parseInt(MERCHANT_ID),
                is_published: false,
                is_unlimited: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            packageList: [],
            typesList: ['coin', 'paynet', 'voice', 'internet', 'product'],
            sort: {
                column: 'id',
                direction: 'desc'
            },
            search: '',
            filter: {
                type: [],
                level_id: []
            },
            levelList: [],
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAllItems();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.images = [];
            if (isArray(image)) {
                this.dataForModal.images = image;
            } else if (isObject(image)) {
                this.dataForModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title'),
                    stickyColumn: true,
                    variant: 'dark'
                },
                {
                    key: 'level',
                    label: this.$t('titles.level')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'value',
                    label: this.$t('titles.value')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'probability',
                    label: this.$t('titles.probability')
                },
                {
                    key: 'draft_count',
                    label: this.$t('titles.draft_count')
                },
                {
                    key: 'upload',
                    label: this.$t('titles.image')
                },
                {
                    key: 'has_draft',
                    label: this.$t('titles.has_draft')
                },
                {
                    key: 'is_unlimited',
                    label: this.$t('titles.is_unlimited')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getAllLevels();
        await this.getAllPackage();
    },

    methods: {
        checkLang,

        async getAllLevels() {
            const body = {
                page: 1,
                limit: 20,
                merchant_id: parseInt(MERCHANT_ID)
            };

            const { data } = await api.newLevel.fetchAllItems(body);
            this.levelList = data.data.list;
        },

        async getAllPackage() {
            const body = {
                page: 1,
                limit: 200
            };
            const { data } = await api.billingPackages.fetchAllItems(body);
            this.packageList = data.data.list;
        },

        async getAllItems() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                page: this.pagination.current,
                limit: this.pagination.perPage,
                sort: this.sort,
                search: this.search,
                filters: {
                    level_id: this.filter.level_id,
                    type: this.filter.type
                }
            };

            const { data } = await api.newLevelPrize.fetchAllItems(body);
            this.items = data.data.list;
            this.pagination = data.data.pagination;

            if (this.pagination.current > this.pagination.totalItem) {
                this.pagination.current = 1;
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            const body = {
                id: item.id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.newLevelPrize
                .fetchOneItem(body)
                .then((response) => {
                    this.isOpenModal = true;
                    const image = response.data.data.upload;
                    this.dataForModal = {
                        ...this.dataForModal,
                        ...response.data.data,
                        level: {
                            ...response.data.data.level,
                            name: checkLang(response.data.data.level.name)
                        },
                        level_id: response.data.data.level.id
                    };
                    this.uploadImage = {
                        id: image.id,
                        name: image.name,
                        size: image.size,
                        type: 'image/jpg',
                        url: image.path,
                        src: image.path
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.newLevelPrize
                .createItem(body)
                .then(async () => {
                    await this.getAllItems();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(body) {
            await api.newLevelPrize
                .updateItem(body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAllItems();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            const params = {
                id: id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            await api.newLevelPrize
                .deleteItem(params)
                .then(() => {
                    this.getAllItems();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    console.log(this.uploadImage);

                    body.upload_id = this.dataForModal.images[0].id;
                    delete body.images;
                    body.merchant_id = parseInt(MERCHANT_ID);
                    body.value = body.value.toString();

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateItem(body, config);
                        } else {
                            await this.createItem(body, config);
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        resetTheFilter() {
            this.filter.level_id = [];
            this.filter.type = [];
            this.getAllItems();
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getCities();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        openBannerModal(item) {
            const image = item.upload;
            if (image) {
                this.uploadImage = {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                };
            }

            this.openModalToUpdate(item);
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        clearValueInput() {
            this.dataForModal.value = null;
        },

        disableQtyAndEqualsToZero(val) {
            if (val) {
                this.dataForModal.qty = 0;
            } else {
                this.dataForModal.qty = null;
            }
        },

        disableDraftAndEqualsToZero(val) {
            if (!val) {
                this.dataForModal.draft_count = 0;
            } else {
                this.dataForModal.draft_count = null;
            }
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('level_prizes', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>{{ $t('titles.level_prize') }}</template>
            <template v-if="hasAccess('cities', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--    FILTER    -->
        <div
            class="d-flex row flex-wrap flex-column flex-sm-row justify-content-between justify-content-lg-start mb-2"
        >
            <div
                class="col-12 px-0 d-flex flex-column flex-sm-row flex-wrap align-items-center align-items-lg-end"
            >
                <div class="col-12 col-sm-4 col-md-4 mb-1 mb-xl-0">
                    <v-select
                        id="typeOptions"
                        v-model="filter.type"
                        :clearable="true"
                        :placeholder="$t('choose.type')"
                        :options="typesList"
                        label="name"
                        :multiple="true"
                    />
                </div>
                <div class="col-12 col-sm-4 col-md-4 mb-1 mb-xl-0">
                    <v-select
                        id="levelOptions"
                        v-model="filter.level_id"
                        :clearable="true"
                        :placeholder="$t('choose.level')"
                        :options="levelList"
                        :reduce="(option) => option.id"
                        label="name"
                        :multiple="true"
                    >
                        <template v-slot:option="option">
                            {{ checkLang(option.name) }}
                        </template>

                        <template v-slot:selected-option="option">
                            {{ checkLang(option.name) }}
                        </template>
                    </v-select>
                </div>
                <div
                    class="d-flex align-items-end justify-content-center col-12 col-sm-4 col-md-4 offset-md-0 mb-1 mb-xl-0"
                >
                    <b-button class="mr-1" @click="getAllItems" variant="primary">
                        <feather-icon icon="SearchIcon" size="20" />
                    </b-button>
                    <b-button @click="resetTheFilter" variant="danger">
                        <feather-icon icon="XIcon" size="20" />
                    </b-button>
                </div>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(name)="{ item }">
                    {{ checkLang(item.name) }}
                </template>

                <template #cell(level)="{ item }">
                    {{ checkLang(item.level.name) }}
                </template>

                <template #cell(upload)="{ item }">
                    <div>
                        <img class="table-img" v-if="item.upload" :src="item.upload.path" alt="" />
                        <span v-else>No Photo</span>
                    </div>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="[item.is_published ? 'text-success' : 'text-danger']"
                        :icon="item.is_published ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(is_unlimited)="{ item }">
                    <feather-icon
                        :class="[item.is_unlimited ? 'text-success' : 'text-danger']"
                        :icon="item.is_unlimited ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(has_draft)="{ item }">
                    <feather-icon
                        :class="[item.has_draft ? 'text-success' : 'text-danger']"
                        :icon="item.has_draft ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end">
                        <table-action-btns
                            :delete-access="{ section: 'cities', permission: 'delete' }"
                            :delete-handler="deleteItem"
                            :index="index"
                            :item="item"
                            :update-access="{ section: 'cities', permission: 'update' }"
                            @openModal="getOneItem(item)"
                        />
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="isViewModal"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.level_prize') : $t('add.level_prize')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--   NAME [UZ]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [UZ]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   NAME [RU]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [RU]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--   NAME [EN]   -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} [EN]</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.type') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="type" rules="required">
                            <v-select
                                id="type"
                                v-model="dataForModal.type"
                                :disabled="isViewModal"
                                :options="typesList"
                                :placeholder="$t('choose.type')"
                                label="name"
                                :clearable="false"
                                :searchable="false"
                                @input="clearValueInput"
                            />
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    VALUE    -->
                    <b-col
                        cols="4"
                        v-if="dataForModal.type === 'internet' || dataForModal.type === 'voice'"
                    >
                        <label> {{ $t('titles.value') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="value" rules="required">
                            <b-form-group>
                                <v-select
                                    id="value"
                                    v-model="dataForModal.value"
                                    :disabled="isViewModal"
                                    :options="packageList"
                                    :reduce="(option) => option.id"
                                    :placeholder="$t('choose.value')"
                                    label="type_name"
                                    :searchable="false"
                                    :clearable="true"
                                >
                                    <template v-slot:option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ option.type_name + ' ' + option.qty }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <b-col cols="4" v-else>
                        <label> {{ $t('titles.value') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="value" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.value"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.value')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    QTY    -->
                    <b-col cols="4">
                        <label :class="dataForModal.is_unlimited ? 'text-secondary' : ''">
                            {{ $t('titles.qty') }}
                        </label>
                        <ValidationProvider v-slot="{ errors }" name="qty" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.qty"
                                    :disabled="isViewModal || dataForModal.is_unlimited"
                                    :placeholder="`${$t('enter.qty')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    DRAFT COUNT    -->
                    <b-col cols="4">
                        <label :class="dataForModal.has_draft ? 'text-secondary' : ''">
                            {{ $t('titles.draft_count') }}
                        </label>
                        <ValidationProvider v-slot="{ errors }" name="draft_count" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.draft_count"
                                    :disabled="isViewModal || !dataForModal.has_draft"
                                    :placeholder="`${$t('enter.draft_count')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--    PROBABILITY    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.probability') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="probability" rules="required">
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.probability"
                                    :disabled="isViewModal"
                                    :placeholder="`${$t('enter.probability')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <!--     LEVEL ID     -->
                    <b-col cols="4">
                        <label> {{ $t('titles.level') }} </label>
                        <ValidationProvider v-slot="{ errors }" name="level" rules="required">
                            <b-form-group>
                                <v-select
                                    id="level"
                                    v-model="dataForModal.level_id"
                                    :disabled="isViewModal"
                                    :options="levelList"
                                    :reduce="(option) => option.id"
                                    :placeholder="$t('choose.level')"
                                    label="name"
                                    size=""
                                    :searchable="false"
                                    :clearable="false"
                                >
                                    <template v-slot:option="option">
                                        {{ checkLang(option.name) }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ checkLang(option.name) }}
                                    </template>
                                </v-select>
                            </b-form-group>
                            <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </b-col>

                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="uploadImage"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_image')"
                                    :maxFiles="14"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    class="float-right mt-1"
                                    @click="getImageId()"
                                >
                                    <span v-if="!imageUploaded">
                                        {{ $t('vue_file_agent.upload_image') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('vue_file_agent.image_uploaded') }}
                                    </span>
                                </b-button>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>

                    <!--    IS UNLIMITED    -->
                    <b-col cols="4" class="d-flex justify-content-center mt-2 user-select-none">
                        <b-form-checkbox
                            @change="disableQtyAndEqualsToZero(dataForModal.is_unlimited)"
                            id="is_unlimited"
                            v-model="dataForModal.is_unlimited"
                            :disabled="isViewModal"
                        />
                        <label for="is_unlimited" class="font-medium-1">
                            {{ $t('titles.is_unlimited') }}
                        </label>
                    </b-col>

                    <!--    HAS DRAFT    -->
                    <b-col cols="4" class="d-flex justify-content-center mt-2 user-select-none">
                        <b-form-checkbox
                            @change="disableDraftAndEqualsToZero(dataForModal.has_draft)"
                            id="has_draft"
                            v-model="dataForModal.has_draft"
                            :disabled="isViewModal"
                        />
                        <label for="has_draft" class="font-medium-1">
                            {{ $t('titles.has_draft') }}
                        </label>
                    </b-col>

                    <!--    IS PUBLISHED    -->
                    <b-col cols="4" class="d-flex justify-content-center mt-2 user-select-none">
                        <b-form-checkbox
                            id="is_published"
                            v-model="dataForModal.is_published"
                            :disabled="isViewModal"
                        />
                        <label for="is_published" class="font-medium-1">
                            {{ $t('titles.is_published') }}
                        </label>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}
</style>
