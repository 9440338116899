<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BCollapse, BFormInput, BPagination, BRow, BTable, VBToggle } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import FlatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';

export default {
    name: 'ViewHistoryPurchases',
    components: {
        vSelect,
        BFormInput,
        ProgressLinear,
        PageTitle,
        FlatPickr,
        BTable,
        BCol,
        BRow,
        BPagination,
        BCollapse
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.fetchInterval);
        this.fetchInterval = null;

        next();
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            fetchTrigger: true,
            fetchInterval: null,
            initializeGetAll: false,
            items: [],
            purchaseList: [],
            productList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 30
            },
            body: {
                page: 1,
                limit: 30,
                params: {
                    date_start: null,
                    date_end: null,
                    phone: null,
                    user_id: null,
                    purchase_id: null,
                    product_id: null,
                    product_type: null
                }
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            filterOpened: false,
            showCollapse: false,
            staticTypesList: [
                {
                    name: {
                        uz: 'Paket',
                        ru: 'Пакет',
                        en: 'Package'
                    },
                    value: 0
                },
                {
                    name: {
                        uz: 'Fit-Coin',
                        ru: 'Fit-Coin',
                        en: 'Fit-Coin'
                    },
                    value: 1
                },
                {
                    name: {
                        uz: 'Chipta',
                        ru: 'Билет',
                        en: 'Ticket'
                    },
                    value: 2
                },
                {
                    name: {
                        uz: 'Paynet',
                        ru: 'Paynet',
                        en: 'Paynet'
                    },
                    value: 3
                },
                {
                    name: {
                        uz: 'Level',
                        ru: 'Левел',
                        en: 'Level'
                    },
                    value: 4
                },
                {
                    name: {
                        uz: "Sovg'a",
                        ru: 'Приз',
                        en: 'Prize'
                    },
                    value: 5
                }
            ]
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                if (this.initializeGetAll) {
                    this.getAll();
                }
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        fetchTrigger: {
            handler() {
                if (this.fetchTrigger) {
                    this.fetchInterval = setInterval(() => {
                        this.getAll();
                    }, 5000);
                } else {
                    clearInterval(this.fetchInterval);
                    this.fetchInterval = null;
                }
            },
            immediate: true
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'user_id',
                    label: this.$t('titles.user_id')
                },
                {
                    key: 'phone',
                    label: this.$t('titles.phone_number')
                },
                {
                    key: 'purchase',
                    label: this.$t('titles.purchase')
                },
                {
                    key: 'product_name',
                    label: this.$t('titles.product')
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at')
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        await this.getDatesForParam();
        await this.checkWindowWidth();
        await this.getAll();
        await this.getProductList();
        await this.getPurchaseList();
    },

    methods: {
        checkLocales,

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                params: {
                    date_start: this.body.params.date_start,
                    date_end: this.body.params.date_end,
                    product_id: this.body.params.product_id,
                    purchase_id: this.body.params.purchase_id,
                    product_type: this.body.params.product_type,
                    phone: parseInt(this.body.params.phone),
                    user_id: parseInt(this.body.params.user_id)
                }
            };
            try {
                const { data } = await api.purchases.fetchPurchaseHistory(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getPurchaseList() {
            const body = {
                page: 1,
                limit: 100
            };

            try {
                const { data } = await api.purchases.fetchAllItems(body);
                this.purchaseList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getProductList() {
            const body = {
                page: 1,
                limit: 10000
            };

            try {
                const { data } = await api.infinityProducts.sortedAllItems(body);
                this.productList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getDatesForParam() {
            const date = new Date();
            const currentYear = date.getUTCFullYear(); // Get the full year (4 digits)
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const currentDay = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.body.params.date_start = `${currentYear}-${currentMonth}-${currentDay}`;
            this.body.params.date_end = `${currentYear}-${currentMonth}-${currentDay}`;
            this.initializeGetAll = true;
        },

        triggerFetching() {
            this.fetchTrigger = !this.fetchTrigger;

            if (this.fetchTrigger) {
                this.getAll();
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        resetRequest() {
            this.body = {
                page: 1,
                limit: 20,
                params: {
                    date_start: null,
                    date_end: null,
                    phone: null,
                    user_id: null,
                    purchase_id: null,
                    product_id: null
                }
            };
            this.getDatesForParam();
            this.getAll();
        },

        checkWindowWidth() {
            this.showCollapse = window.innerWidth <= 768;
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <!--    v-if="hasAccess('coin_award','view')"-->
            <page-title>
                <template #title> {{ $t('titles.purchase_history') }} [VIP]</template>
            </page-title>

            <b-button
                :variant="!fetchTrigger ? 'success' : 'danger'"
                class="trigger-button"
                @click="triggerFetching"
            >
                <FeatherIcon :icon="!fetchTrigger ? 'PlayIcon' : 'PauseIcon'" size="20" />
            </b-button>
        </div>

        <!--    FILTER    -->
        <div v-if="showCollapse">
            <b-button
                v-b-toggle.collapse-1
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-block w-100 mb-2"
                variant="outline-primary"
                @click="filterOpened = !filterOpened"
            >
                <feather-icon :icon="filterOpened ? 'ArrowUpIcon' : 'ArrowDownIcon'" />
            </b-button>

            <b-collapse id="collapse-1" class="mt-2 mb-2">
                <b-card class="border mb-0 p-1">
                    <div class="row mb-1">
                        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                            <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                            <flat-pickr
                                id="start_date"
                                v-model="body.params.date_start"
                                :config="{
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="form-control"
                                name="date"
                            />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-xl-3 mt-1 mt-sm-0">
                            <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                            <flat-pickr
                                id="end_date"
                                v-model="body.params.date_end"
                                :config="{
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="form-control"
                                name="date"
                            />
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-xl-3 mt-1 mt-sm-0">
                            <label>{{ $t('titles.phone_number') }}</label>
                            <b-form-input
                                v-model="body.params.phone"
                                :placeholder="$t('enter.phone_number')"
                                size="md"
                                type="number"
                            />
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-xl-3 mt-1 mt-sm-0">
                            <label>{{ $t('titles.user_id') }}</label>
                            <b-form-input
                                v-model="body.params.user_id"
                                :placeholder="$t('titles.user_id')"
                                size="md"
                                type="number"
                            />
                        </div>

                        <div class="col-12 col-md-8 col-xl-12 px-0 d-sm-flex">
                            <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                                <label>{{ $t('titles.purchase') }}</label>
                                <v-select
                                    id="categories"
                                    v-model="body.params.purchase_id"
                                    :options="purchaseList"
                                    :placeholder="$t('titles.purchase')"
                                    :reduce="(option) => option.id"
                                    :searchable="true"
                                    label="name"
                                    @input="getAll"
                                />
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 mt-1 mt-sm-0">
                                <label>{{ $t('titles.product_type') }}</label>
                                <v-select
                                    v-model="body.params.product_type"
                                    :options="staticTypesList"
                                    :placeholder="$t('titles.product_type')"
                                    :reduce="(option) => option.value"
                                    :searchable="true"
                                    label="name"
                                    @input="getAll"
                                >
                                    <template v-slot:option="option">
                                        {{ checkLocales(option.name) }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ checkLocales(option.name) }}
                                    </template>
                                </v-select>
                            </div>

                            <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                                <label>{{ $t('titles.product') }}</label>
                                <v-select
                                    id="categories"
                                    v-model="body.params.product_id"
                                    :options="productList"
                                    :placeholder="$t('titles.product')"
                                    :reduce="(option) => option.id"
                                    :searchable="true"
                                    label="name"
                                    @input="getAll"
                                />
                            </div>
                        </div>

                        <div
                            class="col-12 mt-1 d-md-flex align-items-md-end justify-content-md-end"
                        >
                            <div class="row">
                                <div class="col-6">
                                    <b-button
                                        class="filter-button"
                                        variant="danger"
                                        @click="resetRequest"
                                    >
                                        <feather-icon icon="XIcon" />
                                    </b-button>
                                </div>
                                <div class="col-6">
                                    <b-button
                                        class="filter-button"
                                        variant="success"
                                        @click="getAll"
                                    >
                                        <feather-icon icon="SearchIcon" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-collapse>
        </div>

        <div v-else class="row mb-1">
            <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                <flat-pickr
                    id="start_date"
                    v-model="body.params.date_start"
                    :config="{
                        allowInput: true,
                        time_24hr: true
                    }"
                    :placeholder="$t('choose.date')"
                    autocomplete="off"
                    class="form-control"
                    name="date"
                />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-xl-3 mt-1 mt-sm-0">
                <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                <flat-pickr
                    id="end_date"
                    v-model="body.params.date_end"
                    :config="{
                        allowInput: true,
                        time_24hr: true
                    }"
                    :placeholder="$t('choose.date')"
                    autocomplete="off"
                    class="form-control"
                    name="date"
                />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-xl-3 mt-1 mt-sm-0">
                <label>{{ $t('titles.phone_number') }}</label>
                <b-form-input
                    v-model="body.params.phone"
                    :placeholder="$t('enter.phone_number')"
                    size="md"
                    type="number"
                />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-xl-3 mt-1 mt-sm-0">
                <label>{{ $t('titles.user_id') }}</label>
                <b-form-input
                    v-model="body.params.user_id"
                    :placeholder="$t('titles.user_id')"
                    size="md"
                    type="number"
                />
            </div>

            <div class="col-12 col-md-8 col-xl-12 px-0 d-sm-flex flex-wrap">
                <div class="col-12 col-sm-6 col-md-4 mt-1 mt-sm-0">
                    <label>{{ $t('titles.purchase') }}</label>
                    <v-select
                        v-model="body.params.purchase_id"
                        :options="purchaseList"
                        :placeholder="$t('titles.purchase')"
                        :reduce="(option) => option.id"
                        :searchable="true"
                        label="name"
                        @input="getAll"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 mt-1 mt-sm-0">
                    <label>{{ $t('titles.product_type') }}</label>
                    <v-select
                        v-model="body.params.product_type"
                        :options="staticTypesList"
                        :placeholder="$t('titles.product_type')"
                        :reduce="(option) => option.value"
                        :searchable="true"
                        label="name"
                        @input="getAll"
                    >
                        <template v-slot:option="option">
                            {{ checkLocales(option.name) }}
                        </template>

                        <template v-slot:selected-option="option">
                            {{ checkLocales(option.name) }}
                        </template>
                    </v-select>
                </div>

                <div class="col-12 col-sm-6 col-md-4 mt-1 mt-sm-0">
                    <label>{{ $t('titles.product') }}</label>
                    <v-select
                        id="categories"
                        v-model="body.params.product_id"
                        :options="productList"
                        :placeholder="$t('titles.product')"
                        :reduce="(option) => option.id"
                        :searchable="true"
                        label="name"
                        @input="getAll"
                    />
                </div>
            </div>

            <div class="col-12 mt-1 d-md-flex align-items-md-end justify-content-md-end">
                <div class="row">
                    <div class="col-6">
                        <b-button class="filter-button" variant="danger" @click="resetRequest">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </div>
                    <div class="col-6">
                        <b-button class="filter-button" variant="success" @click="getAll">
                            <feather-icon icon="SearchIcon" />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(name)="{ item }">
                    {{ checkLocales(item.name) }}
                </template>

                <template #cell(user_id)="{ item }">
                    <router-link
                        :to="{ name: 'subscriber-new-logger', params: { id: item.user_id } }"
                    >
                        {{ item.user_id }}
                    </router-link>
                </template>

                <template #cell(product_name)="{ item }">
                    <span
                        v-b-tooltip.hover.top="
                            `${$t('titles.product_id')} : ${item.gift.product_id}`
                        "
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    >
                        <b-badge class="font-small-4" variant="primary">
                            {{ item.gift.product_type }}
                        </b-badge>
                        :
                        <b-badge class="font-weight-light font-medium-1" variant="primary">
                            {{ item.gift.product_name }}
                        </b-badge>
                    </span>
                </template>

                <template #cell(purchase)="{ item }">
                    <b-badge :variant="item.purchase.color" class="font-small-4"
                        >{{ item.purchase.text }}
                    </b-badge>
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-3"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

::v-deep .tooltip-inner {
    min-width: max-content;
}

.filter-button {
    height: 40px;
    padding: 0.75rem 1rem;
    width: 100%;
}
</style>
