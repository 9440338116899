import CoreV2 from '@/services/core/coreV2';
import { axiosV2 } from '@/services/core/base';

class HomeCharges extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchProducts(body) {
        return this.post('statistics/charges/products', body);
    }

    fetchTotal(body) {
        return this.post('statistics/charges/total', body);
    }

    fetchDaily(body) {
        return this.post('statistics/charges/daily', body);
    }

    fetchDailyBy(body) {
        return this.post('statistics/charges/daily-by', body);
    }

    fetchDailyProduct(body) {
        return this.post('statistics/charges/daily-product', body);
    }

    fetchExcelProduct(body) {
        return this.post('statistics/charges/excel-product', body);
    }
}

export default HomeCharges;
