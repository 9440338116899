<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';
import api from '@/services/api';
import { isArray, isObject } from '@/util/inspect.util';

export default {
    name: 'LevelProductCard',
    components: {
        BButton,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        vSelect
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        levelList: {
            type: Array,
            required: true
        },
        packageList: {
            type: Array,
            required: true
        },
        cardsList: {
            type: Array,
            required: true
        },
        avatarsList: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            staticTypesList: [
                {
                    name: {
                        uz: 'Paket',
                        ru: 'Пакет',
                        en: 'Package'
                    },
                    value: 0
                },
                {
                    name: {
                        uz: 'Fit-Coin',
                        ru: 'Fit-Coin',
                        en: 'Fit-Coin'
                    },
                    value: 1
                },
                {
                    name: {
                        uz: 'Chipta',
                        ru: 'Билет',
                        en: 'Ticket'
                    },
                    value: 2
                },
                {
                    name: {
                        uz: 'Paynet',
                        ru: 'Paynet',
                        en: 'Paynet'
                    },
                    value: 3
                },
                {
                    name: {
                        uz: 'Level',
                        ru: 'Левел',
                        en: 'Level'
                    },
                    value: 4
                },
                {
                    name: {
                        uz: "Sovg'a",
                        ru: 'Приз',
                        en: 'Prize'
                    },
                    value: 5
                },
                {
                    name: {
                        uz: 'Kartalar',
                        ru: 'Карточки',
                        en: 'Cards'
                    },
                    value: 6
                },
                {
                    name: {
                        uz: 'Avatarlar',
                        ru: 'Аватары',
                        en: 'Avatars'
                    },
                    value: 7
                }
            ],
            uploadImage: null,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            propsProduct: null
        };
    },

    watch: {
        uploadImage(image) {
            this.propsProduct.image = [];
            if (isArray(image)) {
                this.propsProduct.image = image;
            } else if (isObject(image)) {
                this.propsProduct.image.push(image);
            }
        }
    },

    computed: {
        checkedLabels() {
            if (this.product.type === 0) {
                return this.$t('titles.packages');
            } else if (this.product.type === 1) {
                return this.$t('titles.coin_amount');
            } else if (this.product.type === 2) {
                return this.$t('titles.ticket_qty');
            } else if (this.product.type === 3) {
                return this.$t('titles.paynet_sum');
            } else if (this.product.type === 4) {
                return this.$t('titles.level_type');
            } else if (this.product.type === 5) {
                return this.$t('titles.prize');
            } else if (this.product.type === 6) {
                return this.$t('titles.cards');
            } else if (this.product.type === 7) {
                return this.$t('avatars');
            }
            return this.$t('titles.packages');
        }

        // propsProduct() {
        //     return this.product;
        // }
    },

    async mounted() {
        this.propsProduct = this.product;
        const image = this.propsProduct?.image?.[0];
        if (image) {
            this.uploadImage = [
                {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                }
            ];
        }
    },

    methods: {
        checkLocales,

        clearActivationItem(productId) {
            productId.activation_item_id = null;
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            // await this.propsProduct.image.forEach((image, index) => {
            if (Array.isArray(this.uploadImage)) {
                if (this.uploadImage && !this.uploadImage[0].id) {
                    const formData = new FormData();
                    formData.append('file', this.uploadImage[0].file);

                    api.imageUploader
                        .imageUpload('infinityProducts', formData, config)
                        .then(({ data }) => {
                            this.propsProduct.image[0] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                            // this.uploadImage = null;
                        });
                }
            } else {
                if (this.uploadImage && !this.uploadImage.id) {
                    const formData = new FormData();
                    formData.append('file', this.uploadImage.file);

                    api.imageUploader
                        .imageUpload('infinityProducts', formData, config)
                        .then(({ data }) => {
                            this.propsProduct.image[0] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                            // this.uploadImage = null;
                        });
                }
            }
            // });
        }
    }
};
</script>

<template>
    <div>
        <div v-if="propsProduct" class="product-card">
            <div class="product-card-form">
                <ValidationObserver ref="createUpdateModal">
                    <b-row>
                        <!--    NAME [UZ]    -->
                        <b-col cols="12">
                            <label>{{ `${$t('titles.title')} [UZ]` }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="`${$t('titles.title')} [UZ]`"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="propsProduct.name.uz"
                                        :placeholder="`${$t('enter.title')} [UZ]`"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    NAME [RU]    -->
                        <b-col cols="12">
                            <label>{{ `${$t('titles.title')} [RU]` }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="`${$t('titles.title')} [RU]`"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="propsProduct.name.ru"
                                        :placeholder="`${$t('enter.title')} [RU]`"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    NAME [EN]    -->
                        <b-col cols="12">
                            <label>{{ `${$t('titles.title')} [EN]` }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="`${$t('titles.title')} [EN]`"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="propsProduct.name.en"
                                        :placeholder="`${$t('enter.title')} [EN]`"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    TYPES    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.types') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.types')"
                                rules="required"
                            >
                                <v-select
                                    id="categories"
                                    v-model="propsProduct.type"
                                    :options="staticTypesList"
                                    :reduce="(option) => option.value"
                                    :placeholder="$t('choose.type')"
                                    @input="clearActivationItem(propsProduct)"
                                    label="name"
                                    :clearable="false"
                                >
                                    <template v-slot:option="option">
                                        {{ checkLocales(option.name) }}
                                    </template>

                                    <template v-slot:selected-option="option">
                                        {{ checkLocales(option.name) }}
                                    </template>
                                </v-select>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    ACTIVATION ITEM ID    -->
                        <b-col cols="12" v-if="propsProduct.type !== 5">
                            <label>{{ checkedLabels }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="checkedLabels"
                                :rules="product.type === 3 ? 'required|min_value:500' : 'required'"
                            >
                                <b-form-group>
                                    <v-select
                                        v-if="propsProduct.type === 4"
                                        id="categories"
                                        v-model="propsProduct.activation_item_id"
                                        :options="levelList"
                                        :reduce="(option) => option.type"
                                        :placeholder="checkedLabels"
                                        label="name"
                                        :clearable="false"
                                    />
                                    <b-form-input
                                        v-else-if="
                                            propsProduct.type === 1 ||
                                            propsProduct.type === 2 ||
                                            propsProduct.type === 3
                                        "
                                        v-model="propsProduct.activation_item_id"
                                        :placeholder="checkedLabels"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                        type="number"
                                    />
                                    <v-select
                                        v-else-if="propsProduct.type === 6"
                                        id="categories"
                                        v-model="propsProduct.activation_item_id"
                                        :options="cardsList"
                                        :reduce="(option) => option.id"
                                        :placeholder="checkedLabels"
                                        label="name"
                                        :clearable="false"
                                    >
                                        <template v-slot:option="option">
                                            {{ checkLocales(option.name) }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ checkLocales(option.name) }}
                                        </template>
                                    </v-select>
                                    <v-select
                                        v-else-if="propsProduct.type === 7"
                                        id="categories"
                                        v-model="propsProduct.activation_item_id"
                                        :options="avatarsList"
                                        :reduce="(option) => option.id"
                                        :placeholder="checkedLabels"
                                        label="name"
                                        :clearable="false"
                                    >
                                        <template v-slot:option="option">
                                            <div class="select-img">
                                                <img :src="option.path" alt="" />
                                            </div>
                                        </template>

                                        <template v-slot:selected-option="option">
                                            <div class="select-img">
                                                <img :src="option.path" alt="" />
                                            </div>
                                        </template>
                                    </v-select>
                                    <v-select
                                        v-else
                                        id="categories"
                                        v-model="propsProduct.activation_item_id"
                                        :options="packageList"
                                        :reduce="(option) => option.id"
                                        :placeholder="checkedLabels"
                                        label="name"
                                        :clearable="false"
                                    >
                                        <template v-slot:option="option">
                                            {{ option.type_name + ' ' + option.qty }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ option.type_name + ' ' + option.qty }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <b-col cols="12">
                            <!--                            <ValidationProvider-->
                            <!--                                v-slot="{ errors }"-->
                            <!--                                :name="$t('titles.image')"-->
                            <!--                                rules="required"-->
                            <!--                            >-->
                            <VueFileAgent
                                ref="vueFileAgent"
                                v-model="uploadImage"
                                :accept="'image/*,.zip'"
                                :deletable="true"
                                :errorText="{
                                    type: $t('vue_file_agent.file_type_error'),
                                    size: $t('vue_file_agent.size_type_error')
                                }"
                                :helpText="$t('vue_file_agent.choose_image')"
                                :maxFiles="14"
                                :maxSize="'10MB'"
                                :meta="true"
                                :multiple="false"
                                :theme="'grid'"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                @select="filesSelected($event)"
                            >
                            </VueFileAgent>

                            <b-button
                                :disabled="!fileRecordsForUpload.length || imageUploaded"
                                :variant="imageUploaded ? 'success' : 'primary'"
                                class="float-right mt-1"
                                @click="getImageId()"
                            >
                                <span v-if="!imageUploaded">
                                    {{ $t('vue_file_agent.upload_image') }}
                                </span>
                                <span v-else>
                                    {{ $t('vue_file_agent.image_uploaded') }}
                                </span>
                            </b-button>
                            <!--                                <span class="validation__red">-->
                            <!--                                    {{ errors[0] }}-->
                            <!--                                </span>-->
                            <!--                            </ValidationProvider>-->
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.product-card {
    &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid var(--secondary);
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        &-image {
            width: 70px;
            min-width: 70px;
            height: 100px;
            margin-bottom: 1rem;

            & img {
                width: 100%;
                min-width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.select-img {
    width: 40px;
    height: 40px;
    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
