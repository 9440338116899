import {
    clearObjectValues,
    copyObjectWithoutID,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import { getLocalVar } from '@/util/localstorage-helper';
import { BFormCheckbox } from 'bootstrap-vue';

export default {
    components: { BFormCheckbox },
    data: () => ({
        updatingItem: null,
        isOpenModal: false,
        createOneMore: false,
        localLang: getLocalVar('lang'),
        isViewModal: false
    }),
    computed: {
        modalSubmitBtnText() {
            return this.updatingItem ? this.$t('button.save') : this.$t('button.add');
        }
    },
    methods: {
        modalSubmit() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    try {
                        if (!this.updatingItem) {
                            await this.create();
                        } else {
                            await this.update();
                        }

                        if (this.createOneMore) {
                            this.dataForModal = clearObjectValues(this.dataForModal);
                        } else {
                            this.isOpenModal = false;
                        }
                    } catch (error) {
                        if (error?.response?.data?.message) {
                            return this.$toast.error(error.response.data.message);
                        } else {
                            return this.$toast.error(error?.message);
                        }

                        // this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        checkCreateMore() {
            if (this.createOneMore) {
                this.dataForModal = clearObjectValues(this.dataForModal);
            } else {
                this.isOpenModal = false;
            }
        },

        openModalToUpdate(item) {
            this.dataForModal = copyObjectWithoutID(item);
            this.updatingItem = item;
            this.imageUploaded = true;

            // if (this.$route.name !== "News") {
            //   delete this.dataForModal.buttons
            // }

            Object.keys(this.updatingItem).forEach((key) => {
                if (key === 'role') {
                    this.dataForModal.role_id = this.dataForModal.role?.id;
                    delete this.dataForModal.role;
                }

                if (key === 'input_type') {
                    if (this.dataForModal.input_type?.id)
                        this.dataForModal.input_type_id = this.dataForModal.input_type.id;
                    delete this.dataForModal.input_type;
                }

                if (key === 'dependent_survey') {
                    if (this.dataForModal.dependent_survey?.uuid)
                        this.dataForModal.dependent_survey_id =
                            this.dataForModal.dependent_survey.uuid;
                    delete this.dataForModal.dependent_survey;
                }

                if (key === 'gifts') {
                    this.dataForModal.gift_id = this.dataForModal.gifts.map((gift) => {
                        return (gift = gift.id);
                    });
                    delete this.dataForModal.gifts;
                }

                if (key === 'level') {
                    this.dataForModal.level_id = this.dataForModal.level?.id;
                    delete this.dataForModal.level;
                }

                if (key === 'region') {
                    this.dataForModal.region_id = this.dataForModal.region.id;
                    // delete this.dataForModal.region;
                }

                if (key === 'merchant_id') {
                    if (this.merchantOptions) {
                        const idx = this.merchantOptions.findIndex(
                            (item) => item.id === this.dataForModal.merchant_id
                        );
                        if (idx !== -1) {
                            this.dataForModal.merchant = this.merchantOptions[idx];
                            delete this.dataForModal.merchant_id;
                        }
                    }
                }

                if (key === 'image') {
                    this.dataForModal.image;
                    this.dataForModal.image_id = this.dataForModal.image?.id;
                    // delete this.dataForModal.image
                }

                if (key === 'buttons' && !this.updatingItem[key]) {
                    this.dataForModal.buttons = {
                        name: {
                            uz: '',
                            ru: '',
                            en: ''
                        },
                        link: ''
                    };
                }

                if (key === 'measurement' && typeof this.dataForModal.measurement === 'object') {
                    this.dataForModal.measurement = {
                        id: this.dataForModal.measurement?.id,
                        name: this.checkLocales(this.dataForModal.measurement?.name)
                    };
                }

                if (key === 'images') {
                    for (let i = 0; i < this.dataForModal.images.length; i++) {
                        const image = this.dataForModal.images[i];
                        this.dataForModal.images[i] = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }
                }
            });

            this.isOpenModal = true;
        },

        openViewModal(item) {
            this.openModalToUpdate(item);
            this.isViewModal = true;
        },

        openEditModal(item) {
            this.openModalToUpdate(item);
            this.isViewModal = false;
        },

        onCloseModal() {
            this.dataForModal = clearObjectValues(this.dataForModal);
            this.updatingItem = null;
            this.isViewModal = false;
        },

        updateDataToNewData(newData) {
            updateObjectToNew(this.updatingItem, newData);
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
