<script>
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import FilterBlock from '@/components/FilterBlock';
import CoinLogsTimeline from '@/components/CoinLogsTimeline.vue';
import { MERCHANT_ID } from '@/constants/names';
import { keys } from '@/util/object.util';
import { checkLang } from '@/util/helper';

export default {
    name: 'SubscribersSingleCoinMonitoring',
    components: {
        FilterBlock,
        CoinLogsTimeline,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BButton,
        vSelect
    },
    data: () => ({
        logs: {
            items: [],
            pagination: {}
        },
        filter: {
            page: 1
        },
        statusOptions: [],
        requestPending: false,
        localLang: localStorage.getItem('lang')
    }),
    computed: {
        statusOptionList() {
            const locale = this.$i18n.locale;
            if (this.statusOptions && keys(this.statusOptions).length > 0) {
                const l = [];
                for (let [key, option] of Object.entries(this.statusOptions)) {
                    l.push({
                        value: key,
                        label: option[locale][0].toUpperCase() + option[locale].slice(1)
                    });
                }
                const all = { uz: 'Hammasi', ru: 'Bce', en: 'All' };
                l.unshift({ value: 0, label: checkLang(all) });
                return l;
            }

            return [];
        }
    },
    methods: {
        checkLang,
        async getHistory() {
            if (this.requestPending) return;
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 10,
                user_id: this.$route.params.id,
                merchant_id: MERCHANT_ID
            });
            const { data } = await api.coin.getUserCoinOrdersHistory(this.filter);
            if (data.pagination.current === 1) {
                this.logs.items = data.result;
            } else {
                this.logs.items = [...this.logs.items, ...data.result];
            }
            this.logs.pagination = data.pagination;
            this.requestPending = false;
        },

        loadNextPage(page) {
            if (this.requestPending || this.autoReload || !page) return;
            this.requestPending = true;
            this.filter.page = page;
            this.getHistory();
            this.requestPending = false;
        },

        deSelect(val) {
            if (!val) {
                this.getHistory();
            }
        },
        async getStatusList() {
            try {
                const response = await api.coin.fetchCoinStatus();
                this.statusOptions = response.data;
            } catch (e) {
                this.$toast.error(e.response?.data?.message || e.message || this.$t('error.smth'));
            }
        }
    },
    mounted() {
        this.getHistory();
        this.getStatusList();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-7 col-sm-12 col-12 order-2 order-md-1">
                <coin-logs-timeline
                    v-model:loading="requestPending"
                    :items="logs.items"
                    :pagination="logs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <!--                        <div>{{ item }}</div>-->
                        <div class="activity-item">
                            <div class="activity-item__content">
                                <span>Id => {{ item.id }}</span>
                                <b-badge :style="`background-color: ${item.color}`">
                                    {{ checkLang(item.status) }}
                                </b-badge>

                                <h4>
                                    <img
                                        :src="require('@/assets/images/icons/coin.png')"
                                        alt=""
                                        style="margin-right: 0.5rem"
                                    />
                                    {{ item.grand_total }}
                                </h4>
                                <!--                                <h5>-->
                                <!--                                    {{ item.sub_title }}-->
                                <!--                                </h5>-->
                                <!--                                <p v-if="item.user">{{ item.user.phone }}</p>-->
                                <!--                                <span :class="{ debit: item.debit }"-->
                                <!--                                    >{{ item.amount }} {{ $t('titles.coin') }}-->
                                <!--                                </span>-->
                            </div>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <!--                        <router-link-->
                        <!--                            :to="{-->
                        <!--                                name: 'subscribers Single',-->
                        <!--                                params: {-->
                        <!--                                    id: item.user.id-->
                        <!--                                }-->
                        <!--                            }"-->
                        <!--                            class="d-flex"-->
                        <!--                        >-->
                        <!--                            {{ item.phone }}-->
                        <!--                        </router-link>-->
                        <!--                                                <div v-for="(data, key) in item.data" :key="key" class="d-flex">-->
                        <!--                                                    <small v-if="key === 'prize'">-->
                        <!--                                                        {{ key }}: {{ data.name[localLang] }}-->
                        <!--                                                    </small>-->
                        <!--                                                    <small v-else>{{ key }}: {{ data }}</small>-->
                        <!--                                                </div>-->
                    </template>
                </coin-logs-timeline>
            </b-col>
            <b-col class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2">
                <filter-block :filter="filter" @submit="getHistory()">
                    <b-col cols="12">
                        <h5>Действия</h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.status"
                                :options="statusOptionList"
                                :reduce="(item) => item.value"
                                :searchable="false"
                                label="label"
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                </filter-block>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #7367f0;
            margin-bottom: 0;
        }

        span {
            color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}
</style>
