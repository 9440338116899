<script>
import api from '@/services/api';
import vSelect from 'vue-select';

export default {
    name: 'AppControl',
    components: { vSelect },
    data() {
        return {
            queueList: [],
            serverList: [],
            setIntervalList: [5000, 10000, 30000],
            setIntervalValue: 5000,
            doubleRequesting: null
        };
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.doubleRequesting);
        this.doubleRequesting = null;

        next();
    },

    async created() {
        await this.fetchQueue();
        await this.fetchServerList();
        await this.doubleRequest();
    },

    methods: {
        async fetchQueue() {
            try {
                const { data } = await api.control.fetchQueue();
                this.queueList = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async fetchServerList() {
            try {
                const { data } = await api.control.fetchServerList();
                this.serverList = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async refreshButton() {
            await this.fetchQueue();
            await this.fetchServerList();
        },

        async doubleRequest() {
            this.doubleRequesting = setInterval(async () => {
                await this.fetchQueue();
                await this.fetchServerList();
            }, this.setIntervalValue);
        },

        async clearIntervalAndSetNew() {
            await clearInterval(this.doubleRequesting);
            await this.doubleRequest();
        },

        async triggerServer(server) {
            const serverId = server.id;
            console.log(server, 'server');
            try {
                if (server.status.isActive) {
                    await api.control.stopServer(serverId);
                    this.$bvModal.hide(`server-${serverId}`);
                    await this.fetchServerList();
                    await this.fetchQueue();
                } else {
                    await api.control.startServer(serverId);
                    this.$bvModal.hide(`server-${serverId}`);
                    await this.fetchServerList();
                    await this.fetchQueue();
                }
            } catch (e) {
                console.error(e);
            }
        },

        matchColorsForTasks(item) {
            if (item > 0) {
                return 'text-success';
            } else if (item > 2000) {
                return 'text-warning';
            } else if (item > 5000) {
                return 'text-danger';
            } else {
                return '';
            }
        }
    }
};
</script>

<template>
    <div class="control">
        <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-between mb-1">
                <h2>Queue List</h2>
                <div class="d-flex align-items-center">
                    <b-button @click="refreshButton" variant="outline-primary" class="mr-1">
                        <feather-icon icon="RefreshCwIcon" size="16" />
                    </b-button>
                    <v-select
                        v-model="setIntervalValue"
                        :options="setIntervalList"
                        style="flex-grow: 1; height: 37px; max-width: 150px; min-width: 150px"
                        @input="clearIntervalAndSetNew"
                        :clearable="false"
                    />
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3" v-for="(queue, index) in queueList" :key="index">
                <b-card class="card d-flex align-items-center">
                    <h3 class="text-uppercase">{{ queue.name }}</h3>
                    <h1 class="mb-0 text-center" :class="matchColorsForTasks(queue.tasks)">
                        {{ queue.tasks }}
                    </h1>
                </b-card>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Server List</h2>
            </div>
            <div
                class="col-12 col-sm-6 col-md-4 col-lg-3"
                v-for="server in serverList"
                :key="server.id"
            >
                <b-card
                    v-if="server.status"
                    class="cursor-pointer"
                    :class="server.status.isActive ? 'active' : 'not-active'"
                >
                    <div class="d-flex align-items-center mb-05 position-relative">
                        <!--                        <div v-if="!server.status.isActive" class="status-detector"></div>-->
                        <h3 class="mb-0 mx-auto text-info">
                            {{ server.host }} : {{ server.port }}
                        </h3>
                    </div>
                    <div class="d-flex align-items-center mb-05">
                        <span>Token:</span>
                        <small class="mb-0 ml-05 text__overflow text-secondary">{{
                            server.token
                        }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-05">
                        <span>Type Queue:</span>
                        <h5 class="mb-0 ml-05">
                            <b-badge variant="primary text-uppercase">{{
                                server.status.typeQueue
                            }}</b-badge>
                        </h5>
                    </div>

                    <div class="d-flex align-items-center mb-05">
                        <span>Active Workers:</span>
                        <h5 class="mb-0 ml-05">{{ server.status.activeWorkers }}</h5>
                    </div>

                    <div class="control-actions">
                        <b-button
                            class="w-100"
                            :variant="server.status.isActive ? 'outline-danger' : 'outline-success'"
                            v-b-modal="`server-${server.id}`"
                        >
                            <feather-icon
                                :class="server.status.isActive ? 'text-danger' : 'text-success'"
                                :icon="server.status.isActive ? 'PauseIcon' : 'PlayIcon'"
                                size="20"
                            />
                        </b-button>
                    </div>

                    <b-modal
                        :id="`server-${server.id}`"
                        centered
                        :title="
                            server.status.isActive
                                ? $t('titles.stop_server')
                                : $t('titles.play_server')
                        "
                    >
                        <p class="my-4" v-if="server.status.isActive">
                            {{ $t('titles.server_actions_verification_stop') }}?
                            <span class="d-block mt-1 font-medium-4"
                                ><strong>{{ server.host }} : {{ server.port }}</strong></span
                            >
                        </p>
                        <p class="my-4" v-else>
                            {{ $t('titles.server_actions_verification_start') }}?
                            <span class="d-block mt-1 font-medium-4"
                                ><strong>{{ server.host }} : {{ server.port }}</strong></span
                            >
                        </p>

                        <template #modal-footer="{ cancel }">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-button @click="cancel()" variant="danger">{{
                                    $t('titles.cancel')
                                }}</b-button>
                                <b-button
                                    @click="triggerServer(server)"
                                    class="ml-1"
                                    variant="success"
                                    >{{ $t('titles.yes') }}</b-button
                                >
                            </div>
                        </template>
                    </b-modal>
                </b-card>

                <b-card v-else class="cursor-pointer secondary-bg">
                    <div class="d-flex align-items-center mb-05 position-relative">
                        <!--                        <div v-if="!server.status.isActive" class="status-detector"></div>-->
                        <h3 class="mb-0 mx-auto text-info">
                            {{ server.host }} : {{ server.port }}
                        </h3>
                    </div>
                    <div class="d-flex align-items-center mb-05">
                        <span>Token:</span>
                        <small class="mb-0 ml-05 text__overflow text-secondary">{{
                            server.token
                        }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-05">
                        <span>Type Queue:</span>
                        <h5 class="mb-0 ml-05">
                            <b-badge variant="primary text-uppercase">service is no active</b-badge>
                        </h5>
                    </div>

                    <div class="d-flex align-items-center mb-05">
                        <span>Active Workers:</span>
                        <h5 class="mb-0 ml-05">service is no active</h5>
                    </div>

                    <div class="control-actions">
                        <b-button
                            class="w-100"
                            variant="outline-secondary"
                            v-b-modal="`server-${server.id}`"
                            disabled
                        >
                            <feather-icon class="text-secondary" icon="XIcon" size="20" />
                        </b-button>
                    </div>

                    <b-modal :id="`server-${server.id}`" centered title="service is not active">
                        <p class="my-4">
                            {{ $t('titles.server_actions_verification_start') }}?
                            <span class="d-block mt-1 font-medium-4"
                                ><strong>{{ server.host }} : {{ server.port }}</strong></span
                            >
                        </p>

                        <template #modal-footer="{ cancel }">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-button @click="cancel()" variant="danger">{{
                                    $t('titles.cancel')
                                }}</b-button>
                                <b-button
                                    @click="triggerServer(server)"
                                    class="ml-1"
                                    variant="success"
                                    >{{ $t('titles.yes') }}</b-button
                                >
                            </div>
                        </template>
                    </b-modal>
                </b-card>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.control {
    &-actions {
    }
}
.ml-05 {
    margin-left: 0.5rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.text__overflow {
    width: auto;
    overflow-x: hidden;
}

.card.active {
    border: 2px solid var(--success);
}

.card.not-active {
    border: 2px solid var(--danger);
}

.status-detector {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border: 2px solid var(--danger);
    background: var(--danger);
    border-radius: 50%;
}

.secondary-bg {
    border: 2px solid var(--secondary);
}
</style>
