// import { axiosInstanceV4 } from '@/services/core/base';
import { axiosV5 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';
import { MERCHANT_ID } from '@/constants/names';

class KubeServers extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'kube/dashboardGf/' : 'kube/dashboardAl/';
    }

    // SERVERS
    fetchAllItems(body) {
        return this.post(this.dashboardVersion() + 'server/getAll', body);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `server/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'server/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `server/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `server/delete/${id}`, id);
    }

    fetchConfigConstants(id) {
        return this.get(this.dashboardVersion() + `server/constant/${id}`);
    }

    // CONTROL
    fetchServerList(body) {
        return this.post(this.dashboardVersion() + 'control/getServers', body);
    }

    // fetchOneServer(id) {
    //     return this.post(this.dashboardVersion() + `control/get/${id}`);
    // }

    fetchQueue(body) {
        return this.post(this.dashboardVersion() + 'control/queueTricks', body);
    }

    startServer(id) {
        return this.post(this.dashboardVersion() + `control/starting/${id}`);
    }

    stopServer(id) {
        return this.post(this.dashboardVersion() + `control/stopping/${id}`);
    }

    cleanQueue(id) {
        return this.post(this.dashboardVersion() + `control/cleanTemp/${id}`);
    }

    //QUEUE TRICKS
    fetchAllTricks(body) {
        return this.post(this.dashboardVersion() + 'queueTrick/getAll', body);
    }

    fetchOneTrick(id) {
        return this.get(this.dashboardVersion() + `queueTrick/get/${id}`);
    }

    createTrick(body) {
        return this.post(this.dashboardVersion() + 'queueTrick/create', body);
    }

    updateTrick(id, body) {
        return this.post(this.dashboardVersion() + `queueTrick/update/${id}`, body);
    }

    deleteTrick(id) {
        return this.delete(this.dashboardVersion() + `queueTrick/delete/${id}`, id);
    }
}

export default KubeServers;
