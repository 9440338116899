import Core from '@/services/axios/core.axios';
import { axiosV1 } from '@/services/core/base';

class SubscriberService extends Core {
    constructor() {
        super({
            axios: axiosV1({
                endpoint: 'subscribers'
            })
        });
    }

    subscriberDetails({ subscriberId }) {
        return this.get(`${subscriberId}/personal`);
    }

    toggleSubscription(body) {
        return this.post('toggle-subscription', body);
    }
}

export const subscriberApi = new SubscriberService();
