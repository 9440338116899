var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-sm-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.notification_history')))]},proxy:true}])})],1),_c('div',{staticClass:"d-flex align-items-center mb-2 mb-sm-0"},[_c('v-select',{staticClass:"mr-2",staticStyle:{"width":"200px"},attrs:{"id":"property","options":_vm.directionList,"append-to-body":"","clearable":true,"placeholder":"Select a direction"},on:{"input":_vm.getNotificationHistories},model:{value:(_vm.filter.direction),callback:function ($$v) {_vm.$set(_vm.filter, "direction", $$v)},expression:"filter.direction"}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v("Per page:")]),_c('v-select',{staticClass:"ml-2",staticStyle:{"flex-grow":"1","background-color":"#fff","height":"37px","width":"180px"},attrs:{"options":_vm.perPageList},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1)],1)]),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"no-border-collapse":"","borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.checkLocales(item.title)))])]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"line__break"},[_vm._v(_vm._s(_vm.checkLocales(item.description)))])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.fixTimeFrame(item.created_at)))])]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(item && item.image)?_c('img',{staticClass:"table-img",attrs:{"src":item.image.path}}):_vm._e()]}}])})],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }