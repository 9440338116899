import AppFeedbackPolls from '@/views/feedback/AppFeedbackPolls.vue';
import AppFeedbackOptions from '@/views/feedback/AppFeedbackOptions.vue';

export default [
    {
        path: '/feedback-polls',
        name: 'feedback-polls',
        component: AppFeedbackPolls,
        meta: {
            section: 'users',
            permission: 'view'
        }
    },
    {
        path: '/feedback-options',
        name: 'feedback-options',
        component: AppFeedbackOptions,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
