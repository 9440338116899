import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class RoadmapSeasonGtwApi extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    fetchAllItems(body) {
        return this.post('roadmap/season/findAll', body);
    }

    fetchOneItem(body) {
        return this.post('roadmap/season/findOne', body);
    }

    createItem(body) {
        return this.post('roadmap/season/create', body);
    }

    updateItem(body) {
        return this.post('roadmap/season/update', body);
    }

    deleteItem(body) {
        return this.post('roadmap/season/remove', body);
    }

    fetchCurrentSeason(body) {
        return this.post('roadmap/season/current', body);
    }

    fetchRoadmapUserInfo(body) {
        return this.post('roadmap/user/info', body);
    }

    fetchRoadmapScore(body) {
        return this.post('roadmap/score/findAll', body);
    }

    createRoadmapScore(body) {
        return this.post('roadmap/score/create', body);
    }

    fetchProductHistory(body) {
        return this.post('roadmap/product-history/findAll', body);
    }
}

export default RoadmapSeasonGtwApi;
