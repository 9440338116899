<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCard,
    BCardHeader,
    BCol,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
// import UploadImage from '@/components/ui/UploadImage/UploadImage';
import vSelect from 'vue-select';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import PasteImageFileAgent from '@/mixins/pasteImageFileAgent';

export default {
    name: 'NewsV1',
    components: {
        // UploadImage,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        flatPickr,
        BButton,
        BTable,
        // BAvatar,
        BModal,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormTextarea,
        BFormInput,
        // BFormDatepicker,
        BPagination,
        BCardHeader,
        BCollapse,
        BCard
    },
    mixins: [CRUDPageMixin, PasteImageFileAgent],
    data() {
        return {
            news: [],
            apps: [
                {
                    id: '',
                    name: ''
                }
            ],
            customAppAll: [
                {
                    id: null,
                    name: 'All'
                }
            ],
            activeAppID: null,
            merchantOptions: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                buttons: {
                    name: {
                        uz: '',
                        ru: '',
                        en: ''
                    },
                    link: ''
                },
                date: null,
                image: '',
                image_id: null,
                story_image: '',
                story_image_id: null,
                image_poster: '',
                image_poster_id: null,
                likes: {},
                views: null,
                is_stories: false,
                is_published: false,
                merchant: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,

            fileRecords: [],
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],

            fileRecordsStory: [],
            uploadUrlStory: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeadersStory: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUploadStory: [],

            fileRecordsPoster: [],
            uploadUrlPoster: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeadersPoster: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUploadPoster: [],

            imageUploaded: false,
            storyImageUploaded: false,
            posterImageUploaded: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = parseInt(query.page) || 1;
                this.pagination.perPage = parseInt(query.perPage) || 10;
                this.getAllNews();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: parseInt(newPage),
                    perPage: parseInt(this.pagination.perPage)
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'image',
                    label: this.$t('titles.picture')
                },
                {
                    key: 'name',
                    label: this.$t('titles.title'),
                    formatter: (name) => checkLang(name)
                },
                {
                    key: 'description',
                    label: this.$t('description'),
                    formatter: (description) => checkLang(description || '').substring(0, 150)
                },
                {
                    key: 'merchant.name',
                    label: this.$t('titles.merchant')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.published')
                },
                {
                    key: 'is_stories',
                    label: this.$t('titles.is_stories')
                },
                {
                    key: 'story_image',
                    label: this.$t('titles.story_image')
                },
                {
                    key: 'image_poster',
                    label: this.$t('titles.poster_image')
                },
                {
                    key: 'views',
                    label: this.$t('titles.views')
                },
                {
                    key: 'likes.like',
                    label: this.$t('titles.likes')
                },
                {
                    key: 'likes.dislike',
                    label: this.$t('titles.dislikes')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.news && this.news.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },

        isFormCompleted() {
            const l =
                this.dataForModal.name.uz &&
                this.dataForModal.name.en &&
                this.dataForModal.name.ru &&
                this.dataForModal.description.ru &&
                this.dataForModal.description.uz &&
                this.dataForModal.description.en &&
                this.dataForModal.image_id;
            // this.dataForModal.image_poster_id;

            if (this.dataForModal.is_stories) {
                return l && this.dataForModal.story_image_id;
            } else {
                return l;
            }
        }
    },

    methods: {
        emptyStoryImage() {
            this.dataForModal.story_image = '';
            this.dataForModal.story_image_id = null;
            this.fileRecordsForUploadStory = [];
            this.storyImageUploaded = false;
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        async getMerchants() {
            const params = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 10
                }
            };
            await api.coin
                .fetchCoinMerchants(params)
                .then((res) => {
                    this.merchantOptions = res.data.result;
                    this.apps = [...this.customAppAll, ...res.data.result];
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getAllNews() {
            const body = {
                method: 'coin.news_all',
                params: {
                    page: this.pagination.current,
                    limit: this.pagination.perPage,
                    merchant_id: this.activeAppID
                }
            };
            await api.news
                .fetchAllNews(body)
                .then((response) => {
                    this.news = response.data.result;
                    this.pagination = response.data.pagination;
                    this.pagination.current = response.data.pagination.current || 1;
                    this.pagination.perPage = response.data.pagination.perPage || 10;
                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    this.dataForModal['merchant_id'] = this.dataForModal.merchant.id;

                    if (
                        this.dataForModal.image &&
                        typeof this.dataForModal.image.path === 'string'
                    ) {
                        this.dataForModal.image_id = this.dataForModal.image.id;
                    }

                    if (
                        this.dataForModal.story_image &&
                        typeof this.dataForModal.story_image.path === 'string'
                    ) {
                        this.dataForModal.story_image_id = this.dataForModal.story_image.id;
                    }

                    if (
                        this.dataForModal.image_poster &&
                        typeof this.dataForModal.image_poster.path === 'string'
                    ) {
                        this.dataForModal.image_poster_id = this.dataForModal.image_poster.id;
                    }
                    // delete this.dataForModal.image;
                    // delete this.dataForModal.story_image;
                    // delete this.dataForModal.merchant;

                    let body = {
                        method: '',
                        params: this.dataForModal
                    };

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            app_id: this.activeAppID
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.method = 'coin.news_update';
                            body.params.id = this.updatingItem.id;
                            await this.updateNews(body, config);
                            // await this.getAllNews();
                        } else {
                            body.method = 'coin.news_create';
                            await this.createNews(body, config);
                            // await this.getAllNews();
                        }
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async updateWithFetch(item) {
            const body = {
                method: 'coin.news_one',
                params: {
                    id: item.id
                }
            };
            try {
                const { data } = await api.news.fetchOneNews(body);
                this.openModalToUpdate(data.result);
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },

        async createNews(body, config) {
            try {
                const { data } = await api.news.createNews(body, config);
                this.news.unshift(data);
                this.$toast.success(this.$t('success.added'));
                this.changeMerchantID(this.activeAppID || null);
                this.isOpenModal = false;
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
            // finally {
            //     await this.getAllNews();
            // }
        },

        async updateNews(body, config) {
            await api.news
                .updateNews(body, config)
                .then(({ data }) => {
                    this.news.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.$toast.success(this.$t('success.updated.title'));
                    this.changeMerchantID(this.activeAppID || null);
                    this.isOpenModal = false;
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
            // .finally(() => {
            //     this.getAllNews(this.activeAppID);
            // });
        },

        async deleteNews(id, index) {
            const data = {
                method: 'coin.news_remove',
                params: { id }
            };
            try {
                await api.news.deleteNews(data);
                this.news.splice(index, 1);
                await this.getAllNews();
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        changeMerchantID(appID) {
            if (appID === 100) {
                this.activeAppID = null;
            } else {
                this.activeAppID = appID;
            }
            this.getAllNews(this.activeAppID);
        },

        async getImageId(image, imageId, type) {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            const formData = new FormData();
            // formData.append('type', 'news');
            formData.append('file', image.file);

            // formData.append('attachment', this.dataForModal.story_image.file)
            await api.imageUploader
                .imageUpload('news', formData, config)
                .then(({ data }) => {
                    if (type === 'image') {
                        this.dataForModal.image_id = data.result.id;
                        this.imageUploaded = true;
                    } else if (type === 'story_image') {
                        this.dataForModal.story_image_id = data.result.id;
                        this.storyImageUploaded = true;
                    } else {
                        this.dataForModal.image_poster_id = data.result.id;
                        this.posterImageUploaded = true;
                    }
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        deleteImage(type) {
            if (type === 'image') {
                this.dataForModal.image = null;
                this.dataForModal.image_id = null;
                this.fileRecordsForUpload = [];
                this.imageUploaded = false;
            } else if (type === 'story_image') {
                this.dataForModal.story_image = null;
                this.dataForModal.story_image_id = null;
                this.fileRecordsForUploadStory = [];
                this.storyImageUploaded = false;
            } else {
                this.dataForModal.image_poster = null;
                this.dataForModal.image_poster_id = null;
                this.fileRecordsForUploadPoster = [];
                this.posterImageUploaded = false;
            }
        },

        // IMAGE
        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i === -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
                // }
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
            this.imageUploaded = false;
            this.dataForModal.image = '';
            this.dataForModal.image_id = null;
        },

        // STORY
        deleteUploadedFileStory: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgentStory.deleteUpload(
                this.uploadUrlStory,
                this.uploadHeadersStory,
                fileRecord
            );
        },

        filesSelectedStory: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUploadStory =
                this.fileRecordsForUploadStory.concat(validFileRecords);
        },

        onBeforeDeleteStory: function (fileRecord) {
            const i = this.fileRecordsForUploadStory.indexOf(fileRecord);
            if (i === -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUploadStory.splice(i, 1);
                const k = this.fileRecordsStory.indexOf(fileRecord);
                if (k !== -1) this.fileRecordsStory.splice(k, 1);
            } else {
                // if (confirm('Are you sure you want to delete?')) {
                //   this.$refs.vueFileAgentStory.deleteFileRecord(fileRecord); // will trigger 'delete' event
                // }
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgentStory.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        fileDeletedStory: function (fileRecord) {
            const i = this.fileRecordsForUploadStory.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUploadStory.splice(i, 1);
            } else {
                this.deleteUploadedFileStory(fileRecord);
            }
            this.storyImageUploaded = false;
            this.dataForModal.story_image = '';
            this.dataForModal.story_image_id = null;
        },

        // POSTER
        deleteUploadedFilePoster: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgentPoster.deleteUpload(
                this.uploadUrlPoster,
                this.uploadHeadersPoster,
                fileRecord
            );
        },

        filesSelectedPoster: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUploadPoster =
                this.fileRecordsForUploadPoster.concat(validFileRecords);
        },

        onBeforeDeletePoster: function (fileRecord) {
            const i = this.fileRecordsForUploadPoster.indexOf(fileRecord);
            if (i === -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUploadPoster.splice(i, 1);
                const k = this.fileRecordsPoster.indexOf(fileRecord);
                if (k !== -1) this.fileRecordsPoster.splice(k, 1);
            } else {
                // if (confirm('Are you sure you want to delete?')) {
                //   this.$refs.vueFileAgentStory.deleteFileRecord(fileRecord); // will trigger 'delete' event
                // }
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgentPoster.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        fileDeletedPoster: function (fileRecord) {
            const i = this.fileRecordsForUploadPoster.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUploadPoster.splice(i, 1);
            } else {
                this.deleteUploadedFilePoster(fileRecord);
            }
            this.posterImageUploaded = false;
            this.dataForModal.image_poster = '';
            this.dataForModal.image_poster_id = null;
        }
    },

    generateIconToBoolean,
    generateIconColorToBoolean,

    async mounted() {
        this.requestPending = true;
        await this.getMerchants();
        this.requestPending = false;
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.news') }}
            </template>
            <template v-if="hasAccess('news', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <b-col class="d-flex align-items-center justify-content-between" cols="12">
                <div class="nav nav-tabs">
                    <div
                        v-for="(link, index) in apps"
                        :key="index"
                        :class="['nav-link', 'cursor-pointer', { active: activeAppID === link.id }]"
                        @click="changeMerchantID(link.id)"
                    >
                        {{ link.name }}
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                    Per page:
                    <v-select
                        v-model="pagination.perPage"
                        :options="perPageList"
                        class="ml-2"
                        style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                    />
                </div>
            </b-col>
        </b-row>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="news"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>

                <template #cell(image)="{ item }">
                    <div class="table-img">
                        <img v-if="item && item.image" :src="item.image.path" />
                        <!--                        <img v-else src="../assets/images/icons/no-photo.png" />-->
                    </div>
                </template>

                <template #cell(story_image)="{ item }">
                    <div class="table-img">
                        <img v-if="item && item.story_image" :src="item.story_image.path" />
                        <!--                        <img v-else src="../assets/images/icons/no-photo.png" />-->
                    </div>
                </template>

                <template #cell(poster_image)="{ item }">
                    <div class="table-img">
                        <img v-if="item && item.story_image" :src="item.story_image.path" />
                        <!--                        <img v-else src="../assets/images/icons/no-photo.png" />-->
                    </div>
                </template>

                <template #cell(views)="{ item }">
                    {{ item.views }}
                </template>

                <template #cell(date)="{ item }">
                    <div style="min-width: 90px">
                        {{ item.date }}
                    </div>
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="['stroke-current', generateIconColorToBoolean(item.is_published)]"
                        :icon="generateIconToBoolean(item.is_published)"
                    />
                </template>

                <template #cell(is_stories)="{ item }">
                    <feather-icon
                        :class="['stroke-current', generateIconColorToBoolean(item.is_stories)]"
                        :icon="generateIconToBoolean(item.is_stories)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :delete-access="{ section: 'news', permission: 'delete' }"
                        :delete-handler="deleteNews"
                        :index="index"
                        :item="item"
                        :update-access="{ section: 'news', permission: 'update' }"
                        @openModal="updateWithFetch"
                    />
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.news')"
            cancel-variant="danger"
            centered
            size="xl"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME[uz]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} (uz) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[ru]    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.title') }} (ru)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (ru)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[en]    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.title') }} (en)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (en)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[uz]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} (uz) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} (uz)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.uz"
                                    :placeholder="`${$t('enter.description')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[ru]    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.description') }} (ru)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} (ru)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.ru"
                                    :placeholder="`${$t('enter.description')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DESCRIPTION[en]    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.description') }} (en)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} (en)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.description.en"
                                    :placeholder="`${$t('enter.description')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    DATE    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.date') }}</label>
                        <div class="input-group">
                            <flat-pickr
                                v-model="dataForModal.date"
                                :config="{ enableTime: true, allowInput: true, time_24hr: true }"
                                class="mb-1 form-control"
                                name="date"
                                placeholder="Select date"
                            />
                        </div>
                    </b-col>

                    <!--     MERCHANT ID     -->
                    <b-col cols="4" style="z-index: 999">
                        <label>Merchant</label>
                        <v-select
                            v-model="dataForModal.merchant"
                            :options="merchantOptions"
                            label="name"
                        />
                    </b-col>

                    <!--    IS PUBLISHED & IS STORIES    -->
                    <b-col class="d-flex align-items-center" cols="4">
                        <b-col class="px-0" cols="6">
                            <label for="is_published">{{ $t('titles.is_published') }}</label>
                            <b-form-checkbox
                                id="is_published"
                                v-model="dataForModal.is_published"
                            />
                        </b-col>

                        <b-col class="px-1" cols="6">
                            <label for="is_stories">{{ $t('titles.in_stories') }}</label>
                            <b-form-checkbox
                                id="is_stories"
                                v-model="dataForModal.is_stories"
                                @change="emptyStoryImage"
                            />
                        </b-col>
                    </b-col>

                    <!--     IMAGE     -->
                    <div class="w-100 mb-2">
                        <label class="pl-1" for="">{{ $t('titles.image') }}</label>
                        <b-col
                            v-if="dataForModal.image && typeof dataForModal.image.path === 'string'"
                            cols="12"
                        >
                            <div class="bordered__image-list mb-2 p-1">
                                <div class="bordered__image-list_item">
                                    <span
                                        class="bordered__image-list_item-close"
                                        @click="deleteImage('image')"
                                        >X</span
                                    >
                                    <img :src="dataForModal.image.path" alt="" />
                                </div>
                            </div>
                        </b-col>

                        <b-col v-else class="d-flex align-items-center" cols="12">
                            <b-col class="px-0" cols="9">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.image')"
                                    rules="required"
                                >
                                    <VueFileAgent
                                        ref="vueFileAgent"
                                        v-model="dataForModal.image"
                                        :accept="'image/*,.zip'"
                                        :deletable="true"
                                        :errorText="{
                                            type: $t('vue_file_agent.file_type_error'),
                                            size: $t('vue_file_agent.size_type_error')
                                        }"
                                        :helpText="$t('vue_file_agent.choose_image')"
                                        :maxFiles="1"
                                        :maxSize="'10MB'"
                                        :meta="true"
                                        :multiple="false"
                                        theme="list"
                                        @beforedelete="onBeforeDelete($event)"
                                        @delete="fileDeleted($event)"
                                        @select="filesSelected($event)"
                                    ></VueFileAgent>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>
                            <b-col class="pr-0" cols="3">
                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    @click="
                                        getImageId(
                                            dataForModal.image,
                                            dataForModal.image_id,
                                            'image'
                                        )
                                    "
                                >
                                    <span v-if="!imageUploaded">{{
                                        $t('vue_file_agent.upload_image')
                                    }}</span>
                                    <span v-else>{{ $t('vue_file_agent.image_uploaded') }}</span>
                                </b-button>
                            </b-col>
                        </b-col>
                    </div>

                    <!--     POSTER IMAGE ID     -->
                    <div class="w-100">
                        <label class="pl-1" for="">{{ $t('titles.poster_image') }}</label>
                        <b-col
                            v-if="
                                dataForModal.image_poster &&
                                typeof dataForModal.image_poster.path === 'string'
                            "
                            cols="12"
                        >
                            <div class="bordered__image-list mb-2 p-1">
                                <div class="bordered__image-list_item">
                                    <span
                                        class="bordered__image-list_item-close"
                                        @click="deleteImage('poster_image')"
                                        >X</span
                                    >
                                    <img :src="dataForModal.image_poster.path" alt="" />
                                </div>
                            </div>
                        </b-col>

                        <b-col v-else class="d-flex align-items-center" cols="12">
                            <b-col class="px-0" cols="9">
                                <!--                                <ValidationProvider-->
                                <!--                                    v-slot="{ errors }"-->
                                <!--                                    :name="$t('titles.poster_image')"-->
                                <!--                                    rules="required"-->
                                <!--                                >-->
                                <VueFileAgent
                                    ref="vueFileAgentPoster"
                                    v-model="dataForModal.image_poster"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: 'Неверный тип файла. Разрешено только изображение',
                                        size: 'Размер файла не должен превышать 10 МБ.'
                                    }"
                                    :helpText="'Выберите изображение'"
                                    :maxFiles="1"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    theme="list"
                                    @beforedelete="onBeforeDeletePoster($event)"
                                    @delete="fileDeletedPoster($event)"
                                    @select="filesSelectedPoster($event)"
                                ></VueFileAgent>
                                <!--                                    <span class="validation__red">-->
                                <!--                                        {{ errors[0] }}-->
                                <!--                                    </span>-->
                                <!--                                </ValidationProvider>-->
                            </b-col>
                            <b-col class="pr-0" cols="3">
                                <b-button
                                    :disabled="
                                        !fileRecordsForUploadPoster.length || posterImageUploaded
                                    "
                                    :variant="posterImageUploaded ? 'success' : 'primary'"
                                    @click="
                                        getImageId(
                                            dataForModal.image_poster,
                                            dataForModal.image_poster_id,
                                            'image_poster'
                                        )
                                    "
                                >
                                    <span v-if="!posterImageUploaded">{{
                                        $t('vue_file_agent.upload_image')
                                    }}</span>
                                    <span v-else>{{ $t('vue_file_agent.image_uploaded') }}</span>
                                </b-button>
                            </b-col>
                        </b-col>
                    </div>

                    <!--     STORY IMAGE     -->
                    <div v-if="dataForModal.is_stories" class="w-100">
                        <label class="pl-1" for="">{{ $t('titles.story_image') }}</label>
                        <b-col
                            v-if="
                                dataForModal.story_image &&
                                typeof dataForModal.story_image.path === 'string'
                            "
                            cols="12"
                        >
                            <div class="bordered__image-list mb-2 p-1">
                                <div class="bordered__image-list_item">
                                    <span
                                        class="bordered__image-list_item-close"
                                        @click="deleteImage('story_image')"
                                        >X</span
                                    >
                                    <img :src="dataForModal.story_image.path" alt="" />
                                </div>
                            </div>
                        </b-col>

                        <b-col v-else class="d-flex align-items-center" cols="12">
                            <b-col class="px-0" cols="9">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.story_image')"
                                    rules="required"
                                >
                                    <VueFileAgent
                                        ref="vueFileAgentStory"
                                        v-model="dataForModal.story_image"
                                        :accept="'image/*,.zip'"
                                        :deletable="true"
                                        :errorText="{
                                            type: 'Неверный тип файла. Разрешено только изображение',
                                            size: 'Размер файла не должен превышать 10 МБ.'
                                        }"
                                        :helpText="'Выберите изображение'"
                                        :maxFiles="1"
                                        :maxSize="'10MB'"
                                        :meta="true"
                                        :multiple="false"
                                        theme="list"
                                        @beforedelete="onBeforeDeleteStory($event)"
                                        @delete="fileDeletedStory($event)"
                                        @select="filesSelectedStory($event)"
                                    ></VueFileAgent>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>
                            <b-col class="pr-0" cols="3">
                                <b-button
                                    :disabled="
                                        !fileRecordsForUploadStory.length || storyImageUploaded
                                    "
                                    :variant="storyImageUploaded ? 'success' : 'primary'"
                                    @click="
                                        getImageId(
                                            dataForModal.story_image,
                                            dataForModal.story_image_id,
                                            'story_image'
                                        )
                                    "
                                >
                                    <span v-if="!storyImageUploaded">{{
                                        $t('vue_file_agent.upload_image')
                                    }}</span>
                                    <span v-else>{{ $t('vue_file_agent.image_uploaded') }}</span>
                                </b-button>
                            </b-col>
                        </b-col>
                    </div>

                    <!--     BUTTONS     -->
                    <b-col cols="12">
                        <div class="accordion" role="tablist">
                            <b-card class="mb-1" no-body>
                                <b-card-header class="py-1 px-0" header-tag="header" role="tab">
                                    <b-button v-b-toggle.accordion-1 block variant="primary">
                                        {{ $t('titles.buttons') }}
                                        <feather-icon icon=""></feather-icon>
                                    </b-button>
                                </b-card-header>

                                <b-collapse
                                    id="accordion-1"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                    visible
                                >
                                    <b-card>
                                        <div class="d-flex flex-wrap align-items-center">
                                            <!--    BUTTON[UZ]    -->
                                            <b-col class="mb-1" cols="4">
                                                <label> {{ $t('titles.button') }} (uz) </label>
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.buttons.name.uz"
                                                        :placeholder="`${$t('enter.button')} (uz)`"
                                                        size="lg"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!--    BUTTON[RU]    -->
                                            <b-col class="mb-1" cols="4">
                                                <label>{{ $t('titles.button') }} (ru)</label>
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.buttons.name.ru"
                                                        :placeholder="`${$t('enter.button')} (ru)`"
                                                        size="lg"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!--    BUTTON[EN]    -->
                                            <b-col class="mb-1" cols="4">
                                                <label>{{ $t('titles.button') }} (en)</label>
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.buttons.name.en"
                                                        :placeholder="`${$t('enter.button')} (en)`"
                                                        size="lg"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <!--    BUTTON LINK    -->
                                            <b-col cols="12">
                                                <label>{{ $t('titles.button_link') }}</label>
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.buttons.link"
                                                        :placeholder="$t('enter.button_link')"
                                                        size="lg"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </div>
                                    </b-card>
                                </b-collapse>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button :disabled="!isFormCompleted" variant="success" @click.once="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
