import { axiosV2 } from '@/services/core/base';
import CoreV2 from '@/services/core/coreV2';

class NotificationManual extends CoreV2 {
    constructor() {
        super(axiosV2({}));
    }

    sendNotificationToOne(body) {
        return this.post('notification-history/sendManual', body);
    }

    sendNotificationToAll(body) {
        return this.post('notification-history/manualPartition', body);
    }

    sendNotificationSegmentation(body) {
        return this.post('notification-history/segment', body);
    }

    pauseNotificationProcess(body) {
        return this.post('notification-history/stopWorker', body);
    }

    continueNotificationProcess(body) {
        return this.post('notification-history/startWorker', body);
    }

    checkStatusOfProcess(body) {
        return this.post('notification-history/statusWorker', body);
    }
}

export default NotificationManual;
