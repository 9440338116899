<script>
import LogsTimeline from '@/components/LogsTimeline';
import api from '@/services/api';
import { BButton, BCol, BFormGroup, BRow } from 'bootstrap-vue';
import {
    deletePropertiesWhichNoValue,
    generateIconColorToBoolean,
    generateIconToBoolean
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import TournamentsSelect from '@/views/tournament/tournament-tasks/TournamentsSelect.vue';
import TasksSelect from '@/views/tournament/tournament-tasks/TasksSelect.vue';
import flatPickr from 'vue-flatpickr-component';
import ActivateBlock from '@/components/ActivateBlock.vue';
import TasksSelectDate from '@/views/tournament/tournament-tasks/TasksSelectDate.vue';
import TaskTimeline from '@/components/TaskLogsTimeline.vue';
import TournamentsSelectTask from '@/views/tournament/tournament-tasks/TournamentsSelectTask.vue';

export default {
    name: 'SubscribersSingleTaskProgress',
    components: {
        TournamentsSelectTask,
        TaskTimeline,
        TasksSelectDate,
        ActivateBlock,
        TasksSelect,
        TournamentsSelect,
        LogsTimeline,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        flatPickr
    },
    inject: ['transactions'],
    data: () => ({
        subscriberTransactions: {
            items: [],
            pagination: {}
        },
        localLang: localStorage.getItem('lang'),
        filter: {
            page: 1,
            tournament_task_id: '',
            tournament_id: '',
            date: null
        },
        requestPending: true,
        merchant: parseInt(MERCHANT_ID)
    }),
    computed: {
        computedTransactions() {
            if (!this.transactions.value) return;
            return this.transactions.value.map((item) => {
                if (typeof item.name === 'object') {
                    item.name = item.name[this.localLang];
                }
                return item;
            });
        }
    },
    methods: {
        generateIconToBoolean,
        generateIconColorToBoolean,
        async getUserTransactions() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            if (this.merchant === 1) {
                let body = {
                    user_id: this.$route.params.id,
                    ...this.filter,
                    limit: (this.filter.limit = 10),
                    merchant_id: this.merchant
                };
                if (body.tournament_id) {
                    body.tournament_id = body.tournament_id.id;
                }
                if (body.tournament_task_id) {
                    body.task_id = body.tournament_task_id.id;
                }
                const { data } = await api.tournament.fetchTournamentTaskProgress(body);
                if (data.pagination.current === 1) {
                    this.subscriberTransactions.items = data.result;
                } else {
                    this.subscriberTransactions.items = [
                        ...this.subscriberTransactions.items,
                        ...data.result
                    ];
                }
                this.subscriberTransactions.pagination = data.pagination;
            }
            this.requestPending = false;
        },
        async createUserTransactions() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            if (this.merchant === 1) {
                let body = {
                    user_id: this.$route.params.id,
                    ...this.filter,
                    limit: (this.filter.limit = 20),
                    merchant_id: this.merchant
                };
                if (body.tournament_task_id) {
                    body.tournament_task_id = body.tournament_task_id.id;
                }

                await api.tournament
                    .fetchTournamentTaskActive(body)
                    .then(({ data }) => {
                        this.$toast.success(data.message || this.$t('success.created'));
                    })
                    .finally(() => {
                        this.filter = {
                            page: 1,
                            tournament_task_id: '',
                            tournament_id: '',
                            date: null
                        };

                        this.getUserTransactions();
                    });
            }
            this.requestPending = false;
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.requestPending = true;
            this.filter.page = page;
            await this.getUserTransactions();
            this.requestPending = false;
        },

        isPremium(premium) {
            return premium ? 'Ручной' : 'Автоматически';
        },

        deSelect(val) {
            if (!val) {
                this.getUserTransactions();
            }
        },
        async sendSyncRequest() {
            const body = {
                merchant_id: MERCHANT_ID,
                user_id: this.$route.params.id
            };
            await api.tournament
                .sendSyncRequest(body)
                .then(({ data }) => {
                    this.$toast.success(data.message || this.$t('success.updated'));
                })
                .finally(() => {
                    this.getUserTransactions();
                });
        }
    },
    mounted() {
        this.getUserTransactions();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-6 col-12 order-2 order-sm-1">
                <task-timeline
                    v-model:loading="requestPending"
                    :items="subscriberTransactions.items"
                    :pagination="subscriberTransactions.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>{{ item.name[localLang] }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.date }}
                            </small>
                        </div>
                        <div>
                            <small>{{ $t('titles.name') }}: {{ item.group_name[localLang] }}</small>
                        </div>
                        <div>
                            <small>{{ $t('titles.type') }}: {{ isPremium(item.is_manual) }}</small>
                            <!--                            <small>{{ $t('titles.step') }}: {{ item.step }}</small>-->
                        </div>
                        <div>
                            <small>{{ $t('titles.score') }}: {{ item.score }}</small>
                        </div>
                        <div>
                            <small
                                >{{ $t('tournament.task_completing_time') }}:
                                <b-badge style="font-size: 12px" variant="warning"
                                    >{{ item.diff }}
                                </b-badge>
                            </small>
                        </div>
                        <div>
                            <small
                                >{{ $t('tournament.score') }}:
                                <b-badge :variant="item.is_finished ? 'success' : 'danger'">
                                    <span v-if="item.is_gained">{{
                                        $t('tournament.score_charged')
                                    }}</span>
                                    <span v-else>{{ $t('tournament.score_not_charged') }}</span>
                                </b-badge>
                            </small>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <small
                                    >{{ $t('tournament.task_status') }}:
                                    <b-badge :variant="item.is_finished ? 'success' : 'danger'">
                                        <span v-if="item.is_finished">{{
                                            $t('tournament.task_done')
                                        }}</span>
                                        <span v-else>{{ $t('tournament.task_on_process') }}</span>
                                    </b-badge>
                                </small>
                            </div>
                            <div>
                                <b-badge :variant="item.is_finished ? 'success' : 'danger'">
                                    <small>{{
                                        $t('tournament.done_tasks_qty', {
                                            qty: $t(`${item.qty}`),
                                            done: $t(`${item.done_task_qty}`)
                                        })
                                    }}</small>
                                </b-badge>
                            </div>
                        </div>
                    </template>
                </task-timeline>
            </b-col>
            <b-col class="mb-2 mb-sm-0 col-lg-6 col-sm-6 col-12 order-1 order-sm-2">
                <div class="mb-2">
                    <b-button @click="sendSyncRequest">Sync Ranking</b-button>
                </div>
                <activate-block :filter="filter" @submit="createUserTransactions">
                    <b-col cols="12">
                        <h4 class="mb-1">
                            {{ $t('filter_title') }}
                        </h4>
                        <b-form-group>
                            <div class="mb-1">
                                <tournaments-select-task v-model="filter.tournament_id" />
                            </div>
                            <div class="mb-1">
                                <label for="datePicker">{{ $t('titles.date') }}</label>
                                <flat-pickr
                                    v-model="filter.date"
                                    :config="{
                                        enableTime: true,
                                        allowInput: true,
                                        time_24hr: true
                                    }"
                                    class="mb-1 form-control"
                                    name="date"
                                    :placeholder="$t('choose.date')"
                                    id="datePicker"
                                />
                            </div>
                            <div>
                                <tasks-select-date
                                    v-if="filter.date"
                                    v-model="filter.tournament_task_id"
                                    :date="filter.date"
                                />
                            </div>
                        </b-form-group>
                    </b-col>
                </activate-block>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
::v-deep small.bg-green {
    background-color: green !important;
}
</style>
