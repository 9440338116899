import AppDashboardActivity from '@/views/dashboard-activity/AppDashboardActivity.vue';

export default [
    {
        path: '/dashboard-activity',
        name: 'dashboard-activity',
        component: AppDashboardActivity,
        meta: {
            section: 'users',
            permission: 'view'
        }
    }
];
